import React, { FC, useEffect, useState } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import debounce from 'lodash/debounce';
// Components
import {
  Modal,
  Fade,
  Backdrop,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  GridList,
  GridListTile,
  FormControl,
  Button,
  Typography,
  TextField,
  InputAdornment,
  IconButton
} from '@material-ui/core';
import { Close, Search } from '@material-ui/icons';
// Fetch
import { getAdoTask } from '@shared/fetch';
// Types
import { ITimeTrackingEntry, ITask } from '@shared/types';

interface IConnectTaskProps {
  entry: ITimeTrackingEntry;
  open: boolean;
  close: () => void;
  onSave: (entry: ITimeTrackingEntry, previousEntry: ITimeTrackingEntry) => void;
}

export const ConnectTask: FC<IConnectTaskProps> = ({ open, close, onSave, entry }) => {
  const classes = useStyles();

  // state
  const [tfsId, setTfsId] = useState<number>(entry.externalId ? +entry.externalId : 1);

  const handleSave = () => {
    const updatedEntry = {
      ...entry,
      sourceType: tfsId === 1 ? 'Manual' : 'ADO',
      externalId: tfsId === 1 ? null : tfsId.toString()
    };
    onSave(updatedEntry, entry);
    handleClose();
  };

  let searchRef: any = {};
  const [searchString, setSearchString] = useState<string>('');
  const [isSearching, setIsSearching] = useState<boolean>(false);
  const [result, setResult] = useState<ITask | null>(null);
  const debouncedSearch = debounce(() => handleSearch(), 500);

  const handleSearch = async () => {
    setIsSearching(true);
    try {
      const response = await getAdoTask(searchString);
      setIsSearching(false);
      setResult(response);
      if (response.externalId) {
        setTfsId(response.externalId);
      }
    } catch (error) {
      setIsSearching(false);
      console.log('error', error);
    }
  };

  const handleResetSearch = () => {
    searchRef.value = '';
    setSearchString('');
    setResult(null);
    setTfsId(1);
  };

  useEffect(() => {
    if (searchString.length === 6) {
      debouncedSearch();
    }
  }, [searchString]);

  const handleClose = () => {
    setTfsId(1);
    handleResetSearch();
    close();
  };

  const modalTitle = () => {
    if (entry.description === '') {
      return 'Connect Task';
    }
    return `Connect Task for ${entry.description}`;
  };

  return (
    <Modal
      aria-labelledby='connect-task-modal-total'
      aria-describedby='connect-task-modal-description'
      className={classes.modal}
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500
      }}
    >
      <Fade in={open}>
        <Card className={classes.root}>
          <CardHeader title={modalTitle()} titleTypographyProps={{ component: 'h4', variant: 'h4' }} />
          <CardContent>
            <GridList cellHeight='auto' cols={1} spacing={24}>
              <GridListTile cols={1} classes={{ tile: classes.searchWrapper }}>
                <FormControl fullWidth>
                  <TextField
                    label='Search'
                    className={classes.formControl}
                    disabled={isSearching}
                    variant='outlined'
                    size='small'
                    inputRef={el => (searchRef = el)}
                    InputLabelProps={{ id: 'task-search', htmlFor: 'task-search' }}
                    onChange={({ target: { value } }) => {
                      if (value.length) {
                        setSearchString(value);
                      } else {
                        handleResetSearch();
                      }
                    }}
                    InputProps={{
                      className: classes.inputWrapper,
                      endAdornment: (
                        <InputAdornment position='end'>
                          {result ? (
                            <IconButton className={classes.iconButton} onClick={() => handleResetSearch()}>
                              <Close />
                            </IconButton>
                          ) : (
                            <IconButton className={classes.iconButton} disabled={isSearching} onClick={() => handleSearch()}>
                              <Search />
                            </IconButton>
                          )}
                        </InputAdornment>
                      )
                    }}
                  />
                </FormControl>
              </GridListTile>
              <GridListTile cols={1}>
                {result && result.externalId && (
                  <React.Fragment>
                    <Typography variant='h6' color='secondary'>
                      {result.externalId}
                    </Typography>
                    <Typography variant='body1' color='primary'>
                      {result.description}
                    </Typography>
                  </React.Fragment>
                )}
                {result && result.externalId === null && (
                  <Typography variant='body1' display='inline' color='error'>
                    Task not Found. Please try another query.
                  </Typography>
                )}
              </GridListTile>
            </GridList>
          </CardContent>
          <CardActions>
            <Button variant='contained' color='secondary' disabled={tfsId === 1 || isSearching || !result || result.externalId === null} onClick={handleSave}>
              Save
            </Button>
            <Button type='button' variant='contained' color='default' onClick={handleClose}>
              Cancel
            </Button>
          </CardActions>
        </Card>
      </Fade>
    </Modal>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    formControl: {
      margin: '0 2rem 1rem 0',
      minWidth: 300
    },
    iconButton: {
      padding: 0
    },
    inputWrapper: {
      paddingRight: '12px'
    },
    root: {
      width: 300,
      [theme.breakpoints.up('md')]: {
        width: 450
      }
    },
    searchWrapper: {
      overflow: 'visible'
    }
  })
);
