import React, { FC } from 'react';
import { useSelector } from 'react-redux';
// Components
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
// helpers
import { get } from 'lodash';
// Types
import { FormikHandlers, FieldMetaProps, FormikErrors } from 'formik';
// Types
import { IAppState, IClientInfo } from '@shared/types';

interface IClientCellProps extends FormikHandlers, FieldMetaProps<any> {
  cell: { value: any };
  row: { index: number };
  column: { id: string };
  data: any[];
  updateMyData: (rowIndex: number, columnId: string, value: any) => void;
  errors: FormikErrors<any>;
}

const getValue = (id: number, clients: IClientInfo[]) => {
  const find = clients.find(x => x.clientId === id);
  if (find) {
    return { label: find.name, value: find.clientId };
  }
  return null;
};

export const ClientCell: FC<IClientCellProps> = ({
  cell: { value: initialValue },
  row: { index },
  column: { id },
  data,
  updateMyData,
  errors,
  touched,
  handleBlur
}) => {
  // redux
  const { clients } = useSelector((state: IAppState) => state.clients);

  // We need to keep and update the state of the cell normally
  const [value, setValue] = React.useState(initialValue);

  // If the initialValue is changed externall, sync it up with our state
  React.useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  const selectValue = getValue(value, clients);

  return (
    <Autocomplete
      id={`${id}-search-autocomplete-${index}`}
      options={clients.filter(c => c.isActive).map(client => ({ label: client.name, value: client.clientId }))}
      getOptionLabel={option => option && option.label}
      value={selectValue}
      disabled={data[index].isLocked}
      onChange={(e: any, value: any) => {
        setValue(value ? value.value : null);
        updateMyData(index, 'clientId', value ? value.value : null);
        updateMyData(index, 'clientProjectId', null);
      }}
      renderInput={params => {
        const inputProps = params.inputProps;
        (inputProps as any).autoComplete = 'off';
        return (
          <TextField
            {...params}
            inputProps={inputProps}
            name={`timeEntries[${index}].${id}`}
            error={!selectValue && get(errors, `timeEntries[${index}].${id}`) && get(touched, `timeEntries[${index}].${id}`)}
            onBlur={handleBlur}
            helperText={
              !selectValue &&
              get(errors, `timeEntries[${index}].${id}`) &&
              get(touched, `timeEntries[${index}].${id}`) &&
              get(errors, `timeEntries[${index}].${id}`)
            }
            FormHelperTextProps={{ style: { position: 'absolute', pointerEvents: 'none' } }}
          />
        );
      }}
    />
  );
};
