import React, { FC } from 'react';
// Components
import SelectComponent from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';

export interface IOptionType {
  key: string | number;
  label: string;
  value: string | number | string[] | undefined;
}

interface ISelectProps {
  id: string;
  labelId?: string;
  label?: string;
  value?: string | number | string[] | undefined;
  disabled?: boolean;
  options: IOptionType[];
  onChange: (event: React.ChangeEvent<{ name?: string | undefined; value: unknown }>, child: React.ReactNode) => void;
  className?: string;
  placeholderItem?: IOptionType;
}

export const Select: FC<ISelectProps> = ({ id, labelId, label, value, options, onChange, disabled, className, placeholderItem }) => (
  <FormControl className={className}>
    <InputLabel id={labelId} disabled={disabled}>
      {label}
    </InputLabel>
    <SelectComponent labelId={labelId} id={id} value={value} disabled={disabled} onChange={onChange}>
      {options &&
        options.map && [
          ...(placeholderItem
            ? [
                <MenuItem disabled key={placeholderItem.key} value={placeholderItem.value}>
                  {placeholderItem.label}
                </MenuItem>
              ]
            : []),
          ...options.map(x => (
            <MenuItem key={x.key} value={x.value}>
              {x.label}
            </MenuItem>
          ))
        ]}
    </SelectComponent>
  </FormControl>
);
