import { AuthenticationContext, adalFetch, withAdalLogin, AdalConfig } from 'react-adal';
import axios from 'axios';
import { storeUserInteraction } from '@shared/fetch';
let buildConfig = require('./buildSettings');
const axiosInstance = axios.create({
  baseURL: `${buildConfig.REACT_APP_API_URL}/`
});

export const adalConfig: AdalConfig = {
  tenant: buildConfig.tenantId,
  clientId: buildConfig.clientId,
  endpoints: {
    api: buildConfig.clientId
  },
  redirectUri: window.location.origin,
  cacheLocation: 'localStorage',
  // This callback should happen on login according to react-adal source code
  callback: () => {
    storeUserInteraction({ userInteractionType: 'userLogin', pageName: window.location.pathname });
  }
};

export const authContext = new AuthenticationContext(adalConfig);

export const logout = async () => await authContext.logOut();

export const adalApiFetch = (url: string, options: any) => adalFetch(authContext, adalConfig!.endpoints!.api, axiosInstance, url, options);

export const withAdalLoginApi = withAdalLogin(authContext, adalConfig!.endpoints!.api);
