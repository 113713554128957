import React, { FC } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { Link, useLocation, useHistory } from 'react-router-dom';
// Components
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
// Types
import { ISubNavItem } from './SubNavItem';
import { SubNavMenu } from './SubNavMenu';

export interface IClientNavItem {
  path: string;
  title: string;
  subNav?: ISubNavItem[];
  icon?: any;
  userAccess?: string;
  isNavOpen?: boolean;
}

interface IClientNavItemProps extends IClientNavItem {
  isNavOpen: boolean;
}

const useStyles = makeStyles(theme => ({
  root: {
    '&:hover > .sub-nav-menu': {
      display: 'flex',
      cursor: 'pointer',
      '& svg': {
        fill: theme.palette.common.white
      }
    },
    position: 'relative'
  },
  link: {
    textDecoration: 'none',
    color: theme.palette.text.secondary
  },
  listItem: {
    overflowX: 'hidden',
    '&:hover': {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.secondary.main,
      '& svg': {
        fill: theme.palette.common.white
      }
    }
  },
  listItemActive: {
    color: theme.palette.secondary.main
  },
  icon: {
    '& > svg': {
      fill: theme.palette.primary.main
    }
  },
  iconActive: {
    '& > svg': {
      fill: theme.palette.secondary.main
    }
  }
}));

const splitPathString = (path: string) => {
  return path.split('/').filter((x: string) => x);
};

export const ClientNavItem: FC<IClientNavItemProps> = ({ title, path, icon, isNavOpen, subNav }) => {
  // styles
  const classes = useStyles();
  // router
  const history = useHistory();

  const { pathname } = useLocation();

  // determine if this path is active so we can highlight
  const splitPathname = splitPathString(pathname);
  const splitPath = splitPathString(path);
  const active = splitPath[1] === splitPathname[1];

  return (
    <div className={classes.root}>
      <Link className={clsx(classes.link, { [classes.listItemActive]: active })} to={path}>
        <ListItem className={clsx(classes.listItem, { active })} button key={title} divider>
          <ListItemIcon
            classes={{
              root: clsx(classes.icon, {
                [classes.iconActive]: active
              })
            }}
          >
            {icon}
          </ListItemIcon>
          <ListItemText primary={title} />
        </ListItem>
      </Link>
      {!isNavOpen && (
        <SubNavMenu
          className='sub-nav-menu'
          title={title}
          isNavOpen={isNavOpen}
          isActive={active}
          onNav={() => history.push(path)}
          subNav={subNav || []}
        />
      )}
    </div>
  );
};
