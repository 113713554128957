import React, { useState, FC, useEffect } from 'react';
import { makeStyles, Theme, darken } from '@material-ui/core/styles';
import { useHistory, Link, useLocation } from 'react-router-dom';
import { deepEqual } from 'fast-equals';
import clsx from 'clsx';
import { useMedia } from 'react-use';
import { isEmpty, isUndefined, isNull } from 'lodash';
import ReactGA from 'react-ga';
// redux
import { useSelector, useDispatch } from 'react-redux';
// Components
import { Skeleton, Alert, AlertTitle } from '@material-ui/lab';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import CardContent from '@material-ui/core/CardContent';
import Divider from '@material-ui/core/Divider';
import Card from '@material-ui/core/Card';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Save from '@material-ui/icons/Save';
import Close from '@material-ui/icons/Close';
import Visibility from '@material-ui/icons/Visibility';
import Publish from '@material-ui/icons/Publish';
import Cached from '@material-ui/icons/Cached';
import Delete from '@material-ui/icons/Delete';
import Create from '@material-ui/icons/Create';
import Print from '@material-ui/icons/Print';
import { Wysiwyg, WysiwygRender } from '@shared/components/wysiwyg';
import LibraryBooks from '@material-ui/icons/LibraryBooks';
import CancelScheduleSend from '@material-ui/icons/CancelScheduleSend';
import TextField from '@material-ui/core/TextField';
import { SprintSwitcher } from '@shared/components/switcher';
import { CreateProgressReportModal } from '../components/CreateProgressReportModal';
import { ProgressReportCard } from '../components/ProgressReportCard';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { Toast } from '@shared/components/toast';
import { NoProgressReports } from './NoProgressReports';
import { ProgressReportMobileButton } from './ProgressReportMobileButton';
import { ProgressReportAccruedExpensesChart, ProgressReportSprintExpensesChart } from './charts';
import { Loader } from '@shared/components/loader';
import { UnsavedChanges } from '@shared/components/modals';
// types
import {
  IAppState,
  IUserProps,
  IProgressReportDetail,
  IProgressReportLaborExpenses,
  IBasicObject,
  ProgressReportMobileMenuOption,
  IExtranetSprintItem
} from '@shared/types';
// helpers
import {
  formatMoney,
  formatShortFriendlyDateWithTime,
  buildAccruedExpensesData,
  buildSprintExpensesData,
  isWysiwygEmpty,
  renderSprintLabel,
  getCorrectProgressReportEditorValue
} from '@shared/helpers';
// constants
import { userAccess, BLOCKED_COLOR } from '@shared/constants';
// actions
import { setCurrentReport } from '@shared/redux/actions';
// fetch
import {
  createProgressReport,
  updateSingleProgressReport,
  getSingleProgressReportBudget,
  getSingleProgressReportHours,
  getSingleProgressReportByReportId,
  removeProgressReport,
  getSingleProgressReportLaborExpenses,
  getSingleProgressReportBySprint
} from '@shared/fetch';
import { getSingleProgressReportSprintAccomplishments } from '../../shared/fetch/progressReports';
import { DashboardCard } from './DashboardCard';
import { ProgressReportDetailCard } from './ProgressReportDetailCard';
import { Book, LocalAtm, WorkOutline } from '@material-ui/icons';

interface IProgressReportDetailProps {
  // used to compare the one from the redux and what is currently being edited
  currentReport: IProgressReportDetail;
  isLoadingReport?: boolean;
  isEditing?: boolean;
  isPreview?: boolean;
  // used to compare the one from the api and what is currently being edited
  apiReport?: IProgressReportDetail | null;
}

export const ProgressReportDetail: FC<IProgressReportDetailProps> = ({ currentReport, isLoadingReport, isEditing, isPreview, apiReport }) => {
  const SPRINT_COUNT = 10;
  // state
  const [saving, setSaving] = useState<'save' | 'publish' | null>(null);
  const [isUnpublishing, setUnpublishing] = useState<boolean>(false);
  const [isDeleting, setDeleting] = useState<boolean>(false);
  const [nextSprintValue, setNextSprintValue] = useState<string>(currentReport.mercuryNeeds);
  const [sprintReviewValue, setSprintReviewValue] = useState<string>(currentReport.sprintActivities);
  const [additionalInformation] = useState<string>(currentReport.portfolioStatus);
  const [initialAdditionalInformation] = useState<string>('');
  const [initialSprintReviewValue, setInitialSprintReviewValue] = useState<string>('');
  const [initialNextSprintValue, setInitialNextSprintValue] = useState<string>('');
  const [isShowingCreateModal, showModal] = useState<boolean>(false);
  const [sendNotification, setNotification] = useState<boolean>(currentReport.sendNotification);
  const [showBudget, setShowBudget] = useState<boolean>(currentReport.displayBudgetSpend);
  const [showHours, setShowHours] = useState<boolean>(currentReport.displayExpendedLaborHours);
  const [showStoriesCompleted, setShowStories] = useState<boolean>(currentReport.displayUserStoriesCompleted);
  const [titleValue, setTitle] = useState<string>(currentReport.title);
  const [isError, showError] = useState<boolean>(false);
  const [isSuccess, showSuccess] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [successMessage, setSuccessMessage] = useState<string>('');
  const [needsRefresh, setNeedsRefresh] = useState<boolean>(currentReport.needsRefresh);
  const [refresh, setRefreshing] = useState<'budget' | 'hours' | 'stories' | 'charts' | null>(null);
  const [budget, setBudget] = useState<number>(currentReport.budgetSpendTotalAmount);
  const [hours, setHours] = useState<number>(currentReport.expendedLaborHours);
  const [storiesCompleted, setStoriesCompleted] = useState<number>(currentReport.userStoriesCompleted);
  // Provide a default so isReportClean's deepEqual check behaves as it should
  // when the currentReport.laborExpenses gets updated from the BE during saving
  const [laborExpenses, setLaborExpenses] = useState<IProgressReportLaborExpenses>(
    currentReport.laborExpenses || { accruedExpenses: [], sprintExpenses: [] }
  );
  const [accruedExpensesChartData, setAccruedExpensesChartData] = useState<IBasicObject[]>();
  const [sprintExpensesChartData, setSprintExpensesChartData] = useState<IBasicObject[]>();
  const [hasNoProgressReport, setNoProgressReport] = useState<boolean>(false);
  const [isSwitchingProgressReports, setSwitchingProgressReports] = useState<boolean>(false);
  const [isLoadingBudget, setLoadingBudget] = useState<boolean>(false);
  const [selectedSprint, setSelectedSprint] = useState<IExtranetSprintItem | null>(null);
  // redux
  const { selectedClient } = useSelector((state: IAppState) => state.extranet);
  const user: IUserProps = useSelector((state: IAppState) => state.user);
  const {
    ClientFinancialsData
  } = user.userAccess
  const isAdmin = user.userAccess[userAccess.VIEW_ADMIN];
  const gaLabelClientValue: string = selectedClient ? ` | Client: ${selectedClient?.name}` : '';
  // styles
  const classes = useStyles();
  // router
  const history = useHistory();
  const location = useLocation<{ includeJog: boolean }>()
  // dispatch
  const dispatch = useDispatch();
  // media
  const isMobile = useMedia('(max-width: 960px)');
  // effects
  useEffect(() => {
    if (needsRefresh) {
      Promise.all([handleRefresh('budget'), handleRefresh('hours'), handleRefresh('stories'), handleRefresh('charts')]);

      setNeedsRefresh(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [needsRefresh]);
  useEffect(() => {
    // the user changed the selected client, go back to the progress reports list
    // only redirect on change of client, if editing or previewing, else load per usual to accommodate email links
    if ((selectedClient && selectedClient.clientId) !== (currentReport && currentReport.clientId) && (isEditing || isPreview)) {
      history.push('/clients/progress-reports');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedClient, currentReport]);
  useEffect(() => {
    if (!isEmpty(laborExpenses.accruedExpenses) && !isUndefined(laborExpenses.accruedExpenses) && !isNull(laborExpenses.accruedExpenses)) {
      setAccruedExpensesChartData(buildAccruedExpensesData(laborExpenses.accruedExpenses));
    }
    if (!isEmpty(laborExpenses.sprintExpenses) && !isUndefined(laborExpenses.sprintExpenses) && !isNull(laborExpenses.sprintExpenses)) {
      setSprintExpensesChartData(buildSprintExpensesData(laborExpenses.sprintExpenses));
    }
  }, [laborExpenses]);

  // compare in redux or api report state vs current react state
  const isReportClean = (): boolean => {
    if (apiReport) {
      return deepEqual(
        {
          title: apiReport.title,
          sendNotification: apiReport.sendNotification,
          published: apiReport.published,
          portfolioStatus: initialAdditionalInformation || apiReport.portfolioStatus,
          sprintActivities: initialSprintReviewValue || apiReport.sprintActivities,
          mercuryNeeds: initialNextSprintValue || apiReport.mercuryNeeds,
          budgetSpendTotalAmount: apiReport.budgetSpendTotalAmount,
          expendedLaborHours: apiReport.expendedLaborHours,
          userStoriesCompleted: apiReport.userStoriesCompleted,
          displayBudgetSpend: apiReport.displayBudgetSpend,
          displayExpendedLaborHours: apiReport.displayExpendedLaborHours,
          displayUserStoriesCompleted: apiReport.displayUserStoriesCompleted,
          laborExpenses: apiReport.laborExpenses
        },
        {
          title: titleValue,
          sendNotification,
          published: currentReport.published,
          portfolioStatus: additionalInformation,
          sprintActivities: sprintReviewValue,
          mercuryNeeds: nextSprintValue,
          budgetSpendTotalAmount: budget,
          expendedLaborHours: hours,
          userStoriesCompleted: storiesCompleted,
          displayBudgetSpend: showBudget,
          displayExpendedLaborHours: showHours,
          displayUserStoriesCompleted: showStoriesCompleted,
          laborExpenses: laborExpenses
        }
      );
    }
    // don't use this compare when we are in preview mode
    if (currentReport && !currentReport.isPreview && !apiReport) {
      return deepEqual(
        {
          title: currentReport.title,
          sendNotification: currentReport.sendNotification,
          published: currentReport.published,
          portfolioStatus: getCorrectProgressReportEditorValue(
            currentReport.published || !currentReport.dateUpdated,
            currentReport.portfolioStatus,
            initialAdditionalInformation
          ),
          sprintActivities: getCorrectProgressReportEditorValue(
            currentReport.published || !currentReport.dateUpdated,
            currentReport.sprintActivities,
            initialSprintReviewValue
          ),
          mercuryNeeds: getCorrectProgressReportEditorValue(
            currentReport.published || !currentReport.dateUpdated,
            currentReport.mercuryNeeds,
            initialNextSprintValue
          ),
          budgetSpendTotalAmount: currentReport.budgetSpendTotalAmount,
          expendedLaborHours: currentReport.expendedLaborHours,
          userStoriesCompleted: currentReport.userStoriesCompleted,
          displayBudgetSpend: currentReport.displayBudgetSpend,
          displayExpendedLaborHours: currentReport.displayExpendedLaborHours,
          displayUserStoriesCompleted: currentReport.displayUserStoriesCompleted,
          laborExpenses: currentReport.laborExpenses
        },
        {
          title: titleValue,
          sendNotification,
          published: currentReport.published,
          portfolioStatus: additionalInformation,
          sprintActivities: sprintReviewValue,
          mercuryNeeds: nextSprintValue,
          budgetSpendTotalAmount: budget,
          expendedLaborHours: hours,
          userStoriesCompleted: storiesCompleted,
          displayBudgetSpend: showBudget,
          displayExpendedLaborHours: showHours,
          displayUserStoriesCompleted: showStoriesCompleted,
          laborExpenses: laborExpenses
        }
      );
    }
    return false;
  };

  const handleSave = async (isPublished?: boolean, isUnpublishing?: boolean, isSavingPublished?: boolean) => {
    setSaving(isSavingPublished || !isPublished ? 'save' : 'publish');
    const data = {
      title: titleValue,
      sendNotification,
      published: isPublished || false,
      portfolioStatus: additionalInformation,
      sprintActivities: sprintReviewValue,
      mercuryNeeds: nextSprintValue,
      budgetSpendTotalAmount: budget,
      expendedLaborHours: hours,
      userStoriesCompleted: storiesCompleted,
      displayBudgetSpend: showBudget,
      displayExpendedLaborHours: showHours,
      displayUserStoriesCompleted: showStoriesCompleted,
      laborExpenses: laborExpenses
    };
    try {
      if (currentReport.clientPortfolioReportId) {
        await updateSingleProgressReport(currentReport.clientPortfolioReportId, data);
        // call the get endpoint after update to get the latest information and update redux state
        const updatedReport = await getSingleProgressReportByReportId(currentReport.clientPortfolioReportId, { withBudget: ClientFinancialsData });

        dispatch(
          setCurrentReport({
            ...updatedReport,
            isPreview: false
          })
        );
        // successfully update report, if they first publish then route to view progress report page
        if (!isUnpublishing) {
          history.push(`/clients/progress-reports/${currentReport.clientPortfolioReportId}`);
        }
      } else {
        const reportId = await createProgressReport(currentReport.clientId, currentReport.projectIterationUuid, data);
        const newReport = await getSingleProgressReportByReportId(reportId, { withBudget: ClientFinancialsData });
        isReportClean();

        dispatch(
          setCurrentReport({
            ...newReport,
            isPreview: false
          })
        );
        // successfully created report, route to actual progress report page
        history.push(`/clients/progress-reports/${reportId}`);
      }
      showSuccess(true);
    } catch (error) {
      showError(true);
      console.log(error);
    } finally {
      setSaving(null);
      setUnpublishing(false);
    }
  };

  const handleRefresh = async (type: 'budget' | 'hours' | 'stories' | 'charts' | null) => {
    const IncludeJog = location.state.includeJog
    const singleReportParams = {
      IncludeJog
    }
    try {
      setRefreshing(type);
      if (type === 'budget') {
        const data = await getSingleProgressReportBudget(
          currentReport.clientId,
          currentReport.projectIterationUuid,
          singleReportParams
        );
        setBudget(data);
      }
      if (type === 'hours') {
        const data = await getSingleProgressReportHours(
          currentReport.clientId,
          currentReport.projectIterationUuid,
          singleReportParams
        );
        setHours(data);
      }
      if (type === 'stories') {
        const data = await getSingleProgressReportSprintAccomplishments(
          currentReport.clientId,
          currentReport.projectIterationUuid,
          singleReportParams
        );
        setStoriesCompleted(data.storyCount);
      }
      if (type === 'charts') {
        setLoadingBudget(true);
        const data = await getSingleProgressReportLaborExpenses(
          currentReport.clientId,
          currentReport.projectIterationUuid,
          singleReportParams
        );
        setLaborExpenses(data);
        setLoadingBudget(false);
      }
    } catch (error) {
    } finally {
      setRefreshing(null);
    }
  };
  const handleCancel = () => {
    if (currentReport.dateUpdated) {
      return history.push(`/clients/progress-reports/${currentReport.clientPortfolioReportId}`);
    }
    return history.push('/clients/progress-reports');
  };

  const handlePreview = () => {
    // store the latest data in redux to show to the user on the preview page
    dispatch(
      setCurrentReport({
        ...currentReport,
        title: titleValue,
        sendNotification,
        portfolioStatus: additionalInformation,
        sprintActivities: sprintReviewValue,
        mercuryNeeds: nextSprintValue,
        displayBudgetSpend: showBudget,
        displayExpendedLaborHours: showHours,
        displayUserStoriesCompleted: showStoriesCompleted,
        laborExpenses: laborExpenses,
        isPreview: true
      })
    );
    setTimeout(() => {
      history.push(`/clients/progress-reports/${currentReport.clientPortfolioReportId || 'create'}/preview`);
    }, 500);
  };

  const handleDelete = async () => {
    const result = window.confirm(
      currentReport.published
        ? 'Are you sure you want to delete this report?  Clients may have already received an email with a link to this report, and deleting it would cause the link to no longer work.'
        : 'Are you sure you want to delete this report?'
    );
    if (result) {
      setDeleting(true);
      try {
        if (currentReport.clientPortfolioReportId) {
          await removeProgressReport(currentReport.clientPortfolioReportId);
        }
        setSuccessMessage('Report Deleted!');
        showSuccess(true);
        setTimeout(() => {
          history.push('/clients/progress-reports');
        }, 500);
      } catch (err) {
        setErrorMessage(
          'We were unable to delete your report at this time. Please try again later. Please contact MercuryWorks support if this issue continues.'
        );
        showError(true);
      }
    }
  };
  const displayStatsArr: boolean[] = [
    currentReport.displayBudgetSpend,
    currentReport.displayExpendedLaborHours,
    currentReport.displayUserStoriesCompleted
  ].filter(Boolean);

  return (
    <section className={classes.wrapper}>
      <Grid container alignItems='flex-start' justify='space-between' spacing={2} className={classes.cardHolder}>
        <UnsavedChanges dirty={!isReportClean() && !saving} />
        {isPreview && (
          <div className={classes.previewInfo}>
            <Alert severity='info'>
              <AlertTitle className={classes.alertTitle}>
                <strong>Currently viewing the report in preview mode.</strong>
              </AlertTitle>
            </Alert>
          </div>
        )}
        <Grid item xs={12}>
          {isLoadingReport ? (
            <DashboardCard title={titleValue} isColumn={false}>
              <Grid direction='column' container alignItems='center' justify='center' className={classes.skeletonPadding}>
                <Grid direction='row' container spacing={4} alignItems='center' justify='space-between' item xs={12}>
                  <Grid xs={12} md={4}>
                    <Skeleton variant='rect' height={107} width='95%' />
                  </Grid>
                  <Grid xs={12} md={4} className={classes.skeletonSpacing}>
                    <Skeleton variant='rect' height={107} width='95%' />
                  </Grid>
                  <Grid xs={12} md={4}>
                    <Skeleton variant='rect' height={107} width='95%' />
                  </Grid>
                </Grid>
              </Grid>
            </DashboardCard>
          ) : (
            <DashboardCard
              title={titleValue}
              isColumn={false}
              actions={
                <div className={`${classes.buttonWrapper} ${classes.hidden}`}>
                  <div className={classes.innerButtonWrapper}>
                    <Button
                      disableRipple
                      size='small'
                      color='primary'
                      startIcon={<ChevronLeft />}
                      className={isAdmin ? classes.backToReports : classes.backToReportsUser}
                      onClick={e => {
                        e.preventDefault();
                        history.push('/clients/progress-reports');
                      }}
                    >
                      Back to Reports
                    </Button>
                  </div>
                  {!isMobile && <Divider orientation='vertical' flexItem className={classes.pipe} />}
                  <SprintSwitcher
                    sprintOption={{
                      // use the selected sprint for reports that don't exist otherwise fallback to the current report in redux
                      projectIterationUuid: selectedSprint?.projectIterationUuid || currentReport.projectIterationUuid,
                      name: selectedSprint ? renderSprintLabel(selectedSprint) : titleValue
                    }}
                    showArrows={false}
                    // change sprints find the progress report id and then redirect the user to that page
                    handleSelectedSprint={async selectedSprint => {
                      if (selectedClient && selectedSprint) {
                        setSwitchingProgressReports(true);
                        setNoProgressReport(false);
                        try {
                          const res = await getSingleProgressReportBySprint(selectedClient.clientId, selectedSprint.projectIterationUuid as number);
                          history.push(`/clients/progress-reports/${res.clientPortfolioReportId}`);
                          // set the selected sprint null here because the selected sprint will be set by the currentReport in redux
                          setSelectedSprint(null);
                          dispatch(setCurrentReport(res));
                          setTitle(res.title);
                        } catch (error) {
                          setNoProgressReport(true);
                          // set the title of the progress report that doesn't exist based on the selected sprint value
                          setTitle(renderSprintLabel(selectedSprint));
                          // set the selected sprint locally when the selected progress report doesn't exist
                          setSelectedSprint(selectedSprint);
                          console.error(error);
                        } finally {
                          setSwitchingProgressReports(false);
                        }
                      }
                    }}
                  />
                </div>
              }
            >
              {isSwitchingProgressReports && <Loader position='centered'>Loading...</Loader>}
              {hasNoProgressReport && !isSwitchingProgressReports && (
                <NoProgressReports
                  isAdmin={isAdmin}
                  message={
                    isAdmin
                      ? 'No Progress Report exists for this sprint. Click on the Create New Report button below to create a report.'
                      : 'No Progress Report has been created for this sprint.  Please select a different sprint to see a Progress Report.'
                  }
                  selectedClient={selectedClient}
                />
              )}
              {!hasNoProgressReport && !isSwitchingProgressReports && (
                <>
                  <Grid item container className={classes.topButtonWrapper}>
                    <div className={classes.buttons}>
                      {(currentReport.dateUpdated || currentReport.dateCreated) && (
                        <Typography display='inline' className={classes.savedDraftText}>
                          <span className={classes.bold}>
                            {!currentReport.published && !currentReport.dateUpdated
                              ? 'Created'
                              : !currentReport.published
                              ? 'Saved as Draft'
                              : 'Published'}
                          </span>{' '}
                          on{' '}
                          {currentReport.dateUpdated
                            ? formatShortFriendlyDateWithTime(`${currentReport.dateUpdated}Z`)
                            : formatShortFriendlyDateWithTime(`${currentReport.dateCreated}Z`)}
                        </Typography>
                      )}
                      {isMobile && isAdmin && isEditing && (
                        <ProgressReportMobileButton
                          isAdmin={isAdmin}
                          isLoading={!!saving || isDeleting}
                          handleClick={(val: ProgressReportMobileMenuOption) => {
                            if (val === 'Publish') {
                              return handleSave(true);
                            }
                            if (val === 'Unpublish') {
                              return handleSave(false, true);
                            }
                            if (val === 'Cancel') {
                              return handleCancel();
                            }
                            if (val === 'Save') {
                              return handleSave(true, false, true);
                            }
                            if (val === 'Save as Draft') {
                              return handleSave(false, true);
                            }
                            if (val === 'Delete') {
                              return handleDelete();
                            }
                            if (val === 'Preview') {
                              return handlePreview();
                            }
                          }}
                          showPublishOption={currentReport.published ? false : true}
                          isDisabled={isReportClean()}
                        />
                      )}
                      {!isMobile && isAdmin && !currentReport.published && isEditing && (
                        <Button
                          variant='contained'
                          size='small'
                          color='secondary'
                          startIcon={saving === 'publish' ? null : <Publish />}
                          disabled={saving === 'publish'}
                          onClick={() => handleSave(true)}
                          className={classes.publishButton}
                        >
                          {saving === 'publish' ? 'Publishing...' : 'Publish'}
                        </Button>
                      )}
                      {!isMobile && isAdmin && currentReport.published && isEditing && (
                        <Button
                          variant='contained'
                          size='small'
                          color='default'
                          startIcon={isUnpublishing ? null : <CancelScheduleSend />}
                          disabled={saving === 'save'}
                          onClick={() => {
                            const result = window.confirm('Are you sure you want to unpublish this report?');
                            if (result) {
                              setUnpublishing(true);
                              handleSave(false, true);
                            }
                          }}
                          className={classes.unpublishButton}
                        >
                          {isUnpublishing ? 'Unpublishing...' : 'Unpublish'}
                        </Button>
                      )}
                      {isAdmin && !isEditing && currentReport.clientPortfolioReportId && !isPreview && (
                        <Button
                          disableRipple
                          size='small'
                          color='primary'
                          className={classes.hidden}
                          startIcon={<Create />}
                          onClick={() => history.push(`/clients/progress-reports/${currentReport.clientPortfolioReportId}/edit`)}
                        >
                          Edit
                        </Button>
                      )}
                      {isAdmin && !isEditing && isPreview && (
                        <Button
                          disableRipple
                          size='small'
                          color='primary'
                          startIcon={<Close />}
                          onClick={() => {
                            setTimeout(() => {
                              currentReport.clientPortfolioReportId
                                ? history.push(`/clients/progress-reports/${currentReport.clientPortfolioReportId}/edit`)
                                : history.push(`/clients/progress-reports/new/create`);
                            }, 500);
                          }}
                        >
                          Close Preview
                        </Button>
                      )}
                      {isAdmin && !isMobile && isEditing && currentReport.published && (
                        <Button
                          disableRipple
                          size='small'
                          color='primary'
                          disabled={(isReportClean() && (!!currentReport.dateUpdated || !!currentReport.dateCreated)) || saving === 'save'}
                          startIcon={saving === 'save' ? null : <Save />}
                          onClick={() => handleSave(true, false, true)}
                        >
                          {saving === 'save' ? 'Saving...' : 'Save'}
                        </Button>
                      )}
                      {isAdmin && !isMobile && isEditing && !currentReport.published && (
                        <Button
                          disableRipple
                          size='small'
                          color='primary'
                          disabled={(isReportClean() && (!!currentReport.dateUpdated || !!currentReport.dateCreated)) || saving === 'save'}
                          startIcon={saving === 'save' ? null : <Save />}
                          onClick={() => handleSave(false, true)}
                        >
                          {saving === 'save' ? 'Saving...' : 'Save as Draft'}
                        </Button>
                      )}
                      {!isMobile && isEditing && (
                        <>
                          <Divider orientation='vertical' flexItem className={classes.pipe} />
                          <Button
                            disableRipple
                            size='small'
                            color='primary'
                            disabled={isReportClean() && (!!currentReport.dateUpdated || !!currentReport.dateCreated) && !!currentReport.isPreview}
                            startIcon={<Close />}
                            onClick={() => handleCancel()}
                          >
                            Cancel
                          </Button>
                          <Divider orientation='vertical' flexItem className={classes.pipe} />
                          <Button
                            disableRipple
                            size='small'
                            color='primary'
                            disabled={
                              isReportClean() &&
                              (!!currentReport.dateUpdated || !!currentReport.dateCreated) &&
                              !!currentReport.isPreview &&
                              !currentReport.published
                            }
                            startIcon={<Visibility />}
                            onClick={() => handlePreview()}
                          >
                            Preview
                          </Button>
                        </>
                      )}
                    </div>
                    {!isMobile && (
                      <Button
                        startIcon={<Print />}
                        className={classes.hidden}
                        color='primary'
                        onClick={() => {
                          ReactGA.event({
                            category: 'Progress Reports',
                            action: 'Clicking the print button',
                            label: `Clicking the print button${gaLabelClientValue}`
                          });

                          document.getElementById('wrapper')?.classList.add(classes.hidden);
                          window.print();
                          document.getElementById('wrapper')?.classList.remove(classes.hidden);
                        }}
                      >
                        <div>PRINT</div>
                      </Button>
                    )}
                    {isAdmin && !isMobile && currentReport.clientPortfolioReportId && (
                      <Button
                        disableRipple
                        size='small'
                        className={`${classes.deleteButton} ${classes.hidden}`}
                        color='default'
                        disabled={isDeleting}
                        startIcon={isDeleting ? null : <Delete />}
                        onClick={() => handleDelete()}
                      >
                        {isDeleting ? 'Deleting...' : 'Delete'}
                      </Button>
                    )}
                  </Grid>
                  {isAdmin && isEditing && (
                    <Grid container item>
                      <ProgressReportCard title='Report Information'>
                        <div>
                          <TextField
                            className={classes.titleInput}
                            id='progress-report-title'
                            size='small'
                            margin='none'
                            label='Title'
                            inputProps={{ maxLength: 50 }}
                            onChange={e => {
                              setTitle(e.target.value);
                            }}
                            placeholder='Enter title...'
                            variant='outlined'
                            required
                            value={titleValue}
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                name='send-notification'
                                color='secondary'
                                checked={sendNotification}
                                onChange={() => setNotification(!sendNotification)}
                              />
                            }
                            label='Send Notification on Publish?'
                          />
                        </div>
                      </ProgressReportCard>
                    </Grid>
                  )}
                  <Grid item container>
                    {(isEditing ||
                      currentReport.displayBudgetSpend ||
                      currentReport.displayExpendedLaborHours ||
                      currentReport.displayUserStoriesCompleted) && (
                      <Grid container alignContent='center' spacing={isMobile ? 1 : 2} className={classes.budgetWrapper}>
                        {ClientFinancialsData && (isEditing || currentReport.displayBudgetSpend) && (
                          <Grid
                            item
                            xs={12}
                            md={!isEditing && (!currentReport.displayUserStoriesCompleted || !currentReport.displayExpendedLaborHours) ? 6 : 4}
                          >
                            <ProgressReportDetailCard
                              icon={LocalAtm}
                              detail={budget}
                              detailName={'budget'}
                              refresh={refresh}
                              handleRefresh={handleRefresh}
                              isAdmin={isAdmin}
                              isEditing={isEditing}
                              showDetail={showBudget}
                              setShowDetail={setShowBudget}
                              detailLabel={'Budget Spend'}
                              detailFormatter={formatMoney}
                            />
                          </Grid>
                        )}
                        {(isEditing || currentReport.displayExpendedLaborHours) && (
                          <Grid
                            item
                            xs={12}
                            md={!isEditing && (!currentReport.displayBudgetSpend || !currentReport.displayUserStoriesCompleted) ? 6 : 4}
                          >
                            <ProgressReportDetailCard
                              icon={WorkOutline}
                              detail={hours}
                              detailName={'hours'}
                              refresh={refresh}
                              handleRefresh={handleRefresh}
                              isAdmin={isAdmin}
                              isEditing={isEditing}
                              showDetail={showHours}
                              setShowDetail={setShowHours}
                              detailLabel={'Expended Labor Hours'}
                              isPrimary={false}
                            />
                          </Grid>
                        )}
                        {(isEditing || currentReport.displayUserStoriesCompleted) && (
                          <Grid
                            item
                            xs={12}
                            md={!isEditing && (!currentReport.displayBudgetSpend || !currentReport.displayExpendedLaborHours) ? 6 : 4}
                          >
                            <ProgressReportDetailCard
                              icon={Book}
                              detail={storiesCompleted}
                              detailName={'stories'}
                              refresh={refresh}
                              handleRefresh={handleRefresh}
                              isAdmin={isAdmin}
                              isEditing={isEditing}
                              showDetail={showStoriesCompleted}
                              setShowDetail={setShowStories}
                              detailLabel={'User Stories Completed'}
                            />
                          </Grid>
                        )}
                        {!isEditing && displayStatsArr.length === 1 && (
                          <Grid item xs={12} md={6}>
                            <Card className={classes.card}>
                              {/* Update this card */}
                              <CardContent className={classes.cardContent}>
                                <Grid container alignItems='center'>
                                  <LibraryBooks className={classes.icon} />
                                  <div>
                                    <Typography className={classes.statValue}>View the Backlog</Typography>
                                    <Typography variant='h2' gutterBottom className={classes.statTitle}>
                                      <Link to='/clients/board' className={classes.userStoriesLink}>
                                        Go to User Stories
                                        <ChevronRight />
                                      </Link>
                                    </Typography>
                                  </div>
                                  {isAdmin && isEditing && (
                                    <IconButton
                                      onClick={() => handleRefresh('stories')}
                                      className={clsx(classes.refresh, refresh === 'stories' ? classes.refreshAnimation : undefined)}
                                    >
                                      <Cached />
                                    </IconButton>
                                  )}
                                </Grid>
                              </CardContent>
                            </Card>
                          </Grid>
                        )}
                      </Grid>
                    )}
                  </Grid>
                </>
              )}
            </DashboardCard>
          )}
        </Grid>

        <Grid item container xs={12} md={6}>
          <DashboardCard title={'Sprint Accomplishments'}>
            {isLoadingReport ? (
              <Grid item xs={12}>
                {Array.from(Array(SPRINT_COUNT).keys()).map(x => (
                  <Skeleton key={`${x}`} height={37} />
                ))}
              </Grid>
            ) : (
              <Grid item xs={12}>
                {isEditing ? (
                  <Wysiwyg
                    id='accomplishments'
                    onChange={(value, delta, source, editor) => {
                      setSprintReviewValue(value);
                      // check the value from the api which might not include the correct format that the editor has
                      if (!initialSprintReviewValue && !currentReport.sprintActivities.includes('class')) {
                        setInitialSprintReviewValue(editor.getHTML());
                      }
                    }}
                    value={sprintReviewValue}
                  />
                ) : (
                  <WysiwygRender html={!isWysiwygEmpty(sprintReviewValue) ? sprintReviewValue : `<p>N/A</p>`} />
                )}
              </Grid>
            )}
          </DashboardCard>
        </Grid>

        <Grid item container xs={12} md={6}>
          <DashboardCard title={'Planned for Next Sprint'}>
            {isLoadingReport ? (
              <Grid item xs={12}>
                {Array.from(Array(SPRINT_COUNT).keys()).map(x => (
                  <Skeleton key={`${x}`} height={37} />
                ))}
              </Grid>
            ) : (
              <Grid item xs={12}>
                {isEditing ? (
                  <Wysiwyg
                    id='next-sprint'
                    onChange={(value, delta, source, editor) => {
                      setNextSprintValue(value);
                      // check the value from the api which might not include the correct format that the editor has
                      if (!initialNextSprintValue && !currentReport.mercuryNeeds.includes('class')) {
                        setInitialNextSprintValue(editor.getHTML());
                      }
                    }}
                    value={nextSprintValue}
                  />
                ) : (
                  <WysiwygRender html={!isWysiwygEmpty(nextSprintValue) ? nextSprintValue : `<p>N/A</p>`} />
                )}
              </Grid>
            )}
          </DashboardCard>
        </Grid>
        {/* Not Full Width for some reason */}
        {ClientFinancialsData && <Grid item container xs={12} classes={{ root: classes.budgetAccordion }}>
          <DashboardCard
            title='Budget'
            actions={
              isAdmin && isEditing ? (
                <Button
                  disableRipple
                  size='large'
                  color='inherit'
                  startIcon={<Cached className={refresh === 'charts' ? classes.refreshAnimation : undefined} />}
                  onClick={() => handleRefresh('charts')}
                  className={classes.accordionRefreshButton}
                >
                  <span className={classes.mobileOnly}>Refresh</span>
                </Button>
              ) : undefined
            }
          >
            {isLoadingReport ? (
              <Grid direction='column' container alignItems='center' justify='center' className={classes.skeletonPadding}>
                <Grid direction='row' container spacing={4} alignItems='center' justify='space-between' item xs={12}>
                  <Grid xs={12} md={6}>
                    <Skeleton variant='rect' height={200} width='90%' />
                  </Grid>
                  <Grid xs={12} md={6}>
                    <Skeleton variant='rect' height={200} width='90%' />
                  </Grid>
                </Grid>
              </Grid>
            ) : (
              <Grid item container xs={12} alignContent='center' spacing={isMobile ? undefined : 2}>
                {isLoadingBudget && <Loader className={classes.budgetLoader} position='centered' />}
                {!sprintExpensesChartData && !accruedExpensesChartData && !isLoadingBudget && (
                  <Grid item xs={12}>
                    <Typography variant='h5' align='center'>
                      No budget information available
                    </Typography>
                  </Grid>
                )}
                {sprintExpensesChartData && (
                  <Grid
                    className={classes.chartWrapper}
                    item
                    xs={12}
                    md={laborExpenses.sprintExpenses.length > 3 ? 12 : 6}
                    lg={laborExpenses.sprintExpenses.length > 3 && laborExpenses.sprintExpenses.length < 10 ? 6 : undefined}
                  >
                    <div className={`${classes.chartContainer} ${classes.fullHeight} ${isMobile ? classes.minChartWidth : ''}`}>
                      <Typography variant='h3' align='center'>
                        Labor Expense for Current Invoices
                      </Typography>
                      <ProgressReportSprintExpensesChart data={sprintExpensesChartData} count={laborExpenses.sprintExpenses.length} />
                    </div>
                  </Grid>
                )}
                {accruedExpensesChartData && (
                  <Grid
                    className={classes.chartWrapperTwo}
                    item
                    xs={12}
                    md={laborExpenses.accruedExpenses.length > 3 ? 12 : 6}
                    lg={laborExpenses.accruedExpenses.length > 3 && laborExpenses.accruedExpenses.length < 10 ? 6 : undefined}
                  >
                    <div className={`${classes.chartContainer} ${classes.fullHeight} ${isMobile ? classes.minChartWidth : ''}`}>
                      <Typography variant='h3' align='center'>
                        Accrued Labor Expense To Date By Project
                      </Typography>
                      <ProgressReportAccruedExpensesChart data={accruedExpensesChartData} count={laborExpenses.accruedExpenses.length} />
                    </div>
                  </Grid>
                )}
              </Grid>
            )}
          </DashboardCard>
        </Grid>}

        <CreateProgressReportModal
          isOpen={isShowingCreateModal}
          handleClose={() => showModal(false)}
          clientId={selectedClient ? selectedClient.clientId : null}
        />
        <Toast
          id='reports-success'
          message={successMessage || `Report Saved!`}
          open={isSuccess}
          onClose={() => showSuccess(false)}
          variant='success'
        />
        <Toast
          id='reports-error'
          message={
            errorMessage ||
            'We were unable to save your report at this time. Please try again later. Please contact MercuryWorks support if this issue continues.'
          }
          open={isError}
          onClose={() => showError(false)}
          variant='error'
        />
      </Grid>
    </section>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    paddingTop: theme.spacing(0.125)
  },
  topButtonWrapper: {
    marginBottom: 0
  },
  divider: {
    margin: theme.spacing(0.5, 0),
    [theme.breakpoints.up('md')]: {
      margin: theme.spacing(1, 0)
    }
  },
  sprintReviewWrapper: {
    marginTop: theme.spacing(1)
  },
  sprintContent: {
    marginTop: theme.spacing(0.5),
    '&& ul': {
      padding: `0 0 0 10px !important`,
      [theme.breakpoints.up('md')]: {
        padding: `0 0 0 30px !important`
      }
    }
  },
  card: {
    boxShadow: 'none',
    borderRadius: 0,
    position: 'relative'
  },
  cardContent: {
    padding: '16px 10px',
    paddingBottom: `16px !important`,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white
  },
  fullHeight: {
    height: '100%'
  },
  chartContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    [theme.breakpoints.up('md')]: {
      minHeight: '400px',
      maxHeight: '600px'
    }
  },
  sprintReviewContent: {
    minHeight: '30rem',
    borderRight: `1px solid ${theme.palette.grey[300]}`,
    overflowY: 'scroll'
  },
  statTitle: {
    fontSize: 16,
    marginTop: '3px'
  },
  statValue: {
    fontSize: 30,
    lineHeight: 1,
    fontWeight: 700
  },
  icon: {
    color: 'inherit',
    fontSize: 70,
    backgroundColor: 'inherit',
    borderRadius: '50%',
    padding: theme.spacing(1)
  },
  loader: {
    margin: theme.spacing(2, 0)
  },
  pipe: {
    margin: '0 10px',
    color: theme.palette.grey[300],
    [theme.breakpoints.up('md')]: {
      margin: '0 8px 0 4px'
    }
  },
  buttonWrapper: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column-reverse',
    paddingBottom: '8px',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row'
    }
  },
  innerButtonWrapper: {
    display: 'flex',
    alignItems: 'center',
    margin: theme.spacing(1, 0, 0.5, 0),
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      margin: 0
    }
  },
  titleInput: {
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: 600,
      marginRight: theme.spacing(2)
    }
  },
  statsContent: {
    borderTop: 0,
    padding: theme.spacing(0.5, 0.75),
    borderBottomRightRadius: 5,
    borderBottomLeftRadius: 5
  },
  createNewReportWrapper: {
    display: 'flex',
    alignItems: 'center'
  },
  unpublishButton: {
    margin: theme.spacing(0, 0.5),
    backgroundColor: BLOCKED_COLOR,
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: darken(BLOCKED_COLOR, 0.1)
    }
  },
  publishButton: {
    margin: theme.spacing(0, 0.5)
  },
  savedDraftText: {
    marginRight: theme.spacing(0.5),
    color: theme.palette.grey[500],
    display: 'flex',
    alignItems: 'center'
  },
  bold: {
    fontWeight: 600,
    marginRight: '5px'
  },
  refresh: {
    position: 'absolute',
    right: 0,
    top: 0,
    padding: theme.spacing(0.5),
    color: theme.palette.common.white
  },
  refreshAnimation: {
    animation: '$spin 1.3s linear infinite'
  },
  '@keyframes spin': {
    from: {
      transform: 'rotate(0deg)'
    },

    to: {
      transform: 'rotate(360deg)'
    }
  },
  backToReports: {
    fontSize: '0.75rem',
    padding: 0,
    [theme.breakpoints.up('md')]: {
      fontSize: '0.8125rem',
      padding: '4px 5px'
    }
  },
  backToReportsUser: {
    fontSize: '0.75rem',
    padding: 0,
    [theme.breakpoints.up('md')]: {
      fontSize: '0.8125rem',
      padding: '4px 5px',
      marginRight: theme.spacing(0.5)
    }
  },
  createNewReport: {
    fontSize: '0.75rem',
    padding: 0,
    [theme.breakpoints.up('md')]: {
      fontSize: '0.8125rem',
      padding: '4px 5px'
    }
  },
  deleteButton: {
    color: BLOCKED_COLOR
  },
  buttons: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    [theme.breakpoints.up('md')]: {
      flex: 1,
      flexDirection: 'row',
      alignItems: 'center'
    }
  },
  loaderText: {
    fontSize: '0.8125rem'
  },
  budgetWrapper: {
    marginBottom: theme.spacing(0.5),
    [theme.breakpoints.up('md')]: {
      marginBottom: 0
    }
  },
  mobileOnly: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'inline-block'
    }
  },
  accordionRefreshButton: {
    paddingTop: 0,
    paddingBottom: 0,
    [theme.breakpoints.down('sm')]: {
      minWidth: 0,
      marginLeft: theme.spacing(0.25),
      '& .MuiButton-startIcon': {
        margin: 0
      }
    }
  },
  budgetAccordion: {
    width: '100%'
  },
  minChartWidth: {
    [theme.breakpoints.down('xs')]: {
      minWidth: theme.spacing(25),
      paddingRight: theme.spacing(0.75)
    }
  },
  userStoriesLink: {
    color: theme.palette.common.white,
    textDecoration: 'none',
    display: 'flex',
    alignItems: 'center'
  },
  previewInfo: {
    marginBottom: theme.spacing(1)
  },
  alertTitle: {
    marginBottom: 0
  },
  budgetLoader: {
    margin: theme.spacing(2, 0)
  },
  cardHolder: {
    alignItems: 'stretch'
  },
  skeletonPadding: {
    paddingTop: theme.spacing(7),
    paddingBottom: theme.spacing(3)
  },
  skeletonSpacing: {
    '@media (max-width: 960px)': {
      marginTop: 20,
      marginBottom: 20
    }
  },
  hidden: {
    '@media print': {
      display: 'none'
    }
  },
  chartWrapper: {
    '@media print': {
      height: '450px',
      overflow: 'hidden'
    }
  },
  chartWrapperTwo: {
    '@media print': {
      height: '450px',
      overflow: 'hidden',
      marginBottom: '50px'
    }
  }
}));
