import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import ReactGA from 'react-ga';
import TagManager from 'react-gtm-module';
import debounce from 'lodash/debounce';
import { withAdalLoginApi } from '../../adalConfig';
// Components
import { AppPage } from '@shared/components/layout';
import { AccessRoute } from '@shared/components/router';
import { Home } from './Home';
import { Login } from './Login';
import { LoaderWrapper, Loader } from '@shared/components/loader';
import { VersionWrapper } from '@src/context/version-wrapper';
// Build Settings
import buildSettings from '../../buildSettings.json';
// Redux
import { setUser } from '@shared/redux/actions';
// Fetch
import { getUser } from '@shared/fetch';
// Types
import { IUserProps, IAppState } from '@shared/types';
import { userAccess } from '@shared/constants';
// Containers
import Admin from '@src/admin';
import Clients from '@src/clients';
import Employees from '@src/employees';
import Settings from '@src/settings';
import { QueryClient, QueryClientProvider } from 'react-query';

declare global {
  interface Window {
    ga: any | undefined;
    gtm: any | undefined;
  }
}

const tagManagerArgs = {
  gtmId: buildSettings.googleTagManagerTrackingId
};

// Solve for address bar on mobile cutting off bottom of page
// https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
const resize = () => {
  // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
  let vh = window.innerHeight * 0.01;
  // Then we set the value in the --vh custom property to the root of the document
  document.documentElement.style.setProperty('--vh', `${vh}px`);
};
const debouncedResize = debounce(resize, 500);
const adjustViewHeight = () => {
  if (window && window.innerHeight && window.addEventListener) {
    resize();
    window.addEventListener('resize', () => {
      debouncedResize();
    });
  }
};

const renderProtectedPage = (page: React.FC) =>
  withAdalLoginApi(
    page,
    // loading state
    () => (
      <LoaderWrapper>
        <Loader position='centered' type='overlay' size='large' />
      </LoaderWrapper>
    ),
    // error, redirect to /login
    () => <Redirect to='/login' />
  );

const routes = [
  {
    path: '/admin',
    component: renderProtectedPage(Admin),
    accessTokens: [userAccess.VIEW_ADMIN]
  },
  { path: '/employees', component: renderProtectedPage(Employees), accessTokens: [userAccess.VIEW_EMPLOYEES] },
  { path: '/clients', component: renderProtectedPage(Clients), accessTokens: [userAccess.VIEW_CLIENTS] },
  { path: '/settings', component: renderProtectedPage(Settings), accessTokens: [userAccess.VIEW_EMPLOYEES] }
];

const App: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const user: IUserProps = useSelector((state: IAppState) => state.user);
  const [isUserLoading, setUserLoading] = useState<boolean>(true);

  const initialLoad = async () => {
    try {
      const userResponse = await getUser();
      dispatch(setUser(userResponse));
    } catch (error) {
      console.log(error);
    } finally {
      setUserLoading(false);
    }
  };

  const initializeGoogleAnalytics = () => {
    if (!window.ga) {
      ReactGA.initialize(buildSettings.googleAnalyticsTrackingId, {
        titleCase: false
      });
    }
  };
  const initializeGoogleTagManager = () => {
    if (!window.gtm) {
      TagManager.initialize(tagManagerArgs);
    }
  };

  useEffect(() => {
    // if the user has the FF enabled, then turn the zendesk support button on all pages
    if (user.userAccess[userAccess.CLIENT_COLLABOTARTOR_DATA] || user.userAccess[userAccess.VIEW_ADMIN]) {
      const script = document.createElement('script');
      script.id = 'ze-snippet';
      script.type = 'text/javascript';
      script.async = true;
      script.defer = true;
      script.src = `https://static.zdassets.com/ekr/snippet.js?key=47d9796e-639f-4c51-a5f2-ad4c30342a80`;
      document.body.appendChild(script);
    }
  }, [user]);

  // Create a client
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        cacheTime: 0, // no caching of api calls,
        retry: false, // no retry on failed api calls
        retryOnMount: false, // the query will not be retried on mount if it contains an error
        staleTime: Infinity, // no caching of api calls
        refetchOnMount: false, // the query will not refetch on mount.
        refetchOnWindowFocus: false // the query will not refetch on window focus.
      }
    }
  });

  useEffect(() => {
    initialLoad();
    initializeGoogleAnalytics();
    initializeGoogleTagManager();
    adjustViewHeight();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  if (isUserLoading) {
    return (
      <LoaderWrapper className={classes.loaderWrapper}>
        <Loader position='centered' type='overlay' size='large' />
      </LoaderWrapper>
    );
  }
  if (!isUserLoading && user && user.userAccess && user.userAccess.ViewSite) {
    return (
      <Router>
        <QueryClientProvider client={queryClient}>
          <VersionWrapper>
            <AppPage>
              <Switch>
                <Route exact path='/login' render={() => <Redirect to='/' />} />
                {routes.map(route => (
                  <AccessRoute key={route.path} requiredAccess={user.userAccess && route.accessTokens} userAccess={user.userAccess} {...route} />
                ))}
                <Route path='/' component={Home}>
                  {user.userAccess && user.userAccess[userAccess.CLIENT_ONLY] && <Redirect to='/clients/dashboard' />}
                </Route>
              </Switch>
            </AppPage>
          </VersionWrapper>
        </QueryClientProvider>
      </Router>
    );
  }
  return (
    <Router>
      <QueryClientProvider client={queryClient}>
        <VersionWrapper>
          <AppPage title='Login' hasSideNav={false} showUserMenu={false}>
            <Switch>
              <Route exact path='/login' component={Login} />
              <Route path='/' render={() => <Redirect to='/login' />} />
            </Switch>
          </AppPage>
        </VersionWrapper>
      </QueryClientProvider>
    </Router>
  );
};

const useStyles = makeStyles(theme => ({
  loaderWrapper: {
    height: '100vh'
  }
}));

export default App;
