import React, { FC, useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Grid, Popover, IconButton } from '@material-ui/core';
import { FilterList, Close, Add, Delete } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import { IRelease, IAppState, IClientLookup } from '@shared/types';
import { Helmet } from 'react-helmet';
// Helpers
import { formatShortFriendlyDateWithTime } from '@shared/helpers/format';
import clsx from 'clsx';
// Fetch
import { getAllReleases, deleteSoftwareRelease, getClientLookup } from '@shared/fetch';
// Components
import { StatusButton } from '../../shared/components/buttons/StatusButton';
import { DeleteReleaseModal } from '../components/modals/DeleteReleaseModal';
import { Table, ITableColumn } from '@shared/components/tables';
import { Toast } from '@shared/components/toast';
import { Pagination } from '@shared/components/pagination';
import { MobileExpanderLoader } from '@shared/components/loader';
import { SoftwareReleaseFilters } from '../components/filters';
import { DashboardCard } from '../../clients/components/DashboardCard';
import { MobileClientReleases } from '../../admin/components/mobile';
// Hooks
import { useReleases } from '@shared/hooks';
// redux
import { useSelector } from 'react-redux';

const SoftwareReleases: FC = () => {
  const classes = useStyles();
  const [{ message: PageMessage, variant: pageVariant, isOpen: pageToastIsOpen }, setPageToast] = useState<{
    message: string;
    variant: 'error' | 'success';
    isOpen: boolean;
  }>({
    message: '',
    variant: 'success',
    isOpen: false
  });

  // permissions for delete //
  const currentUserRole = useSelector((state: IAppState) => state.user);
  const { AdminData, ViewAdmin } = currentUserRole.userAccess;

  // releases //
  const [selectedClient, setSelectedClient] = useState<number | null>(null);
  const loadReleases = async ({
    page,
    perPage,
    selectedSort,
    sortDirection,
    selectedStatusIds,
    searchTitle,
    setReleases,
    setRecordCount,
    setReleasesLoading,
    handleStatuses
  }: any) => {
    try {
      setReleasesLoading(true);
      const response = await getAllReleases({
        page: page + 1,
        perPage,
        sortBy: selectedSort,
        sortDirection: sortDirection[selectedSort] as string,
        statusId: handleStatuses(selectedStatusIds),
        searchTerm: searchTitle,
        clientId: selectedClient !== null ? selectedClient : undefined
      });
      setRecordCount(response?.totalRecordCount);
      setReleases(response?.records);
      setReleasesLoading(false);
    } catch (error) {
      setPageToast({
        message: 'Error loading releases',
        variant: 'error',
        isOpen: true
      });
      setReleasesLoading(false);
    }
  };

  const {
    isDesktop,
    releases,
    releasesLoading,
    selectedStatusIds,
    setSelectedStatusIds,
    selectedStatusValues,
    setSelectedStatusValues,
    hasAppliedFilters,
    setHasAppliedFilters,
    filters,
    setFilters,
    isLoadingStatuses,
    statuses,
    searchTitle,
    setSearchTitle,
    page,
    setPage,
    recordCount,
    perPage,
    setRowsPerPage,
    selectedSort,
    setSelectedSort,
    sortDirection,
    setSortDirection,
    isFiltersModalOpen,
    toggleFiltersModal,
    releaseFiltersButtonRef,
    fetchReleaseStatuses,
    setReleases,
    setRecordCount,
    setReleasesLoading,
    handleStatuses
  } = useReleases(loadReleases);

  // delete release //

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [releaseToDelete, setReleaseToDelete] = useState<IRelease | null>(null);

  const handleDeleteClick = (release: IRelease) => {
    setReleaseToDelete(release);
    setIsDeleteModalOpen(true);
  };

  const handleCloseDeleteModal = () => {
    setIsDeleteModalOpen(false);
    setReleaseToDelete(null);
  };

  const handleConfirmDelete = async () => {
    if (releaseToDelete) {
      try {
        await deleteSoftwareRelease(releaseToDelete.releaseId);
        setIsDeleteModalOpen(false);
        setReleaseToDelete(null);
        loadReleases({
          page,
          perPage,
          selectedSort,
          sortDirection,
          selectedStatusIds,
          searchTitle,
          setReleases,
          setRecordCount,
          setReleasesLoading,
          handleStatuses
        });
      } catch (error) {
        setPageToast({
          message: 'Error deleting release',
          variant: 'error',
          isOpen: true
        });
        console.error('Error deleting release:', error);
      }
    }
  };

  const handleDelete = async (releaseId: number) => {
    try {
      await deleteSoftwareRelease(releaseId);
      loadReleases({
        page,
        perPage,
        selectedSort,
        sortDirection,
        selectedStatusIds,
        searchTitle,
        setReleases,
        setRecordCount,
        setReleasesLoading,
        handleStatuses
      });
    } catch (error) {
      setPageToast({
        message: 'Error deleting release',
        variant: 'error',
        isOpen: true
      });
      console.error('Error deleting release:', error);
    }
  };

  // Client Dropdown //

  const [clientList, setClientList] = useState<IClientLookup[]>([]);

  useEffect(() => {
    const fetchClients = async () => {
      try {
        const clientResult = await getClientLookup(true); // Pass true for isActive
        setClientList(clientResult);
      } catch (error) {
        console.error('Error fetching client lookup data:', error);
        setPageToast({
          message: 'Error fetching clients',
          variant: 'error',
          isOpen: true
        });
      }
    };

    fetchClients();
  }, []);

  // Table Functions //

  const handleClickColumn = (column: string) => {
    setSelectedSort(column);
    setSortDirection({
      ...sortDirection,
      [column]: sortDirection[column] === 'Asc' ? 'Desc' : 'Asc'
    });
  };

  const determineContainerClasses = () => {
    if (releasesLoading) {
      return classes.mobileTable;
    } else {
      return 'p-0';
    }
  };

  const containerClasses = determineContainerClasses();

  const columns = [
    {
      Header: 'Release Summary',
      accessor: 'releaseSummary',
      overrideWidth: 220,
      isServerSorted: selectedSort === 'Name',
      isServerSortedDesc: sortDirection.Name === 'Desc',
      handleClickColumn: () => handleClickColumn('Name'),
      Cell: ({
        cell: {
          row: { original }
        }
      }: {
        cell: { row: { original: IRelease } };
      }) => {
        const getTargetPath = (status: string, releaseId: number) => {
          switch (status) {
            case 'New':
              return `/employees/software-releases/${releaseId}/general-information`;
            case 'Client Approval':
              return `/employees/software-releases/${releaseId}/waiting-for-release`;
            case 'Releasing':
              return `/employees/software-releases/${releaseId}/releasing`;
            case 'Verifying':
              return `/employees/software-releases/${releaseId}/verifying`;
            case 'Complete':
              return `/employees/software-releases/${releaseId}/done`;
            default:
              return `/employees/software-releases/${releaseId}/general-information`;
          }
        };

        const targetPath = getTargetPath(original.releaseStatusName, original.releaseId);

        return (
          <Link
            className={classes.link}
            to={{
              pathname: targetPath,
              state: { release: original }
            }}
          >
            {original.releaseSummary || 'Release Summary'}
          </Link>
        );
      }
    },
    {
      Header: 'Client',
      accessor: 'clientName',
      isServerSorted: selectedSort === 'Client',
      isServerSortedDesc: sortDirection.Name === 'Desc',
      handleClickColumn: () => handleClickColumn('Client')
    },
    {
      Header: 'Application',
      accessor: 'application',
      isServerSorted: selectedSort === 'Application',
      isServerSortedDesc: sortDirection.Application === 'Desc',
      handleClickColumn: () => handleClickColumn('Application')
    },
    {
      Header: 'Status',
      sort: false,
      accessor: ({ releaseStatusName }: any) => {
        if (!releaseStatusName) {
          return;
        }
        return <StatusButton status={releaseStatusName} />;
      },
      isServerSorted: selectedSort === 'Status',
      isServerSortedDesc: sortDirection.Status === 'Desc',
      handleClickColumn: () => handleClickColumn('Status')
    },
    {
      Header: 'Selected Release Date',
      accessor: ({ releaseDate }: any) => {
        if (!releaseDate) {
          return '';
        }
        return formatShortFriendlyDateWithTime(new Date(releaseDate));
      },
      isServerSorted: selectedSort === 'ReleaseDate',
      isServerSortedDesc: sortDirection.ReleaseDate === 'Desc',
      handleClickColumn: () => handleClickColumn('ReleaseDate')
    },
    {
      Header: 'Release Ended At',
      accessor: ({ releaseEndDate }: any) => {
        if (!releaseEndDate) {
          return '';
        }
        return formatShortFriendlyDateWithTime(new Date(releaseEndDate));
      },
      isServerSorted: selectedSort === 'ReleaseEndDate',
      isServerSortedDesc: sortDirection.ReleaseEndDate === 'Desc',
      handleClickColumn: () => handleClickColumn('ReleaseEndDate')
    },
    {
      Header: 'Created On',
      accessor: ({ createdOn }: any) => {
        if (!createdOn) {
          return '';
        }
        return formatShortFriendlyDateWithTime(new Date(createdOn));
      },
      isServerSorted: selectedSort === 'CreatedOn',
      isServerSortedDesc: sortDirection.CreatedOn === 'Desc',
      handleClickColumn: () => handleClickColumn('CreatedOn')
    },
    {
      Header: '',
      accessor: 'delete',
      Cell: ({
        cell: {
          row: { original }
        }
      }: {
        cell: { row: { original: IRelease } };
      }) => {
        return AdminData && ViewAdmin ? (
          <IconButton size='small' aria-label='delete-release' className={classes.deleteButton} onClick={() => handleDeleteClick(original)}>
            <Delete />
          </IconButton>
        ) : null;
      }
    }
  ];

  return (
    <section className={classes.wrapper}>
      <Helmet>
        <title>Software Releases | My MercuryWorks</title>
      </Helmet>
      <Grid container alignItems='flex-start' justify='space-between' spacing={2} className={classes.cardHolder}>
        <Grid item lg={12}>
          <DashboardCard
            setHeight={false}
            isColumn={false}
            title={'Software Releases'}
            additionalHeaderContent={
              <Button
                color='secondary'
                variant='contained'
                startIcon={<Add />}
                component={Link}
                to='/employees/software-releases/add/general-information'
              >
                ADD NEW
              </Button>
            }
            disableJustifyContentOnMobile={true}
            actions={
              <>
                {!isDesktop && (
                  <Button
                    ref={releaseFiltersButtonRef}
                    className={classes.filtersButton}
                    color='primary'
                    variant='contained'
                    onClick={() => {
                      toggleFiltersModal();
                    }}
                    startIcon={isFiltersModalOpen ? <Close /> : <FilterList />}
                  ></Button>
                )}
              </>
            }
          >
            {isDesktop && (
              <SoftwareReleaseFilters
                isLoading={releasesLoading}
                handleTitleSearch={(clearSearch?: boolean) => {
                  setFilters({
                    ...filters,
                    title: searchTitle
                  });

                  if (clearSearch) {
                    setSearchTitle('');
                  }
                }}
                applyFilters={(clearFilters?: boolean) => {
                  if (clearFilters) {
                    setFilters({
                      status: '',
                      title: ''
                    });
                  } else {
                    setPage(0);
                    setFilters({
                      ...filters
                    });
                  }
                }}
                wrapperClass='pb-1'
                hasAppliedFilters={hasAppliedFilters}
                setHasAppliedFilters={setHasAppliedFilters}
                searchTitle={searchTitle}
                setSearchTitle={setSearchTitle}
                setSelectedStatusIds={setSelectedStatusIds}
                statuses={statuses}
                selectedStatusValues={selectedStatusValues}
                setSelectedStatusValues={setSelectedStatusValues}
                fetchReleaseStatuses={fetchReleaseStatuses}
                isLoadingStatuses={isLoadingStatuses}
                clients={clientList}
                selectedClient={selectedClient}
                setSelectedClient={setSelectedClient}
              />
            )}
            <Table
              reducedPaddingHeader={true}
              alternatingRowColor
              data={releases}
              columns={columns as ITableColumn[]}
              stickyHeader={true}
              expandToFit
              ResponsiveComponent={props => <MobileClientReleases {...props} handleDelete={handleDelete} />}
              isLoading={releasesLoading || isLoadingStatuses}
              ResponsiveComponentLoader={MobileExpanderLoader}
              containerClasses={clsx(containerClasses)}
              hidePagination
              isRequests
            />
            {!releasesLoading && (
              <Pagination page={page} count={recordCount} rowsPerPage={perPage} setPage={setPage} setRowsPerPage={setRowsPerPage} />
            )}
            <Popover
              open={isFiltersModalOpen}
              anchorEl={releaseFiltersButtonRef.current}
              onClose={toggleFiltersModal}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right'
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right'
              }}
              className='mt-1'
            >
              <div className={classes.popoverStyles}>
                <SoftwareReleaseFilters
                  hasTitle={true}
                  isLoading={releasesLoading}
                  handleTitleSearch={(clearSearch?: boolean) => {
                    setFilters({
                      ...filters,
                      title: searchTitle
                    });

                    if (clearSearch) {
                      setSearchTitle('');
                    }
                  }}
                  applyFilters={(clearFilters?: boolean) => {
                    if (clearFilters) {
                      setFilters({
                        status: '',
                        title: ''
                      });
                    } else {
                      setPage(0);
                      setFilters({
                        ...filters
                      });
                    }
                  }}
                  hasAppliedFilters={hasAppliedFilters}
                  setHasAppliedFilters={setHasAppliedFilters}
                  searchTitle={searchTitle}
                  setSearchTitle={setSearchTitle}
                  setSelectedStatusIds={setSelectedStatusIds}
                  statuses={statuses}
                  selectedStatusValues={selectedStatusValues}
                  setSelectedStatusValues={setSelectedStatusValues}
                  fetchReleaseStatuses={fetchReleaseStatuses}
                  isLoadingStatuses={isLoadingStatuses}
                  clients={clientList}
                  selectedClient={selectedClient}
                  setSelectedClient={setSelectedClient}
                />
              </div>
            </Popover>
          </DashboardCard>
        </Grid>
        <DeleteReleaseModal
          isDeleteModalOpen={isDeleteModalOpen}
          handleCloseDeleteModal={handleCloseDeleteModal}
          handleConfirmDelete={handleConfirmDelete}
          releaseTitle={releaseToDelete ? releaseToDelete.releaseSummary : ''}
        />
        <Toast
          id='page-toast'
          message={PageMessage}
          open={pageToastIsOpen}
          onClose={() =>
            setPageToast({
              message: '',
              variant: pageVariant,
              isOpen: false
            })
          }
          variant={pageVariant}
        />
      </Grid>
    </section>
  );
};

const useStyles = makeStyles(theme => ({
  wrapper: {
    paddingTop: theme.spacing(0.125)
  },
  cardHolder: {
    alignItems: 'stretch'
  },
  mobileTable: {
    padding: 0,
    '@media (max-width: 1100px)': {
      minWidth: '90vw',
      minHeight: '400px'
    },
    '@media (max-width: 805px)': {
      minWidth: '87vw',
      minHeight: '400px'
    }
  },
  filtersButton: {
    borderRadius: '50%',
    width: theme.spacing(2),
    height: theme.spacing(2),
    minWidth: 0,
    padding: 0,
    marginLeft: '8px',

    '& .MuiButton-startIcon': {
      margin: 0
    },
    '& .MuiButton-label': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center'
    }
  },
  popoverStyles: {
    padding: theme.spacing(1.5),
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[5],
    width: '425px',
    '@media (max-width: 450px)': {
      maxWidth: '95vw'
    }
  },
  linkButton: {
    textTransform: 'none',
    textDecoration: 'underline',
    '&:hover': {
      textDecoration: 'underline',
      backgroundColor: 'transparent'
    }
  },
  link: {
    textDecoration: 'none',
    cursor: 'pointer',
    fontSize: theme.spacing(1),
    color: theme.palette.primary.dark,
    '&:hover': {
      color: theme.palette.primary.light,
      textDecoration: 'none'
    }
  },
  deleteButton: {
    color: theme.palette.error.main
  }
}));

export default SoftwareReleases;
