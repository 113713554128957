import React, { FC } from 'react';
// Components
import { Button, Typography } from '@material-ui/core';
import { Modal } from '@shared/components/modals/Modal';

interface IDeleteReleaseModal {
  isDeleteModalOpen: boolean;
  handleCloseDeleteModal: () => void;
  handleConfirmDelete: () => void;
  releaseTitle: string;
}

export const DeleteReleaseModal: FC<IDeleteReleaseModal> = ({ isDeleteModalOpen, handleCloseDeleteModal, handleConfirmDelete, releaseTitle }) => {
  return (
    <>
      <Modal
        open={isDeleteModalOpen}
        maxWidth='sm'
        onClose={handleCloseDeleteModal}
        title='Delete Release'
        actions={
          <>
            <Button onClick={handleConfirmDelete} variant='contained' color='primary'>
              Delete
            </Button>
            <Button onClick={handleCloseDeleteModal} variant='contained' color='default'>
              Cancel
            </Button>
          </>
        }
      >
        <Typography variant={'subtitle2'}>Are you sure you want to delete the release "{releaseTitle}"?</Typography>
      </Modal>
    </>
  );
};
