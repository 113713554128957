import React, { FC, Dispatch, SetStateAction } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
// Components
import { FormLabel, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails, Typography, Button } from '@material-ui/core';
import { ExpandMore, Edit } from '@material-ui/icons';
// Types
import { IExtranetUser } from '@shared/types';
import { FormikHandlers, FieldMetaProps } from 'formik';

interface IMobileUser extends FormikHandlers, FieldMetaProps<any> {
  original: IExtranetUser;
  setEditing: Dispatch<SetStateAction<IExtranetUser | {} | null>>;
  handleEdit: (val: IExtranetUser) => void;
}

export const MobileUser: FC<IMobileUser> = ({ original, handleEdit }) => {
  const classes = useStyles();
  const { firstName, lastName, email, isEnabled, role, clientName, displayName } = original;
  return (
    <ExpansionPanel className={classes.root}>
      <ExpansionPanelSummary className={classes.expansionPanel} expandIcon={<ExpandMore />} aria-controls='panel1a-content' id='panel1a-header'>
        <div className={classes.panelSummaryWrapper}>
          <Typography component='span'>{displayName}</Typography>
        </div>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails className={classes.details}>
        <FormLabel component='h2'>First Name</FormLabel>
        <Typography component='span'>{firstName || '--'}</Typography>

        <FormLabel component='h2'>Last Name</FormLabel>
        <Typography component='span'>{lastName || '--'}</Typography>

        <FormLabel component='h2'>Email</FormLabel>
        <Typography component='span'>{email || '--'}</Typography>

        <FormLabel component='h2'>Role</FormLabel>
        <Typography component='span'>{role || '--'}</Typography>

        <FormLabel component='h2'>Client</FormLabel>
        <Typography component='span'>{clientName || '--'}</Typography>

        <FormLabel component='h2'>Status</FormLabel>
        <Typography component='span'>{isEnabled ? 'Active' : 'Inactive'}</Typography>
        <Button color='primary' startIcon={<Edit />} onClick={() => handleEdit(original)}>
          Edit
        </Button>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    maxWidth: '100%'
  },
  expansionPanel: {
    padding: '0px 24px'
  },
  panelSummaryWrapper: {
    display: 'flex',
    alignItems: 'flex-start',
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  details: {
    display: 'flex',
    flexDirection: 'column'
  }
}));
