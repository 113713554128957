import { useEffect, useState } from 'react';

interface IWindowSizeHookResponse {
  width: number | undefined;
  height: number | undefined;
}

type WindowSizeHook = () => IWindowSizeHookResponse;

export const useOnWindowSize: WindowSizeHook = () => {
  function getSize() {
    return {
      width: window ? window.innerWidth : undefined,
      height: window ? window.innerHeight : undefined
    };
  }

  const [windowSize, setWindowSize] = useState(getSize);

  useEffect(() => {
    function handleResize() {
      setWindowSize(getSize());
    }
    // Add listener
    window.addEventListener('resize', handleResize);

    // Call handler right away so state gets updated with initial window size
    handleResize();

    // Remove listener on cleanup
    return () => window.removeEventListener('resize', handleResize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return windowSize;
};
