import React, { FC, useEffect, ReactNode, useContext } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import isFunction from 'lodash/isFunction';
import { useMedia } from 'react-use';
import clsx from 'clsx';
// Components`
import {
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
  Grid,
  Table as MaUTable,
  Collapse,
  Box,
  Tooltip
} from '@material-ui/core';
import { useGlobalFilter, usePagination, useRowSelect, useSortBy, useTable, Column, Row, useExpanded } from 'react-table';
import Skeleton from '@material-ui/lab/Skeleton';
import { Pagination } from '@shared/components/pagination';
// Types
import { ITableSummaryData } from '@shared/types';
import { CardContext } from '@src/context/card';

// @ts-ignore
export interface ITableColumn extends Column {
  id?: string;
  Header: string;
  className?: string;
  accessor?: (() => string | number) | ((item: unknown) => string | number) | string;
  canFilter?: boolean;
  sort?: boolean;
  isServerSorted?: boolean;
  isServerSortedDesc?: boolean;
  handleClickColumn?: (columnId: any) => void;
  hideLoad?: boolean;
  overrideWidth?: number;
  filterType?: 'input' | 'autocomplete';
  isDate?: boolean;
  isNumber?: boolean;
  isRate?: boolean;
  options?: { label: string; value: any}[];
  headerFontSize?: string;
}

interface ITableProps {
  data: any[];
  columns: ITableColumn[];
  noResultsText?: string;
  isLoading?: boolean;
  rowOnClick?: (val: any) => void;
  hideDeleted?: boolean;
  cellClasses?: string | ((original: any) => string | undefined);
  rowClasses?: string | ((original: any) => string | undefined);
  useTableProps?: { [key: string]: any };
  hidePagination?: boolean;
  stickyHeader?: boolean;
  expandToFit?: boolean;
  ResponsiveComponent?: FC<any>;
  ResponsiveComponentLoader?: FC<any>;
  containerClasses?: string;
  tableSize?: 'medium' | 'small' | undefined;
  onRowsPerPageChange?: (rows: number) => void;
  LoadMoreComponent?: FC<any>;
  mobileProps?: any;
  totalBillableHours?: number;
  totalNonBillableHours?: number;
  summaryData?: ITableSummaryData;
  renderSubComponent?: (row: any) => ReactNode;
  resetPageOnChange?: boolean;
  maxHeight?: number;
  autoReset?: boolean;
  alternatingRowColor?: boolean;
  isRequests?: boolean;
  reducedPaddingHeader?: boolean;
}

export const Table: FC<ITableProps> = ({
  columns,
  data,
  isLoading,
  noResultsText = 'No Results',
  hideDeleted,
  cellClasses = '',
  rowClasses,
  useTableProps = {},
  hidePagination,
  stickyHeader = false,
  expandToFit = false,
  rowOnClick,
  ResponsiveComponent,
  containerClasses = '',
  ResponsiveComponentLoader,
  tableSize = 'medium',
  onRowsPerPageChange,
  LoadMoreComponent,
  mobileProps,
  totalBillableHours,
  totalNonBillableHours,
  summaryData,
  renderSubComponent,
  resetPageOnChange = true,
  autoReset = true,
  alternatingRowColor = false,
  isRequests = false,
  reducedPaddingHeader = false
}) => {
  const {
    getTableProps,
    headerGroups,
    prepareRow,
    page,
    gotoPage,
    setPageSize,
    state: { pageIndex, pageSize }
  } = useTable(
    {
      columns,
      data,
      userPageCount: 1, // ignored if manualPagination is false
      manualPagination: hidePagination,
      autoResetPage: isLoading && autoReset, // reset page when loading
      autoResetSortBy: isLoading && autoReset, // reset sort when loading
      ...useTableProps
    },
    useGlobalFilter,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect
  );

  const handleChangePage = (page: number) => {
    gotoPage(page);
  };

  const handleChangeRowsPerPage = (value: number) => {
    setPageSize(value);
    if (onRowsPerPageChange) {
      onRowsPerPageChange(value);
    }
  };

  // update page size if we hide pagination
  useEffect(() => {
    if (hidePagination && Array.isArray(data)) {
      setPageSize(Number(data.length));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);
  // always start on first page if data length changes, ie; filter was applied
  useEffect(() => {
    // We need the ceiling of the division of total records and page size to get the number of pages
    // We subtract one since we are using indexes and not page numbers for paging
    const numberOfPages = Math.ceil(data.length / pageSize) - 1;
    // reset if the size of the array changes
    if (resetPageOnChange) {
      handleChangePage(0);
      // if the page is past the new page limit, put them on the last page
    } else if (numberOfPages < pageIndex) {
      handleChangePage(numberOfPages);
    }
    // Don't do anything the page if resetPageOnChange is false
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.length]);

  const { cardContentHeight } = useContext(CardContext);

  const classes = useStyles({ cardContentHeight, expandToFit, alternatingRowColor, isRequests });
  const isDesktop = useMedia(isRequests ? '(min-width: 1100px)' : '(min-width: 960px)');

  const getCellStyle = (column: ITableColumn, type: 'header' | 'cell') => {
    let style: any = { cursor: 'inherit' };

    if (column.Header === 'Ticket#') {
      style = {
        ...style,
        paddingRight: 0,
        marginRight: 0
      };
    }

    if (column.isDate) {
      style = {
        ...style,
        textAlign: 'center'
      };
    } else if (column.isNumber) {
      style = {
        ...style,
        textAlign: 'right',
        paddingRight: '2rem'
      };
    } else if (column.isRate) {
      style = {
        ...style,
        textAlign: 'right'
      };
    }

    if (column.overrideWidth) {
      style = {
        ...style,
        width: column.overrideWidth,
        maxWidth: column.overrideWidth,
        wordWrap: 'break-word', // IE11
        overflowWrap: 'break-word'
      };
    }

    if (type === 'header') {
      style = {
        ...style,
        fontWeight: 'bold',
        fontSize: column.headerFontSize,
        cursor: column.handleClickColumn ? 'pointer' : 'inherit'
      };
    }

    return style;
  };

  return (
    <>
      <TableContainer className={clsx(containerClasses, stickyHeader ? classes.stickyHeader : '')}>
        <MaUTable data-testid='table' stickyHeader={stickyHeader} size={tableSize} {...getTableProps()}>
          {isDesktop || !ResponsiveComponent ? (
            <>
              <TableHead>
                {headerGroups.map(headerGroup => (
                  <TableRow {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map(column => (
                      <TableCell
                        data-testid={`table-header-cell`}
                        {...((column as ITableColumn).sort !== false
                          ? column.getHeaderProps(column.getSortByToggleProps())
                          : column.getHeaderProps())}
                        title=''
                        className={clsx((column as ITableColumn).className, classes.nonWrappingHeader, { [classes.reducedPaddingHeader]: reducedPaddingHeader })} 
                        style={getCellStyle(column as ITableColumn, 'header')}
                        onClick={e => {
                          const { handleClickColumn, sort } = column as ITableColumn;
                          const sortProps: any = column.getHeaderProps(column.getSortByToggleProps());

                          // If the column is sortable and there's an onClick handler, then call it
                          const { onClick } = sortProps || {};
                          sort !== false && onClick && onClick(e);
                          handleChangePage(0);
                          // Also run column click handler if passed
                          handleClickColumn && handleClickColumn(column.id);
                        }}
                      >
                        {(column as ITableColumn).sort !== false || (column as ITableColumn).isServerSorted ? (
                          <TableSortLabel
                            active={column.isSorted || (column as ITableColumn).isServerSorted}
                            direction={column.isSortedDesc || (column as ITableColumn).isServerSortedDesc ? 'desc' : 'asc'}
                          >
                            {column.render('Header')}
                          </TableSortLabel>
                        ) : (
                          column.render('Header')
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableHead>
              <TableBody>
                {isLoading
                  ? new Array(pageSize || 1).fill('').map((x, j) => (
                      <TableRow key={`table-row-skeleton-${j}`} {...(rowOnClick ? { onClick: rowOnClick } : {})}>
                        {columns.map((column, i) => (
                          <TableCell
                            key={`skeleton-cell-${i}`}
                            style={getCellStyle(column as ITableColumn, 'cell')}
                            className={isFunction(cellClasses) ? cellClasses({}) : cellClasses}
                          >
                            {column.hideLoad ? null : <Skeleton />}
                          </TableCell>
                        ))}
                      </TableRow>
                    ))
                  : page.map((row: Row, i) => {
                      prepareRow(row);
                      return !hideDeleted || (hideDeleted && !(row.original as any).isDeleted) ? (
                        <React.Fragment key={row.id}>
                          <TableRow
                            {...row.getRowProps()}
                            className={clsx(isFunction(rowClasses) ? rowClasses(row.original) : rowClasses, classes.alternatingRow)}
                            {...(rowOnClick ? { onClick: () => rowOnClick(row.original) } : {})}
                          >
                            {row.cells.map((cell, j) => {
                              const cellContent = cell.render('Cell');
                              return (
                                <TableCell
                                  {...cell.getCellProps()}
                                  className={clsx(
                                    (cell.column as ITableColumn).className ?? '',
                                    isFunction(cellClasses) ? cellClasses(row.original) : cellClasses,
                                    cell.column.Header === 'Title' || cell.column.Header === 'Service Type' ? classes.ellipsis : ''
                                  )}
                                  style={getCellStyle(cell.column as ITableColumn, 'cell')}
                                >
                                  {cell.column.Header === 'Title' || cell.column.Header === 'Service Type' ? (
                                    <Tooltip title={cellContent} classes={{ tooltip: classes.tooltipText }}>
                                      <span>{cellContent}</span>
                                    </Tooltip>
                                  ) : (
                                    cellContent
                                  )}
                                </TableCell>
                              );
                            })}
                          </TableRow>
                          {renderSubComponent ? (
                            <TableRow className={isFunction(rowClasses) ? rowClasses(row.original) : rowClasses}>
                              <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                                <Collapse in={row.isExpanded} timeout='auto' unmountOnExit>
                                  <Box margin={0}>{renderSubComponent({ row })}</Box>
                                </Collapse>
                              </TableCell>
                            </TableRow>
                          ) : null}
                        </React.Fragment>
                      ) : null;
                    })}
              </TableBody>
            </>
          ) : (
            <TableBody>
              {isLoading ? (
                <TableRow>
                  <TableCell className={clsx(classes.mobileCell, isFunction(cellClasses) ? cellClasses({}) : cellClasses)}>
                    {ResponsiveComponentLoader ? <ResponsiveComponentLoader /> : <Skeleton />}
                  </TableCell>
                </TableRow>
              ) : (
                page.map((row, i) => {
                  prepareRow(row);
                  return !hideDeleted || (hideDeleted && !(row.original as any).isDeleted) ? (
                    <TableRow
                      {...row.getRowProps()}
                      className={clsx(isFunction(rowClasses) ? rowClasses(row.original) : rowClasses, classes.alternatingRow)}
                      {...(rowOnClick ? { onClick: () => rowOnClick(row.original) } : {})}
                    >
                      <TableCell
                        key={`row-${i}-mobile-cell`}
                        className={clsx(classes.mobileCell, isFunction(cellClasses) ? cellClasses(row.original) : cellClasses)}
                      >
                        <ResponsiveComponent key={`responsive-row-${i}`} {...row} {...(useTableProps ? useTableProps : {})} {...mobileProps} />
                      </TableCell>
                    </TableRow>
                  ) : null;
                })
              )}
            </TableBody>
          )}
        </MaUTable>
        {!isLoading && Array.isArray(data) && data.length > 0 && !hidePagination && (
          <Pagination
            rowsPerPageOptions={[5, 10, 25, { label: 'All', value: data.length }]}
            count={isLoading ? 0 : data.length}
            rowsPerPage={pageSize}
            page={pageIndex}
            setPage={handleChangePage}
            setRowsPerPage={handleChangeRowsPerPage}
            summaryData={summaryData}
          />
        )}
        {!isLoading && Array.isArray(data) && data.length === 0 && (
          <Grid container justify='center'>
            <Typography className={classes.noResults} variant='body1' gutterBottom>
              {noResultsText}
            </Typography>
          </Grid>
        )}

        {LoadMoreComponent && <LoadMoreComponent />}
      </TableContainer>
    </>
  );
};

const useStyles = makeStyles<Theme, { cardContentHeight: number; expandToFit: boolean; alternatingRowColor: boolean; isRequests: boolean }>(
  (theme: Theme) => ({
    noResults: {
      marginTop: '8rem',
      marginBottom: '8rem',
      '@media (max-width: 1100px)': {
        minWidth: '88vw',
        minHeight: '100px',
        textAlign: 'center'
      },
      '@media (max-width: 750px)': {
        minWidth: '80vw',
        minHeight: '100px',
        textAlign: 'center'
      }
    },
    stickyHeader: {
      flexGrow: 1,
      maxHeight: ({ cardContentHeight, expandToFit }) =>
        expandToFit ? 'none' : cardContentHeight > 0 ? `${cardContentHeight}px` : `calc(100vh - 375px)`,
      [theme.breakpoints.down('sm')]: {
        maxHeight: `100%!important`
      }
    },
    reducedPaddingHeader: {
      paddingBottom: '0.5rem',
    },
    borderNone: {
      border: 'none'
    },
    mobileCell: {
      padding: theme.spacing(0.25),
      border: 0
    },
    nonWrappingHeader: {
      whiteSpace: 'nowrap'
    },
    alternatingRow: props => ({
      '&:nth-child(odd)': {
        '@media (min-width: 1100px)': {
          backgroundColor: props.alternatingRowColor ? '#f1f5f9' : ''
        }
      }
    }),
    ellipsis: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      maxWidth: '325px'
    },
    tooltipText: {
      color: '#fff',
      '& a': {
        textDecoration: 'none',
        color: '#fff'
      }
    },
    formType: {
      maxWidth: '125px',
      whiteSpace: 'nowrap'
    }
  })
);
