import React, { FC, useState, useEffect, useMemo, useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
// Components
import { Table, TableToolbar, ITableColumn } from '@shared/components/tables';
import { Button, TextField, IconButton, FormControl } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { LockOpen, KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons';
import { LoaderOverlay } from '@shared/components/loader';
import { Alert } from '@shared/components/alerts';
import { DateRangePicker, DateRange } from '@shared/components/inputs';
import { Page } from '@shared/components/layout';
import { ConfirmationDialogue } from '@shared/components/modals';
// Types
import { IClientInfo, IProjectSummaryItem } from '@shared/types';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
// Fetch
import { getClients, getProjectSummary, updateInvoicedTimeEntries } from '@shared/fetch';
// Helpers
import { formatMoney } from '@shared/helpers';

export const InvoiceLock: FC = () => {
  const classes = useStyles();

  // component state
  const [hasRefreshed, setHasRefreshed] = useState<boolean>(false);
  const [isPageLoading, setIsPageLoading] = useState<boolean>(false);
  const [isTableLoading, setIsTableLoading] = useState<boolean>(false);
  const [loadErrorOpen, setLoadErrorOpen] = useState<boolean>(false);
  const [clients, setClients] = useState<IClientInfo[]>([]);
  const [data, setData] = useState<IProjectSummaryItem[]>([]);
  const [totalBillable, setTotalBillable] = useState<number | null>(null);
  const [totalNonBillable, setTotalNonBillable] = useState<number | null>(null);
  const [total, setTotal] = useState<number | null>(null);

  // clients
  const [selectedClient, setSelectedClient] = useState<number | undefined>(undefined);

  // date ranges
  const [dateRange, setDateRange] = useState<DateRange>();

  const initialLoad = async () => {
    setIsPageLoading(true);

    try {
      const clientsResponse = await getClients();
      setClients(clientsResponse);

      setIsPageLoading(false);
    } catch (error) {
      setIsPageLoading(false);
      console.error(error);
      setLoadErrorOpen(true);
    }
  };

  useEffect(() => {
    initialLoad();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const refresh = async (currentStartDate: MaterialUiPickersDate, currentEndDate: MaterialUiPickersDate, selectedClient?: number) => {
    try {
      if (currentStartDate && currentEndDate) {
        setIsTableLoading(true);
        setHasRefreshed(true);
        const response = await getProjectSummary({
          BeginDate: currentStartDate.toISOString().substring(0, 10),
          EndDate: currentEndDate.toISOString().substring(0, 10),
          ClientId: selectedClient && selectedClient
        });
        setData(response.projectSummaries);
        setTotalBillable(response.totalBillable);
        setTotalNonBillable(response.totalNonBillable);
        setTotal(response.totalAmount);
        setIsTableLoading(false);
      }
    } catch (error) {
      setIsTableLoading(false);
      console.error(error);
      setLoadErrorOpen(true);
    }
  };

  // fetch data on filter changes
  useEffect(() => {
    if (dateRange && dateRange.begin && dateRange.end) {
      refresh(dateRange.begin, dateRange.end, selectedClient);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateRange, selectedClient]);

  const columns = useMemo(() => {
    return [
      {
        Header: () => null,
        id: 'expander',
        overrideWidth: 35,
        Cell: ({ cell: { row } }: any) => (
          <span {...row.getToggleRowExpandedProps()}>
            <IconButton className={classes.iconButton}>{row.isExpanded ? <KeyboardArrowUp /> : <KeyboardArrowDown />}</IconButton>
          </span>
        )
      },
      {
        Header: 'Client',
        accessor: 'clientName',
        canFilter: false
      }
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedClient, dateRange]);

  // save and save error functionality
  const [isConfirmShowing, setIsConfirmShowing] = useState<boolean>(false);
  const [errorOpen, setErrorOpen] = useState(false);
  const [successOpen, setSuccessOpen] = useState(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const handleSave = async () => {
    try {
      setIsSaving(true);
      setIsConfirmShowing(false);

      let beginDate: MaterialUiPickersDate | null = null;
      let endDate: MaterialUiPickersDate | null = null;

      if (dateRange) {
        beginDate = dateRange.begin;
        endDate = dateRange.end;
      }

      if (beginDate && endDate) {
        await updateInvoicedTimeEntries(
          beginDate.toISOString().substring(0, 10),
          endDate.toISOString().substring(0, 10),
          selectedClient && selectedClient
        );
      }

      setSuccessOpen(true);
      setIsSaving(false);
    } catch (error) {
      setIsConfirmShowing(false);
      setErrorOpen(true);
      setIsSaving(false);
    }
  };

  const renderSubComponent = useCallback(({ row }) => {
    return (
      <Table
        columns={[
          {
            Header: 'Project Name',
            canFilter: false,
            accessor: 'projectName'
          },
          {
            Header: 'Billable',
            canFilter: false,
            id: 'billable',
            accessor: (d: any) => (d.billable ? formatMoney(d.billable) : ''),
            className: classes.rightAlign,
            overrideWidth: 175
          },
          {
            Header: 'Non-Billable',
            id: 'nonBillable',
            canFilter: false,
            accessor: (d: any) => (d.nonBillable ? formatMoney(d.nonBillable) : ''),
            className: classes.rightAlign,
            overrideWidth: 175
          },
          {
            Header: 'Total',
            id: 'total',
            canFilter: false,
            accessor: (d: any) => (d.total ? formatMoney(d.total) : ''),
            className: classes.rightAlign,
            overrideWidth: 300
          }
        ]}
        data={row.original.clientProjectSummaries}
        isLoading={isTableLoading}
        noResultsText='No Results for this client'
        hidePagination
      />
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getClientName = () => {
    const client = clients.find(c => c.clientId === selectedClient);

    if (client) {
      return client.name;
    }
  };

  return (
    <Page
      setHeight={false}
      flexGrow={false}
      title='Invoice Lock'
      footerSpacing={64}
      actions={() => (
        <Button
          disabled={isPageLoading || isTableLoading || !dateRange}
          aria-label='refresh'
          color='primary'
          onClick={() => setIsConfirmShowing(true)}
          startIcon={<LockOpen />}
          className={classes.right}
        >
          Lock Time Entries
        </Button>
      )}
    >
      <TableToolbar filteredData={data} columns={columns as ITableColumn[]}>
        <div className={classes.filterRow}>
          <DateRangePicker
            className={classes.formControl}
            inputVariant='outlined'
            size='small'
            autoOk
            label='Dates'
            value={dateRange}
            onChange={values => setDateRange(values)}
            disabled={isPageLoading}
            format='M/d/yyyy'
          />
          <FormControl fullWidth className={`${classes.clientFilter} ${classes.formControl}`}>
            <Autocomplete
              id='autocomplete-filter-client'
              options={clients.map(x => ({ key: x.clientId, value: x.clientId, label: x.name }))}
              getOptionLabel={option => option.label}
              onChange={(e: any, selected: any) => {
                if (selected) {
                  setSelectedClient(selected.value);
                } else {
                  setSelectedClient(undefined);
                }
              }}
              renderInput={params => (
                <TextField
                  {...params}
                  variant='outlined'
                  size='small'
                  label='Client'
                  InputLabelProps={{ id: 'filter-client-label', htmlFor: 'autocomplete-filter-client' }}
                />
              )}
            />
          </FormControl>
        </div>
      </TableToolbar>
      <Table
        stickyHeader
        expandToFit
        columns={columns as ITableColumn[]}
        data={data}
        isLoading={isTableLoading}
        noResultsText={hasRefreshed ? 'No Results' : 'Please select a date range'}
        summaryData={{
          entries: [
            {
              key: 'Billable',
              value: formatMoney(totalBillable)
            },
            {
              key: 'Non-Billable',
              value: formatMoney(totalNonBillable)
            },
            {
              key: 'Total',
              value: formatMoney(total)
            }
          ]
        }}
        renderSubComponent={renderSubComponent}
      />
      <LoaderOverlay open={isSaving} />
      <Alert open={errorOpen} onClose={() => setErrorOpen(false)} type='error' text='Problem saving. Please try again!' />
      <Alert open={loadErrorOpen} onClose={() => setLoadErrorOpen(false)} type='error' text='Problem loading data. Please try again!' />
      <Alert open={successOpen} onClose={() => setSuccessOpen(false)} type='success' text='Save Success!' />
      <ConfirmationDialogue
        id='lock-time-entries'
        open={isConfirmShowing}
        title='Lock Time Entries'
        text={`Are you sure you want to lock time entries for the selected time period${selectedClient ? ` for ${getClientName()}?` : '?'}`}
        onClose={() => setIsConfirmShowing(false)}
        onConfirm={() => handleSave()}
      />
    </Page>
  );
};

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(0, 1, 1, 0),
    minWidth: 250
  },
  active: {
    color: theme.palette.primary.main
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  },
  additionalInfo: {
    padding: 0
  },
  right: {
    marginLeft: 'auto'
  },
  overflowNone: {
    overflowX: 'hidden'
  },
  sprintSelect: {
    width: '100%',
    margin: theme.spacing(0.5, 0),
    [theme.breakpoints.up('md')]: {
      width: 300,
      margin: 0,
      marginRight: theme.spacing(1)
    }
  },
  clientSelect: {
    width: '100%',
    margin: theme.spacing(0.5, 0),
    [theme.breakpoints.up('md')]: {
      width: 300,
      margin: 0,
      marginRight: theme.spacing(1)
    }
  },
  inputLoader: {
    marginRight: theme.spacing(1),
    marginTop: 4
  },
  rightAlign: {
    textAlign: 'right'
  },
  iconButton: {
    padding: 0,
    fontSize: '.75rem'
  },
  filterRow: {
    display: 'flex',
    alignItems: 'center'
  },
  clientFilter: {
    minWidth: '20rem'
  }
}));
