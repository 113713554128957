import React, { FC } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import ReactGA from 'react-ga';
import { useSelector } from 'react-redux';
// components
import Button from '@material-ui/core/Button';
import FilterNone from '@material-ui/icons/FilterNone';
import Launch from '@material-ui/icons/Launch';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
// constants
import { BLOCKED_COLOR, EPIC_COLOR, FEATURE_COLOR, STORY_COLOR, BUG_COLOR, DEFAULT_COLOR } from '@shared/constants';
// helpers
import { formatHours } from '@shared/helpers';
// types
import { WorkItemStatusLabels, WorkItemTypeLabels, IAppState } from '@shared/types';

interface IUserStoryKanbanCardProps {
  count?: number;
  effort?: number;
  id: number;
  onClick: (id: number) => void;
  remainingWork?: number;
  sprint?: string;
  state: WorkItemStatusLabels;
  title: string;
  workItemType: WorkItemTypeLabels;
}

/**
 * Card component for user story kanban view. NOTE: If you update styling here, you may need to update styling in the <CardLoader /> component.
 * @param props IUserStoryKanbanCardProps
 */
export const UserStoryKanbanCard: FC<IUserStoryKanbanCardProps> = props => {
  const { count, effort, id, onClick, remainingWork, sprint, state, title, workItemType } = props;

  const classes = UserStoryKanbanCardStyles({ state, workItemType });
  const { selectedClient } = useSelector((state: IAppState) => state.extranet);

  const text = workItemType === 'Product Backlog Item' ? 'Story' : workItemType;
  const tooltip = `Open ${text} details`;
  const gaActionValue: any = workItemType === 'Bug' || workItemType === 'Product Backlog Item' ? 'Story' : workItemType;
  const gaLabelSprintValue: string = sprint ? `Selected Sprint: ${sprint} | ` : '';
  const gaLabelClientValue: string = selectedClient ? ` | Client: ${selectedClient?.name}` : '';

  const handleInteraction = () => {
    ReactGA.event({
      category: 'User Stories',
      action: `View ${gaActionValue} Details`,
      value: id,
      label: `View Details: ${gaLabelSprintValue}${gaActionValue} ID: ${id}${gaLabelClientValue}`
    });
    onClick(id);
  };
  return (
    <Tooltip arrow={true} placement='top' title={tooltip}>
      <article
        className={classes.card}
        data-testid={`${id}`}
        id={`${id}`}
        onClick={() => {
          handleInteraction();
        }}
        onKeyDown={e => {
          if (e.key === 'Enter') {
            handleInteraction();
          }
        }}
        tabIndex={0}
      >
        <header className={classes.header}>
          <div className={classes.action}>
            <Button aria-label={tooltip} className={classes.button} type='button'>
              {workItemType === 'Bug' || workItemType === 'Product Backlog Item' ? (
                <Launch className={classes.iconStory} />
              ) : (
                <FilterNone className={classes.icon} />
              )}
              {typeof count === 'number' && (
                <Typography className={classes.count} variant='body1'>
                  {count || 0}
                </Typography>
              )}
            </Button>
          </div>
          <div className={classes.details}>
            <Typography className={classes.workItemType} variant='body1'>
              {text} {id}
            </Typography>
            {sprint && (
              <Typography className={classes.sprint} variant='body1'>
                {sprint}
              </Typography>
            )}
          </div>
        </header>
        <main className={classes.main}>
          <Typography className={classes.title} variant='h3'>
            {title}
          </Typography>
        </main>
        <footer className={classes.footer}>
          <Grid container spacing={1}>
            <Grid item xs={5}>
              <Typography className={classes.effort} variant='body1'>
                Total Effort: {typeof effort === 'number' && effort > 0 ? formatHours(effort) : `0 hrs`}
              </Typography>
            </Grid>
            <Grid item xs={7}>
              <Typography className={classes.effort} variant='body1'>
                Remaining Effort: {typeof remainingWork === 'number' && remainingWork > 0 ? formatHours(remainingWork) : `0 hrs`}
              </Typography>
            </Grid>
          </Grid>
          {state && state === 'Blocked' && (
            <Typography className={classes.state} variant='body1'>
              {state}
            </Typography>
          )}
        </footer>
      </article>
    </Tooltip>
  );
};

interface IUserStoryKanbanCardThemeProps {
  state: WorkItemStatusLabels;
  workItemType: WorkItemTypeLabels;
}

const UserStoryKanbanCardStyles = makeStyles<Theme, IUserStoryKanbanCardThemeProps>((theme: Theme) => {
  const breakpoint = '@media (min-width: 1024px)';
  return {
    action: {
      display: 'flex',
      marginBottom: theme.spacing(0.5),
      marginLeft: 'auto',
      order: 1,
      [breakpoint]: {
        order: 0
      },
      [theme.breakpoints.up('lg')]: {
        order: 1
      }
    },
    button: {
      color: theme.palette.primary.main,
      display: 'flex',
      minWidth: 0,
      padding: theme.spacing(0.25)
    },
    card: {
      backgroundColor: theme.palette.background.paper,
      border: `1px solid ${theme.palette.grey[300]}`,
      cursor: 'pointer',
      marginBottom: theme.spacing(0.75),
      padding: theme.spacing(0.75, 0.75, 0.75, 1),
      position: 'relative',
      width: '100%',
      '&:before': {
        backgroundColor: props =>
          props.state === 'Blocked'
            ? BLOCKED_COLOR
            : props.workItemType === 'Epic'
            ? EPIC_COLOR
            : props.workItemType === 'Feature'
            ? FEATURE_COLOR
            : props.workItemType === 'Product Backlog Item'
            ? STORY_COLOR
            : props.workItemType === 'Bug'
            ? BUG_COLOR
            : DEFAULT_COLOR,
        bottom: 0,
        content: '""',
        display: 'block',
        left: 0,
        position: 'absolute',
        top: 0,
        width: theme.spacing(0.25),
        zIndex: 1
      },
      '&:focus': {
        outline: 0
      },
      '&:focus, &:hover': {
        border: `1px solid ${theme.palette.primary.main}`,
        transform: 'translate(0, -1px)'
      },
      '&:last-child': {
        marginBottom: 0
      }
    },
    count: {
      fontFamily: 'Poppins-light, sans-serif',
      fontSize: 14,
      marginLeft: theme.spacing(0.25)
    },
    details: {
      alignItems: 'center',
      display: 'flex',
      marginBottom: theme.spacing(0.75)
    },
    effort: {
      color: props => (props.state === 'Blocked' ? BLOCKED_COLOR : theme.palette.grey[600]),
      fontFamily: 'Poppins-light, sans-serif',
      fontSize: 14
    },
    footer: {
      alignItems: 'flex-end',
      display: 'flex',
      marginTop: theme.spacing(0.75)
    },
    header: {
      display: 'flex',
      [breakpoint]: {
        flexDirection: 'column'
      },
      [theme.breakpoints.up('lg')]: {
        flexDirection: 'row'
      }
    },
    icon: {
      fontSize: 15
    },
    iconStory: {
      fontSize: 17
    },
    main: {
      display: 'flex',
      flexDirection: 'column'
    },
    sprint: {
      color: props => (props.state === 'Blocked' ? BLOCKED_COLOR : theme.palette.grey[600]),
      fontFamily: 'Poppins-light, sans-serif',
      fontSize: 14,
      paddingLeft: theme.spacing(1.125),
      position: 'relative',
      '&:before': {
        backgroundColor: theme.palette.grey[300],
        bottom: 0,
        content: '""',
        display: 'block',
        left: 0,
        marginLeft: theme.spacing(0.5),
        position: 'absolute',
        top: 0,
        width: theme.spacing(0.125),
        zIndex: 1
      }
    },
    state: {
      color: props => (props.state === 'Blocked' ? BLOCKED_COLOR : theme.palette.grey[600]),
      fontFamily: 'Poppins-light, sans-serif',
      fontSize: 14,
      marginLeft: 'auto'
    },
    title: {
      color: props => (props.state === 'Blocked' ? BLOCKED_COLOR : theme.palette.grey[800]),
      fontSize: 16,
      lineHeight: 1.3,
      wordBreak: 'break-word'
    },
    workItemType: {
      color: theme.palette.grey[400],
      fontFamily: 'Poppins-Bold, sans-serif',
      fontSize: 14
    }
  };
});
