import React, { FC, useState } from 'react';
import { DndContext, useSensor, useSensors, MouseSensor, TouchSensor, KeyboardSensor, DragOverlay, closestCenter } from '@dnd-kit/core';
import { SortableContext, arrayMove, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import clsx from 'clsx';
import { Grid, TextField, Checkbox, IconButton, useMediaQuery, useTheme, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import { IReleaseContent } from '@shared/types/releases';
// Components
import { OptionListItem } from '@shared/components/draggable-table/option-List-Item';
import { StaticOptionListItem } from '@shared/components/draggable-table/static-option-list-item';
import { ListItemColors } from '@shared/components/draggable-table/util';

interface DragAndDropProps {
  items: IReleaseContent[];
  handleInputChange: (index: number, field: keyof IReleaseContent, value: any) => void;
  handleDelete: (index: number) => void;
  setReleaseContents: React.Dispatch<React.SetStateAction<IReleaseContent[]>>;
  clientId?: number | null;
}

export const DragAndDrop: FC<DragAndDropProps> = ({ items, handleInputChange, handleDelete, setReleaseContents, clientId }) => {
  const [activeId, setActiveId] = useState<string | null>(null);
  const classes = useStyles();
  const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor), useSensor(KeyboardSensor));
  const theme = useTheme();
  const isMediumDown = useMediaQuery(theme.breakpoints.down('sm'));
  const color = ListItemColors.Dark;

  const handleDragStart = (event: any) => {
    setActiveId(event.active.id);
  };

  const handleDragEnd = (event: any) => {
    const { active, over } = event;
    if (over && active.id !== over.id) {
      const oldIndex = items.findIndex(item => item.releaseContentId.toString() === active.id);
      const newIndex = items.findIndex(item => item.releaseContentId.toString() === over.id);
      if (oldIndex !== -1 && newIndex !== -1) {
        const newItems = arrayMove(items, oldIndex, newIndex);
        const uniqueNewItems = newItems.filter((item, index, self) => index === self.findIndex(t => t.releaseContentId === item.releaseContentId));
        setReleaseContents(uniqueNewItems); // Update parent state directly
      }
    }
    setActiveId(null);
  };

  const handleDragCancel = () => {
    setActiveId(null);
  };

  return (
    <DndContext
      sensors={sensors}
      onDragEnd={handleDragEnd}
      onDragStart={handleDragStart}
      onDragCancel={handleDragCancel}
      collisionDetection={closestCenter}
      modifiers={[restrictToVerticalAxis]}
    >
      <SortableContext items={items.map(item => item.releaseContentId.toString())} strategy={verticalListSortingStrategy}>
        <Grid container direction='column'>
          {items.map(
            (option: IReleaseContent, index: number) =>
              !option.isDeleted && (
                <OptionListItem key={option.releaseContentId} item={option} id={option.releaseContentId.toString()} color={color}>
                  <Grid container alignItems='center' spacing={1} className={isMediumDown ? classes.mobileCard : ''}>
                    {isMediumDown ? (
                      <>
                        <IconButton size='small' onClick={() => handleDelete(index)} className={classes.closeButton}>
                          <CloseIcon className={classes.icon} />
                        </IconButton>

                        <Grid container alignItems='center' spacing={isMediumDown ? 0 : 1} justify='center'>
                          <Grid item>
                            <Typography className={clsx(classes.mobileTitle, 'mr-1')}>Title:</Typography>
                          </Grid>
                          <Grid item xs>
                            <TextField
                              className={clsx(classes.input, classes.textWrapMobile)}
                              multiline
                              value={option.title || ''}
                              fullWidth
                              onChange={e => handleInputChange(index, 'title', e.target.value)}
                              InputProps={{ autoComplete: 'off' }}
                            />
                          </Grid>
                        </Grid>

                        <Grid container alignItems='center' spacing={isMediumDown ? 0 : 1}>
                          <Grid item>
                            <Typography className={clsx(classes.mobileTitle, 'mr-1')}>Story:</Typography>
                          </Grid>
                          <Grid item>
                            <a
                              href={`https://stg-my.mwks.io/clients/backlog?clientId=${clientId}&userStoryId=${option.tfsId}`}
                              target='_blank'
                              rel='noopener noreferrer'
                              className={classes.link}
                            >
                              {option.tfsId || ''}
                            </a>
                          </Grid>
                        </Grid>

                        <Grid container alignItems='center' spacing={isMediumDown ? 0 : 1} justify='center'>
                          <Grid item>
                            <Typography className={clsx(classes.mobileTitle, 'mr-1')}>Sprint:</Typography>
                          </Grid>
                          <Grid item xs>
                            <TextField
                              className={clsx(classes.input)}
                              value={option.sprintName || ''}
                              fullWidth
                              InputProps={{ readOnly: true, autoComplete: 'off' }}
                            />
                          </Grid>
                        </Grid>

                        <Grid container alignItems='center' spacing={isMediumDown ? 0 : 1} justify='center'>
                          <Grid item>
                            <Typography className={clsx(classes.mobileTitle, 'mr-1')}>Type:</Typography>
                          </Grid>
                          <Grid item xs>
                            <TextField
                              className={clsx(classes.input)}
                              fullWidth
                              value={option.contentType || ''}
                              InputProps={{ readOnly: true, autoComplete: 'off' }}
                            />
                          </Grid>
                        </Grid>

                        <Grid container alignItems='center' spacing={0}>
                          <Grid item>
                            <Typography className={classes.mobileTitle}>Silent:</Typography>
                          </Grid>
                          <Grid item>
                            <Checkbox
                              className={classes.checkbox}
                              checked={option.isSilent || false}
                              onChange={e => handleInputChange(index, 'isSilent', e.target.checked)}
                            />
                          </Grid>
                        </Grid>
                      </>
                    ) : (
                      <>
                        <Grid item xs={3}>
                          <TextField
                            className={clsx(classes.input, 'ml-1', classes.ellipsisInput)}
                            value={option.title || ''}
                            fullWidth
                            onChange={e => handleInputChange(index, 'title', e.target.value)}
                            InputProps={{ autoComplete: 'off' }}
                          />
                        </Grid>
                        <Grid item xs={2}>
                          <a
                            href={`https://stg-my.mwks.io/clients/backlog?clientId=${clientId}&userStoryId=${option.tfsId}`}
                            target='_blank'
                            rel='noopener noreferrer'
                            className={classes.link}
                          >
                            {option.tfsId || ''}
                          </a>
                        </Grid>
                        <Grid item xs={3}>
                          <TextField
                            className={clsx(classes.input)}
                            fullWidth
                            value={option.sprintName || ''}
                            InputProps={{ readOnly: true, autoComplete: 'off' }}
                          />
                        </Grid>
                        <Grid item xs={2}>
                          <TextField
                            className={clsx(classes.input)}
                            fullWidth
                            value={option.contentType || ''}
                            InputProps={{ readOnly: true, autoComplete: 'off' }}
                          />
                        </Grid>
                        <Grid item xs={1}>
                          <Checkbox checked={option.isSilent || false} onChange={e => handleInputChange(index, 'isSilent', e.target.checked)} className={classes.checkbox} />
                        </Grid>
                        <Grid item xs={1} style={{ display: 'flex', justifyContent: 'flex-end', paddingRight: '1rem' }}>
                          <IconButton size='small' onClick={() => handleDelete(index)}>
                            <CloseIcon className={classes.icon} />
                          </IconButton>
                        </Grid>
                      </>
                    )}
                  </Grid>
                </OptionListItem>
              )
          )}
        </Grid>
      </SortableContext>
      <DragOverlay>
        {activeId && (
          <StaticOptionListItem
            item={items.find(v => v.releaseContentId.toString() === activeId)}
            id={activeId}
            color={color}
            customBorder='2px solid #d7dce5'
          >
            {items.find(v => v.releaseContentId.toString() === activeId)?.title}
          </StaticOptionListItem>
        )}
      </DragOverlay>
    </DndContext>
  );
};

const useStyles = makeStyles(theme => ({
  textWrapMobile: {
    '& .MuiInputBase-input': {
      whiteSpace: 'pre-wrap',
      wordBreak: 'break-word',
      overflowWrap: 'break-word',
      marginTop: '0.15rem',
    },
    '& .MuiInputBase-input:focus': {
      backgroundColor: 'rgba(255, 255, 255, 0.9)'
    }
  },
  input: {
    flex: 1,
    '& .MuiInputBase-root': {
      paddingLeft: '0.25rem',
      [theme.breakpoints.down('sm')]: {
        fontSize: '0.75rem',
        paddingLeft: '0',
      }
    },
    '& .MuiInput-underline:before, & .MuiInput-underline:hover:before, & .MuiInput-underline:after': {
      borderBottom: 'none', 
    },
  },
  itemContainer: {
    paddingLeft: 0,
    paddingRight: 0,
    backgroundColor: 'rgb(239,245,255)',
    borderRadius: '4px',
    borderLeft: `4px solid ${theme.palette.primary.main}`
  },
  link: {
    textDecoration: 'none',
    fontSize: '1rem',
    cursor: 'pointer',
    color: theme.palette.primary.main,
    '&:hover': {
      color: '#168dde'
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.75rem'
    }
  },
  icon: {
    fontSize: '1.125rem',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1rem', 
    },
    color: theme.palette.common.black
  },
  mobileCard: {
    position: 'relative',
    textAlign: 'center',
    padding: '1.25rem 1rem'
  },
  closeButton: {
    position: 'absolute',
    top: '0.75rem',
    right: '0.75rem'
  },
  ellipsisInput: {
    '& .MuiInputBase-input': {
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    },
    '& .MuiInputBase-input:focus': {
      backgroundColor: 'rgba(255, 255, 255, 0.9)'
    }
  },
  mobileTitle: {
    fontWeight: 600,
    fontSize: '0.75rem',
    textAlign: 'left', 
  },
  checkbox: {
    transform: 'scale(0.85)',
    [theme.breakpoints.down('sm')]: {
      transform: 'scale(0.65)',
      padding: '0',
      marginLeft: '0.25rem'
    }
  },
}));

export default DragAndDrop;
