import React, { useEffect, useState, FC } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { format } from 'date-fns';
import clsx from 'clsx';
// Components
import { Typography, Button, Grid, CardContent, Paper, Divider, Link } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { SkeletonLoader } from '@shared/components/loader';
// Types
import { IProgramIncrement } from '@shared/types';
// Fetch
import { getExtranetProgramIncrements } from '@shared/fetch';

// number of sprints to show
const SPRINT_COUNT = 10;

interface IProgramIncrementContent {
  /** Always render Program Increments on top of each other? */
  stackVertically: boolean;
  isWidget?: boolean;
}

export const ProgramIncrementContent: FC<IProgramIncrementContent> = ({ stackVertically, isWidget = true }) => {
  const classes = SprintCalendarStyles({ stackVertically, isWidget });

  // Sprints state
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [programIncrements, setProgramIncrements] = useState<IProgramIncrement[]>([]);
  const [apiError, setApiError] = useState<string>('');

  // Load Sprints
  const load = async () => {
    try {
      const response = await getExtranetProgramIncrements(isWidget ? 2 : null);
      setProgramIncrements(response);
    } catch (error) {
      console.error('getExtranetProgramIncrements', error);
      setApiError('Problem loading program increments. Refresh to try again!');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    load();
  }, []);

  const handleRefresh = () => {
    setIsLoading(true);
    setApiError('');
    load();
  };

  const getXLGridSize = () => {
    if (isWidget) {
      return stackVertically ? undefined : 6;
    }
    return 6;
  };

  return (
    <>
      <Grid container direction='column' alignItems='flex-start' justify='center'>
        {apiError && (
          <Grid item xs={12}>
            <Alert severity='error'>{apiError}</Alert>
            <Button variant='contained' color='primary' className={classes.marginTop} onClick={handleRefresh}>
              Refresh
            </Button>
          </Grid>
        )}
        {/* PROGRAM INCREMENT CONTENT */}
        <Grid item container xs={12} classes={{ root: classes.content }}>
          {isLoading ? (
            <Grid item xs={12}>
              {Array.from(Array(SPRINT_COUNT).keys()).map(x => (
                <SkeletonLoader key={`${x}`} height={37} />
              ))}
            </Grid>
          ) : (
            programIncrements.map((programIncrement, index) => (
              <Grid
                item
                xs={12}
                md={isWidget ? 12 : 6}
                xl={getXLGridSize()}
                className={!(index % 2) ? classes.programIncrements : undefined}
                key={programIncrement.projectIterationId}
              >
                <Paper elevation={0} className={classes.programIncrementContainer}>
                  <CardContent className={classes.incrementCard}>
                    <div className={classes.headerContainer}>
                      {/* TITLE */}
                      <Typography variant={'h3'} component={isWidget ? 'h3' : 'h2'} className={classes.programIncrementName}>
                        {programIncrement.name}
                      </Typography>
                      {/* INCREMENT DATE RANGE */}
                      {programIncrement.startDate && programIncrement.endDate && (
                        <Typography
                          className={clsx('fontWeight-normal', classes.incrementDateFont)}
                          variant={'h4'}
                          component={isWidget ? 'h4' : 'h3'}
                        >
                          {format(Date.parse(programIncrement.startDate), 'MMMM d')} - {format(Date.parse(programIncrement.endDate), 'MMMM d')}
                        </Typography>
                      )}
                    </div>
                    <Divider classes={{ root: classes.incrementDivider }} />
                    {/* SPRINT LIST */}
                    <div className={classes.sprintBody}>
                      {programIncrement?.sprints?.map(
                        sprint =>
                          sprint.startDate &&
                          sprint.endDate && (
                            <Typography
                              key={`${sprint.projectIterationUuid}`}
                              className={classes.sprintContainer}
                              variant={'subtitle2'}
                              component='p'
                            >
                              <span className={classes.sprintDate}>
                                {format(Date.parse(sprint.startDate), 'LLL d')} - {format(Date.parse(sprint.endDate), 'LLL d')}
                              </span>
                              <span className={classes.sprintName}>{sprint.name}</span>
                            </Typography>
                          )
                      )}
                    </div>
                  </CardContent>
                </Paper>
              </Grid>
            ))
          )}
        </Grid>
      </Grid>
      {!isLoading && isWidget && (
        <Link href='/clients/calendar' className={classes.link}>
          See More
        </Link>
      )}
    </>
  );
};

const SprintCalendarStyles = makeStyles<Theme, { stackVertically: boolean; isWidget: boolean }>((theme: Theme) => ({
  programIncrementName: {
    color: '#5E5E5E',
    marginTop: theme.spacing(0.25)
  },
  marginTop: {
    marginTop: theme.spacing(1)
  },

  calendarCard: {
    marginBottom: '2rem'
  },
  sprintDate: {
    color: theme.palette.common.black
  },
  sprintName: {
    color: theme.palette.primary.main
  },
  titleWrap: {
    display: 'flex',
    alignItems: 'center'
  },
  titleIcon: {
    color: theme.palette.primary.main,
    fontSize: '2rem'
  },
  titleText: {
    marginLeft: theme.spacing(0.5),
    color: theme.palette.primary.main
  },
  content: {
    width: '100%',
    justifyContent: 'space-between'
  },
  programIncrementContainer: {
    margin: ({ isWidget }) => (isWidget ? theme.spacing(0.5, 0, 1, 0) : theme.spacing(0.5, 0.5, 1, 0.5)),
    backgroundColor: theme.palette.info.light
  },
  programIncrements: {
    [theme.breakpoints.up('xl')]: {
      paddingRight: ({ stackVertically }) => (stackVertically ? undefined : theme.spacing(1))
    }
  },
  incrementCard: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0',
    paddingBottom: '0px !important' // Need to specifically override material styling
  },
  headerContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(0.5, 1, 0, 1),
    gap: theme.spacing(0.25)
  },
  sprintBody: {
    padding: theme.spacing(0, 1, 1, 1),
    color: theme.palette.primary.main,
    fontWeight: 'normal'
  },
  sprintContainer: {
    fontSize: '14px',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(0.5),
    lineHeight: 1.3
  },
  incrementDateFont: {
    color: theme.palette.secondary.main
  },
  divider: {
    margin: theme.spacing(1.5, 1)
  },
  incrementDivider: {
    fontFamily: 'Poppins-regular',
    backgroundColor: '#DFEDF6'
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline'
    }
  }
}));
