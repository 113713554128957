import React, { FC } from 'react';
import { Route, Redirect, useLocation } from 'react-router-dom';

import { IRouteObject, IUserProps } from '@shared/types';

interface IAccessRoute extends IRouteObject, Pick<IUserProps, 'userAccess'> {
  requiredAccess: string[];
}

export const AccessRoute: FC<IAccessRoute> = ({ component: Component, requiredAccess, userAccess, ...routeProps }) => {
  // router state
  const { pathname } = useLocation();
  return (
    <Route
      exact
      {...routeProps}
      render={props =>
        // need to check that the user trying to access the Route has the correct feature flags turn on
        // this was `.some`, but changed to `.every` (11/4/20) so that every FF check is passed as opposed to just one value in the array passes
        // i.e. the user is an admin and they have the manage users FF turned on for them
        requiredAccess.every(access => userAccess[access]) || !requiredAccess || (Array.isArray(requiredAccess) && requiredAccess.length === 0) ? (
          <>
            <Component {...props} />
          </>
        ) : (
          <Redirect
            to={{
              pathname: '/access-denied',
              state: {
                accessedPath: pathname // used for access-denied request access
              }
            }}
          />
        )
      }
    />
  );
};
