import React, { FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';

interface IConfigCardProps {
  title: string;
  onSync: () => void;
}

export const ConfigCard: FC<IConfigCardProps> = ({ title, onSync, children }) => {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <CardHeader title={title} className={classes.primaryHeader} />
      <CardContent>{children}</CardContent>
      <CardActions className={classes.actions}>
        <Button variant='contained' onClick={onSync}>
          Sync
        </Button>
      </CardActions>
    </Card>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    minWidth: 275
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)'
  },
  pos: {
    marginBottom: 12
  },
  actions: {
    justifyContent: 'center',
    paddingBottom: 24
  },
  primaryHeader: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    marginBottom: theme.spacing(1)
  }
}));
