import React, { FC } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import ReactGA from 'react-ga';
// components
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';
import { Link, ExpandMore } from '@material-ui/icons';
import { IconButton, Box } from '@material-ui/core';
import { LoaderWrapper } from '@shared/components/loader';
import { UserStoryDetail } from './UserStoryDetail';
// constants
import { STORY_COLOR, BLOCKED_COLOR } from '@shared/constants';
// helpers
import { formatSprint, copyToClipboard } from '@shared/helpers';
// types
import { IUserStory, IUserStoryDetail, IAppState } from '@shared/types';
import { useSelector } from 'react-redux';
import { useMedia } from 'react-use';

interface IUserStoryExpansionProps {
  currentStoryId: number | null;
  handleStoryClick: (val: number) => void;
  isLoadingStoryDetail: boolean;
  isSearching?: boolean;
  selectedStory?: IUserStoryDetail;
  userStory: IUserStory;
  indent?: boolean;
  isDeepLink?: boolean;
  storyCount?: number;
}

export const UserStoryExpansion: FC<IUserStoryExpansionProps> = ({
  currentStoryId,
  handleStoryClick,
  isLoadingStoryDetail,
  isSearching,
  userStory,
  selectedStory,
  indent = false,
  isDeepLink,
  storyCount
}) => {
  const classes = userStoryExpansionStyles({ indent });
  const { selectedClient } = useSelector((state: IAppState) => state.extranet);
  const gaLabelClientValue: string = selectedClient ? ` | Client: ${selectedClient?.name}` : '';
  const isMobile = useMedia('(max-width: 959px)');
  const currentUrl = window.location.href.split('?')[0];
  const clientId = selectedClient?.clientId;
  const storyId = userStory.id;
  const getUserStoryStateLabel = (state: string) => {
    switch (state) {
      case 'Committed':
      case 'Demo Ready':
      case 'PR/Review':
      case 'Releasing':
      case 'Remediating':
      case 'Test Ready':
        return 'In Progress';
      case 'Refined':
        return 'Approved';
      default:
        return state;
    }
  };

  let defaultExpanded: boolean = false;
  if (storyCount && storyCount === 1 && isDeepLink) {
    defaultExpanded = true;
  }

  return (
    <ExpansionPanel
      defaultExpanded={defaultExpanded}
      className={classes.userStory}
      key={`${userStory.id}`}
      onChange={() => {
        ReactGA.event({
          category: 'User Stories',
          action: 'View Story Details',
          value: userStory.id,
          label: `View Selected Story Details: ${userStory.id}${gaLabelClientValue}`
        });
        handleStoryClick(userStory.id);
      }}
    >
      <ExpansionPanelSummary
        className={classes.userStoryTitle}
        expandIcon={<ExpandMore />}
        classes={{
          root:
            userStory.state === 'Blocked' || userStory.workItemType === 'Bug' ? classes.panelSummaryStoryBlockedRoot : classes.panelSummaryStoryRoot
        }}
        aria-controls={`user-story-content-${userStory.id}`}
        id={`user-story-header-${userStory.id}`}
      >
        <div className={classes.panelSummaryWrapper}>
          <div className={classes.panelSummaryWrapperFirstCol}>
            <Typography component='span' className={isSearching ? classes.storyNameSearching : classes.storyName}>
              {userStory.workItemType === 'Bug' ? 'Bug' : 'Story'}
            </Typography>
            <Box flexDirection={'column'}>
              <Typography className={userStory.state === 'Blocked' ? classes.storyTitleBlocked : classes.storyTitle}>
                {userStory.title}{' '}
                <IconButton
                  size='small'
                  aria-label='copy'
                  onClick={e => {
                    e.stopPropagation();
                    copyToClipboard(`${currentUrl}?clientId=${clientId}&userStoryId=${storyId}`);
                  }}
                  className={classes.copyLinkButton}
                >
                  <Link />
                </IconButton>
              </Typography>
              {isMobile && (
                <>
                  <div className={classes.break}></div>
                  <Typography className={userStory.state === 'Blocked' ? classes.sprintBlockedText : classes.sprintText}>
                    {formatSprint(userStory.sprintName, userStory.sprintContainer)}
                  </Typography>
                </>
              )}
            </Box>
          </div>
          <div className={classes.panelSummaryWrapperSecondCol}>
            {!isMobile && (
              <Typography className={userStory.state === 'Blocked' ? classes.sprintBlockedText : classes.sprintText}>
                {formatSprint(userStory.sprintName, userStory.sprintContainer)}
              </Typography>
            )}
            <Typography className={userStory.state === 'Blocked' ? classes.stateBlockedText : classes.stateText}>
              {getUserStoryStateLabel(userStory.state)}
            </Typography>
          </div>
        </div>
      </ExpansionPanelSummary>
      <LoaderWrapper>
        <ExpansionPanelDetails classes={{ root: classes.panelDetailsStoryRoot }}>
          <UserStoryDetail
            currentStoryId={currentStoryId}
            isLoadingStoryDetail={isLoadingStoryDetail}
            selectedStory={selectedStory}
            userStory={userStory}
          />
        </ExpansionPanelDetails>
      </LoaderWrapper>
    </ExpansionPanel>
  );
};

const userStoryExpansionStyles = makeStyles<Theme, { indent: boolean }>((theme: Theme) => ({
  id: {
    marginRight: '5px'
  },
  panelSummaryStoryRoot: {
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
    borderRight: `1px solid ${theme.palette.grey[300]}`,
    paddingLeft: `10px`,
    paddingRight: '10px',
    borderLeft: `4px solid ${STORY_COLOR}`,
    minHeight: '100% !important',
    '&& .MuiExpansionPanelSummary-content.Mui-expanded': {
      margin: '12px 0'
    }
  },
  panelSummaryStoryBlockedRoot: {
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
    borderRight: `1px solid ${theme.palette.grey[300]}`,
    paddingLeft: `10px`,
    paddingRight: '10px',
    borderLeft: `4px solid ${BLOCKED_COLOR}`,
    minHeight: '100% !important',
    '&& .MuiExpansionPanelSummary-content.Mui-expanded': {
      margin: '12px 0'
    }
  },
  panelSummaryWrapper: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    flexDirection: 'row'
  },
  panelSummaryWrapperFirstCol: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '70%'
  },
  panelSummaryWrapperSecondCol: {
    display: 'flex',
    alignItems: 'center',
    width: '30%',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    whiteSpace: 'nowrap'
  },
  storyNameSearching: {
    color: theme.palette.grey[400],
    marginBottom: theme.spacing(0.5),
    fontSize: '.75rem',
    minWidth: 'auto',
    [theme.breakpoints.up('md')]: {
      marginBottom: 0,
      minWidth: theme.spacing(5)
    }
  },
  storyName: {
    color: theme.palette.grey[400],
    fontSize: '.75rem',
    marginBottom: 0,
    marginRight: theme.spacing(1)
  },
  userStory: {
    margin: '0 !important',
    '&& .MuiExpansionPanelDetails-root': {
      padding: theme.spacing(1)
    },
    '@media print': {
      display: 'none'
    }
  },
  userStoryTitle: {
    minHeight: '100%',

    '&& .MuiExpansionPanelSummary-content .Mui-expanded': {
      margin: '0'
    }
  },
  storyTitleBlocked: {
    color: BLOCKED_COLOR,
    fontWeight: 'bold'
  },
  storyTitle: {
    fontWeight: 'bold',
    overflowWrap: 'anywhere'
  },
  sprintText: {
    width: theme.spacing(8)
  },
  sprintBlockedText: {
    color: BLOCKED_COLOR,
    width: theme.spacing(8)
  },
  stateBlockedText: {
    color: BLOCKED_COLOR,
    width: theme.spacing(6),
    textSize: '.75rem',
    textAlign: 'right'
  },
  stateText: {
    width: theme.spacing(6),
    textSize: '.75rem',
    textAlign: 'right'
  },
  panelDetailsStoryRoot: {
    display: 'block',
    padding: 0,
    marginLeft: ({ indent }) => {
      return indent ? theme.spacing(1) : 0;
    },
    backgroundColor: '#f1f2f2',
    '&& .MuiPaper-elevation1': {
      boxShadow: 'none'
    }
  },
  projectTitle: {
    [theme.breakpoints.up('md')]: {
      width: theme.spacing(15)
    }
  },
  projectTitleBlocked: {
    color: BLOCKED_COLOR,
    [theme.breakpoints.up('md')]: {
      width: theme.spacing(15)
    }
  },
  break: {
    flexBasis: '100%',
    height: 0
  },
  copyLinkButton: {
    transition: 'background-color 0.3s',
    '&:active': {
      backgroundColor: 'rgba(0, 0, 0, 0.2)'
    }
  }
}));
