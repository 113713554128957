import React, { FC, useState, useCallback } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
// components
import { Typography, Grid, Button } from '@material-ui/core';
import { SprintSwitcher } from '@shared/components/switcher';
import { Modal } from '@shared/components/modals/Modal';
import { Loader } from '@shared/components/loader';
import { Toast } from '@shared/components/toast';
// icons
import Add from '@material-ui/icons/Add';
import Close from '@material-ui/icons/Close';
// types
import { IExtranetSprintItem, IAppState } from '@shared/types';
// fetch
import {
  getSingleProgressReportBudget,
  getSingleProgressReportHours,
  getSingleProgressReportLaborExpenses,
  getSingleProgressReportSprintAccomplishments,
  getSingleProgressReportSprintPlans,
  getSingleProgressReportBySprint
} from '@shared/fetch';
// redux
import { setCurrentReport } from '@shared/redux/actions';

interface ICreateProgressReportModal {
  isOpen: boolean;
  handleClose: () => void;
  clientId: number | null;
}

export const CreateProgressReportModal: FC<ICreateProgressReportModal> = ({ isOpen, handleClose, clientId }) => {
  const [selectedSprint, setSelectedSprint] = useState<IExtranetSprintItem | null>(null);
  const [isCreating, setCreating] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const classes = createReportModalStyles();
  const toastClose = useCallback(() => setErrorMessage(''), []);

  // redux
  const { selectedClient } = useSelector((state: IAppState) => state.extranet);
  const dispatch = useDispatch();
  const history = useHistory();

  return (
    <Modal open={isOpen} onClose={handleClose} maxWidth='xs' classes={{ paper: classes.modalWrapper }}>
      <Typography component='h2' variant='h5'>
        Create a Report
      </Typography>
      <Typography className={classes.clientText} component='h2' variant='h3' color='secondary'>
        Client: {selectedClient?.name}
      </Typography>
      <Typography className={classes.text}>Please select the sprint your would like to create a report for</Typography>
      <SprintSwitcher
        handleSelectedSprint={(val: IExtranetSprintItem) => setSelectedSprint(val)}
        showArrows={false}
        excludeJogs={true}
        isFullWidth
        disablePortal
        showProgressReportSprint={true}
        checkLatestReport={clientId}
      />

      <Toast autoHideDuration={6000} id='report-exists-error' message={errorMessage} open={!!errorMessage} onClose={toastClose} variant='error' />

      <Grid container alignItems='center' className={classes.buttonWrapper}>
        <Button
          className={classes.createReportButton}
          size='medium'
          color='secondary'
          variant='contained'
          disabled={!selectedSprint || isCreating}
          startIcon={isCreating ? null : <Add />}
          onClick={async () => {
            setCreating(true);
            try {
              if (clientId && selectedSprint?.projectIterationUuid) {
                let reportAlreadyExists = false;
                // check if a report exists already for this sprint
                try {
                  const report = await getSingleProgressReportBySprint(clientId, selectedSprint.projectIterationUuid);
                  if (report) reportAlreadyExists = true;
                } catch (error) {
                  // @ts-ignore
                  switch (error.response.status) {
                    // @ts-ignore
                    case 400:
                      console.log('If error 400, report does not exist for this sprint and we can create one, otherwise error normally.');
                      console.log(error);
                      break;
                    // @ts-ignore
                    case !400:
                      console.log(error);
                      break;
                    default:
                      console.log(error);
                  }
                }

                // if report exists, display error toast.
                switch (reportAlreadyExists) {
                  case true:
                    setErrorMessage('Report already exists for selected sprint.');
                    console.log('Report already exists for selected sprint.');
                    break;
                  case false:
                    //else proceed to report creation screen
                    // need to query all of initial data to build out the temp report that isn't saved/created until they click Publish or Save as Draft on page
                    const [budget, hours, laborExpenses, sprint, mercuryNeeds] = await Promise.all([
                      getSingleProgressReportBudget(clientId, selectedSprint.projectIterationUuid, { IncludeJog: true }),
                      getSingleProgressReportHours(clientId, selectedSprint.projectIterationUuid, { IncludeJog: true }),
                      getSingleProgressReportLaborExpenses(clientId, selectedSprint.projectIterationUuid, { IncludeJog: true }),
                      getSingleProgressReportSprintAccomplishments(clientId, selectedSprint.projectIterationUuid, { IncludeJog: true }),
                      getSingleProgressReportSprintPlans(clientId, selectedSprint.projectIterationUuid)
                    ]);
                    const stories = sprint.storyCount;
                    dispatch(
                      setCurrentReport({
                        budgetSpendTotalAmount: budget,
                        clientId: selectedClient?.clientId,
                        clientName: selectedClient?.name,
                        displayBudgetSpend: true,
                        displayExpendedLaborHours: true,
                        displayUserStoriesCompleted: true,
                        expendedLaborHours: hours,
                        laborExpenses,
                        mercuryNeeds,
                        portfolioStatus: '',
                        projectIterationUuid: selectedSprint?.projectIterationUuid,
                        published: false,
                        sendNotification: true,
                        sprintActivities: sprint.sprintAccomplishments,
                        sprintDisplayName: selectedSprint.name,
                        sprintName:
                          selectedSprint?.sprintName?.split('.')[2] == null
                            ? `${selectedSprint?.sprintName?.split('.')[1]}`
                            : `${selectedSprint?.sprintName?.split('.')[1]}.${selectedSprint?.sprintName?.split('.')[2]}`,
                        title: selectedSprint.name,
                        userStoriesCompleted: stories,
                        needsRefresh: false
                      })
                    );
                    history.push('/clients/progress-reports/new/create', { includeJog: true });
                    break;
                  default:
                    console.log('Cannot check if report already exists');
                }
              }
            } catch (error) {
            } finally {
              setCreating(false);
            }
          }}
        >
          {isCreating ? <Loader /> : `Create Report`}
        </Button>
        <Button
          size='medium'
          color='default'
          variant='contained'
          startIcon={<Close />}
          disabled={isCreating}
          className={classes.cancelButton}
          onClick={() => {
            setSelectedSprint(null);
            handleClose();
          }}
        >
          Cancel
        </Button>
      </Grid>
    </Modal>
  );
};

const createReportModalStyles = makeStyles((theme: Theme) => ({
  modalWrapper: {
    overflow: 'visible'
  },
  bold: {
    fontWeight: 'bold'
  },
  buttonWrapper: {
    margin: theme.spacing(1.5, 0),
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row'
    }
  },
  createReportButton: {
    marginBottom: theme.spacing(1),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      marginRight: theme.spacing(1),
      marginBottom: theme.spacing(0),
      width: 'auto'
    }
  },
  cancelButton: {
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: 'auto'
    }
  },
  text: {
    margin: theme.spacing(1, 0)
  },
  clientText: {
    marginTop: theme.spacing(0.5)
  }
}));
