import { makeStyles, Theme } from '@material-ui/core/styles';
import { SvgIconComponent } from '@material-ui/icons';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import React, { FC, HTMLAttributes } from 'react';

interface IEmptyProps extends HTMLAttributes<HTMLDivElement> {
  icon?: SvgIconComponent;
  messages: string[];
  showIcon?: boolean;
}

export const Empty: FC<IEmptyProps> = ({ children, icon, messages, showIcon = true, ...props }) => {
  const classes = emptyStyles();
  return (
    <div className={clsx(classes.empty, props.className)} data-testid={props.id} {...props}>
      {icon && showIcon ? icon : showIcon ? <InfoOutlinedIcon className={classes.icon} color='secondary' /> : null}
      {messages.map((message, index) => {
        return (
          <Typography className={classes.message} key={`empty-${index}`} variant='body1'>
            {message}
          </Typography>
        );
      })}
      {children}
    </div>
  );
};

const emptyStyles = makeStyles((theme: Theme) => {
  return {
    empty: {
      alignItems: 'center',
      backgroundColor: '#f2f3f4',
      border: `1px solid #e9eaeb`,
      borderRadius: theme.shape.borderRadius,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      padding: theme.spacing(2)
    },
    icon: {
      fontSize: 60,
      marginBottom: theme.spacing(1)
    },
    message: {
      fontFamily: 'Poppins-SemiBold, sans-serif',
      fontSize: 18,
      lineHeight: 1.4,
      marginBottom: theme.spacing(0.5),
      textAlign: 'center',
      '&:last-child': {
        marginBottom: theme.spacing(0)
      }
    }
  };
});
