import React, { FC, useState, useEffect, useMemo, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { useMedia } from 'react-use';
import clsx from 'clsx';
import { get } from 'lodash';
import { useHistory } from 'react-router-dom';
// Components
import { Table, ITableColumn } from '@shared/components/tables';
import { Button } from '@material-ui/core';
import { Edit, Add } from '@material-ui/icons';
import ClientForm from '../components/forms/ClientForm';
import { ClientsFilters } from './ClientsFilters';
import { MobileExpanderLoader } from '@shared/components/loader';
import { MobileClient } from '../components/mobile';
import { Page } from '@shared/components/layout';
import { Alert } from '@shared/components/alerts';
import { Pagination } from '@shared/components/pagination';
import { FilterContext } from '@src/context/filters';
// Fetch
import { createClient, reduxFetch, getEmployees, getAvailableEntraGroups, getClientsList } from '@shared/fetch';
// Types
import { IClientInfo, IAppState, IEmployeeInfo, IActiveDirectoryGroup } from '@shared/types';
import { setEmployees } from '@shared/redux/actions';

export const Clients: FC = () => {
  const classes = useStyles();
  const isDesktop = useMedia('(min-width: 960px)');
  const history = useHistory();
  const previousPage = history?.location?.state;

  // redux
  const dispatch = useDispatch();
  const { employees } = useSelector((state: IAppState) => state.admin);

  // general state
  const [clients, setClients] = useState<IClientInfo[]>([]);
  const [clientAbbreviations, setClientAbbreviations] = useState<string[]>([]);
  const [isPageLoading, setIsPageLoading] = useState<boolean>(false);
  const [clearFilters, setClearFilters] = useState<boolean>(previousPage ? false : true);
  const [editing, setEditing] = useState<IClientInfo | {} | null>(null);
  const [activeDirectoryGroups, setActiveDirectoryGroups] = useState<IActiveDirectoryGroup[] | []>([]);

  const {
    page,
    setPage,
    searchName,
    setSearchName,
    selectedStatus = {
      text: 'Active',
      description: 'Active',
      value: 1,
      shorthand: 'Active',
      order: 0
    },
    setSelectedStatus,
    selectedPartnerId,
    setSelectedPartnerId,
    selectedProductManagerId,
    setSelectedProductManagerId,
    selectedRelationshipManagerId,
    setSelectedRelationshipManagerId,
    hasAppliedFilters,
    setHasAppliedFilters
  } = useContext(FilterContext);

  const [recordCount, setRecordCount] = useState<number>(0);
  const [perPage, setRowsPerPage] = useState<number>(10);
  const [selectedSort, setSelectedSort] = useState<string>('name');
  const [filters, setFilters] = useState<{
    name?: string;
    address?: string;
    city?: string;
    state?: string;
    partner?: string;
    relationshipManager?: string;
    productManager?: string;
  }>({});
  const [sortDirection, setSortDirection] = useState<{
    name?: 'Asc' | 'Desc';
    address?: 'Asc' | 'Desc';
    city?: 'Asc' | 'Desc';
    state?: 'Asc' | 'Desc';
    partner?: 'Asc' | 'Desc';
    relationshipManager?: 'Asc' | 'Desc';
    productManager?: 'Asc' | 'Desc';
  }>({
    name: 'Asc'
  });

  // success and error state
  const [success, setSuccess] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const loadClients = async () => {
    try {
      setIsPageLoading(true);

      const res = await getClientsList({
        page: page + 1,
        perPage: perPage,
        isActive: selectedStatus?.text,
        name: searchName,
        partnerId: selectedPartnerId ? Number(selectedPartnerId.value) : null,
        relationshipManagerId: selectedRelationshipManagerId ? Number(selectedRelationshipManagerId.value) : null,
        productManagerId: selectedProductManagerId ? Number(selectedProductManagerId.value) : null,
        sortBy: selectedSort,
        sortDirection: sortDirection[selectedSort] as string
      });

      setRecordCount(res.totalRecordCount);
      setClients(res.records);
      setClientAbbreviations(res.map((client: IClientInfo) => client.abbreviation));

      setIsPageLoading(false);
    } catch (error) {
      setIsPageLoading(false);
    }
  };

  useEffect(() => {
    const loadEmployees = async () => {
      try {
        const employeeResponse = await reduxFetch(getEmployees, employees);
        // remove null null employee name
        const filtered = employeeResponse.filter((employee: IEmployeeInfo) => employee.firstName && employee.lastName);
        dispatch(setEmployees(filtered));
      } catch (error) {
        console.log(error);
      }
    };
    if (employees.length === 0) {
      loadEmployees();
    }

    loadActiveDirectoryGroups();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadActiveDirectoryGroups = async () => {
    try {
      const response = await getAvailableEntraGroups();
      setActiveDirectoryGroups(response);
    } catch (error) {
      console.log('error', error);
    }
  };

  useEffect(() => {
    loadClients();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, perPage, selectedSort, sortDirection, filters]);

  const handleClickColumn = (column: string) => {
    setSelectedSort(column);
    setSortDirection({
      ...sortDirection,
      [column]: sortDirection[column] === 'Asc' ? 'Desc' : 'Asc'
    });
  };

  const columns = useMemo(() => {
    return [
      {
        Header: 'Name',
        accessor: 'clientName',
        isServerSorted: selectedSort === 'name',
        isServerSortedDesc: sortDirection.name === 'Desc',
        handleClickColumn: () => handleClickColumn('name')
      },
      {
        Header: 'Address',
        accessor: 'address',
        isServerSorted: selectedSort === 'address',
        isServerSortedDesc: sortDirection.address === 'Desc',
        handleClickColumn: () => handleClickColumn('address')
      },
      {
        Header: 'City',
        accessor: 'city',
        isServerSorted: selectedSort === 'city',
        isServerSortedDesc: sortDirection.city === 'Desc',
        handleClickColumn: () => handleClickColumn('city')
      },
      {
        Header: 'State',
        accessor: 'state',
        isServerSorted: selectedSort === 'state',
        isServerSortedDesc: sortDirection.state === 'Desc',
        handleClickColumn: () => handleClickColumn('state')
      },
      {
        Header: 'Partner',
        id: 'partner',
        accessor: 'partnerName',
        isServerSorted: selectedSort === 'partner',
        isServerSortedDesc: sortDirection.partner === 'Desc',
        handleClickColumn: () => handleClickColumn('partner')
      },
      {
        Header: 'Engagement Manager',
        id: 'relationshipManager',
        accessor: 'relationshipManagerName',
        isServerSorted: selectedSort === 'relationshipManager',
        isServerSortedDesc: sortDirection.relationshipManager === 'Desc',
        handleClickColumn: () => handleClickColumn('relationshipManager')
      },
      {
        Header: 'Delivery Lead',
        id: 'productManager',
        accessor: 'productManagerName',
        isServerSorted: selectedSort === 'productManager',
        isServerSortedDesc: sortDirection.productManager === 'Desc',
        handleClickColumn: () => handleClickColumn('productManager')
      },
      {
        Header: ' ',
        id: 'actions',
        overrideWidth: 100,
        sort: false,
        hideLoad: true,
        className: classes.actionButton,
        Cell: ({
          cell: {
            row: { index }
          }
        }: any) => (
          <Button
            color='primary'
            startIcon={<Edit />}
            onClick={() => {
              history.push(`/admin/clients/${clients[index].clientId}`);
            }}
          >
            Edit
          </Button>
        )
      }
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clients, employees]);

  const addClient = async (values: IClientInfo, saveCallback: (error?: Error) => void) => {
    try {
      const data = {
        ...values,
        internalUserSubscription: values.internalProgressReportEmployeeSubscriptions
      };
      await createClient(data);
      await loadClients();

      saveCallback();
      setEditing(null);
      setSuccess(true);
    } catch (error) {
      setErrorMessage(error?.response?.data?.Detail ?? '');
      console.log('error', error);
      saveCallback(get(error, 'response.data.Detail') || true);
      setError(true);
    }
  };

  return (
    <Page
      hideTitle={!!editing}
      flexGrow={false}
      setHeight={false}
      overflow={!!editing}
      footerSpacing={150}
      title='Clients'
      actions={() =>
        !editing && (
          <Button color='primary' onClick={() => setEditing({})} aria-label='add-client' startIcon={<Add />}>
            Add Client
          </Button>
        )
      }
    >
      {!editing && (
        <>
          <ClientsFilters
            isLoading={isPageLoading}
            handleNameSearch={(clearSearch?: boolean) => {
              setPage(0);
              setFilters({
                ...filters,
                name: searchName
              });

              if (clearSearch) {
                setSearchName('');
              }
            }}
            applyFilters={(clearFilters?: boolean) => {
              if (clearFilters) {
                setFilters({
                  name: ''
                });
              } else {
                setPage(0);
                setFilters({
                  ...filters
                });
              }
            }}
            setPage={setPage}
            hasAppliedFilters={hasAppliedFilters}
            setHasAppliedFilters={setHasAppliedFilters}
            searchName={searchName}
            setSearchName={setSearchName}
            selectedStatus={selectedStatus}
            setSelectedStatus={setSelectedStatus}
            selectedPartnerId={selectedPartnerId}
            setSelectedPartnerId={setSelectedPartnerId}
            selectedProductManagerId={selectedProductManagerId}
            setSelectedProductManagerId={setSelectedProductManagerId}
            selectedRelationshipManagerId={selectedRelationshipManagerId}
            setSelectedRelationshipManagerId={setSelectedRelationshipManagerId}
            clearFilters={clearFilters}
          />
          <Table
            data={clients}
            columns={columns as ITableColumn[]}
            stickyHeader
            expandToFit
            noResultsText={'No Results Found.'}
            ResponsiveComponent={MobileClient}
            ResponsiveComponentLoader={MobileExpanderLoader}
            containerClasses={clsx(isDesktop ? classes.desktopTable : classes.mobileTable, editing ? classes.editing : '')}
            isLoading={isPageLoading}
            autoReset={false}
            hidePagination={true}
          />
          <Pagination
            rowsPerPageOptions={[10, 25, 50]}
            count={recordCount}
            rowsPerPage={perPage}
            page={page}
            setPage={setPage}
            setRowsPerPage={setRowsPerPage}
          />
        </>
      )}
      {editing !== null && (
        <ClientForm
          clientAbbreviations={clientAbbreviations}
          initialValues={editing}
          onSave={async values => await addClient(values, () => {})}
          onCancel={() => {
            setEditing(null);
            setClearFilters(false);
          }}
          activeDirectoryGroups={activeDirectoryGroups}
          employeeList={employees.filter((em: IEmployeeInfo) => em.isActive)}
          clientInfo={{ azureObjectId: '', areaPaths: [] }}
          loading={isPageLoading}
        />
      )}
      <Alert
        open={Boolean(error)}
        onClose={() => {
          setError(false);
          setErrorMessage('');
        }}
        type='error'
        text={errorMessage ?? `Problem saving. ${error === true ? 'Please try again!' : error}`}
      />
      <Alert open={success} onClose={() => setSuccess(false)} type='success' text='Save Success!' />
    </Page>
  );
};

const useStyles = makeStyles(theme => ({
  mobileTable: {
    padding: 0
  },
  desktopTable: {
    paddingLeft: 5,
    paddingRight: 5
  },
  actionButton: {
    padding: 0
  },
  clientsFormControl: {
    margin: '0 2rem 1rem 0',
    minWidth: 300
  },
  iconButton: {
    padding: 0
  },
  inputWrapper: {
    paddingRight: '12px'
  },
  editing: {
    display: 'none'
  }
}));
