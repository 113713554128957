import { authFetch } from './';
import { IProject, IProjectBudget } from '@shared/types';

export const getProject = async (
  projectId: string | number,
  clientId: string | number,
  options?: { withBudget: boolean }
): Promise<IProject> => {
  // empty budget data for unauthorized users
  const defaultBudgetData = {
    budgetedAmount: 0,
    budgetSpentAmount: 0,
    budgetInvoicedAmount: 0,
  }

  let queryString = `clientId=${clientId}`;
    if (projectId > 0 || projectId === "")
      queryString += `&projectId=${projectId}`;

  const { data } = await authFetch.get(`/api/extranet/Projects?${queryString}`);
  try {
    if (options?.withBudget) {
      const projectBudgetData = await getProjectBudget(projectId, clientId)
      return {
        ...data,
        ...projectBudgetData
      }
    }
    return {
      ...defaultBudgetData,
      ...data
    }
  } catch(e) {
    console.log('Failed to fetch budget:', e)
    return {
      ...defaultBudgetData,
      ...data
    }
  }
};

export const getProjectBudget = async (projectId: string | number, clientId: string | number): Promise<IProjectBudget> => {
  let queryString = `clientId=${clientId}`;
    if (projectId > 0 || projectId === "")
      queryString += `&projectId=${projectId}`;

  const { data } = await authFetch.get(`/api/extranet/Projects/Budget?${queryString}`);
  return data
};

export const getProjectReleasePlan = async (projectId: string | number): Promise<any> => {
  try {
    const { data } = await authFetch.get(`/api/extranet/Projects/${projectId}/Release-Plan`);
    Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};
