import React, { FC } from 'react';
import { Viewer, Worker } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';

import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

export const PdfViewer: FC<{ url: string | Uint8Array }> = ({ url }) => {
  const defaultLayoutPluginInstance = defaultLayoutPlugin();
  return (
    <Worker workerUrl='https://unpkg.com/pdfjs-dist@2.7.570/build/pdf.worker.js'>
      <div style={{ height: '100%' }}>
        <Viewer fileUrl={url} withCredentials plugins={[defaultLayoutPluginInstance]} />
      </div>
    </Worker>
  );
};
