import React, { FC, CSSProperties } from 'react';
import { useMedia } from 'react-use';

interface StatusButtonProps {
  status: string;
}

export const StatusButton: FC<StatusButtonProps> = ({ status }) => {
  const isSmallScreen = useMedia('(max-width: 960px)');
  let backgroundColor: string;
  const statusLower = status.toLowerCase();

  switch (statusLower) {
    case 'open':
    case 'approved':
    case 'ready':
    case 'in progress':
    case 'remediating':
      backgroundColor = '#c72a1c'; // zendesk red (requests/releases)
      break;
    case 'awaiting your reply':
    case 'internal testing':
    case 'internal planning':
    case 'client approval':
      backgroundColor = '#3091ec'; // zendesk blue (requests/releases)
      break;
    case 'releasing':
    case 'verifying':
      backgroundColor = '#f1c40f'; // yellow (releases)
      break;
    case 'released':
    case 'solved':
      backgroundColor = '#2ecc71'; // green (releases/requests)
      break;
    case 'new':
      backgroundColor = '#20c997'; // teal (releases)
      break;
    default:
      if (statusLower.includes('4')) {
        backgroundColor = '#2ecc71'; // green (requests)
      } else if (statusLower.includes('3')) {
        backgroundColor = '#f1c40f'; // yellow (requests)
      } else if (statusLower.includes('2')) {
        backgroundColor = '#ab2e20'; // red (requests)
      } else if (statusLower.includes('1')) {
        backgroundColor = '#d91c09'; // brighter red (requests)
      } else {
        backgroundColor = '#87929d'; // gray (default)
      }
      break;
  }

  const buttonStyle: CSSProperties = {
    backgroundColor,
    color: '#fff',
    border: 'none',
    padding: isSmallScreen ? '2px 9px' : '4px 18px',
    borderRadius: '4px',
    cursor: 'default',
    whiteSpace: 'nowrap'
  };

  return <button style={buttonStyle}>{status}</button>;
};