import React, { FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';
// Components
import { Typography, TextField } from '@material-ui/core';

interface ILaborRateCellProps {
  cell: { value: any };
  row: { index: number };
  updateMyData: (rowIndex: number, value: any) => void;
}

export const LaborRateCell: FC<ILaborRateCellProps> = ({ cell: { value: initialValue }, row: { index }, updateMyData }) => {
  const classes = useStyles();

  // We need to keep and update the state of the cell normally
  const [value, setValue] = React.useState(initialValue);

  // DON'T UPDATE HERE, OR WE WILL RENDER ON EVERY KEYSTROKE
  const onChange = ({ target: { value } }: any) => {
    setValue(value);
  };

  // We'll only update the external data when the input is blurred
  const onBlur = (e: any) => {
    updateMyData(index, value ? Number(value) : '');
  };

  // If the initialValue is changed externally, sync it up with our state
  React.useEffect(() => {
    setValue(initialValue ? Number(initialValue) : '');
  }, [initialValue]);

  return (
    <div className={classes.newRatesWrapper}>
      <TextField
        fullWidth
        type='number'
        className={classes.numeric}
        key={`new-rates-text-field-${index}`}
        id={`new-rates-text-field-${index}`}
        name={`new-rates-text-field-${index}`}
        onFocus={({ target }) => target.select()}
        value={value === null ? '' : value}
        onBlur={onBlur}
        onChange={onChange}
      />
      <Typography component='p'> / hr</Typography>
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  numeric: {
    '& input': {
      textAlign: 'right'
    }
  },
  newRatesWrapper: {
    display: 'flex',
    width: 130,
    '& input': {
      padding: 4
    },
    '& p': {
      width: 50,
      padding: 4
    }
  }
}));
