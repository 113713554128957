import React, { FC } from 'react';
import { Grid, Typography, DialogActions, Checkbox, TextField, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Modal } from '@shared/components/modals/Modal';
import { ISuggestionContent } from '@shared/types';
import clsx from 'clsx';
import { CardWrapper } from '../cards/CardWrapper';
import { Loader } from '@shared/components/loader';

interface ReleaseSuggestionsModalProps {
  isModalOpen: boolean;
  handleCloseModal: () => void;
  suggestions: ISuggestionContent[];
  modalSelectedSuggestions: ISuggestionContent[];
  modalDismissedTfsIds: number[];
  handleCheckboxChange: (suggestion: ISuggestionContent, checked: boolean) => void;
  handleDismissCheckboxChange: (suggestion: ISuggestionContent, checked: boolean) => void;
  handleSuggestionInputChange: (index: number, field: keyof ISuggestionContent, value: any) => void;
  handleApplyChanges: () => void;
  isMediumDown?: boolean;
  isLoading?: boolean;
  clientId?: number | null;
}

export const ReleaseSuggestionsModal: FC<ReleaseSuggestionsModalProps> = ({
  isModalOpen,
  handleCloseModal,
  suggestions,
  modalSelectedSuggestions,
  modalDismissedTfsIds,
  handleCheckboxChange,
  handleDismissCheckboxChange,
  handleSuggestionInputChange,
  handleApplyChanges,
  isMediumDown,
  isLoading,
  clientId
}) => {
  const classes = useStyles();

  return (
    <Modal
      maxWidth='md'
      open={isModalOpen}
      onClose={handleCloseModal}
      title='Add Work Item'
      customPaddingContent={isMediumDown ? '' : '0 1.5rem 1.5rem 1.5rem'}
    >
      {isLoading ? (
        <Loader position='centered' type='overlay' size='small' />
      ) : (
        <>
          {suggestions.length === 0 ? ( // Check if there are no suggestions
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Typography variant='body1'>There are no suggested items at this time.</Typography>
              </Grid>
            </Grid>
          ) : (
            <>
              {!isMediumDown && (
                <Grid container spacing={1} className={classes.stickyHeader}>
                  <Grid item xs={5}>
                    <Typography className={classes.cardHeader}>Title</Typography>
                  </Grid>
                  <Grid item xs={2} className='text-center'>
                    <Typography className={classes.cardHeader}>Story</Typography>
                  </Grid>
                  <Grid item xs={2} className='text-center'>
                    <Typography className={classes.cardHeader}>Sprint</Typography>
                  </Grid>
                  <Grid item xs={1} className='text-center'>
                    <Typography className={classes.cardHeader}>Type</Typography>
                  </Grid>
                  <Grid item xs={1} className='text-center'>
                    <Typography className={classes.cardHeader}>Add</Typography>
                  </Grid>
                  <Grid item xs={1} className='text-center'>
                    <Typography className={classes.cardHeader}>Dismiss</Typography>
                  </Grid>
                </Grid>
              )}
              <Grid container spacing={1} className={isMediumDown ? '' : 'mt-2'}>
                {suggestions.map((suggestion, index) => (
                  <Grid item xs={12} key={index} className={classes.suggestionItem}>
                    <CardWrapper
                      className={isMediumDown ? '' : 'p-2'}
                      contents={
                        <Grid container alignItems='center' spacing={1} className={isMediumDown ? classes.mobileCard : ''}>
                          {isMediumDown ? (
                            <>
                              <Grid container alignItems='center' justify='center'>
                                <Grid item>
                                  <Typography className={clsx(classes.mobileTitle, 'mr-1')}>Title:</Typography>
                                </Grid>
                                <Grid item xs>
                                  <TextField
                                    className={clsx(classes.input, classes.textWrapMobile)}
                                    value={suggestion.title || ''}
                                    multiline
                                    fullWidth
                                    onChange={e => handleSuggestionInputChange(index, 'title', e.target.value)}
                                    InputProps={{ autoComplete: 'off' }}
                                  />
                                </Grid>
                              </Grid>

                              <Grid container alignItems='center'>
                                <Grid item>
                                  <Typography className={clsx(classes.mobileTitle, 'mr-1')}>Story:</Typography>
                                </Grid>
                                <Grid item>
                                  <a
                                    href={`https://stg-my.mwks.io/clients/backlog?clientId=${clientId}&userStoryId=${suggestion.tfsId}`}
                                    target='_blank'
                                    rel='noopener noreferrer'
                                    className={classes.link}
                                  >
                                    {suggestion.tfsId || ''}
                                  </a>
                                </Grid>
                              </Grid>

                              <Grid container alignItems='center'>
                                <Grid item>
                                  <Typography className={clsx(classes.mobileTitle, 'mr-1')}>Sprint:</Typography>
                                </Grid>
                                <Grid item>
                                  <Typography className={classes.mobileText}>{suggestion.sprintName}</Typography>
                                </Grid>
                              </Grid>

                              <Grid container alignItems='center'>
                                <Grid item>
                                  <Typography className={clsx(classes.mobileTitle, 'mr-1')}>Type:</Typography>
                                </Grid>
                                <Grid item>
                                  <Typography className={classes.mobileText}>{suggestion.contentType}</Typography>
                                </Grid>
                              </Grid>

                              <Grid container alignItems='center' spacing={0}>
                                <Grid item>
                                  <Typography className={classes.mobileTitle}>Add:</Typography>
                                </Grid>
                                <Grid item>
                                  <Checkbox
                                    checked={modalSelectedSuggestions.includes(suggestion)}
                                    onChange={e => handleCheckboxChange(suggestion, e.target.checked)}
                                    className={classes.checkbox}
                                  />
                                </Grid>
                              </Grid>

                              <Grid container alignItems='center' spacing={0}>
                                <Grid item>
                                  <Typography className={classes.mobileTitle}>Dismiss:</Typography>
                                </Grid>
                                <Grid item>
                                  <Checkbox
                                    checked={modalDismissedTfsIds.includes(suggestion.tfsId)}
                                    onChange={e => handleDismissCheckboxChange(suggestion, e.target.checked)}
                                    className={classes.checkbox}
                                  />
                                </Grid>
                              </Grid>
                            </>
                          ) : (
                            <>
                              <Grid item xs={5}>
                                <TextField
                                  className={clsx(classes.input, classes.textWrap)}
                                  value={suggestion.title || ''}
                                  fullWidth
                                  multiline
                                  onChange={e => handleSuggestionInputChange(index, 'title', e.target.value)}
                                  InputProps={{ autoComplete: 'off' }}
                                />
                              </Grid>
                              <Grid item xs={2} className='text-center'>
                                <a
                                  href={`https://stg-my.mwks.io/clients/backlog?clientId=${clientId}&userStoryId=${suggestion.tfsId}`}
                                  target='_blank'
                                  rel='noopener noreferrer'
                                  className={classes.link}
                                >
                                  {suggestion.tfsId}
                                </a>
                              </Grid>
                              <Grid item xs={2} className='text-center'>
                                <Typography>{suggestion.sprintName}</Typography>
                              </Grid>
                              <Grid item xs={1} className='text-center'>
                                <Typography>{suggestion.contentType}</Typography>
                              </Grid>
                              <Grid item xs={1} className='text-center'>
                                <Checkbox
                                  checked={modalSelectedSuggestions.includes(suggestion)}
                                  onChange={e => handleCheckboxChange(suggestion, e.target.checked)}
                                  className={classes.checkbox}
                                />
                              </Grid>
                              <Grid item xs={1} className='text-center'>
                                <Checkbox
                                  checked={modalDismissedTfsIds.includes(suggestion.tfsId)}
                                  onChange={e => handleDismissCheckboxChange(suggestion, e.target.checked)}
                                  className={classes.checkbox}
                                />
                              </Grid>
                            </>
                          )}
                        </Grid>
                      }
                    />
                  </Grid>
                ))}
              </Grid>
            </>
          )}

          <DialogActions className='mt-2'>
            <Button onClick={handleCloseModal} color='primary'>
              Close
            </Button>
            <Button onClick={handleApplyChanges} color='primary' variant='contained'>
              Continue
            </Button>
          </DialogActions>
        </>
      )}
    </Modal>
  );
};

const useStyles = makeStyles(theme => ({
  textWrapMobile: {
    '& .MuiInputBase-input': {
      whiteSpace: 'pre-wrap',
      wordBreak: 'break-word',
      overflowWrap: 'break-word',
      marginTop: '0.15rem',
    }
  },
  textWrap: {
    whiteSpace: 'pre-wrap',
    wordBreak: 'break-word'
  },
  cardHeader: {
    fontSize: '0.875rem',
    fontWeight: 600
  },
  cardHeaderCentered: {
    fontSize: '0.875rem',
    fontWeight: 600,
    textAlign: 'center'
  },
  suggestionItem: {
    '&.MuiGrid-item': {
      padding: '0.5rem 0.5rem 0 0.5rem',
      '&:not(:first-child)': {
        paddingTop: '0.5rem'
      },
      '&:first-child': {
        paddingTop: 0
      }
    }
  },
  ellipsisInput: {
    '& .MuiInputBase-input': {
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    }
  },
  input: {
    flex: 1,
    '& .MuiInputBase-root': {
      paddingLeft: '0.75rem',
      [theme.breakpoints.down('sm')]: {
        fontSize: '0.75rem',
        paddingLeft: '0'
      }
    },
    '& .MuiInput-underline:before, & .MuiInput-underline:hover:before, & .MuiInput-underline:after': {
      borderBottom: 'none'
    },
    '& .MuiInputBase-input:focus': {
      backgroundColor: 'rgba(255, 255, 255, 0.9)'
    }
  },
  mobileTitle: {
    fontWeight: 600,
    fontSize: '0.75rem',
    textAlign: 'left',
  },
  mobileText: {
    fontSize: '0.75rem',
    display: 'flex',
    alignItems: 'center',
    padding: '0.25rem 0'
  },
  mobileCard: {
    position: 'relative',
    padding: '1rem 1.5rem'
  },
  link: {
    textDecoration: 'none',
    fontSize: '0.85rem',
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.75rem',
    },
    cursor: 'pointer',
    padding: '0.25rem 0',
    color: theme.palette.primary.main,
    '&:hover': {
      color: '#168dde'
    }
  },
  checkbox: {
    transform: 'scale(0.85)',
    [theme.breakpoints.down('sm')]: {
      transform: 'scale(0.70)',
      padding: '.25rem',
      marginLeft: '0.25rem'
    }
  },
  stickyHeader: {
    position: 'sticky',
    top: '-.25rem',
    [theme.breakpoints.up('md')]: {
      marginBottom: '1rem'
    },
    backgroundColor: theme.palette.background.paper,
    zIndex: 1,
    borderBottom: '1px solid #dfe0e1'
  }
}));
