import React, { useState, FC } from 'react';
import { useMedia } from 'react-use';
import { Autocomplete } from '@material-ui/lab';
import { useSelector, useDispatch } from 'react-redux';
import clsx from 'clsx';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { useLocation, useHistory } from 'react-router-dom';
// redux
import { setExtranetClient } from '@shared/redux/actions';
// components
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Popover from '@material-ui/core/Popover';
import InputBase from '@material-ui/core/InputBase';
import Divider from '@material-ui/core/Divider';
import People from '@material-ui/icons/People';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
// types
import { IClientInfo, IAppState } from '@shared/types';

interface IClientSwitcher {
  handleOpen?: (val: boolean) => void;
}

type StyleProps = {
  isMobile: boolean;
};

export const ClientSwitcher: FC<IClientSwitcher> = ({ handleOpen }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const dispatch = useDispatch();
  const { clients, isDisabled, selectedClient } = useSelector((state: IAppState) => state.extranet);
  const isMobile = useMedia('(max-width: 960px)');
  const isOpen = Boolean(anchorEl);
  const classes = useStyles({ isMobile });
  const location = useLocation();
  const history = useHistory();

  const redirectIfNeeded = () => {
    // '/' is important, as it means we're on a page for a specific report
    if (location.pathname.includes('progress-reports/')) {
      // use replace so that the user cannot go 'back' to a report not associated with that client.
      history.replace('/clients/progress-reports');
    }
  };

  return (
    <Grid container alignContent='center' className={classes.switcherWrapper}>
      <Button
        disableRipple
        disabled={isDisabled || !selectedClient || clients.length === 1}
        classes={{
          disabled: classes.buttonDisabled
        }}
        variant='contained'
        size='large'
        aria-describedby='client-switcher'
        onClick={e => {
          if (isOpen) {
            handleOpen && handleOpen(false);
            return setAnchorEl(null);
          }
          handleOpen && handleOpen(true);
          return setAnchorEl(e.currentTarget);
        }}
        className={clsx(classes.selectButton, {
          active: isOpen
        })}
        style={isMobile ? { width: '100%', justifyContent: 'space-between', fontSize: '14px' } : {}}
      >
        <div style={{ display: 'flex', alignItems: 'center', fontSize: '16px' }}>
          <People style={isMobile ? { fontSize: '24px', marginLeft: '8px' } : {}} />
          <span style={isMobile ? { marginLeft: '2rem', paddingTop: '4px' } : { marginLeft: '8px', marginRight: '8px' }}>
            {selectedClient ? selectedClient?.name || 'Select a Client' : 'Loading...'}
          </span>
        </div>
        {clients.length !== 1 &&
          (isOpen ? <ExpandLess style={isMobile ? { marginRight: '8px' } : {}} /> : <ExpandMore style={isMobile ? { marginRight: '8px' } : {}} />)}
      </Button>
      {isMobile && <Divider className={classes.divider} />}
      {isMobile && isOpen && clients.length > 1 && (
        <List className={classes.list}>
          {clients.map((client, index: number) => {
            return (
              <ListItem
                key={`${index}`}
                className={classes.listItem}
                selected={selectedClient?.name === client.name}
                button
                onClick={() => {
                  dispatch(setExtranetClient(client));
                  redirectIfNeeded();
                  return setAnchorEl(null);
                }}
              >
                {client.name}
              </ListItem>
            );
          })}
        </List>
      )}
      {!isMobile && clients.length > 1 && (
        <Popover
          anchorEl={anchorEl}
          elevation={1}
          open={isOpen}
          onClose={() => setAnchorEl(null)}
          PaperProps={{ className: classes.menu }}
          anchorPosition={{
            left: 0,
            top: 0
          }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
        >
          <Autocomplete
            id='client-switcher-autocomplete'
            open
            onClose={() => {
              if (isOpen && anchorEl) {
                anchorEl.focus();
              }
            }}
            disableCloseOnSelect
            disablePortal
            options={clients}
            noOptionsText='No Clients'
            onChange={(e: any, newValue: IClientInfo | null) => {
              if (newValue) {
                dispatch(setExtranetClient(newValue));
                redirectIfNeeded();
              }
              if (anchorEl) {
                anchorEl.focus();
              }
              handleOpen && handleOpen(false);
              setAnchorEl(null);
            }}
            classes={{
              paper: classes.autoCompletePaper,
              option: classes.option,
              popper: classes.popper
            }}
            getOptionLabel={(option: IClientInfo) => option.name}
            renderOption={(option: IClientInfo) => {
              const selectedIndex = clients?.findIndex(option => option.name === selectedClient?.name);
              // hack here to show the correct background color for selected option in the list
              setTimeout(() => {
                const optionEl = document.getElementById(`client-switcher-autocomplete-option-${selectedIndex}`);
                if (optionEl) {
                  optionEl.setAttribute('aria-selected', 'true');
                }
              }, 1);
              return option.name;
            }}
            renderInput={params => (
              <InputBase
                className={classes.inputBase}
                ref={params.InputProps.ref}
                inputProps={params.inputProps}
                autoFocus
                placeholder='Find a client...'
              />
            )}
          />
        </Popover>
      )}
    </Grid>
  );
};

const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) =>
  createStyles({
    switcherWrapper: {
      width: 'auto',
      height: '100%'
    },
    list: {
      width: '100%',
      marginTop: '-8px',
      marginBottom: '8px',
      maxHeight: '25rem',
      overflow: 'scroll',
      '&& .MuiListItem-root.Mui-selected': {
        color: theme.palette.secondary.main
      },
      '&& .MuiListItem-button:hover': {
        color: theme.palette.secondary.main
      }
    },
    listItem: {
      marginLeft: '3.5rem'
    },
    divider: {
      width: '100%',
      marginBottom: theme.spacing(0.5)
    },
    selectButton: {
      height: '100%',
      borderRadius: 0,
      textTransform: 'none',
      fontSize: '18px',
      boxShadow: 'none',
      backgroundColor: 'transparent',

      padding: theme.spacing(0.5),
      alignSelf: 'flex-start',
      color: (props: StyleProps) => (props.isMobile ? theme.palette.primary.main : theme.palette.common.white),
      transition: '0.3s ease-in-out',
      '&:hover': {
        backgroundColor: theme.palette.background.paper,
        color: theme.palette.secondary.main,
        boxShadow: 'none'
      },
      '&.active': {
        backgroundColor: theme.palette.background.paper,
        color: theme.palette.secondary.main
      },
      [theme.breakpoints.up('md')]: {
        backgroundColor: '#0773BB',
        color: theme.palette.background.paper,
        padding: '8px 22px',
        '&:hover': {
          backgroundColor: '#0773BB',
          color: theme.palette.secondary.main,
          boxShadow: 'none'
        },
        '&.active': {
          backgroundColor: '#0773BB',
          color: theme.palette.secondary.main
        }
      }
    },
    inputBase: {
      padding: 10,
      width: '100%',
      borderBottom: '1px solid #dfe2e5',
      '& input': {
        borderRadius: 4,
        backgroundColor: theme.palette.common.white,
        padding: 8,
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        border: '1px solid #ced4da',
        fontSize: 14,
        '&:focus': {
          borderColor: theme.palette.primary.main
        }
      }
    },
    menu: {
      width: 400,
      borderRadius: 3,
      minHeight: theme.spacing(25),
      overflow: 'hidden',
      borderBottom: `5px solid ${theme.palette.primary.light}`
    },
    option: {
      color: theme.palette.primary.main,
      '&[aria-selected="true"]': {
        color: theme.palette.secondary.main,
        backgroundColor: theme.palette.grey[100]
      },
      '&[data-focus="true"]': {
        color: theme.palette.secondary.main,
        backgroundColor: theme.palette.grey[100]
      }
    },
    autoCompletePaper: {
      border: 0,
      margin: 0,
      '&& .MuiPaper-elevation1': {
        boxShadow: 'none'
      }
    },
    buttonDisabled: {
      backgroundColor: 'transparent !important',
      color: `${theme.palette.primary.light} !important`,
      [theme.breakpoints.up('md')]: {
        backgroundColor: `#0773BB !important`,
        color: `${theme.palette.background.paper} !important`,
        padding: '8px 22px !important'
      }
    },
    popper: {
      position: 'relative'
    }
  })
);
