import React, { FC, useEffect, useState } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Box, Grid, Paper } from '@material-ui/core';

import { SkeletonLoader } from '../loader';
import { useSelector } from 'react-redux';
import { IAppState, IOpenRequests } from '@shared/types';
import { getOpenRequests } from '@shared/fetch';

interface IOpenRequestsContent {}

export const OpenRequestsContent: FC<IOpenRequestsContent> = () => {
  const classes = useStyles();

  // Sprints state
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [requests, setRequests] = useState<any[]>([]);
  const { selectedClient } = useSelector((state: IAppState) => ({ ...state.extranet }));

  // Load Sprints
  const load = async () => {
    if (selectedClient) {
      try {
        const response = await getOpenRequests(selectedClient?.clientId);
        setRequests(response);
      } catch (error) {
        console.error('getOpenRequest', error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    load();
  }, [selectedClient]);

  return (
    <Grid container direction='column' alignItems='flex-start' justify='center'>
      {isLoading && <SkeletonLoader className={classes.loader} />}
      {!isLoading && requests.length > 0 && (
        <Paper elevation={0} className={classes.releaseContainer}>
          {requests.map((requests: IOpenRequests) => {
            return (
              <Box key={`${requests.statusName}-${requests.count}`} className={classes.releaseRow}>
                <span className={classes.releaseText}>{requests.statusName}</span>
                <span className={classes.releaseDate}>{requests.count ?? 0}</span>
              </Box>
            );
          })}
        </Paper>
      )}
      {!isLoading && requests.length === 0 && (
        <Paper elevation={0} className={classes.releaseContainer}>
          <Box className={classes.releaseRow}>
            <span className={classes.releaseText}>No Requests</span>
          </Box>
        </Paper>
      )}
    </Grid>
  );
};

const useStyles = makeStyles<Theme>((theme: Theme) => ({
  upcomingReleaseContainer: {
    backgroundColor: theme.palette.info.light
  },
  incrementCard: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0',
    paddingBottom: '0px !important' // Need to specifically override material styling
  },
  releaseContainer: {
    margin: theme.spacing(0.5, 0, 1, 0),
    padding: theme.spacing(1, 1, 1, 1),
    backgroundColor: theme.palette.info.light,
    width: '100%',
    height: '100%',
    display: 'flex',
    fontSize: '14px',
    lineHeight: '1.3',
    marginBottom: '8px',
    justifyContent: 'flex-start',
    flexDirection: 'column'
  },
  releaseDate: {
    color: theme.palette.primary.main
  },
  releaseText: {
    color: '#5E5E5E'
  },
  releaseRow: {
    justifyContent: 'space-between',
    flexDirection: 'row',
    display: 'flex',
    marginBottom: theme.spacing(0.5)
  },
  releaseBody: {
    padding: theme.spacing(0, 1, 1, 1),
    color: theme.palette.primary.main,
    fontWeight: 'normal'
  },
  loader: {
    width: '100%',
    height: '100%'
  }
}));
