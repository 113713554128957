import React, { FC, useState, useRef } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { Loader } from '@shared/components/loader';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Save from '@material-ui/icons/Save';
import Close from '@material-ui/icons/Close';
import Visibility from '@material-ui/icons/Visibility';
import Publish from '@material-ui/icons/Publish';
import Edit from '@material-ui/icons/Edit';
import Delete from '@material-ui/icons/Delete';
import CancelScheduleSend from '@material-ui/icons/CancelScheduleSend';
import { ProgressReportMobileMenuOption } from '@shared/types';

const options = ['Save as Draft', 'Unpublish', 'Cancel', 'Preview', 'Delete'];

interface IProgressReportMobileButtonProps {
  handleClick: (val: ProgressReportMobileMenuOption) => void;
  showPublishOption: boolean;
  isDisabled: boolean;
  isLoading: boolean;
  isAdmin: boolean;
}

const getIcon = (option: string) => {
  const selectedOptions = {
    Unpublish: <CancelScheduleSend />,
    Publish: <Publish />,
    'Save as Draft': <Save />,
    Save: <Save />,
    Edit: <Edit />,
    Cancel: <Close />,
    Preview: <Visibility />,
    Delete: <Delete />
  };
  return selectedOptions[option];
};

export const ProgressReportMobileButton: FC<IProgressReportMobileButtonProps> = ({
  handleClick,
  showPublishOption,
  isDisabled,
  isLoading,
  isAdmin
}) => {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLDivElement>(null);
  const [selectedClickType, setClickType] = useState<ProgressReportMobileMenuOption | null>(null);

  const handleMenuItemClick = (event: React.MouseEvent<HTMLLIElement, MouseEvent>, index: number) => {
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const handleClose = (event: React.MouseEvent<Document, MouseEvent>) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    setOpen(false);
  };
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <Grid item xs={12}>
        <ButtonGroup variant='contained' color='secondary' ref={anchorRef} aria-label='split button'>
          {showPublishOption ? (
            <Button
              onClick={() => {
                setClickType('Publish');
                handleClick('Publish');
              }}
              startIcon={isLoading ? null : <Publish />}
              disabled={isLoading}
            >
              {isLoading && selectedClickType === 'Delete' ? (
                <Loader title='Deleting...' />
              ) : isLoading && selectedClickType === 'Save as Draft' ? (
                <Loader title='Saving...' />
              ) : selectedClickType === 'Publish' ? (
                <Loader title='Publishing...' />
              ) : (
                'Publish'
              )}
            </Button>
          ) : (
            <Button
              onClick={() => {
                setClickType('Save');
                handleClick('Save');
              }}
              startIcon={isLoading ? null : <Save />}
              disabled={isLoading || isDisabled}
            >
              {isLoading && selectedClickType === 'Delete' ? (
                <Loader title='Deleting...' />
              ) : isLoading && selectedClickType === 'Save' ? (
                <Loader title='Saving...' />
              ) : selectedClickType === 'Unpublish' ? (
                <Loader title='Unpublishing...' />
              ) : (
                'Save'
              )}
            </Button>
          )}

          <Button
            size='small'
            aria-controls={open ? 'split-button-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-label='select merge strategy'
            aria-haspopup='menu'
            onClick={handleToggle}
            className={classes.arrowButton}
          >
            {open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
          </Button>
        </ButtonGroup>
        <Popper style={{ zIndex: 10 }} open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList id='split-button-menu'>
                    {options.map((option, index) => {
                      if (!isAdmin && option === 'Delete') {
                        return null;
                      }
                      if (!showPublishOption && option === 'Publish') {
                        return null;
                      }
                      if (showPublishOption && option === 'Unpublish') {
                        return null;
                      }
                      if (!showPublishOption && option === 'Save as Draft') {
                        return null;
                      }

                      return (
                        <MenuItem
                          key={option}
                          disabled={isDisabled && (option === 'Preview' || option === 'Cancel' || option === 'Save as Draft')}
                          onClick={event => {
                            const result = option === 'Unpublish' ? window.confirm('Are you sure you want to unpublish this report?') : true;
                            if (result) {
                              setClickType(options[index] as ProgressReportMobileMenuOption);
                              handleMenuItemClick(event, index);
                              handleClick(options[index] as ProgressReportMobileMenuOption);
                            }
                          }}
                        >
                          <ListItemIcon>{getIcon(option)}</ListItemIcon>
                          <ListItemText primary={option} />
                        </MenuItem>
                      );
                    })}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </Grid>
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    marginTop: theme.spacing(0.5)
  },
  arrowButton: {
    backgroundColor: '#59944f'
  }
}));
