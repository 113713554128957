import React, { FC, useState } from 'react';
import { Grid, Typography, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Add, CheckBox } from '@material-ui/icons';
// Fetch
import { useQuery } from 'react-query';
import {
  getVerificationSteps,
  updateVerificationStepStatus,
  createVerificationStep,
  updateVerificationStep,
  updateVerificationSteps
} from '@shared/fetch';
import { IVerificationStep, ReleaseStatuses } from '@shared/types';
// Components
import { Loader } from '@shared/components/loader';
import { DashboardCard } from '../../../clients/components/DashboardCard';
import { DragAndDropCheckbox } from '@shared/components/drag-and-drop';
import { Toast } from '@shared/components/toast/Toast';

interface IVerifying {
  releaseId: number | null;
  handleVerified: () => void;
  releaseStatusName?: string;
}

export const Verifying: FC<IVerifying> = ({ releaseId, handleVerified, releaseStatusName }) => {
  const classes = useStyles();
  const [editingId, setEditingId] = useState<string | null>(null);
  const [isUpdating, setIsUpdating] = useState<boolean>(false);

  const [{ message: PageMessage, variant: pageVariant, isOpen: pageToastIsOpen }, setPageToast] = useState<{
    message: string;
    variant: 'error' | 'success';
    isOpen: boolean;
  }>({
    message: '',
    variant: 'success',
    isOpen: false
  });

  const {
    isLoading: isLoadingVerificationSteps,
    data: verificationSteps = [],
    refetch: refetchVerificationSteps,
    isRefetching: isRefetchingVerificationSteps
  } = useQuery<any[], Error>(
    ['verificationSteps', releaseId],
    async () => {
      const steps = await getVerificationSteps(releaseId, true);
      const orderedReleaseVerificationSteps = steps.map((step: any, index: number) => ({
        ...step,
        order: index + 1
      }));
      return orderedReleaseVerificationSteps;
    },
    {
      notifyOnChangeProps: 'tracked',
      enabled: !!releaseId
    }
  );

  function areAllStepsComplete(steps: IVerificationStep[]) {
    return steps.every(step => step.isComplete === true);
  }

  const updateStepsState = (updatedSteps: any[]) => {
    setEditingId(null); // Close the editor
  };

  const handleCreate = async (name: string, id: string | number) => {
    try {
      setIsUpdating(true);
      const createdStep = await createVerificationStep(releaseId, { name, order: verificationSteps.length + 1, isVerifyingStageStep: true });
      const updatedSteps = [...verificationSteps, createdStep].map((step: any, index: number) => ({
        ...step,
        releaseVerificationStepId: step.id,
        order: index + 1
      }));

      updateStepsState(updatedSteps); // Update local state
      setEditingId(createdStep.id.toString()); // Open editor on the new step
      await updateVerificationSteps(releaseId, { updates: updatedSteps });
      await refetchVerificationSteps();
    } catch (error) {
      console.error('Error: ', error);
      setPageToast({ message: 'Error adding step', variant: 'error', isOpen: true });
    } finally {
      setIsUpdating(false);
    }
  };

  const handleUpdate = async (payload: any, id: string | number) => {
    try {
      setIsUpdating(true);
      const updatedStep = await updateVerificationStep(releaseId, id, payload);
      const updatedSteps = verificationSteps.map((step: any, index: number) =>
        step.id === updatedStep.id ? { ...updatedStep, order: index + 1 } : { ...step, order: index + 1 }
      );

      const orderedReleaseExecutionSteps = updatedSteps.map((step: any, index: number) => ({
        ...step,
        releaseVerificationStepId: step.id,
        order: index + 1
      }));

      updateStepsState(updatedSteps); // Update local state
      await updateVerificationSteps(releaseId, { updates: orderedReleaseExecutionSteps });
      await refetchVerificationSteps();
    } catch (error) {
      console.error('Error updating step:', error);
      setPageToast({ message: 'Error updating step', variant: 'error', isOpen: true });
    } finally {
      setIsUpdating(false);
    }
  };

  const handleUpdateVerificationSteps = async (newItems: any[]) => {
    const orderedReleaseExecutionSteps = newItems.map((step: any, index: number) => ({
      ...step,
      releaseVerificationStepId: step.id,
      order: index + 1
    }));

    try {
      setIsUpdating(true);
      updateStepsState(orderedReleaseExecutionSteps); // Update local state
      await updateVerificationSteps(releaseId, { updates: orderedReleaseExecutionSteps });
      await refetchVerificationSteps();
    } catch (error) {
      console.error('Error updating steps:', error);
      setPageToast({
        message: 'Error updating verification steps',
        variant: 'error',
        isOpen: true
      });
    } finally {
      setIsUpdating(false);
    }
  };

  const handleCheckboxChange = async (id: number, checked: boolean) => {
    try {
      await updateVerificationStepStatus(releaseId, id.toString(), checked);
      await refetchVerificationSteps();
    } catch (error) {
      console.error(`Failed to update the status of step with ID ${id}`, error);
      setPageToast({
        message: `Error updating status for step with ID ${id}`,
        variant: 'error',
        isOpen: true
      });
    }
  };

  return (
    <Grid container alignItems='flex-start' justify='space-between' spacing={2} className={classes.cardHolder}>
      <Grid item xs={12}>
        <Grid container className={classes.buttonContainer}>
          <Button
            className={classes.verifiedButton}
            startIcon={<CheckBox />}
            onClick={handleVerified}
            disabled={
              !areAllStepsComplete(verificationSteps) ||
              isUpdating ||
              isLoadingVerificationSteps ||
              isRefetchingVerificationSteps ||
              releaseStatusName !== ReleaseStatuses.VERIFYING
            }
          >
            Verified
          </Button>
        </Grid>
        <DashboardCard setHeight={false} isColumn={false} hideTitle={true}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography className={classes.title}>Verification Tasks</Typography>
            </Grid>
            <Grid item xs={12}>
              {isLoadingVerificationSteps ? (
                <Loader position='centered' />
              ) : (
                <>
                  {verificationSteps.length > 0 ? (
                    <DragAndDropCheckbox
                      items={verificationSteps}
                      onItemsReorder={handleUpdateVerificationSteps}
                      onCheckboxChange={handleCheckboxChange}
                      onUpdate={handleUpdate}
                      useIsComplete={true}
                      editingId={editingId}
                      setEditingId={setEditingId}
                    />
                  ) : (
                    <Typography variant='body1'>No active verification steps.</Typography>
                  )}
                </>
              )}
            </Grid>
            <Grid item xs={12} style={{ textAlign: 'right', marginTop: 10 }}>
              <Button
                className={classes.addButton}
                startIcon={<Add />}
                onClick={() => handleCreate('', `new-${new Date().getTime()}`)}
                disabled={isLoadingVerificationSteps || isRefetchingVerificationSteps || isUpdating || editingId !== null}
              >
                Add Task
              </Button>
            </Grid>
          </Grid>
        </DashboardCard>
      </Grid>
      <Grid item xs={12} className='mb-6'>
        <Toast
          id='page-toast'
          message={PageMessage}
          open={pageToastIsOpen}
          onClose={() =>
            setPageToast({
              message: '',
              variant: pageVariant,
              isOpen: false
            })
          }
          variant={pageVariant}
        />
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles(theme => ({
  title: {
    fontSize: '1.125rem',
    color: '#616161',
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      fontSize: '1rem',
      padding: '0.75rem 0 0.5rem 0'
    }
  },
  cardHolder: {
    alignItems: 'stretch',
    marginBottom: '0.5rem'
  },
  addButton: {
    color: theme.palette.primary.main,
    textTransform: 'none',
    fontWeight: 500,
    '& .MuiButton-startIcon': {
      marginRight: theme.spacing(0.25)
    }
  },
  verifiedButton: {
    color: theme.palette.primary.main,
    textTransform: 'none',
    '& .MuiButton-startIcon': {
      marginRight: theme.spacing(0.5)
    }
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: theme.spacing(1),
    paddingRight: theme.spacing(1)
  }
}));
