import React from 'react';
import { useSelector } from 'react-redux';

import { AccessRoute } from '@shared/components/router';

// Types
import { IUserProps, IAppState, IRouteObject } from '@shared/types';

// Containers
import { EmployeeConfig } from './containers';
import { userAccess } from '@shared/constants';

const Routes: IRouteObject[] = [{ path: '/settings/config', component: EmployeeConfig }];

const Admin: React.FC = () => {
  const user: IUserProps = useSelector((state: IAppState) => state.user);

  return (
    <React.Fragment>
      {Routes.map(route => (
        <AccessRoute key={route.path} userAccess={user.userAccess} requiredAccess={[userAccess.VIEW_EMPLOYEES]} {...route}></AccessRoute>
      ))}
    </React.Fragment>
  );
};

export default Admin;
