import React, { FC } from 'react';
import clsx from 'clsx';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles';
// components
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
// types
import { CircularProgressProps } from '@material-ui/core/CircularProgress';

type LoaderPosition = 'centered' | 'left' | 'top-center';
type LoaderSize = 'small' | 'medium' | 'large';
type LoaderType = 'fullscreen' | 'inline' | 'overlay';
type LoaderTopOffset = number;

interface ILoaderExtendedProps extends CircularProgressProps {
  loaderWrapperClassName?: string;
  /** 'centered' | 'left' */
  position?: LoaderPosition;
  /** number */
  topOffset?: LoaderTopOffset;
  /** 'small' | 'medium' | 'large' */
  size?: LoaderSize;
  subtitle?: string;
  title?: string;
  /** 'inline' | 'overlay' */
  type?: LoaderType;
}

export type ILoaderProps = Omit<ILoaderExtendedProps, 'variant'>;

export const Loader: FC<ILoaderProps> = ({ children, loaderWrapperClassName, subtitle, title = 'Loading...', ...props }) => {
  const classes = loaderStyles(props);
  return (
    <div className={clsx(classes.loader, loaderWrapperClassName || '')} data-testid={props.id} role='alert'>
      <div className={classes.inner}>
        <CircularProgress
          {...props}
          className={clsx(classes.progress, props.className)}
          size={props.size === 'large' ? 18 : props.size === 'small' ? 10 : 14}
          variant='indeterminate'
        />
        <div className={classes.text}>
          {children}
          {!children && (
            <>
              <Typography className={classes.title} variant='body1'>
                {title}
              </Typography>
              {subtitle && (
                <Typography className={classes.subtitle} variant='subtitle1'>
                  {subtitle}
                </Typography>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

const loaderStyles = makeStyles<Theme, ILoaderProps>((theme: Theme) => {
  return createStyles({
    inner: {
      alignItems: 'center',
      display: 'flex',
      flexShrink: 1,
      flexWrap: 'wrap',
      justifyContent: props => (props.position === 'top-center' ? 'center' : props.position === 'centered' ? 'center' : 'flex-start'),
      marginTop: props => props.topOffset && props.topOffset + '%',
      width: '100%'
    },
    loader: {
      alignItems: props => (props.position === 'centered' ? 'center' : 'flex-start'),
      backgroundColor: props => (props.type === 'fullscreen' || props.type === 'overlay' ? fade(theme.palette.background.paper, 0.75) : ''),
      bottom: 0,
      display: 'flex',
      fontSize: props => (props.size === 'large' ? '1.25rem' : props.size === 'small' ? '0.875rem' : '1rem'),
      height: props => (props.position === 'centered' ? '100%' : 'auto'),
      left: 0,
      position: props => (props.type === 'fullscreen' ? 'fixed' : props.type === 'overlay' ? 'absolute' : 'static'),
      right: 0,
      top: 0,
      width: '100%',
      zIndex: theme.zIndex.tooltip + 1
    },
    progress: {
      color: theme.palette.primary.main,
      margin: theme.spacing(0, 1, 0, 0)
    },
    subtitle: {
      color: theme.palette.grey[800],
      fontSize: props => (props.size === 'large' ? '0.875rem' : props.size === 'small' ? '0.625rem' : '0.75rem'),
      margin: theme.spacing(0.25, 0, 0),
      textTransform: 'uppercase'
    },
    text: {
      maxWidth: 230 // this is needed for long subtitles (to force that text to wrap)
    },
    title: {
      color: theme.palette.primary.main,
      fontSize: props => (props.size === 'large' ? '1.25rem' : props.size === 'small' ? '0.875rem' : '1rem'),
      margin: 0
    }
  });
});
