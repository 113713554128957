import React, { useState, useEffect, FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';
// Components
import Tooltip from '@material-ui/core/Tooltip';

interface IHashtagCellProps {
  cell: { value: any };
  row: { index: number };
  column: { id: string };
  hideHashtag?: boolean;
}

export const HashtagCell: FC<IHashtagCellProps> = ({ cell: { value }, row: { index }, column: { id }, hideHashtag = false }) => {
  const classes = useStyles();

  const [copied, setCopied] = useState<boolean>(false);

  const displayString = `${hideHashtag ? '' : '#'}${value.toString()}`;
  const handleCopy = () => {
    setCopied(true);
    let tempInput = document.createElement('input');
    tempInput.value = displayString;
    document.body.appendChild(tempInput);
    tempInput.select();
    document.execCommand('copy');
    document.body.removeChild(tempInput);
  };

  useEffect(() => {
    if (copied) {
      setTimeout(() => {
        setCopied(false);
      }, 1500);
    }
  }, [copied]);

  return (
    <Tooltip title={copied ? 'Copied' : 'Copy to Clipboard'}>
      <div
        className={classes.hashtag}
        onClick={() => handleCopy()}
        onKeyDown={e => {
          if (e.key === 'Enter') {
            handleCopy();
          }
        }}
      >
        {displayString}
      </div>
    </Tooltip>
  );
};

const useStyles = makeStyles(theme => ({
  hashtag: {
    cursor: 'pointer'
  }
}));
