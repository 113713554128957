import React, { FC } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import clsx from 'clsx';
// Types
import { IWeeklyTimesheet } from '@shared/types';
import { Typography } from '@material-ui/core';
import { TimesheetNameColor } from '@shared/helpers';
import { useMedia } from 'react-use';

interface IWeeklyTotalsProps {
  name?: string;
  weeklyTimeSheet: IWeeklyTimesheet;
  loading: boolean;
  handleChangeDate(date: string): void;
  currentDate: string;
}

export const WeeklyTotals: FC<IWeeklyTotalsProps> = ({ name, loading, weeklyTimeSheet, handleChangeDate, currentDate }) => {
  const classes = weeklyTotalsStyles();
  const isMobile = useMedia('(max-width: 960px)');

  if (loading || !weeklyTimeSheet.dailyTotals.length) return null;

  function checkIncompleteTasksBeforeCurrentDate(weeklyTimeSheet: IWeeklyTimesheet) {
    const currentDate = new Date(weeklyTimeSheet.currentDate);

    for (let day of weeklyTimeSheet.dailyTotals) {
      const taskDate = new Date(day.date);

      if (taskDate < currentDate && !day.isCompleted) {
        return true;
      }
    }

    return false;
  }

  return (
    <Paper classes={{ root: isMobile ? classes.mobilePaper : classes.paper }}>
      <Typography
        className={checkIncompleteTasksBeforeCurrentDate(weeklyTimeSheet) ? classes.employeeNameIncomplete : classes.employeeName}
        variant='body2'
      >
        {name}
      </Typography>
      <div className={classes.hoursSummary}>
        {weeklyTimeSheet.dailyTotals.map(day => (
          <div
            key={day.date}
            className={classes.dayColumn}
            onClick={() => handleChangeDate(day.date.split('T')[0])}
            onKeyDown={e => {
              if (e.key === 'Enter') {
                handleChangeDate(day.date.split('T')[0]);
              }
            }}
          >
            <div className={clsx(classes.header, day.isCompleted ? classes.headerComplete : '')}>
              <div className={classes.headerText}>{day.dayOfWeek.charAt(0)}</div>
            </div>
            <div className='details'>
              <div className={day.isCompleted ? classes.dayComplete : ''}>
                <div className={clsx(classes.hours, day.isCompleted ? classes.finished : '')} data-testid={`${day.dayOfWeek.charAt(0)}-total`}>{day.hoursCompleted}</div>
              </div>
            </div>
          </div>
        ))}
        <div className={clsx(classes.dayColumn, classes.totals)}>
          <div>Total</div>
          <div>{weeklyTimeSheet.totalHoursCompleted}</div>
        </div>
      </div>
    </Paper>
  );
};

const weeklyTotalsStyles = makeStyles((theme: Theme) =>
  createStyles({
    employeeName: {
      color: theme.palette.secondary.main
    },
    employeeNameIncomplete: {
      color: TimesheetNameColor.red
    },
    paper: {
      padding: theme.spacing(0.5),
      marginTop: theme.spacing(0.5),
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center'
    },
    mobilePaper: {
      padding: theme.spacing(0.5),
      marginTop: theme.spacing(0.5),
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'left'
    },
    hoursSummary: {
      display: 'flex'
    },
    dayColumn: {
      cursor: 'pointer',
      width: '40px',
      marginLeft: '.25rem'
    },
    headerRow: {
      display: 'flex',
      alignItems: 'center'
    },
    hoursRow: {
      display: 'flex',
      alignItems: 'center'
    },
    details: {
      flexDirection: 'column'
    },
    dayComplete: {
      color: theme.palette.secondary.main
    },
    finished: {
      borderBottom: `2px solid ${theme.palette.secondary.main}`
    },
    dayToday: {
      backgroundColor: 'lightgray',
      color: 'white',
      borderBottom: `2px solid lightgray`
    },
    header: {
      color: theme.palette.primary.main
    },
    headerComplete: {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.common.white
    },
    headerToday: {
      backgroundColor: 'lightgray',
      color: 'white'
    },
    headerText: {
      textAlign: 'center'
    },
    hours: {
      textAlign: 'center'
    },
    totals: {
      color: theme.palette.secondary.main,
      fontWeight: 'bold',
      textAlign: 'center'
    }
  })
);
