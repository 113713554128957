import React, { FC } from 'react';
import { makeStyles, Theme, Typography } from '@material-ui/core';
import { TypographyProps } from '@material-ui/core/Typography';
import clsx from 'clsx';

interface IH1Props extends TypographyProps {}

export const H1: FC<IH1Props> = ({ children, ...props }) => {
  const classes = useStyles();
  return (
    <Typography {...props} className={clsx(classes.h1, props.className)} variant='h1'>
      {children}
    </Typography>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  h1: {
    fontSize: 32,
    fontWeight: 700,
    margin: theme.spacing(1, 0)
  }
}));
