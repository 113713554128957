import React, { FC } from 'react';
import { makeStyles, Theme } from '@material-ui/core';
import clsx from 'clsx';

interface IUlProps {
  className?: string;
}

export const Ul: FC<IUlProps> = ({ children, ...props }) => {
  const classes = ulStyles();
  return (
    <ul {...props} className={clsx(classes.ul, props.className)}>
      {children}
    </ul>
  );
};

const ulStyles = makeStyles((theme: Theme) => ({
  ul: {
    fontSize: `1rem`,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 400,
    lineHeight: 1.5,
    margin: theme.spacing(2, 0)
  }
}));
