import React, { createContext, useState, ReactNode, useMemo } from 'react';
import { IClientRelationsLookup, IDropdownResponseExt } from '@shared/types';

interface FilterContextProps {
  page: number;
  setPage: (val: any) => void;
  searchName: string;
  setSearchName: (val: any) => void;
  selectedStatus: any;
  setSelectedStatus: (val: any) => void;
  selectedPartnerId: IClientRelationsLookup | null;
  setSelectedPartnerId: (val: IClientRelationsLookup | null) => void;
  selectedProductManagerId: IClientRelationsLookup | null;
  setSelectedProductManagerId: (val: IClientRelationsLookup | null) => void;
  selectedRelationshipManagerId: IClientRelationsLookup | null;
  setSelectedRelationshipManagerId: (val: IClientRelationsLookup | null) => void;
  setHasAppliedFilters: (val: boolean) => void;
  hasAppliedFilters?: boolean;
}

export const FilterContext = createContext<FilterContextProps>({
  page: 0,
  setPage: () => {},
  searchName: '',
  setSearchName: () => {},
  selectedStatus: {},
  setSelectedStatus: () => {},
  selectedPartnerId: null,
  setSelectedPartnerId: () => {},
  selectedProductManagerId: null,
  setSelectedProductManagerId: () => {},
  selectedRelationshipManagerId: null,
  setSelectedRelationshipManagerId: () => {},
  hasAppliedFilters: false,
  setHasAppliedFilters: () => {}
});

interface FilterProviderProps {
  children: ReactNode;
}

export const FiltersContextHandler = ({ children }: FilterProviderProps) => {
  const [page, setPage] = useState<number>(0);
  const [searchName, setSearchName] = useState<string>('');
  const [selectedStatus, setSelectedStatus] = useState<IDropdownResponseExt>();
  const [selectedPartnerId, setSelectedPartnerId] = useState<IClientRelationsLookup | null>(null);
  const [selectedRelationshipManagerId, setSelectedRelationshipManagerId] = useState<IClientRelationsLookup | null>(null);
  const [selectedProductManagerId, setSelectedProductManagerId] = useState<IClientRelationsLookup | null>(null);
  const [hasAppliedFilters, setHasAppliedFilters] = useState<boolean>(false);

  return useMemo(
    () => (
      <FilterContext.Provider
        value={{
          page,
          setPage,
          searchName,
          setSearchName,
          selectedStatus,
          setSelectedStatus,
          selectedPartnerId,
          setSelectedPartnerId,
          selectedProductManagerId,
          setSelectedProductManagerId,
          selectedRelationshipManagerId,
          setSelectedRelationshipManagerId,
          hasAppliedFilters,
          setHasAppliedFilters
        }}
      >
        {children}
      </FilterContext.Provider>
    ),
    [
      page,
      setPage,
      searchName,
      setSearchName,
      selectedStatus,
      setSelectedStatus,
      selectedPartnerId,
      setSelectedPartnerId,
      selectedProductManagerId,
      setSelectedProductManagerId,
      selectedRelationshipManagerId,
      setSelectedRelationshipManagerId,
      hasAppliedFilters,
      setHasAppliedFilters,
      children
    ]
  );
};

export default FilterContext;
