import React, { FC } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
// Components
import { FormLabel, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails, Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
// Types
import { ILaborRate } from '@shared/types';
import { FormikHandlers, FieldMetaProps } from 'formik';

interface IMobileLaborRate extends FormikHandlers, FieldMetaProps<any> {
  original: ILaborRate;
  cells: any[];
}

export const MobileLaborRate: FC<IMobileLaborRate> = ({ original: { role }, cells }) => {
  const classes = useStyles();

  return (
    <ExpansionPanel className={classes.root}>
      <ExpansionPanelSummary className={classes.expansionPanel} expandIcon={<ExpandMoreIcon />} aria-controls='panel1a-content' id='panel1a-header'>
        <div className={classes.panelSummaryWrapper}>
          <Typography component='span'>{role}</Typography>
        </div>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails className={classes.details}>
        {cells.map(
          (
            cell // We can just render the cell here and save on indepth custom render. Utilize react-table built in methods
          ) => (
            <>
              <FormLabel component='h2'>{cell.column.Header}</FormLabel>
              <Typography component='span'>{cell.render('Cell')}</Typography>
            </>
          )
        )}
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    maxWidth: '100%'
  },
  expansionPanel: {
    padding: '0px 24px'
  },
  panelSummaryWrapper: {
    display: 'flex',
    alignItems: 'flex-start',
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  details: {
    display: 'flex',
    flexDirection: 'column'
  }
}));
