import React, { FC, useState, useEffect, useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useMedia } from 'react-use';
import debounce from 'lodash/debounce';
// Components
import { Table, ITableColumn, TableToolbar } from '@shared/components/tables';
import { TextField, FormControl, InputAdornment, IconButton } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { MobileExpanderLoader } from '@shared/components/loader';
import { MobileClientHashtag } from '../components/mobile';
import { Alert } from '@shared/components/alerts';
import { HashtagCell } from '@shared/components/table-cells';
import { Page } from '@shared/components/layout';
import { Close } from '@material-ui/icons';
// Fetch
import { getClients, getClientHashtags } from '@shared/fetch';
// Types
import { IClientInfo, IClientProject } from '@shared/types';
import { Pagination } from '@shared/components/pagination';

const HashtagList: FC = () => {
  const classes = useStyles();
  const isDesktop = useMedia('(min-width: 960px)');

  // general state
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isPageLoading, setIsPageLoading] = useState<boolean>(false);
  const [clients, setClients] = useState<IClientInfo[]>([]);
  const [clientProjects, setClientProjects] = useState<IClientProject[]>([]);
  const [filteredProjects, setFilteredProjects] = useState<IClientProject[]>([]);

  const [page, setPage] = useState<number>(0);
  const [perPage, setPerPage] = useState<number>(10);
  const [recordCount, setRecordCount] = useState<number>(0);

  const [selectedSort, setSelectedSort] = useState<string>('OrderNumber');
  const [sortDirection, setSortDirection] = useState<{
    clientName?: 'Asc' | 'Desc';
    projectName?: 'Asc' | 'Desc';
    hashtag?: 'Asc' | 'Desc';
  }>({ clientName: 'Asc' });

  const isColumnSortable = (column: string) => {
    return selectedSort === column;
  };

  const isColumnSortDesc = (column: any) => {
    return column === 'Desc';
  };

  const handleClickColumn = (column: string) => {
    setSelectedSort(column);
    setSortDirection({
      ...sortDirection,
      [column]: sortDirection[column] === 'Asc' ? 'Desc' : 'Asc'
    });
  };
  // filter
  const [client, setClient] = useState<{ label: string; value: number } | null>(null);
  const [success, setSuccess] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  const fetchClients = async () => {
    setIsPageLoading(true);
    try {
      const clientsResponse = await getClients();
      setClients(clientsResponse);
    } catch (error) {
      setIsPageLoading(false);
    } finally {
      setIsPageLoading(false);
    }
  };

  useEffect(() => {
    fetchClients();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let searchRef: any = {};
  const [searchString, setSearchString] = useState<string>('');
  const debouncedSetSearch = debounce((val: string) => setSearchString(val.toLowerCase()), 500);

  const loadClientProjects = async () => {
    try {
      setIsLoading(true);
      const clientsProjectsResponse = await getClientHashtags({
        clientId: client?.value,
        page: page + 1,
        perPage,
        searchTerm: searchString,
        sortDirection: sortDirection[selectedSort],
        sortBy: selectedSort
      });

      setRecordCount(clientsProjectsResponse.totalRecordCount);

      setClientProjects(clientsProjectsResponse.records);
      setFilteredProjects(clientsProjectsResponse.records);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error(error);
      setError(true);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadClientProjects();
  }, [client, searchString, page, perPage, sortDirection, selectedSort]);

  const handleSearch = () => {
    const results = clientProjects.filter(project => {
      return Object.keys(project).some(() => {
        const client = clients.find(c => c.clientId === project.clientId);
        return (
          (client && client.name.toLowerCase().includes(searchString)) ||
          (project.description && project.description.toLowerCase().includes(searchString)) ||
          (project.hashtag && project.hashtag.toLowerCase().includes(searchString))
        );
      });
    });

    setFilteredProjects(results);
  };

  const handleResetSearch = () => {
    searchRef.value = '';
    setSearchString('');
    setPage(0);
    setFilteredProjects(clientProjects);
  };

  useEffect(() => {
    setPage(0);
    handleSearch();
  }, [searchString]);

  const columns = useMemo(() => {
    return [
      {
        Header: 'Client Name',
        accessor: 'clientName',
        isServerSorted: isColumnSortable('clientName'),
        isServerSortedDesc: isColumnSortDesc(sortDirection.clientName),
        handleClickColumn: () => handleClickColumn('clientName')
      },
      {
        Header: 'Project Name',
        accessor: 'projectName',
        isServerSorted: isColumnSortable('projectName'),
        isServerSortedDesc: isColumnSortDesc(sortDirection.projectName),
        handleClickColumn: () => handleClickColumn('projectName')
      },
      {
        Header: 'Outlook Hashtag',
        accessor: (d: IClientProject) => d?.hashtag?.toLowerCase(),
        isServerSorted: isColumnSortable('hashtag'),
        isServerSortedDesc: isColumnSortDesc(sortDirection.hashtag),
        handleClickColumn: () => handleClickColumn('hashtag'),
        id: 'outlookHashtag',
        Cell: (props: any) => <HashtagCell {...props} />
      },
      {
        Header: 'AzDO Hashtag',
        accessor: (d: IClientProject) => d?.hashtag?.toLowerCase(),
        sort: false,
        id: 'AzDOHashtag',
        Cell: (props: any) => <HashtagCell {...props} hideHashtag />
      }
    ];
  }, [clientProjects]);

  return (
    <Page title='Hashtag List' setHeight={false} flexGrow={false} footerSpacing={135}>
      <TableToolbar>
        <Autocomplete
          id='clients-autocomplete'
          className={classes.clientsFormControl}
          options={clients.map(client => ({ label: client.name, value: client.clientId }))}
          getOptionLabel={option => option && option.label}
          value={client}
          disabled={isPageLoading}
          onChange={(e: any, value: any) => setClient(value)}
          renderInput={params => <TextField {...params} label='Client' variant='outlined' size='small' />}
        />
        <FormControl>
          <TextField
            label='Search'
            className={classes.clientsFormControl}
            variant='outlined'
            size='small'
            disabled={isPageLoading}
            inputRef={el => (searchRef = el)}
            InputLabelProps={{ id: 'hashtag-search', htmlFor: 'hashtag-search' }}
            onChange={({ target: { value } }) => {
              if (value.length) {
                debouncedSetSearch(value);
              } else {
                handleResetSearch();
              }
            }}
            InputProps={{
              className: classes.inputWrapper,
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton className={classes.iconButton} onClick={() => handleResetSearch()}>
                    <Close />
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
        </FormControl>
      </TableToolbar>
      <Table
        data={filteredProjects}
        columns={columns as ITableColumn[]}
        stickyHeader
        hidePagination={true}
        expandToFit
        isLoading={isPageLoading || isLoading}
        noResultsText='No Projects For This Client.'
        ResponsiveComponent={MobileClientHashtag}
        ResponsiveComponentLoader={MobileExpanderLoader}
        containerClasses={isDesktop ? classes.desktopTable : classes.mobileTable}
      />
      {isPageLoading || isLoading ? null : (
        <div className={classes.paginationWrapper}>
          <Pagination page={page} count={recordCount} rowsPerPage={perPage} setPage={setPage} setRowsPerPage={setPerPage} />
        </div>
      )}

      <Alert open={success} onClose={() => setSuccess(false)} type='success' text='Save Success!' />
      <Alert open={Boolean(error)} onClose={() => setError(false)} type='error' text='Problem loading hashtag list. Please Refresh and Try Again!' />
    </Page>
  );
};

const useStyles = makeStyles(theme => ({
  clientsFormControl: {
    margin: theme.spacing(0, 1, 1, 0),
    minWidth: 300
  },
  mobileTable: {
    padding: 0
  },
  desktopTable: {
    paddingLeft: 5,
    paddingRight: 5
  },
  inputWrapper: {
    paddingRight: '12px'
  },
  iconButton: {
    padding: 0
  },
  paginationWrapper: {
    margin: theme.spacing(0.5, 0)
  }
}));

export default HashtagList;
