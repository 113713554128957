import qs from 'qs';
import { authFetch } from './';
// Types
import {
  IActiveDirectoryGroup,
  IAdminTimeEntryItem,
  IBillingType,
  IBulkFilters,
  IClientInfo,
  IClientProject,
  IClientProjectLaborRate,
  IClientProjectsListItem,
  IClientProjectType,
  IDropdownResponseExt,
  IEmployeeInfo,
  IEmployeeRate,
  IEmployeeType,
  IEnumResponse,
  IGetTimeEntriesFilters,
  IHolidayTimeEntryItem,
  ILaborRate,
  ILaborRateHistory,
  IPaginatedBulkEntryData,
  IProjectSummary,
  IRevenueCategory,
  ISprintListResponse,
  IUnassignedZendeskOrgsOrUsers,
  IUserSettings,
  IZendeskUser
} from '@shared/types';

export const BillableTypeTextByValue = {
  1: 'Billable',
  2: 'NonBillable'
};

const API = {
  TimeEntries: '/api/LaborTracking/TimeEntries'
};

export const getSprintList = async (): Promise<ISprintListResponse> => {
  try {
    const { data } = await authFetch.get('/api/LaborTracking/Sprints/List');
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getTimeEntries = async (
  startDate: string,
  endDate: string,
  filters?: Omit<IGetTimeEntriesFilters, 'TimeEntryStartDate' | 'TimeEntryEndDate'>
): Promise<IPaginatedBulkEntryData<IAdminTimeEntryItem>> => {
  const queryParamsFilters = filters ? `&${qs.stringify(filters)}` : '';
  const uri = `${API.TimeEntries}?TimeEntryStartDate=${startDate}&TimeEntryEndDate=${endDate}${queryParamsFilters}`;
  try {
    const { data } = await authFetch.get(uri);
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const postTimeEntries = async (timeEntries: IAdminTimeEntryItem[]): Promise<IAdminTimeEntryItem[]> => {
  try {
    const { data } = await authFetch.post('/api/LaborTracking/TimeEntries', { timeEntries });
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const InsertTimeEntry = async (timeEntry: IAdminTimeEntryItem): Promise<IAdminTimeEntryItem> => {
  try {
    const { data } = await authFetch.post('/api/LaborTracking/TimeEntries/InsertTimeEntry', { timeEntry });
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getBillingTypes = async (): Promise<IBillingType[]> => {
  try {
    const { data } = await authFetch.get('/api/LaborTracking/BillingTypes');
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getClients = async (isActive?: boolean): Promise<IClientInfo[]> => {
  try {
    const { data } = await authFetch.get(`/api/LaborTracking/Clients?IsActive=${isActive ?? true}`);
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getAllClients = async (search?: { IsActive?: boolean }): Promise<IClientInfo[]> => {
  try {
    const queryParamsFilters = search ? `?${qs.stringify(search)}` : '';
    const { data } = await authFetch.get(`/api/LaborTracking/Clients${queryParamsFilters}`);
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getClient = async (clientId: number | null): Promise<IClientInfo> => {
  try {
    const { data } = await authFetch.get(`/api/LaborTracking/Clients/${clientId}`);
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getAvailableEntraGroups = async (query?: {clientId? : number | null}): Promise<IActiveDirectoryGroup[]> => {
  try {
    const { data } = await authFetch.get(`/api/LaborTracking/Clients/available-entra-groups`,{
      params:query
    });
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const createClient = async (client: IClientInfo): Promise<IClientInfo> => {
  try {
    const { data } = await authFetch.post(`/api/LaborTracking/Clients`, { client });
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const updateClient = async (clientId: number, updatedClient: IClientInfo): Promise<IClientInfo> => {
  try {
    const { data } = await authFetch.put(`/api/LaborTracking/Clients/${clientId}`, { client: updatedClient });
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const validateClientAreaPaths = async (areaPaths: string[]): Promise<boolean> => {
  try {
    const { data } = await authFetch.post('/api/LaborTracking/Clients/ValidateClientAreaPath', { areaPaths });
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getClientProjectStatuses = async (): Promise<IEnumResponse[]> => {
  try {
    const { data } = await authFetch.get('/api/LaborTracking/Clients/ProjectStatuses');
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getClientProjects = async (search?: {
  ClientId?: number;
  ProjectStatus?: string;
  IncludeAllProjects?: boolean;
}): Promise<IClientProject[]> => {
  try {
    const { data } = await authFetch.get(
      `/api/LaborTracking/Clients/ClientProjects?${search && Object.keys(search).length ? qs.stringify(search) : ''}`
    );
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

// A more performant version of getClientProjects when getting all projects
export const getClientProjectsList = async (search?: { IsActive?: boolean; ProjectStatus?: number | string }): Promise<IClientProjectsListItem[]> => {
  try {
    const queryParamsFilters = search ? `?${qs.stringify(search)}` : '';
    const { data } = await authFetch.get(`/api/LaborTracking/Clients/ClientProjectList${queryParamsFilters}`);

    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getClientProjectActualSpend = async (ClientProjectId: number): Promise<number> => {
  try {
    const { data } = await authFetch.get(`/api/LaborTracking/Clients/ClientProjectActualSpend?ClientProjectId=${ClientProjectId}`);
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const updateClientProjects = async (clientProject: IClientProject): Promise<number> => {
  try {
    const { data } = await authFetch.post('/api/LaborTracking/Clients/UpdateClientProject', clientProject);
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getEmployees = async (search?: { IsActive?: boolean }): Promise<IEmployeeInfo[]> => {
  try {
    const { data } = await authFetch.get(`/api/LaborTracking/Employees?${search && Object.keys(search).length ? qs.stringify(search) : ''}`);
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const addRole = async (role: IEmployeeRate): Promise<number> => {
  const postObj = {
    role: role
  };
  try {
    const { data } = await authFetch.post('/api/LaborTracking/Employees/AddEmployeeRole', postObj);
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const updateRole = async (role: IEmployeeRate): Promise<number> => {
  // Strip out unused parameters
  const { employeeRateId, ...roleObj } = role;
  try {
    const { data } = await authFetch.put(`/api/LaborTracking/Employees/UpdateRole/${employeeRateId}`, roleObj);
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const addEmployee = async (employee: IEmployeeInfo): Promise<number> => {
  // strip out unused employee ID
  const { employeeId, ...employeeObj } = employee;
  const postObj = {
    employee: employeeObj
  };
  try {
    const { data } = await authFetch.post('/api/LaborTracking/Employees', postObj);
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const updateEmployee = async (employee: IEmployeeInfo): Promise<number> => {
  // Strip out unused parameters
  const { employeeId, sourceControlUser, collaborationPassword, collaborationUser, helpDeskUserName, ...employeeObj } = employee;
  try {
    const { data } = await authFetch.put(`/api/LaborTracking/Employees/${employeeId}`, employeeObj);
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getRevenueCategories = async (): Promise<IRevenueCategory[]> => {
  try {
    const { data } = await authFetch.get('/api/LaborTracking/RevenueCategories');
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const addRevenueCategory = async (name: string): Promise<number> => {
  try {
    const { data } = await authFetch.post(`/api/LaborTracking/RevenueCategories?Name=${name}`, {});
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const updateRevenueCategory = async (revenueCategory: IRevenueCategory): Promise<number> => {
  try {
    const { data } = await authFetch.put('/api/LaborTracking/RevenueCategories', revenueCategory);
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getLaborRates = async (
  clientId?: number,
  clientProjectId?: number,
  getEmployeeRates?: boolean,
  getClientEmployeeRates?: boolean
): Promise<ILaborRate[]> => {
  try {
    const { data } = await authFetch.get(
      `/api/LaborTracking/LaborRates/GetRates?IsActive=true${clientId ? `&ClientId=${clientId}` : ''}${
        clientProjectId ? `&ClientProjectId=${clientProjectId}` : ''
      }${getEmployeeRates ? `&IndividualEmployeeRates=true` : ''}${getClientEmployeeRates ? `&ClientEmployeeRates=true` : ''}`
    );
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const updateLaborRates = async (laborRates: ILaborRate[]): Promise<ILaborRate[]> => {
  try {
    const { data } = await authFetch.post('/api/LaborTracking/LaborRates/UpdateRates', { laborRates });
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getClientProjectLaborRates = async (ClientProjectId: number): Promise<IClientProjectLaborRate[]> => {
  try {
    const { data } = await authFetch.get(`/api/LaborTracking/LaborRates/GetProjectDisplayRates?ClientProjectId=${ClientProjectId}`);
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getLaborRateHistory = async (search: {
  EmployeeRateId: number;
  ClientId?: number;
  ClientProjectId?: number;
  EmployeeId?: number;
}): Promise<ILaborRateHistory> => {
  try {
    const { data } = await authFetch.get(
      `/api/LaborTracking/LaborRates/GetLaborRateHistory?${search && Object.keys(search).length ? qs.stringify(search) : ''}`
    );
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const updateLaborRateHistory = async (laborRateHistory: ILaborRateHistory): Promise<ILaborRateHistory> => {
  try {
    const { data } = await authFetch.post('/api/LaborTracking/LaborRates/UpdateLaborRateHistory', { laborRateHistory });
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const bulkAddHoliday = async (holiday: IHolidayTimeEntryItem): Promise<number> => {
  try {
    const { data } = await authFetch.post('/api/LaborTracking/TimeEntries/BulkLoadHoliday', holiday);
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getUserSettings = async (): Promise<IUserSettings> => {
  try {
    const { data } = await authFetch.get('/api/LaborTracking/Employees/UserSettings');
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getZendeskEmployees = async () => {
  try {
    const { data } = await authFetch.get(`/api/LaborTracking/Employees/GetZendeskEmployees`);
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const setZendeskUserSettings = async (zendeskUser: IZendeskUser): Promise<any> => {
  try {
    const { data } = await authFetch.post('/api/LaborTracking/Employees/SetZendeskUser', zendeskUser);
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const setAzureDevOpsUserSettings = async (username: string): Promise<any> => {
  try {
    const { data } = await authFetch.post('/api/LaborTracking/Employees/SetAzureDevopsUserSettings', { username });
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getEmployeeRates = async (): Promise<IEmployeeRate[]> => {
  try {
    const { data } = await authFetch.get('/api/LaborTracking/Employees/EmployeeRates');
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getEmployeeTypes = async (): Promise<IEmployeeType[]> => {
  try {
    const { data } = await authFetch.get('/api/LaborTracking/Employees/EmployeeTypes');
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getClientProjectTypes = async (): Promise<IClientProjectType[]> => {
  try {
    const { data } = await authFetch.get('/api/LaborTracking/Clients/ClientProjectTypes');
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getProjectSummary = async (search: { BeginDate: string; EndDate: string; ClientId?: number }): Promise<IProjectSummary> => {
  try {
    const { data } = await authFetch.get(
      `/api/LaborTracking/TimeEntries/ProjectSummary?${search && Object.keys(search).length ? qs.stringify(search) : ''}`
    );
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const updateInvoicedTimeEntries = async (beginDate: string, endDate: string, clientId?: number): Promise<any> => {
  try {
    const { data } = await authFetch.post('/api/LaborTracking/TimeEntries/UpdateInvoicedTimeEntries', { beginDate, endDate, clientId });
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getClientPlanningType = async (): Promise<IEnumResponse[]> => {
  try {
    const { data } = await authFetch.get('/api/LaborTracking/Clients/ClientPlanningTypes');
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getClientContacts = async (clientId: number) => {
  try {
    const { data } = await authFetch.get(`/api/extranet/Clients/${clientId}/Contacts`);
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getClientRelations = async (relation: string, filterToActiveClientRelations: boolean | null) => {
  try {
    const { data } = await authFetch.get(
      `/api/LaborTracking/Clients/lookup?relation=${relation}&clientIsActiveFilter=${filterToActiveClientRelations}`
    );
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getClientsList = async (filters?: {
  isActive?: boolean;
  name?: string | null;
  partnerId?: number | null;
  relationshipManagerId?: number | null;
  productManagerId?: number | null;
  sortBy?: string;
  sortDirection?: string;
  page?: number;
  perPage?: number | string;
}): Promise<any> => {
  try {
    const { data } = await authFetch.get(`/api/LaborTracking/Clients/list`, {
      params: filters
    });
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getClientHashtags = async (filters?: {
  searchTerm?: string;
  clientId?: number;
  sortBy?: string;
  sortDirection?: string;
  page?: number;
  perPage?: number | string;
}): Promise<any> => {
  try {
    const { data } = await authFetch.get(`/api/LaborTracking/Clients/hashtags`, {
      params: filters
    });
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getUnassignedZendeskOrganizations = async (): Promise<IUnassignedZendeskOrgsOrUsers[]> => {
  try {
    const { data } = await authFetch.get('/api/LaborTracking/Clients/unassigned-zendesk-organizations');
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getUnassignedZendeskUsers = async (): Promise<IUnassignedZendeskOrgsOrUsers[]> => {
  try {
    const { data } = await authFetch.get('/api/extranet/Users/unassigned-zendesk-users');
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getZendeskAssignment = async (userId: any): Promise<any> => {
  try {
    const { data } = await authFetch.get(`/api/extranet/users/zendesk-assignment/${userId}`);
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getStatusOptions = async (): Promise<IDropdownResponseExt[]> => {
  try {
    const { data } = await authFetch.get('/api/LaborTracking/Clients/status-options');
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getClientRates = async (id: string | number): Promise<IClientProjectLaborRate[]> => {
  try {
    const { data } = await authFetch.get(`/api/LaborTracking/Clients/${id}/rates`);
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getBulkFilterOptions = async (
  startDate: string,
  endDate: string,
  filters?: Omit<IGetTimeEntriesFilters, 'TimeEntryStartDate' | 'TimeEntryEndDate'>
): Promise<IBulkFilters> => {
  const queryParamsFilters = filters ? `&${qs.stringify(filters)}` : '';
  const uri = `${API.TimeEntries}/BulkFilters?TimeEntryStartDate=${startDate}&TimeEntryEndDate=${endDate}${queryParamsFilters}`;
  try {
    const { data } = await authFetch.get(uri);
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getBulkOptionsModels = async (
  startDate: string,
  endDate: string,
  filters?: Omit<IGetTimeEntriesFilters, 'TimeEntryStartDate' | 'TimeEntryEndDate' | 'description' | 'storyID'>
): Promise<any[]> => {
  const queryParamsFilters = filters ? `&${qs.stringify(filters)}` : '';
  const uri = `${API.TimeEntries}/BulkEditModels?TimeEntryStartDate=${startDate}&TimeEntryEndDate=${endDate}${queryParamsFilters}`;
  try {
    const { data } = await authFetch.get(uri);
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

interface IBulkEntryUpdatePayload {
  timeEntryIds: number[];
  clientProjectId: number | string | null;
  billingType: string | null;
  description?: string | null;
  tfsId: number | null;
  hours: number | null;
}
export const updateBulkOptions = async (updates: IBulkEntryUpdatePayload): Promise<number> => {
  try {
    const { data } = await authFetch.put(`${API.TimeEntries}/BulkUpdate`, { body: updates });
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getAvailableChannels = async (clientId: number): Promise<IDropdownResponseExt[]> => {
  try {
    const { data } = await authFetch.get('/api/LaborTracking/Clients/channels', {
      params: { clientId },
    });
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};