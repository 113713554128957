import React, { useEffect, useState, FC } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { isAfter, isBefore } from 'date-fns';
// Components
import { Typography, Button, Grid, Card, CardContent } from '@material-ui/core';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import Alert from '@material-ui/lab/Alert';
import { SkeletonLoader } from '@shared/components/loader';
// Types
import { IExtranetSprintItem } from '@shared/types';
// Fetch
import { getExtranetSprintList } from '@shared/fetch';

// number of sprints to show
const SPRINT_COUNT = 10;

interface ISprintCalendarProps {}

export const SprintCalendar: FC<ISprintCalendarProps> = () => {
  const classes = SprintCalendarStyles();

  // Sprints state
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [sprintList, setSprintList] = useState<IExtranetSprintItem[]>([]);
  const [apiError, setApiError] = useState<string>('');

  // Load Sprints
  const load = async () => {
    try {
      const res = await getExtranetSprintList();
      // If we have more sprints in the list than the amount we want to show
      // find the current sprint and splice array down to ensure we include that
      if (res.sprints.length > SPRINT_COUNT) {
        // Find the index of the current sprint
        const currentSprintIndex = res.sprints.findIndex(
          sprint =>
            !(
              isBefore(new Date().setHours(0, 0, 0, 0), new Date(sprint.startDate as string)) ||
              isAfter(new Date().setHours(0, 0, 0, 0), new Date(sprint.endDate as string))
            )
        );

        // If the current sprint exists, adjust to those entries otherwise fallback to the last 10 sprints
        if (currentSprintIndex !== -1) {
          // default splice start to - SPRINT_COUNT from end of array
          let spliceStart = res.sprints.length - SPRINT_COUNT;
          // default splice end to + SPRINT_COUNT from splice start
          let spliceEnd = spliceStart + SPRINT_COUNT;

          // If the start is > than current sprint index AND the current sprint index
          // isn't the beginning of the array, set splice to current index - 1. That
          // way we show at least 1 past sprint. Otherwise default to the first item in the array
          // being the current sprint.
          if (spliceStart > currentSprintIndex) {
            if (currentSprintIndex > 0) {
              spliceStart = currentSprintIndex - 1;
            } else {
              spliceStart = currentSprintIndex;
            }

            spliceEnd = spliceStart + SPRINT_COUNT;
          }

          // splice out the sprints we want
          setSprintList(res.sprints.slice(spliceStart, spliceEnd));
        } else {
          setSprintList(res.sprints.slice(res.sprints.length - SPRINT_COUNT));
        }
      } else {
        setSprintList(res.sprints);
      }
    } catch (error) {
      console.error('fetchSprints', error);
      setApiError('Problem loading sprints. Refresh to try again!');
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    load();
  }, []);

  const handleRefresh = () => {
    setIsLoading(true);
    setApiError('');
    load();
  };

  return (
    <Card classes={{ root: classes.calendarCard }}>
      <CardContent>
        {apiError ? (
          <>
            <Alert severity='error'>{apiError}</Alert>
            <Button variant='contained' color='primary' className={classes.marginTop} onClick={handleRefresh}>
              Refresh
            </Button>
          </>
        ) : (
          <Grid container direction='column' alignItems='flex-start' justify='center' spacing={2}>
            <Grid item xs={12}>
              <div className={classes.titleWrap}>
                <CalendarTodayIcon className={classes.titleIcon} />
                <Typography align='left' className={classes.titleText} variant='h2'>
                  Calendar
                </Typography>
              </div>
            </Grid>
            <Grid item xs={12} classes={{ root: classes.content }}>
              {isLoading
                ? Array.from(Array(SPRINT_COUNT).keys()).map(x => <SkeletonLoader key={`${x}`} height={37} />)
                : sprintList.map(sprint => {
                    const type = isBefore(new Date().setHours(0, 0, 0, 0), new Date(sprint.startDate as string))
                      ? 'Future'
                      : isAfter(new Date().setHours(0, 0, 0, 0), new Date(sprint.endDate as string))
                      ? 'Past'
                      : 'Current';

                    return (
                      <div key={`sprint-${sprint.sprintName}`} className={`${classes.sprintListItem} ${type === 'Current' ? 'current' : ''}`}>
                        <Typography variant='h5' classes={{ root: classes.sprintName }}>
                          {sprint.name}
                        </Typography>
                        <Typography variant='caption'>{type}</Typography>
                      </div>
                    );
                  })}
            </Grid>
          </Grid>
        )}
      </CardContent>
    </Card>
  );
};

const SprintCalendarStyles = makeStyles((theme: Theme) => ({
  sprintListItem: {
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: theme.palette.common.white,
    padding: theme.spacing(0.5),
    color: theme.palette.primary.main,
    fontWeight: 'normal',
    '&.current': {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.secondary.main
    }
  },
  sprintName: {
    fontSize: 16
  },
  marginTop: {
    marginTop: theme.spacing(1)
  },
  calendarCard: {
    marginBottom: '2rem'
  },
  titleWrap: {
    display: 'flex',
    alignItems: 'center'
  },
  titleIcon: {
    color: theme.palette.primary.main,
    fontSize: '2rem'
  },
  titleText: {
    marginLeft: theme.spacing(0.5)
  },
  content: {
    width: '100%'
  }
}));
