import React, { FC, useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
// Components
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import CardActions from '@material-ui/core/CardActions';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
// types
import { IRevenueCategory } from '@shared/types';

interface IRevenueEditCardProps {
  revenueCategory: IRevenueCategory | null;
  onSave: (newRevenueCategory: IRevenueCategory) => void;
  onCancel: () => void;
}

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%'
  },
  formControl: {
    width: '100%',
    marginBottom: '1rem'
  },
  delete: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.error.main
  }
}));

export const RevenueEditCard: FC<IRevenueEditCardProps> = ({ revenueCategory, onSave, onCancel }) => {
  const classes = useStyles();

  // state
  const [name, setName] = useState<string>('');
  const [saveError, setSaveError] = useState<string>('');

  useEffect(() => {
    setName(revenueCategory ? revenueCategory.name : '');
  }, [revenueCategory]);

  const handleSave = () => {
    // If we don't have a value for name, show error
    // Formik/Yup would be overkill for 1 field
    if (!name) {
      return setSaveError('Name is required');
    }

    // set error back to default if we make it passed the check
    setSaveError('');
    if (revenueCategory) {
      onSave({ ...revenueCategory, name });
    }
  };

  return (
    <Card className={classes.root}>
      <CardHeader title='Edit Revenue Category' />
      <CardContent>
        <TextField
          className={classes.formControl}
          id='edit-name'
          label='Name'
          value={name}
          onChange={({ target: { value } }) => setName(value)}
          error={!!saveError}
          helperText={saveError}
        />
        <CardActions>
          <Button variant='contained' color='secondary' onClick={handleSave}>
            Save
          </Button>
          <Button variant='contained' color='default' onClick={onCancel}>
            Cancel
          </Button>
        </CardActions>
      </CardContent>
    </Card>
  );
};
