import React, { FC, useState } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { InsertChartOutlined, Add } from '@material-ui/icons';
import { Typography, Grid, Button } from '@material-ui/core';
// components
import { CreateProgressReportModal } from '../components/CreateProgressReportModal';
// types
import { IClientInfo } from '@shared/types';

interface NoProgressReportsProps {
  isAdmin: boolean;
  selectedClient: IClientInfo | null;
  message: string;
}

/**
 * Presentational component displaying the empty status of a list of Progress Reports.
 */
export const NoProgressReports: FC<NoProgressReportsProps> = ({ isAdmin, selectedClient, message }) => {
  const classes = useStyles();
  const [isShowingCreateModal, showModal] = useState<boolean>(false);

  return (
    <>
      <Grid container direction='column' alignItems='center' justify='center' className={classes.noReportsWrapper}>
        <InsertChartOutlined className={classes.noReportsIcon} color='secondary' />
        <Typography className={isAdmin ? classes.noReportsText : classes.bold}>{message}</Typography>
        {selectedClient?.usesProgressReports && (
          <>
            {!isAdmin && (
              <Typography className={classes.bold}>
                If you have any questions about progress reports, please contact your Product Manager
                {selectedClient ? `, ${selectedClient.productManager}` : ''}
              </Typography>
            )}
            {isAdmin && (
              <Button variant='contained' size='large' color='primary' startIcon={<Add />} onClick={() => showModal(true)}>
                Create New Report
              </Button>
            )}
          </>
        )}
      </Grid>
      <CreateProgressReportModal
        isOpen={isShowingCreateModal}
        handleClose={() => showModal(false)}
        clientId={selectedClient ? selectedClient.clientId : null}
      />
    </>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  bold: {
    fontWeight: 'bold'
  },
  noReportsWrapper: {
    minHeight: theme.spacing(15),
    backgroundColor: '#f1f2f2',
    marginTop: theme.spacing(1),
    padding: theme.spacing(2)
  },
  noReportsText: {
    margin: theme.spacing(1, 0),
    fontWeight: 'bold',
    textAlign: 'center',
    [theme.breakpoints.up('md')]: {
      textAlign: 'left'
    }
  },
  noReportsIcon: {
    fontSize: theme.spacing(4)
  }
}));
