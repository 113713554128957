import React, { FC, useState, useEffect } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { useMedia } from 'react-use';
import { deepEqual } from 'fast-equals';
import { Save } from '@material-ui/icons';
// Components
import Typography from '@material-ui/core/Typography';
import { Toast } from '@shared/components/toast';
import Fade from '@material-ui/core/Fade';
import CardActions from '@material-ui/core/CardActions';
import Backdrop from '@material-ui/core/Backdrop';
import CardHeader from '@material-ui/core/CardHeader';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import { Modal } from '@shared/components/modals/Modal';
import { Table, ITableColumn } from '@shared/components/tables';
import { Loader, MobileExpanderLoader } from '@shared/components/loader';
import { MobileBudgetAdjustment } from '../mobile';
// Types
import { IBudgetHistoryRecord } from '@shared/types';
// fetch
import { getBudgetHistory, createBudgetHistory } from '@shared/fetch';
import { formatShortFriendlyDateWithTime, formatMoney, stripMoneyFormat } from '@shared/helpers';
import { IClientProjectFormValues } from '../forms/ClientProjectForm';

interface IBudgetAdjustmentProps extends Pick<IClientProjectFormValues, 'clientProjectId' | 'blendedRate'> {
  open: boolean;
  onClose: (data: Pick<IClientProjectFormValues, 'budgetedAmount' | 'scopedHours' | 'comment'>) => void;
}

const commaRegEx = /,/g;

const BudgetAdjustmentSchema = Yup.object().shape({
  budgetedAmount: Yup.string().nullable().required('Required'),
  scopedHours: Yup.string().nullable().required('Required'),
  comment: Yup.string().nullable().required('Required')
});

export const BudgetAdjustment: FC<IBudgetAdjustmentProps> = ({ open, onClose, clientProjectId, blendedRate }) => {
  const classes = useStyles();
  const [budgetHistory, setBudgetHistory] = useState<IBudgetHistoryRecord[]>([]);
  const [budgetTotal, setBudgetTotal] = useState<number | null>(null);
  const [scopeHoursTotal, setScopeTotal] = useState<number | null>(null);
  const [isLoading, setLoading] = useState<boolean>(true);
  const [isError, showError] = useState<boolean>(false);
  const [isSuccess, showSuccess] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  /** Shows the user what the blended rate will be for the new row entry. NOT the `blendedRate` prop. */
  const [newBlendedRate, setNewBlendedRate] = useState<string>('');

  const isDesktop = useMedia('(min-width: 960px)');

  const fetchBudgetHistory = async () => {
    setBudgetHistory([]);
    if (!isLoading) setLoading(true);
    if (!clientProjectId) {
      setLoading(false);
      return;
    }
    try {
      const res = await getBudgetHistory(clientProjectId);
      setBudgetTotal(res.budgetedAmount);
      setScopeTotal(res.scopedHours);
      setBudgetHistory(res.history);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchBudgetHistory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={{
          budgetedAmount: '',
          scopedHours: '',
          comment: ''
        }}
        validationSchema={BudgetAdjustmentSchema}
        onSubmit={async (values, actions) => {
          if (!clientProjectId) return;
          try {
            await createBudgetHistory(clientProjectId, {
              budgetedAmount: Number(stripMoneyFormat(values.budgetedAmount)),
              scopedHours: Number(values.scopedHours.replace(commaRegEx, '')),
              comment: values.comment
            });
            fetchBudgetHistory();
            showSuccess(true);
            actions.resetForm();
            setNewBlendedRate('');
          } catch (error) {
            console.error(error);
            // @ts-ignore
            if (error?.response?.data?.Detail) setErrorMessage(error.response.data.Detail);
            showError(true);
          }
        }}
      >
        {({ resetForm, isSubmitting, values, initialValues, setFieldValue, errors, touched, dirty, isValid, handleBlur }) => {
          const hasMaxScopedHoursError = Number(values.scopedHours.replace(commaRegEx, '')) > 32767;

          return (
            <Modal
              className={classes.modal}
              maxWidth='md'
              open={open}
              onClose={() => {
                if (!deepEqual(initialValues, values)) {
                  const closeModal = window.confirm('You have unsaved changes, are you sure you want to close the modal?');
                  if (!closeModal) return;
                  resetForm();
                  setNewBlendedRate('');
                }
                onClose({
                  budgetedAmount: budgetTotal,
                  scopedHours: scopeHoursTotal,
                  comment: budgetHistory?.[0]?.comment ?? ''
                });
              }}
              closeAfterTransition
              BackdropComponent={Backdrop}
              BackdropProps={{
                timeout: 500
              }}
            >
              {isSubmitting && <Loader type='overlay' position='centered' />}
              <Fade in={open}>
                <Form>
                  <div className={classes.root}>
                    <CardHeader title='Budget Adjustment' />
                    <Grid container spacing={1} alignItems='center' className={classes.inputWrapper}>
                      <Grid item xs={12} md={5}>
                        <FormControl fullWidth>
                          <TextField
                            label='New Total Budget Amount'
                            id='amount-text-field'
                            value={values.budgetedAmount}
                            name='budgetedAmount'
                            error={(errors.budgetedAmount && touched.budgetedAmount) as boolean}
                            helperText={touched.budgetedAmount && errors.budgetedAmount}
                            onBlur={e => {
                              const { value } = e.target;
                              const cleanedValue = Number(stripMoneyFormat(value));
                              setFieldValue('budgetedAmount', formatMoney(cleanedValue, 0));
                              handleBlur(e);

                              // Get budgeted amount as a number from `value`, which may be "41000" or "$41,000" if input is non-empty
                              const budgetedAmount = +value || cleanedValue;

                              // When budget amount is entered and scoped hours is blank, compute scoped hours as `round(budget / blendedrate)`
                              const scopedHours = Math.round(budgetedAmount / blendedRate);
                              if (!values.scopedHours) setFieldValue('scopedHours', '' + scopedHours);

                              // Recompute readonly blended rate field every time either Budget or Hours is edited
                              const rate = formatMoney(budgetedAmount / (+values.scopedHours || scopedHours), 2);
                              setNewBlendedRate(rate);
                            }}
                            onChange={({ target: { value } }) => setFieldValue('budgetedAmount', value)}
                            variant='outlined'
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} md={5}>
                        <FormControl fullWidth>
                          <TextField
                            label='New Total Scoped Hours'
                            id='hours-text-field'
                            type='number'
                            value={values.scopedHours}
                            name='scopedHours'
                            error={((errors.scopedHours && touched.scopedHours) as boolean) || hasMaxScopedHoursError}
                            helperText={
                              (touched.scopedHours && errors.scopedHours) || (hasMaxScopedHoursError ? 'Max amount for scoped hours is 32767' : '')
                            }
                            onBlur={handleBlur}
                            onChange={({ target: { value } }) => {
                              setFieldValue('scopedHours', value);

                              // Recompute readonly blended rate field every time either Budget or Hours is edited
                              if (!value) return;
                              const budget = Number(stripMoneyFormat(values.budgetedAmount));
                              setNewBlendedRate(formatMoney(budget / +value, 2));
                            }}
                            variant='outlined'
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} md={2}>
                        <TextField
                          disabled
                          label='Blended Rate'
                          id='blended-rate-text-field'
                          name='blendedRate'
                          variant='outlined'
                          value={newBlendedRate}
                        />
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <FormControl fullWidth>
                          <TextField
                            label='Comment'
                            id='comment-text-field'
                            value={values.comment}
                            name='comment'
                            error={(errors.comment && touched.comment) as boolean}
                            helperText={touched.comment && errors.comment}
                            onBlur={handleBlur}
                            onChange={({ target: { value } }) => setFieldValue('comment', value)}
                            multiline={true}
                            rows={3}
                            inputProps={{
                              style: {
                                resize: 'vertical',
                                minHeight: '100px'
                              }
                            }}
                            variant='outlined'
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12}>
                        <CardActions>
                          <Button
                            startIcon={<Save />}
                            disabled={!dirty || isSubmitting || !isValid || hasMaxScopedHoursError}
                            variant='contained'
                            color='secondary'
                            type='submit'
                          >
                            Save
                          </Button>
                          <Button
                            type='button'
                            variant='contained'
                            disabled={!dirty || isSubmitting || !isValid}
                            color='default'
                            onClick={() => {
                              resetForm();
                              setNewBlendedRate('');
                            }}
                          >
                            Cancel
                          </Button>
                        </CardActions>
                      </Grid>
                    </Grid>
                    <Table
                      data={budgetHistory}
                      columns={
                        [
                          {
                            Header: 'Budget',
                            accessor: 'budgetedTotal',
                            sort: false,
                            Cell: ({
                              cell: {
                                row: { original }
                              }
                            }: {
                              cell: { row: { original: IBudgetHistoryRecord } };
                            }) => {
                              return <span>{formatMoney(original.budgetedTotal)}</span>;
                            }
                          },
                          {
                            Header: 'Hours',
                            accessor: 'scopedHoursTotal',
                            sort: false,
                            Cell: ({
                              cell: {
                                row: { original }
                              }
                            }: {
                              cell: { row: { original: IBudgetHistoryRecord } };
                            }) => {
                              return <span>{original.scopedHoursTotal}</span>;
                            }
                          },
                          {
                            Header: 'Comment',
                            accessor: 'comment',
                            sort: false,
                            Cell: ({
                              cell: {
                                row: { original }
                              }
                            }: {
                              cell: { row: { original: IBudgetHistoryRecord } };
                            }) => {
                              return (
                                <Typography paragraph className={classes.comment}>
                                  {original.comment}
                                </Typography>
                              );
                            }
                          },
                          {
                            Header: 'Created',
                            sort: false,
                            // @ts-ignore
                            Cell: ({
                              cell: {
                                row: { original }
                              }
                            }: {
                              cell: { row: { original: IBudgetHistoryRecord } };
                            }) => {
                              return (
                                <span className={classes.noWrap}>
                                  {formatShortFriendlyDateWithTime(original.createdDate)}
                                  <div>{original.createdByName}</div>
                                </span>
                              );
                            }
                          }
                        ] as ITableColumn[]
                      }
                      stickyHeader
                      isLoading={isLoading}
                      hidePagination
                      ResponsiveComponent={MobileBudgetAdjustment}
                      ResponsiveComponentLoader={MobileExpanderLoader}
                      containerClasses={isDesktop ? classes.desktopTable : classes.mobileTable}
                      rowClasses={classes.rowAlignTop}
                      noResultsText='No history found.'
                    />
                  </div>
                </Form>
              </Fade>
            </Modal>
          );
        }}
      </Formik>
      <Toast id='budget-history-success' message='Budget History Added!' open={isSuccess} onClose={() => showSuccess(false)} variant='success' />
      <Toast
        id='budget-history-error'
        autoHideDuration={6000}
        message={
          errorMessage ||
          'We were unable to create the budget history at this time. Please try again later. Please contact MercuryWorks support if this issue continues.'
        }
        open={isError}
        onClose={() => showError(false)}
        variant='error'
      />
    </>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    root: {
      width: '275px',
      '@media (min-width: 567px)': {
        width: 'auto'
      }
    },
    mobileTable: {
      padding: 0
    },
    desktopTable: {
      paddingLeft: 5,
      paddingRight: 5
    },
    inputWrapper: {
      padding: theme.spacing(1)
    },
    comment: {
      wordWrap: 'break-word',
      margin: 0
    },
    rowAlignTop: {
      verticalAlign: 'top'
    },
    noWrap: {
      whiteSpace: 'nowrap'
    }
  })
);
