import React, { FC, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { object, array, string } from 'yup';
import { useParams } from 'react-router-dom';
import { Form, Formik } from 'formik';
// Fetch
import { getExecutionSteps, getVerificationSteps, updateExecutionSteps, updateVerificationSteps } from '@shared/fetch';
// Components
import { DashboardCard } from '@src/clients/components/DashboardCard';
import { QueryObserverResult, RefetchOptions, RefetchQueryFilters, useQuery } from 'react-query';
import { Loader } from '@shared/components/loader';
import { Toast } from '@shared/components/toast';
import { ReleaseVerificationSteps } from './ReleaseVerificationSteps/ReleaseVerificationSteps';
import { ReleaseProcedureSteps } from './ReleaseProcedureSteps/ReleaseProcedureSteps';

const ReleaseDetailsSchema = object().shape({
  executionSteps: array()
    .of(
      object().shape({
        name: string().min(1, 'Text is required').required('Text is required'),
      })
    )
    .required('Text is required'),
});

interface IReleaseDetailsProps {
  refetchCanDeliveryLeadSave: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
  ) => Promise<QueryObserverResult<boolean, any>>;
}

const ReleaseDetails: FC<IReleaseDetailsProps> = ({ refetchCanDeliveryLeadSave }) => {
  const classes = useStyles();

  const { releaseId }: { releaseId: string; clientId: string } = useParams();

  const {
    isLoading: isLoadingExecutionSteps,
    data: executionSteps,
    refetch: refetchExecutionSteps,
    isRefetching: isRefetchingExecutionSteps
  } = useQuery<any[], Error>(
    ['executionPlans', releaseId],
    async () => {
      const steps = await getExecutionSteps(releaseId);
      const orderedReleaseExecutionSteps = steps.map((step: any, index: number) => ({
        ...step,
        order: index + 1
      }));
      refetchCanDeliveryLeadSave();
      return orderedReleaseExecutionSteps;
    },
    {
      notifyOnChangeProps: 'tracked',
      enabled: !!releaseId
    }
  );

  const {
    isLoading: isLoadingVerificationSteps,
    data: verificationSteps,
    refetch: refetchVerificationSteps,
    isRefetching: isRefetchingVerificationSteps
  } = useQuery<any[], Error>(
    ['verificationSteps', releaseId],
    async () => {
      const steps = await getVerificationSteps(releaseId);
      const orderedReleaseVerificationSteps = steps.map((step: any, index: number) => ({
        ...step,
        order: index + 1
      }));
      refetchCanDeliveryLeadSave();
      return orderedReleaseVerificationSteps;
    },
    {
      notifyOnChangeProps: 'tracked',
      enabled: !!releaseId
    }
  );

  const [{ message: PageMessage, variant: pageVariant, isOpen: pageToastIsOpen }, setPageToast] = useState<{
    message: string;
    variant: 'error' | 'success';
    isOpen: boolean;
  }>({
    message: '',
    variant: 'success',
    isOpen: false
  });

  const [isSubmittingForm, setIsSubmittingForm] = useState(false);

  return (
    <>
      <section className={classes.wrapper}>
        <Formik
          enableReinitialize={true}
          initialValues={{
            executionSteps: executionSteps ?? [],
            verificationSteps: verificationSteps ?? []
          }}
          validationSchema={ReleaseDetailsSchema}
          onSubmit={async (values: any, actions: any) => {
            try {
              setIsSubmittingForm(true);
              const orderedReleaseExecutionSteps = values.executionSteps.map((step: any, index: number) => ({
                ...step,
                releaseExecutionStepId: step.id,
                order: index + 1
              }));

              await updateExecutionSteps(releaseId, { updates: orderedReleaseExecutionSteps });

              const orderedReleaseVerificationSteps = values.verificationSteps.map((step: any, index: number) => ({
                ...step,
                releaseVerificationStepId: step.id,
                order: index + 1
              }));

              await updateVerificationSteps(releaseId, { updates: orderedReleaseVerificationSteps });

              await refetchExecutionSteps();
              await refetchVerificationSteps();

              setPageToast({
                message: 'Release Details updated successfully',
                variant: 'success',
                isOpen: true
              });
              setIsSubmittingForm(false);
            } catch (error) {
              console.error('Error saving Release Details', error);
              setPageToast({
                message: 'Error saving Release Details',
                variant: 'error',
                isOpen: true
              });
              setIsSubmittingForm(false);
            }
          }}
        >
          {({
            isSubmitting,
            initialValues,
            values,
            handleChange,
            resetForm,
            setFieldValue,
            handleSubmit,
            dirty,
            isValid,
            handleBlur,
            errors,
            touched
          }) => {
            return (
              <>
                <Grid container direction='column' spacing={2}>
                  <Grid item>
                    <DashboardCard
                      setHeight={false}
                      isColumn={false}
                      hideTitle={true}
                      cardPadding='0'
                      mobileStyles={{ maxWidth: '100%', padding: '1rem' }}
                    >
                      <Form onSubmit={handleSubmit} autoComplete='none'>
                        {isLoadingExecutionSteps || isSubmittingForm ? (
                          <div className={classes.loader}>
                            <Loader type='inline' position='centered' />
                          </div>
                        ) : (
                          <ReleaseProcedureSteps
                            setFieldValue={setFieldValue}
                            values={values.executionSteps}
                            initialValues={initialValues.executionSteps}
                            setPageToast={setPageToast}
                            touched={touched}
                            errors={errors}
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            handleSubmit={handleSubmit}
                            isSubmitting={isSubmitting}
                            dirty={dirty}
                            isValid={isValid}
                            refetchExecutionPlans={refetchExecutionSteps}
                            isRefetchingExecutionPlans={isRefetchingExecutionSteps}
                          />
                        )}
                      </Form>
                    </DashboardCard>
                  </Grid>
                  <Grid item>
                    <DashboardCard
                      setHeight={false}
                      isColumn={false}
                      hideTitle={true}
                      cardPadding='0'
                      mobileStyles={{ maxWidth: '100%', padding: '1rem' }}
                    >
                      <Form onSubmit={handleSubmit} autoComplete='none'>
                        {isLoadingVerificationSteps || isSubmittingForm ? (
                          <div className={classes.loader}>
                            <Loader type='inline' position='centered' />
                          </div>
                        ) : (
                          <ReleaseVerificationSteps
                            setFieldValue={setFieldValue}
                            values={values.verificationSteps}
                            initialValues={initialValues.verificationSteps}
                            setPageToast={setPageToast}
                            touched={touched}
                            errors={errors}
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            handleSubmit={handleSubmit}
                            isSubmitting={isSubmitting}
                            dirty={dirty}
                            isValid={isValid}
                            refetchVerificationSteps={refetchVerificationSteps}
                            isRefetchingVerificationSteps={isRefetchingVerificationSteps}
                          />
                        )}
                      </Form>
                    </DashboardCard>
                  </Grid>
                </Grid>
              </>
            );
          }}
        </Formik>
        <Toast
          id='page-toast'
          message={PageMessage}
          open={pageToastIsOpen}
          onClose={() =>
            setPageToast({
              message: '',
              variant: pageVariant,
              isOpen: false
            })
          }
          variant={pageVariant}
        />
      </section>
    </>
  );
};

const useStyles = makeStyles(theme => ({
  wrapper: {
    paddingTop: theme.spacing(0.125)
  },
  cardHolder: {
    alignItems: 'stretch'
  },
  loader: {
    margin: '24px auto 12px auto',
    width: '100%',
    alignItems: 'center',
    '@media (max-width: 600px)': {
      marginTop: 25
    }
  }
}));

export default ReleaseDetails;
