import React, { FC, useEffect } from 'react';
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import {
  Grid,
  TextField,
  InputAdornment,
  Typography,
  FormControl,
  Select,
  IconButton,
  MenuItem,
  Checkbox,
  ListItemText,
  OutlinedInput,
  useMediaQuery
} from '@material-ui/core';
import { RequestsFilterButtons } from '../../../admin/components/filters';
import { Close, Search } from '@material-ui/icons';
// Types
import { IClientRelationsLookup, IClientLookup } from '@shared/types';

interface ISoftwareReleaseFilters {
  isLoading: boolean;
  applyFilters: (clearFilters?: boolean) => void;
  setHasAppliedFilters: (val: boolean) => void;
  hasAppliedFilters?: boolean;
  searchTitle: string;
  setSearchTitle: (val: any) => void;
  handleTitleSearch: (clearSearch?: boolean) => void;
  setSelectedStatusIds: (val: any) => void;
  clearFilters?: boolean;
  hasTitle?: boolean;
  statuses: IClientRelationsLookup[];
  selectedStatusValues: any[];
  setSelectedStatusValues: (val: any[]) => void;
  fetchReleaseStatuses: () => void;
  isLoadingStatuses: boolean;
  wrapperClass?: string;
  clients?: IClientLookup[];
  selectedClient?: number | null;
  setSelectedClient?: (val: number | null) => void;
}

export const SoftwareReleaseFilters: FC<ISoftwareReleaseFilters> = ({
  hasAppliedFilters,
  setHasAppliedFilters,
  isLoading,
  applyFilters,
  setSelectedStatusIds,
  clearFilters,
  searchTitle,
  setSearchTitle,
  handleTitleSearch,
  hasTitle,
  statuses,
  selectedStatusValues,
  setSelectedStatusValues,
  fetchReleaseStatuses,
  isLoadingStatuses,
  wrapperClass,
  clients,
  selectedClient,
  setSelectedClient
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('md'));
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    if (clearFilters) {
      setHasAppliedFilters(false);
      setSelectedStatusIds('');
      applyFilters(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleStatusFilterChange = (e: any, s: any) => {
    const {
      target: { value }
    } = e;

    let values = value.slice(0, -1);
    let selected = value.pop();
    let checked = s.props.children[0].props.checked;
    const newValues = () => {
      if (checked) {
        return values?.filter((item: any) => item?.value !== selected);
      } else {
        return [...values, statuses.find((status: any) => status.value === selected)];
      }
    };

    setSelectedStatusValues(newValues());
    const valuesArray = newValues().map((item: any) => item.value);
    setSelectedStatusIds(valuesArray);
  };

  return (
    <Grid container alignItems='center' spacing={1} className={wrapperClass}>
      {!isLargeScreen && (
        <Grid item sm={12}>
          {hasTitle && <Typography className={classes.modalTitle}>Filter Releases</Typography>}
        </Grid>
      )}
      {/* statusFilter */}
      <Grid item sm={12} md={4} style={isMobileScreen ? { width: '100%' } : {}}>
        <div className={classes.statusFilter}>
          <FormControl size='small' fullWidth className={classes.filters}>
            <Select
              labelId={'status-filter-label'}
              id={'status-filter'}
              multiple
              fullWidth
              displayEmpty
              value={selectedStatusValues}
              disabled={isLoadingStatuses || isLoading}
              input={<OutlinedInput disabled={isLoadingStatuses} />}
              MenuProps={{
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left'
                },
                transformOrigin: {
                  vertical: 'top',
                  horizontal: 'left'
                },
                getContentAnchorEl: null,
                PaperProps: {
                  style: {
                    maxHeight: 300
                  }
                }
              }}
              endAdornment={
                selectedStatusValues.length > 0 ? (
                  <InputAdornment position='end' style={{ position: 'absolute', right: 26 }}>
                    <IconButton
                      onClick={() => {
                        setSelectedStatusValues([]);
                        setSelectedStatusIds([]);
                      }}
                      style={{ padding: '4px' }}
                      aria-label='Clear'
                    >
                      <Close fontSize='small' />
                    </IconButton>
                  </InputAdornment>
                ) : null
              }
              onChange={handleStatusFilterChange}
              renderValue={(selected: any) => {
                if (selected.length === 0) {
                  return <span className={classes.placeholder}>Select Status...</span>;
                }
                return [
                  selectedStatusValues.map((status: any) => status.shorthand)[0],
                  selectedStatusValues.length > 1 ? ` (+${selected.length - 1})` : ''
                ];
              }}
              variant='outlined'
              placeholder='Select Status...'
            >
              {statuses.map((option: any) => (
                <MenuItem key={option?.key} value={option?.value}>
                  <Checkbox checked={selectedStatusValues.indexOf(option) > -1} style={{ paddingTop: 0, paddingBottom: 0 }} />
                  <ListItemText primary={option.shorthand} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      </Grid>
      {/* client filter */}
      <Grid item sm={12} md={4} style={isMobileScreen ? { width: '100%' } : {}}>
        <div className={classes.statusFilter}>
          <FormControl size='small' fullWidth className={classes.filters}>
            <Select
              labelId={'client-filter-label'}
              id={'client-filter'}
              fullWidth
              displayEmpty
              value={selectedClient !== null ? selectedClient : ''}
              onChange={(e: React.ChangeEvent<{ value: unknown }>) => setSelectedClient && setSelectedClient(Number(e.target.value))}
              input={<OutlinedInput />}
              MenuProps={{
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left'
                },
                transformOrigin: {
                  vertical: 'top',
                  horizontal: 'left'
                },
                getContentAnchorEl: null,
                PaperProps: {
                  style: {
                    maxHeight: 300
                  }
                }
              }}
              renderValue={selected => {
                if (!selected) {
                  return <span className={classes.placeholder}>Client</span>;
                }
                const selectedClient = clients?.find(client => client.clientId === selected);
                return selectedClient ? selectedClient.name : 'Client';
              }}
              variant='outlined'
              placeholder='Client'
            >
              {clients?.map(client => (
                <MenuItem key={client.clientId} value={client.clientId}>
                  {client.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      </Grid>

      {/* search filter */}
      <Grid item sm={12} md={4} style={isMobileScreen ? { width: '100%' } : {}}>
        <TextField
          className={classes.filters}
          size='small'
          fullWidth
          variant='outlined'
          placeholder='Search...'
          name='nameSearch'
          value={searchTitle}
          disabled={isLoading}
          InputProps={{
            startAdornment: (
              <InputAdornment
                position='start'
                className={classes.searchIcon}
                onClick={() => {
                  if (searchTitle && searchTitle.length > 0) {
                    setHasAppliedFilters(true);
                    handleTitleSearch();
                  }
                }}
              >
                <Search />
              </InputAdornment>
            ),
            endAdornment: searchTitle ? (
              <InputAdornment
                position='end'
                className={classes.searchIcon}
                onClick={() => {
                  setSearchTitle('');
                  handleTitleSearch(true);
                }}
              >
                <Close />
              </InputAdornment>
            ) : null
          }}
          onKeyDown={e => {
            if (e.key === 'Enter' && searchTitle && searchTitle.length > 0) {
              setHasAppliedFilters(true);
              handleTitleSearch();
            }
          }}
          onChange={e => {
            setSearchTitle(e.target.value);
          }}
        />
      </Grid>
      {/* apply filters */}
      <Grid item sm={12} style={isMobileScreen ? { width: '100%' } : {}}>
        <div className={classes.buttonContainer}>
          <RequestsFilterButtons
            marginTop={0}
            hasAppliedFilters={hasAppliedFilters}
            isDisabled={isLoading}
            handleApplyFilters={() => {
              setHasAppliedFilters(true);
              applyFilters();
            }}
            handleResetFilters={() => {
              if (setSelectedClient) {
                setSelectedClient(null);
              }
              setHasAppliedFilters(false);
              setSelectedStatusIds([1, 2, 3, 4, 5, 6, 7]);
              setSearchTitle('');
              fetchReleaseStatuses();
              applyFilters(true);
            }}
          />
        </div>
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  searchIcon: {
    cursor: 'pointer',
    color: theme.palette.grey[500]
  },
  iconButton: {
    padding: 0
  },
  label: {
    marginRight: theme.spacing(1),
    color: '#5F5F5F',
    fontSize: '1rem',
    fontWeight: 600
  },
  placeholder: {
    color: theme.palette.grey[500]
  },
  filters: {
    '&& .MuiInputBase-root': {
      width: '100%',
      paddingLeft: theme.spacing(0.5)
    }
  },
  statusFilter: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    '&& .MuiInputBase-root': {
      width: '100%',
      paddingLeft: 0,
      paddingRight: 0
    }
  },
  inputRoot: {
    maxHeight: 38,
    '@media (max-width: 1100px)': {
      maxHeight: 'none'
    },
    '& .MuiAutocomplete-input': {
      minWidth: 0
    }
  },
  modalTitle: {
    fontWeight: 600,
    fontSize: '1rem'
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%'
  }
}));
