import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
// redux
import { useSelector, useDispatch } from 'react-redux';
// Components
import { ProgressReportDetail } from '../components/ProgressReportDetail';
import { IAppState } from '@shared/types';
import { Loader } from '@shared/components/loader';
// fetch
import { getSingleProgressReportBySprint } from '@shared/fetch';
// actions
import { setCurrentReport } from '@shared/redux/actions';

export const ProgressReportsCreate = () => {
  // redux
  const { currentReport } = useSelector((state: IAppState) => state.progressReports);
  const history = useHistory();
  const dispatch = useDispatch();
  // state
  const [isChecking, setChecking] = useState<boolean>(true);

  // check the report the user is trying to create doesn't already exist, if it does route the user to the correct page
  const fetchProgressReport = async () => {
    if (currentReport) {
      try {
        const report = await getSingleProgressReportBySprint(currentReport?.clientId, currentReport?.projectIterationUuid);
        if (report && report.clientPortfolioReportId) {
          history.push(`/clients/progress-reports/${report.clientPortfolioReportId}`);
          dispatch(setCurrentReport(report));
        }
      } catch (error) {
        console.log(error);
      } finally {
        setChecking(false);
      }
    } else {
      history.push('/clients/progress-reports');
    }
  };

  useEffect(() => {
    fetchProgressReport();
  }, []);
  if (isChecking) {
    return <Loader position='centered' />;
  }
  if (currentReport && !isChecking) {
    return <ProgressReportDetail currentReport={currentReport} isEditing={true} />;
  }
  return <></>;
};
