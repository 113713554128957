import { ITimesheetCompletionV2 } from '@shared/types';
import { authFetch } from './';

const baseUrl = '/api/LaborTracking/Timesheet';

export const fetchTimesheetCompletion = async (employeeId: string): Promise<number> => {

  try {
    const { data } = await authFetch.get(`${baseUrl}/GetTimesheetCompletion`, {params: {EmployeeId: employeeId}});
    return Promise.resolve(data);
  } catch (error) {
    console.log('error in fetchTimesheetCompletion', error)
    return Promise.reject(error);
  }
};

export const v2FetchTimesheetCompletion = async (includeIndirectReports: boolean | undefined): Promise<ITimesheetCompletionV2> => {

  try {
    const { data } = await authFetch.get(`${baseUrl}/v2/GetTimesheetCompletion`, {params: { IncludeIndirectReports: includeIndirectReports}});
    return Promise.resolve(data);
  } catch (error) {
    console.log('error in v2FetchTimesheetCompletion', error)
    return Promise.reject(error);
  }
};


export const userHasIndirectReports = async (): Promise<{hasIndirectReports: boolean}> => {

  try {
    const { data } = await authFetch.get(`${baseUrl}/user-has-indirect-reports`);
    return Promise.resolve(data);
  } catch (error) {
    console.log('error in userHasIndirectReports', error)
    return Promise.reject(error);
  }
};