import React, { FC } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
// Components
import { ExpansionPanel, ExpansionPanelSummary } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { SkeletonLoader } from '@shared/components/loader';

export const MobileExpanderLoader: FC = () => {
  const classes = useStyles();
  return (
    <>
      {Array.from(Array(5).keys()).map((x) => (
        <ExpansionPanel key={x} className={classes.root}>
          <ExpansionPanelSummary
            className={classes.expansionPanel}
            expandIcon={<ExpandMoreIcon />}
            aria-controls='panel1a-content'
            id='panel1a-header'
          >
            <SkeletonLoader className={classes.panelSummaryWrapper} width='100%' />
          </ExpansionPanelSummary>
        </ExpansionPanel>
      ))}
    </>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    maxWidth: '100%',
    marginBottom: theme.spacing(1),
  },
  expansionPanel: {
    padding: '0px 24px',
  },
  panelSummaryWrapper: {
    display: 'flex',
    alignItems: 'flex-start',
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
}));
