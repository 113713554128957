import React, { FC, useEffect, useState } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Box, Grid, Paper } from '@material-ui/core';
import { getUpcomingReleases } from '@shared/fetch';
import { IAppState, IUpcomingReleases } from '@shared/types';
import { SkeletonLoader } from '../loader';
import { useSelector } from 'react-redux';
import { format } from 'date-fns';

interface IUpconingReleasesContent {}

export const UpcomingReleasesContent: FC<IUpconingReleasesContent> = () => {
  const classes = useStyles();

  // Sprints state
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [releases, setReleases] = useState<IUpcomingReleases[]>([]);
  const { selectedClient } = useSelector((state: IAppState) => ({ ...state.extranet }));

  // Load Sprints
  const load = async () => {
    if (selectedClient) {
      try {
        const response = await getUpcomingReleases(selectedClient?.clientId);
        setReleases(response);
      } catch (error) {
        console.error('getUpcomingReleases', error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    load();
  }, [selectedClient]);
  return (
    <Grid container direction='column' alignItems='flex-start' justify='center'>
      {isLoading && <SkeletonLoader className={classes.loader} />}
      {!isLoading && releases.length > 0 && (
        <Paper elevation={0} className={classes.releaseContainer}>
          {releases.map((release: IUpcomingReleases) => {
            return (
              <Box key={`${release.name}-${release.releaseDate}`} className={classes.releaseRow}>
                <span className={classes.releaseText}>{release.name}</span>
                <span className={classes.releaseDate}>{release.releaseDate ? format(Date.parse(release.releaseDate), 'MMMM d') : 'TBD'}</span>
              </Box>
            );
          })}
        </Paper>
      )}
      {!isLoading && releases.length === 0 && (
        <Paper elevation={0} className={classes.releaseContainer}>
          <Box className={classes.releaseRow}>
            <span className={classes.releaseText}>No Upcoming Releases</span>
          </Box>
        </Paper>
      )}
    </Grid>
  );
};

const useStyles = makeStyles<Theme>((theme: Theme) => ({
  upcomingReleaseContainer: {
    backgroundColor: theme.palette.info.light
  },
  incrementCard: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0',
    paddingBottom: '0px !important' // Need to specifically override material styling
  },
  releaseContainer: {
    margin: theme.spacing(0.5, 0, 1, 0),
    padding: theme.spacing(1, 1, 1, 1),
    backgroundColor: theme.palette.info.light,
    width: '100%',
    height: '100%',
    display: 'flex',
    fontSize: '14px',
    lineHeight: '1.3',
    marginBottom: '8px',
    justifyContent: 'flex-start',
    flexDirection: 'column'
  },
  releaseDate: {
    color: theme.palette.primary.main
  },
  releaseText: {
    color: '#5E5E5E'
  },
  releaseRow: {
    justifyContent: 'space-between',
    flexDirection: 'row',
    display: 'flex',
    marginBottom: theme.spacing(0.5)
  },
  releaseBody: {
    padding: theme.spacing(0, 1, 1, 1),
    color: theme.palette.primary.main,
    fontWeight: 'normal'
  },
  loader: {
    width: '100%',
    height: '100%'
  }
}));
