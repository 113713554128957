export enum userAccess {
  VIEW_ADMIN = 'ViewAdmin',
  VIEW_EMPLOYEES = 'ViewEmployees',
  VIEW_CLIENTS = 'ViewClients',
  CLIENT_DATA = 'ClientData',
  CLIENT_ONLY = 'ClientOnly',
  VIEW_ADMIN_CLIENT_PROJECTS = 'ViewAdminClientProjects',
  VIEW_ADMIN_EMPLOYEES = 'ViewAdminEmployees',
  VIEW_ADMIN_JOB_FORM = 'ViewAdminJobForm',
  VIEW_USER_ROLE_HISTORY = 'ViewUserRoleHistory',
  VIEW_MANAGING_USERS = 'ViewManageUsers',
  VIEW_EMBED_REPORTS = 'ViewEmbedReports',
  CLIENT_COLLABOTARTOR_DATA = 'ClientCollaboratorData',
  CLIENT_ADMIN_DATA = 'ClientAdminData',
  CLIENT_FINANCIALS_DATA = 'ClientFinancialsData'
}
