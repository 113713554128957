import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { useMedia } from 'react-use';
// components
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import { SkeletonLoader } from './SkeletonLoader';

export const GridLoader = ({ rows }: { rows?: number }) => {
  const classes = gridLoaderStyles();
  const numOfRows = Array.from(Array(rows || 5).keys());
  const isMobile = useMedia('(max-width: 768px)');
  return (
    <>
      {numOfRows.map(num => {
        return (
          <ExpansionPanel classes={{ root: classes.panelRoot }} key={`${num}`}>
            <ExpansionPanelSummary
              className={classes.userStoryTitle}
              classes={{ root: classes.panelSummaryRootLoading }}
              aria-controls={`user-epic-content-${num}`}
              id={`user-epic-header-${num}`}
            >
              <SkeletonLoader width={isMobile ? 300 : 400} />
            </ExpansionPanelSummary>
          </ExpansionPanel>
        );
      })}
    </>
  );
};

const gridLoaderStyles = makeStyles((theme: Theme) => ({
  userStoryTitle: {
    minHeight: '100%',
    '&& .MuiExpansionPanelSummary-content .Mui-expanded': {
      margin: '0'
    }
  },
  panelRoot: {
    margin: theme.spacing(1, 0),
    '&& .MuiPaper-elevation1': {
      boxShadow: 'none'
    }
  },
  panelSummaryRootLoading: {
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
    borderRight: `1px solid ${theme.palette.grey[300]}`,
    borderLeft: `3px solid ${theme.palette.grey[300]}`,
    paddingLeft: `10px`
  }
}));
