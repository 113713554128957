/**
 * HTML generated by Quill when the WYSIWYG editor is "blank"
 */
const emptyWysiwygHtml: string[] = [
  '<div></div>',
  '<h1><br></h1>',
  '<h2><br></h2>',
  '<h3><br></h3>',
  '<h4><br></h4>',
  '<h5><br></h5>',
  '<h6><br></h6>',
  '<p><br></p>',
  '<h1> </h1>',
  '<h2> </h2>',
  '<h3> </h3>',
  '<h4> </h4>',
  '<h5> </h5>',
  '<h6> </h6>',
  '<p> </p>',
  '<h1></h1>',
  '<h2></h2>',
  '<h3></h3>',
  '<h4></h4>',
  '<h5></h5>',
  '<h6></h6>',
  '<p></p>'
];

/**
 * Check if Quill WYISWYG editor content is actually empty
 * @param content string | undefined
 * @returns boolean
 */
export const isWysiwygEmpty = (content?: string): boolean => {
  let empty = true;

  if (content) {
    content = content.trim();

    const html = emptyWysiwygHtml.find(emptyHtml => emptyHtml === content);
    if (!html) {
      empty = false;
    }
  }

  return empty;
};
