import React from 'react';
import { useHistory } from 'react-router-dom';
// redux
import { useSelector } from 'react-redux';
// Components
import { ProgressReportDetail } from '../components/ProgressReportDetail';
import { IAppState } from '@shared/types';

export const ProgressReportPreview = () => {
  const history = useHistory();
  // redux
  const { currentReport } = useSelector((state: IAppState) => state.progressReports);

  if (currentReport) {
    return <ProgressReportDetail currentReport={currentReport} isPreview={true} />;
  }
  history.push('/clients/progress-reports');
  return <></>;
};
