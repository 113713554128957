import { handleActions } from 'redux-actions';
// Types
import { IEmployeeState } from '@shared/types';

const initialState: IEmployeeState = {
  sourceTypes: []
};

export default handleActions(
  {
    SET_SOURCE_TYPES: (state: any, { payload: { sourceTypes } }) => ({ ...state, sourceTypes })
  },
  initialState
);
