import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { List, ListItem } from '@material-ui/core';
import { DragIndicator } from '@material-ui/icons';
import React, { FC, PropsWithChildren } from 'react';
import { ListItemColors, getStyle } from './util';

interface IOptionListItem {
  item: any;
  id: string;
  color?: ListItemColors;
  listPadding?: string;
}

export const OptionListItem: FC<PropsWithChildren<IOptionListItem>> = ({ item, id, children, color = ListItemColors.Default, listPadding }) => {
  const { attributes, listeners, transform, transition, setNodeRef, isDragging } = useSortable({
    id: id
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition: transition,
    backgroundColor: 'white'
  };

  const listStyle = {
    padding: listPadding || '0.25rem 0'
  };

  return (
    <List dense style={{ ...style, ...listStyle }} ref={setNodeRef}>
      {isDragging ? (
        <ListItem key={id} style={getStyle(color)}>
          <DragIndicator />
          {children}
        </ListItem>
      ) : (
        <ListItem key={id} style={getStyle(color)}>
          <DragIndicator {...attributes} {...listeners} />
          {children}
        </ListItem>
      )}
    </List>
  );
};
