import React, { FC, ReactNode } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
// Components
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

interface ICustomCardProps {
  icon?: ReactNode;
  title?: string;
  content?: string;
  iconColor?: string;
  backgroundColor?: string;
  borderRadius?: string;
  maxWidth?: number;
  titleColor?: string;
  contentColor?: string;
  titleFontSize?: string;
  contentFontSize?: string;
}

export const CustomCard: FC<ICustomCardProps> = ({ 
  icon,
  title,
  content,
  iconColor,
  backgroundColor,
  borderRadius,
  maxWidth,
  titleColor,
  contentColor,
  titleFontSize,
  contentFontSize,
 }) => {
  const classes = useStyles({ 
    iconColor, 
    backgroundColor, 
    borderRadius, 
    maxWidth, 
    titleColor, 
    contentColor,
    titleFontSize,
    contentFontSize 
  });

  return (
    <Card className={classes.root}>
      <CardContent>
        <div className={classes.icon}>{icon}</div>
        <Typography className={classes.title}>{title}</Typography>
        <Typography className={classes.content}>{content}</Typography>
      </CardContent>
    </Card>
  );
};

const useStyles = makeStyles<Theme, { 
  iconColor?: string; 
  backgroundColor?: string; 
  borderRadius?: string; 
  maxWidth?: number; 
  titleColor?: string; 
  contentColor?: string;
  titleFontSize?: string;
  contentFontSize?: string;
}>(theme => ({
  root: {
    backgroundColor: ({ backgroundColor }) => backgroundColor,
    borderRadius: ({ borderRadius }) => borderRadius,
    padding: theme.spacing(1),
    textAlign: 'center',
    maxWidth: ({ maxWidth }) => maxWidth,
    margin: 'auto',
  },
  icon: {
    color: ({ iconColor }) => iconColor, 
  },
  title: {
    fontSize: ({ titleFontSize }) => titleFontSize, 
    fontWeight: 'bold',
    color: ({ titleColor }) => titleColor, 
  },
  content: {
    fontSize: ({ contentFontSize }) => contentFontSize, 
    color: ({ contentColor }) => contentColor, 
    marginTop: theme.spacing(0.5),
  },
}));
