import { Box, BoxProps, Grid } from '@material-ui/core';
import { DashboardCard } from '@src/clients/components/DashboardCard';
import React, { FC, ReactNode, useEffect } from 'react';
import ReactGA from 'react-ga';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { PageHeader, PageTitle } from '.';
import { IAppState } from '../../types';
import { makeStyles } from '@material-ui/core/styles';

interface IPage {
  title: string;
  children: ReactNode;
  /**
   * Allows consistent, optional rendering of controls and actions for the rest
   * of the displayed page. Not rendered when `hideHeader` is true.
   */
  actions?: () => ReactNode;
  actionsStyle?: BoxProps;
  /**
   * Controls visibility of the page header. Useful for when components using
   * <Page /> have a child element rendering its own <Page />.
   */
  hideHeader?: boolean;
  isCard?: boolean;
  isColumn?: boolean;
  overflow?: boolean;
  flexGrow?: boolean;
  setHeight?: boolean;
  hideTitle?: boolean;
  elevation?: number;
  footerSpacing?: number;
  rightAlignActions?: boolean;
  justifyContent?: string;
  cardHeaderIsBlockInMobile?: boolean;
  isTitleCenteredMobile?: boolean;
  titleGrow?: boolean;
  fullMobileWidth?: boolean;
}

export const Page: FC<IPage> = ({
  children,
  title,
  actions,
  actionsStyle,
  hideHeader = true,
  isCard = true,
  isColumn = false,
  overflow = false,
  flexGrow = true,
  setHeight = true,
  hideTitle = false,
  elevation = 12,
  footerSpacing = 0,
  rightAlignActions = true,
  justifyContent = '',
  cardHeaderIsBlockInMobile = false,
  isTitleCenteredMobile = false,
  titleGrow = true,
  fullMobileWidth = false
}) => {
  const classes = useStyles();
  const { pathname } = useLocation();
  const { selectedClient } = useSelector((state: IAppState) => state.extranet);
  const renderTitle = `${title} | My MercuryWorks`;

  // TODO: Fix: This does not work, the window title is not updated for the `/` route
  useEffect(() => {
    if (window.ga && pathname) {
      document.title = renderTitle;
      ReactGA.set({ dimension1: selectedClient && selectedClient.clientId, dimension2: selectedClient && selectedClient.name });
      ReactGA.pageview(pathname);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  return (
    <>
      <Grid container alignItems='flex-start' justify='space-between' spacing={2} className={classes.cardHolder}>
        <Grid item xs={fullMobileWidth ? 12 : undefined} lg={12}>
          {!hideHeader && (
            <PageHeader title={title} hiddenTitle={hideTitle}>
              {actions && (
                <Box display='flex' flex={1}>
                  <Box marginLeft={actionsStyle ? undefined : 'auto'} {...(actionsStyle ?? {})}>
                    {actions()}
                  </Box>
                </Box>
              )}
            </PageHeader>
          )}
          {/* Accessible title when overall page title is not shown (i.e. dashboard) */}
          {hideHeader && !isCard && <PageTitle title={title} hiddenTitle />}
          {isCard ? (
            <DashboardCard
              overflow={overflow}
              isColumn={isColumn}
              flexGrow={flexGrow}
              setHeight={setHeight}
              title={title}
              hideTitle={hideTitle}
              elevation={elevation}
              footerSpacing={footerSpacing}
              rightAlignActions={rightAlignActions}
              justifyContent={justifyContent}
              cardHeaderIsBlockInMobile={cardHeaderIsBlockInMobile}
              isTitleCenteredMobile={isTitleCenteredMobile}
              titleVariant='h2'
              titleComponent='h1'
              titleGrow={titleGrow}
              actions={
                actions && (
                  <Box display='flex' flex={1}>
                    <Box marginLeft={actionsStyle ? undefined : 'auto'} {...(actionsStyle ?? {})}>
                      {actions()}
                    </Box>
                  </Box>
                )
              }
            >
              {children}
            </DashboardCard>
          ) : (
            children
          )}
        </Grid>
      </Grid>
    </>
  );
};

const useStyles = makeStyles(theme => ({
  cardHolder: {
    alignItems: 'stretch',
    paddingBottom: theme.spacing(1) // Help Button Offset
  }
}));
