import React, { FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';
// Components
import { Tooltip, IconButton, Typography } from '@material-ui/core';
import { ChevronLeft, ChevronRight } from '@material-ui/icons';
import { formatDateWithDay } from '@shared/helpers';

interface IDayPicker {
  isLoading: boolean;
  day: string;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  handleChangeDay: (direction: string) => void;
}

export const DayPicker: FC<IDayPicker> = ({ isLoading, setIsOpen, day, handleChangeDay }) => {
  const classes = useStyles();

  return (
    <div className={classes.dayPicker}>
      <Tooltip title='Previous Day'>
        <span>
          <IconButton aria-label='previous' size='small' onClick={() => (isLoading ? false : handleChangeDay('prev'))}>
            <ChevronLeft color='secondary' />
          </IconButton>
        </span>
      </Tooltip>
      <Typography
        variant='h4'
        component='h3'
        display='inline'
        color='primary'
        className={isLoading ? 'fontWeight-normal' : classes.textDate}
        onClick={() => {
          if (isLoading) {
            return;
          } else {
            setIsOpen(true);
          }
        }}
      >
        {formatDateWithDay(day)}
      </Typography>
      <Tooltip title='Next Day'>
        <span>
          <IconButton aria-label='next' size='small' onClick={() => (isLoading ? false : handleChangeDay('next'))}>
            <ChevronRight color='secondary' />
          </IconButton>
        </span>
      </Tooltip>
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  dayPicker: {
    display: 'flex',
    alignItems: 'center'
  },
  textDate: {
    cursor: 'pointer',
    fontFamily: theme.typography.body1.fontFamily
  }
}));
