import { handleActions } from 'redux-actions';
// Types
import { IClientsState } from '@shared/types';

const initialState: IClientsState = {
  clients: [],
  clientProjects: [],
  clientProjectsList: [],
  selectedClient: null,
  clientProjectStatuses: [],
  clientProjectTypes: [],
  selectedProjectId: null,
  clientMentionLinks: [],
};

export default handleActions(
  {
    SET_CLIENT: (state: any, { payload: { client } }) => ({ ...state, selectedClient: client }),
    SET_CLIENTS: (state: any, { payload: { clients } }) => ({ ...state, clients }),
    SET_CLIENT_PROJECTS: (state: any, { payload: { clientProjects } }) => ({ ...state, clientProjects }),
    SET_CLIENT_PROJECTS_LIST: (state: any, { payload: { clientProjectsList } }) => ({ ...state, clientProjectsList }),
    SET_CLIENT_PROJECT_STATUSES: (state: any, { payload: { clientProjectStatuses } }) => ({ ...state, clientProjectStatuses }),
    SET_CLIENT_PROJECT_TYPES: (state: any, { payload: { clientProjectTypes } }) => ({ ...state, clientProjectTypes }),
    SET_CLIENT_MENTION_LINKS: (state: any, { payload: { clientMentionLinks } }) => ({ ...state, clientMentionLinks }),
    SET_SELECTED_PROJECT_ID: (state: any, { payload: { selectedProjectId } }) => ({ ...state, selectedProjectId }),
  },
  initialState
);
