import React, { FC } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
// components
import { SkeletonLoader } from './SkeletonLoader';

/**
 * NOTE: If you update styling here, you may need to update styling in the <UserStoryKanbanCard /> component.
 */
export const CardLoader: FC = () => {
  const classes = CardLoaderStyles();

  return (
    <article className={classes.card}>
      <header className={classes.header}>
        <SkeletonLoader />
      </header>
      <main className={classes.main}>
        <SkeletonLoader />
      </main>
      <footer className={classes.footer}>
        <SkeletonLoader />
      </footer>
    </article>
  );
};

const CardLoaderStyles = makeStyles((theme: Theme) => {
  return {
    card: {
      backgroundColor: theme.palette.background.paper,
      border: `1px solid ${theme.palette.grey[300]}`,
      marginBottom: theme.spacing(0.75),
      padding: theme.spacing(0.75, 0.75, 0.75, 1),
      position: 'relative',
      width: '100%',
      '&:before': {
        backgroundColor: '#58595b',
        bottom: 0,
        content: '""',
        display: 'block',
        left: 0,
        position: 'absolute',
        top: 0,
        width: theme.spacing(0.25),
        zIndex: 1
      }
    },
    footer: {},
    header: {
      marginBottom: theme.spacing(1)
    },
    main: {
      marginBottom: theme.spacing(1)
    }
  };
});
