import React, { FC, useState, useEffect } from 'react';
// Redux
import { useDispatch, useSelector, batch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { setClients, setSourceTypes, setEmployees, setClientProjectsList } from '@shared/redux/actions';
// Components
import { Timesheet } from '../components/forms';
import { Alert } from '@shared/components/alerts';
// Types
import { IAppState, ITimesheet, IWeeklyTimesheet, ITimeTrackingEntry } from '@shared/types';
// Fetch
import {
  getAllClients,
  getClientProjectsList,
  reduxFetch,
  getEmployeeTimeSheet,
  getEmployeeWeeklyTimeSheet,
  getSourceTypes,
  getReportingEmployees,
  getEmployees,
  checkEmployeeRole
} from '@shared/fetch';
import { isToday } from 'date-fns';
import { formatDate } from '@shared/helpers';

// group entries by external ID so that we don't break visual groupings in the UI
const groupByExternalId = (entries: ITimeTrackingEntry[]) => {
  // return list for entries after they have been grouped
  const groupedEntries: ITimeTrackingEntry[] = [];

  // flag entries that have been added to the grouped entries using externalId
  const isGrouped: { [key: number]: boolean } = {};

  // we need to flag entries added if they don't have timeEntryId, we will use suggestions
  const isSuggestionsGrouped: { [key: number]: boolean } = {};

  // Entries should be in order of timeEntryId coming from api, with exception to suggestions
  // which are always at the bottom of the list
  entries.forEach(entry => {
    // if its not a suggestion, check to see if its been grouped, if it has do nothing.
    // Otherwise we need to check if it has an externalId, if not add to groupedEntries
    // and flag it. If it has one, find all entries with that externalId and add them in order
    // to the grouped entries and mark them as grouped
    if (!entry.isSuggestion && entry.timeEntryId) {
      if (!isGrouped[entry.timeEntryId]) {
        if (entry.externalId) {
          const filteredByExtneralId = entries.filter(x => x.externalId === entry.externalId);
          filteredByExtneralId.forEach(filteredEntry => {
            if (filteredEntry.timeEntryId && !isGrouped[filteredEntry.timeEntryId]) {
              groupedEntries.push(filteredEntry);
              isGrouped[filteredEntry.timeEntryId] = true;
            }
          });
        } else {
          groupedEntries.push(entry);
          isGrouped[entry.timeEntryId] = true;
        }
      }
    } else if (entry.timeEntryId && entry.isSuggestion) {
      // if its a suggestion, then it won't have a timeEntryId and we do groupings purely off of externalId.
      // Check to see if it has an externalId (I believe all suggestions do), then find all entries with
      // that externalId, and add them to the groupedEntries list and mark each one grouped
      if (entry.externalId && !isSuggestionsGrouped[entry.externalId]) {
        const filteredSuggestionsByExternalId = entries.filter(x => x.externalId === entry.externalId);
        filteredSuggestionsByExternalId.forEach(filteredEntry => {
          if (filteredEntry.externalId) {
            groupedEntries.push(filteredEntry);
          }
        });
        isSuggestionsGrouped[entry.externalId] = true;
      }
    } else {
      // Fallback and ensure it makes it in if the above fails. In theory this will
      // never be hit, but better to be safe than broken.
      groupedEntries.push(entry);
    }
  });

  return groupedEntries;
};

const DefaultWeeklyTime: IWeeklyTimesheet = {
  currentDate: '',
  totalHoursCompleted: 0,
  dailyTotals: []
};

const TimeTracking: FC = () => {
  //location state
  const location = useLocation();

  // redux
  const { employees } = useSelector((state: IAppState) => state.admin);
  const { clients, clientProjectsList } = useSelector((state: IAppState) => state.clients);
  const { sourceTypes } = useSelector((state: IAppState) => state.employees);
  const dispatch = useDispatch();

  // if we have a day in our react-router location state, use that
  // This means we navigated here from another page and need to default the date
  // otherwise use todays date
  const locationDate = location && location.state && (location.state as any).day;
  const date = locationDate ? new Date(locationDate) : new Date();
  const today = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;

  // state
  // if employeeId exists on location state, handle it like date and set it -> means we came from another page
  const [employeeId, setEmployeeId] = useState<number | undefined>((location && location.state && (location.state as any).employeeId) || undefined);
  const [timeSheet, setTimeSheet] = useState<ITimesheet>();
  const [isInitialLoad, setIsInitialLoad] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [day, setDay] = useState<string>(today);
  const [weeklyTimeSheet, setWeeklyTimeSheet] = useState<IWeeklyTimesheet>(DefaultWeeklyTime);
  const [reportingEmployees, setReportingEmployees] = useState<number[]>([]);

  // success and error state
  const [success, setSuccess] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [loadError, setLoadError] = useState<boolean>(false);
  const [loadErrorMessage, setLoadErrorMessage] = useState<string>('');
  const [userError, setUserError] = useState<boolean>(false);
  const [userErrorMessage, setUserErrorMessage] = useState<string>('');

  // user role state
  const [userHasRole, setUserHasRole] = useState<boolean>(false);
  const [userRoleError, setUserRoleError] = useState<boolean>(false);
  const [userRoleErrorMessage, setUserRoleErrorMessage] = useState<string>('');

  const initialLoad = async () => {
    try {
      setIsInitialLoad(true);

      const [clientsResponse, clientProjectsListResponse, sourceTypesResponse, employeesResponse, reportingEmployeesResponse] = await Promise.all([
        reduxFetch(getAllClients, clients),
        reduxFetch(getClientProjectsList, clientProjectsList),
        reduxFetch(getSourceTypes, sourceTypes),
        reduxFetch(getEmployees, employees),
        getReportingEmployees()
      ]);

      // apply redux state and batch so we only fire 1 re-render
      batch(() => {
        dispatch(setClients(clientsResponse));
        dispatch(setClientProjectsList(clientProjectsListResponse));
        dispatch(setSourceTypes(sourceTypesResponse));
        dispatch(setEmployees(employeesResponse));
      });

      setReportingEmployees(reportingEmployeesResponse);
      setIsInitialLoad(false);
    } catch (error) {
      setLoadError(true);
      // @ts-ignore
      setLoadErrorMessage(error);
      setIsInitialLoad(false);
    }
  };
  useEffect(() => {
    initialLoad();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadTimeSheets = async (search?: { TimeEntryDate: string; includeSuggestions?: boolean }) => {
    const timesheetDate = new Date(formatDate(day) ?? '');
    const isDateToday = isToday(timesheetDate);

    const shouldIncludeSuggestions = search && search.includeSuggestions ? search.includeSuggestions : isDateToday;

    setIsLoading(true);
    try {
      const [weeklyTimeSheetResponse, timeSheetResponse] = await Promise.all([
        getEmployeeWeeklyTimeSheet({ ...search, EmployeeId: employeeId }),
        getEmployeeTimeSheet({ ...search, EmployeeId: employeeId, includeSuggestions: shouldIncludeSuggestions })
      ]);

      if (timeSheetResponse.timeEntries.length === 0) {
        setLoadError(true);
        setLoadErrorMessage('No time entries found!');
      }

      setWeeklyTimeSheet(weeklyTimeSheetResponse);
      await confirmEmployeeRole(timeSheetResponse.employeeId);

      const sorted = {
        ...timeSheetResponse,
        timeEntries: groupByExternalId(timeSheetResponse.timeEntries) // DON'T REMOVE, ENFORCES VISUAL GROUPING OF TIME ENTRIES
      };
      setTimeSheet(sorted);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      setUserError(true);
      // @ts-ignore
      setUserErrorMessage(error);
      console.log('error', error);
    }
  };

  useEffect(() => {
    loadTimeSheets({ TimeEntryDate: day });
  }, [day]);

  const loadWeekly = async (search?: { TimeEntryDate: string }) => {
    try {
      const result = await getEmployeeWeeklyTimeSheet({ ...search, EmployeeId: employeeId });
      setWeeklyTimeSheet(result);
    } catch (error) {
      console.log('error', error);
    }
  };

  const handleEmployeeSwitch = (newId: number) => {
    setEmployeeId(newId);
  };
  useEffect(() => {
    if (employeeId) {
      loadTimeSheets({ TimeEntryDate: day });
    }
  }, [employeeId]);

  const confirmEmployeeRole = async (employeeId: number) => {
    try {
      const response = await checkEmployeeRole(employeeId);
      // an empty response indicates that the user has a role
      if (response && response.length) {
        setUserRoleError(true);
        setUserRoleErrorMessage(response);
        setUserHasRole(false);
      } else {
        setUserRoleError(false);
        setUserRoleErrorMessage('');
        setUserHasRole(true);
      }
    } catch (error) {
      console.log('error', error);
      setUserRoleError(true);
      setUserRoleErrorMessage('Employee Not Found');
      setUserHasRole(false);
    }
  };

  return (
    <>
      {(userHasRole || (!userHasRole && isLoading && !userRoleError)) && ( // if we don't have `userhasRole` but we are still loading with no errors, show timesheet with loadingState
        <Timesheet
          day={day}
          onChangeDay={date => setDay(date)}
          isLoading={isLoading || isInitialLoad}
          initialValues={timeSheet}
          weeklyTimeSheet={weeklyTimeSheet}
          updateWeekly={loadWeekly}
          updateParent={values => setTimeSheet(values)}
          setSuccess={setSuccess}
          setError={setError}
          reportingEmployees={reportingEmployees}
          handleEmployeeSwitch={handleEmployeeSwitch}
          loadTimeSheets={loadTimeSheets}
        />
      )}
      <Alert
        open={userError}
        onClose={() => {
          setUserError(false);
          setUserErrorMessage('');
        }}
        type='error'
        text={userErrorMessage}
      />
      <Alert open={error} onClose={() => setError(false)} type='error' text='Problem saving. Please try again!' />
      <Alert open={success} onClose={() => setSuccess(false)} type='success' text='Save Success!' />
      <Alert open={userRoleError} onClose={() => setUserRoleError(false)} type='error' text={userRoleErrorMessage} />
      <Alert
        open={loadError}
        onClose={() => {
          setLoadError(false);
          setLoadErrorMessage('');
        }}
        type='warning'
        text={loadErrorMessage}
      />
    </>
  );
};

export default TimeTracking;
