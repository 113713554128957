import React, { FC, useMemo, useState } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
// Components
import { Modal, Fade, Backdrop, Card, CardHeader, CardContent, CardActions, Button, Checkbox } from '@material-ui/core';
import { Table, ITableColumn } from '@shared/components/tables';
// Types
import { ITimeTrackingEntry } from '@shared/types';

interface IUndismissProps {
  open: boolean;
  timeEntries: ITimeTrackingEntry[];
  close: () => void;
  onSave: (externalIds: string[]) => void;
}

export const Undismiss: FC<IUndismissProps> = ({ open, close, onSave, timeEntries }) => {
  const classes = useStyles();

  const [selected, setSelected] = useState<string[]>([]);

  // memoize all selected
  const allSelected = useMemo(() => {
    return timeEntries.length > 0 && !timeEntries.some(x => x.externalId && selected.indexOf(x.externalId) === -1);
  }, [timeEntries, selected]);

  const toggleSelect = (externalId: string) => {
    let newSelected = [...selected];
    if (selected.indexOf(externalId) > -1) {
      newSelected.splice(selected.indexOf(externalId), 1);
    } else {
      newSelected.push(externalId);
    }
    setSelected(newSelected);
  };

  const toggleAll = () => {
    if (allSelected) {
      setSelected([]);
    } else {
      let newEntries: string[] = [];
      timeEntries.forEach(entry => {
        if (entry.externalId && newEntries.indexOf(entry.externalId) === -1) {
          newEntries.push(entry.externalId);
        }
      });
      setSelected(newEntries);
    }
  };

  const handleSave = () => {
    onSave(selected);
    setSelected([]);
    close();
  };

  const columns = useMemo(() => {
    return [
      {
        id: 'selection',
        sort: false,
        hideLoad: true,
        className: classes.checkbox,
        Header: () => (
          <div>
            <Checkbox checked={timeEntries.length > 0 && allSelected} onClick={toggleAll} />
          </div>
        ),
        Cell: ({
          cell: {
            row: { original }
          }
        }: any) => (
          <div>
            <Checkbox checked={selected.indexOf(original.externalId) > -1} onClick={() => toggleSelect(original.externalId)} />
          </div>
        )
      },
      { Header: 'Description', accessor: 'description' }
    ];
  }, [timeEntries, selected]);

  return (
    <Modal
      aria-labelledby='connect-task-modal-total'
      aria-describedby='connect-task-modal-description'
      className={classes.modal}
      open={open}
      onClose={close}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500
      }}
    >
      <Fade in={open}>
        <Card className={classes.root}>
          <CardHeader title='Undismiss Suggestions' />
          <CardContent className={classes.cardContent}>
            <Table
              // trim out duplicate external ids and just show 1 line for each dismissed suggestions (don't show groupings)
              data={Array.from(new Set(timeEntries.map(a => a.externalId))).map(externalId => {
                return timeEntries.find(a => a.externalId === externalId);
              })}
              columns={columns as ITableColumn[]}
              hidePagination
              stickyHeader
            />
          </CardContent>
          <CardActions>
            <Button variant='contained' disabled={Boolean(Object.keys(selected).length === 0)} color='secondary' onClick={handleSave}>
              Save
            </Button>
            <Button type='button' variant='contained' color='default' onClick={close}>
              Cancel
            </Button>
          </CardActions>
        </Card>
      </Fade>
    </Modal>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    root: {
      width: 300,
      [theme.breakpoints.up('md')]: {
        width: 600
      }
    },
    cardContent: {
      display: 'flex',
      flexDirection: 'column',
      height: '60vh'
    },
    checkbox: {
      padding: 0
    }
  })
);
