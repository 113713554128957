import React, { FC } from 'react';
// Components
import TextField from '@material-ui/core/TextField';
// helpers
import { get } from 'lodash';
// Types
import { FormikHandlers, FieldMetaProps, FormikErrors } from 'formik';

interface IDescriptionCellProps extends FormikHandlers, FieldMetaProps<any> {
  cell: { value: any };
  row: { index: number };
  column: { id: string };
  data: any[];
  updateMyData: (rowIndex: number, columnId: string, value: any) => void;
  errors: FormikErrors<any>;
}

export const DescriptionCell: FC<IDescriptionCellProps> = ({
  cell: { value: initialValue },
  row: { index },
  column: { id },
  data,
  updateMyData,
  errors,
  touched,
  handleBlur
}) => {
  // We need to keep and update the state of the cell normally
  const [value, setValue] = React.useState(initialValue);

  // DON'T UPDATE HERE, OR WE WILL RENDER ON EVERY KEYSTROKE
  const onChange = ({ target: { value } }: any) => {
    setValue(value);
  };

  // We'll only update the external data when the input is blurred
  const onBlur = (e: any) => {
    updateMyData(index, id, value);
    handleBlur(e);
  };

  // If the initialValue is changed externall, sync it up with our state
  React.useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  return (
    <TextField
      fullWidth
      key={`${id}-search-text-field-${index}`}
      id={`${id}-search-text-field-${index}`}
      value={value}
      disabled={data[index].isLocked}
      name={`timeEntries[${index}].${id}`}
      error={!value && get(errors, `timeEntries[${index}].${id}`) && get(touched, `timeEntries[${index}].${id}`)}
      helperText={
        !value &&
        get(errors, `timeEntries[${index}].${id}`) &&
        get(touched, `timeEntries[${index}].${id}`) &&
        get(errors, `timeEntries[${index}].${id}`)
      }
      onBlur={onBlur}
      onChange={onChange}
      FormHelperTextProps={{ style: { position: 'absolute', pointerEvents: 'none' } }}
    />
  );
};
