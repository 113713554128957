import React, { FC, HTMLAttributes } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
// Components
import { Grid, Paper, Card, CardContent, CardHeader } from '@material-ui/core';

interface IWidgetItemProps extends HTMLAttributes<HTMLDivElement> {
  title: string;
  headerColor: string;
}

export const WidgetItem: FC<IWidgetItemProps> = ({ title, headerColor, children }) => {
  const classes = WidgetItemStyles(headerColor)();
  return (
    <Grid item xs={12} md={6} lg={4}>
      <Card elevation={0} variant='outlined' className={classes.marginBottom}>
        <CardHeader title={title} className={classes.primaryHeader} />
        <CardContent>
          <Paper elevation={0} square>
            {children}
          </Paper>
        </CardContent>
      </Card>
    </Grid>
  );
};

const WidgetItemStyles = (headerColor: string) => makeStyles((theme: Theme) => ({
  primaryHeader: {
    backgroundColor: headerColor,
    color: theme.palette.common.white,
    marginBottom: theme.spacing(0)
  },
  marginBottom: {
    marginBottom: theme.spacing(1)
  }
}));
