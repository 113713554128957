import { useLayoutEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { getVersion } from '../shared/fetch';
import { APP_VERSION } from '../shared/helpers';

/**
 * On each route change call the version endpoint to see if a new version has been deployed
 * If a new version is deployed and it doesn't match the current stored item in local storage
 * Do a full refresh on route change to reload the static index file with the new version and then update
 * LocalStorage with the new version number
 */
//@ts-ignore
export const VersionWrapper = ({ children }) => {
  const location = useLocation();
  const [isReloading, setIsReloading] = useState(false);

  useLayoutEffect(() => {
    const fetchVersion = async () => {
      try {
        const res = await getVersion();
        const LSVersion = localStorage.getItem(APP_VERSION);
        let needsRefresh = false;
        if (!LSVersion) {
          needsRefresh = true;
          localStorage.setItem(APP_VERSION, res.build);
        }
        if (LSVersion && LSVersion !== res.build) {
          needsRefresh = true;
          localStorage.setItem(APP_VERSION, res.build); // update localStorage so we don't get any inifite refresh loops
        }
        if (needsRefresh) {
          setIsReloading(true);
          window.location.reload(); // refresh the browser
        }
      } catch (error) {}
    };
    fetchVersion();
  }, [location]);
  if (isReloading) {
    return null;
  }
  return children;
};
