import React, { FC, useState } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
// Redux
import { useSelector } from 'react-redux';
// Components
import { Modal, Fade, Backdrop, Card, CardHeader, CardContent, CardActions, Button, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
// Types
import { IAppState } from '@shared/types';

interface ISwitchUserProps {
  open: boolean;
  reportingEmployees: number[];
  close: () => void;
  onSave: (userId: number) => void;
}

export const SwitchUser: FC<ISwitchUserProps> = ({ open, close, onSave, reportingEmployees }) => {
  const classes = useStyles();
  const { employees } = useSelector((state: IAppState) => state.admin);

  const [user, setUser] = useState<{ label: string; value: number } | null>(null);

  const handleSave = () => {
    if (user && user.value) {
      onSave(user.value);
    }
    close();
  };

  return (
    <Modal
      aria-labelledby='switch-user-modal-total'
      aria-describedby='switch-user-modal-description'
      className={classes.modal}
      open={open}
      onClose={close}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500
      }}
    >
      <Fade in={open}>
        <Card className={classes.root}>
          <CardHeader title='Switch Employee' />
          <CardContent className={classes.cardContent}>
            <Autocomplete
              id='user-autocomplete'
              options={employees
                .filter(x => x.isActive && reportingEmployees.indexOf(x.employeeId) > -1)
                .map(x => ({ label: `${x.firstName} ${x.lastName}`, value: x.employeeId }))}
              getOptionLabel={option => option.label}
              value={user}
              onChange={(e: any, value: any) => setUser(value)}
              renderInput={params => (
                <TextField {...params} label='Employee' InputLabelProps={{ id: 'user-autocomplete-label', htmlFor: 'user-autocomplete' }} />
              )}
            />
          </CardContent>
          <CardActions>
            <Button variant='contained' color='secondary' disabled={!user} onClick={handleSave}>
              Switch
            </Button>
            <Button type='button' variant='contained' color='default' onClick={close}>
              Cancel
            </Button>
          </CardActions>
        </Card>
      </Fade>
    </Modal>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    root: {
      width: 300,
      [theme.breakpoints.up('md')]: {
        width: 600
      }
    },
    cardContent: {
      display: 'flex',
      flexDirection: 'column'
    }
  })
);
