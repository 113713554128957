import { authFetch } from './';
import qs from 'qs';
import axios from 'axios';

let buildConfig = require('../../buildSettings');
const axiosInstance = axios.create({
  baseURL: `${buildConfig.REACT_APP_API_URL}/`
});

export const requestAccess = async (RequestPath: string): Promise<number> => {
  if (!RequestPath) {
    return Promise.reject('No path provided');
  }
  try {
    const { data } = await authFetch.get(`/api/RequestAccess?${qs.stringify({ RequestPath })}`);
    return Promise.resolve(data);
  } catch (error) {
    console.log('error in fetch', error)
    return Promise.reject(error);
  }
};


export const requestNewAccess = async (params?: any): Promise<number> => {
  try {
    const { data } = await axiosInstance.get(`/api/RequestAccess?${qs.stringify(params)}`);
    return Promise.resolve(data);
  } catch (error) {
    console.log('error in fetch', error)
    return Promise.reject(error);
  }
};
