import React, { FC } from 'react';
import { Close, FilterList } from '@material-ui/icons';
import { Button } from '@material-ui/core';
import clsx from 'clsx';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { useMedia } from 'react-use';

interface IFiltersButtons {
  hasAppliedFilters?: boolean;
  isDisabled?: boolean;
  handleApplyFilters: () => void;
  handleResetFilters: () => void;
  marginTop?: number;
}

export const RequestsFilterButtons: FC<IFiltersButtons> = ({
  isDisabled,
  hasAppliedFilters,
  handleApplyFilters,
  handleResetFilters,
  marginTop = -12  // Default marginTop value
}) => {
  const classes = useStyles({ marginTop });
  const isDesktop = useMedia('(min-width: 1100px)');

  return (
    <div>
      {isDesktop && (
        <div className={classes.buttonContainer}>
          <Button
            color='primary'
            variant='contained'
            className={classes.button}
            disabled={isDisabled}
            startIcon={<FilterList />}
            onClick={handleApplyFilters}
          >
            Apply Filters
          </Button>
          {hasAppliedFilters && (
            <Button
              color='inherit'
              className={clsx(classes.button, classes.resetButton)}
              variant='contained'
              disabled={isDisabled}
              startIcon={<Close />}
              onClick={handleResetFilters}
            >
              Reset
            </Button>
          )}
        </div>
      )}

      {!isDesktop && (
        <div className={hasAppliedFilters ? classes.mobileButtonContainerWithReset : classes.mobileButtonContainerOnlyApply}>
          {hasAppliedFilters && (
            <Button
              color='inherit'
              className={clsx(classes.mobileButton, classes.resetButton, classes.red)}
              variant='text'
              disabled={isDisabled}
              onClick={handleResetFilters}
            >
              Reset Filters
            </Button>
          )}
          <Button color='primary' variant='contained' className={classes.mobileButton} disabled={isDisabled} onClick={handleApplyFilters}>
            Apply Filters
          </Button>
        </div>
      )}
    </div>
  );
};

const useStyles = makeStyles<Theme, { marginTop: number }>((theme: Theme) => ({
  button: {
    whiteSpace: 'nowrap',
    marginTop: props => props.marginTop,
    marginBottom: 2,
    height: 35,
    textTransform: 'capitalize',
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: 'auto'
    },
    '@media (max-width: 1441px)': {
      marginTop: -12
    },
    '@media (max-width: 960px)': {
      marginBottom: 10
    }
  },
  mobileButton: {
    whiteSpace: 'nowrap',
    marginTop: -12,
    marginBottom: -12,
    height: 35,
    textTransform: 'capitalize',
    width: 'auto'
  },
  resetButton: {
    '@media (min-width: 400px)': {
      marginLeft: 11
    },
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(0.5)
    }
  },
  buttonContainer: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'nowrap'
  },
  red: {
    color: 'red'
  },
  mobileButtonContainerWithReset: {
    paddingTop: '16px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%'
  },
  mobileButtonContainerOnlyApply: {
    paddingTop: '16px',
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%'
  }
}));
