import React, { Component } from "react";
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import { Modal, Fade, Backdrop, Card, CardHeader, CardActions, Button } from '@material-ui/core';

interface ErrorBoundaryProps {
  classes: any;
}

interface ErrorBoundaryState {
  hasError: boolean;
  error: string;
}
class ErrorBoundaryBase extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false, error: "" };
  }

  static getDerivedStateFromError(error: Error) {
    return { hasError: true, error: error.toString() };
  }

  // this block can be used to log the errors in the future
  // componentDidCatch(error: Error, info: ErrorInfo) {}

  render() {
    const { hasError } = this.state;
    const { children, classes } = this.props;
    if (hasError) {
      // You can render any custom fallback UI
      return (
        <Modal
          aria-labelledby='error-modal'
          aria-describedby='error-modal-description'
          className={classes.modal}
          open={hasError}
          onClose={() => null}
          BackdropComponent={Backdrop}
          BackdropProps={{ timeout: 500 }}
        >
          <Fade in={hasError}>
            <Card className={classes.root}>
              <CardHeader title='Something went wrong. Please refresh and try again.' />
              <CardActions>
                <Button variant='contained' onClick={() => window.location.reload()}>
                  Ok
                </Button>
              </CardActions>
            </Card>
          </Fade>
        </Modal>
      );
    }

    // If there is no error just render the children component.
    return children;
  }
}

const styles = (theme: Theme) => createStyles({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  root: {
    width: 300,
    [theme.breakpoints.up('md')]: {
      width: 600
    },

    '&:focus': {
      outline: 'none'
    }
  }
});

export const ErrorBoundary = withStyles(styles, { withTheme: true })(ErrorBoundaryBase);