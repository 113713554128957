import { List, ListItem, makeStyles, Theme } from '@material-ui/core';
import { DragIndicator } from '@material-ui/icons';
import React, { FC, PropsWithChildren } from 'react';
import { getStyle, ListItemColors } from './util';

interface IStaticOptionListItem {
  item: any;
  id: string;
  color?: ListItemColors;
  customBorder?: string; // Optional custom border prop
}

export const StaticOptionListItem: FC<PropsWithChildren<IStaticOptionListItem>> = ({ item, id, children, color = ListItemColors.Default, customBorder }) => {
  const classes = useStyles({ customBorder });

  return (
    <List dense className={classes.StyledStaticList}>
      <ListItem key={id} className={classes.StyledStaticListItem} style={{ ...getStyle(color), cursor: 'grab' }}>
        <DragIndicator />
        {children}
      </ListItem>
    </List>
  );
};

const useStyles = makeStyles<Theme, { customBorder?: string }>((theme: Theme) => ({
  StyledStaticList: {
    '&:first-of-type': {
      minWidth: '20ch'
    }
  },
  StyledStaticListItem: {
    border: props => props.customBorder || `1px solid ${theme.palette.primary.main}`,
  }
}));