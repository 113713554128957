import React, { FC } from 'react';

import { DashboardCard } from '@src/clients/components/DashboardCard';
import { Button } from '@material-ui/core';
import { UpcomingReleasesContent } from './UpcomingReleasesContent';
import { useHistory } from 'react-router-dom';

interface IUpcomingReleases {}

export const UpcomingReleases: FC<IUpcomingReleases> = () => {
  const history = useHistory();

  return (
    <DashboardCard
      actions={
        <Button color='primary' variant='contained' onClick={() => history.push('/clients/releases')}>
          View All
        </Button>
      }
      title={'Upcoming Releases'}
    >
      <UpcomingReleasesContent />
    </DashboardCard>
  );
};
