import React, { FC } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
// Redux
// Components
import { FormLabel, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails, Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
// Types
import { IClientProject } from '@shared/types';

interface MobileClientHashtag {
  original: IClientProject;
}

export const MobileClientHashtag: FC<MobileClientHashtag> = ({ original: { clientName, hashtag } }) => {
  const classes = useStyles();

  // redux

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const handleOpenPopover = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setTimeout(() => {
      setAnchorEl(null);
    }, 1500);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const handleCopy = (event: React.MouseEvent<HTMLButtonElement>, hasHashtag: boolean) => {
    handleOpenPopover(event);
    let tempInput = document.createElement('input');
    tempInput.value = `${hasHashtag ? '#' : ''}${hashtag?.toString()}`;
    document.body.appendChild(tempInput);
    tempInput.select();
    document.execCommand('copy');
    document.body.removeChild(tempInput);
    handleClose();
  };

  return (
    <ExpansionPanel className={classes.root}>
      <ExpansionPanelSummary className={classes.expansionPanel} expandIcon={<ExpandMoreIcon />} aria-controls='panel1a-content' id='panel1a-header'>
        <div className={classes.panelSummaryWrapper}>
          <Typography component='span'>{clientName}</Typography>
        </div>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails className={classes.details}>
        <FormLabel component='h2'>Client Name</FormLabel>
        <Typography component='span'>{clientName || '--'}</Typography>
        <FormLabel component='h2'>Outlook Hashtag</FormLabel>
        <button className={classes.hashtagItem} onClick={e => handleCopy(e, true)}>
          #{hashtag || '--'}
        </button>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          classes={{ paper: classes.popover }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}
        >
          <Typography component='span'>Copied to Clipboard</Typography>
        </Popover>
        <FormLabel component='h2'>AzDO Hashtag</FormLabel>
        <button className={classes.hashtagItem} onClick={e => handleCopy(e, false)}>
          {hashtag || '--'}
        </button>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          classes={{ paper: classes.popover }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}
        >
          <Typography component='span'>Copied to Clipboard</Typography>
        </Popover>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    maxWidth: '100%'
  },
  expansionPanel: {
    padding: '0px 24px'
  },
  panelSummaryWrapper: {
    display: 'flex',
    alignItems: 'flex-start',
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  details: {
    display: 'flex',
    flexDirection: 'column'
  },
  hashtagItem: {
    fontSize: '1rem',
    fontFamily: theme.typography.fontFamily,
    fontWeight: 400,
    lineHeight: 1.5,
    textAlign: 'left',
    color: 'rgba(0, 0, 0, 0.87)',
    paddingLeft: theme.spacing(0),
    border: 'none',
    backgroundColor: '#fff'
  },
  popover: {
    backgroundColor: theme.palette.grey[700],
    borderRadius: theme.shape.borderRadius,
    color: theme.palette.common.white,
    fontFamily: theme.typography.fontFamily,
    padding: '4px 8px',
    fontSize: theme.typography.pxToRem(10),
    maxWidth: 300,
    fontWeight: theme.typography.fontWeightMedium as 'normal'
  }
}));
