import { Box, Typography, Button } from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import React, { FC } from 'react';
import { useDropzone } from 'react-dropzone';
import { FileDrop } from './FileDrop';

interface IFileUploader {
  handleFile: (file: File[]) => void;
  queuedFiles: File[];
  isRequired: boolean;
  dropzoneText?: string;
  removeText?: string;
  handleRemove?: (file: File) => void;
}

export const FileUploader: FC<IFileUploader> = ({
  handleFile,
  queuedFiles,
  isRequired,
  dropzoneText = 'Add File',
  removeText = 'Remove & Replace',
  handleRemove
}) => {
  const dropzone = useDropzone({
    accept: {
      file: []
    },
    useFsAccessApi: false,
    onDrop: acceptedFiles => {
      handleFile(acceptedFiles);
    }
  });
  return (
    <>
      {queuedFiles.length > 0 &&
        (handleRemove ? (
          queuedFiles.map((file: File, index: number) => (
            <div key={index + file.name}>
              <Box display='flex' marginTop='1rem' alignItems='center'>
                <Box flex={1}>
                  <Typography>{file.name}</Typography>
                </Box>
                <Button
                  onClick={e => {
                    handleRemove(file);
                  }}
                  startIcon={<Delete />}
                >
                  {removeText}
                </Button>
              </Box>
            </div>
          ))
        ) : (
          <div {...dropzone.getRootProps()}>
            <Box display='flex' marginTop='1rem' alignItems='center'>
              <Box flex={1}>
                <Typography>{queuedFiles}</Typography>
              </Box>
              <Button
                onClick={e => {
                  e.preventDefault();
                }}
                startIcon={<Delete />}
              >
                {removeText}
              </Button>
            </Box>
            <input id='template-file-drop' {...dropzone.getInputProps()} />
          </div>
        ))}
      {queuedFiles.length < 1 && (
        <>
          <Box>
            <FileDrop id='template-file-drop' {...dropzone} title={dropzoneText}>
              <Typography variant='body1'>
                <a href='/#' onClick={e => e.preventDefault()}>
                  Browse and pick
                </a>{' '}
                files.
              </Typography>
            </FileDrop>
          </Box>
        </>
      )}
    </>
  );
};
