import { Drawer, IconButton, List } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';
import { clientNavItems, Navigation } from '@shared/helpers';
import { IAppState, IUserProps } from '@shared/types';
import clsx from 'clsx';
import * as localForage from 'localforage';
import React, { useEffect, useState } from 'react';

import { ClientNavItem } from './ClientNavItem';
import { INavItem, NavItem } from './NavItem';
import { ISubNavItem } from './SubNavItem';
import { useSelector } from 'react-redux';

interface ISideNavProps {
  user: IUserProps;
  id?: string;
}
// passing in user for testing purposes
export const SideNav: React.FC<ISideNavProps> = ({ user, id = 'sideNav' }) => {
  const classes = useStyles();

  const { userAccess, featureFlags } = user;

  const { selectedClient } = useSelector((state: IAppState) => ({ ...state.extranet }));
  const pendingRequests = !!featureFlags.find(flag => flag.name === 'client-backlog-request')?.hasAccess;
  const accessSoftwareReleases = !!featureFlags.find(flag => flag.name === 'software-release')?.hasAccess;
  const clientReleases = !!featureFlags.find(flag => flag.name === 'mymwks-client-release-notes')?.hasAccess;
  const sharedFilesUpdate = !!featureFlags.find(flag => flag.name === 'shared-files-update')?.hasAccess;

  const hasZendeskForm = !!featureFlags.find(flag => flag.name === 'zendesk-form')?.hasAccess;
  const [isNavOpen, setIsNavOpen] = useState(false);

  // Anytime isNavOpen changes set it in localForage. setItem is async but we don't care
  // to wait since we look at this on load only.
  useEffect(() => {
    localForage.setItem('my-mwks-nav-open', isNavOpen);
  }, [isNavOpen]);

  const loadInitialOpenState = async () => {
    try {
      const initialOpenState = await localForage.getItem<boolean>('my-mwks-nav-open');
      setIsNavOpen(!!initialOpenState);
    } catch (error) {
      // do nothing, this is purely cosmetic
    }
  };
  useEffect(() => {
    loadInitialOpenState();
  }, []);

  const userHasAccess = (item: INavItem | ISubNavItem) => {
    return !item.userAccess || userAccess[item.userAccess];
  };

  const navItemShouldBeInMenu = (item: INavItem) => {
    if (item?.title === 'Software Releases' && !accessSoftwareReleases) {
      return false;
    }
    return userHasAccess(item);
  };

  const clientNavItemShouldBeInMenu = (item: ISubNavItem) => {
    if (item?.title === 'Progress Reports' && (!userHasAccess(item) || !selectedClient?.usesProgressReports)) {
      return false;
    }

    if (!clientReleases && item?.title === 'Releases') {
      return false;
    }

    if (!pendingRequests && item?.title === 'Pending Requests') {
      return false;
    }
    if (!hasZendeskForm && item?.title === 'Zendesk Form') {
      return false;
    }

    if (!sharedFilesUpdate && item?.title === 'Files V2') {
      return false;
    }

    return userHasAccess(item);
  };

  return (
    <Drawer
      data-testid={id}
      variant='permanent'
      className={clsx(classes.hideForPrint, classes.drawer, {
        [classes.drawerOpen]: isNavOpen,
        [classes.drawerClose]: !isNavOpen
      })}
      classes={{
        paper: clsx({
          [classes.drawerOpen]: isNavOpen,
          [classes.drawerClose]: !isNavOpen
        })
      }}
    >
      <div className={classes.spacer} />
      <List data-testid={`${id}-list`} disablePadding>
        {user && userAccess.ClientOnly
          ? clientNavItems.filter(clientNavItemShouldBeInMenu).map(item => (
              <ClientNavItem
                key={`item-${item.title}`}
                isNavOpen={isNavOpen}
                {...{
                  ...item,
                  subNav: []
                }}
              />
            ))
          : user && user.userAccess && Object.keys(user.userAccess).length
          ? Navigation.filter(navItemShouldBeInMenu).map(navItem => (
              <NavItem
                key={`nav-item-${navItem.title}`}
                {...{
                  ...navItem
                }}
                isNavOpen={isNavOpen}
              />
            ))
          : null}
      </List>
      <IconButton className={classes.drawerToggle} onClick={() => setIsNavOpen(!isNavOpen)}>
        <DoubleArrowIcon
          className={clsx({
            [classes.drawerOpenIcon]: isNavOpen
          })}
        />
      </IconButton>
    </Drawer>
  );
};

const drawerWidth = 285;

const useStyles = makeStyles(theme => ({
  hideForPrint: {
    '@media print': {
      display: 'none'
    }
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap'
  },
  spacer: {
    height: 95
  },
  drawerOpen: {
    overflow: 'visible',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    }),
    backgroundColor: theme.palette.common.white,
    boxShadow: '0px 10px 30px 0px rgba(0, 0, 0, 0.1)'
  },
  drawerClose: {
    overflow: 'visible',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    width: theme.spacing(3.7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(3.7) + 1
    },
    backgroundColor: theme.palette.common.white,
    boxShadow: '0px 10px 30px 0px rgba(0, 0, 0, 0.1)'
  },
  drawerToggle: {
    marginTop: 'auto',
    alignSelf: 'flex-end'
  },
  drawerOpenIcon: {
    transform: 'rotate(180deg)'
  }
}));
