import React, { FC, ReactNode } from 'react';
import clsx from 'clsx';
import { Link, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { makeStyles, Theme } from '@material-ui/core/styles';
// Components
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
// types
import { IUserProps, IAppState } from '@shared/types';

export interface ISubNavItem {
  title: string;
  path: string;
  externalLink?: string;
  userAccess?: string;
  icon?: ReactNode;
  featureFlag?: string;
  showStaticMenu?: boolean;
  isMobile?: boolean;
}

interface ISubNavItemProps extends ISubNavItem {
  onNav?: (event: {}) => void;
}

export const SubNavItem: FC<ISubNavItemProps> = ({ showStaticMenu, title, path, externalLink, userAccess, onNav, featureFlag, isMobile }) => {
  const classes = useStyles({ isMobile, showStaticMenu });
  const user: IUserProps = useSelector((state: IAppState) => state.user);
  // custom FF check here for embed reports page to handle hiding the sub nav reports link
  const isReportsLink: boolean = title === 'Reports';
  const embedReportsFFOn: boolean = isReportsLink && featureFlag && user.userAccess[featureFlag] ? true : false;
  const { pathname } = useLocation();
  const active = pathname === path;

  if (isReportsLink && embedReportsFFOn) {
    return null;
  }
  return !userAccess || user.userAccess[userAccess] ? (
    externalLink ? (
      <a className={classes.root} href={externalLink} target='_blank' rel='noopener noreferrer'>
        <ListItem classes={{ root: showStaticMenu ? classes.navItem : classes.hooverItem }} button key={title}>
          <ListItemText primary={title} className={classes.listItemText} />
        </ListItem>
      </a>
    ) : (
      <Link
        className={clsx(classes.root, { [classes.active]: active && showStaticMenu, [classes.activeHover]: active && !showStaticMenu })}
        to={path}
        onClick={onNav}
      >
        <ListItem classes={{ root: showStaticMenu ? classes.navItem : classes.hooverItem }} button key={title}>
          <ListItemText primary={title} className={classes.listItemText} />
        </ListItem>
      </Link>
    )
  ) : null;
};

const useStyles = makeStyles<Theme, { isMobile?: boolean; showStaticMenu?: boolean }>(theme => ({
  root: {
    backgroundColor: theme.palette.common.white,
    color: '#B1B1B1',
    textDecoration: 'none',
    display: 'flex',
    alignItems: 'flex-start'
  },
  active: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.secondary.main,
    borderRightStyle: 'solid',
    borderColor: theme.palette.secondary.main,
    width: '100%'
  },
  activeHover: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.secondary.main
  },
  hooverItem: {
    padding: 0,
    marginTop: 2,
    marginBottom: 2,
    paddingLeft: theme.spacing(0.75)
  },
  navItem: {
    padding: 0,
    marginTop: 2,
    marginBottom: 2,
    paddingLeft: theme.spacing(0.5)
  },
  listItemText: {
    marginLeft: ({ isMobile, showStaticMenu }) => (isMobile ? '4rem' : showStaticMenu ? '3.7rem' : 0)
  }
}));
