export interface IRoleFilter {
  value: 'all' | 'External' | 'MwksUser' | 'MwksAdmin' | 'MwksDeveloper';
  title: string;
}

export enum UserRolesTitle {
  ALL = 'All Roles',
  USER = 'MyMWKS User',
  ADMIN = 'MyMWKS Admin',
  EXTERNAL = 'External'
}

export enum UserRolesValue {
  ALL = 'all',
  EXTERNAL = 'External',
  USER = 'MwksUser',
  ADMIN = 'MwksAdmin'
}

export interface IClientRoleFilter {
  value: "Client" | "ClientAdmin" | "Financial" | "Collaborator";
  title: string;
}