import { makeStyles, Theme } from '@material-ui/core/styles';
import React, { FC, Fragment, useEffect, useState } from 'react';
import ReactGA from 'react-ga';
// components
import { Grid, IconButton, Typography, ExpansionPanelSummary, ExpansionPanelDetails, ExpansionPanel } from '@material-ui/core';
import { ExpandMore, Link } from '@material-ui/icons';
import { GridLoader } from '@shared/components/loader';
import { UserStoryExpansion } from './UserStoryExpansion';
import { UserStoryViews } from '../containers/UserStories';
// constants
import { FEATURE_COLOR, EPIC_COLOR } from '@shared/constants';
// types
import { IUserEpic, IUserStoryFeature, IUserStory, IUserStoryDetail, WorkItemTypes, IAppState } from '@shared/types';
import { useSelector } from 'react-redux';

import { copyToClipboard } from '@shared/helpers';

interface IUserStoryEpicsExpansionProps {
  currentEpicId: number | null;
  currentFeatureId: number | null;
  currentStoryId: number | null;
  epics: IUserEpic[];
  features: IUserStoryFeature[];
  handleEpicClick: (id: number) => void;
  handleFeatureClick: (id: number | null) => void;
  handleStoryClick: (id: number) => void;
  isLoadingStories: boolean;
  isLoadingFeatures: boolean;
  isLoadingStoryDetail: boolean;
  selectedStories?: IUserStoryDetail[];
  selectedTypeFilter: WorkItemTypes | null;
  selectedView: UserStoryViews;
  userStories: IUserStory[];
  indent?: boolean;
  expanded: { [id: number]: boolean };
  setExpanded: React.Dispatch<
    React.SetStateAction<{
      [id: number]: boolean;
    }>
  >;
  isLoadingFeaturesArray?: number[];
  isDeepLink?: boolean;
}

export const UserStoryEpicsExpansion: FC<IUserStoryEpicsExpansionProps> = ({
  currentEpicId,
  currentFeatureId,
  currentStoryId,
  epics,
  features,
  handleEpicClick,
  handleFeatureClick,
  handleStoryClick,
  isLoadingFeatures,
  isLoadingStories,
  isLoadingStoryDetail,
  selectedStories,
  userStories,
  indent = false,
  expanded,
  setExpanded,
  isLoadingFeaturesArray,
  isDeepLink
}) => {
  const classes = userStoryEpicsFeaturesStoriesExpansionsStyles({ indent });
  const { selectedClient } = useSelector((state: IAppState) => state.extranet);
  const gaLabelClientValue: string = selectedClient ? ` | Client: ${selectedClient?.name}` : '';
  const currentUrl = window.location.href.split('?')[0];
  const clientId = selectedClient?.clientId;
  const storyCount = userStories?.length;

  const [expandedStateChange, setExpandedStateChange] = useState(expanded);
  useEffect(() => {
    setExpandedStateChange(expanded);
  }, [expanded]);

  const featuresClicked = new Array<number>();

  return (
    <>
      {epics.map((epic: IUserEpic, i: number) => {
        return (
          <div className={classes.wrapper} key={`${i}`}>
            <ExpansionPanel
              classes={{ root: classes.panelRoot }}
              defaultExpanded={epics.length > 1 ? false : true}
              expanded={expanded?.[epic?.id] ?? expandedStateChange?.[epic?.id]}
              key={`${epic?.id}-${expanded?.[epic?.id]}`}
              onChange={() => {
                ReactGA.event({
                  category: 'User Stories',
                  action: 'View Epic Details for Epic',
                  value: epic?.id,
                  label: `View Selected Epic Details: ${epic?.id}${gaLabelClientValue}`
                });
                if (expanded?.[epic?.id]) {
                  setExpanded?.(prev => {
                    return { ...prev, [epic?.id]: false };
                  });
                } else {
                  setExpanded?.(prev => {
                    return { ...prev, [epic?.id]: true };
                  });
                }
                handleEpicClick?.(epic?.id);
              }}
            >
              <ExpansionPanelSummary
                className={classes.userStoryTitle}
                classes={{ root: classes.panelSummaryRoot }}
                expandIcon={<ExpandMore />}
                aria-controls={`user-epic-content-${i}`}
                id={`user-epic-header-${i}`}
              >
                <div className={classes.panelSummaryWrapper}>
                  <div className={classes.panelSummaryWrapperFirstCol}>
                    <Grid container alignItems={'center'} classes={{ root: classes.panelSummaryGridFirstCol }} wrap='nowrap'>
                      <Typography component='span' className={classes.epicTitle}>
                        Epic
                      </Typography>
                      <Typography component='span' className={classes.storyTitle}>
                        {epic.title}
                        <IconButton
                          size='small'
                          aria-label='copy'
                          onClick={e => {
                            e.stopPropagation();
                            copyToClipboard(`${currentUrl}?clientId=${clientId}&epicId=${epic.id}`);
                          }}
                          className={classes.copyLinkButton}
                        >
                          <Link />
                        </IconButton>
                      </Typography>
                    </Grid>
                  </div>
                  <div className={classes.panelSummaryWrapperSecondCol}>
                    <Typography className={classes.projectTitle}>{epic.clientProjectName || ''}</Typography>
                    <Typography className={classes.sprintText}></Typography>
                    <Typography className={classes.stateText}>{epic.state}</Typography>
                  </div>
                </div>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails classes={{ root: classes.panelDetailsRoot }}>
                {isLoadingFeaturesArray
                  ? isLoadingFeaturesArray.includes(epic.id) && expanded[epic.id] && <GridLoader rows={2} />
                  : isLoadingFeatures && currentEpicId === epic.id && <GridLoader rows={2} />}
                {features.map((feature: IUserStoryFeature, j: number) => {
                  return feature.epicId === epic.id ? (
                    <Fragment key={`${j}`}>
                      <ExpansionPanel
                        defaultExpanded={false}
                        expanded={feature.id === currentFeatureId}
                        onChange={() => {
                          ReactGA.event({
                            category: 'User Stories',
                            action: 'View Feature Details',
                            value: feature.id,
                            label: `View Selected Feature Details: ${feature.id}${gaLabelClientValue}`
                          });
                          if (featuresClicked?.[feature.id]) {
                            featuresClicked?.splice(featuresClicked?.indexOf(feature.id), 1);
                          } else {
                            featuresClicked?.push(feature.id);
                          }
                          handleFeatureClick(currentFeatureId === feature.id ? null : feature.id);
                        }}
                      >
                        <ExpansionPanelSummary
                          className={classes.userStoryTitle}
                          expandIcon={<ExpandMore />}
                          IconButtonProps={{ classes: { label: classes.iconButtonLabel } }}
                          classes={{ root: classes.panelSummaryFeatureRoot }}
                          aria-controls={`user-epic-content-${i}`}
                          id={`user-epic-header-${i}`}
                        >
                          <div className={classes.panelSummaryWrapper}>
                            <div className={classes.panelSummaryWrapperFirstCol}>
                              <Grid container classes={{ root: classes.panelSummaryGridFirstCol }} wrap='nowrap'>
                                <Typography component='span' className={classes.epicTitle}>
                                  Feature
                                </Typography>
                                <Typography component='span' className={classes.storyTitle}>
                                  {feature.title}
                                  <IconButton
                                    size='small'
                                    aria-label='copy'
                                    onClick={e => {
                                      e.stopPropagation();
                                      copyToClipboard(`${currentUrl}?clientId=${clientId}&featureId=${feature.id}`);
                                    }}
                                    className={classes.copyLinkButton}
                                  >
                                    <Link />
                                  </IconButton>
                                </Typography>
                              </Grid>
                            </div>
                            <div className={classes.panelSummaryWrapperSecondCol}>
                              <Typography className={classes.projectTitle}>{feature.clientProjectName || ''}</Typography>
                              <Typography className={classes.sprintText}></Typography>
                              <Typography className={classes.stateText}>{feature.state}</Typography>
                            </div>
                          </div>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails classes={{ root: classes.panelDetailsRoot }}>
                          {isLoadingStories && currentFeatureId === feature.id && <GridLoader rows={2} />}
                          {userStories &&
                            userStories.length > 0 &&
                            userStories.map((userStory: IUserStory, k: number) => {
                              const selectedStory = selectedStories && selectedStories.find(story => story.id === userStory.id);
                              return userStory.featureId === feature.id ? (
                                <UserStoryExpansion
                                  storyCount={storyCount}
                                  currentStoryId={currentStoryId}
                                  key={`user-story-${k}`}
                                  selectedStory={selectedStory}
                                  userStory={userStory}
                                  isLoadingStoryDetail={isLoadingStoryDetail}
                                  handleStoryClick={handleStoryClick}
                                  indent={indent}
                                  isDeepLink={isDeepLink}
                                />
                              ) : null;
                            })}
                          {!isLoadingStories && userStories && userStories.length === 0 && (
                            <Typography className={classes.noUserStories}>No user stories are available.</Typography>
                          )}
                        </ExpansionPanelDetails>
                      </ExpansionPanel>
                    </Fragment>
                  ) : null;
                })}
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </div>
        );
      })}
    </>
  );
};

const userStoryEpicsFeaturesStoriesExpansionsStyles = makeStyles<Theme, { indent?: boolean }>((theme: Theme) => ({
  wrapper: {
    '&& .MuiPaper-elevation1': {
      boxShadow: 'none',
      margin: 0,
      borderTop: `1px solid ${theme.palette.grey[300]}`
    }
  },
  iconButtonLabel: {
    display: 'flex',
    flexDirection: 'column'
  },
  panelRoot: {
    margin: theme.spacing(1, 0)
  },
  userStoryTitle: {
    minHeight: '100%',
    '&& .MuiExpansionPanelSummary-content .Mui-expanded': {
      margin: '0'
    }
  },
  epicTitle: {
    color: theme.palette.grey[400],
    marginRight: theme.spacing(1),
    fontSize: '.75rem'
  },
  featureTitle: {
    color: theme.palette.grey[400],
    marginRight: theme.spacing(2),
    fontSize: '.75rem'
  },
  projectTitle: {
    [theme.breakpoints.up('md')]: {
      width: theme.spacing(15),
      fontSize: '.75rem'
    }
  },
  storyTitle: {
    fontWeight: 'bold',
    overflowWrap: 'anywhere'
  },
  panelSummaryFeatureRoot: {
    paddingLeft: `10px`,
    paddingRight: '10px',
    borderLeft: `3px solid ${FEATURE_COLOR}`,
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
    borderRight: `1px solid ${theme.palette.grey[300]}`,
    minHeight: '100% !important',
    '&& .MuiExpansionPanelSummary-content.Mui-expanded': {
      margin: '12px 0'
    }
  },
  sprintText: {
    width: theme.spacing(8),
    [theme.breakpoints.down('sm')]: {
      width: 'auto',
      position: 'absolute',
      left: '55px',
      top: '13px',
      borderLeft: `1px solid ${theme.palette.grey[300]}`,
      paddingLeft: '10px'
    }
  },
  stateText: {
    width: theme.spacing(6),

    textAlign: 'right'
  },
  panelSummaryRoot: {
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
    borderRight: `1px solid ${theme.palette.grey[300]}`,
    borderLeft: `3px solid ${EPIC_COLOR}`,
    paddingLeft: `10px`,
    paddingRight: '10px',
    minHeight: '100% !important',
    '&& .MuiExpansionPanelSummary-content.Mui-expanded': {
      margin: '12px 0'
    }
  },
  panelDetailsRoot: {
    display: 'block',
    padding: 0,
    marginLeft: ({ indent }) => {
      return indent ? theme.spacing(2) : 0;
    },
    '&& .MuiPaper-elevation1': {
      boxShadow: 'none'
    },
    [theme.breakpoints.down('md')]: {
      marginLeft: ({ indent }) => {
        return indent ? theme.spacing(0.25) : 0;
      }
    }
  },
  panelSummaryWrapper: {
    display: 'flex',
    width: '100%',

    flexDirection: 'row',
    alignItems: 'center'
  },
  panelSummaryWrapperFirstCol: {
    display: 'flex',

    alignItems: 'center',
    width: '70%',
    flexDirection: 'row'
  },
  panelSummaryGridFirstCol: {
    alignItems: 'center'
  },
  panelSummaryWrapperSecondCol: {
    display: 'flex',
    alignItems: 'center',
    width: '30%',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    whiteSpace: 'nowrap'
  },
  noUserStories: {
    margin: theme.spacing(1)
  },
  margin: {
    margin: theme.spacing(1, 0)
  },
  marginBottom: {
    marginBottom: theme.spacing(0.5)
  },
  bold: {
    fontWeight: 700
  },
  id: {
    marginRight: '5px'
  },
  copyLinkButton: {
    transition: 'background-color 0.3s',
    '&:active': {
      backgroundColor: 'rgba(0, 0, 0, 0.2)'
    }
  }
}));
