import React, { FC } from 'react';
import { PageTitle } from '@shared/components/layout/PageTitle';
import { Divider, Toolbar } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

export interface IPageHeaderProps {
  children?: React.ReactNode;
  title: string;
  hiddenTitle?: boolean;
}

export const PageHeader: FC<IPageHeaderProps> = ({ children, title, hiddenTitle }) => {
  const classes = useStyles();

  return (
    <>
      <Toolbar disableGutters className={classes.toolbar}>
        <PageTitle title={title} className={classes.title} hiddenTitle={hiddenTitle} />
        <div className={classes.children}>{children}</div>
      </Toolbar>

      <Divider className={classes.divider} />
    </>
  );
};

const useStyles = makeStyles(theme => ({
  divider: {
    marginBottom: theme.spacing(1)
  },
  toolbar: {
    justifyContent: 'space-between',

    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
      justifyContent: 'space-around',
      minHeight: theme.spacing(8) // keeps card expanders from rendering over toolbar
    }
  },
  title: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center'
    }
  },
  children: {
    [theme.breakpoints.down('sm')]: {}
  }
}));
