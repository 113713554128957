import React, { FC } from 'react';
import clsx from 'clsx';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

interface IPageTitle {
  title: string;
  className?: string;
  hiddenTitle?: boolean;
  titleComponent?: React.ElementType;
}

export const PageTitle: FC<IPageTitle> = ({ title, className, hiddenTitle = false, titleComponent = 'h1' }) => {
  const classes = useStyles();
  return (
    <Typography variant={hiddenTitle ? 'srOnly' : 'h2'} component={titleComponent} color='primary' className={clsx(classes.title, className)}>
      {title}
    </Typography>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    lineHeight: 1.2
  }
}));
