import React, { FC } from 'react';
// components
import Skeleton, { SkeletonProps } from '@material-ui/lab/Skeleton';

interface ISkeletonLoaderProps extends SkeletonProps {
  className?: string;
}

export const SkeletonLoader: FC<ISkeletonLoaderProps> = ({ className, ...props }) => {
  return (
    <div className={className || ''}>
      <Skeleton {...props} animation={props.animation || 'wave'} />
    </div>
  );
};
