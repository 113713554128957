import { authFetch } from './';
// Types
import { IUserProps } from '@shared/types';

export const getUser = async (): Promise<IUserProps> => {
  try {
    const { data } = await authFetch.get('/api/me');
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};