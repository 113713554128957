export function hasOwnProperty<X extends {}, Y extends PropertyKey>(obj: X, prop: Y): obj is X & Record<Y, unknown> {
  return obj.hasOwnProperty(prop);
}

export const copyToClipboard = (text: string) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
      })
      .catch(err => {
        console.error(`Error copying ${text} to clipboard: ${err}`);
      });
  };

