/**
 * Generic way to obtain an autocomplete/select option based on a provided list of objects
 * @param id => the formikl attribute key (i.e. id, value, text)
 * @param key => the key we are comparing to in the provided list
 * @param list => the provided list of objects we are searching through
 * @param iter => optional param for changing how we set the label on return object incase we don't want to use 'name'
 */
export const getValueForSelect = (id: number | string | null | undefined, key: string, list: any[], iter?: (original: any) => void) => {
  if (id === null) {
    return null;
  }

  const find = list.find(x => x[key] === id);
  if (find) {
    return { label: iter ? iter(find) : find.name, value: find[key] };
  }
  return null;
};
