import { makeStyles, Theme, Typography } from '@material-ui/core';
import { TypographyProps } from '@material-ui/core/Typography';
import clsx from 'clsx';
import React, { FC } from 'react';

interface IH6Props extends TypographyProps {}

export const H6: FC<IH6Props> = ({ children, ...props }) => {
  const classes = h6Styles();
  return (
    <Typography {...props} className={clsx(classes.h6, props.className)} variant='h6'>
      {children}
    </Typography>
  );
};

const h6Styles = makeStyles((theme: Theme) => ({
  h6: {
    fontSize: 14,
    fontWeight: 700,
    margin: theme.spacing(6, 0, 3),
    textTransform: 'uppercase'
  }
}));
