import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';
import React, { FC, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
// types
import { IAppState } from '@shared/types';
// helpers

// Styles
import CheckCircle from '@shared/assets/images/checkmark_outline_icon.svg';

const Inline = Quill.import('blots/inline');

// these are imported globally in the index.html
// <script src="https://cdn.jsdelivr.net/gh/T-vK/DynamicQuillTools@master/DynamicQuillTools.js"></script>
declare const QuillToolbarDropDown: any;

interface IWysiwygProps {
  className?: string;
  id: string;
  onBlur?: () => void;
  onChange: (html: string, delta: any, source: any, editor: any) => void;
  readOnly?: boolean;
  value: string;
  canShowMentionLinks?: boolean;
  noShow?: boolean;
  plainWywiwyg?: boolean;
}

// Create a custom blot that will generate an HTML anchor in the desired format, e.g.,
// <a href="#" data-vss-mention="version:2.0,{user.localId}">@{user.displayName}</a>
class UserMentionBlot extends Inline {
  static blotName = 'userMention';
  static tagName = 'a';
  static create(userLocalId: number) {
    let node = super.create();
    node.setAttribute('href', '#');
    node.setAttribute('data-vss-mention', `version:2.0,${userLocalId}`);
    return node;
  }
}
Quill.register(UserMentionBlot);

export const Wysiwyg: FC<IWysiwygProps> = ({ className, id, onBlur, onChange, readOnly, noShow, value, canShowMentionLinks, plainWywiwyg }) => {
  const classes = useStyles();
  const editorRef = useRef<any>(null);
  const { clientMentionLinks } = useSelector((state: IAppState) => state.clients);

  useEffect(() => {
    const quill = editorRef.current.getEditor();
    if (quill && canShowMentionLinks && clientMentionLinks && clientMentionLinks.length > 0) {
      // Add a custom DropDown Menu to the Quill Editor's toolbar:
      const dropDownItems = clientMentionLinks.reduce(
        (acc, cur) => ({
          ...acc,
          [cur.displayName]: cur.localId
        }),
        {}
      );

      const userDropdown = new QuillToolbarDropDown({
        label: '@',
        rememberSelection: false
      });

      userDropdown.setItems(dropDownItems);

      userDropdown.onSelect = function (label: any, value: any, quill: any) {
        const { index, length } = quill.selection.savedRange;
        // Remove any selected text
        quill.deleteText(index, length);
        // Insert text formatted using our custom userMention "blot"
        quill.insertText(index, `@${label}`, 'userMention', value);
        // Move cursor to the end of inserted text (+1 for the @ character)
        quill.setSelection(index + label.length + 1);
      };

      userDropdown.attach(quill);
    }
  }, [clientMentionLinks]);

  return (
    <ReactQuill
      className={clsx(plainWywiwyg ? classes.plainWysiwyg : classes.wysiwyg, className ? className : '', noShow ? classes.noShow : '')}
      data-testid={id}
      id={id}
      ref={editorRef}
      modules={{
        clipboard: { matchVisual: false },
        toolbar: noShow
          ? false
          : [
              [{ header: [1, 2, 3, 4, 5, 6, false] }],
              ['bold', 'italic'],
              [{ script: 'sub' }, { script: 'super' }],
              [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
              [{ align: null }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
              ['link'],
              ['clean']
            ]
      }}
      onBlur={onBlur}
      onChange={onChange}
      readOnly={readOnly}
      value={value}
    />
  );
};

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    noShow: {
      backgroundColor: 'inherit !important',
      '& .ql-container.ql-snow ': {
        border: 'none'
      }
    },
    wysiwyg: {
      backgroundColor: theme.palette.background.paper,
      '& .ql-container': {
        height: '450px !important'
      },
      '&& .ql-editor': {
        '& h1': { color: theme.palette.primary.main },
        '& h2': { color: theme.palette.secondary.main },
        '& h3': { color: theme.palette.secondary.main },
        '& ul': {
          listStyle: 'none'
        },

        '& ul li': {
          position: 'relative',
          paddingLeft: theme.spacing(1.5)
        },
        '& ul li:before': {
          // svg of checkOutline https://materialui.co/material-icons-outlined/check-circle
          content: `url(${CheckCircle})`,
          marginRight: theme.spacing(0.75),
          position: 'absolute',
          left: 0,
          top: theme.spacing(0.25)
        },
        minHeight: 200
      },

      '&& h1': {
        fontSize: 32,
        fontWeight: 700,
        margin: theme.spacing(1, 0)
      },
      '&& h2': {
        fontSize: 28,
        fontWeight: 700,
        margin: theme.spacing(1, 0)
      },
      '&& h3': {
        fontSize: 24,
        fontWeight: 700,
        margin: theme.spacing(1, 0)
      },
      '&& h4': {
        fontSize: 20,
        fontWeight: 700,
        margin: theme.spacing(1, 0)
      },
      '&& h5': {
        fontSize: 18,
        fontWeight: 700,
        margin: theme.spacing(1, 0)
      },
      '&& h6': {
        fontSize: 16,
        fontWeight: 700,
        margin: theme.spacing(1, 0),
        textTransform: 'uppercase'
      },
      '&& ol': {
        margin: theme.spacing(1, 0)
      },
      '&& p': {
        margin: theme.spacing(1, 0)
      },
      '&& ul': {
        margin: theme.spacing(1, 0)
      }
    },
    plainWysiwyg: {
      backgroundColor: theme.palette.background.paper,
      '& .ql-container': {
        height: '450px !important'
      },
      '&& .ql-editor': {
        '& h1': { color: theme.palette.primary.main },
        '& h2': { color: theme.palette.secondary.main },
        '& h3': { color: theme.palette.secondary.main },
        '& ul': {
          listStyle: 'none'
        },

        '& ul li': {
          position: 'relative',
          paddingLeft: theme.spacing(1.5)
        },

        minHeight: 200
      },

      '&& h1': {
        fontSize: 32,
        fontWeight: 700,
        margin: theme.spacing(1, 0)
      },
      '&& h2': {
        fontSize: 28,
        fontWeight: 700,
        margin: theme.spacing(1, 0)
      },
      '&& h3': {
        fontSize: 24,
        fontWeight: 700,
        margin: theme.spacing(1, 0)
      },
      '&& h4': {
        fontSize: 20,
        fontWeight: 700,
        margin: theme.spacing(1, 0)
      },
      '&& h5': {
        fontSize: 18,
        fontWeight: 700,
        margin: theme.spacing(1, 0)
      },
      '&& h6': {
        fontSize: 16,
        fontWeight: 700,
        margin: theme.spacing(1, 0),
        textTransform: 'uppercase'
      },
      '&& ol': {
        margin: theme.spacing(1, 0)
      },
      '&& p': {
        margin: theme.spacing(1, 0)
      },
      '&& ul': {
        margin: theme.spacing(1, 0)
      }
    }
  });
});
