import { authFetch } from './';
import qs from 'qs';
// Types
import {
  ITimesheet,
  IEnumResponse,
  IWeeklyTimesheet,
  ITimesheetCompletion,
  ITimeSheetRange,
  IBudgetHistory,
  IBudgetHistoryPost,
} from '@shared/types';

export const getEmployeeTimeSheet = async (search?: {
  TimeEntryDate?: string;
  EmployeeId?: number;
  includeSuggestions?: boolean;
}): Promise<ITimesheet> => {
  try {
    const { data } = await authFetch.get(
      `/api/LaborTracking/TimeEntries/GetEmployeeTimeSheet?${search && Object.keys(search).length ? qs.stringify(search) : ''}`
    );
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const postEmployeeTimeSheet = async (timeSheet: ITimesheet): Promise<ITimesheet> => {
  try {
    const { data } = await authFetch.post('/api/LaborTracking/TimeEntries/UpdateEmployeeTimeSheet', { timeSheet });
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getSourceTypes = async (): Promise<IEnumResponse[]> => {
  try {
    const { data } = await authFetch.get('/api/LaborTracking/TimeEntries/SourceTypes');
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getSourceInfo = async (timeEntryId?: number | null, sourceType?: string, externalId?: number): Promise<any> => {
  const entryId = timeEntryId ? `&TimeEntryId=${timeEntryId}` : '';
  const extId = externalId ? `&ExternalId=${externalId}` : '';
  const source = sourceType ? `SourceType=${sourceType}` : '';
  try {
    const { data } = await authFetch.get(`/source-info?${entryId}${source}${extId}`);
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getEmployeeTimeSheetWidget = async (): Promise<ITimesheetCompletion> => {
  try {
    const { data } = await authFetch.get(`/api/LaborTracking/Timesheet/GetTimesheetCompletion`);
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getEmployeeWeeklyTimeSheet = async (search?: { TimeEntryDate?: string; EmployeeId?: number }): Promise<IWeeklyTimesheet> => {
  try {
    const { data } = await authFetch.get(
      `/api/LaborTracking/TimeEntries/EmployeeWeeklyTotals?${search && Object.keys(search).length ? qs.stringify(search) : ''}`
    );
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const postTimeSheetRange = async (timeSheetRange: ITimeSheetRange): Promise<ITimesheet> => {
  try {
    const { data } = await authFetch.post('/api/LaborTracking/TimeEntries/InsertTimeSheetRange', { timeSheetRange });
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getReportingEmployees = async (): Promise<number[]> => {
  try {
    const { data } = await authFetch.get('/api/me/reports');
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const checkEmployeeRole = async (employeeId: number): Promise<string | null> => {
  try {
    const { data } = await authFetch.get(`/api/CheckEmployeeRole?EmployeeId=${employeeId}`);
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getBudgetHistory = async (clientId: string | number): Promise<IBudgetHistory> => {
  try {
    const { data } = await authFetch.get(`/api/LaborTracking/Clients/ClientProjects/${clientId}/Budget`);

    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const createBudgetHistory = async (clientId: string | number, post: IBudgetHistoryPost): Promise<IBudgetHistory> => {
  try {
    const { data } = await authFetch.post(`/api/LaborTracking/Clients/ClientProjects/${clientId}/Budget`, post);

    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};