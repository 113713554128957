import React, { FC, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useMedia } from 'react-use';
import ReactGA from 'react-ga';
import { useLocation } from 'react-router-dom';
import Div100vh from 'react-div-100vh';
import clsx from 'clsx';
import { Header } from '.';
import { SideNav } from '../navigation';
import { useDispatch, useSelector } from 'react-redux';
import { setUser } from '@shared/redux/actions';
import { getUser } from '@shared/fetch';
import { IUserProps, IAppState } from '@shared/types';

interface IAppPageProps {
  title?: string;
  hasSideNav?: boolean;
  showUserMenu?: boolean;
}

export const AppPage: FC<IAppPageProps> = ({ title, children, hasSideNav = true, showUserMenu = true }) => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const isLoginPage = pathname === '/login';
  const isRequestsPage = pathname === '/clients/requests';
  const isDesktop = useMedia(isRequestsPage ? '(min-width: 1100px)' : '(min-width: 960px)');
  const renderTitle = title ? `${title} | My MercuryWorks` : 'My MercuryWorks';
  const user: IUserProps = useSelector((state: IAppState) => state.user);
  const classes = useStyles({ isLoginPage });

  const refreshUser = async () => {
    try {
      const userResponse = await getUser();
      dispatch(setUser(userResponse));
    } catch (error) {
      // do nothing and just leave the user as is
    }
  };

  useEffect(() => {
    if (window.ga && pathname) {
      document.title = renderTitle;
      ReactGA.pageview(pathname);
    }
    refreshUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  return (
    <div className={classes.root}>
      {!isLoginPage && <Header showUserMenu={showUserMenu} />}
      {isDesktop && hasSideNav && <SideNav user={user} />}
      <Div100vh as='main' className={classes.content} id='back-to-top-anchor'>
        {!isLoginPage && <div className={clsx(classes.spacer, classes.marginBottom)} />}
        {children}
      </Div100vh>
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.background.paper,
    maxWidth: '100%',
    flexGrow: 1,
    padding: (props: { isLoginPage: boolean }) => (props.isLoginPage ? 0 : theme.spacing(0, 1, 1)),
    marginBottom: 0,
    [theme.breakpoints.up('lg')]: {
      padding: (props: { isLoginPage: boolean }) => (props.isLoginPage ? 0 : theme.spacing(0, 1, 1)),
    },
  },
  spacer: {
    minHeight: 78,
    [theme.breakpoints.up('md')]: {
      minHeight: 78,
    },
  },
  marginBottom: {
    marginBottom: theme.spacing(1),
  },
  loaderWrapper: {
    height: '100vh',
  },
}));
