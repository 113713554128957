import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
// Components
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
// helpers
import { get } from 'lodash';
// Types
import { FormikHandlers, FieldMetaProps, FormikErrors } from 'formik';
// Types
import { IAppState, IClientProjectsListItem } from '@shared/types';
import { CLIENT_PROJECT_STATUSES } from '@shared/constants';

interface IClientProjectCellProps extends FormikHandlers, FieldMetaProps<any> {
  cell: { value: any };
  row: { index: number };
  column: { id: string };
  updateMyData: (rowIndex: number, columnId: string, value: any) => void;
  errors: FormikErrors<any>;
  data: any[];
}

const getValue = (id: number, clientProjects: IClientProjectsListItem[]) => {
  //id 0 is "All Projects" because clientProjectId is not nullable, so anything without a hashtag will be set to 0
  if (id === 0) {
    return null;
  }

  const find = clientProjects.find(x => x.clientProjectId === id);
  if (find) {
    return { label: find.projectName, value: find.clientProjectId, billingTypeId: find.billingTypeId };
  }
  return null;
};

export const ClientProjectCell: FC<IClientProjectCellProps> = ({
  cell: { value: initialValue },
  row: { index },
  column: { id },
  updateMyData,
  errors,
  touched,
  handleBlur,
  data
}) => {
  // redux
  const { clientProjectsList } = useSelector((state: IAppState) => state.clients);

  // We need to keep and update the state of the cell normally
  const [value, setValue] = useState(initialValue);

  // If the initialValue is changed externall, sync it up with our state
  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  const selectValue = getValue(value, clientProjectsList);
  return (
    <Autocomplete
      id={`${id}-search-autocomplete-${index}`}
      options={clientProjectsList
        .filter(x => x.clientId === data[index].clientId && x.projectStatus === CLIENT_PROJECT_STATUSES.APPROVED) // filter down to current client only
        .sort((a, b) => (a.projectName > b.projectName) ? 1 : -1)
        .map(project => ({ label: project.projectName, value: project.clientProjectId, billingTypeId: project.billingTypeId }))}
      getOptionLabel={option => option && option.label}
      value={selectValue}
      disabled={data[index].isLocked}
      onChange={(e: any, value: any) => {
        setValue(value ? value.value : null);
        updateMyData(index, 'clientProjectId', value ? value.value : null);
        if (value) {
          updateMyData(index, 'billingTypeId', value.billingTypeId);
        }
      }}
      renderInput={params => {
        const inputProps = params.inputProps;
        (inputProps as any).autoComplete = 'off';
        return (
          <TextField
            {...params}
            inputProps={inputProps}
            onBlur={handleBlur}
            name={`timeEntries[${index}].${id}`}
            error={!selectValue && get(errors, `timeEntries[${index}].${id}`) && get(touched, `timeEntries[${index}].${id}`)}
            helperText={
              !selectValue &&
              get(errors, `timeEntries[${index}].${id}`) &&
              get(touched, `timeEntries[${index}].${id}`) &&
              get(errors, `timeEntries[${index}].${id}`)
            }
            FormHelperTextProps={{ style: { position: 'absolute', pointerEvents: 'none' } }}
          />
        );
      }}
    />
  );
};
