import React, { FC, HTMLAttributes } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
// Components
import { Grid } from '@material-ui/core';

interface IWidgetWrapperProps extends HTMLAttributes<HTMLDivElement> {}

export const WidgetWrapper: FC<IWidgetWrapperProps> = ({ children }) => {
  const classes = WidgetWrapperStyles();

  return (
    <Grid container justify='flex-start' spacing={1} className={classes.dashboardContainer}>
      {children}
    </Grid>
  );
};

const WidgetWrapperStyles = makeStyles((theme: Theme) => ({
  dashboardContainer: {
    marginBottom: theme.spacing(1)
  }
}));
