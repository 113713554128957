import React, { FC, useState } from 'react';
import ReactDOM from 'react-dom';
// Material setup
import { ThemeProvider } from '@material-ui/core/styles';
import { theme } from '@shared/helpers';
// Components
import { ConfirmationDialogue } from './ConfirmationDialogue';

interface IAsyncConfirmDialogueProps {
  resolve: (confirmed: boolean) => void;
  title: string;
  text: string;
}

const AsyncConfirmDialogue: FC<IAsyncConfirmDialogueProps> = ({ resolve, title, text }) => {
  const [isModalShowing, setIsModalShowing] = useState(true);
  // MUST wrap in theme provider or this blows away our custom themes
  return (
    <ThemeProvider theme={theme}>
      <ConfirmationDialogue
        id='async-confirm-dialogue'
        open={isModalShowing}
        title={title}
        text={text}
        onConfirm={() => {
          resolve(true);
          setIsModalShowing(false);
          removeDialog();
        }}
        onClose={() => {
          resolve(false);
          setIsModalShowing(false);
          removeDialog();
        }}
      />
    </ThemeProvider>
  );
};

interface IDialogueProps {
  title: string;
  text: string;
}

export const asyncConfirmDialogue = ({ title, text }: IDialogueProps) => {
  return new Promise((resolve, reject) => {
    addDialog({ title, text }, resolve);
  });
};

const addDialog = ({ title, text }: IDialogueProps, resolve: (confirm: boolean) => void) => {
  const body = document.getElementsByTagName('body')[0];
  const div = document.createElement('div');
  div.setAttribute('id', 'confirm-dialogue-container');
  body.appendChild(div);
  ReactDOM.render(<AsyncConfirmDialogue title={title} text={text} resolve={resolve} />, div);
};

const removeDialog = () => {
  const div = document.getElementById('confirm-dialogue-container');
  const body = document.getElementsByTagName('body')[0];
  if (div) {
    body.removeChild(div);
  }
};
