import React, { FC } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { IAppState, IDashboardReportCard, IProgressReport, IReport, IUserProps } from '@shared/types';
import { useSelector } from 'react-redux';
// components
import { Grid, GridSize, Typography, IconButton, MenuItem, Menu, Button, CircularProgress, Box } from '@material-ui/core';
import InsertChartOutlinedIcon from '@material-ui/icons/InsertChartOutlined';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { DeleteForever } from '@material-ui/icons';
import LinkIcon from '@material-ui/icons/Link';
import { IDisplaySharedFile } from '@shared/types/sharedFiles';
import { format } from 'date-fns';
import { TileCard } from '@shared/components/tile-card';

interface IReportGridCardProps {
  gridBreakpoint?: GridSize;
  isMobile: boolean;
  icon?: any;
  report: (IProgressReport & IDashboardReportCard) | (IDisplaySharedFile & IDashboardReportCard) | (IReport & IDashboardReportCard);
  children?: React.ReactNode;
  reactRouterLinkOnClick?: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
  onContainerClick?: () => void;
  handleDownload?: () => void;
  kebabMenuItems?: {
    key: string | number;
    value: string | number;
    onClick: (value?: string | number) => void;
  }[];
  handleDelete?: () => void;
  handleDeepLink?: (e: React.MouseEvent<HTMLElement>) => void;
  isLoading?: boolean;
  isDeleting?: boolean;
  isWidget?: boolean;
  isPrimaryColor?: boolean;
  dateTimeUploaded?: string;
  userUploaded?: string;
  titleOverflow?: boolean;
  alignCenter?: boolean;
  extraActions?: JSX.Element | null
}

export const ReportGridCard: FC<IReportGridCardProps> = ({
  gridBreakpoint = 12,
  isMobile,
  report,
  kebabMenuItems,
  onContainerClick = () => {
    return;
  },
  handleDownload = () => {
    return;
  },
  children,
  reactRouterLinkOnClick,
  icon = InsertChartOutlinedIcon,
  handleDelete,
  handleDeepLink,
  isLoading,
  isDeleting,
  isWidget = false,
  isPrimaryColor = false,
  dateTimeUploaded,
  userUploaded,
  titleOverflow = false,
  alignCenter = false,
  extraActions
}) => {
  const user: IUserProps = useSelector((state: IAppState) => state.user);
  const { userAccess } = user;
  const classes = useStyles({ isPrimaryColor });
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const closeKebabMenu = () => {
    setAnchorEl(null);
  };

  const handleInteraction = () => {
    if (onContainerClick) onContainerClick();
    if (handleDownload) handleDownload();
  };

  const cardFooter = () => {
    if (reactRouterLinkOnClick && report.hasOwnProperty('sprintEndDate') && report.hasOwnProperty('sprintStartDate')) {
      return (
        <Typography>
          <strong>{`(${format(Date.parse((report as IProgressReport)?.sprintStartDate), 'MMM d')} - ${format(
            Date.parse((report as IProgressReport)?.sprintEndDate),
            'MMM d'
          )})`}</strong>
        </Typography>
      );
    }
    if (!reactRouterLinkOnClick) {
      return (
        <Typography>
          <strong>{userUploaded}</strong>
        </Typography>
      );
    }
    return undefined;
  };
  return (
    <TileCard
      isMobile={isMobile}
      gridBreakpoint={gridBreakpoint}
      reactRouterLinkOnClick={reactRouterLinkOnClick}
      onClick={handleInteraction}
      linkTo={report?.linkTo}
      icon={icon}
      iconColor={isPrimaryColor ? 'primary' : 'secondary'}
      title={report?.title}
      titleOverflow={titleOverflow}
      alignCenter={alignCenter}
      titleComponent={isWidget ? 'h3' : 'h2'}
      header={reactRouterLinkOnClick ? children : <Typography>{dateTimeUploaded}</Typography>}
      content={
        <Grid container>
          <Grid item xs={6}>
            {!!handleDelete && !isWidget && userAccess.ViewAdmin && (
              <Button
                disabled={isDeleting || isLoading}
                color='inherit'
                startIcon={<DeleteForever />}
                onClick={() => handleDelete()}
                justify-self='flex-start'
              >
                Delete{isDeleting && <CircularProgress size={24} className={classes.progress} />}
              </Button>
            )}
          </Grid>
          <Grid item xs={6}>
            <Box display='flex' justifyContent='flex-end' alignItems='center'>
              {!!handleDeepLink && !isWidget && (
                <IconButton
                  aria-label='copy'
                  className={classes.copyLinkButton}
                  onClick={e => {
                    handleDeepLink(e);
                  }}
                  justify-self='flex-end'
                >
                  <LinkIcon />
                </IconButton>
              )}
              {extraActions}
            </Box>
          </Grid>
        </Grid>
      }
      footer={cardFooter()}
      action={
        <>
          {/* KEBAB BUTTON */}
          <Grid item xs={1} justify='center'>
            {!!kebabMenuItems?.length && (
              <IconButton classes={{ root: classes.kebabButton }} onClick={(event: any) => setAnchorEl(event.currentTarget)}>
                <MoreVertIcon />
              </IconButton>
            )}
          </Grid>
          {/* KEBAB MENU */}
          <Menu anchorEl={anchorEl} open={open} onClose={closeKebabMenu}>
            {kebabMenuItems?.map(item => (
              <MenuItem
                key={item.key}
                onClick={() => {
                  closeKebabMenu();
                  item.onClick(item.value);
                }}
              >
                {item.value}
              </MenuItem>
            ))}
          </Menu>
        </>
      }
    />
  );
};

const useStyles = makeStyles<Theme>((theme: Theme) => ({
  kebabButton: {
    padding: 0
  },
  progress: {
    position: 'absolute',
    top: 7,
    left: 2,
    zIndex: 1
  },
  copyLinkButton: {
    transition: 'background-color 0.3s',
    '&:active': {
      backgroundColor: 'rgba(0, 0, 0, 0.2)'
    }
  }
}));
