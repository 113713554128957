import React, { FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';
// Components

import { List, ListItem, ListItemText } from '@material-ui/core';
import { IClientProjectLaborRate } from '@shared/types';

interface ICustomCardProps {
  laborRates?: IClientProjectLaborRate[];
}

export const RateContent: FC<ICustomCardProps> = ({ laborRates }) => {
  const classes = useStyles();

  return (
    <List disablePadding>
      {Array.isArray(laborRates) &&
        laborRates.map((laborRate, i) => (
          <ListItem disableGutters key={`labor-rate-${laborRate.employeeRateId}-${i}`}>
            <ListItemText primary={laborRate.isEmployeeRate ? `${laborRate.employeeLastName}, ${laborRate.employeeFirstName}` : laborRate.role} />
            <div className={classes.rateBox}>
              <span className='rate'>{laborRate.currentRate ? `$${laborRate.currentRate}` : ''}</span>
              <span className='rateUnit'> / hr</span>
            </div>
            <span className={classes.rateType}>{laborRate.rateType}</span>
          </ListItem>
        ))}
    </List>
  );
};

const useStyles = makeStyles(theme => ({
  addButton: {
    marginTop: theme.spacing(1)
  },
  primaryHeader: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    marginBottom: theme.spacing(1)
  },
  primaryHeaderBudget: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    marginBottom: theme.spacing(1),
    padding: '15px 16px 36px 16px',
    '@media (min-width: 380px)': {
      padding: 16
    }
  },
  marginBottom: {
    marginBottom: theme.spacing(1),
    position: 'relative'
  },
  buttonToolbar: {
    '& > button': {
      marginRight: theme.spacing(1)
    }
  },
  delete: {
    color: theme.palette.error.main
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    '& > div:not(:first-of-type)': {
      marginTop: theme.spacing(1)
    }
  },
  descriptionMultiline: {
    height: theme.spacing(8),
    '& textarea': {
      height: '100%'
    }
  },
  outlinedLabel: {
    backgroundColor: theme.palette.common.white,
    paddingLeft: 2,
    paddingRight: 2
  },
  budgetInformationGrid: {
    '& > div': {
      minWidth: '20%'
    }
  },
  divider: {
    marginBottom: '0'
  },

  rateBox: {
    paddingRight: '1rem'
  },
  rateType: {
    color: theme.palette.grey[500],
    width: 90
  }
}));
