import React, { FC, useState, useEffect } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { FormLabel, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails, Typography, Link } from '@material-ui/core';
import { ExpandMore, Launch } from '@material-ui/icons';
import format from 'date-fns/format';
import { capitalize } from '@shared/helpers/format';
import { StatusButton } from '../../../shared/components/buttons/StatusButton';
import { IClientZendeskRequest, IClientRelationsLookup } from '@shared/types';
import { FormikHandlers, FieldMetaProps } from 'formik';
import { getClientZendeskStatuses, getClientZendeskSeverities, getClientZendeskFormTypes } from '@shared/fetch';

interface IMobileClientRequests extends FormikHandlers, FieldMetaProps<any> {
  original: IClientZendeskRequest;
}

export const MobileClientRequests: FC<IMobileClientRequests> = ({ original }) => {
  const [statuses, setStatuses] = useState<IClientRelationsLookup[]>([]);
  const [severities, setSeverities] = useState<IClientRelationsLookup[]>([]);
  const [formTypes, setFormTypes] = useState<IClientRelationsLookup[]>([]);
  const [isExpanded, setIsExpanded] = useState(false);
  const { ticketId, statusId, title, severity, requester, requested, lastUpdated, sprintIteration } = original;
  const statusShorthand = getShorthandStatusById(original.statusId).toLowerCase();
  const borderColor = getStatusBorderColor(statusShorthand);
  const classes = useStyles({ borderColor });

  const handleExpansionChange = (event: any, expanded: any) => {
    setIsExpanded(expanded);
  };

  function getDescriptionById(id: number | string) {
    const foundObject = formTypes?.find(obj => obj.value === id);
    if (foundObject) {
      return foundObject.description;
    } else {
      return '--';
    }
  }

  function getTextById(id: number | string) {
    const foundObject = severities?.find(obj => obj.value === id);
    if (foundObject) {
      const statusNumber = foundObject.text.split(' - ')[0];
      return <StatusButton status={capitalize(statusNumber)} />;
    } else {
      return '--';
    }
  }

  function getShorthandById(id: number | string) {
    const foundObject = statuses?.find(obj => obj.value === id);
    if (foundObject) {
      return <StatusButton status={capitalize(foundObject.shorthand)} />;
    } else {
      return '--';
    }
  }

  function getShorthandStatusById(id: number | string): string {
    const foundObject = statuses?.find(obj => obj.value === id);
    if (foundObject) {
      return foundObject.shorthand;
    } else {
      return '';
    }
  }

  const fetchZendeskStatuses = async () => {
    try {
      const res = await getClientZendeskStatuses();
      setStatuses(res);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchZendeskSeverities = async () => {
    try {
      const res = await getClientZendeskSeverities();
      setSeverities(res);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchZendeskFormTypes = async () => {
    try {
      const res = await getClientZendeskFormTypes();
      setFormTypes(res);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchZendeskStatuses();
    fetchZendeskSeverities();
    fetchZendeskFormTypes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ExpansionPanel className={classes.root} expanded={isExpanded} onChange={handleExpansionChange}>
      <ExpansionPanelSummary className={classes.expansionPanel} expandIcon={<ExpandMore />} aria-controls='panel1a-content' id='panel1a-header'>
        <div className={classes.panelSummaryWrapper}>
          <div className={classes.titleAndLink}>
            <Typography component='span' className={isExpanded ? '' : classes.ellipsis}>
              {title}
            </Typography>
            <Link
              href={`https://support.mercuryworks.com/hc/en-us/requests/${ticketId}`}
              className={isExpanded ? classes.launchIconLinkExpanded : classes.launchIconLink}
              onClick={(event: React.MouseEvent<HTMLAnchorElement>) => event.stopPropagation()}
            >
              <Launch className={classes.launchIcon} />
            </Link>
          </div>
          <div>
            <Typography component='span'>{statusId ? getShorthandById(statusId) : ''}</Typography>
            <Typography component='span' style={{ paddingLeft: '4px' }}>
              {severity ? getTextById(severity) : ''}
            </Typography>
          </div>
        </div>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails className={classes.details}>
        <FormLabel component='h2'>SPRINT ITERATION</FormLabel>
        <Typography component='span' style={{ fontWeight: 'bold' }}>
          {sprintIteration?.toUpperCase() || '--'}
        </Typography>

        <FormLabel component='h2'>REQUESTOR</FormLabel>
        <Typography component='span' style={{ fontWeight: 'bold' }}>
          {requester.toUpperCase() || '--'}
        </Typography>

        <FormLabel component='h2'>REQUESTED</FormLabel>
        <Typography component='span' style={{ fontWeight: 'bold' }}>
          {format(new Date(requested), 'MM/dd/yy h:mma') || '--'}
        </Typography>

        <FormLabel component='h2'>LAST UPDATED</FormLabel>
        <Typography component='span' style={{ fontWeight: 'bold' }}>
          {format(new Date(lastUpdated), 'MM/dd/yy h:mma') || '--'}
        </Typography>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};

function getStatusBorderColor(status: string): string {
  let statusLower = status.toLowerCase();
  if (statusLower === 'open') {
    return '#c72a1c';
  } else if (statusLower === 'approved') {
    return '#c72a1c';
  } else if (statusLower === 'ready') {
    return '#c72a1c';
  } else if (statusLower === 'awaiting your reply') {
    return '#3091ec';
  } else if (statusLower === 'in progress') {
    return '#c72a1c';
  } else if (statusLower === 'internal testing') {
    return '#3091ec';
  } else {
    return '#87929d';
  }
}

const useStyles = makeStyles<Theme, { borderColor: string }>(() => ({
  root: {
    maxWidth: '100%',
    backgroundColor: '#f1f5f9',
    borderLeft: props => `4px solid ${props.borderColor}`
  },
  expansionPanel: {
    padding: '0px 24px'
  },
  panelSummaryWrapper: {
    display: 'flex',
    alignItems: 'flex-start',
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    '@media (max-width: 850px)': {
      flexDirection: 'column',
      alignItems: 'flex-start'
    }
  },
  details: {
    display: 'flex',
    flexDirection: 'column'
  },
  launchIconLink: {
    verticalAlign: '3px',
    marginLeft: '4px'
  },
  launchIconLinkExpanded: {
    verticalAlign: '-3px',
    marginLeft: '4px'
  },
  launchIcon: {
    fontSize: '16px'
  },
  ellipsis: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    display: 'inline-block',
    '@media (max-width: 1100px)': {
      maxWidth: '500px'
    },
    '@media (max-width: 900px)': {
      maxWidth: '450px'
    },
    '@media (max-width: 715px)': {
      maxWidth: '350px'
    },
    '@media (max-width: 600px)': {
      maxWidth: '285px'
    },
    '@media (max-width: 450px)': {
      maxWidth: '230px'
    },
    '@media (max-width: 400px)': {
      maxWidth: '185px'
    }
  },
  titleAndLink: {
    minWidth: '450px',
    '@media (max-width: 650px)': {
      minWidth: '325px'
    },
    '@media (max-width: 450px)': {
      minWidth: '225px'
    },
    '@media (max-width: 400px)': {
      minWidth: '200px'
    }
  }
}));
