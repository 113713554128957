import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import * as localForage from 'localforage';
// redux
import { useDispatch, useSelector } from 'react-redux';
// Components
import { ProgressReportDetail } from '../components/ProgressReportDetail';
// fetch
import { getSingleProgressReportByReportId, getSingleProgressReportSprintAccomplishments, getSingleProgressReportSprintPlans } from '@shared/fetch';
// redux
import { setExtranetClient, setCurrentReport } from '@shared/redux/actions';
// types
import { IAppState, IProgressReportDetail, IUserProps } from '@shared/types';
// constants
import { selectedClientIdLSKey } from '@shared/constants';

export const ProgressReport = () => {
  const { selectedClient } = useSelector((state: IAppState) => state.extranet);
  const user: IUserProps = useSelector((state: IAppState) => state.user);
  const { ClientFinancialsData } = user.userAccess;
  // router
  // @ts-ignore
  const { reportId } = useParams();
  // dispatch
  const dispatch = useDispatch();
  // api
  const fetchProgressReport = async () => {
    if (reportId) {
      try {
        const report = await getSingleProgressReportByReportId(Number(reportId), { withBudget: ClientFinancialsData });
        let sprintActivities = report.sprintActivities;
        let mercuryNeeds = report.mercuryNeeds;
        if (!report.sprintActivities) {
          let sprint = await getSingleProgressReportSprintAccomplishments(report.clientId, report.projectIterationUuid);
          sprintActivities = sprint.sprintAccomplishments;
        }
        if (!report.mercuryNeeds) {
          mercuryNeeds = await getSingleProgressReportSprintPlans(report.clientId, report.projectIterationUuid);
        }
        dispatch(
          setCurrentReport({
            ...report,
            sprintActivities,
            mercuryNeeds
          })
        );
        // set local forage with the correct clientId
        localForage.setItem(selectedClientIdLSKey, report.clientId);
        dispatch(
          setExtranetClient({
            ...selectedClient,
            clientId: report.clientId,
            name: report.clientName
          })
        );
      } catch (error) {
        console.log(error);
      }
    }
  };

  const tempReport: IProgressReportDetail = {
    clientId: 0,
    clientName: '',
    projectIterationUuid: '',
    sprintName: '',
    sprintDisplayName: '',
    needsRefresh: false,
    title: '',
    sendNotification: true,
    published: false,
    portfolioStatus: '',
    sprintActivities: '',
    mercuryNeeds: '',
    budgetSpendTotalAmount: 0,
    expendedLaborHours: 0,
    userStoriesCompleted: 0,
    displayBudgetSpend: false,
    displayExpendedLaborHours: false,
    displayUserStoriesCompleted: false,
    laborExpenses: { accruedExpenses: [], sprintExpenses: [] }
  };
  // effects
  useEffect(() => {
    fetchProgressReport();
  }, []);
  // redux
  const { currentReport } = useSelector((state: IAppState) => state.progressReports);
  return (
    <>
      {!currentReport && <ProgressReportDetail currentReport={tempReport} isLoadingReport={true} />}
      {currentReport && <ProgressReportDetail currentReport={currentReport} isLoadingReport={false} />}
    </>
  );
};
