import React, { FC, useState, useEffect } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { useHistory, useLocation } from 'react-router-dom';
// components
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Close from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { CardLoader } from '@shared/components/loader/CardLoader';
import { Modal } from '@shared/components/modals/Modal';
import { UserStoryKanbanCard } from './UserStoryKanbanCard';
import { UserStoryEpicsExpansion } from './UserStoryEpicsExpansion';
import { UserStoryFeaturesExpansion } from './UserStoryFeaturesExpansion';
import { Empty } from '@shared/components/empty';
// fetch
import { getWorkItemCounts } from '@shared/fetch/workItems';
// helpers
import { formatSprint, formatWorkItemType } from '@shared/helpers';
// types
import {
  IWorkItemState,
  WorkItemStates,
  WorkItemStatusLabels,
  IUserEpic,
  IUserStoryFeature,
  IUserStory,
  IUserStoryDetail,
  WorkItemTypes,
  IModifiedSearchValue,
  IExtranetSprintItem,
  WorkItemStatuses
} from '@shared/types';

interface IKanbanColumn extends IWorkItemState {
  count: number;
  defaultExpanded?: boolean;
  mappedStates?: WorkItemStatusLabels[];
}

const DEFAULT_KANBAN_COLUMNS: IKanbanColumn[] = [
  {
    count: 0,
    defaultExpanded: true,
    id: WorkItemStates.NEW,
    label: 'New',
    mappedStates: ['Blocked', 'New'],
    value: 'New'
  },
  {
    count: 0,
    id: WorkItemStates.APPROVED,
    label: 'Approved',
    mappedStates: ['Refined', 'Approved'],
    value: 'Approved'
  },
  {
    count: 0,
    id: WorkItemStates.IN_PROGRESS,
    label: 'In Progress',
    mappedStates: ['Committed', 'Demo Ready', 'PR/Review', 'Remediating', 'Test Ready', 'Releasing'],
    value: 'InProgress'
  },
  {
    count: 0,
    id: WorkItemStates.DONE,
    label: 'Done',
    value: 'Done'
  }
];

interface IUserStoryKanbanViewProps {
  clientId: number | null;
  currentEpicId: number | null;
  currentFeatureId: number | null;
  currentStoryId: number | null;
  epics: IUserEpic[];
  setEpics: React.Dispatch<React.SetStateAction<IUserEpic[] | null>>;
  setFeatures: React.Dispatch<React.SetStateAction<IUserStoryFeature[]>>;
  features: IUserStoryFeature[];
  fetchFeatures: (id?: number) => void;
  handleFeatureClick: (id: number | null) => void;
  handleStoryClick: (id: number | null) => void;
  handleClearSearch: () => void;
  isLoadingEpics: boolean;
  isLoadingFeatures: boolean;
  isLoadingProjects: boolean;
  isLoadingStories: boolean;
  isLoadingStoryDetail: boolean;
  isSearching: boolean;
  searchedInputValue: string;
  searchResults: IModifiedSearchValue[] | null;
  selectedSprint: IExtranetSprintItem | null;
  selectedStateFilter: WorkItemStatuses | '';
  selectedStory?: IUserStoryDetail;
  selectedStories: IUserStoryDetail[];
  selectedTypeFilter: WorkItemTypes;
  setCurrentEpicId: React.Dispatch<React.SetStateAction<number | null>>;
  setCurrentFeatureId: React.Dispatch<React.SetStateAction<number | null>>;
  setCurrentStoryId: React.Dispatch<React.SetStateAction<number | null>>;
  setShowEpicModal: React.Dispatch<React.SetStateAction<boolean>>;
  setShowFeatureModal: React.Dispatch<React.SetStateAction<boolean>>;
  setShowStoryModal: React.Dispatch<React.SetStateAction<boolean>>;
  showEpicModal: boolean;
  showFeatureModal: boolean;
  showStoryModal: boolean;
  stories: IUserStory[];
  userStories: IUserStory[];
  projectId: string | number;
}

export const UserStoryKanbanView: FC<IUserStoryKanbanViewProps> = ({
  clientId,
  currentEpicId,
  currentFeatureId,
  currentStoryId,
  epics,
  setEpics,
  features,
  setFeatures,
  fetchFeatures,
  handleFeatureClick,
  handleStoryClick,
  handleClearSearch,
  isLoadingEpics,
  isLoadingFeatures,
  isLoadingProjects,
  isLoadingStories,
  isLoadingStoryDetail,
  isSearching,
  searchedInputValue,
  searchResults,
  selectedSprint,
  selectedStateFilter,
  selectedStories,
  selectedTypeFilter,
  setCurrentEpicId,
  setCurrentFeatureId,
  setCurrentStoryId,
  setShowEpicModal,
  setShowFeatureModal,
  setShowStoryModal,
  showEpicModal,
  showFeatureModal,
  stories,
  userStories,
  projectId
}) => {
  const classes = userStoryKanbanViewStyles();

  const [columns, setColumns] = useState<IKanbanColumn[]>(DEFAULT_KANBAN_COLUMNS);
  const [columnsError, setColumnsError] = useState<Error | null>(null);
  const [columnsLoading, setColumnsLoading] = useState(false);

  const history = useHistory();
  const location = useLocation();

  const fetchWorkItemCounts = async () => {
    if (!columnsLoading && clientId && selectedSprint) {
      try {
        setColumnsError(null);
        setColumnsLoading(true);
        setColumns(DEFAULT_KANBAN_COLUMNS);

        if (!isSearching && !searchResults) {
          const result = await getWorkItemCounts(
            clientId,
            selectedTypeFilter === 'Story' ? ['Bug', 'Story'] : [selectedTypeFilter],
            (selectedSprint && selectedSprint.projectIterationUuid) || undefined,
            selectedStateFilter || undefined,
            projectId ? [projectId] : undefined
          );
          // map counts from the API to the local column counts
          const _columns = columns.slice();
          result.counts.map(count => {
            _columns.map(column => {
              if (column.mappedStates && column.mappedStates.includes(count.status)) {
                var mappedResults = result.counts.filter(res => column.mappedStates && column.mappedStates.includes(res.status));
                var totalCount = mappedResults.reduce((accum, item) => accum + item.count, 0);
                column.count = totalCount;
              } else if (count.status === column.value) {
                column.count = count.count;
              }
            });
          });
          setColumns(_columns);
        } else {
          // if the user is searching, reset the columns total counts
          setColumns(DEFAULT_KANBAN_COLUMNS);
        }
      } catch (error) {
        setColumnsError({ message: 'Error loading column counts.', name: 'fetchWorkItemCounts' });
      } finally {
        setColumnsLoading(false);
      }
    }
  };

  useEffect(() => {
    setColumns(DEFAULT_KANBAN_COLUMNS);
    fetchWorkItemCounts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientId, selectedSprint, projectId, selectedStateFilter, selectedTypeFilter]);

  if (
    (!isLoadingProjects && !isLoadingEpics && selectedTypeFilter === 'Epic' && epics.length === 0 && !searchResults && !isSearching) ||
    (!isLoadingProjects && !isLoadingFeatures && selectedTypeFilter === 'Feature' && features.length === 0 && !searchResults && !isSearching) ||
    (!isLoadingProjects && !isLoadingStories && selectedTypeFilter === 'Story' && stories.length === 0 && !searchResults && !isSearching)
  ) {
    // empty state is shown on <UserStories /> container
    return null;
  }

  // for Epics modal
  const selectedEpic = searchResults ? searchResults.find(result => result.id === currentEpicId) : epics.find(epic => epic.id === currentEpicId);
  let selectedEpicFeatures: IUserStoryFeature[] = [];
  if (selectedEpic) {
    selectedEpicFeatures = features.filter(feature => feature.epicId === selectedEpic.id);
  }

  // for Features modal
  const selectedFeature = searchResults
    ? searchResults.find(feature => feature.id === currentFeatureId)
    : features.find(feature => feature.id === currentFeatureId);

  return (
    <>
      {!isSearching && searchResults && (
        <Grid container alignItems='center' className={classes.noResultsWrapper}>
          <Typography variant='h4' color='secondary'>
            {searchResults && searchResults.length === 0
              ? `No search results for "${searchedInputValue}"`
              : `Showing results for "${searchedInputValue}"`}
          </Typography>
          <Button size='small' color='default' startIcon={<Close />} className={classes.clearSearchButton} onClick={() => handleClearSearch()}>
            Clear Search
          </Button>
        </Grid>
      )}
      <div className={classes.columns}>
        {columns.map(column => {
          // filter down to just the work items for this column
          const _epics =
            selectedTypeFilter === 'Epic'
              ? searchResults
                ? searchResults.filter(result => result.state === column.label)
                : epics.filter(epic => epic.state === column.label)
              : [];

          const _features =
            selectedTypeFilter === 'Feature'
              ? searchResults
                ? searchResults.filter(result => result.state === column.label)
                : features.filter(feature => feature.state === column.label)
              : [];

          const _stories =
            selectedTypeFilter === 'Story'
              ? searchResults
                ? searchResults.filter(result => {
                    let matches = false;
                    if (result.state === column.label) {
                      matches = true;
                    }
                    // this is an exception for 'In Progress'
                    // where 'Blocked', 'Committed', 'Demo Ready', 'PR/Review', 'Remediating', and 'Test Ready'
                    // are also considered 'In Progress' for a result
                    if (!matches && column.mappedStates) {
                      const mapped = column.mappedStates.find(mappedState => mappedState === result.state);
                      if (mapped) {
                        matches = true;
                      }
                    }
                    return matches;
                  })
                : stories.filter(story => {
                    let matches = false;
                    if (story.state === column.label) {
                      matches = true;
                    }
                    // This handles mapping ADO states to the column states
                    if (!matches && column.mappedStates) {
                      const mapped = column.mappedStates.find(mappedState => mappedState === story.state);
                      if (mapped) {
                        matches = true;
                      }
                    }
                    return matches;
                  })
              : [];

          return (
            <ExpansionPanel
              classes={{ root: classes.panel }}
              defaultExpanded={column.defaultExpanded}
              key={`panel-${column.value}`}
              onChange={() => {}}
            >
              <ExpansionPanelSummary
                aria-controls={`details-${column.value}`}
                classes={{ content: classes.summaryContent, expandIcon: classes.summaryExpandIcon, root: classes.summary }}
                expandIcon={<ExpandMore />}
                id={`header-${column.value}`}
              >
                <div className={classes.summaryHeader}>
                  <Typography className={classes.h2} variant='h2'>
                    {column.label}
                  </Typography>
                  {!columnsError && !searchResults && (
                    <Typography className={classes.count} variant='body1'>
                      {columnsLoading ? 0 : column.count} Total
                    </Typography>
                  )}
                </div>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails classes={{ root: classes.details }}>
                {!isLoadingProjects &&
                  ((!isLoadingEpics && selectedTypeFilter === 'Epic' && _epics.length === 0) ||
                    (!isLoadingFeatures && selectedTypeFilter === 'Feature' && _features.length === 0) ||
                    (!isLoadingStories && selectedTypeFilter === 'Story' && _stories.length === 0)) && (
                    <Empty
                      className={classes.empty}
                      messages={[`There are no ${formatWorkItemType(selectedTypeFilter, true)} to show.`]}
                      showIcon={false}
                    />
                  )}

                {selectedTypeFilter === 'Epic' &&
                  _epics.length > 0 &&
                  // @ts-ignore
                  _epics.map((epic: IUserEpic) => {
                    return (
                      <UserStoryKanbanCard
                        count={epic.childCount}
                        id={epic.id}
                        key={`epic-${epic.id}`}
                        onClick={() => {
                          setCurrentEpicId(epic.id);
                          setShowEpicModal(true);
                          if (searchResults) {
                            fetchFeatures(epic.id);
                          }
                        }}
                        state={epic.state as WorkItemStatusLabels}
                        title={epic.title}
                        workItemType='Epic'
                        effort={epic.effort}
                        remainingWork={epic?.remainingWork}
                      />
                    );
                  })}

                {selectedTypeFilter === 'Feature' &&
                  _features.length > 0 &&
                  // @ts-ignore
                  _features.map((feature: IUserStoryFeature) => {
                    return (
                      <UserStoryKanbanCard
                        count={feature.childCount}
                        id={feature.id}
                        key={`feature-${feature.id}`}
                        onClick={() => {
                          setCurrentFeatureId(feature.id);
                          setShowFeatureModal(true);
                        }}
                        state={feature.state as WorkItemStatusLabels}
                        title={feature.title}
                        workItemType='Feature'
                        effort={feature.effort}
                        remainingWork={feature?.remainingWork}
                      />
                    );
                  })}

                {selectedTypeFilter === 'Story' &&
                  _stories.length > 0 &&
                  // @ts-ignore
                  _stories.map((story: IUserStory) => {
                    return (
                      <UserStoryKanbanCard
                        effort={story.effort}
                        id={story.id}
                        key={`story-${story.id}`}
                        onClick={() => {
                          history.push(`${location.pathname}?workitem=${story.id}`);
                          setCurrentStoryId(story.id);
                          setShowStoryModal(true);
                        }}
                        remainingWork={story.remainingWork}
                        sprint={formatSprint(story.sprintName, story.sprintContainer)}
                        state={story.state as WorkItemStatusLabels}
                        title={story.title}
                        workItemType={story.workItemType}
                      />
                    );
                  })}

                {(isLoadingProjects ||
                  (isLoadingEpics && selectedTypeFilter === 'Epic') ||
                  (isLoadingFeatures && selectedTypeFilter === 'Feature') ||
                  (isLoadingStories && selectedTypeFilter === 'Story') ||
                  isSearching) && <CardLoader />}
              </ExpansionPanelDetails>
            </ExpansionPanel>
          );
        })}
      </div>
      <Modal
        onClose={() => {
          setCurrentEpicId(null);
          setShowEpicModal(false);
          handleFeatureClick(null);
          handleStoryClick(null);
          setFeatures([]);
        }}
        open={showEpicModal}
        subtitle={selectedEpic ? `${selectedEpic.id}  ${selectedEpic.title}` : selectedEpic}
        // @ts-ignore
        title={`Epic Stories: ${((selectedEpic && selectedEpic.childCount) || selectedEpicFeatures?.childCount) ?? 0} Total`}
      >
        <UserStoryEpicsExpansion
          currentEpicId={currentEpicId}
          currentFeatureId={currentFeatureId}
          currentStoryId={currentStoryId}
          // @ts-ignore
          epics={selectedEpic ? [selectedEpic] : []}
          features={selectedEpicFeatures}
          handleFeatureClick={handleFeatureClick}
          handleStoryClick={handleStoryClick}
          isLoadingFeatures={isLoadingFeatures}
          isLoadingStories={isLoadingStories}
          isLoadingStoryDetail={isLoadingStoryDetail}
          selectedStories={selectedStories}
          selectedTypeFilter={selectedTypeFilter}
          selectedView='Kanban'
          userStories={userStories}
        />
      </Modal>
      <Modal
        onClose={() => {
          setCurrentFeatureId(null);
          setShowFeatureModal(false);
        }}
        open={showFeatureModal}
        subtitle={selectedFeature ? `${selectedFeature.id}  ${selectedFeature?.title}` : ''}
        // @ts-ignore
        title={`Feature Stories: ${(selectedFeature && selectedFeature.childCount) || userStories.length} Total`}
      >
        <UserStoryFeaturesExpansion
          // @ts-ignore
          allFeatureUserStories={selectedFeature ? [selectedFeature] : []}
          currentFeatureId={currentFeatureId}
          currentStoryId={currentStoryId}
          handleFeatureClick={handleFeatureClick}
          handleStoryClick={handleStoryClick}
          isLoadingStories={isLoadingStories}
          isLoadingStoryDetail={isLoadingStoryDetail}
          selectedStories={selectedStories}
          selectedView='Kanban'
          userStories={userStories}
        />
      </Modal>
    </>
  );
};

const userStoryKanbanViewStyles = makeStyles((theme: Theme) => {
  const breakpoint = '@media (min-width: 1024px)';
  return {
    columns: {
      [breakpoint]: {
        display: 'flex',
        flex: 1,
        paddingBottom: theme.spacing(2),
        width: '100%'
      }
    },
    count: {
      color: theme.palette.grey[500],
      fontFamily: 'Poppins-Light, sans-serif',
      fontSize: 16,
      lineHeight: 1.6,
      margin: theme.spacing(0.125, 0, 0)
    },
    details: {
      backgroundColor: '#f1f2f2',
      borderTop: `2px solid #9e9e9f`,
      display: 'block',
      flex: 1,
      padding: theme.spacing(0.75)
    },
    empty: {
      color: theme.palette.grey[500],
      [breakpoint]: {
        display: 'none'
      }
    },
    h2: {
      fontFamily: 'Poppins-Light, sans-serif',
      fontSize: 18,
      lineHeight: 1.6,
      margin: theme.spacing(0),
      marginRight: 'auto'
    },
    panel: {
      '@media print': {
        '& .MuiCollapse-container': {
          height: 'auto',
          visibility: 'visible'
        }
      },
      border: `1px solid ${theme.palette.grey[300]}`,
      borderRadius: '0 !important',
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      marginBottom: `${theme.spacing(1)}px !important`,
      '&:before': {
        backgroundColor: 'transparent'
      },
      [breakpoint]: {
        marginBottom: `0 !important`,
        '&& .MuiCollapse-container, && .MuiCollapse-wrapper, && .MuiCollapse-wrapperInner, && .MuiCollapse-wrapperInner > div': {
          display: 'flex',
          flex: 1,
          flexDirection: 'column'
        },
        '&& .MuiCollapse-container': {
          height: 'auto !important',
          overflow: 'visible !important',
          transition: 'none !important',
          visibility: 'visible !important'
        }
      }
    },
    showMore: {
      alignSelf: 'center',
      marginBottom: theme.spacing(1),
      [breakpoint]: {
        marginBottom: theme.spacing(2)
      }
    },
    summary: {
      padding: theme.spacing(0.75, 1),
      [breakpoint]: {
        cursor: 'default !important',
        minHeight: theme.spacing(3),
        '&.Mui-expanded': {
          minHeight: theme.spacing(3)
        }
      }
    },
    summaryContent: {
      margin: 0,
      '&.Mui-expanded': {
        margin: 0
      }
    },
    summaryExpandIcon: {
      [breakpoint]: {
        display: 'none'
      }
    },
    summaryHeader: {
      alignItems: 'baseline',
      display: 'flex',
      flex: 1,
      flexWrap: 'wrap',
      [breakpoint]: {
        display: 'block'
      },
      [theme.breakpoints.up('lg')]: {
        display: 'flex'
      }
    },
    noResultsWrapper: {
      margin: theme.spacing(1, 0)
    },
    clearSearchButton: {
      [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(1)
      }
    }
  };
});
