import { Button, IconButton, Typography } from '@material-ui/core';
import { ITableColumn, Table } from '@shared/components/tables';
import { downloadSharedFileV2, previewSharedFileV2 } from '@shared/fetch';
import { IAppState, IGraphEntry, IUserProps } from '@shared/types';
import { format } from 'date-fns';
import React, { FC, useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { Delete, Description, Folder as FolderIcon, OndemandVideo, PictureAsPdf } from '@material-ui/icons';
import { Toast } from '@shared/components/toast';
import LinkIcon from '@material-ui/icons/Link';
import { copyToClipboard } from '@shared/helpers';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { ISharedFile } from '@shared/types/sharedFiles';
import { PdfViewer } from '@shared/components/PdfViewer';
import ReactPlayer from 'react-player';
import { Modal } from '@shared/components/modals';
import { getFileExtension } from '@src/clients/containers/SharedFiles/utils';
import { userAccess } from '@shared/constants';
import { base64ToArrayBuffer } from '@shared/helpers/file';

interface IEntries {
  entries: IGraphEntry[] | null;
  entryPath: string[];
  setEntryPath: React.Dispatch<React.SetStateAction<string[]>>;
  setHasAppliedFilters: React.Dispatch<React.SetStateAction<boolean>>;
  hasAppliedFilters: boolean;
  selectedSort: string;
  setSelectedSort: React.Dispatch<React.SetStateAction<string>>;
  sortDirection: {
    Name?: 'Asc' | 'Desc';
    ModifiedDate?: 'Asc' | 'Desc';
  };
  setSortDirection: React.Dispatch<React.SetStateAction<{ Name?: 'Asc' | 'Desc'; ModifiedDate?: 'Asc' | 'Desc' }>>;
  handleDelete: (e: React.MouseEvent<HTMLButtonElement>, fileId: string) => void;
}

export enum EntryType {
  Folder = 'Folder',
  File = 'File'
}

const formatDate = (date: Date) => {
  return format(new Date(date), 'MMM, dd yyyy hh:mm a');
};

export const FileExplorer: FC<IEntries> = ({
  entries,
  entryPath,
  setEntryPath,
  setHasAppliedFilters,
  hasAppliedFilters,
  selectedSort,
  setSelectedSort,
  sortDirection,
  setSortDirection,
  handleDelete
}) => {
  const navigateForward = (entry: IGraphEntry) => {
    if (entry.path) {
      setEntryPath(entry.path.split('/').filter(Boolean));
      setHasAppliedFilters(false);
    } else {
      setEntryPath([...entryPath, entry.name]);
      setHasAppliedFilters(false);
    }
  };

  const handleClickColumn = (column: string) => {
    setSelectedSort(column);
    setSortDirection({
      ...sortDirection,
      [column]: sortDirection[column] === 'Asc' ? 'Desc' : 'Asc'
    });
  };

  const columns = [
    {
      Header: 'Name',
      accessor: 'name',
      isServerSorted: selectedSort === 'Name',
      isServerSortedDesc: sortDirection.Name === 'Desc',
      sort: !hasAppliedFilters,
      handleClickColumn: () => (!hasAppliedFilters ? handleClickColumn('Name') : null),
      Cell: ({
        cell: {
          row: { original }
        }
      }: {
        cell: { row: { original: IGraphEntry } };
      }) => {
        switch (original.type) {
          case EntryType.Folder:
            return <Folder name={original.name} navigateForward={() => navigateForward(original)} />;
          case EntryType.File:
            return <File name={original.name} itemId={original.id} file={original} handleDelete={handleDelete} />;
          default:
            return <Typography>{original.name}</Typography>;
        }
      }
    },
    {
      Header: 'Modified Date',
      accessor: 'modifiedDate',
      isServerSorted: selectedSort === 'ModifiedDate',
      isServerSortedDesc: sortDirection.ModifiedDate === 'Desc',
      sort: !hasAppliedFilters,
      handleClickColumn: () => (!hasAppliedFilters ? handleClickColumn('ModifiedDate') : null),
      Cell: ({
        cell: {
          row: { original }
        }
      }: {
        cell: { row: { original: IGraphEntry } };
      }) => {
        return <Typography>{formatDate(original.createdDateTime)}</Typography>;
      }
    }
  ];

  return (
    <div>
      <Table data={entries ?? []} columns={columns as ITableColumn[]} stickyHeader expandToFit hidePagination isRequests />
    </div>
  );
};

const Folder: FC<any> = ({ name, navigateForward }): React.ReactElement | null => {
  return (
    <>
      <Button startIcon={<FolderIcon />} variant='text' size='small' onClick={navigateForward}>
        {name}
      </Button>
    </>
  );
};

const File: FC<{
  name: string;
  itemId: string;
  file: IGraphEntry;
  handleDelete: (e: React.MouseEvent<HTMLButtonElement>, fileId: string) => void;
}> = ({ name, itemId, file, handleDelete }): React.ReactElement | null => {
  const { selectedClient } = useSelector((state: IAppState) => state.extranet);

  const user: IUserProps = useSelector((state: IAppState) => state.user);
  const isAdmin = user.userAccess[userAccess.VIEW_ADMIN];

  const [{ message: downloadToastMessage, variant: downloadToastVariant, isOpen: downloadToastIsOpen }, setDownloadToast] = useState<{
    message: string;
    variant: 'error' | 'success';
    isOpen: boolean;
  }>({
    message: '',
    variant: 'success',
    isOpen: false
  });

  const [{ isOpen: isVideoModalOpen, title: videoModalTitle, data: videoModalData }, setVideoModal] = useState<{
    title: string;
    data: {
      src: string;
    };
    isOpen: boolean;
  }>({
    title: '',
    data: {
      src: ''
    },
    isOpen: false
  });

  const closeVideoModal = useCallback(
    () =>
      setVideoModal({
        title: '',
        isOpen: false,
        data: {
          src: ''
        }
      }),
    []
  );

  const [{ isOpen: isPdfReaderModalOpen, title: pdfReaderModalTitle, data: pdfReaderModalData }, setPdfReaderModal] = useState<{
    title: string;
    data: {
      src: any;
    };
    isOpen: boolean;
  }>({
    title: '',
    data: {
      src: ''
    },
    isOpen: false
  });

  const closePdfReaderModal = useCallback(
    () =>
      setPdfReaderModal({
        title: '',
        isOpen: false,
        data: {
          src: ''
        }
      }),
    []
  );

  const openVideoModal = useCallback(({ fileName, fileShareUri }: ISharedFile) => {
    setVideoModal({
      title: fileName,
      isOpen: true,
      data: {
        src: fileShareUri
      }
    });
  }, []);

  const openPdfReader = useCallback(async ({ fileName, fileShareUri }: ISharedFile) => {
    if (!selectedClient) {
      return;
    }
    try {
      const fileBytes = await previewSharedFileV2(selectedClient.clientId, file.id);
      base64ToArrayBuffer(fileBytes);
      setPdfReaderModal({
        title: fileName,
        isOpen: true,
        data: {
          src: base64ToArrayBuffer(fileBytes)
        }
      });
    } catch (e) {
      console.error(e);
    }
  }, []);

  const selectExtraActions = (file: ISharedFile) => {
    switch (getFileExtension(file)) {
      case 'mp4':
        return (
          <IconButton aria-label='Play Video' onClick={() => openVideoModal(file)}>
            <OndemandVideo />
          </IconButton>
        );
      case 'pdf':
        return (
          <IconButton aria-label='Open PDF Reader' onClick={() => openPdfReader(file)}>
            <PictureAsPdf />
          </IconButton>
        );
      default:
        return null;
    }
  };

  const downloadFile = async (itemId: string, filename: string) => {
    if (!selectedClient) {
      return;
    }

    try {
      setDownloadToast({
        message: `Started download for ${filename}`,
        variant: 'success',
        isOpen: true
      });

      const sharepointUri = await downloadSharedFileV2(selectedClient.clientId, itemId);

      const link = document.createElement('a');
      link.href = sharepointUri.location;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      setDownloadToast({
        message: `Failed to download file ${filename}`,
        variant: 'error',
        isOpen: true
      });
      console.error(error);
    }
  };

  const currentUrl = window.location.href.split('?')[0];

  const handleDeepLink = (e: React.MouseEvent<HTMLButtonElement>, queryStringValue: string) => {
    e.stopPropagation();
    copyToClipboard(`${currentUrl}?fileId=${queryStringValue}`);
  };

  const classes = useStyles();

  return (
    <>
      <Button startIcon={<Description color='secondary' />} variant='text' size='small' onClick={() => downloadFile(itemId, name)}>
        {name}
      </Button>

      <IconButton
        aria-label='copy'
        className={classes.copyLinkButton}
        onClick={e => {
          handleDeepLink(e, itemId);
        }}
        justify-self='flex-end'
      >
        <LinkIcon />
      </IconButton>
      {selectExtraActions({
        fileName: name,
        fileShareUri: file.fileUri,
        sharedFileId: Number(file.id),
        dateTimeUploaded: `${file.lastModifiedDateTime}`,
        userUploaded: ''
      })}

      {isAdmin && (
        <IconButton
          aria-label='copy'
          onClick={e => {
            handleDelete(e, itemId);
          }}
          justify-self='flex-end'
          className={classes.deleteButton}
        >
          <Delete />
        </IconButton>
      )}
      <Toast
        id='file-download-toast'
        message={downloadToastMessage}
        open={downloadToastIsOpen}
        onClose={() =>
          setDownloadToast({
            message: '',
            variant: 'success',
            isOpen: false
          })
        }
        variant={downloadToastVariant}
      />
      <Modal
        maxWidth='xl'
        classes={{
          paper: classes.modalContent
        }}
        open={isVideoModalOpen}
        onClose={() => closeVideoModal()}
        title={videoModalTitle}
      >
        <ReactPlayer
          url={videoModalData.src}
          controls
          playing={true}
          width='100%'
          height='100%'
          onReady={e => {
            setTimeout(() => {
              const player = e.getInternalPlayer();
              player.setAttribute('muted', 'false');
            }, 200);
          }}
        />
      </Modal>
      <Modal
        maxWidth='xl'
        classes={{
          paper: classes.modalContent
        }}
        open={isPdfReaderModalOpen}
        onClose={() => closePdfReaderModal()}
        title={pdfReaderModalTitle}
      >
        {pdfReaderModalData.src && <PdfViewer url={pdfReaderModalData.src} />}
      </Modal>
    </>
  );
};

const useStyles = makeStyles<Theme>((theme: Theme) => ({
  copyLinkButton: {
    transition: 'background-color 0.3s',
    '&:active': {
      backgroundColor: 'rgba(0, 0, 0, 0.2)'
    }
  },
  deleteButton: {
    color: theme.palette.error.main,
    transition: theme.palette.error.main
  }
}));
