import React, { FC } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';
// components
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Search from '@material-ui/icons/Search';
import Clear from '@material-ui/icons/Clear';
import IconButton from '@material-ui/core/IconButton';

interface ISearchInputProps {
  className?: string;
  disabled?: boolean;
  id: string;
  isSearching: boolean;
  handleChange: (val: string) => void;
  handleClearSearch: () => void;
  handleSearch: () => void;
  value: string;
  placeholder?: string;
  customClearFieldHandler?: () => void;
}

export const SearchInput: FC<ISearchInputProps> = ({
  className,
  disabled,
  handleChange,
  handleClearSearch,
  handleSearch,
  id,
  isSearching,
  placeholder,
  value,
  customClearFieldHandler
}) => {
  const classes = searchInputStyles();
  return (
    <TextField
      className={clsx(classes.textfield, className || '')}
      id={`search-${id}`}
      inputProps={{ className: classes.input }}
      size='small'
      disabled={disabled || false}
      InputProps={{
        className: clsx(classes.inputWrapper),
        startAdornment: (
          <InputAdornment position='start'>
            <IconButton className={classes.iconButton} disabled={isSearching || value.length === 0} onClick={() => handleSearch()}>
              <Search />
            </IconButton>
          </InputAdornment>
        ),
        endAdornment: (customClearFieldHandler &&  value.length !== 0 &&
          <InputAdornment position='start'>
            <IconButton
              className={classes.iconButton}
              disabled={isSearching || value.length === 0}
              onClick={() => {
                handleChange('');
                customClearFieldHandler();
              }}
            >
              <Clear />
            </IconButton>
          </InputAdornment>
        )
      }}
      margin='none'
      onChange={e => {
        handleChange(e.target.value);
      }}
      onKeyDown={e => {
        // enter key
        if (e.keyCode === 13) {
          if (value.length > 0) {
            handleSearch();
          }
          if (value.length === 0) {
            handleClearSearch();
          }
        }
      }}
      placeholder={placeholder || 'Search...'}
      variant='outlined'
      type={customClearFieldHandler ? 'text' : 'search'}
      value={value}
    />
  );
};

const searchInputStyles = makeStyles<Theme>((theme: Theme) => {
  return createStyles({
    icon: {
      color: theme.palette.grey[700],
      fontSize: '20px',
      marginTop: '-1px'
    },
    iconButton: {
      padding: 0
    },
    input: {},
    inputWrapper: {},
    textfield: {
      alignItems: 'center',
      borderRadius: '10px',
      display: 'flex',
      margin: 0
    },
    textfieldWrapper: {
      width: '100%'
    }
  });
});
