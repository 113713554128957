type ColumnIds = 'id' | 'parentId' | 'workItemType' | 'title' | 'project' | 'sprint' | 'state' | 'effort' | 'acceptanceCriteria' | 'comments';

export interface IColumn {
  id: ColumnIds; // used for map key
  title: string; // desktop table th text
  type: 'string' | 'number'; // for excel export
}

export const columns: IColumn[] = [
  {
    id: 'id',
    title: 'ID',
    type: 'number'
  },
  {
    id: 'parentId',
    title: 'Parent ID',
    type: 'number'
  },
  {
    id: 'workItemType',
    title: 'Type',
    type: 'string'
  },
  {
    id: 'title',
    title: 'Title',
    type: 'string'
  },
  {
    id: 'project',
    title: 'Project',
    type: 'string'
  },
  {
    id: 'sprint',
    title: 'Sprint',
    type: 'string'
  },
  {
    id: 'state',
    title: 'State/Status',
    type: 'string'
  },
  {
    id: 'effort',
    title: 'Effort',
    type: 'string'
  },
  {
    id: 'acceptanceCriteria',
    title: 'Acceptance Criteria',
    type: 'string'
  },
  {
    id: 'comments',
    title: 'Comments',
    type: 'string'
  }
];
