import React, { FC, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useMedia } from 'react-use';
import { isEmpty, sortBy, isNull, get } from 'lodash';
import { TextField, Button } from '@material-ui/core';
import { Edit, Add } from '@material-ui/icons';
import Autocomplete from '@material-ui/lab/Autocomplete';
import clsx from 'clsx';
import format from 'date-fns/format';

import { EmployeeRolesForm } from '../components/forms';
import { Page } from '@shared/components/layout';
import { Table, TableToolbar, ITableColumn } from '@shared/components/tables';
import { MobileExpanderLoader } from '@shared/components/loader';
import { MobileEmployeeRoles } from '../components/mobile';
import { Alert } from '@shared/components/alerts';

import { getEmployeeRates, addRole, updateRole } from '@shared/fetch';
import { IBasicObject, IEmployeeRate } from '@shared/types';

export const EmployeeRoles: FC = () => {
  const classes = useStyles();
  const isDesktop = useMedia('(min-width: 960px)');
  const [roles, setRoles] = useState<IEmployeeRate[] | []>([]);
  const [rolesLoading, setRolesLoading] = useState<boolean>(false);
  const [activeFilter, setActiveFilter] = useState<IBasicObject>({ label: 'Active', value: 'active' });
  const [filteredRoles, setfilteredRoles] = useState<IEmployeeRate[]>([]);
  const [error, setError] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);

  const [editing, setEditing] = useState<IEmployeeRate | {} | null>(null);

  const columns = [
    {
      Header: 'Role',
      accessor: 'title'
    },
    {
      Header: 'Status',
      accessor: ({ isActive }: any) => {
        return isActive ? 'Active' : 'Inactive';
      },
      canFilter: true
    },
    {
      Header: 'Date Created',
      accessor: 'dateCreated',
      sort: false,
      Cell: ({
        cell: {
          row: { original }
        }
      }: {
        cell: { row: { original: IEmployeeRate } };
      }) => {
        if (original.dateCreated) {
          return format(new Date(original.dateCreated), 'MM/dd/yy');
        } else {
          return '';
        }
      }
    },
    {
      Header: 'Date Updated',
      accessor: 'dateUpdated',
      sort: false,
      Cell: ({
        cell: {
          row: { original }
        }
      }: {
        cell: { row: { original: IEmployeeRate } };
      }) => {
        if (original.dateUpdated) {
          return format(new Date(original.dateUpdated), 'MM/dd/yy');
        } else {
          return '';
        }
      }
    },
    {
      Header: ' ',
      id: 'actions',
      sort: false,
      hideLoad: true,
      className: classes.actionButton,
      Cell: ({
        cell: {
          row: { original }
        }
      }: any) => {
        return (
          <Button color='primary' startIcon={<Edit />} onClick={() => setEditing(original)}>
            Edit
          </Button>
        );
      }
    }
  ];

  const selectOptions = [
    { label: 'Active', value: 'active' },
    { label: 'Inactive', value: 'inactive' }
  ];

  const onFormSave = async (values: IEmployeeRate, saveCallback: (error?: Error) => void) => {
    try {
      let updatedRoles: IEmployeeRate[];
      // New roles don't have an id until they're created, so we use -1 as a temp id/flag for a new role
      if (values.employeeRateId === -1) {
        await addRole(values);
        const response = await getEmployeeRates();
        updatedRoles = [...response];
      } else {
        await updateRole(values);
        const response = await getEmployeeRates();
        updatedRoles = [...response];
      }
      setRoles(updatedRoles);
      setEditing(null);
    } catch (error) {
      saveCallback(get(error, 'response.data.Detail'));
      setError(true);
    }
  };

  const loadRoles = async () => {
    try {
      setRolesLoading(true);
      const [rolesResponse] = await Promise.all([getEmployeeRates()]);
      setRoles(rolesResponse);
      setRolesLoading(false);
    } catch (error) {
      console.log(error);
      setRolesLoading(false);
    }
  };

  useEffect(() => {
    loadRoles();
  }, []);

  useEffect(() => {
    if (!isEmpty(roles)) {
      let employeeArray: IEmployeeRate[] = [];
      if (isNull(activeFilter)) {
        employeeArray = roles;
      } else {
        if (activeFilter.value === 'active') {
          employeeArray = roles.filter(employee => employee.isActive);
        }

        if (activeFilter.value === 'inactive') {
          employeeArray = roles.filter(employee => !employee.isActive);
        }
      }
      setfilteredRoles(sortBy(employeeArray, 'title'));
    }
  }, [activeFilter, roles]);

  return (
    <Page
      title='Role Admin'
      setHeight={false}
      flexGrow={false}
      hideHeader={true}
      hideTitle={!!editing}
      footerSpacing={100}
      actions={() =>
        isNull(editing) && (
          <Button color='primary' onClick={() => setEditing({})} aria-label='add-employee' startIcon={<Add />}>
            Add Role
          </Button>
        )
      }
    >
      {isNull(editing) && (
        <>
          <TableToolbar>
            <Autocomplete
              id='status-autocomplete'
              className={classes.activeSelect}
              options={selectOptions.map(option => option)}
              getOptionLabel={option => option.label}
              getOptionSelected={(option, value) => option.label === value.label}
              value={activeFilter}
              disabled={rolesLoading}
              onChange={(e: any, value: any) => setActiveFilter(value)}
              renderInput={params => <TextField {...params} label='Status' variant='outlined' size='small' />}
            />
          </TableToolbar>
        </>
      )}

      <Table
        data={sortBy(filteredRoles, 'firstName')}
        columns={columns as ITableColumn[]}
        expandToFit
        stickyHeader
        isLoading={rolesLoading}
        ResponsiveComponent={MobileEmployeeRoles}
        useTableProps={{ setEditing }}
        ResponsiveComponentLoader={MobileExpanderLoader}
        containerClasses={clsx(isDesktop ? classes.desktopTable : classes.mobileTable, !isNull(editing) ? classes.editing : '')}
      />

      {!isNull(editing) && <EmployeeRolesForm initialValues={editing} onSave={onFormSave} onCancel={() => setEditing(null)} rolesList={roles} />}

      <Alert
        open={error}
        onClose={() => setError(false)}
        type='error'
        text={`An error was encountered when trying to ${editing ? 'update' : 'save'} this role. Please try again.`}
      />
      <Alert open={success} onClose={() => setSuccess(false)} type='success' text='Save Success!' />
    </Page>
  );
};

const useStyles = makeStyles(theme => ({
  activeSelect: {
    minWidth: '200px',
    marginBottom: theme.spacing(1)
  },
  mobileTable: {
    padding: 0
  },
  desktopTable: {
    paddingLeft: 5,
    paddingRight: 5
  },
  actionButton: {
    padding: 0
  },
  editing: {
    display: 'none'
  },
  rightAlign: {
    textAlign: 'right'
  }
}));
