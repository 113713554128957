import { FormHelperText, Typography } from '@material-ui/core';
import clsx from 'clsx';
import React, { FC } from 'react';
import { DropzoneState } from 'react-dropzone';
import { makeStyles, Theme } from '@material-ui/core/styles';

interface IFileDropProps extends DropzoneState {
  error?: Error | null;
  id: string;
  title: string;
  className?: string;
}

export const FileDrop: FC<IFileDropProps> = ({ children, error, id, title, className, ...props }) => {
  const classes = useStyles();

  return (
    <div className={clsx(classes.fileDrop, className ? className : '')}>
      <div
        className={clsx(
          classes.dropzone,
          !!error ? classes.draggedFiles : props.isDragActive ? classes.isDragActive : props.isFocused ? classes.isFocused : ''
        )}
        {...props.getRootProps()}
      >
        <input id={id} {...props.getInputProps()} />
        <Typography className={classes.title} variant='h6'>
          {title}
        </Typography>
        <div className={classes.instructions}>{children}</div>
        {props.isDragActive && (
          <div className={clsx(classes.active)}>
            <Typography variant='body1'>Drop your file</Typography>
          </div>
        )}
      </div>
      {!!error && <FormHelperText error={!!error}>{!!error?.message ? error?.message : <>Invalid file. Please try again.</>}</FormHelperText>}
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  active: {
    alignItems: 'center',
    backgroundColor: theme.palette.common.white,
    bottom: 0,
    color: theme.palette.primary.main,
    display: 'flex',
    fontSize: '1.125rem',
    fontWeight: 700,
    justifyContent: 'center',
    left: 0,
    position: 'absolute',
    right: 0,
    textAlign: 'center',
    textTransform: 'uppercase',
    top: 0,
    width: '100%',
    zIndex: 1
  },
  fileDrop: {
    margin: theme.spacing(2, 0),
    width: '100%'
  },
  dropzone: {
    border: `2px dashed ${theme.palette.grey[300]}`,
    borderRadius: theme.shape.borderRadius,
    boxShadow: '0 2px 3px rgba(0,0,0,0.06)',
    padding: theme.spacing(3),
    position: 'relative',
    width: '100%'
  },
  isDragActive: {
    border: `2px solid ${theme.palette.primary.main}`
  },
  isFocused: {
    border: `2px dashed ${theme.palette.primary.main}`,
    outline: 0
  },
  instructions: {
    color: theme.palette.grey[500],
    fontSize: '0.875rem',
    textAlign: 'center',
    '& > *': {
      margin: theme.spacing(1, 0)
    },
    '& > p': {
      fontSize: '0.875rem'
    }
  },
  draggedFiles: {
    border: `2px solid ${theme.palette.error.main}`
  },
  title: {
    color: theme.palette.grey[600],
    fontSize: '1rem',
    fontWeight: 700,
    marginBottom: theme.spacing(1),
    textAlign: 'center'
  }
}));
