import React, { FC } from 'react';
// components
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert, { Color } from '@material-ui/lab/Alert';

function AlertComponent(props: any) {
  return <MuiAlert elevation={6} variant='filled' {...props} />;
}

interface IAlertProps {
  open: boolean;
  duration?: number;
  onClose: (close: boolean) => void;
  type: Color;
  text: string;
}

const handleClose = (event: any, reason: string, handleClose: (close: boolean) => void) => {
  if (reason === 'clickaway') {
    return;
  }
  handleClose(false);
};

export const Alert: FC<IAlertProps> = ({ open, duration = 6000, onClose, type, text }) => {
  return (
    <Snackbar open={open} autoHideDuration={duration} onClose={(e, reason) => handleClose(e, reason, onClose)}>
      <AlertComponent onClose={(e: any) => handleClose(e, '', onClose)} severity={type}>
        {text}
      </AlertComponent>
    </Snackbar>
  );
};
