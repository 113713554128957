import React, { FC } from 'react';
import { Close, FilterList } from '@material-ui/icons';
import { Button } from '@material-ui/core';
import clsx from 'clsx';
import { makeStyles, Theme } from '@material-ui/core/styles';

interface IFiltersButtons {
  hasAppliedFilters?: boolean;
  isDisabled?: boolean;
  handleApplyFilters: () => void;
  handleResetFilters: () => void;
  applyFiltersText?: string;
  applyFiltersIcon?: JSX.Element;
  wrapperClass?: string;
}

export const FiltersButtons: FC<IFiltersButtons> = ({
  isDisabled,
  hasAppliedFilters,
  handleApplyFilters,
  handleResetFilters,
  applyFiltersText,
  applyFiltersIcon,
  wrapperClass
}) => {
  const classes = useStyles();
  return (
    <div className={clsx(wrapperClass, classes.buttonContainer)}>
      <Button
        color='primary'
        variant='contained'
        className={classes.button}
        disabled={isDisabled}
        startIcon={applyFiltersIcon ?? <FilterList />}
        onClick={handleApplyFilters}
      >
        {applyFiltersText ?? 'Apply Filters'}
      </Button>
      {hasAppliedFilters && (
        <Button
          color='inherit'
          className={clsx(classes.button, classes.resetButton)}
          variant='contained'
          disabled={isDisabled}
          startIcon={<Close />}
          onClick={handleResetFilters}
        >
          Reset
        </Button>
      )}
    </div>
  );
};

const useStyles = makeStyles<Theme>((theme: Theme) => ({
  button: {
    whiteSpace: 'nowrap',
    marginTop: -12,
    marginBottom: 2,
    height: 35,
    textTransform: 'capitalize',
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: 'auto'
    },
    '@media (max-width: 1441px)': {
      marginTop: -12
    },
    '@media (max-width: 960px)': {
      marginBottom: 10
    }
  },
  resetButton: {
    '@media (min-width: 400px)': {
      marginLeft: 11
    },
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1)
    }
  },
  buttonContainer: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'nowrap'
  }
}));
