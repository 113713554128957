import React, { FC, useState, useEffect, useMemo } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { useMedia } from 'react-use';
// Components
import { Table, ITableColumn } from '@shared/components/tables';
import { Modal } from '@shared/components/modals';
import { Typography, Button } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { LoaderOverlay, Loader } from '@shared/components/loader';
import { HistoryActionsCell, HistoryRateCell, HistoryEffectiveDateCell } from '../controls';
// Fetch
import { getLaborRateHistory, updateLaborRateHistory } from '@shared/fetch';
// Types
import { ILaborRate, ILaborRateHistory, IRateHistory } from '@shared/types';
// Enums
import { LaborRateTypes } from '../../containers';

interface ILaborRateHistoryModal {
  open: boolean;
  onClose: (refresh?: boolean) => void;
  laborRate?: ILaborRate;
  currentLaborRateType?: LaborRateTypes;
}

export const LaborRateHistoryModal: FC<ILaborRateHistoryModal> = ({ onClose, open, laborRate, currentLaborRateType }) => {
  const classes = useStyles();
  const isDesktop = useMedia('(min-width: 960px)');

  // state
  const [laborRateHistory, setLaborRateHistory] = useState<ILaborRateHistory | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [apiError, setApiError] = useState<boolean>(false);

  const loadHistory = async () => {
    // reset values in case
    setEditing(-1);
    setApiError(false);
    setTempRate(null);

    // load history if we are open and have what we need
    if (open && laborRate && laborRate.employeeRateId) {
      try {
        setIsLoading(true);
        const laborRateHistoryResponse = await getLaborRateHistory({
          EmployeeRateId: laborRate.employeeRateId,
          ...(currentLaborRateType === LaborRateTypes.CLIENT || currentLaborRateType === LaborRateTypes.CLIENTPROJECT
            ? { ClientId: laborRate.clientId }
            : {}),
          ...(currentLaborRateType === LaborRateTypes.CLIENTPROJECT ? { ClientProjectId: laborRate.clientProjectId } : {}),
          ...(laborRate.employeeId ? { EmployeeId: laborRate.employeeId } : {})
        });
        setLaborRateHistory(laborRateHistoryResponse);
        setIsLoading(false);
      } catch (error) {
        setApiError(true);
        setIsLoading(false);
      }
    }
  };
  useEffect(() => {
    loadHistory();
  }, [open]);

  // Editing and Deleting
  const [editing, setEditing] = useState<number>(-1);
  const [tempRate, setTempRate] = useState<IRateHistory | null>(null);
  const [hasChanged, setHasChanged] = useState<boolean>(false);
  const [isNewDateValid, setIsNewDateValid] = useState<boolean>(true);
  const setDateValid = (isValid: boolean) => setIsNewDateValid(isValid);
  const handleEdit = (index: number) => {
    setEditing(index);
    setTempRate(null);
  };
  const handleAcceptEdit = () => {
    if (laborRateHistory && tempRate && editing !== -1) {
      const newLaborRateHistory = { ...laborRateHistory };
      newLaborRateHistory.laborRateHistory[editing] = tempRate;
      setLaborRateHistory(newLaborRateHistory);
      setHasChanged(true);
    }
    setTempRate(null);
    setEditing(-1);
  };
  const handleDelete = (index: number) => {
    if (laborRateHistory) {
      const newLaborRateHistory = { ...laborRateHistory };
      newLaborRateHistory.laborRateHistory[index].isDeleted = true;
      setLaborRateHistory(newLaborRateHistory);
      setHasChanged(true);
    }
  };
  const updateMyData = (index: number, newData: { rate?: number; effectiveDate?: string | null }) => {
    if (laborRateHistory) {
      setTempRate({ ...laborRateHistory.laborRateHistory[index], ...(tempRate === null ? {} : tempRate), ...newData });
    }
  };

  // Table Columns
  const columns = useMemo(() => {
    return [
      {
        Header: 'Effective Date',
        accessor: 'effectiveDate',
        Cell: HistoryEffectiveDateCell,
        sort: false
      },
      { Header: 'Rate', accessor: 'rate', Cell: HistoryRateCell, sort: false, isRate: true },
      ...(isDesktop ? [{ Header: '', id: 'actions', Cell: HistoryActionsCell, sort: false, overrideWidth: 100 }] : [])
    ];
  }, [laborRateHistory, editing]);

  const useTableProps = {
    handleEdit,
    handleDelete,
    handleAcceptEdit,
    editing,
    updateMyData,
    isNewDateValid,
    setDateValid
  };

  const [saveError, setSaveError] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const handleSave = async () => {
    if (laborRateHistory) {
      try {
        setSaveError(false);
        setIsSubmitting(true);
        await updateLaborRateHistory(laborRateHistory);
        setIsSubmitting(false);
        setHasChanged(false);
        onClose(true);
      } catch (error) {
        setIsSubmitting(false);
        setSaveError(true);
      }
    }
  };

  return (
    <Modal
      title='Rate History'
      onClose={() => {
        setHasChanged(false);
        setSaveError(false);
        onClose();
      }}
      open={open}
      fullWidth={false}
      actions={
        <>
          <Button color='primary' disabled={!hasChanged} variant='contained' onClick={handleSave}>
            Save
          </Button>
          <Button
            color='secondary'
            variant='contained'
            onClick={() => {
              setHasChanged(false);
              setSaveError(false);
              onClose();
            }}
          >
            Cancel
          </Button>
        </>
      }
    >
      {isLoading && <Loader position='centered' />}
      {!isLoading && !apiError && laborRateHistory && (
        <>
          <div className={classes.historyInfo}>
            <div>
              <Typography variant='h4' color='primary'>
                {laborRateHistory.roleName}
              </Typography>
              {currentLaborRateType === LaborRateTypes.CLIENT && (
                <Typography variant='body1'>
                  <strong>Client:</strong> {laborRateHistory.clientName}
                </Typography>
              )}
              {currentLaborRateType === LaborRateTypes.CLIENTPROJECT && (
                <Typography variant='body1'>
                  <strong>Project:</strong> {laborRateHistory.clientProjectName}
                </Typography>
              )}
            </div>
            {currentLaborRateType !== LaborRateTypes.STANDARD && (
              <div>
                <Typography variant='body1'>
                  <strong>Standard Rate:</strong> ${laborRateHistory.standardRate}
                </Typography>
                {currentLaborRateType === LaborRateTypes.CLIENTPROJECT && (
                  <Typography variant='body1'>
                    <strong>Client Rate:</strong> ${laborRateHistory.clientRate}
                  </Typography>
                )}
              </div>
            )}
          </div>
          <Table
            key='labor-rates-history-table'
            data={
              laborRateHistory && Array.isArray(laborRateHistory.laborRateHistory) ? laborRateHistory.laborRateHistory.filter(x => !x.isDeleted) : []
            }
            columns={columns as ITableColumn[]}
            stickyHeader
            isLoading={isLoading}
            useTableProps={useTableProps}
          />
        </>
      )}
      {!isLoading && apiError && <Alert severity='error'>Error loading rate history! Close and try again.</Alert>}
      {!isSubmitting && saveError && <Alert severity='error'>Error saving rate history! Please try again.</Alert>}
      <LoaderOverlay open={isSubmitting} />
    </Modal>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  historyInfo: {
    display: 'flex',
    '& > div': {
      padding: `${theme.spacing(0.5)}px ${theme.spacing(1)}px`,
      '& > p:not(:last-of-type)': {
        marginBottom: theme.spacing(0.5)
      },
      '& > h4': {
        marginBottom: theme.spacing(0.5),
        fontSize: theme.spacing(1)
      }
    },
    '& > div:first-of-type': {
      paddingLeft: 0
    },
    '& > div:not(:first-of-type)': {
      borderLeft: `1px solid ${theme.palette.common.black}`
    }
  }
}));
