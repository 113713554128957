import React, { FC } from 'react';
import { makeStyles, Theme, Typography } from '@material-ui/core';
import { TypographyProps } from '@material-ui/core/Typography';
import clsx from 'clsx';

interface IH4Props extends TypographyProps {}

export const H4: FC<IH4Props> = ({ children, ...props }) => {
  const classes = h4Styles();
  return (
    <Typography {...props} className={clsx(classes.h4, props.className)} variant='h4'>
      {children}
    </Typography>
  );
};

const h4Styles = makeStyles((theme: Theme) => ({
  h4: {
    fontSize: 20,
    fontWeight: 700,
    margin: theme.spacing(6, 0, 3)
  }
}));
