import axios from 'axios';
import { IVersion } from '../types';

let buildConfig = require('../../buildSettings');
const axiosInstance = axios.create({
  baseURL: `${buildConfig.REACT_APP_API_URL}/`
});

export const getVersion = async (): Promise<IVersion> => {
  try {
    const { data } = await axiosInstance.get('/version');
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
};
