import React, { FC, useState, useEffect } from 'react';
import { Grid, Checkbox, ButtonGroup, IconButton, Theme, makeStyles } from '@material-ui/core';
import { theme } from '@shared/helpers';
import { DndContext, useSensor, useSensors, MouseSensor, TouchSensor, KeyboardSensor, DragOverlay, closestCenter } from '@dnd-kit/core';
import { SortableContext, arrayMove, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { Save, Close } from '@material-ui/icons';
// Components
import { Wysiwyg, WysiwygRender } from '@shared/components/wysiwyg';
import { OptionListItem } from '@shared/components/draggable-table/option-List-Item';
import { StaticOptionListItem } from '@shared/components/draggable-table/static-option-list-item';
import { ListItemColors } from '@shared/components/draggable-table/util';

// useIsComplete is a boolean that determines whether the checkbox should be checked based on the isComplete property of the item
// status is a string that determines whether the checkbox should be checked based on the status property of the item they work similarly

interface IDragAndDropCheckbox {
  items: {
    id: number;
    name: string;
    order: number;
    status: string;
    isComplete?: boolean;
  }[];
  onItemsReorder?: (
    newItems: {
      id: number;
      name: string;
      order: number;
      isComplete?: boolean;
    }[]
  ) => void;
  onUpdate: (payload: { name: string }, id: number | string) => Promise<void>;
  onCheckboxChange: (id: number, checked: boolean) => void;
  useIsComplete?: boolean;
  editingId: string | null;
  setEditingId: (id: string | null) => void;
}

export const DragAndDropCheckbox: FC<IDragAndDropCheckbox> = ({
  items,
  onItemsReorder,
  onCheckboxChange,
  onUpdate,
  useIsComplete = false,
  editingId,
  setEditingId
}) => {
  const classes = useStyles();

  const [orderedItems, setOrderedItems] = useState(items);
  const [activeId, setActiveId] = useState<string | null>(null);
  const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor), useSensor(KeyboardSensor));

  useEffect(() => {
    setOrderedItems(items);
  }, [items]);

  const handleDragStart = (event: any) => {
    setActiveId(event.active.id);
  };

  const handleDragEnd = (event: any) => {
    const { active, over } = event;
    if (over && active.id !== over.id) {
      const oldIndex = orderedItems.findIndex(item => item.id.toString() === active.id);
      const newIndex = orderedItems.findIndex(item => item.id.toString() === over.id);
      if (oldIndex !== -1 && newIndex !== -1) {
        const newItems = arrayMove(orderedItems, oldIndex, newIndex);
        setOrderedItems(newItems);
        if (onItemsReorder) {
          onItemsReorder(newItems); // Calls parent handler to update backend
        }
      }
    }
    setActiveId(null);
  };

  const handleCheckboxChange = (id: number) => {
    const currentItem = orderedItems.find(item => item.id === id);
    const isChecked = useIsComplete ? currentItem?.isComplete : currentItem?.status === 'Done';
    onCheckboxChange(id, !isChecked);
  };

  const handleSave = async (id: number, newName: string) => {
    setEditingId(null);
    await onUpdate({ name: newName }, id);
  };

  const handleCancel = () => {
    setEditingId(null);
  };

  return (
    <DndContext
      sensors={sensors}
      onDragEnd={handleDragEnd}
      onDragStart={handleDragStart}
      onDragCancel={() => setActiveId(null)}
      collisionDetection={closestCenter}
      modifiers={[restrictToVerticalAxis]}
    >
      <SortableContext items={orderedItems.map(item => item.id.toString())} strategy={verticalListSortingStrategy}>
        {orderedItems.map(item => (
          <>
            <OptionListItem item={item} id={item.id.toString()} color={ListItemColors.Primary} key={item.id.toString()}>
              <Grid container alignItems='center' justify='space-between' spacing={1}>
                <Grid item>
                  <Checkbox
                    className={classes.checkbox}
                    checked={useIsComplete ? item.isComplete === true : item.status === 'Done'}
                    onChange={() => handleCheckboxChange(item.id)}
                  />
                </Grid>
                <Grid item xs>
                  <WysiwygRender
                    html={item.name}
                    plainHtml={true}
                    style={{
                      textDecoration: (useIsComplete && item.isComplete) || (!useIsComplete && item.status === 'Done') ? 'line-through' : 'none',
                      display: 'block',
                      alignItems: 'center',
                      width: '100%'
                    }}
                    htmrOptions={{
                      transform: {},
                      preserveAttributes: [],
                      dangerouslySetChildren: ['style']
                    }}
                    marginLeft='0'
                  />
                </Grid>
                <Grid item xs={2} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  {editingId === item.id.toString() && (
                    <ButtonGroup>
                      <IconButton size='small' color='primary' aria-label='save' onClick={() => handleSave(item.id, item.name)}>
                        <Save className={classes.icon} />
                      </IconButton>
                      <IconButton size='small' color='default' aria-label='cancel' onClick={handleCancel}>
                        <Close className={classes.icon} />
                      </IconButton>
                    </ButtonGroup>
                  )}
                </Grid>
              </Grid>
            </OptionListItem>
            {editingId === item.id.toString() && (
              <Wysiwyg
                id={`wysiwyg-editor-${item.id}`}
                plainWywiwyg={true}
                className={classes.wysiwyg}
                onChange={value => {
                  const updatedItems = orderedItems.map(i => (i.id === item.id ? { ...i, name: value } : i));
                  setOrderedItems(updatedItems);
                }}
                value={item.name}
              />
            )}
          </>
        ))}
      </SortableContext>
      <DragOverlay>
        {activeId && (
          <StaticOptionListItem
            item={orderedItems.find(v => v.id.toString() === activeId)}
            id={activeId}
            color={ListItemColors.Primary}
            customBorder='2px solid #d7dce5'
          >
            <Grid item>
              <Checkbox
                checked={
                  useIsComplete
                    ? orderedItems.find(item => item.id.toString() === activeId)?.isComplete === true
                    : orderedItems.find(item => item.id.toString() === activeId)?.status === 'Done'
                }
              />
            </Grid>
            <Grid item xs={9}>
              <WysiwygRender
                html={orderedItems.find(v => v.id.toString() === activeId)?.name || ''}
                plainHtml={true}
                style={{
                  textDecoration:
                    (useIsComplete && orderedItems.find(v => v.id.toString() === activeId)?.isComplete) ||
                    (!useIsComplete && orderedItems.find(v => v.id.toString() === activeId)?.status === 'Done')
                      ? 'line-through'
                      : 'none',
                  display: 'block',
                  alignItems: 'center',
                  width: '100%'
                }}
                htmrOptions={{
                  transform: {},
                  preserveAttributes: [],
                  dangerouslySetChildren: ['style']
                }}
                marginLeft='0'
              />
            </Grid>
          </StaticOptionListItem>
        )}
      </DragOverlay>
    </DndContext>
  );
};

const useStyles = makeStyles<Theme>(() => ({
  wysiwyg: {
    backgroundColor: theme.palette.background.paper,
    '& .ql-toolbar': {
      borderRadius: '15px 15px 0 0',
      backgroundColor: theme.palette.grey[200]
    },
    '& .ql-container': {
      height: '100% !important',
      borderRadius: '0 0 15px 15px'
    },
    '&& .ql-editor': {
      minHeight: 200
    }
  },
  icon: {
    fontSize: '1.125rem'
  },
  checkbox: {
    transform: 'scale(0.85)'
  }
}));
