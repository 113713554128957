import React, { FC, useState, useEffect, MouseEvent } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { useMedia } from 'react-use';
import { useHistory, useLocation } from 'react-router-dom';
// Components
import AppBar from '@material-ui/core/AppBar';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Popover from '@material-ui/core/Popover';
import IconButton from '@material-ui/core/IconButton';
import { UserMenu } from '@shared/components/menus';
import { MobileNav } from '@shared/components/navigation';
import { ClientSwitcher } from '@shared/components/switcher';
import { ContactSupport, Close, Menu, ExpandLess, ExpandMore, AccountCircle, ArrowBack } from '@material-ui/icons';
// types
import { IUserProps, IAppState } from '@shared/types';
import { userAccess } from '@shared/constants';
import { Logo } from './Logo';

const CloseIcon: FC = () => (
  <Grid container direction='column' justify='center' alignItems='center'>
    <Close />
    <Typography variant='caption'>Close</Typography>
  </Grid>
);

interface IHeaderProps {
  showUserMenu?: boolean;
}

export const Header: FC<IHeaderProps> = ({ showUserMenu = true }) => {
  const classes = useStyles();

  const router = useHistory();
  const { pathname } = useLocation();
  const isRequestsPage = pathname === '/clients/requests';

  const user: IUserProps = useSelector((state: IAppState) => state.user);
  const [clientMenuOpen, setClientMenuOpen] = useState<boolean>(false);
  // userMenu
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const userMenuOpen = Boolean(menuAnchorEl);
  const handleMenuClick = (event: MouseEvent<HTMLButtonElement>) => {
    setMenuAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  };

  // mobileNav
  const [navAnchorEl, setNavAnchorEl] = useState<null | HTMLElement>(null);
  const mobileNavOpen = Boolean(navAnchorEl);
  const handleNavClick = (event: MouseEvent<HTMLButtonElement>) => {
    setNavAnchorEl(event.currentTarget);
  };
  const handleNavClose = () => {
    setNavAnchorEl(null);
  };

  const isDesktop = useMedia(isRequestsPage ? '(min-width: 1100px)' : '(min-width: 960px)');
  const isMobile = useMedia('(max-width: 500px)');

  let homeLink: string = '';

  const setHomeLink = () => {
    if (user.userAccess[userAccess.CLIENT_ONLY]) {
      homeLink = '/clients/dashboard';
    } else {
      homeLink = '/';
    }
  };

  useEffect(() => {
    setHomeLink();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const showClientSwitcher: boolean =
    router.location.pathname.includes('clients') && !router.location.pathname.includes('admin') && !router.location.pathname.includes('/reports');

  return (
    <AppBar
      className={clsx(classes.root, {
        [classes.rootActiveSecondary]: userMenuOpen,
        [classes.rootActivePrimary]: mobileNavOpen,
        [classes.rootActiveClient]: clientMenuOpen
      })}
      color={'default'}
      elevation={0}
    >
      <Grid className={classes.appBarGrid} container direction='row' justify='space-between' alignItems='center'>
        <Link to={homeLink} className='home'>
          <Logo inverted={userMenuOpen || mobileNavOpen || clientMenuOpen} />
        </Link>
        {!showUserMenu && (
          <div className={classes.supportWrapper}>
            <Button
              disableRipple
              className={clsx(classes.menuButtonBack)}
              variant='contained'
              size='large'
              color='primary'
              component='a'
              target='_blank'
              href='https://www.mercuryworks.com/'
              title='Back to Site'
              startIcon={isMobile ? undefined : <ArrowBack fontSize='large' />}
            >
              {isMobile ? <ArrowBack fontSize='large' /> : <Typography className={classes.linkText}>Back to Site</Typography>}
            </Button>
            <Button
              disableRipple
              className={clsx(classes.menuButtonSupport)}
              variant='contained'
              size='large'
              color='primary'
              component='a'
              target='_blank'
              href='https://support.mercuryworks.com/hc'
              title='Support'
              startIcon={isMobile ? undefined : <ContactSupport fontSize='large' />}
            >
              {isMobile ? <ContactSupport fontSize='large' /> : <Typography className={classes.linkText}>Support</Typography>}
            </Button>
          </div>
        )}
        {showUserMenu && (
          <>
            {isDesktop ? (
              <Grid container alignItems='center' className={classes.buttonWrapper}>
                {showClientSwitcher && <ClientSwitcher handleOpen={(val: boolean) => setClientMenuOpen(val)} />}
                <Button
                  disableRipple
                  className={clsx(classes.menuButton, { active: userMenuOpen })}
                  variant={'text'}
                  size='large'
                  color='secondary'
                  onClick={handleMenuClick}
                  startIcon={<AccountCircle fontSize='large' />}
                  endIcon={userMenuOpen ? <ExpandLess /> : <ExpandMore />}
                >
                  <Typography className={classes.userName}>{user.name}</Typography>
                </Button>
              </Grid>
            ) : (
              <Grid item className={classes.iconButtons}>
                <Button
                  className={clsx(classes.menuButton, classes.mobileUserMenuButton, { active: userMenuOpen })}
                  color='secondary'
                  onClick={handleMenuClick}
                >
                  <AccountCircle />
                  {userMenuOpen ? <ExpandLess /> : <ExpandMore />}
                </Button>
                <IconButton
                  className={clsx(classes.menuButton, classes.mobileNavButton, { active: mobileNavOpen })}
                  color='primary'
                  onClick={handleNavClick}
                >
                  {mobileNavOpen ? (
                    <CloseIcon />
                  ) : (
                    <Grid container direction='column' justify='center' alignItems='center'>
                      <Menu />
                      <Typography variant='caption'>Menu</Typography>
                    </Grid>
                  )}
                </IconButton>
              </Grid>
            )}
            <Popover
              anchorEl={menuAnchorEl}
              elevation={1}
              open={userMenuOpen}
              onClose={handleMenuClose}
              classes={{
                root: classes.menuRoot
              }}
              PaperProps={{ className: classes.userMenu }}
              anchorPosition={{
                left: 0,
                top: 0
              }}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right'
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right'
              }}
            >
              <UserMenu isMobile={!isDesktop} />
            </Popover>
            {!isDesktop && (
              <Popover
                PaperProps={{ className: clsx(classes.menu, classes.navMenu) }}
                anchorEl={navAnchorEl}
                elevation={0}
                open={mobileNavOpen}
                onClose={handleNavClose}
                style={{
                  zIndex: 999999
                }}
                anchorPosition={{
                  left: 0,
                  top: 0
                }}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right'
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right'
                }}
              >
                <MobileNav onNav={handleNavClose} />
              </Popover>
            )}
          </>
        )}
      </Grid>
    </AppBar>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: '#0773BB',
    height: 79,
    boxShadow: '0px 2px 7.2px 0px rgba(0, 0, 0, 0.1)'
  },
  rootActiveSecondary: {
    backgroundColor: '#0773BB'
  },
  rootActivePrimary: {
    backgroundColor: '#0773BB'
  },
  rootActiveClient: {
    backgroundColor: '#0773BB'
  },
  appBarGrid: {
    height: '100%',
    minHeight: 70,
    paddingLeft: 17.5
  },
  buttonWrapper: {
    width: 'auto',
    height: '100%'
  },
  menuButtonBack: {
    '@media (max-width: 500px)': {
      padding: '6px 6px'
    },
    height: '100%',
    borderRadius: 0,
    textTransform: 'none',
    boxShadow: 'none',
    padding: '6px 22px',
    '&:hover': {
      backgroundColor: theme.palette.background.paper,
      color: theme.palette.secondary.main,
      boxShadow: 'none'
    },
    '&.active': {
      backgroundColor: theme.palette.background.paper,
      color: theme.palette.secondary.main
    }
  },
  menuButtonSupport: {
    '@media (max-width: 500px)': {
      padding: '8px 14px'
    },
    height: '100%',
    borderRadius: 0,
    textTransform: 'none',
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: theme.palette.background.paper,
      color: theme.palette.secondary.main,
      boxShadow: 'none'
    },
    '&.active': {
      backgroundColor: theme.palette.background.paper,
      color: theme.palette.secondary.main
    }
  },
  menuButton: {
    height: '100%',
    borderRadius: 0,
    textTransform: 'none',
    boxShadow: 'none',
    color: theme.palette.common.white,
    transition: '0.3s ease-in-out',
    '&:hover': {
      color: theme.palette.secondary.main,
      boxShadow: 'none'
    },
    '&.active': {
      color: theme.palette.secondary.main
    }
  },
  linkText: {
    fontSize: '18px'
  },
  userName: {
    fontSize: '16px'
  },
  menuRoot: {
    // @ts-ignore
    zIndex: `${theme.zIndex.tooltip + 5} !important` as any // override the loading state which has a ~1500 z index
  },
  menu: {
    width: '100%',
    maxWidth: '100%',
    left: '0 !important', // override internal set styles
    padding: theme.spacing(1),
    height: 'calc(100% - 86px)', // 80px is toolbar height and 16px (1rem) is padding applied by component
    [theme.breakpoints.up('md')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      height: theme.spacing(25)
    },
    borderBottom: `15px solid ${theme.palette.secondary.main}`
  },
  userMenu: {
    right: '0 !important', // override internal set styles
    borderRadius: 0,
    borderBottomLeftRadius: 3,
    borderBottomRightRadius: 3,
    overflow: 'hidden',
    borderBottom: `5px solid ${theme.palette.secondary.light}`
  },
  iconButtons: {
    marginLeft: 'auto',
    height: '100%'
  },
  mobileUserMenuButton: {
    color: theme.palette.background.paper,
    backgroundColor: theme.palette.secondary.main,
    width: 60,
    '&.active': {
      color: theme.palette.primary.main
    }
  },
  mobileNavButton: {
    color: theme.palette.background.paper,
    backgroundColor: theme.palette.primary.main,
    '&.active': {
      color: theme.palette.secondary.main
    },
    width: 60
  },
  navMenu: {
    padding: 0
  },
  home: {
    cursor: 'pointer'
  },
  supportWrapper: {
    height: '100%'
  }
}));
