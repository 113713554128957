import React, { FC } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
// components
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Close from '@material-ui/icons/Close';
import { UserStoryExpansion } from '../components/UserStoryExpansion';
import TablePagination from '@material-ui/core/TablePagination';
// constants
import { STORY_COLOR, FEATURE_COLOR, EPIC_COLOR, BUG_COLOR, BLOCKED_COLOR } from '@shared/constants';
// helpers
import { formatSprint } from '@shared/helpers';
// types
import { IUserStoryDetail, IModifiedSearchValue } from '@shared/types';

const DEFAULT_PAGE = 0;

interface IUserStoriesSearchGridView {
  currentStoryId: number | null;
  handleClearSearch: () => void;
  isLoadingStoryDetail: boolean;
  isSearching: boolean;
  page: number;
  perPage: number;
  recordCount: number;
  searchResults: IModifiedSearchValue[] | null;
  searchedInputValue: string;
  selectedStories: IUserStoryDetail[];
  selectedView: string;
  setCurrentStoryId: (val: number) => void;
  setPage: (val: number) => void;
  setPerPage: (val: number) => void;
  isDeepLink?: boolean;
}

export const UserStoriesSearchGridView: FC<IUserStoriesSearchGridView> = ({
  currentStoryId,
  handleClearSearch,
  isLoadingStoryDetail,
  isSearching,
  page,
  perPage,
  recordCount,
  searchResults,
  searchedInputValue,
  selectedStories,
  selectedView,
  setCurrentStoryId,
  setPage,
  setPerPage,
  isDeepLink
}) => {
  const classes = userStoriesSearchGridViewStyles();

  return (
    <>
      {selectedView === 'Grid' && !isSearching && searchResults && (
        <>
          <Grid container alignItems='center' className={classes.resultsHeadingWrapper}>
            <Typography variant='h4' color='secondary'>
              {searchResults.length > 0 ? `Showing results for "${searchedInputValue}"` : `No search results for "${searchedInputValue}"`}
            </Typography>
            <Button size='small' color='default' startIcon={<Close />} className={classes.clearSearchButton} onClick={() => handleClearSearch()}>
              Clear Search
            </Button>
          </Grid>
          {searchResults.map((result, index: number) => {
            const selectedStory = selectedStories && selectedStories.find(story => story.id === Number(result.id));
            return result.workItemType === 'Product Backlog Item' ? (
              <UserStoryExpansion
                currentStoryId={currentStoryId}
                key={`user-story-${index}`}
                selectedStory={selectedStory}
                userStory={result}
                isLoadingStoryDetail={isLoadingStoryDetail}
                isSearching
                handleStoryClick={(val: number) => setCurrentStoryId(val)}
                isDeepLink={isDeepLink}
              />
            ) : (
              <div
                key={`search-result-${index}`}
                className={
                  result.state === 'Blocked'
                    ? `${classes.searchResultWrapper} ${classes.searchResultBlocked}`
                    : result.workItemType === 'Bug'
                    ? `${classes.searchResultWrapper} ${classes.searchResultBug}`
                    : result.workItemType === 'Task'
                    ? `${classes.searchResultWrapper} ${classes.searchResultStory}`
                    : result.workItemType === 'Epic'
                    ? `${classes.searchResultWrapper} ${classes.searchResultEpic}`
                    : `${classes.searchResultWrapper} ${classes.searchResultFeature}`
                }
              >
                <div className={classes.searchResultContent}>
                  <div className={classes.searchResultContentFirstCol}>
                    <Typography component='span' className={classes.storyName}>
                      {result.workItemType}
                    </Typography>
                    <Typography className={result.state === 'Blocked' ? classes.storyTitleBlocked : classes.storyTitle}>{result.title}</Typography>
                  </div>
                  <div className={classes.searchResultContentSecondCol}>
                    <Typography className={result.state === 'Blocked' ? classes.projectTitleBlocked : classes.projectTitle}>
                      {result.clientProjectName || ''}
                    </Typography>
                    <Typography className={result.state === 'Blocked' ? classes.sprintBlockedText : classes.sprintText}>
                      {formatSprint(result.sprintName, result.sprintContainer)}
                    </Typography>
                    <Typography className={result.state === 'Blocked' ? classes.stateBlockedText : classes.stateText}>{result.state}</Typography>
                  </div>
                  <Typography className={classes.fakeArrow}></Typography>
                </div>
              </div>
            );
          })}
          <TablePagination
            rowsPerPageOptions={[10, 25, 50]}
            component='div'
            count={recordCount}
            classes={{
              root: classes.paginationRoot
            }}
            rowsPerPage={perPage}
            // this component is 0 based page
            page={page}
            onChangePage={(e: any, newPage: number) => {
              setPage(newPage);
            }}
            onChangeRowsPerPage={(e: React.ChangeEvent<HTMLInputElement>) => {
              setPage(DEFAULT_PAGE);
              setPerPage(parseInt(e.target.value, 10));
            }}
          />
        </>
      )}
    </>
  );
};

const userStoriesSearchGridViewStyles = makeStyles((theme: Theme) => ({
  resultsHeadingWrapper: {
    marginBottom: theme.spacing(1)
  },
  storyTitle: {
    fontWeight: 'bold'
  },
  searchResultWrapper: {
    width: '100%',
    borderRight: '1px solid #e0e0e0',
    padding: '0 48px 0 10px',
    borderBottom: '1px solid #e0e0e0',
    borderTop: '1px solid #e0e0e0',
    position: 'relative'
  },
  searchResultStory: {
    borderLeft: `3px solid ${STORY_COLOR}`
  },
  searchResultFeature: {
    borderLeft: `3px solid ${FEATURE_COLOR}`
  },
  searchResultEpic: {
    borderLeft: `3px solid ${EPIC_COLOR}`
  },
  searchResultBug: {
    borderLeft: `3px solid ${BUG_COLOR}`
  },
  searchResultBlocked: {
    borderLeft: `3px solid ${BLOCKED_COLOR}`
  },
  searchResultContent: {
    display: 'flex',
    alignItems: 'center',
    margin: '12px 0'
  },
  searchResultContentFirstCol: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      alignItems: 'center',
      width: '60%',
      flexDirection: 'row'
    }
  },
  searchResultContentSecondCol: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      width: '40%',
      flexDirection: 'row',
      justifyContent: 'flex-end'
    }
  },
  workItemTypeText: {
    color: theme.palette.grey[400],
    marginRight: theme.spacing(1)
  },
  projectTitleBlocked: {
    color: BLOCKED_COLOR,
    [theme.breakpoints.up('md')]: {
      width: theme.spacing(15)
    }
  },
  projectTitle: {
    [theme.breakpoints.up('md')]: {
      width: theme.spacing(15)
    }
  },
  sprintText: {
    width: theme.spacing(8),
    [theme.breakpoints.down('sm')]: {
      width: 'auto',
      position: 'absolute',
      left: '55px',
      top: '13px',
      borderLeft: `1px solid ${theme.palette.grey[300]}`,
      paddingLeft: '10px'
    }
  },
  sprintBlockedText: {
    color: BLOCKED_COLOR,
    width: theme.spacing(8),
    [theme.breakpoints.down('sm')]: {
      width: 'auto',
      position: 'absolute',
      left: '55px',
      top: '13px',
      borderLeft: `1px solid ${theme.palette.grey[300]}`,
      paddingLeft: '10px'
    }
  },
  stateBlockedText: {
    color: BLOCKED_COLOR,
    width: theme.spacing(6),
    [theme.breakpoints.down('sm')]: {
      width: 'auto',
      position: 'absolute',
      right: '10px',
      top: '4px'
    }
  },
  stateText: {
    width: theme.spacing(6),
    [theme.breakpoints.down('sm')]: {
      width: 'auto',
      position: 'absolute',
      right: '10px',
      top: '4px'
    }
  },
  storyName: {
    color: theme.palette.grey[400],
    marginBottom: theme.spacing(0.5),
    minWidth: 'auto',
    [theme.breakpoints.up('md')]: {
      marginBottom: 0,
      minWidth: theme.spacing(5)
    }
  },
  storyTitleBlocked: {
    color: BLOCKED_COLOR,
    fontWeight: 'bold'
  },
  fakeArrow: {
    padding: '0 18px'
  },
  clearSearchButton: {
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1)
    }
  },
  paginationRoot: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&& .MuiTablePagination-selectRoot': {
      marginLeft: 0,
      marginRight: '5px'
    },
    '&& .MuiToolbar-gutters': {
      paddingLeft: 0
    },
    [theme.breakpoints.up('md')]: {
      '&& .MuiTablePagination-selectRoot': {
        marginLeft: '8px',
        marginRight: '32px'
      },
      '&& .MuiToolbar-gutters': {
        paddingLeft: 0
      }
    }
  }
}));
