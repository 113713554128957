import React from 'react';
import { useSelector } from 'react-redux';
import { AccessRoute } from '@shared/components/router';
import { userAccess } from '@shared/constants';
// Types
import { IUserProps, IAppState, IRouteObject } from '@shared/types';
// Containers
import TimeTracking from './containers/TimeTracking';
import HashtagList from './containers/HashtagList';
import SoftwareReleases from './containers/SoftwareReleases';
// Components
import SoftwareReleaseDetail from './containers/SoftwareReleaseDetail';

const Routes: IRouteObject[] = [
  { path: '/employees/time-tracking', component: TimeTracking },
  { path: '/employees/hashtag-list', component: HashtagList },
  { path: '/employees/software-releases', component: SoftwareReleases },
  { path: '/employees/software-releases/:releaseId', component: SoftwareReleaseDetail },
  { path: '/employees/software-releases/:releaseId/general-information', component: SoftwareReleaseDetail },
  { path: '/employees/software-releases/:releaseId/release-details', component: SoftwareReleaseDetail },
  { path: '/employees/software-releases/:releaseId/waiting-for-release', component: SoftwareReleaseDetail },
  { path: '/employees/software-releases/:releaseId/releasing', component: SoftwareReleaseDetail },
  { path: '/employees/software-releases/:releaseId/verifying', component: SoftwareReleaseDetail },
  { path: '/employees/software-releases/:releaseId/done', component: SoftwareReleaseDetail }
];

const Employees: React.FC = () => {
  const user: IUserProps = useSelector((state: IAppState) => state.user);

  return (
    <React.Fragment>
      {Routes.map(route => (
        <AccessRoute key={route.path} userAccess={user.userAccess} requiredAccess={[userAccess.VIEW_EMPLOYEES]} {...route}></AccessRoute>
      ))}
    </React.Fragment>
  );
};

export default Employees;
