import { makeStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';
import { format } from 'date-fns';
import React, { FC, useState, useEffect } from 'react';
import ReactGA from 'react-ga';
import { useSelector } from 'react-redux';
// Components
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import ExpandMore from '@material-ui/icons/ExpandMore';
import AccountCircle from '@material-ui/icons/AccountCircle';
import Save from '@material-ui/icons/Save';
import Close from '@material-ui/icons/Close';
import Delete from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import Create from '@material-ui/icons/Create';
import { Toast } from '@shared/components/toast';
import { Wysiwyg } from '@shared/components/wysiwyg';
import { Loader, SkeletonLoader } from '@shared/components/loader';
// fetch
import { createStoryComment, getStoryComments, updateStoryComment, deleteStoryComment } from '@shared/fetch';
// types
import { IUserStoryDetail, IStoryComment, IAppState } from '@shared/types';
// helpers
import { isWysiwygEmpty } from '@shared/helpers';
import { printViewStyles } from './UserStoryDetail';

interface IUserStoryComments {
  currentStoryId: number;
  selectedStory: IUserStoryDetail;
}

export const UserStoryComments: FC<IUserStoryComments> = ({ selectedStory, currentStoryId }) => {
  // styles
  const classes = userStoryCommentsStyles();
  const printClasses = printViewStyles();

  const { selectedClient } = useSelector((state: IAppState) => state.extranet);
  const {
    userAccess: {
      ClientCollaboratorData
    }
  } = useSelector((state: IAppState) => state.user);

  const [commentValue, setCommentValue] = useState<string>('');
  const [errorMessage, showError] = useState<string>('');
  const [successMessage, showSuccess] = useState<string>('');
  const [isCreating, setCreating] = useState<boolean>(false);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [isUpdating, setUpdating] = useState<boolean>(false);
  const [storyComments, setComments] = useState<IStoryComment[] | null>(null);
  const [editComment, setEditing] = useState<{ [id: string]: boolean }>({});
  const [newCommentValue, setNewCommentValue] = useState<{ [id: string]: string }>({});

  const fetchComments = async () => {
    setComments(null);
    setLoading(true);
    try {
      const commentsRes = await getStoryComments(currentStoryId);
      setComments(commentsRes);
    } catch (error) {
      console.error('fetchComments', error);
    } finally {
      setLoading(false);
    }
  };

  const gaLabelClientValue: string = selectedClient ? ` | Client: ${selectedClient?.name}` : '';

  useEffect(() => {
    fetchComments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return ClientCollaboratorData ? (
    <>
      <ExpansionPanel classes={{ root: classes.panelACRoot }} defaultExpanded={true}>
        <ExpansionPanelSummary
          classes={{ root: classes.panelSummaryACStoryRoot }}
          expandIcon={<ExpandMore />}
          aria-controls={`ac-${selectedStory.id}`}
          id={`ac-${selectedStory.id}`}
        >
          <Typography className={classes.bold}>Comments</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails classes={{ root: classes.panelDetailsACRoot }}>
          <div className={classes.commentsWrapper}>
            {/** Hide comments input if in print view. */}
            <div className={printClasses.hidden}>
              <Wysiwyg
                id={`comments-wysiwyg-${selectedStory.id}`}
                value={commentValue}
                onChange={newValue => setCommentValue(newValue)}
                className={classes.editor}
                canShowMentionLinks
              />
              <Grid container className={classes.commentButtons}>
                <Button
                  size='small'
                  startIcon={isCreating ? undefined : <Save />}
                  disabled={isWysiwygEmpty(commentValue) || isCreating}
                  color='secondary'
                  variant='contained'
                  className={classes.commentSaveButton}
                  onClick={async () => {
                    try {
                      ReactGA.event({
                        category: 'User Stories',
                        action: 'Entered a Comment on Story',
                        label: `Commented on Story: ${selectedStory.id} | Comment: ${commentValue}${gaLabelClientValue}`
                      });
                      setCreating(true);
                      const res = await createStoryComment(selectedStory.id, commentValue);
                      if (res.status === 200) {
                        showSuccess('Comment Entered!');
                        setCommentValue('');
                        await fetchComments();
                      } else {
                        throw Error;
                      }
                    } catch (error) {
                      console.error('createStoryComment', error);
                      showError(
                        'We were unable to save your comment at this time. Please try again later.  Please contact MercuryWorks support if this issue continues.'
                      );
                    } finally {
                      setCreating(false);
                    }
                  }}
                >
                  {isCreating ? <Loader size='small'>Saving...</Loader> : 'Save'}
                </Button>
                <Button
                  size='small'
                  disabled={isWysiwygEmpty(commentValue) || isCreating}
                  onClick={() => setCommentValue('')}
                  startIcon={<Close />}
                  variant='contained'
                >
                  Cancel
                </Button>
              </Grid>
            </div>
            {storyComments &&
              storyComments.map((comment, index: number) => {
                return (
                  <Grid key={`${index}`} container className={classes.commentInfo}>
                    <AccountCircle fontSize='large' />
                    <div className={classes.commentDetail}>
                      <Grid container alignItems='center' justify='space-between'>
                        <Typography className={classes.bold}>
                          {comment.createdBy.displayName}
                          <Typography variant='caption' component='span' className={classes.commentDate}>
                            commented {format(new Date(comment.createdDate), 'cccc')}
                          </Typography>
                        </Typography>
                        {/* Only show edit and delete buttons if the comment was created by the user */}
                        {!editComment[comment.commentId] && comment.canEdit && (
                          <span>
                            <IconButton
                              className={classes.editButton}
                              disabled={isUpdating}
                              onClick={() => {
                                setEditing({ [comment.commentId]: true });
                                setNewCommentValue({
                                  [comment.commentId]: comment.baseText // NOTICE: we're setting comment.baseText here, not comment.text (this is intentional)
                                });
                              }}
                              size='small'
                            >
                              <Create />
                            </IconButton>
                            <IconButton
                              className={classes.editButton}
                              disabled={isUpdating}
                              onClick={async () => {
                                const result = window.confirm('Are you sure you want to delete this comment?');
                                if (result) {
                                  try {
                                    setUpdating(true);
                                    await deleteStoryComment(selectedStory.id, comment.commentId);
                                    setComments(storyComments.filter(storyComment => storyComment.commentId !== comment.commentId));
                                    showSuccess('Comment deleted!');
                                  } catch (error) {
                                    showError(
                                      'We were unable to delete your comment at this time. Please try again later.  Please contact MercuryWorks support if this issue continues.'
                                    );
                                  } finally {
                                    setUpdating(false);
                                  }
                                }
                              }}
                              size='small'
                            >
                              <Delete />
                            </IconButton>
                          </span>
                        )}
                      </Grid>
                      {editComment[comment.commentId] ? (
                        <Wysiwyg
                          id={`comments-wysiwyg-${selectedStory.id}`}
                          value={newCommentValue[comment.commentId]}
                          onChange={newValue => setNewCommentValue({ [comment.commentId]: newValue })}
                          className={clsx(classes.editor, classes.editorUpdate)}
                        />
                      ) : (
                        <div dangerouslySetInnerHTML={{ __html: comment.text }} />
                      )}
                      {editComment[comment.commentId] && (
                        <Grid container justify='flex-end'>
                          <ButtonGroup>
                            <Button
                              color='secondary'
                              disabled={isUpdating || newCommentValue[comment.commentId] === comment.baseText}
                              onClick={async () => {
                                try {
                                  setUpdating(true);
                                  await updateStoryComment(selectedStory.id, comment.commentId, newCommentValue[comment.commentId]);
                                  showSuccess('Comment Updated!');
                                  setEditing({ [comment.commentId]: false });
                                  // we need to refetch so we get the comment.text with proper prepended PM tag and appended signature
                                  await fetchComments();
                                } catch (error) {
                                  showError(
                                    'We were unable to update your comment at this time. Please try again later.  Please contact MercuryWorks support if this issue continues.'
                                  );
                                } finally {
                                  setUpdating(false);
                                }
                              }}
                              size='small'
                              variant='contained'
                            >
                              {isUpdating ? 'Updating...' : 'Update'}
                            </Button>
                            <Button
                              disabled={isUpdating}
                              onClick={() => {
                                if (newCommentValue[comment.commentId] !== comment.baseText) {
                                  const result = window.confirm('You have unsaved changes, are you sure you want to cancel?');
                                  if (result) {
                                    setEditing({ [comment.commentId]: false });
                                  }
                                } else {
                                  setEditing({ [comment.commentId]: false });
                                }
                              }}
                              size='small'
                              variant='contained'
                            >
                              Cancel
                            </Button>
                          </ButtonGroup>
                        </Grid>
                      )}
                    </div>
                  </Grid>
                );
              })}
            {storyComments && storyComments.length === 0 && (
              <Typography color='textSecondary' className={clsx(classes.commentInfo, printClasses.visible)}>
                No Comments
              </Typography>
            )}
            {!storyComments && isLoading && (
              <Grid container className={classes.commentInfo}>
                <SkeletonLoader variant='circle' width={30} height={30} />
                <div className={classes.commentDetail}>
                  <SkeletonLoader width={100} />
                  <SkeletonLoader width={100} />
                  <SkeletonLoader width={250} />
                </div>
              </Grid>
            )}
          </div>
        </ExpansionPanelDetails>
      </ExpansionPanel>
      <Toast id='comments-success' message={successMessage} open={!!successMessage} onClose={() => showSuccess('')} variant='success' />
      <Toast id='comments-error' message={errorMessage} open={!!errorMessage} onClose={() => showError('')} variant='error' />
    </>
  ) : null;
};

const userStoryCommentsStyles = makeStyles((theme: Theme) => ({
  panelACRoot: {
    margin: theme.spacing(1, 0),
    backgroundColor: 'transparent',
    borderTop: `0 !important`,
    boxShadow: 'none',
    '&:before': {
      display: 'none'
    }
  },
  panelSummaryACStoryRoot: {
    margin: 0,
    padding: 0,
    borderBottom: `1px solid #c6c6c6`,
    minHeight: `0 !important`,
    '&& .MuiExpansionPanelSummary-content.Mui-expanded': {
      margin: 0
    },
    '&& .MuiIconButton-root': {
      padding: '3px'
    }
  },
  panelDetailsACRoot: {
    padding: `8px 0 0 !important`
  },
  commentsWrapper: {
    width: '100%',
    height: '100%'
  },
  editor: {
    marginBottom: theme.spacing(1)
  },
  editorUpdate: {
    marginTop: theme.spacing(1)
  },
  commentButtons: {
    marginBottom: theme.spacing(1)
  },
  commentSaveButton: {
    marginRight: theme.spacing(1)
  },
  commentInfo: {
    marginTop: theme.spacing(1),
    flexWrap: 'nowrap'
  },
  commentDetail: {
    marginLeft: '10px'
  },
  commentDate: {
    marginLeft: '5px'
  },
  bold: {
    fontWeight: 700
  },
  editButton: {
    '&& svg': {
      fontSize: 16
    }
  }
}));
