import React, { useState, FC } from 'react';
import { ArrowDropDown, ArrowDropUp, FilterList } from '@material-ui/icons';
import { Button, Divider, Grid, Box } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';

interface IFiltersLayout {
  withExpand?: boolean;
}

export const FiltersLayout: FC<IFiltersLayout> = ({ children, withExpand }) => {
  const [isMobileFilterButtonOpen, toggleMobileFilter] = useState<boolean>(false);
  const [isExpanded, setExpanded] = useState<boolean>(false);
  const classes = useStyles({ isMobileFilterButtonOpen, withExpand, isExpanded });

  const handleToggle = () => {
    if (withExpand) {
      setExpanded(!isExpanded);
    } else {
      toggleMobileFilter(!isMobileFilterButtonOpen);
    }
  };

  return (
    <>
      <Box
        display='flex'
        paddingBottom='1rem'
        width={{
          xs: '100%',
          sm: 'auto'
        }}
      >
        <Button
          color='secondary'
          variant='contained'
          startIcon={<FilterList />}
          endIcon={isExpanded ? <ArrowDropUp /> : <ArrowDropDown />}
          onClick={handleToggle}
        >
          Filters
        </Button>
      </Box>
      {!isExpanded && <Divider className={classes.divider} />}
      <Grid container spacing={1} alignItems='center' className={classes.wrapper}>
        {children}
      </Grid>
    </>
  );
};

const useStyles = makeStyles<Theme, { isMobileFilterButtonOpen?: boolean; withExpand?: boolean; isExpanded?: boolean }>((theme: Theme) => ({
  wrapper: ({ isMobileFilterButtonOpen, isExpanded }) => ({
    marginTop: isMobileFilterButtonOpen || isExpanded ? 10 : 0,
    marginBottom: isMobileFilterButtonOpen || isExpanded ? 10 : theme.spacing(1),
    display: isMobileFilterButtonOpen || isExpanded ? 'flex' : 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      marginBottom: theme.spacing(1)
    }
  }),
  divider: {
    display: 'block',
    marginBottom: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      display: 'none'
    }
  }
}));
