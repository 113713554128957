import React, { FC, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { sortBy } from 'lodash';
// Components
import Autocomplete from '@material-ui/lab/Autocomplete';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { LoaderOverlay } from '@shared/components/loader';
import { Alert } from '@shared/components/alerts';
import { Page } from '@shared/components/layout';
import { ConfigCard } from '../components';
// Fetch
import { getZendeskEmployees, getUserSettings, setZendeskUserSettings, setAzureDevOpsUserSettings } from '@shared/fetch';
// Types
import { IUserSettings, IZendeskUser } from '@shared/types';

const defaultZendeskUser = { label: '', value: 0 };

export const EmployeeConfig: FC<{}> = () => {
  const classes = useStyles();

  const [currentUserSettings, setCurrentUserSettings] = useState<IUserSettings | null>(null);
  const [zendeskEmployees, setZendeskEmployees] = useState<IZendeskUser[]>([]);
  const [selectedZendeskEmployee, setSelectedZendeskEmployee] = useState<{ label: string; value: number }>(defaultZendeskUser);
  const [devOpsUsername, setDevOpsUsername] = useState<string>('');

  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [errorOpen, setErrorOpen] = useState(false);
  const [successOpen, setSuccessOpen] = useState(false);

  const loadEmployees = async () => {
    try {
      const [userSettings, zdEmployees] = await Promise.all([getUserSettings(), getZendeskEmployees()]);
      setCurrentUserSettings(userSettings);
      setZendeskEmployees(
        sortBy(zdEmployees, [
          o => {
            return o.userName.split(' ')[0];
          }
        ])
      );
    } catch (error) {
      console.log(error);
      setErrorOpen(error);
    }
  };

  useEffect(() => {
    loadEmployees();
  }, []);

  const updateUserSettings = (registeredAs: string, type: 'zendesk' | 'microsoft365' | 'azureDevOps') => {
    const updatedSettings = {
      ...currentUserSettings,
      [type]: {
        isRegistered: true,
        registeredAs
      }
    } as IUserSettings;
    setCurrentUserSettings(updatedSettings);
  };

  const syncZendesk = async () => {
    if (selectedZendeskEmployee && selectedZendeskEmployee?.value) {
      try {
        setIsSaving(true);
        const zendeskUser: IZendeskUser = {
          userId: selectedZendeskEmployee.value,
          userName: selectedZendeskEmployee.label
        };
        await setZendeskUserSettings(zendeskUser);
        setSuccessOpen(true);
        updateUserSettings(zendeskUser.userName, 'zendesk');
        setSelectedZendeskEmployee(defaultZendeskUser);
      } catch (err) {
        setErrorOpen(err);
      }
      setIsSaving(false);
    } else {
      setErrorOpen(true);
    }
  };

  const syncDevOps = async () => {
    if (devOpsUsername && devOpsUsername.length > 0) {
      try {
        setIsSaving(true);
        await setAzureDevOpsUserSettings(devOpsUsername);
        setSuccessOpen(true);
        updateUserSettings(devOpsUsername, 'azureDevOps');
        setDevOpsUsername('');
      } catch (err) {
        setErrorOpen(err);
      }
      setIsSaving(false);
    } else {
      setErrorOpen(true);
    }
  };

  return (
    <Page title='Employee Configuration'>
      <Grid container className={classes.root}>
        <Grid container justify='center' spacing={2}>
          <Grid item xs={12} md={6}>
            <ConfigCard title='Zendesk' onSync={syncZendesk}>
              {currentUserSettings?.zendesk && (
                <h3 className={classes.registeredAs}>
                  {currentUserSettings.zendesk?.isRegistered ? (
                    <span>
                      Registered As:
                      <br />
                      {currentUserSettings.zendesk.registeredAs}
                    </span>
                  ) : (
                    'Not Registered'
                  )}
                </h3>
              )}
              {zendeskEmployees && zendeskEmployees.length > 0 ? (
                <Autocomplete
                  className={classes.autocomplete}
                  options={zendeskEmployees.map(employee => ({ label: employee.userName, value: employee.userId }))}
                  getOptionLabel={option => option && option.label}
                  value={selectedZendeskEmployee}
                  onChange={(e: any, value: any) => setSelectedZendeskEmployee(value)}
                  renderInput={params => <TextField {...params} label='Name' variant='outlined' size='small' />}
                />
              ) : null}
            </ConfigCard>
          </Grid>
          <Grid item xs={12} md={6}>
            <ConfigCard title='Azure DevOps' onSync={syncDevOps}>
              {currentUserSettings?.azureDevOps && (
                <h3 className={classes.registeredAs}>
                  {currentUserSettings.azureDevOps?.isRegistered ? (
                    <span>
                      Registered As:
                      <br />
                      {currentUserSettings.azureDevOps.registeredAs}
                    </span>
                  ) : (
                    'Not Registered'
                  )}
                </h3>
              )}
              <TextField
                fullWidth
                variant='outlined'
                label='DevOps Username'
                name='name'
                required
                size='small'
                value={devOpsUsername}
                onChange={({ target: { value } }) => setDevOpsUsername(value)}
              />
            </ConfigCard>
          </Grid>
        </Grid>
      </Grid>
      <LoaderOverlay open={isSaving || !currentUserSettings} />
      <Alert open={errorOpen} onClose={setErrorOpen} type='error' text='Problem syncing please check your input and try again!' />
      <Alert open={successOpen} onClose={setSuccessOpen} type='success' text='Save Success!' />
    </Page>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    paddingTop: 24
  },
  divider: {
    marginBottom: theme.spacing(1)
  },
  cardRoot: {
    minWidth: 275
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)'
  },
  pos: {
    marginBottom: 12
  },
  autocomplete: {
    width: '100%'
  },
  registeredAs: {
    textAlign: 'center',
    marginTop: 0,
    marginBottom: '2rem'
  }
}));
