import { handleActions } from 'redux-actions';
import { IActionUserPayload } from '@shared/types';

export default handleActions(
  {
    SET_USER: (state: object, { payload }: IActionUserPayload) => {
      return { ...state, ...payload };
    }
  },
  {
    // initial state
    username: '',
    name: '',
    email: '',
    groups: [],
    featureFlags: [],
    userAccess: {
      ViewSite: false,
      ViewAdmin: false,
      ViewEmployees: false,
      ViewClients: false,
      ClientOnly: false,

      // Client Roles
      ClientCollaboratorData: false,
      ClientAdminData: false,
      ClientFinancialsData: false
    }
  }
);
