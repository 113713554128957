import React, { FC } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
// components
import { Grid, GridSize, Icon, Paper } from '@material-ui/core';
import InsertChartOutlinedIcon from '@material-ui/icons/InsertChartOutlined';
import { SkeletonLoader } from '@shared/components/loader';

interface ILoadingReportGridCardProps {
  key: number;
  gridBreakpoint: GridSize;
  isMobile: boolean;
  icon?: any;
}

export const LoadingReportGridCard: FC<ILoadingReportGridCardProps> = ({ key, gridBreakpoint, isMobile, icon = InsertChartOutlinedIcon }) => {
  const classes = useStyles();

  return (
    <Grid key={key} item xs={gridBreakpoint} className={isMobile ? classes.mobileCard : ''}>
      <Paper variant='outlined' className={classes.loadingCardContainer}>
        <div className={classes.gridCardContainer}>
          {/* ICON */}
          {!isMobile && <Icon component={icon} color={'disabled'} className={classes.barChartImageLoading} />}

          {/* TEXTFIELD PLACEHOLDERS */}
          <Grid container direction='column' justify='center'>
            <SkeletonLoader width={200} />
            <SkeletonLoader width={120} />
          </Grid>
        </div>
      </Paper>
    </Grid>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  mobileCard: {
    flexGrow: 1,
    maxWidth: '100%'
  },
  bodyContent: {
    maxWidth: 'inherit',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  loadingCardContainer: {
    backgroundColor: theme.palette.grey[50],
    border: `1px solid ${theme.palette.grey[300]}`,
    padding: theme.spacing(0.5)
  },
  gridCardContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  barChartImageLoading: {
    fontSize: '56px',
    marginRight: '12px'
  }
}));
