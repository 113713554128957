import React, { FC } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';
// Components
import { IconButton, Tooltip, Grid } from '@material-ui/core';
import { Edit, Delete, Close, Check } from '@material-ui/icons';
// Helpers
import { asyncConfirmDialogue } from '@shared/components/modals';
// Types
import { IRateHistory } from '@shared/types';

interface IHistoryActionsCellProps {
  row: { index: number };
  handleEdit: (index: number) => void;
  handleDelete: (index: number) => void;
  handleAcceptEdit: () => void;
  editing: number;
  isNewDateValid: boolean;
  setDateValid: (isValid: boolean) => void;
  data: IRateHistory[];
}

export const HistoryActionsCell: FC<IHistoryActionsCellProps> = ({
  row: { index },
  handleEdit,
  handleDelete,
  handleAcceptEdit,
  editing,
  isNewDateValid,
  setDateValid,
  data
}) => {
  const classes = useStyles();

  const onDelete = async () => {
    const canDelete = await asyncConfirmDialogue({ title: 'Delete', text: 'Are you sure you want to delete this rate?' });
    if (canDelete) {
      handleDelete(index);
    }
  };

  return (
    <Grid className={clsx(classes.actionButtons, 'actionButtons')}>
      {editing !== index ? (
        <>
          <Tooltip title='Edit Item'>
            <IconButton
              disabled={editing !== -1 && editing !== index}
              size='small'
              color='primary'
              aria-label='edit-rate'
              onClick={() => handleEdit(index)}
              className={classes.editButton}
            >
              <Edit />
            </IconButton>
          </Tooltip>
          {!data[index].isCurrentRate ? (
            <Tooltip title='Delete Item'>
              <IconButton
                disabled={editing !== -1 && editing !== index}
                size='small'
                aria-label='delete-rate'
                onClick={onDelete}
                className={classes.deleteButton}
              >
                <Delete />
              </IconButton>
            </Tooltip>
          ) : (
            <Tooltip title='Cannot Delete Current Rate'>
              <div>
                <IconButton disabled={true} size='small' aria-label='delete-rate' onClick={onDelete} className={classes.deleteButton}>
                  <Delete />
                </IconButton>
              </div>
            </Tooltip>
          )}
        </>
      ) : (
        <>
          <Tooltip title='Accept'>
            <IconButton
              disabled={!isNewDateValid}
              size='small'
              color='primary'
              aria-label='accept-edit'
              onClick={handleAcceptEdit}
              className={classes.editButton}
            >
              <Check />
            </IconButton>
          </Tooltip>
          <Tooltip title='Cancel'>
            <IconButton
              size='small'
              aria-label='cancel-edit'
              onClick={() => {
                handleEdit(-1);
                setDateValid(true);
              }}
              className={classes.deleteButton}
            >
              <Close />
            </IconButton>
          </Tooltip>
        </>
      )}
    </Grid>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  actionButtons: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  editButton: {
    padding: 0
  },
  deleteButton: {
    padding: 0,
    marginLeft: theme.spacing(1),
    color: theme.palette.error.main
  }
}));
