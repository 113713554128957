import {
  Assignment,
  AssignmentInd,
  Dashboard,
  FolderShared,
  LibraryBooks,
  MenuBook,
  PeopleOutline,
  AttachMoney,
  Description,
  CalendarToday,
  Update,
  CloudDownload,
  Help
} from '@material-ui/icons';
import { Admin } from '@shared/components/icons';
import { INavItem } from '@shared/components/navigation';
import { ISubNavItem } from '@shared/components/navigation/SubNavItem';
import { userAccess } from '@shared/constants';
import React from 'react';

export const clientNavItems: ISubNavItem[] = [
  {
    title: 'Dashboard',
    path: '/clients/dashboard',
    icon: <Dashboard />
  },
  {
    title: 'Progress Reports',
    path: '/clients/progress-reports',
    icon: <Assignment />
  },
  {
    title: 'Releases',
    path: '/clients/releases',
    icon: <Update />
  },
  {
    title: 'Requests',
    path: '/clients/requests',
    icon: <Description />
  },
  {
    title: 'Board',
    path: '/clients/board',
    icon: <MenuBook />
  },
  {
    title: 'Backlog',
    path: '/clients/backlog',
    icon: <LibraryBooks />
  },
  {
    title: 'Files',
    path: '/clients/files',
    icon: <FolderShared />
  },
  {
    title: 'Files V2',
    path: '/clients/files-organization-poc',
    icon: <CloudDownload />
  },
  {
    title: 'Spend History',
    path: '/clients/spend-history',
    icon: <AttachMoney />,
    userAccess: userAccess.CLIENT_FINANCIALS_DATA
  },
  {
    title: 'Calendar',
    path: '/clients/calendar',
    icon: <CalendarToday />
  },
  {
    title: 'Manage Users',
    path: '/clients/manage-users',
    icon: <Admin />,
    userAccess: userAccess.CLIENT_ADMIN_DATA
  },
  {
    title: 'Zendesk Form',
    path: '/clients/zendesk-form',
    icon: <Help />
  }
];
export const Navigation: INavItem[] = [
  {
    title: 'Admin',
    rootPath: '/admin',
    icon: <Admin />,
    userAccess: userAccess.VIEW_ADMIN,
    subNav: [
      {
        title: 'Bulk Time Entry Management',
        path: '/admin/bulk-time-entry-management'
      },
      {
        title: 'Invoice Lock',
        path: '/admin/invoice-lock',
        userAccess: userAccess.VIEW_CLIENTS
      },
      {
        title: 'Clients',
        path: '/admin/clients',
        userAccess: userAccess.VIEW_CLIENTS
      },
      {
        title: 'Client Projects',
        path: '/admin/client-projects',
        userAccess: userAccess.VIEW_ADMIN_CLIENT_PROJECTS
      },
      {
        title: 'Labor Rates',
        path: '/admin/labor-rates'
      },
      {
        title: 'Employees',
        path: '/admin/employees',
        userAccess: userAccess.VIEW_ADMIN_EMPLOYEES
      },
      {
        title: 'Employee Roles',
        path: '/admin/employeeroles',
        userAccess: userAccess.VIEW_ADMIN_EMPLOYEES
      },
      {
        title: 'Holiday Entry',
        path: '/admin/holidays'
      },
      {
        title: 'Revenue Categories',
        path: '/admin/revenue-categories'
      },
      {
        title: 'Manage Users',
        path: '/admin/manage-users'
      }
    ]
  },
  {
    title: 'Employees',
    rootPath: '/employees',
    icon: <AssignmentInd />,
    userAccess: userAccess.VIEW_EMPLOYEES,
    subNav: [
      {
        title: 'Time Tracking',
        path: '/employees/time-tracking'
      },
      {
        title: 'Hashtag List',
        path: '/employees/hashtag-list'
      },
      {
        title: 'Software Releases',
        path: '/employees/software-releases'
      }
    ]
  },
  {
    title: 'Clients',
    rootPath: '/clients',
    icon: <PeopleOutline />,
    userAccess: userAccess.VIEW_CLIENTS,
    subNav: clientNavItems
  }
];
