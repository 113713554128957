import { applyMiddleware, createStore, compose, combineReducers } from 'redux';
import thunk from 'redux-thunk';
import { admin, clients, employees, user, extranet, progressReports } from './reducers';

// In development, use the browser's Redux dev tools extension if installed
declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const rootReducer = combineReducers({ admin, clients, employees, user, extranet, progressReports });

export default () => {
  const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)));
  return { store };
};
