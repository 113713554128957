import React from 'react';
import { useSelector } from 'react-redux';

import { AccessRoute } from '@shared/components/router';

// Types
import { IUserProps, IAppState, IRouteObject } from '@shared/types';

// Containers
import {
  ProjectRevenues,
  ClientProjects,
  TimesheetAdjustment,
  Employees,
  LaborRates,
  CompanyHolidays,
  Clients,
  ClientsEdit,
  ManageUsers,
  ManageUserRequests,
  EmployeeRoles,
  InvoiceLock
} from './containers';
import { userAccess } from '@shared/constants';

const Routes: IRouteObject[] = [
  { path: '/admin/revenue-categories', component: ProjectRevenues },
  { path: '/admin/clients', component: Clients },
  { path: '/admin/clients/:clientId', component: ClientsEdit },
  { path: '/admin/client-projects', component: ClientProjects },
  { path: '/admin/employees', component: Employees },
  { path: '/admin/employeeroles', component: EmployeeRoles },
  { path: '/admin/labor-rates', component: LaborRates },
  { path: '/admin/bulk-time-entry-management', component: TimesheetAdjustment },
  { path: '/admin/holidays', component: CompanyHolidays },
  { path: '/admin/manage-users', component: ManageUsers },
  { path: '/admin/manage-user-requests', component: ManageUserRequests },
  { path: '/admin/invoice-lock', component: InvoiceLock }
];

const Admin: React.FC = () => {
  const user: IUserProps = useSelector((state: IAppState) => state.user);

  return (
    <React.Fragment>
      {Routes.map(route => {
        const routeAccess = route.accessTokens || [];
        return (
          <AccessRoute
            key={route.path}
            userAccess={user.userAccess}
            requiredAccess={[userAccess.VIEW_ADMIN, ...routeAccess]}
            {...route}
          ></AccessRoute>
        );
      })}
    </React.Fragment>
  );
};

export default Admin;
