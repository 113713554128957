import React, { FC, useEffect, useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { withFormik, FormikProps, Field, FieldArray, FormikErrors } from 'formik';
import * as Yup from 'yup';
// Redux
import { useSelector } from 'react-redux';
// Components
import {
  Toolbar,
  TextField,
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Paper,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  Divider,
  FormHelperText,
  Tooltip,
  IconButton,
  Checkbox,
  FormControlLabel,
  InputAdornment,
  useMediaQuery
} from '@material-ui/core';
import { Save, Close, Delete } from '@material-ui/icons';
import { LoaderOverlay, Loader } from '@shared/components/loader';
import { PageTitle } from '@shared/components/layout';
// Types
import {
  IAppState,
  IClientInfo,
  IEmployeeInfo,
  IActiveDirectoryGroup,
  IEnumResponse,
  IClientProjectLaborRate,
  IClientApplication,
  IUserProps
} from '@shared/types';
// Fetch
import {
  getClientPlanningType,
  validateClientAreaPaths,
  getUnassignedZendeskOrganizations,
  getClientRates,
  getAvailableChannels
} from '@shared/fetch';
import { useQuery } from 'react-query';
import { Alert, Autocomplete } from '@material-ui/lab';
import { CustomMessage } from '@shared/components/custom-message';
import { RateContent } from '../cards/RateContent';

interface IAreaPathItem {
  clientAreaPathId?: number;
  areaPath: string;
  isDeleted: boolean;
}

interface IClientFormValues {
  name: string;
  [key: string]: any;
  clientId?: number;
  abbreviation?: string;
  address?: string;
  address2?: string;
  city?: string;
  state?: string;
  zip?: string;
  relationshipManagerId?: number;
  productManagerId?: number;
  partnerId?: number;
  isActive?: boolean;
  ocpStatus?: boolean;
  applications: IClientApplication[];
  azureObjectId: string;
  areaPaths: IAreaPathItem[];
  clientPlanningMode?: 'Kanban' | 'Scrum';
  zendeskOrganizationId?: number;
  zendeskOrganizationName?: string;
  usesProgressReports: boolean;
  internalProgressReportEmployeeSubscriptions: number[];
  channelId?: string;
}

interface IClientFormProps {
  initialValues: IClientFormValues | {} | null;
  onCancel: () => void;
  onSave: (values: IClientInfo, callBack: (error?: Error) => void) => void;
  clientAbbreviations?: string[];
  activeDirectoryGroups: IActiveDirectoryGroup[];
  employeeList: IEmployeeInfo[];
  clientInfo: IClientInfo | { azureObjectId: ''; areaPaths: [] };
  loading: boolean;
}

const ClientForm: FC<IClientFormProps & FormikProps<IClientFormValues>> = ({
  values,
  setFieldValue,
  handleChange,
  handleBlur,
  errors,
  isValid,
  touched,
  submitForm,
  onCancel,
  isSubmitting,
  activeDirectoryGroups,
  employeeList,
  loading
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const user: IUserProps = useSelector((state: IAppState) => state.user);
  const { featureFlags } = user;
  const accessSoftwareReleases = !!featureFlags.find(flag => flag.name === 'software-release')?.hasAccess;

  const [loadingPlanningTypes, setLoadingPlanningTypes] = useState<boolean>(false);
  const [clientPlanningTypes, setClientPlanningTypes] = useState<IEnumResponse[]>([]);
  const [loadingZendeskOrgs, setLoadingZendeskOrgs] = useState<boolean>(false);
  const [zendeskOrgs, setZendeskOrgs] = useState<any>([]);

  // redux
  const { employees } = useSelector((state: IAppState) => state.admin);

  const { isLoading: isLoadingChannels, data: channels } = useQuery(
    ['client', values.clientId],

    async () => await getAvailableChannels(Number(values?.clientId)),
    {
      notifyOnChangeProps: 'tracked',
      enabled: accessSoftwareReleases
    }
  );

  const loadClientPlanningTypes = async () => {
    try {
      setLoadingPlanningTypes(true);

      const clientPlanningTypeResponse = await getClientPlanningType();
      setClientPlanningTypes(clientPlanningTypeResponse);
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingPlanningTypes(false);
    }
  };

  const loadUnassignedZendeskOrgs = async () => {
    try {
      setLoadingZendeskOrgs(true);

      const zendeskOrgs = await getUnassignedZendeskOrganizations();
      const currentOrg = {
        value: values?.zendeskOrganizationId,
        text: null,
        description: values?.zendeskOrganizationName,
        shorthand: null,
        order: null
      };
      const allZendeskOrgs = [currentOrg, ...zendeskOrgs];
      setZendeskOrgs(allZendeskOrgs);
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingZendeskOrgs(false);
    }
  };

  useEffect(() => {
    loadClientPlanningTypes();
    loadUnassignedZendeskOrgs();
  }, []);

  const [doesClientHaveValidAAD, setDoesClientHaveValidAAD] = useState<boolean>(true);
  useEffect(() => {
    if (values.azureObjectId === '') {
      setDoesClientHaveValidAAD(true);
    } else {
      setDoesClientHaveValidAAD(activeDirectoryGroups.map(aad => aad.id).includes(values.azureObjectId));
    }
  }, [values.azureObjectId]);

  const checkEmployeeIsActive = (employeeId: number | undefined) => {
    if (employeeId) {
      return employeeList.some(em => em.employeeId === employeeId);
    }
    return true;
  };

  const renderEmployeeList = (employeeId: number | undefined) => {
    if (employeeId && !employeeList.some(em => em.employeeId === employeeId)) {
      const inActiveEmployee = employees.find(em => em.employeeId === employeeId);
      if (inActiveEmployee) {
        return [...employeeList, inActiveEmployee].map(em => (
          <MenuItem key={em.employeeId} value={em.employeeId} {...(em.employeeId === employeeId ? { disabled: true } : {})}>
            {em.firstName} {em.lastName}
          </MenuItem>
        ));
      }
    }
    return employeeList.map(em => (
      <MenuItem key={em.employeeId} value={em.employeeId}>
        {em.firstName} {em.lastName}
      </MenuItem>
    ));
  };

  const renderActiveDirectoryGroups = () => {
    return activeDirectoryGroups.map(ao => (
      <MenuItem key={ao.id} value={ao.id}>
        {ao.name}
      </MenuItem>
    ));
  };

  const addDynamicAreaPath = (fieldValue: string) => {
    const item = {
      areaPath: '',
      clientAreaPathId: null,
      isDeleted: false
    };
    setFieldValue(fieldValue, [...values[fieldValue], item]);
  };

  const addApplicationFieldValue = (fieldValue: string) => {
    const item = {
      name: '',
      clientAppId: 0,
      isActive: true,
      newId: Math.floor(Math.random() * 1000000)
    };
    setFieldValue(fieldValue, [...values[fieldValue], item]);
  };

  const handleDelete = (index: number, fieldValue: string) => {
    let updated = [...(values && values.areaPaths)];
    updated[index].isDeleted = true;
    setFieldValue(fieldValue, updated);
  };

  const handleDeactivate = (index: number, fieldValue: string, handleUnsavedRemove: () => void) => {
    let updated = [...(values && values.applications)];
    if (updated[index]?.clientAppId) {
      updated[index].isActive = false;
      setFieldValue(fieldValue, updated);
    } else {
      // This is a curried function and already has a closure with the approriate index
      handleUnsavedRemove();
    }
  };

  const [selectedProgressReportRecipients, setSelectedProgressReportRecipients] = useState<IEmployeeInfo[]>([]);

  useEffect(() => {
    if (values?.internalProgressReportEmployeeSubscriptions?.length > 0 && employeeList) {
      const options = values?.internalProgressReportEmployeeSubscriptions
        .map(employeeId => employeeList.find(employee => employee.employeeId === employeeId))
        .filter(employee => employee !== undefined)
        .filter(employee => employee?.isActive);
      setSelectedProgressReportRecipients((options as IEmployeeInfo[]) ?? []);
    }
  }, [values, employeeList]);

  // client project rates
  const [laborRates, setLaborRates] = useState<IClientProjectLaborRate[]>([]);
  const [isLoadingLaborRates, setIsLoadingLaborRates] = useState<boolean>(false);
  const [laborRatesError, setLaborRatesError] = useState<boolean>(false);
  const loadLaborRates = async () => {
    if (values && values.clientId) {
      try {
        setLaborRatesError(false);
        setIsLoadingLaborRates(true);
        const laborRatesResponse = await getClientRates(values.clientId);
        setLaborRates(laborRatesResponse);
        setIsLoadingLaborRates(false);
      } catch (error) {
        console.error(error);
        setLaborRatesError(true);
        setIsLoadingLaborRates(false);
      }
    }
  };

  useEffect(() => {
    loadLaborRates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.clientId]);

  return loading || loadingPlanningTypes || loadingZendeskOrgs ? (
    <Loader position='centered' type='overlay' size='large' />
  ) : (
    <>
      <Toolbar disableGutters>
        <PageTitle title={`${values.clientId ? `${values.name}` : 'Add Client'}`} />
        <div className={classes.buttonWrapper}>
          <Button size='small' color='secondary' startIcon={<Save />} disabled={!doesClientHaveValidAAD || !isValid} onClick={() => submitForm()}>
            {isMobile ? 'Save' : 'Save Client'}
          </Button>
          <Button size='small' color='inherit' startIcon={<Close />} onClick={() => onCancel()}>
            Cancel
          </Button>
        </div>
      </Toolbar>
      <Divider className={classes.divider} />
      <div className={classes.content}>
        <Grid container spacing={1}>
          <Grid item xs={12} md={6}>
            <Card elevation={0} variant='outlined' className={classes.marginBottom}>
              <CardHeader
                title='Client Information'
                className={classes.primaryHeader}
                titleTypographyProps={{ component: 'h2', className: 'fontWeight-normal' }}
              />
              <CardContent>
                <Paper elevation={0} square>
                  <div className={classes.column}>
                    <Grid container spacing={1}>
                      <Grid item xs={12} md={12} lg={9}>
                        <TextField
                          fullWidth
                          variant='outlined'
                          label='Client Name'
                          name='name'
                          required
                          value={values.name}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={Boolean(touched.name && errors.name)}
                          helperText={touched.name && errors.name}
                          autoFocus
                        />
                      </Grid>
                      <Grid item xs={12} md={12} lg={3}>
                        <TextField
                          fullWidth
                          required
                          variant='outlined'
                          label='Abbreviation'
                          name='abbreviation'
                          value={values.abbreviation || ''}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={Boolean(touched.abbreviation && errors.abbreviation)}
                          helperText={touched.abbreviation && errors.abbreviation}
                        />
                      </Grid>
                    </Grid>
                    <FormControl fullWidth variant='outlined' required>
                      <InputLabel id='is-active-label' className={classes.outlinedLabel}>
                        Status
                      </InputLabel>
                      <Select
                        name='isActiveSelect'
                        labelId='is-active-label'
                        id='isActive'
                        value={values.isActive}
                        onChange={({ target: { value } }) => setFieldValue('isActive', value as boolean)}
                        onBlur={handleBlur}
                        error={Boolean(touched.isActive && errors.isActive)}
                      >
                        <MenuItem value={'true'}>Active</MenuItem>
                        <MenuItem value={'false'}>Inactive</MenuItem>
                      </Select>
                      {touched.isActive && errors.isActive && <FormHelperText>{errors.isActive}</FormHelperText>}
                    </FormControl>
                    <FormControl fullWidth variant='outlined' required>
                      <InputLabel id='partner-label' className={classes.outlinedLabel}>
                        Partner
                      </InputLabel>
                      <Select
                        name='partnerId'
                        labelId='partner-label'
                        id='partner'
                        value={values.partnerId}
                        onChange={({ target: { value } }) => setFieldValue('partnerId', value as number)}
                        onBlur={handleBlur}
                        error={Boolean(touched.partnerId && errors.partnerId)}
                      >
                        <MenuItem value=''>- None -</MenuItem>
                        {renderEmployeeList(values.partnerId)}
                      </Select>
                      {touched.partnerId && errors.partnerId && <FormHelperText>{errors.partnerId}</FormHelperText>}
                    </FormControl>
                    <FormControl fullWidth variant='outlined' required>
                      <InputLabel id='relationship-manager-label' className={classes.outlinedLabel}>
                        Engagement Manager
                      </InputLabel>
                      <Select
                        name='relationshipManagerId'
                        labelId='relationship-manager-label'
                        id='relationship-manager'
                        value={values.relationshipManagerId}
                        onChange={({ target: { value } }) => setFieldValue('relationshipManagerId', value as number)}
                        onBlur={handleBlur}
                        error={Boolean(touched.relationshipManagerId && errors.relationshipManagerId)}
                      >
                        <MenuItem value=''>- None -</MenuItem>
                        {renderEmployeeList(values.relationshipManagerId)}
                      </Select>
                      {touched.relationshipManagerId && errors.relationshipManagerId && (
                        <FormHelperText>{errors.relationshipManagerId}</FormHelperText>
                      )}
                    </FormControl>
                    <FormControl fullWidth variant='outlined' required>
                      <InputLabel id='product-manager-label' className={classes.outlinedLabel}>
                        Delivery Lead
                      </InputLabel>
                      <Select
                        name='productManagerId'
                        labelId='product-manager-label'
                        id='product-manager'
                        value={values.productManagerId}
                        onChange={({ target: { value } }) => setFieldValue('productManagerId', value as number)}
                        onBlur={handleBlur}
                        error={Boolean(touched.productManagerId && errors.productManagerId)}
                        {...(checkEmployeeIsActive(values.productManagerId) ? {} : { defaultValue: 'Britton Karon' })}
                      >
                        <MenuItem value=''>- None -</MenuItem>
                        {renderEmployeeList(values.productManagerId)}
                      </Select>
                      {touched.productManagerId && errors.productManagerId && <FormHelperText>{errors.productManagerId}</FormHelperText>}
                    </FormControl>
                    <Grid item xs={12}>
                      <CustomMessage
                        showIcon={false}
                        messages={['All assigned employees automatically receive a copy of the Progress Report']}
                        mode={'info'}
                        textClass={classes.smallerText}
                      />
                    </Grid>
                    <Autocomplete
                      multiple
                      id='progress-report-recipients-autocomplete'
                      options={employeeList.filter(employee => employee.isActive)}
                      getOptionLabel={(option: IEmployeeInfo) => option?.firstName + ' ' + option?.lastName}
                      value={selectedProgressReportRecipients}
                      onChange={(e: any, value: IEmployeeInfo[]) => {
                        setSelectedProgressReportRecipients(value);
                        setFieldValue(
                          'internalProgressReportEmployeeSubscriptions',
                          value.map(val => val.employeeId)
                        );
                      }}
                      disableClearable
                      renderInput={(params: any) => <TextField {...params} label='Internal Progress Report Recipients' variant='outlined' />}
                    />
                  </div>
                </Paper>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card elevation={0} variant='outlined' className={classes.marginBottom}>
              <CardHeader
                title='Address Information'
                className={classes.primaryHeader}
                titleTypographyProps={{ component: 'h2', className: 'fontWeight-normal' }}
              />
              <CardContent>
                <Paper elevation={0} square>
                  <div className={classes.column}>
                    <TextField
                      fullWidth
                      variant='outlined'
                      label='Address'
                      name='address'
                      required
                      value={values.address}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={Boolean(touched.address && errors.address)}
                      helperText={touched.address && errors.address}
                    />
                    <TextField
                      fullWidth
                      variant='outlined'
                      label='Address Line 2'
                      name='address2'
                      onKeyDown={(e: React.KeyboardEvent) => {
                        if (e.keyCode === 51 || e.keyCode === 32) {
                          e.preventDefault();
                        }
                      }}
                      value={values.address2}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={Boolean(touched.address2 && errors.address2)}
                      helperText={touched.address2 && errors.address2}
                    />
                    <TextField
                      fullWidth
                      variant='outlined'
                      label='City'
                      name='city'
                      required
                      value={values.city}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={Boolean(touched.city && errors.city)}
                      helperText={touched.city && errors.city}
                    />
                    <TextField
                      fullWidth
                      variant='outlined'
                      label='State'
                      name='state'
                      required
                      onKeyDown={(e: React.KeyboardEvent) => {
                        if (e.keyCode === 51 || e.keyCode === 32) {
                          e.preventDefault();
                        }
                      }}
                      value={values.state}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={Boolean(touched.state && errors.state)}
                      helperText={touched.state && errors.state}
                    />
                    <TextField
                      fullWidth
                      variant='outlined'
                      label='Zip'
                      name='zip'
                      required
                      InputProps={{ type: 'number' }}
                      value={values.zip}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={Boolean(touched.zip && errors.zip)}
                      helperText={touched.zip && errors.zip}
                    />
                  </div>
                </Paper>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Card elevation={0} variant='outlined' className={classes.marginBottom}>
              <CardHeader
                title='Client Portal Admin'
                className={classes.primaryHeader}
                titleTypographyProps={{ component: 'h2', className: 'fontWeight-normal' }}
              />
              <CardContent>
                <Grid container spacing={1}>
                  <Grid item xs={12} md={6}>
                    <Paper elevation={0} square>
                      <div className={classes.column}>
                        {/* ACTIVE DIRECTORY GROUP */}
                        <FormControl fullWidth variant='outlined'>
                          <InputLabel id='azureId-label' className={classes.outlinedLabel}>
                            Azure Active Directory Group
                          </InputLabel>
                          <Select
                            name='azureObjectId'
                            labelId='azureId-label'
                            id='azureObjectId'
                            value={values.azureObjectId}
                            onChange={({ target: { value } }) => setFieldValue('azureObjectId', value as number)}
                            onBlur={handleBlur}
                            error={Boolean(touched.azureObjectId && errors.azureObjectId) || !doesClientHaveValidAAD}
                            endAdornment={
                              values.azureObjectId ? (
                                <InputAdornment position='end'>
                                  <IconButton onClick={() => setFieldValue('azureObjectId', '')}>
                                    <Close />
                                  </IconButton>
                                </InputAdornment>
                              ) : null
                            }
                          >
                            {renderActiveDirectoryGroups()}
                          </Select>
                          {touched.azureObjectId && errors.azureObjectId && <FormHelperText>{errors.azureObjectId}</FormHelperText>}
                          {!doesClientHaveValidAAD && (
                            <FormHelperText>Your Azure Active Directory Group is invalid. Please select another option.</FormHelperText>
                          )}
                        </FormControl>
                        {/* CLIENT PLANNING MODE */}
                        <FormControl fullWidth variant='outlined' required>
                          <InputLabel id='client-planning-is-active-label' className={classes.outlinedLabel}>
                            Client Planning Mode
                          </InputLabel>
                          <Select
                            labelId='client-planning-is-active-label'
                            value={values.clientPlanningMode}
                            onChange={({ target: { value } }) => setFieldValue('clientPlanningMode', value)}
                            onBlur={handleBlur}
                            error={Boolean(touched.clientPlanningMode && errors.clientPlanningMode)}
                          >
                            {clientPlanningTypes.map(planningType => (
                              <MenuItem value={planningType.text}>{planningType.text}</MenuItem>
                            ))}
                          </Select>
                          {touched.clientPlanningMode && errors.clientPlanningMode && <FormHelperText>{errors.clientPlanningMode}</FormHelperText>}
                        </FormControl>

                        <FormControl fullWidth variant='outlined'>
                          <InputLabel id='zendesk-orgs-active-label' className={classes.outlinedLabel}>
                            Zendesk Organizations
                          </InputLabel>
                          <Select
                            labelId='zendesk-orgs-active-label'
                            value={values?.zendeskOrganizationId}
                            onChange={({ target: { value } }) => setFieldValue('zendeskOrganizationId', value)}
                            onBlur={handleBlur}
                            error={Boolean(touched.zendeskOrganizationId && errors.zendeskOrganizationId)}
                          >
                            {zendeskOrgs.map((org: any) => (
                              <MenuItem key={org?.value} value={org?.value}>
                                {org?.description}
                              </MenuItem>
                            ))}
                          </Select>
                          {touched.zendeskOrganizationId && errors.zendeskOrganizationId && (
                            <FormHelperText>{errors.zendeskOrganizationId}</FormHelperText>
                          )}
                        </FormControl>

                        {accessSoftwareReleases && (
                          <FormControl fullWidth variant='outlined'>
                            <InputLabel id='teams-channels' className={classes.outlinedLabel}>
                              Teams Channels
                            </InputLabel>
                            <Select
                              labelId='teams-channels'
                              value={values?.channelId}
                              onChange={({ target: { value } }) => setFieldValue('channelId', value)}
                              onBlur={handleBlur}
                              error={Boolean(touched.channelId && errors.channelId)}
                              disabled={isLoadingChannels}
                            >
                              {channels?.map((org: any) => (
                                <MenuItem key={org?.value} value={org?.value}>
                                  {org?.description}
                                </MenuItem>
                              ))}
                            </Select>
                            {touched.channelId && errors.channelId && <FormHelperText>{errors.channelId}</FormHelperText>}
                          </FormControl>
                        )}
                        {/* USE PROGRESS REPORTS */}
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={values.usesProgressReports}
                              onChange={() => setFieldValue('usesProgressReports', !values.usesProgressReports)}
                              onBlur={handleBlur}
                              color='secondary'
                            />
                          }
                          label='Use Progress Reports'
                        />
                      </div>
                    </Paper>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Paper elevation={0} square>
                      <div className={classes.column}>
                        {values.areaPaths.length > 0 &&
                          values.areaPaths.map(
                            (path, index) =>
                              !path.isDeleted && (
                                <Grid key={`areaPathItem_${index}`} container direction='row' justify='flex-start' spacing={1}>
                                  <Grid item xs={10} classes={{ root: classes.pathInput }}>
                                    <Field
                                      name={`areaPaths[${index}]`}
                                      render={() => (
                                        <TextField
                                          fullWidth
                                          variant='outlined'
                                          label='Area Path'
                                          name={`areaPaths[${index}]`}
                                          required
                                          value={values.areaPaths ? values.areaPaths[index].areaPath : ''}
                                          onChange={({ target: { value } }) => {
                                            if (value) {
                                              setFieldValue(`areaPaths[${index}].areaPath`, value);
                                            } else {
                                              setFieldValue(`areaPaths[${index}].areaPath`, '');
                                            }
                                          }}
                                          onBlur={handleBlur}
                                          error={Boolean(
                                            touched.areaPaths && touched.areaPaths[index] && errors.areaPaths && errors.areaPaths[index]
                                          )}
                                          helperText={touched.areaPaths && touched.areaPaths[index] && errors.areaPaths && errors.areaPaths[index]}
                                        />
                                      )}
                                      validate={async () => {
                                        if (!values.areaPaths || !values.areaPaths[index].areaPath) {
                                          return 'Area path is required.';
                                        }
                                        try {
                                          const isValid = await validateClientAreaPaths(values.areaPaths ? [values.areaPaths[index].areaPath] : ['']);
                                          return isValid ? false : 'Invalid area path please verify.';
                                        } catch (error) {
                                          return 'Invalid area path please verify.';
                                        }
                                      }}
                                    />
                                  </Grid>
                                  {/* DELETE AREA PATH */}
                                  <Grid item xs={2} classes={{ root: classes.deleteContainer }}>
                                    <Tooltip title='Delete Area Path'>
                                      <IconButton
                                        size='small'
                                        aria-label='delete-rate'
                                        onClick={() => handleDelete(index, 'areaPaths')}
                                        className={classes.deleteButton}
                                      >
                                        <Delete />
                                      </IconButton>
                                    </Tooltip>
                                  </Grid>
                                </Grid>
                              )
                          )}
                      </div>
                      <Button
                        variant='contained'
                        color='secondary'
                        onClick={() => addDynamicAreaPath('areaPaths')}
                        classes={{ root: classes.addButton }}
                      >
                        Add Area Path
                      </Button>
                    </Paper>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} md={6}>
            <Card elevation={0} variant='outlined' className={classes.marginBottom}>
              <CardHeader
                title='Rates'
                className={classes.primaryHeader}
                titleTypographyProps={{ component: 'h2', className: 'fontWeight-normal' }}
              />
              <CardContent>
                {isLoadingLaborRates && <Loader position='centered' type='inline' size='large' />}
                {!isLoadingLaborRates && !laborRatesError && <RateContent laborRates={laborRates} />}
                {!isLoadingLaborRates && laborRatesError && <Alert severity='error'>Error loading rates!</Alert>}
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card elevation={0} variant='outlined' className={classes.marginBottom}>
              <CardHeader
                title='Applications'
                className={classes.primaryHeader}
                titleTypographyProps={{ component: 'h2', className: 'fontWeight-normal' }}
              />
              <CardContent>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Paper elevation={0} square>
                      <div className={classes.column}>
                        <FieldArray
                          name={'applications'}
                          render={arrayHelpers => (
                            <>
                              {(values?.applications?.length ?? 0) > 0 &&
                                values?.applications?.map((application, index) => (
                                  <Grid
                                    style={{ display: application.isActive ? 'flex' : 'none' }}
                                    key={`applications_${application.clientAppId ?? application?.newId}`}
                                    container
                                    direction='row'
                                    justify='flex-start'
                                    spacing={1}
                                  >
                                    <Grid
                                      key={`applications_grid_${application.clientAppId ?? application?.newId}`}
                                      item
                                      xs={10}
                                      classes={{ root: classes.pathInput }}
                                    >
                                      <Field
                                        key={`applications_field_${application.clientAppId ?? application?.newId}`}
                                        name={`applications[${index}].name`}
                                        render={() => (
                                          <TextField
                                            key={`applications_textfield_${application.clientAppId ?? application?.newId}`}
                                            fullWidth
                                            variant='outlined'
                                            label='Application Name'
                                            name={`applications[${index}]`}
                                            disabled={!application.isActive}
                                            required
                                            value={values.applications ? values.applications[index].name : ''}
                                            onChange={({ target: { value } }) => {
                                              if (value) {
                                                setFieldValue(`applications[${index}].name`, value);
                                              } else {
                                                setFieldValue(`applications[${index}].name`, '');
                                              }
                                            }}
                                            onBlur={handleBlur}
                                            error={Boolean(touched?.applications?.[index] && errors?.applications?.[index])}
                                            helperText={
                                              touched?.applications?.[index] &&
                                              (errors?.applications?.[index] as FormikErrors<IClientApplication>)?.name
                                            }
                                          />
                                        )}
                                      />
                                    </Grid>

                                    <Grid item xs={2} classes={{ root: classes.deleteContainer }}>
                                      <Tooltip title='Deactivate Application'>
                                        <IconButton
                                          disabled={!values.applications[index].isActive}
                                          size='small'
                                          aria-label='deactivate-application'
                                          onClick={() => handleDeactivate(index, 'applications', arrayHelpers.handleRemove(index))}
                                          className={classes.deleteButton}
                                        >
                                          <Delete />
                                        </IconButton>
                                      </Tooltip>
                                    </Grid>
                                  </Grid>
                                ))}
                            </>
                          )}
                        />
                      </div>
                      <Button
                        variant='contained'
                        color='secondary'
                        onClick={() => addApplicationFieldValue('applications')}
                        classes={{ root: classes.addButton }}
                      >
                        Add Application
                      </Button>
                    </Paper>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </div>
      <LoaderOverlay open={isSubmitting} />
    </>
  );
};

export default withFormik<IClientFormProps, IClientFormValues>({
  enableReinitialize: true,
  validateOnBlur: true,
  validateOnChange: true,
  mapPropsToValues: ({ clientInfo, initialValues = {} }) => {
    return {
      clientId: undefined,
      abbreviation: '',
      name: '',
      address: '',
      address2: '',
      city: '',
      state: '',
      zip: '',
      azureObjectId: clientInfo.azureObjectId || '',
      applications: [],
      areaPaths: clientInfo.areaPaths || [],
      relationshipManagerId: undefined,
      productManagerId: undefined,
      partnerId: undefined,
      isActive: true,
      clientPlanningMode: 'Scrum',
      usesProgressReports: true,
      internalProgressReportEmployeeSubscriptions: [],
      ...initialValues
    };
  },
  validationSchema: (props: any) =>
    Yup.object().shape({
      name: Yup.string().nullable().required('Client Name is Required.'),
      address: Yup.string().nullable().required('Address is Required.'),
      city: Yup.string().nullable().required('City is Required.'),
      state: Yup.string().nullable().required('State is Required.'),
      zip: Yup.number().max(99999).nullable().required('Zip is Required.'),
      partnerId: Yup.number().nullable().required('Partner is Required'),
      applications: Yup.array().of(
        Yup.object().shape({
          name: Yup.string().nullable().required('Application Name is Required.')
        })
      ),
      relationshipManagerId: Yup.number().nullable().required('Engagement Manager is Required'),
      productManagerId: Yup.number().nullable().required('Delivery Lead is Required'),
      abbreviation: props.initialValues.clientId
        ? Yup.string().required('Abbreviation is Required')
        : Yup.string().notOneOf(props?.clientAbbreviations, 'Please Enter a Unique Abbreviation').required('Abbreviation is Required'),
      azureObjectId: Yup.string().nullable(),
      clientPlanningMode: Yup.string().required('Client Planning Mode is Required'),
      zendeskOrganizationId: Yup.number().nullable(),
      channelId: Yup.string().nullable()
    }),
  handleSubmit: async (values, { props: { onSave }, setSubmitting, resetForm }) => {
    setSubmitting(true);
    const applications = values.applications.map(app => {
      return {
        name: app?.name,
        clientAppId: app?.clientAppId ?? 0,
        isActive: app?.isActive
      };
    });

    await onSave(
      {
        ...values,
        applications
      } as IClientInfo,
      (error?: Error) => {
        if (!error) {
          resetForm(); // allows for the dirty flag to reset
        }
        Promise.resolve();
      }
    );
  }
})(ClientForm);

const useStyles = makeStyles(theme => ({
  pathInput: {
    display: 'flex',
    alignItems: 'center'
  },
  addButton: {
    marginTop: theme.spacing(1)
  },
  deleteButton: {
    padding: 0,
    marginLeft: theme.spacing(1),
    color: theme.palette.error.main
  },
  deleteContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end'
  },
  primaryHeader: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    marginBottom: theme.spacing(1)
  },
  marginBottom: {
    marginBottom: theme.spacing(1)
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    '& > div:not(:first-of-type)': {
      marginTop: theme.spacing(1)
    }
  },
  outlinedLabel: {
    backgroundColor: theme.palette.common.white,
    paddingLeft: 2,
    paddingRight: 2
  },
  divider: {
    marginBottom: theme.spacing(1)
  },
  smallCard: {
    maxWidth: '570px'
  },
  buttonWrapper: {
    marginLeft: 'auto'
  },
  checkboxPadding: {
    paddingTop: '8px'
  },
  content: {
    flexGrow: 1,
    maxHeight: '100%'
    // overflowX: 'hidden'
  },
  smallerText: {
    fontSize: '12px'
  },
  customMessage: {
    padding: '12px'
  },
  rateBox: {
    paddingRight: '1rem'
  },
  rateType: {
    color: theme.palette.grey[500],
    width: 90
  }
}));
