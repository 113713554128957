import { createActions } from 'redux-actions';
import * as localForage from 'localforage';
// constants
import { selectedClientIdLSKey } from '@shared/constants';
// Types
import { IClientInfo } from '@shared/types';

export const { setExtranetClients, setExtranetClient, setClientSwitcherDisabled } = createActions({
  SET_EXTRANET_CLIENT: (client: IClientInfo) => {
    // set local forage with the correct clientId
    localForage.setItem(selectedClientIdLSKey, client.clientId);
    return {
      client
    };
  },
  SET_EXTRANET_CLIENTS: (clients: IClientInfo[]) => ({ clients }),
  SET_CLIENT_SWITCHER_DISABLED: (isDisabled: boolean) => ({ isDisabled })
});
