import React, { FC } from 'react';
import { makeStyles, Theme } from '@material-ui/core';
import clsx from 'clsx';

interface IOlProps {
  className?: string;
}

export const Ol: FC<IOlProps> = ({ children, ...props }) => {
  const classes = olStyles();
  return (
    <ol {...props} className={clsx(classes.ol, props.className)}>
      {children}
    </ol>
  );
};

const olStyles = makeStyles((theme: Theme) => ({
  ol: {
    fontSize: `1rem`,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 400,
    lineHeight: 1.5,
    margin: theme.spacing(2, 0)
  }
}));
