import React, { FC } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Grid from '@material-ui/core/Grid';
import Slide from '@material-ui/core/Slide';

interface IEditPanelLayoutProps {
  children: JSX.Element;
  editPanel: JSX.Element;
  open: boolean;
}

export const EditPanelLayout: FC<IEditPanelLayoutProps> = ({ children, editPanel, open }) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Grid className={classes.root} container direction={isMobile ? 'column' : 'row'}>
      <Grid
        item
        xs={12}
        sm={open && !isMobile ? 9 : 12}
        className={classes.children}
        style={{ width: open && isSmallScreen && !isMobile ? 'calc(100% - 300px)' : '100%' }}
      >
        {children}
      </Grid>
      <Slide direction='left' in={open} mountOnEnter unmountOnExit timeout={{ enter: 300, exit: 0 }}>
        <Grid item xs={12} sm={3} className={classes.editPanel} style={{ order: isMobile ? 2 : 0 }}>
          {editPanel}
        </Grid>
      </Slide>
    </Grid>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    overflowX: 'hidden',
    height: '100%'
  },
  children: {
    height: '100%',
    transition: 'width 0.3s' // Smooth transition for width change
  },
  editPanel: {
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  }
}));
