import React, { FC, useEffect } from 'react';
import { Prompt } from 'react-router';

interface IUnsavedchanges {
  dirty: boolean;
  message?: string;
}

export const UnsavedChanges: FC<IUnsavedchanges> = ({ dirty, message = 'You have unsaved changes, are you sure you want to leave?' }) => {
  // Must track when dirty changes and update the onbeforeunload
  // for window. This prevents user from refreshing/exiting page when
  // changes are unsaved.
  useEffect(() => {
    if (window) {
      window.onbeforeunload = dirty ? () => true : null;

      return () => {
        window.onbeforeunload = null;
      };
    }
  }, [dirty]);

  return <Prompt when={dirty} message={message} />;
};
