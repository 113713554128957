import React, { FC } from 'react';
// components
import { GridLoader } from '@shared/components/loader';
import { UserStoryExpansion } from '../components/UserStoryExpansion';
import { UserStoryEpicsExpansion } from './UserStoryEpicsExpansion';
import { UserStoryFeaturesExpansion } from './UserStoryFeaturesExpansion';
// Types
import { IUserEpic, IUserStoryFeature, IUserStory, IUserStoryDetail, WorkItemTypes } from '@shared/types';

interface IUserStoryGridViewProps {
  allFeatureUserStories: IUserStoryFeature[];
  allUserStories: IUserStory[] | null;
  currentEpicId: number | null;
  currentFeatureId?: number | null;
  currentStoryId: number | null;
  epics: IUserEpic[] | null;
  features: IUserStoryFeature[];
  handleEpicClick: (id: number) => void;
  handleFeatureClick: (id: number | null) => void;
  handleStoryClick: (id: number) => void;
  isLoadingFeatureDetail?: boolean;
  isLoadingFeatures: boolean;
  isLoadingStories: boolean;
  isLoadingStoryDetail: boolean;
  page: number;
  recordCount: number;
  rowsPerPage: number;
  selectedStories?: IUserStoryDetail[];
  selectedTypeFilter: WorkItemTypes | null;
  setPage: (val: number) => void;
  setRowsPerPage: (val: number) => void;
  userStories: IUserStory[];
  showPagination?: boolean;
  indent?: boolean;
  isDeepLink?: boolean;
  expanded: { [id: number]: boolean };
  setExpanded: React.Dispatch<
    React.SetStateAction<{
      [id: number]: boolean;
    }>
  >;
  isLoadingFeaturesArray?: number[];
}

export const UserStoryGridView: FC<IUserStoryGridViewProps> = ({
  allFeatureUserStories,
  allUserStories,
  currentEpicId,
  currentFeatureId,
  currentStoryId,
  epics,
  features,
  handleEpicClick,
  handleFeatureClick,
  handleStoryClick,
  isLoadingStories,
  isLoadingFeatures,
  isLoadingStoryDetail,
  page,
  recordCount,
  rowsPerPage,
  selectedStories,
  selectedTypeFilter,
  setPage,
  setRowsPerPage,
  userStories,
  showPagination = true,
  indent = false,
  expanded,
  setExpanded,
  isLoadingFeaturesArray,
  isDeepLink
}) => {
  return (
    <>
      {selectedTypeFilter === 'Story' && (
        <>
          {isLoadingStories && <GridLoader rows={5} />}
          {allUserStories &&
            allUserStories.length > 0 &&
            allUserStories.map((userStory: IUserStory, k: number) => {
              const selectedStory = selectedStories && selectedStories.find(story => story.id === userStory.id);
              return (
                <UserStoryExpansion
                  currentStoryId={currentStoryId}
                  key={`user-story-${k}`}
                  selectedStory={selectedStory}
                  userStory={userStory}
                  isLoadingStoryDetail={isLoadingStoryDetail}
                  handleStoryClick={handleStoryClick}
                  indent={indent}
                  isDeepLink={isDeepLink}
                />
              );
            })}
        </>
      )}

      {selectedTypeFilter === 'Feature' && (
        <>
          {isLoadingStories && <GridLoader rows={5} />}
          <UserStoryFeaturesExpansion
            allFeatureUserStories={allFeatureUserStories}
            currentFeatureId={currentFeatureId ? currentFeatureId : null}
            currentStoryId={currentStoryId}
            handleFeatureClick={handleFeatureClick}
            handleStoryClick={handleStoryClick}
            isLoadingStories={isLoadingStories}
            isLoadingStoryDetail={isLoadingStoryDetail}
            selectedStories={selectedStories}
            selectedView='Grid'
            userStories={userStories}
            indent={indent}
            isDeepLink={isDeepLink}
          />
        </>
      )}

      {epics && (
        <UserStoryEpicsExpansion
          currentEpicId={currentEpicId}
          currentFeatureId={currentFeatureId ? currentFeatureId : null}
          currentStoryId={currentStoryId}
          epics={epics}
          features={features}
          handleEpicClick={handleEpicClick}
          handleFeatureClick={handleFeatureClick}
          handleStoryClick={handleStoryClick}
          isLoadingStories={isLoadingStories}
          isLoadingFeatures={isLoadingFeatures}
          isLoadingStoryDetail={isLoadingStoryDetail}
          selectedStories={selectedStories}
          selectedTypeFilter={selectedTypeFilter}
          selectedView='Grid'
          userStories={userStories}
          indent={indent}
          expanded={expanded}
          setExpanded={setExpanded}
          isLoadingFeaturesArray={isLoadingFeaturesArray}
          isDeepLink={isDeepLink}
        />
      )}
    </>
  );
};
