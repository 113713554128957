import qs from 'qs'
import { authFetch } from './';
// Types
import { IProgressReportsRes, IProgressReportDetail, IProgressReportLaborExpenses, IProgressReportBase, IProgressReportBudgeDetail } from '@shared/types';
import { PortfolioReportSprintAccomplishments } from '../types/progressReports';

export const getProgressReports = async (
  clientId: number,
  filters: { page: number; perPage: number; sortBy: string; sortDirection: string }
): Promise<IProgressReportsRes> => {
  try {
    const { data } = await authFetch.get(
      `/api/extranet/PortfolioReports/Clients/${clientId}?page=${filters.page}&perPage=${filters.perPage}&SortBy=${filters.sortBy}&SortDirection=${filters.sortDirection}`
    );
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getMonthlySpending = async (
  clientId: number,
  startDate: Date | string,
  endDate: Date | string,
  projectId?: string | number | null
): Promise<any> => {
  let query = `?`;
    if (projectId) {
      query += `ProjectId=${projectId}&`;
    }
  try {
    const { data } = await authFetch.get(
      `/api/extranet/Projects/${clientId}/MonthlySpending${query}FromDate=${startDate}&ToDate=${endDate}`
    );
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const createProgressReport = async (clientId: number, projectIterationUuid: string | number, create: IProgressReportBase): Promise<any> => {
  try {
    const { data } = await authFetch.post(`/api/extranet/PortfolioReports/Clients/${clientId}/ProjectIteration/${projectIterationUuid}`, create);
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getSingleProgressReportBySprint = async (clientId: number, projectIterationUuid: string | number): Promise<IProgressReportDetail> => {
  try {
    const { data } = await authFetch.get(`/api/extranet/PortfolioReports/Clients/${clientId}/ProjectIteration/${projectIterationUuid}`);
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getSingleProgressReportByReportId = async (
  reportId: number,
  options?: { withBudget: boolean }
): Promise<IProgressReportDetail> => {
  const { data } = await authFetch.get(`/api/extranet/PortfolioReports/${reportId}`);
  // empty budget data for unauthorized users
  const defaultBudgetData = {
    budgetSpendTotalAmount: 0,
    displayBudgetSpend: false,
    laborExpenses: {
      accruedExpenses: [],
      sprintExpenses: []
    }
  }

  try {
    if (options?.withBudget) {
      const budgetData = await getSingleProgressReportWithBudgetByReportId(reportId)
      return {
        ...data,
        ...budgetData
      }
    }
    return {
      ...defaultBudgetData,
      ...data,
    }
  } catch(e) {
    console.log('Failed to retreive budget data', e)
    return {
      ...defaultBudgetData,
      ...data,
    }
  }
};

export const getSingleProgressReportWithBudgetByReportId = async (reportId: number): Promise<IProgressReportBudgeDetail> => {
  const { data } = await authFetch.get(`/api/extranet/PortfolioReports/${reportId}/Budget`);
  return data
};

export const updateSingleProgressReport = async (reportId: number, update: IProgressReportBase): Promise<any> => {
  try {
    const { data } = await authFetch.put(`/api/extranet/PortfolioReports/${reportId}`, update);
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

interface IProgressReportDetailsQueryParams {
  IncludeJog?: boolean
}

export const getSingleProgressReportBudget = async (
  clientId: number,
  projectIterationUuid: string | number,
  options?: IProgressReportDetailsQueryParams
): Promise<number> => {
  const queryParams = qs.stringify(options ?? {}, { addQueryPrefix: true })
  try {
    const { data } = await authFetch.get(
      `/api/extranet/PortfolioReports/Clients/${clientId}/ProjectIteration/${projectIterationUuid}/Budget${queryParams}`
    );
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getSingleProgressReportHours = async (
  clientId: number,
  projectIterationUuid: string | number,
  options?: IProgressReportDetailsQueryParams
): Promise<number> => {
  const queryParams = qs.stringify(options ?? {}, { addQueryPrefix: true })
  try {
    const { data } = await authFetch.get(`/api/extranet/PortfolioReports/Clients/${clientId}/ProjectIteration/${projectIterationUuid}/Hours${queryParams}`);
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getSingleProgressReportLaborExpenses = async (
  clientId: number,
  projectIterationUuid: string | number,
  options?: IProgressReportDetailsQueryParams
): Promise<IProgressReportLaborExpenses> => {
  const queryParams = qs.stringify(options ?? {}, { addQueryPrefix: true })
  try {
    const { data } = await authFetch.get(`/api/extranet/PortfolioReports/Clients/${clientId}/ProjectIteration/${projectIterationUuid}/LaborExpenses${queryParams}`);
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getSingleProgressReportSprintAccomplishments = async (
  clientId: number,
  projectIterationUuid: string | number,
  options?: IProgressReportDetailsQueryParams
): Promise<PortfolioReportSprintAccomplishments> => {
  const queryParams = qs.stringify(options ?? {}, { addQueryPrefix: true })
  try {
    const { data } = await authFetch.get(
      `/api/extranet/PortfolioReports/Clients/${clientId}/ProjectIteration/${projectIterationUuid}/SprintAccomplishments${queryParams}`
    );
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getSingleProgressReportSprintPlans = async (clientId: number, projectIterationUuid: string | number): Promise<string> => {
  try {
    const { data } = await authFetch.get(`/api/extranet/PortfolioReports/Clients/${clientId}/ProjectIteration/${projectIterationUuid}/SprintPlans`);
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const removeProgressReport = async (reportId: number): Promise<any> => {
  try {
    const { data } = await authFetch.delete(`/api/extranet/PortfolioReports/${reportId}`);
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};
