import React, { FC } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';
import { emphasize } from '@material-ui/core/styles/colorManipulator';
import { Link } from 'react-router-dom';
// components
import { Grid, GridSize, Typography, Paper, Icon, CircularProgress } from '@material-ui/core';

interface ITileCardProps {
  id?: string;
  gridBreakpoint?: GridSize;
  isMobile: boolean;
  isEqualHeight?: boolean;
  titleOverflow?: boolean;
  alignCenter?: boolean;
  icon?: any;
  iconColor?: 'primary' | 'secondary';
  reactRouterLinkOnClick?: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
  linkTo?: string;
  onClick?: () => void;
  content?: React.ReactNode;
  action?: React.ReactNode;
  header?: React.ReactNode;
  title?: string;
  footer?: React.ReactNode;
  isLoading?: boolean;
  loadingText?: string;
  titleComponent?: React.ElementType;
}

export const TileCard: FC<ITileCardProps> = ({
  id,
  gridBreakpoint = 12,
  isMobile,
  isEqualHeight = true,
  titleOverflow = false,
  alignCenter = false,
  icon,
  iconColor = 'primary',
  reactRouterLinkOnClick,
  linkTo = '#',
  onClick,
  content,
  action,
  header,
  title,
  footer,
  isLoading,
  loadingText = 'Downloading...',
  titleComponent = 'h3'
}) => {
  const classes = useStyles({ iconColor });

  const tileContent = (
    <div className={clsx(classes.cardContent, alignCenter && classes.alignCenter)}>
      {!isMobile && icon && <Icon component={icon} className={clsx(classes.icon, alignCenter && 'no-margin')} />}
      <Grid container direction='column' justify='center'>
        {!!header && <div className={classes.header}>{header}</div>}
        <Typography color='primary' variant='h4' component={titleComponent} className={clsx(classes.title, titleOverflow && classes.titleOverflow)}>
          {isLoading ? loadingText : title}
        </Typography>
        {!!footer && <div className={classes.footer}>{footer}</div>}
        {isLoading && <CircularProgress size={24} className={classes.progress} />}
      </Grid>
    </div>
  );
  return (
    <Grid id={id} item xs={gridBreakpoint} className={isMobile ? classes.mobileCard : undefined}>
      <Paper elevation={4} variant='elevation' className={clsx(classes.cardContainer, isEqualHeight && classes.equalHeight)}>
        <Grid item xs={11}>
          {!!reactRouterLinkOnClick && (
            <Link className={classes.hyperlink} to={linkTo} onClick={reactRouterLinkOnClick}>
              {tileContent}
            </Link>
          )}
          {!reactRouterLinkOnClick && (
            <>
              <div
                role='button'
                className={classes.hyperlink}
                onClick={() => {
                  onClick && onClick();
                }}
                onKeyDown={e => {
                  if (e.key === 'Enter') {
                    onClick && onClick();
                  }
                }}
              >
                {tileContent}
              </div>
              {content}
            </>
          )}
        </Grid>
        {action}
      </Paper>
    </Grid>
  );
};

const useStyles = makeStyles<Theme, { iconColor: 'primary' | 'secondary' }>((theme: Theme) => ({
  mobileCard: {
    flexGrow: 1,
    maxWidth: '100%'
  },
  equalHeight: { height: '100%', border: '1px solid black' },
  cardContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    cursor: 'pointer',
    padding: theme.spacing(1),
    transition: '0.3s',
    border: `1px solid transparent`,
    '&:hover': {
      border: ({ iconColor }) => (iconColor === 'primary' ? `1px solid ${theme.palette.primary.dark}` : `1px solid ${theme.palette.secondary.dark}`),
      '& svg': {
        color: ({ iconColor }) => (iconColor === 'primary' ? theme.palette.primary.dark : theme.palette.secondary.dark)
      }
    },
    '& svg:not(:hover)': {
      transition: '0.3s ease-out'
    }
  },
  cardContent: {
    display: 'flex',
    alignItems: 'flex-start'
  },
  alignCenter: {
    alignItems: 'center'
  },
  hyperlink: {
    textDecoration: 'none',
    color: 'unset'
  },
  icon: {
    fontSize: '56px',
    marginRight: '12px',
    marginTop: theme.spacing(0.75),
    padding: theme.spacing(0.5),
    color: ({ iconColor }) => (iconColor === 'primary' ? theme.palette.primary.main : theme.palette.secondary.main),
    borderRadius: '50%',
    backgroundColor: ({ iconColor }) => (iconColor === 'primary' ? emphasize(theme.palette.primary.light, 0.5) : '#E2F7E1'),
    '&.no-margin': {
      marginTop: 0
    }
  },
  title: {
    wordBreak: 'break-word',
    lineHeight: 1.3,
    margin: theme.spacing(0.25, 0),
    fontSize: theme.typography.subtitle1.fontSize
  },
  titleOverflow: {
    display: 'inline-block',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    width: '90%'
  },
  progress: {
    position: 'absolute',
    top: 7,
    left: 2,
    zIndex: 1
  },
  header: {
    fontSize: theme.typography.subtitle1.fontSize
  },
  footer: {
    fontSize: theme.typography.subtitle1.fontSize
  }
}));
