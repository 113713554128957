import React from 'react';
// Components
import { Box, Grid, Typography } from '@material-ui/core';
import { Page } from '../components/layout/Page';
import { TimesheetWidget, ProgramIncrementCalendar } from '@shared/components/widgets';
import { DashboardCard } from '@src/clients/components/DashboardCard';

export const Home = () => {
  const colors = {
    complete: '#1976d2',
    managerComplete: '#ff9800',
    incomplete: '#f44336'
  };

  return (
    <Page title='Home' hideHeader isCard={false}>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={6}>
          <TimesheetWidget isCard colors={colors} />
        </Grid>
        <Grid item xs={12} md={6}>
          <ProgramIncrementCalendar stackVertically={false} />
        </Grid>
        <Grid item xs={12} md={6}>
          <DashboardCard setHeight={false} title='Our Mission Statement'>
            <Box mt={0.5}>
              <Typography variant='h2' component='h3'>
                <Typography component='span' variant='h2' color='secondary'>
                  Craft
                </Typography>{' '}
                great apps,{' '}
                <Typography component='span' variant='h2' color='secondary'>
                  solve
                </Typography>{' '}
                wicked problems and{' '}
                <Typography component='span' variant='h2' color='secondary'>
                  forge
                </Typography>{' '}
                passionate client relationships
              </Typography>
            </Box>
          </DashboardCard>
        </Grid>
      </Grid>
    </Page>
  );
};
