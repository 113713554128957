import React, { FC } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { useMedia } from 'react-use';
import clsx from 'clsx';
// Components
import { Button } from '@material-ui/core';
import { FilterList, ExpandLess, ExpandMore } from '@material-ui/icons';

interface IFilterButtonProps {
  active: boolean;
  onClick: () => void;
  responsive?: boolean;
  className?: string;
}

export const FilterButton: FC<IFilterButtonProps> = ({ active, onClick, responsive = true, className = '' }) => {
  const classes = useStyles();
  const isSmallMobile = useMedia('(max-width: 768px)');

  return (
    <Button
      size='small'
      color={active ? 'secondary' : 'primary'}
      disableRipple
      startIcon={<FilterList />}
      className={clsx(active ? classes.filtersButtonActive : classes.filtersButton, className)}
      onClick={onClick}
      data-testid='filters-button'
    >
      {isSmallMobile && responsive ? '' : 'Filters'} {active ? <ExpandLess /> : <ExpandMore />}
    </Button>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  filtersButton: {
    padding: 0,
    minWidth: 0,
    '&& .MuiButton-startIcon': {
      marginRight: 0
    },
    [theme.breakpoints.up('md')]: {
      minWidth: '64px',
      '&& .MuiButton-startIcon': {
        marginRight: theme.spacing(0.5)
      }
    }
  },
  filtersButtonActive: {
    padding: 0,
    minWidth: 0,
    borderBottom: '1px solid',
    borderRadius: 0,
    fontWeight: 700,
    '&& .MuiButton-startIcon': {
      marginRight: 0
    },
    [theme.breakpoints.up('md')]: {
      minWidth: '64px',
      '&& .MuiButton-startIcon': {
        marginRight: theme.spacing(0.5)
      }
    }
  }
}));
