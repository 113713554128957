import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { Add, Person, Announcement } from '@material-ui/icons';
import { Button, Theme, Typography } from '@material-ui/core';
import { useMedia } from 'react-use';
import { makeStyles } from '@material-ui/core/styles';

interface IManageUsersNav {
  selectedView: 'Users' | 'Requests';
  setExternalUserModalOpen: (val: boolean) => void;
  clientView: boolean;
}

/**
 * Nav controls for switching between Manage Users page and Manage Requests page.
 */
export const ManageUsersNav: FC<IManageUsersNav> = ({ selectedView, setExternalUserModalOpen, clientView }) => {
  const classes = useStyles();
  const isDesktop = useMedia('(min-width: 960px)');
  const usersLink = clientView ? '/clients/manage-users' : '/admin/manage-users';
  const requestsLink = clientView ? '/clients/manage-user-requests' : '/admin/manage-user-requests';

  return (
    <div className={classes.controls}>
      <Button
        color='primary'
        onClick={() => {
          setExternalUserModalOpen(true);
        }}
        aria-label='add-external-user'
        startIcon={<Add />}
      >
        {isDesktop ? 'Add External User' : 'Add'}
      </Button>

      <div className={classes.viewOptions}>
        <Typography display='inline' className={classes.viewText}>
          View By:
        </Typography>

        <Link to={usersLink} className={classes.viewLink}>
          <Button
            disableRipple
            size='small'
            color={selectedView === 'Users' ? 'secondary' : 'primary'}
            startIcon={<Person />}
            className={selectedView === 'Users' ? classes.viewButtonActive : undefined}
          >
            Users
          </Button>
        </Link>
        <>
          <Typography display='inline' className={classes.pipe}>
            |
          </Typography>
          <Link to={requestsLink} className={classes.viewLink}>
            <Button
              disableRipple
              size='small'
              color={selectedView === 'Requests' ? 'secondary' : 'primary'}
              startIcon={<Announcement />}
              className={selectedView === 'Requests' ? classes.viewButtonActive : undefined}
            >
              Requests
            </Button>
          </Link>
        </>
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  divider: {
    marginBottom: theme.spacing(0.5),
    marginTop: theme.spacing(0.5),
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(1),
      marginTop: theme.spacing(1)
    }
  },
  actionButton: {
    padding: 0
  },
  controls: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.up('md')]: {
      marginLeft: 'auto'
    }
  },
  viewOptions: {
    marginLeft: theme.spacing(0.5),
    [theme.breakpoints.up('md')]: {
      marginLeft: theme.spacing(1)
    }
  },
  viewLink: {
    textDecoration: 'none'
  },
  viewText: {
    marginRight: theme.spacing(0.5),
    whiteSpace: 'nowrap',
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'inline'
    }
  },
  pipe: {
    margin: '0 12px 0 4px',
    color: theme.palette.grey[300],
    [theme.breakpoints.up('md')]: {
      margin: '0 8px 0 4px'
    }
  },
  viewBy: {
    '@media (max-width: 1286px)': {
      alignSelf: 'flex-start'
    },
    flexWrap: 'nowrap',
    alignSelf: 'center',
    [theme.breakpoints.up('md')]: {
      marginTop: 0
    }
  },
  viewButtonActive: {
    borderBottom: '1px solid',
    borderRadius: 0,
    fontWeight: 700
  }
}));
