import React, { FC } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { withFormik, FormikProps } from 'formik';
import * as Yup from 'yup';
// Components
import Modal from '@material-ui/core/Modal';
import Fade from '@material-ui/core/Fade';
import Backdrop from '@material-ui/core/Backdrop';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import FormControl from '@material-ui/core/FormControl';
// Types
import { IBasicObject } from '@shared/types';

interface IAddRevenueCategoryProps {
  open: boolean;
  onClose: (event?: {}, reason?: 'backdropClick' | 'escapeKeyDown') => void;
  onSave: (name: string, callback: (error?: Error) => void) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    root: {
      width: 300,
      [theme.breakpoints.up('md')]: {
        width: 450
      }
    }
  })
);

const AddRevenueCategory: FC<IAddRevenueCategoryProps & FormikProps<IBasicObject>> = ({
  open,
  onClose,
  handleBlur,
  handleChange,
  resetForm,
  errors,
  touched,
  values,
  submitForm
}) => {
  const classes = useStyles();

  return (
    <Modal
      className={classes.modal}
      open={open}
      onClose={() => {
        resetForm();
        onClose();
      }}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500
      }}
    >
      <Fade in={open}>
        <Card className={classes.root}>
          <CardHeader title='Add New Revenue Category' titleTypographyProps={{ component: 'h4', variant: 'h4' }} />
          <CardContent>
            <GridList cellHeight='auto' cols={1} spacing={24}>
              <GridListTile cols={1}>
                <FormControl fullWidth>
                  <TextField
                    label='Name'
                    id='name-text-field'
                    defaultValue={values.name}
                    name='name'
                    error={(errors.name && touched.name) as boolean}
                    helperText={touched.name && errors.name}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                </FormControl>
              </GridListTile>
            </GridList>
          </CardContent>
          <CardActions>
            <Button variant='contained' color='secondary' onClick={submitForm}>
              Save
            </Button>
            <Button
              type='button'
              variant='contained'
              color='default'
              onClick={() => {
                resetForm();
                onClose();
              }}
            >
              Cancel
            </Button>
          </CardActions>
        </Card>
      </Fade>
    </Modal>
  );
};

const AddApplicationSchema = Yup.object().shape({
  name: Yup.string().nullable().required('Name is required')
});

export default withFormik<IAddRevenueCategoryProps, IBasicObject>({
  enableReinitialize: true,
  mapPropsToValues: () => {
    return {
      name: ''
    };
  },
  validationSchema: AddApplicationSchema,
  handleSubmit: (values, { resetForm, props: { onSave } }) => {
    onSave(values.name, error => {
      if (!error) {
        resetForm();
      }
    });
  }
})(AddRevenueCategory);
