import { makeStyles, Theme } from '@material-ui/core/styles';
import React, { FC } from 'react';
import ReactGA from 'react-ga';
// components
import { Grid, IconButton, Typography, ExpansionPanelSummary, ExpansionPanelDetails, ExpansionPanel } from '@material-ui/core';
import { ExpandMore, Link } from '@material-ui/icons';
import { GridLoader } from '@shared/components/loader';
import { UserStoryExpansion } from './UserStoryExpansion';
import { UserStoryViews } from '../containers/UserStories';
// constants
import { FEATURE_COLOR } from '@shared/constants';
// types
import { IUserStoryFeature, IUserStory, IUserStoryDetail, IAppState } from '@shared/types';
import { useSelector } from 'react-redux';

import { copyToClipboard } from '@shared/helpers';

interface IUserStoryFeaturesExpansionProps {
  allFeatureUserStories: IUserStoryFeature[];
  currentFeatureId: number | null;
  currentStoryId: number | null;
  handleFeatureClick: (id: number) => void;
  handleStoryClick: (id: number) => void;
  isLoadingStories: boolean;
  isLoadingStoryDetail: boolean;
  selectedStories?: IUserStoryDetail[];
  selectedView: UserStoryViews;
  userStories: IUserStory[];
  indent?: boolean;
  isDeepLink?: boolean;
}

export const UserStoryFeaturesExpansion: FC<IUserStoryFeaturesExpansionProps> = ({
  allFeatureUserStories,
  currentFeatureId,
  currentStoryId,
  handleFeatureClick,
  handleStoryClick,
  isLoadingStories,
  isLoadingStoryDetail,
  selectedStories,
  selectedView,
  userStories,
  indent = false,
  isDeepLink = false
}) => {
  const classes = userStoryEpicsFeaturesStoriesExpansionsStyles({ indent });
  const { selectedClient } = useSelector((state: IAppState) => state.extranet);
  const gaLabelClientValue: string = selectedClient ? ` | Client: ${selectedClient?.name}` : '';
  const storyCount = userStories?.length;
  const currentUrl = window.location.href.split('?')[0];
  const clientId = selectedClient?.clientId;

  let defaultExpanded: boolean = false;
  if (selectedView === 'Kanban' || isDeepLink) {
    defaultExpanded = true;
  }

  return (
    <>
      {allFeatureUserStories.map((feature, j) => {
        return (
          <div className={classes.wrapper} key={`${j}`}>
            <ExpansionPanel
              defaultExpanded={defaultExpanded}
              onChange={() => {
                ReactGA.event({
                  category: 'User Stories',
                  action: 'View Feature Details',
                  value: feature.id,
                  label: `View Selected Feature Details: ${feature.id}${gaLabelClientValue}`
                });
                handleFeatureClick(feature.id);
              }}
            >
              <ExpansionPanelSummary
                aria-controls={`user-feature-content-${j}`}
                classes={{ root: classes.panelSummaryFeatureRoot }}
                className={classes.userStoryTitle}
                expandIcon={<ExpandMore />}
                id={`user-feature-header-${j}`}
              >
                <div className={classes.panelSummaryWrapper}>
                  <div className={classes.panelSummaryWrapperFirstCol}>
                    <Grid container classes={{ root: classes.panelSummaryGridFirstCol }} wrap='nowrap'>
                      <Typography component='span' className={classes.epicTitle}>
                        Feature
                      </Typography>
                      <Typography component='span' className={classes.storyTitle}>
                        <Typography className={classes.id} component='span'>
                          {feature.id}
                        </Typography>
                        {feature.title}
                        <IconButton
                          size='small'
                          aria-label='copy'
                          onClick={e => {
                            e.stopPropagation();
                            copyToClipboard(`${currentUrl}?clientId=${clientId}&featureId=${currentFeatureId}`);
                          }}
                          className={classes.copyLinkButton}
                        >
                          <Link />
                        </IconButton>
                      </Typography>
                    </Grid>
                  </div>
                  <div className={classes.panelSummaryWrapperSecondCol}>
                    <Typography className={classes.projectTitle}>{feature.clientProjectName || ''}</Typography>
                    <Typography className={classes.sprintText}></Typography>
                    <Typography className={classes.stateText}>{feature.state}</Typography>
                  </div>
                </div>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails classes={{ root: classes.panelDetailsRoot }}>
                {isLoadingStories && currentFeatureId === feature.id && <GridLoader rows={2} />}
                {userStories.map((userStory: IUserStory, k: number) => {
                  const selectedStory = selectedStories && selectedStories.find(story => story.id === userStory.id);
                  return userStory.featureId === feature.id ? (
                    <UserStoryExpansion
                      storyCount={storyCount}
                      currentStoryId={currentStoryId}
                      key={`user-story-${k}`}
                      selectedStory={selectedStory}
                      userStory={userStory}
                      isLoadingStoryDetail={isLoadingStoryDetail}
                      handleStoryClick={handleStoryClick}
                      indent={indent}
                    />
                  ) : null;
                })}
                {!isLoadingStories && userStories.length === 0 && (
                  <Typography className={classes.noUserStories}>No user stories are available.</Typography>
                )}
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </div>
        );
      })}
    </>
  );
};

const userStoryEpicsFeaturesStoriesExpansionsStyles = makeStyles<Theme, { indent: boolean }>((theme: Theme) => ({
  wrapper: {
    '&& .MuiPaper-elevation1': {
      boxShadow: 'none',
      margin: 0,
      borderTop: `1px solid ${theme.palette.grey[300]}`
    }
  },
  userStoryTitle: {
    minHeight: '100%',
    '&& .MuiExpansionPanelSummary-content .Mui-expanded': {
      margin: '0'
    }
  },
  epicTitle: {
    color: theme.palette.grey[400],
    marginRight: theme.spacing(1)
  },
  projectTitle: {
    [theme.breakpoints.up('md')]: {
      width: theme.spacing(15)
    }
  },
  storyTitle: {
    fontWeight: 'bold'
  },
  panelSummaryFeatureRoot: {
    paddingLeft: `10px`,
    paddingRight: '10px',
    borderLeft: `3px solid ${FEATURE_COLOR}`,
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
    borderRight: `1px solid ${theme.palette.grey[300]}`,
    minHeight: '100% !important',
    '&& .MuiExpansionPanelSummary-content.Mui-expanded': {
      margin: '12px 0'
    }
  },
  sprintText: {
    width: theme.spacing(8),
    [theme.breakpoints.down('sm')]: {
      width: 'auto',
      position: 'absolute',
      left: '55px',
      top: '13px',
      borderLeft: `1px solid ${theme.palette.grey[300]}`,
      paddingLeft: '10px'
    }
  },
  stateText: {
    width: theme.spacing(6),
    textAlign: 'right'
  },
  panelDetailsRoot: {
    display: 'block',
    padding: 0,
    '&& .MuiPaper-elevation1': {
      boxShadow: 'none'
    }
  },
  panelSummaryWrapper: {
    display: 'flex',
    width: '100%',

    flexDirection: 'row',
    alignItems: 'center'
  },
  panelSummaryGridFirstCol: {
    alignItems: 'center'
  },
  panelSummaryWrapperFirstCol: {
    display: 'flex',

    alignItems: 'center',
    width: '70%',
    flexDirection: 'row'
  },
  panelSummaryWrapperSecondCol: {
    display: 'flex',
    alignItems: 'center',
    width: '30%',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    whiteSpace: 'nowrap'
  },
  margin: {
    margin: theme.spacing(1, 0)
  },
  marginBottom: {
    marginBottom: theme.spacing(0.5)
  },
  bold: {
    fontWeight: 700
  },
  noUserStories: {
    margin: theme.spacing(1)
  },
  id: {
    marginRight: '5px'
  },
  copyLinkButton: {
    transition: 'background-color 0.3s',
    '&:active': {
      backgroundColor: 'rgba(0, 0, 0, 0.2)'
    }
  }
}));
