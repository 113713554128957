import React, { FC } from 'react';
import { DashboardCard } from '@src/clients/components/DashboardCard';
import { ProgramIncrementContent } from './ProgramIncrementCalendarContent';

interface IProgramIncrementCalendarProps {
  /** Always render Program Increments on top of each other? */
  stackVertically: boolean;
}

export const ProgramIncrementCalendar: FC<IProgramIncrementCalendarProps> = ({ stackVertically }) => {
  return (
    <DashboardCard setHeight={false} title={'Calendar'}>
      <ProgramIncrementContent stackVertically={stackVertically} />
    </DashboardCard>
  );
};
