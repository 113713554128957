import React, { FC, ReactNode } from 'react';
import { Grid, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

interface ICardWrapperProps {
  contents: ReactNode;
  className?: string;
}

export const CardWrapper: FC<ICardWrapperProps> = ({ contents, className }) => {
  const classes = useStyles();

  return (
    <Paper elevation={1} className={`${classes.cardWrapper} ${className}`}>
      <Grid container spacing={1} alignItems='center'>
        {contents}
      </Grid>
    </Paper>
  );
};

const useStyles = makeStyles(theme => ({
  cardWrapper: {
    backgroundColor: '#EFF5FF',
    padding: '.5rem',
    display: 'flex',
    alignItems: 'center',
    wordBreak: 'break-word'
  },
}));
