import React, { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { authContext } from '../../adalConfig';

// Components
import { Grid, Typography, Button, Divider, Box } from '@material-ui/core';
import { Alert } from '@shared/components/alerts';
import { ExternalLink } from '@shared/components/link';
import { LoginForm } from '@shared/components/forms';
import SupportIcon from '@shared/assets/images/life-ring-icon.svg';
import MobileImage from '@shared/assets/images/mobile-login-image.jpg';
import DeskTopImage from '@shared/assets/images/desktop-login-image.png';
import { CustomCard } from '@shared/components/cards';
import DotsImage from '@shared/assets/images/Dots.svg';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';

// fetch
import { IUserProps, IAppState } from '@shared/types';

export const Login: FC = () => {
  const classes = useStyles();

  const user: IUserProps = useSelector((state: IAppState) => state.user);

  const [isSendingRequest, setIsSendingRequest] = useState<boolean>(false);
  const [requestSuccessful, setRequestSuccessful] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('There was an error processing your request. Please try again later.');
  const [errorOpen, setErrorOpen] = React.useState(false);

  const hasLoginButNoAccess = user && user.email && user.userAccess && !user.userAccess.ViewSite;

  return (
    <>
      <Grid container className={classes.root}>
        <Grid item xs={12} md={12} lg={6} xl={6} className={classes.imageContainer}>
          <div className={classes.image} />       
         </Grid>
        <Grid item xs={12} md={12} lg={6} xl={6} className={classes.formContainer}>
          <div className={classes.dots} />
          <div className={classes.formContent}>
            <Grid container spacing={1} justify='center' alignItems='center'>
              <Grid item xs={12}>
                <Typography variant='body1' component='h1' className={classes.heading}>
                  Welcome to <br /> My MercuryWorks!
                </Typography>
              </Grid>
              {!hasLoginButNoAccess && (
                <Grid item xs={12} md={8}>
                  <Button variant='contained' color='secondary' fullWidth className={classes.loginButton} onClick={() => authContext.login()}>
                    Login
                  </Button>
                </Grid>
              )}
              <Grid item xs={12} md={8}>
                <div className={classes.dividerContainer}>
                  <Divider className={classes.divider} />
                  <Typography className={classes.dividerText}>or</Typography>
                  <Divider className={classes.divider} />
                </div>
              </Grid>
              {!requestSuccessful ? (
                <>
                  <Grid item xs={12} md={8}>
                    <Typography variant='subtitle1' className={classes.subtext}>
                      {hasLoginButNoAccess
                        ? 'Your account has been deactivated. Fill out the access request form below to have it reinstated.'
                        : "Don't have an account? Request access below."}
                    </Typography>
                  </Grid>
                  <LoginForm
                    setIsSendingRequest={setIsSendingRequest}
                    setRequestSuccessful={setRequestSuccessful}
                    isSendingRequest={isSendingRequest}
                    setErrorMessage={setErrorMessage}
                    setErrorOpen={setErrorOpen}
                    requestSuccessful={requestSuccessful}
                  />
                </>
              ) : (
                <Grid item xs={12} md={8}>
                  <Box className={classes.requestSubmittedBody}>
                    <CustomCard
                      iconColor='#66BB6A'
                      backgroundColor='#EAF7E1'
                      borderRadius='16px'
                      titleColor='#66BB6A'
                      contentColor='#4F4F4F'
                      titleFontSize='24px'
                      contentFontSize='16px'
                      icon={<CheckCircleOutlineIcon className={classes.customIconWeight} style={{ fontSize: 50 }} />}
                      title='Request Submitted'
                      content='Thank you for submitting your access request. This will be routed to our support team. Once approved, you will receive an email confirming your access and providing instructions on logging in.'
                    />
                  </Box>
                </Grid>
              )}
            </Grid>
          </div>
          <div className={classes.supportLinkContainer}>
            <ExternalLink
              to='https://support.mercuryworks.com/hc/en-us'
              type='primary'
              target='_blank'
              title='Go to MercuryWorks'
              icon={<img src={SupportIcon} alt='Support Icon' />}
              fontSize={14}
              textDecoration='underline'
              fontWeight={600}
            >
              Get Support
            </ExternalLink>
          </div>
        </Grid>
        <Alert open={errorOpen} onClose={setErrorOpen} type='error' text={errorMessage} />
      </Grid>
    </>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    minHeight: '100vh',
    display: 'flex',
    overflowY: 'auto',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'row',
      height: 'auto',
    },
  },
  customIconWeight: {
    '& path': {
      strokeWidth: 0,
      stroke: 'currentColor'
    }
  },
  dots: {
    position: 'absolute',
    top: 0,
    right: 0,
    width: '15.625rem',
    height: '9.375rem',
    backgroundImage: `url(${DotsImage})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    [theme.breakpoints.down('sm')]: {
      width: '8rem',
      height: '5rem'
    }
  },
  imageContainer: {
    width: '100%',
    minHeight: '100vh',
    [theme.breakpoints.down('md')]: {
      minHeight: 'auto',
    },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
  },
  image: {
    width: '100%',
    height: '100%',
    backgroundImage: `url(${DeskTopImage})`,
    backgroundSize: 'cover',
    backgroundPosition: 'top',
    backgroundRepeat: 'no-repeat',
    [theme.breakpoints.down('md')]: {
      backgroundImage: `url(${MobileImage})`,
      minHeight: '42vh',
    },
    [theme.breakpoints.down('sm')]: {
      minHeight: '32vh',
    },
    '@media (min-device-width: 600px) and (orientation: landscape)': {
      minHeight: '100vh',
    },
    '@media (min-device-width: 1180px) and (max-device-width: 1180px) and (orientation: landscape), (min-device-width: 1366px) and (max-device-width: 1366px) and (orientation: landscape)': {
      backgroundPosition: 'right'
    },
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '100%',
    position: 'relative',
    padding: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      justifyContent: 'inherit',
      minHeight: 'auto',
    }
  },
  formContent: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'flex-start',
      paddingBottom: theme.spacing(3),
    },
  },
  dividerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: theme.spacing(1, 0),
    width: '100%'
  },
  divider: {
    flexGrow: 1,
    height: 1,
    backgroundColor: theme.palette.grey[300],
    margin: '0',
    width: 'auto'
  },
  dividerText: {
    color: theme.palette.grey[500],
    fontWeight: 'bold',
    margin: '0 1rem'
  },
  heading: {
    fontSize: '3rem',
    lineHeight: '3.25rem',
    textAlign: 'center',
    [theme.breakpoints.down('md')]: {
      paddingTop: theme.spacing(2)
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '2.25rem',
      lineHeight: `2.25rem`,
      paddingTop: theme.spacing(1)
    }
  },
  subtext: {
    textAlign: 'center',
    marginBottom: theme.spacing(0.5)
  },
  loginButton: {
    borderRadius: '0.5rem',
    width: '100%',
    marginTop: theme.spacing(2),
    padding: theme.spacing(1),
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(1)
    }
  },
  requestSubmittedHeading: {
    fontSize: theme.spacing(2),
    lineHeight: `${theme.spacing(1.5)}px`,
    textAlign: 'center',
    marginBottom: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      textAlign: 'left',
      lineHeight: `${theme.spacing(2.5)}px`
    }
  },
  requestSubmittedBody: {
    textAlign: 'center',
    [theme.breakpoints.up('md')]: {
      textAlign: 'left'
    }
  },
  supportLinkContainer: {
    width: '100%',
    textAlign: 'center',
    marginTop: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(0.5)
    }
  }
}));