import { makeStyles, Theme } from '@material-ui/core/styles';
import { format } from 'date-fns';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMedia } from 'react-use';
import stripHtml from 'string-strip-html';
import * as XLSX from 'xlsx';
import ReactGA from 'react-ga';
import { useLocation, useHistory } from 'react-router-dom';
// components
import { Grid, MenuItem, TextField, Tooltip, Typography, Button, InputAdornment, IconButton } from '@material-ui/core';
import { ViewWeek, Print, ViewHeadline, Close } from '@material-ui/icons';
import { UserStoryGridView } from '../components/UserStoryGridView';
import { SkeletonLoader, GridLoader, LoaderWrapper } from '@shared/components/loader';
import { UserStoryKanbanView } from '../components/UserStoryKanbanView';
import { UserStoriesSearchGridView } from '../components/UserStorySearchGridView';
import { Empty } from '@shared/components/empty';
import { Toast } from '@shared/components/toast';
import { Page } from '@shared/components/layout';
import { UserStoryDetail } from '../components/UserStoryDetail';
import { Modal } from '@shared/components/modals/Modal';
// fetch
import {
  getExtranetProjects,
  getExtranetUserEpics,
  getExtranetUserFeatures,
  getExtranetUserStories,
  getExtranetUserStoryDetail,
  searchWorkItems,
  getStoryComments,
  getExtranetClientMentionLinks,
  getClientProjects,
  getEpics,
  getFeatures,
  getStories
} from '@shared/fetch';
// helpers
import { sanitizeString, shortFileDateTime, formatHours, formatSprint, formatWorkItemType, mapWorkItemStates } from '@shared/helpers';
// redux
import { setClientSwitcherDisabled, setSelectedProjectId, setClientMentionLinks, setExtranetClient } from '@shared/redux/actions';
// types
import {
  IUserProject,
  IUserEpic,
  IUserStoryFeature,
  IExtranetSprintItem,
  IUserStory,
  IUserStoryDetail,
  IAppState,
  IModifiedSearchValue,
  WorkItemTypes,
  IExport,
  WorkItemStatuses,
  IExtranetSprintList
} from '@shared/types';
// other
import { columns } from './Columns';
import { SprintSwitcher } from '@shared/components/switcher';
import { ExportButton } from '@shared/components/buttons';

const typeOptions = [
  {
    label: 'Epic',
    value: 'Epic'
  },
  {
    label: 'Feature',
    value: 'Feature'
  },
  {
    label: 'Story',
    value: 'Story'
  }
];

const DEFAULT_PAGE = 0;
const DEFAULT_PAGE_SIZE = 10;

export type UserStoryViews = 'Kanban' | 'Grid';

export const UserStories = () => {
  const history = useHistory();
  const location = useLocation();
  // styles
  const classes = userStoriesStyles();
  // check for workitem query param
  const query = new URLSearchParams(location.search);
  // state
  // search state
  const [isSearching, setSearching] = useState<boolean>(false);
  const [searchResults, setSearchResults] = useState<IModifiedSearchValue[] | null>(null);
  const [searchInputValue, setSearchInput] = useState<string>('');
  const [searchedInputValue, setSearchedValue] = useState<string>('');
  // sprint state
  const [selectedSprint, setSelectedSprint] = useState<IExtranetSprintItem | null>(null);
  const [sprintOptions, setSprintOptions] = useState<IExtranetSprintList | null>(null);

  const [isLoadingProjects, setLoadingProjects] = useState<boolean>(true);
  const [isLoadingEpics, setLoadingEpics] = useState<boolean>(false);
  const [isLoadingFeatures, setLoadingFeatures] = useState<boolean>(false);
  const [isLoadingStories, setLoadingStories] = useState<boolean>(true);
  // set this true by default if we are loading the story directly from a link
  const [isLoadingStoryDetail, setLoadingStoryDetail] = useState<boolean>(query && query.get('workitem') ? true : false);

  const [epics, setEpics] = useState<IUserEpic[] | null>(null);
  const [features, setFeatures] = useState<IUserStoryFeature[]>([]);
  const [projects, setProjects] = useState<IUserProject[] | null>(null);
  const [project, setProject] = useState<IUserProject | null>(null);
  const [projectId, setProjectId] = useState<number | string>('');
  const [selectedStories, setSelectedStories] = useState<IUserStoryDetail[]>([]);
  const [selectedUserStories, setSelectedUserStories] = useState<IUserStory[]>([]);
  const [allUserStories, setAllUserStories] = useState<IUserStory[] | null>(null);
  const [allFeatureUserStories, setAllFeatureUserStories] = useState<IUserStoryFeature[]>([]);
  const [currentEpicId, setCurrentEpicId] = useState<number | null>(null);
  const [currentFeatureId, setCurrentFeatureId] = useState<number | null>(null);
  const [currentStoryId, setCurrentStoryId] = useState<number | null>((query && (Number(query.get('workitem')) as number)) || null);
  const [page, setPage] = useState<number>(DEFAULT_PAGE);
  const [perPage, setPerPage] = useState<number>(DEFAULT_PAGE_SIZE);
  const [recordCount, setRecordCount] = useState<number>(0);
  const [selectedView, setSelectedView] = useState<UserStoryViews>('Kanban');
  const [isShowingFilters, showFilters] = useState(false);
  const [selectedStateFilter, setStateFilter] = useState<WorkItemStatuses | ''>('');
  const [selectedTypeFilter, setTypeFilter] = useState<WorkItemTypes | null>('Story');
  const [showEpicModal, setShowEpicModal] = useState(false);
  const [showFeatureModal, setShowFeatureModal] = useState(false);
  const [showStoryModal, setShowStoryModal] = useState((query && !!query.get('workitem')) || false);
  const [isExporting, setExporting] = useState(false);
  const [exportProgress, setExportProgress] = useState<string>('');
  const [isShowingMore, setShowingMore] = useState(false);
  const [error, setError] = useState<Error | null>(null);
  const [isUserStoryNotAuthorized, setUserStoryNotAuthorized] = useState<boolean>(false);
  const [effort, setEffort] = useState<{ total: number | undefined; remaining: number | undefined }>({
    total: 0,
    remaining: 0
  });
  const [printUserStory, setPrintUserStory] = useState(false);
  const [showSprintSelector, setShowSprintSelector] = useState(true);

  // breakpoints
  const isMobile = useMedia('(max-width: 960px)');

  // redux
  const dispatch = useDispatch();
  const { selectedClient, clients } = useSelector((state: IAppState) => state.extranet);
  const { selectedProjectId } = useSelector((state: IAppState) => state.clients);
  const gaLabelClientValue: string = selectedClient ? ` | Client: ${selectedClient?.name}` : '';

  const [expanded, setExpanded] = useState<{ [id: number]: boolean }>({});
  // UserStoryDetail fires this if clicking the Print button in Grid view
  // Allows UserStoryDetail instances to print itself from Grid views
  useEffect(() => {
    const selectedStoryId = new URLSearchParams(location.search).get('workitem');
    if (selectedView === 'Grid') {
      // User clicked Print on a UserStoryDetail in non-modal mode (any Grid
      // view), open the modal so it can be printed. Close the modal after
      // printed - when the selectedStoryId is removed from url params.
      setShowStoryModal(!!selectedStoryId);

      if (selectedStoryId) {
        setPrintUserStory(true);
        setCurrentStoryId(+selectedStoryId);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  // Show/hide Sprint Selector based on client planning type
  useEffect(() => {
    if (!selectedClient) {
      return;
    }
    if (selectedClient.clientPlanningMode && selectedClient.clientPlanningMode !== 'Scrum') {
      setShowSprintSelector(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedClient]);

  useEffect(() => {
    fetchClientProjects();
    fetchMentionLinks();
    // close the filters if you switch clients when in Grid view
    if (isShowingFilters && selectedView === 'Grid') {
      showFilters(false);
    }
    if (selectedView === 'Kanban') {
      setPage(DEFAULT_PAGE);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedClient]);

  // fetch Epics, Features, or Stories
  useEffect(() => {
    if (selectedSprint && selectedClient && !searchResults) {
      if (selectedView === 'Grid' && (!selectedTypeFilter || selectedTypeFilter === 'Epic')) {
        fetchEpicsAndFeatures();
      }
      if (selectedView === 'Grid' && selectedTypeFilter === 'Feature') {
        fetchAllFeatureUserStories();
      }
      if (selectedView === 'Kanban' && selectedTypeFilter === 'Epic') {
        fetchEpics();
      }
      if (selectedView === 'Kanban' && selectedTypeFilter === 'Feature') {
        fetchFeatures();
      }
      if (selectedTypeFilter === 'Story') {
        fetchAllUserStories();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, perPage, project, selectedClient, selectedSprint, selectedStateFilter, selectedTypeFilter]);

  // fetch features for an epic
  useEffect(() => {
    if (currentEpicId) {
      fetchFeatures(currentEpicId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentEpicId]);

  // fetch all the stories in a feature
  useEffect(() => {
    if (currentFeatureId) {
      fetchUserStories();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentFeatureId]);

  // fetch the story detail
  useEffect(() => {
    // only make the call if we haven't previously loaded the info
    const currentLoadedStory = selectedStories.find(story => story.id === currentStoryId);
    if (currentStoryId && !currentLoadedStory && selectedClient) {
      fetchUserStoryDetail();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStoryId, selectedClient]);

  // search
  useEffect(() => {
    if (searchInputValue) {
      handleSearch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, perPage, selectedStateFilter, selectedTypeFilter]);

  // update the UI when changing between Grid/Kanban views
  useEffect(() => {
    setCurrentEpicId(null);
    setCurrentFeatureId(null);
    if (!query || (query && !query.get('workitem'))) {
      setCurrentStoryId(null);
    }

    if (selectedView === 'Kanban') {
      showFilters(true);
      if (selectedTypeFilter === 'Story') {
        fetchAllUserStories();
      } else {
        setTypeFilter('Story');
      }
    } else {
      showFilters(false);
      setTypeFilter(null);
    }

    if (selectedProjectId) {
      setStateFilter('New');
      showFilters(true);
    } else {
      setStateFilter('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedView]);

  useEffect(() => {
    // reset selectedProjectId if it's set and we navigate away from the page
    // to ensure we don't go back into a single project view when the user comes back
    return history.listen(location => {
      if (location.pathname !== '/clients/board' && selectedProjectId) {
        dispatch(setSelectedProjectId(null));
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);

  // api calls
  const fetchClientProjects = async () => {
    if (selectedClient) {
      setLoadingProjects(true);
      setEpics(null);
      setAllFeatureUserStories([]);
      setFeatures([]);
      setAllUserStories([]);
      setSearchedValue('');
      setSearchInput('');
      setSearchResults(null);
      setPage(DEFAULT_PAGE);
      setPerPage(DEFAULT_PAGE_SIZE);
      if (!query || (query && !query.get('workitem'))) {
        setCurrentStoryId(null);
      }
      try {
        const ClientId = selectedClient.clientId;
        const [extranetProjects, clientProjects] = await Promise.all([getExtranetProjects(ClientId), getClientProjects({ ClientId })]);
        // Remove user projects that should not be shown in the client portal
        const showableProjects = extranetProjects.filter(
          x => clientProjects.find(cp => cp.clientProjectId === x.clientProjectId)?.shouldShowInClientPortal ?? true
        );

        setProjects(showableProjects);
        const foundProject = selectedProjectId ? extranetProjects.find(project => project.clientProjectId === selectedProjectId) : null;
        setProject(foundProject ?? null);
      } catch (error) {
        console.error(error);
      } finally {
        setLoadingProjects(false);
      }
    }
  };
  const baseFilters = {
    projectIterationUuid: selectedSprint && selectedSprint.projectIterationUuid ? selectedSprint.projectIterationUuid : undefined,
    hashTags: project && project.hashTag ? ([project.hashTag] as string[]) : undefined,
    state: selectedStateFilter || undefined,
    page: page + 1,
    perPage,
    projectId: project?.clientProjectId ?? undefined
  };

  // used only by Grid view
  const fetchEpicsAndFeatures = async () => {
    if (selectedClient) {
      setEpics(null);
      setFeatures([]);
      setPage(DEFAULT_PAGE);
      if (!isLoadingEpics) {
        setLoadingEpics(true);
      }
      setAllFeatureUserStories([]);
      setAllUserStories([]);
      setPerPage(DEFAULT_PAGE_SIZE);
      if (!query || (query && !query.get('workitem'))) {
        setCurrentStoryId(null);
      }
      try {
        const res = await getEpics(selectedClient.clientId, { 
          ...baseFilters,
          projectiterationUuid: baseFilters.projectIterationUuid 
        });
        const featurePromises = await getFeatures(selectedClient.clientId, {
          ...baseFilters,
          projectiterationUuid: baseFilters.projectIterationUuid
        });

        const featureRes = featurePromises;

        setEpics(res.records);
        setRecordCount(res.totalRecordCount);

        const featureRecords = featureRes.records;
        setFeatures(featureRecords);
      } catch (error) {
        console.error(error);
      } finally {
        setLoadingEpics(false);
      }
    }
  };

  // used only by Kanban
  const fetchEpics = async () => {
    if (selectedClient) {
      try {
        setAllUserStories([]);
        setCurrentEpicId(null);
        if (!query || (query && !query.get('workitem'))) {
          setCurrentStoryId(null);
        }
        setCurrentFeatureId(null);
        setFeatures([]);
        setLoadingEpics(true);
        const result = await getEpics(selectedClient.clientId, { 
          ...baseFilters,
          projectiterationUuid: baseFilters.projectIterationUuid 
        });
        setEpics([...(isShowingMore && epics ? epics : []), ...result.records]);
        setEffort({
          total: result?.totalEffort,
          remaining: result?.totalRemainingWork
        });
        setRecordCount(result.totalRecordCount);
      } catch (error) {
        console.error(error);
        setEpics([]);
        setRecordCount(0);
      } finally {
        setLoadingEpics(false);
        setShowingMore(false);
      }
    }
  };

  const fetchFeatures = async (epicId?: number) => {
    if (selectedClient) {
      try {
        // only reset epics if we're not loading an epics' children features
        if (!epicId) {
          setCurrentEpicId(null);
          setEpics([]);
        }
        setAllUserStories([]);
        if (!query || (query && !query.get('workitem'))) {
          setCurrentStoryId(null);
        }
        setCurrentFeatureId(null);
        setLoadingFeatures(true);
        const result = await getFeatures(selectedClient.clientId, {
          ...baseFilters,
          projectiterationUuid: baseFilters.projectIterationUuid
        });
        setFeatures([...(isShowingMore ? features : []), ...result.records]);
        setEffort({
          total: result?.totalEffort,
          remaining: result?.totalRemainingWork
        });
        setRecordCount(result.totalRecordCount);
      } catch (error) {
        console.error(error);
        setFeatures([]);
        setRecordCount(0);
      } finally {
        setLoadingFeatures(false);
        setShowingMore(false);
      }
    }
  };

  const fetchUserStories = async () => {
    if (currentFeatureId && selectedClient) {
      setSelectedUserStories([]);
      setLoadingStories(true);
      // reset effort
      setEffort({
        total: 0,
        remaining: 0
      });
      try {
        const res = await getStories(selectedClient.clientId, {
          ...baseFilters,
          projectiterationUuid: baseFilters.projectIterationUuid
        });
        setEffort({
          total: res.totalEffort,
          remaining: res?.totalRemainingWork
        });

        setSelectedUserStories([...res.records]);
      } catch (error) {
        console.error(error);
      } finally {
        setLoadingStories(false);
      }
    }
  };

  // used by both Grid and Kanban
  const fetchAllUserStories = async () => {
    if (selectedClient && selectedSprint) {
      // if we're showing more on the Kanban view, we don't want to reset these
      if (!isShowingMore) {
        setAllUserStories(null);
      }
      setAllFeatureUserStories([]);
      if (!query || (query && !query.get('workitem'))) {
        setCurrentStoryId(null);
      }
      setEpics(null);
      setFeatures([]);
      setLoadingStories(true);

      // reset effort
      setEffort({
        total: 0,
        remaining: 0
      });

      try {
        const res = await getStories(selectedClient.clientId, {
          ...baseFilters,
          projectiterationUuid: baseFilters.projectIterationUuid
        });
        setEffort({
          total: res.totalEffort,
          remaining: res?.totalRemainingWork
        });

        setAllUserStories([...(isShowingMore && allUserStories ? allUserStories : []), ...res.records]);
        setRecordCount(res.totalRecordCount ?? res.records.length);
      } catch (error) {
        console.error(error);
        setAllUserStories(null);
        setRecordCount(0);
      } finally {
        setLoadingStories(false);
        setShowingMore(false);
      }
    }
  };

  // used only by Grid view
  const fetchAllFeatureUserStories = async () => {
    if (selectedClient) {
      setEpics(null);
      setAllFeatureUserStories([]);
      setLoadingStories(true);
      setRecordCount(0);
      try {
        const res = await getExtranetUserFeatures(selectedClient.clientId, baseFilters);
        const userStoryPromises = res.records.map(async feature => {
          return await getExtranetUserStories(selectedClient.clientId, {
            ...baseFilters,
            featureId: feature.id
          });
        });
        const userStoryRes = await Promise.all(userStoryPromises);
        setAllFeatureUserStories(res.records);
        setRecordCount(res.totalRecordCount ?? res.records.length);
        userStoryRes.map(stories => setSelectedUserStories([...selectedUserStories, ...stories.records]));
      } catch (error) {
        console.error(error);
      } finally {
        setLoadingStories(false);
      }
    }
  };

  // used by both Grid and Kanban
  const fetchUserStoryDetail = async () => {
    if (currentStoryId && selectedClient) {
      if (!isLoadingStoryDetail) {
        setLoadingStoryDetail(true);
      }
      if (isUserStoryNotAuthorized) {
        setUserStoryNotAuthorized(false);
      }
      try {
        const res = await getExtranetUserStoryDetail(currentStoryId);
        // the user is opening a user story directly, so we want to check that and then set the dropdown extranet client to the correct one
        if (res.clientId !== selectedClient.clientId) {
          dispatch(setExtranetClient(clients.find(client => client.clientId === Number(res.clientId))));
        }
        setSelectedStories([...selectedStories, res]);
      } catch (error) {
        // @ts-ignore
        if (error.message && error.message.includes('500')) {
          setUserStoryNotAuthorized(true);
        }
        console.error(error);
      } finally {
        setLoadingStoryDetail(false);
      }
    }
  };

  const fetchMentionLinks = async () => {
    const { clientId } = selectedClient || {};
    if (!clientId) {
      return;
    }

    try {
      const mentionLinksRes = await getExtranetClientMentionLinks(clientId);
      dispatch(setClientMentionLinks(mentionLinksRes));
    } catch (error) {
      console.log(error);
    }
  };

  const handleSearch = async () => {
    ReactGA.event({
      category: 'User Stories',
      action: 'Execute Search',
      label: `Search: ${searchInputValue}${gaLabelClientValue}`
    });
    if (selectedClient) {
      setSearching(true);
      if (!isShowingMore) {
        setSearchResults(null);
      }
      setAllUserStories(null);
      setEpics(null);
      setAllFeatureUserStories([]);
      setFeatures([]);
      setSelectedSprint(null);
      try {
        const res = await searchWorkItems(selectedClient.clientId, {
          query: searchInputValue,
          page: page + 1,
          pageSize: perPage,
          type: selectedTypeFilter && selectedTypeFilter === 'Story' ? ['Story', 'Bug'] : selectedTypeFilter ? [selectedTypeFilter] : undefined,
          state: selectedStateFilter ? [selectedStateFilter] : undefined
        });
        const results: IModifiedSearchValue[] = res.values.map(value => ({
          ...value,
          clientProjectName: value.project || null,
          id: Number(value.id)
        }));
        setSearchResults([...(isShowingMore && searchResults ? searchResults : []), ...results]);
        setRecordCount(res.count ?? res.values.length);
        setSearchedValue(searchInputValue);
      } catch (error) {
        console.error(error);
      } finally {
        setSearching(false);
        setShowingMore(false);
      }
    }
  };

  const handleClearSearch = () => {
    // shared
    setPage(DEFAULT_PAGE);
    setPerPage(DEFAULT_PAGE_SIZE);
    setRecordCount(0);
    setSearchedValue('');
    setSearching(false);
    setSearchInput('');
    setSearchResults(null);
    setShowingMore(false);
    if (sprintOptions) {
      setSelectedSprint(sprintOptions.currentSprint);
    }
    if (selectedTypeFilter === 'Story') {
      setLoadingStories(true);
    }

    // kanban
    if (selectedView === 'Kanban') {
      if (selectedTypeFilter === 'Epic') {
        setLoadingEpics(true);
      }
      if (selectedTypeFilter === 'Feature') {
        setLoadingFeatures(true);
      }
    } else {
      // grid
      if (!selectedTypeFilter || selectedTypeFilter === 'Epic') {
        setLoadingEpics(true);
      }
      if (selectedTypeFilter === 'Feature') {
        setLoadingStories(true);
      }
    }
  };

  const fetchAndFormatStoryComments = async (id: number): Promise<string> => {
    try {
      const result = await getStoryComments(id);
      const comments: string =
        result && result.length > 0
          ? result
              .map(comment => {
                return `Comment By: ${comment.createdBy.displayName} - ${format(new Date(comment.createdDate), 'cccc')} - ${sanitizeString(
                  stripHtml(comment.text)
                )}`;
              })
              .join(' | ')
          : '';
      return Promise.resolve(comments);
    } catch (error) {
      return Promise.reject(error);
    }
  };

  // used by both Grid and Kanban
  const exportEpics = async (clientId: number): Promise<{ exportedEpics: IExport[]; _epics: IUserEpic[] }> => {
    try {
      const rows: IExport[] = [];

      let _epics: IUserEpic[] = [];
      // only fetch epics if there's pagination
      if (epics && epics.length >= recordCount) {
        _epics = epics;
      } else if (epics && epics.length > 0) {
        setExportProgress(`Fetching all epics`);
        const result = await getExtranetUserEpics(clientId, { ...baseFilters, page: 1, perPage: 9999 });
        _epics = result.records;
      }

      _epics.map((epic, index) => {
        setExportProgress(`Exporting epic ${index + 1}/${_epics.length}`);

        const row: IExport = {};

        columns.map(column => {
          if (
            column.id === 'parentId' ||
            column.id === 'effort' ||
            column.id === 'sprint' ||
            column.id === 'acceptanceCriteria' ||
            column.id === 'comments'
          ) {
            row[column.title] = '';
          } else if (column.id === 'workItemType') {
            row[column.title] = 'Epic';
          } else if (column.id === 'project') {
            row[column.title] = sanitizeString(epic.clientProjectName);
          } else if (column.id === 'state') {
            row[column.title] = mapWorkItemStates(epic.state);
          } else {
            const other = epic[column.id];
            row[column.title] = other ? sanitizeString(other.toString()) : '';
          }
        });

        rows.push(row);
      });

      setExportProgress('');

      return Promise.resolve({ exportedEpics: rows, _epics });
    } catch (error) {
      console.error(error);
      return Promise.reject(error);
    }
  };

  // used by both Grid and Kanban
  const exportFeatures = async (clientId: number, epic?: IUserEpic): Promise<{ exportedFeatures: IExport[]; _features: IUserStoryFeature[] }> => {
    try {
      const rows: IExport[] = [];

      let _features: IUserStoryFeature[] = [];
      // only fetch features if there's pagination
      if ((epic && epics && epics.length >= recordCount) || (!epic && features.length >= recordCount)) {
        _features = features;
      } else {
        setExportProgress(`Fetching all features${epic ? ` for epic "${epic.title}"` : ''}`);
        // exporting features ignores current pagination
        const result = await getExtranetUserFeatures(clientId, { ...baseFilters, epicId: (epic && epic.id) || undefined, page: 1, perPage: 9999 });
        _features = result.records;
      }

      _features.map((feature, index) => {
        setExportProgress(`Exporting feature ${index + 1}/${_features.length}`);

        const row: IExport = {};

        columns.map(column => {
          if (column.id === 'acceptanceCriteria' || column.id === 'comments' || column.id === 'effort' || column.id === 'sprint') {
            row[column.title] = '';
          } else if (column.id === 'parentId') {
            row[column.title] = feature.epicId ? feature.epicId : '';
          } else if (column.id === 'workItemType') {
            row[column.title] = 'Feature';
          } else if (column.id === 'project') {
            row[column.title] = sanitizeString(feature.clientProjectName);
          } else if (column.id === 'state') {
            row[column.title] = mapWorkItemStates(feature.state);
          } else {
            const other = feature[column.id];
            row[column.title] = other ? sanitizeString(other.toString()) : '';
          }
        });

        rows.push(row);
      });

      setExportProgress('');

      return Promise.resolve({ exportedFeatures: rows, _features });
    } catch (error) {
      console.error(error);
      return Promise.reject(error);
    }
  };

  // used by both Grid and Kanban
  const exportStories = async (clientId: number, feature?: IUserStoryFeature): Promise<{ exportedStories: IExport[]; _stories: IUserStory[] }> => {
    try {
      const rows: IExport[] = [];

      let _stories: IUserStory[] = [];
      // only fetch stories if there's pagination
      if (!feature && allUserStories && allUserStories.length >= recordCount) {
        _stories = allUserStories;
      } else {
        setExportProgress(`Fetching all stories${feature ? ` for feature "${feature.title}"` : ''}`);
        // exporting stories ignores current pagination
        const result = await getExtranetUserStories(clientId, {
          ...baseFilters,
          featureId: (feature && feature.id) || undefined,
          page: 1,
          perPage: 9999
        });
        _stories = result.records;
      }

      // using a for loop here instead of Promise.all because this needs to run sequentially in order not to hit ADO rate limiting
      for (let index = 0; index < _stories.length; index++) {
        setExportProgress(`Exporting story ${index + 1}/${_stories.length}`);

        const story = _stories[index];
        story.comments = await fetchAndFormatStoryComments(story.id);

        const row: IExport = {};

        columns.map(column => {
          if (column.id === 'parentId') {
            row[column.title] = story.featureId || '';
          } else if (column.id === 'workItemType') {
            row[column.title] = story.workItemType === 'Bug' ? 'Bug' : 'Story';
          } else if (column.id === 'project') {
            row[column.title] = sanitizeString(story.clientProjectName);
          } else if (column.id === 'sprint') {
            row[column.title] = formatSprint(story.sprintContainer, story.sprintName);
          } else if (column.id === 'effort') {
            row[column.title] = story.effort ? formatHours(story.effort) : '';
          } else if (column.id === 'acceptanceCriteria') {
            row[column.title] = stripHtml(sanitizeString(story.acceptanceCriteria));
          } else if (column.id === 'comments') {
            row[column.title] = story.comments || '';
          } else if (column.id === 'state') {
            row[column.title] = mapWorkItemStates(story.state);
          } else {
            const other = story[column.id];
            row[column.title] = sanitizeString(other ? other.toString() : '');
          }
        });

        rows.push(row);
      }

      setExportProgress('');

      return Promise.resolve({ exportedStories: rows, _stories });
    } catch (error) {
      console.error(error);
      return Promise.reject(error);
    }
  };

  // used by both Grid and Kanban
  const exportDownload = (clientName: string, rows: IExport[], sprintName?: string) => {
    const worksheet = XLSX.utils.json_to_sheet(rows);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, sprintName ? formatSprint(undefined, undefined, sprintName) : 'No Sprint Name');
    const workbookName: string = `UserStories - ${sanitizeString(clientName)} - ${shortFileDateTime(new Date())}.xlsx`;
    XLSX.writeFile(workbook, workbookName);
  };

  const exportGridToExcel = async () => {
    if (selectedClient) {
      try {
        setExporting(true);
        dispatch(setClientSwitcherDisabled(true));

        let rows: IExport[] = [];

        if (!selectedTypeFilter || selectedTypeFilter === 'Epic') {
          const { exportedEpics, _epics } = await exportEpics(selectedClient.clientId);
          rows = [...exportedEpics];

          let _epicFeatures: IUserStoryFeature[] = [];
          for (let index = 0; index < _epics.length; index++) {
            const epic = _epics[index];
            const { exportedFeatures, _features } = await exportFeatures(selectedClient.clientId, epic);
            _epicFeatures = [..._epicFeatures, ..._features];
            rows = [...rows, ...exportedFeatures];
          }

          for (let index = 0; index < _epicFeatures.length; index++) {
            const feature = _epicFeatures[index];
            const { exportedStories } = await exportStories(selectedClient.clientId, feature);
            rows = [...rows, ...exportedStories];
          }
        }

        if (selectedTypeFilter === 'Feature') {
          const { exportedFeatures, _features } = await exportFeatures(selectedClient.clientId);
          rows = [...rows, ...exportedFeatures];

          for (let index = 0; index < _features.length; index++) {
            const feature = _features[index];
            const { exportedStories } = await exportStories(selectedClient.clientId, feature);
            rows = [...rows, ...exportedStories];
          }
        }

        if (selectedTypeFilter === 'Story') {
          const { exportedStories } = await exportStories(selectedClient.clientId);
          rows = [...rows, ...exportedStories];
        }

        exportDownload(selectedClient.name, rows, selectedSprint?.sprintName);
      } catch (error) {
        console.error(error);
        setError(new Error('Error exporting Grid items to Excel. Please try again.'));
      } finally {
        setExporting(false);
        setExportProgress('');
        dispatch(setClientSwitcherDisabled(false));
      }
    }
  };

  const exportKanbanToExcel = async (workItemType: WorkItemTypes | null) => {
    if (selectedClient && workItemType) {
      try {
        setExporting(true);
        dispatch(setClientSwitcherDisabled(true));

        let rows: IExport[] = [];

        // export epics
        if (workItemType === 'Epic') {
          const { exportedEpics } = await exportEpics(selectedClient.clientId);
          rows = exportedEpics;
        }

        // export features
        if (workItemType === 'Feature') {
          const { exportedFeatures } = await exportFeatures(selectedClient.clientId);
          rows = exportedFeatures;
        }

        // export stories
        if (workItemType === 'Story') {
          const { exportedStories } = await exportStories(selectedClient.clientId);
          rows = exportedStories;
        }
        exportDownload(selectedClient.name, rows, selectedSprint?.sprintName);
      } catch (error) {
        console.error(error);
        setError(new Error('Error exporting Kanban items to Excel. Please try again.'));
      } finally {
        setExporting(false);
        setExportProgress('');
        dispatch(setClientSwitcherDisabled(false));
      }
    }
  };

  const exportSearch = async () => {
    if (searchResults && selectedClient) {
      try {
        setExporting(true);

        let rows: IExport[] = [];

        let _searchResults: IModifiedSearchValue[] = [];
        if (searchResults.length >= recordCount) {
          _searchResults = searchResults;
        } else {
          setExportProgress(`Fetching all search items`);
          const result = await searchWorkItems(selectedClient.clientId, {
            query: searchInputValue,
            page: 1,
            pageSize: 999,
            type: selectedTypeFilter && selectedTypeFilter === 'Story' ? ['Story', 'Bug'] : selectedTypeFilter ? [selectedTypeFilter] : undefined,
            state: selectedStateFilter ? [selectedStateFilter] : undefined
          });
          const results: IModifiedSearchValue[] = result.values.map(value => ({
            ...value,
            clientProjectName: value.project || null,
            id: Number(value.id)
          }));
          _searchResults = results;
        }

        // using a for loop here instead of Promise.all because this needs to run sequentially in order not to hit ADO rate limiting
        for (let i = 0; i < _searchResults.length; i++) {
          setExportProgress(`Exporting search item ${i + 1}/${_searchResults.length}`);

          const searchResult = _searchResults[i];

          if (searchResult.workItemType === 'Epic' || searchResult.workItemType === 'Feature') {
            const row: IExport = {};

            columns.map(column => {
              const value = searchResult[column.id];
              row[column.title] = value ? sanitizeString(value.toString()) : '';
            });

            rows.push(row);
          }

          if (searchResult.workItemType === 'Bug' || searchResult.workItemType === 'Product Backlog Item') {
            const detail = await getExtranetUserStoryDetail(searchResult.id);
            detail.comments = await fetchAndFormatStoryComments(detail.id);

            const row: IExport = {};

            columns.map(column => {
              if (column.id === 'parentId') {
                row[column.title] = detail.featureID;
              } else if (column.id === 'workItemType') {
                row[column.title] = searchResult.workItemType === 'Bug' ? 'Bug' : 'Story';
              } else if (column.id === 'project') {
                row[column.title] = '';
              } else if (column.id === 'sprint') {
                row[column.title] = detail.sprintName && detail.sprintContainer ? formatSprint(detail.sprintContainer, detail.sprintName) : '';
              } else if (column.id === 'effort') {
                row[column.title] = detail.effort ? formatHours(detail.effort) : '';
              } else if (column.id === 'acceptanceCriteria') {
                row[column.title] = stripHtml(sanitizeString(detail.acceptanceCriteria));
              } else if (column.id === 'comments') {
                row[column.title] = detail.comments || '';
              } else {
                const other = detail[column.id];
                row[column.title] = sanitizeString(other ? other.toString() : '');
              }
            });

            rows.push(row);
          }
        }

        exportDownload(selectedClient.name, rows);
      } catch (error) {
        console.error(error);
        setError(new Error('Error exporting search results to Excel. Please try again.'));
      } finally {
        setExporting(false);
        setExportProgress('');
      }
    }
  };

  const formatEffort = (effort: number) => {
    return effort === 1 ? `${effort} hr` : `${effort} hrs`;
  };
  const displayEffort = (effort: number | undefined) => {
    if (isLoadingEpics || isLoadingFeatures) return <SkeletonLoader width={300} className={classes.projectSelectGrid} />;
    return formatEffort(effort ?? 0);
  };

  const hasNoEpicsWithoutFilter =
    !searchResults &&
    !isLoadingProjects &&
    !isLoadingEpics &&
    !isLoadingFeatures &&
    !isLoadingStories &&
    !selectedTypeFilter &&
    epics &&
    epics.length === 0;
  const hasNoEpics = !searchResults && !isLoadingProjects && !isLoadingEpics && selectedTypeFilter === 'Epic' && epics && epics.length === 0;
  const hasNoFeatures =
    !searchResults &&
    !isLoadingProjects &&
    !isLoadingFeatures &&
    !isLoadingStories &&
    selectedTypeFilter === 'Feature' &&
    features.length === 0 &&
    allFeatureUserStories &&
    allFeatureUserStories.length === 0;
  const hasNoStories =
    !searchResults &&
    !isLoadingProjects &&
    !isLoadingStories &&
    selectedTypeFilter === 'Story' &&
    (!allUserStories || (allUserStories && allUserStories.length === 0));
  const hasNoSearchResults = !isSearching && searchResults && searchResults.length === 0;

  const selectedStory = selectedStories && selectedStories.find(story => story.id === Number(currentStoryId));

  return (
    <Page
      title='Board'
      overflow
      actions={
        showSprintSelector
          ? () => (
              <SprintSwitcher
                handleSprintOptions={val => setSprintOptions(val)}
                handleSelectedSprint={(sprint: IExtranetSprintItem) => {
                  setPage(DEFAULT_PAGE);
                  setSelectedSprint(sprint);
                  // reset the ids when changing sprints
                  setCurrentFeatureId(null);
                  setCurrentEpicId(null);

                  if (!query || (query && !query.get('workitem'))) {
                    setCurrentStoryId(null);
                  }
                  // reset user stories when changing sprints
                  setSelectedUserStories([]);
                }}
                sprintOption={selectedSprint as IExtranetSprintItem}
                isDisabled={!!searchedInputValue || !!selectedProjectId}
              />
            )
          : undefined
      }
    >
      <div className={classes.wrapper}>
        <LoaderWrapper>
          <div>
            {/* Shared between Grid & Kanban */}
            <Grid container alignItems='center' spacing={1} className={classes.hideForPrint}>
              {(isLoadingProjects || isLoadingEpics) && !isMobile ? (
                <Grid item xs={12} sm={6} lg={3}>
                  <SkeletonLoader width={300} className={classes.projectSelectGrid} />
                </Grid>
              ) : (
                !isMobile && (
                  <>
                    <Grid item xs={12} sm={6} lg={3}>
                      <TextField
                        id='select-a-project'
                        label='Project'
                        select
                        className={classes.projectSelect}
                        margin='none'
                        disabled={isLoadingProjects || (!!projects && projects.length === 0)}
                        value={projectId}
                        variant='outlined'
                        size='small'
                        SelectProps={{
                          endAdornment: projectId ? (
                            <InputAdornment position='end'>
                              <IconButton
                                style={{ padding: 0, marginRight: '12px' }}
                                onClick={() => {
                                  setProjectId('');
                                  setProject(null);
                                }}
                              >
                                <Close fontSize='small' />
                              </IconButton>
                            </InputAdornment>
                          ) : null
                        }}
                        onChange={e => {
                          if (Number(e.target.value) === 0) {
                            setProjectId('');
                            return setProject(null);
                          }
                          const selected = projects && projects.find(pro => pro.clientProjectId === Number(e.target.value));
                          if (selected) {
                            ReactGA.event({
                              category: 'User Stories',
                              action: 'Set a Project Filter',
                              value: selected.clientProjectId,
                              label: `Filter by Project: ${selected.name}${gaLabelClientValue}`
                            });
                            setProjectId(selected.clientProjectId);
                            setProject(selected);
                          } else {
                            setProjectId('');
                            setProject(null);
                          }
                        }}
                      >
                        {projects &&
                          projects.map((project, index) => (
                            <MenuItem key={`${index}`} value={project.clientProjectId}>
                              {project.name}
                            </MenuItem>
                          ))}
                      </TextField>
                    </Grid>
                  </>
                )
              )}
              {isShowingFilters && (
                <>
                  {isMobile && (
                    <Grid item xs={12} sm={6} lg={3}>
                      {isLoadingEpics ? <SkeletonLoader width={150} /> : ''}
                      {isLoadingEpics ? (
                        <SkeletonLoader width={300} className={classes.projectSelect} />
                      ) : (
                        <TextField
                          id='select-a-project'
                          label='Project'
                          select
                          className={classes.projectSelect}
                          margin='none'
                          disabled={isLoadingProjects || (!!projects && projects.length === 0)}
                          value={projectId}
                          variant='outlined'
                          size='small'
                          SelectProps={{
                            endAdornment: projectId ? (
                              <InputAdornment position='end'>
                                <IconButton
                                  style={{ padding: 0, marginRight: '12px' }}
                                  onClick={() => {
                                    setProjectId('');
                                    setProject(null);
                                  }}
                                >
                                  <Close />
                                </IconButton>
                              </InputAdornment>
                            ) : null
                          }}
                          onChange={e => {
                            if (Number(e.target.value) === 0) {
                              setProjectId('');
                              return setProject(null);
                            }
                            const selected = projects && projects.find(pro => pro.clientProjectId === Number(e.target.value));
                            if (selected) {
                              ReactGA.event({
                                category: 'User Stories',
                                action: 'Set a Project Filter',
                                value: selected.clientProjectId,
                                label: `Filter by Project: ${selected.clientProjectId}${gaLabelClientValue}`
                              });
                              setProjectId(selected.clientProjectId);
                              setProject(selected);
                            } else {
                              setProjectId('');
                              setProject(null);
                            }
                          }}
                        >
                          <MenuItem value={0}>All Projects</MenuItem>
                          {projects &&
                            projects.map((project, index) => (
                              <MenuItem key={`${index}`} value={project.clientProjectId}>
                                {project.name}
                              </MenuItem>
                            ))}
                        </TextField>
                      )}
                    </Grid>
                  )}
                  {isLoadingEpics || isLoadingProjects ? (
                    <Grid item xs={12} sm={6} lg={3}>
                      <SkeletonLoader width={300} className={classes.projectSelect} />
                    </Grid>
                  ) : (
                    <Grid item xs={12} sm={6} lg={3}>
                      <TextField
                        id='type-filter'
                        label='View Mode'
                        select
                        className={`${classes.viewModeSelect} ${classes.hideForPrint}`}
                        margin='none'
                        value={selectedTypeFilter || 0}
                        variant='outlined'
                        size='small'
                        onChange={e => {
                          if (Number(e.target.value) === 0) {
                            return setTypeFilter(null);
                          }
                          ReactGA.event({
                            category: 'User Stories',
                            action: 'Set a Type Filter',
                            label: `Filter by Type: ${e.target.value}${gaLabelClientValue}`
                          });
                          setTypeFilter(e.target.value as any);
                        }}
                      >
                        {selectedView === 'Grid' && <MenuItem value={0}>Filter By Type</MenuItem>}
                        {typeOptions.map((option, index) => (
                          <MenuItem key={`${index}`} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                  )}
                </>
              )}
              {isLoadingEpics || isLoadingProjects ? (
                <Grid item xs={12} sm={6} lg={3}>
                  <SkeletonLoader width={300} />
                </Grid>
              ) : (
                <>
                  <Grid item xs={12} sm={6} lg={3}>
                    {!isLoadingEpics && (selectedStateFilter || project || (selectedView === 'Grid' && selectedTypeFilter)) && (
                      <Button
                        size='small'
                        color='default'
                        startIcon={<Close />}
                        className={classes.filtersResetButton}
                        onClick={() => {
                          if (selectedView === 'Kanban') {
                            setTypeFilter('Story');
                          }
                          if (selectedView === 'Grid') {
                            setTypeFilter(null);
                          }
                          setStateFilter('');
                          setProject(null);
                          setPage(DEFAULT_PAGE);
                          setPerPage(DEFAULT_PAGE_SIZE);
                          if (selectedProjectId) {
                            dispatch(setSelectedProjectId(null));
                          }
                        }}
                      >
                        Reset
                      </Button>
                    )}
                    <Tooltip
                      arrow={true}
                      placement='top'
                      title={`Export ${selectedTypeFilter === 'Story' || selectedView === 'Kanban' ? recordCount : 'all'} items to Excel`}
                    >
                      <span>
                        <ExportButton
                          className={classes.downloadButton}
                          isExporting={isExporting}
                          onClick={() => {
                            ReactGA.event({
                              category: 'User Stories',
                              action: 'Export items to Excel',
                              label: `Export items to Excel${gaLabelClientValue}`
                            });
                            if (searchResults) {
                              exportSearch();
                            } else if (selectedView === 'Grid') {
                              exportGridToExcel();
                            } else {
                              exportKanbanToExcel(selectedTypeFilter);
                            }
                          }}
                          ariaLabel={`Export ${selectedTypeFilter === 'Story' || selectedView === 'Kanban' ? recordCount : 'all'} items to Excel`}
                          isDisabled={
                            hasNoEpics ||
                            hasNoEpicsWithoutFilter ||
                            hasNoFeatures ||
                            hasNoSearchResults ||
                            hasNoStories ||
                            isExporting ||
                            isLoadingEpics ||
                            isLoadingFeatures ||
                            isLoadingProjects ||
                            isLoadingStories ||
                            isSearching ||
                            isShowingMore
                              ? true
                              : false
                          }
                          exportProgress={exportProgress}
                        />
                      </span>
                    </Tooltip>
                    <Typography display='inline' className={classes.pipe}>
                      |
                    </Typography>

                    {/* PRINT BUTTON */}
                    <Button
                      startIcon={<Print />}
                      className={classes.excelButton}
                      color='primary'
                      onClick={() => {
                        window.print();
                        ReactGA.event({
                          category: 'User Stories',
                          action: 'Clicking the print button',
                          label: `Clicking the print button${gaLabelClientValue}`
                        });
                      }}
                    >
                      <div>PRINT</div>
                    </Button>
                  </Grid>

                  {/* GRID VIEW */}
                  <Grid item xs={12} sm={6} lg={3} className={classes.gridAndKanban}>
                    <Typography display='inline' className={classes.viewByText}>
                      View By:
                    </Typography>
                    <Button
                      disabled={isLoadingEpics || isLoadingProjects}
                      disableRipple
                      size='small'
                      color={selectedView === 'Grid' ? 'secondary' : 'primary'}
                      startIcon={<ViewHeadline />}
                      className={selectedView === 'Grid' ? classes.viewButtonActive : undefined}
                      onClick={() => {
                        ReactGA.event({
                          category: 'User Stories',
                          action: 'Switch to Grid View',
                          label: `Switch User Stories View to Grid View${gaLabelClientValue}`
                        });
                        setSelectedView('Grid');
                      }}
                    >
                      Grid
                    </Button>
                    <Typography display='inline' className={classes.pipe}>
                      |
                    </Typography>

                    {/* KANBAN VIEW */}
                    <Button
                      disabled={isLoadingEpics || isLoadingProjects}
                      disableRipple
                      size='small'
                      color={selectedView === 'Kanban' ? 'secondary' : 'primary'}
                      startIcon={<ViewWeek />}
                      onClick={() => {
                        ReactGA.event({
                          category: 'User Stories',
                          action: 'Switch to Kanban View',
                          label: `Switch User Stories View to Kanban View${gaLabelClientValue}`
                        });
                        setSelectedView('Kanban');
                      }}
                      className={selectedView === 'Kanban' ? classes.viewButtonActive : undefined}
                    >
                      Kanban
                    </Button>
                  </Grid>
                </>
              )}
            </Grid>

            <Grid container alignItems='center' className={classes.mobileEffort} id={'divcontents'}>
              <Typography display='inline' className={classes.viewText}>
                Total Effort:{' '}
                <Typography component='span' className={classes.effortValue} variant='body1' display='inline'>
                  {displayEffort(effort?.total)}
                </Typography>
              </Typography>
              <Typography display='inline' className={classes.pipe}>
                |
              </Typography>
              <Typography display='inline' className={classes.viewText}>
                Remaining Effort:{' '}
                <Typography component='span' className={classes.effortValue} variant='body1' display='inline'>
                  {displayEffort(effort?.remaining)}
                </Typography>
              </Typography>
            </Grid>

            {(hasNoEpicsWithoutFilter || hasNoEpics || hasNoFeatures || hasNoStories) && !searchResults && !isSearching && (
              <Empty messages={[`No ${formatWorkItemType(selectedTypeFilter || 'Epic', true)} are available. Please select a different sprint.`]} />
            )}
            <>
              {selectedView !== 'Kanban' && (isLoadingEpics || isSearching) && <GridLoader />}
              {selectedView !== 'Kanban' && !isLoadingEpics && !searchResults && (
                <UserStoryGridView
                  allFeatureUserStories={allFeatureUserStories}
                  allUserStories={allUserStories}
                  currentEpicId={currentEpicId}
                  currentFeatureId={currentFeatureId}
                  currentStoryId={currentStoryId}
                  epics={epics}
                  selectedTypeFilter={selectedTypeFilter}
                  features={features}
                  handleEpicClick={(id: number) => {}}
                  handleFeatureClick={(id: number | null) => {
                    setCurrentFeatureId(id);
                  }}
                  handleStoryClick={(id: number) => {
                    setCurrentStoryId(id);
                  }}
                  isLoadingFeatures={isLoadingFeatures}
                  isLoadingStories={isLoadingStories}
                  isLoadingStoryDetail={isLoadingStoryDetail}
                  page={page}
                  recordCount={recordCount}
                  rowsPerPage={perPage}
                  selectedStories={selectedStories}
                  setPage={val => setPage(val)}
                  setRowsPerPage={val => setPerPage(val)}
                  userStories={selectedUserStories}
                  expanded={expanded}
                  setExpanded={setExpanded}
                />
              )}
              <UserStoriesSearchGridView
                currentStoryId={currentStoryId}
                handleClearSearch={handleClearSearch}
                isLoadingStoryDetail={isLoadingStoryDetail}
                isSearching={isSearching}
                page={page}
                perPage={perPage}
                recordCount={recordCount}
                searchResults={searchResults}
                searchedInputValue={searchedInputValue}
                selectedStories={selectedStories}
                selectedView={selectedView}
                setCurrentStoryId={(id: number) => {
                  setCurrentStoryId(id);
                }}
                setPage={(val: number) => setPage(val)}
                setPerPage={(val: number) => setPerPage(val)}
              />
            </>
          </div>
          {/* Kanban specific components */}
          <>
            {selectedView === 'Kanban' && (
              <UserStoryKanbanView
                clientId={selectedClient ? selectedClient.clientId : null}
                currentFeatureId={currentFeatureId ? currentFeatureId : null}
                currentEpicId={currentEpicId}
                currentStoryId={currentStoryId}
                epics={epics ? epics : []}
                features={features}
                setEpics={setEpics}
                setFeatures={setFeatures}
                fetchFeatures={fetchFeatures}
                handleFeatureClick={(id: number | null) => {
                  setCurrentFeatureId(id);
                }}
                handleStoryClick={(id: number | null) => {
                  setCurrentStoryId(id);
                }}
                handleClearSearch={handleClearSearch}
                isLoadingEpics={isLoadingEpics}
                isLoadingFeatures={isLoadingFeatures}
                isLoadingProjects={isLoadingProjects}
                isLoadingStories={isLoadingStories}
                isLoadingStoryDetail={isLoadingStoryDetail}
                isSearching={isSearching}
                searchedInputValue={searchedInputValue}
                searchResults={searchResults}
                selectedSprint={selectedSprint}
                selectedStateFilter={selectedStateFilter}
                selectedStories={selectedStories}
                selectedTypeFilter={selectedTypeFilter ? selectedTypeFilter : 'Story'}
                setCurrentEpicId={setCurrentEpicId}
                setCurrentFeatureId={setCurrentFeatureId}
                setCurrentStoryId={setCurrentStoryId}
                setShowEpicModal={setShowEpicModal}
                setShowFeatureModal={setShowFeatureModal}
                setShowStoryModal={setShowStoryModal}
                showEpicModal={showEpicModal}
                showFeatureModal={showFeatureModal}
                showStoryModal={showStoryModal}
                stories={allUserStories ? allUserStories : []}
                userStories={selectedUserStories}
                projectId={projectId}
              />
            )}
          </>
        </LoaderWrapper>
        <Toast id='user-stories-error' message={error && error.message} open={error ? true : false} onClose={() => setError(null)} variant='error' />
      </div>

      {/** USER STORY DETAIL MODAL */}
      <Modal
        maxWidth='lg'
        onClose={() => {
          // reset the route
          history.push(location.pathname);
          if (!printUserStory) setCurrentStoryId(null);
          setShowStoryModal(false);
        }}
        open={showStoryModal}
        subtitle={selectedStory ? `${selectedStory.id}  ${selectedStory.title}` : ''}
        title={
          selectedStory && selectedStory.workItemType === 'Product Backlog Item'
            ? 'Story Details'
            : selectedStory && selectedStory.workItemType
            ? `${selectedStory.workItemType} Details`
            : ''
        }
      >
        <LoaderWrapper>
          <UserStoryDetail
            isUserStoryNotAuthorized={isUserStoryNotAuthorized}
            currentStoryId={currentStoryId}
            isLoadingStoryDetail={isLoadingStoryDetail}
            selectedStory={selectedStory}
            isModalView
            printUserStory={printUserStory}
          />
        </LoaderWrapper>
      </Modal>
    </Page>
  );
};

const userStoriesStyles = makeStyles((theme: Theme) => ({
  viewModeSelect: {
    width: '100%',
    marginTop: theme.spacing(0),
    marginRight: theme.spacing(2)
  },
  projectSelectGrid: {
    marginRight: 0,
    paddingRight: 0
  },
  gridAndKanban: {
    '@media (min-width: 1280px)': {
      textAlign: 'right'
    }
  },
  hideForPrint: {
    '@media print': {
      display: 'none'
    }
  },
  wrapper: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    paddingTop: theme.spacing(0.125),
    '&& .MuiPaper-elevation1': {
      boxShadow: 'none',
      margin: 0,
      borderTop: `1px solid ${theme.palette.grey[300]}`
    }
  },
  projectSelect: {
    width: '100%',
    margin: theme.spacing(0.5, 0)
  },
  pipe: {
    margin: '0 12px 0 4px',
    color: theme.palette.grey[300],
    [theme.breakpoints.up('md')]: {
      margin: '0 8px 0 4px'
    }
  },
  acceptanceCriteria: {
    margin: theme.spacing(1, 0),
    '&& ul': {
      padding: `0 0 0 10px !important`,
      [theme.breakpoints.up('md')]: {
        padding: `0 0 0 30px !important`
      }
    }
  },
  margin: {
    margin: theme.spacing(1, 0)
  },
  viewByText: {
    whiteSpace: 'nowrap',
    '@media (min-width: 600px)': {
      paddingLeft: theme.spacing(1),
      marginRight: theme.spacing(0.5)
    }
  },
  viewText: {
    marginRight: theme.spacing(0.5),
    whiteSpace: 'nowrap'
  },
  filtersButton: {
    padding: 0,
    minWidth: 0,
    '&& .MuiButton-startIcon': {
      marginRight: 0
    },
    [theme.breakpoints.up('md')]: {
      minWidth: '64px',
      '&& .MuiButton-startIcon': {
        marginRight: theme.spacing(0.5)
      }
    }
  },
  filtersButtonActive: {
    padding: 0,
    minWidth: 0,
    borderBottom: '1px solid',
    borderRadius: 0,
    fontWeight: 700,
    '&& .MuiButton-startIcon': {
      marginRight: 0
    },
    [theme.breakpoints.up('md')]: {
      minWidth: '64px',
      '&& .MuiButton-startIcon': {
        marginRight: theme.spacing(0.5)
      }
    }
  },
  downloadButton: {
    '@media (min-width: 1280px)': {
      paddingLeft: theme.spacing(1)
    }
  },
  excelButton: {
    padding: 0,
    minWidth: 0,
    '&& .MuiButton-startIcon': {
      marginRight: theme.spacing(0.5)
    },
    [theme.breakpoints.up('md')]: {
      minWidth: '64px'
    }
  },
  filtersResetButton: {
    alignSelf: 'flex-start',
    [theme.breakpoints.up('md')]: {
      alignSelf: 'center'
    },
    '@media (min-width: 1280px)': {
      paddingLeft: theme.spacing(1)
    }
  },
  searchInput: {
    marginRight: '21px',
    width: '66%',
    '&& .MuiInputBase-root': {
      width: '100%',
      paddingLeft: theme.spacing(0.5)
    },
    [theme.breakpoints.up('sm')]: {
      width: '68%'
    },
    [theme.breakpoints.up('md')]: {
      marginRight: theme.spacing(1),
      width: 'auto'
    },
    '@media (min-width: 1038px)': {
      '&& .MuiInputBase-root': {
        width: 300
      }
    }
  },
  viewButtonActive: {
    borderBottom: '1px solid',
    borderRadius: 0,
    fontWeight: 700
  },
  effortValue: {
    color: theme.palette.grey[500],
    fontFamily: 'Poppins-Light, sans-serif',
    fontSize: 16,
    lineHeight: 1.6,
    margin: theme.spacing(0.125, 0, 0)
  },
  mobileEffort: {
    margin: theme.spacing(1, 0)
  }
}));
