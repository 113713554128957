import React, { FC } from 'react';
import Paper from '@material-ui/core/Paper';
import clsx from 'clsx';
// Types
import { ITimesheetCompletionPerson } from '@shared/types';
import { Theme, Typography, createStyles, makeStyles } from '@material-ui/core';
import { useMedia } from 'react-use';

interface IWeeklyTotalsV2Props {
  employeeTimesheet: ITimesheetCompletionPerson;
  handleChangeDate(date: string, employeeId?: number): void;
  depth?: number;
}

export const WeeklyTotalsV2: FC<IWeeklyTotalsV2Props> = ({ employeeTimesheet, handleChangeDate, depth = 0 }) => {
  // Make each indent alternating colors
  const classes = weeklyTotalsStyles({ depth, employeeTimesheet });
  const isMobile = useMedia('(max-width: 960px)');

  return (
    <div style={depth ? { marginLeft: `${depth * 0.25}rem` } : undefined}>
      <Paper classes={{ root: isMobile ? classes.mobilePaper : classes.paper }}>
        <Typography className={classes.employeeName} variant='body2'>
          {employeeTimesheet.name}
        </Typography>
        <div className={classes.hoursSummary}>
          {employeeTimesheet.time.dailyTotals.map(day => (
            <div
              key={day.date}
              className={classes.dayColumn}
              onClick={() => handleChangeDate(day.date.split('T')[0], employeeTimesheet.employeeId)}
              onKeyDown={e => {
                if (e.key === 'Enter') {
                  handleChangeDate(day.date.split('T')[0], employeeTimesheet.employeeId);
                }
              }}
            >
              <div className={clsx(classes.header, day.isCompleted ? classes.headerComplete : '')}>
                <div className={classes.headerText}>{day.dayOfWeek.charAt(0)}</div>
              </div>
              <div className='details'>
                <div className={day.isCompleted ? classes.dayComplete : ''}>
                  <div className={clsx(classes.hours, day.isCompleted ? classes.finished : '')} data-testid={`${day.dayOfWeek.charAt(0)}-total`}>{day.hoursCompleted}</div>
                </div>
              </div>
            </div>
          ))}
          <div className={clsx(classes.dayColumn, classes.totals)}>
            <div>Total</div>
            <div>{employeeTimesheet.time.totalHoursCompleted}</div>
          </div>
        </div>
      </Paper>
      {employeeTimesheet.reports.map(report => {
        return <WeeklyTotalsV2 key={report.employeeId} employeeTimesheet={report} handleChangeDate={handleChangeDate} depth={depth + 1} />;
      })}
    </div>
  );
};

function checkIncompleteTasksBeforeCurrentDate(employeeTimesheet: ITimesheetCompletionPerson) {
  const currentDate = new Date(employeeTimesheet?.time?.currentDate);

  for (let day of employeeTimesheet?.time?.dailyTotals || []) {
    const taskDate = new Date(day.date);

    if (taskDate < currentDate && !day.isCompleted) {
      return true;
    }
  }

  return false;
}

const getColor = (theme: Theme, employeeTimesheet: ITimesheetCompletionPerson, depth: number = 0) => {
  if (employeeTimesheet && checkIncompleteTasksBeforeCurrentDate(employeeTimesheet)) {
    return theme.palette.error.main;
  }

  if (depth === 0 || depth % 2 === 0) {
    return theme.palette.secondary.main;
  }

  return theme.palette.primary.main;
};

const weeklyTotalsStyles = makeStyles<Theme, { depth?: number; employeeTimesheet: ITimesheetCompletionPerson }>((theme: Theme) =>
  createStyles({
    employeeName: {
      wordBreak: 'break-word',
      color: ({ depth, employeeTimesheet }) => getColor(theme, employeeTimesheet, depth)
    },
    paper: {
      padding: theme.spacing(0.5),
      marginTop: theme.spacing(0.5),
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center'
    },
    mobilePaper: {
      padding: theme.spacing(0.5),
      marginTop: theme.spacing(0.5),
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'left'
    },
    hoursSummary: {
      display: 'flex',
      flexDirection: 'row'
    },
    dayColumn: {
      cursor: 'pointer',
      width: '40px',
      marginLeft: '.25rem'
    },
    headerRow: {
      display: 'flex',
      alignItems: 'center'
    },
    hoursRow: {
      display: 'flex',
      alignItems: 'center'
    },
    details: {
      flexDirection: 'column'
    },
    dayComplete: {
      color: theme.palette.secondary.main
    },
    finished: {
      borderBottom: `2px solid ${theme.palette.secondary.main}`
    },
    dayToday: {
      backgroundColor: 'lightgray',
      color: 'white',
      borderBottom: `2px solid lightgray`
    },
    header: {
      color: theme.palette.primary.main
    },
    headerComplete: {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.common.white
    },
    headerToday: {
      backgroundColor: 'lightgray',
      color: 'white'
    },
    headerText: {
      textAlign: 'center'
    },
    hours: {
      textAlign: 'center'
    },
    totals: {
      color: theme.palette.secondary.main,
      fontWeight: 'bold',
      textAlign: 'center'
    }
  })
);
