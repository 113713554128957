import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { ITimesheetWidgetColors, ITimesheetCompletionV2 } from '@shared/types';
import { Typography } from '@material-ui/core';
import { Loader } from '@shared/components/loader';

import { WeeklyTotalsV2 } from '@src/employees/components/controls/WeeklyTotalsV2';

interface ITimesheetProps {
  isLoading: boolean;
  weeklyTimeSheetV2: ITimesheetCompletionV2;
  day: string;
  colors: ITimesheetWidgetColors;
  isCard?: boolean;
  isToggled?: boolean;
  setIsToggled?: (isToggled: boolean) => void;
}

export const Timesheet: FC<ITimesheetProps> = ({ isLoading, weeklyTimeSheetV2, day, colors, isCard, isToggled = false, setIsToggled }) => {
  const classes = TimesheetStyles(colors)();
  const history = useHistory();

  const formatDate = (date: string) => {
    return `${date.trim()} 00:00:00`;
  };

  const setDayAndEmployee = (date: string, employeeId: number | null) => {
    const formattedDate = formatDate(date);
    if (date && employeeId) {
      history.push({ pathname: '/employees/time-tracking', state: { day: formattedDate, employeeId: employeeId } });
    } else {
      history.push({ pathname: '/employees/time-tracking', state: { day: formattedDate } });
    }
  };

  if (isLoading) return <Loader position='centered' />;

  return (
    <>
      {!isCard && (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant='h4' className={classes.subHead}>
            My Time This Week
          </Typography>
        </div>
      )}
      <div className={classes.weeklyTotals}>
        {/* Current Users's TimeSheet */}
        <WeeklyTotalsV2
          key={`weekly-totals-v2-currentUser-${weeklyTimeSheetV2?.currentUser?.employeeId}`}
          employeeTimesheet={weeklyTimeSheetV2.currentUser}
          handleChangeDate={(day, id) => setDayAndEmployee(day, id ?? null)}
        />
      </div>
    </>
  );
};

const TimesheetStyles = (colors: ITimesheetWidgetColors) =>
  makeStyles((theme: Theme) => ({
    incompletePerson: {
      display: 'flex',
      justifyContent: 'space-between',
      backgroundColor: theme.palette.common.white,
      padding: theme.spacing(0.5),
      marginTop: theme.spacing(0.5),
      color: colors.incomplete,
      fontWeight: 'normal'
    },
    completePerson: {
      display: 'flex',
      justifyContent: 'space-between',
      backgroundColor: theme.palette.common.white,
      padding: theme.spacing(0.5),
      marginTop: theme.spacing(0.5),
      color: colors.complete,
      fontWeight: 'normal'
    },
    headerTitle: {
      marginTop: theme.spacing(2)
    },
    marginTop: {
      marginTop: theme.spacing(1)
    },
    indent: {
      marginLeft: '40px'
    },
    button: {
      display: 'block',
      marginLeft: '40px'
    },
    subHead: {
      color: theme.palette.secondary.main,
      fontFamily: theme.typography.fontFamily
    },
    weeklyTotals: {
      margin: theme.spacing(0.5, 0, 1, 0),
      padding: theme.spacing(1),
      borderRadius: '5px',
      backgroundColor: theme.palette.info.light,
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(0.5)
      }
    }
  }));
