import React, { FC, useEffect } from 'react';
import { Button, makeStyles, Tooltip, Theme } from '@material-ui/core';
import { Undo, SaveOutlined, Close } from '@material-ui/icons';
import clsx from 'clsx';
import { ReleaseStatuses } from '@shared/types';
// Context
import { useValidation } from '@src/context/validation-context';

interface ActionButtonsProps {
  onCancel?: () => void;
  onCancelText?: string;
  onCancelIcon?: JSX.Element;
  onSave?: () => void;
  onClientApproved?: () => void;
  onSaveText?: string;
  onSaveIcon?: JSX.Element;
  onSaveAndClose?: () => Promise<void>;
  disableSave?: boolean;
  saveButtonColor?: string;
  contactsAssigned?: boolean;
  employeesAssigned?: boolean;
  showTooltip?: boolean;
  releaseDate?: Date | null;
  selectedReleaseTime?: string;
  releaseStatusName?: string;
}

export const ActionButtons: FC<ActionButtonsProps> = ({
  onCancel,
  onCancelText,
  onCancelIcon,
  onSave,
  onClientApproved,
  onSaveText,
  onSaveIcon,
  onSaveAndClose,
  disableSave,
  saveButtonColor,
  contactsAssigned,
  employeesAssigned,
  showTooltip = true,
  releaseDate,
  selectedReleaseTime,
  releaseStatusName
}) => {
  const classes = useStyles({ saveButtonColor });
  const { validationMessages, setValidationMessages } = useValidation();

  const updateValidationMessage = (condition: boolean, message: string, updatedMessages: string[]) => {
    const index = updatedMessages.indexOf(message);
    if (condition && index === -1) {
      updatedMessages.push(message);
    } else if (!condition && index !== -1) {
      updatedMessages.splice(index, 1);
    }
  };

  useEffect(() => {
    setValidationMessages(prev => {
      const updatedMessages = [...prev];

      updateValidationMessage(employeesAssigned === false, 'Assigned Employee', updatedMessages);
      updateValidationMessage(contactsAssigned === false, 'Client Contact', updatedMessages);
      updateValidationMessage(!releaseDate, 'Release Date', updatedMessages);
      updateValidationMessage(!selectedReleaseTime, 'Release Time', updatedMessages);

      return updatedMessages;
    });
  }, [contactsAssigned, employeesAssigned, releaseDate, selectedReleaseTime, setValidationMessages]);

  const renderButtonWithTooltip = (button: JSX.Element) => {
    return showTooltip && disableSave ? (
      <Tooltip
        title={
          validationMessages.length > 0 ? (
            <>
              Required fields are missing:
              {validationMessages.map((message, index) => (
                <div key={index}>- {message}</div>
              ))}
            </>
          ) : (
            ''
          )
        }
        classes={{ tooltip: clsx(classes.customWidth, classes.customTooltip) }}
        placement='bottom'
      >
        <span>{button}</span>
      </Tooltip>
    ) : (
      button
    );
  };

  return (
    <div className={classes.buttonGroup}>
      {onCancel && (
        <Button className={classes.cancelButton} startIcon={onCancelIcon ?? <Close />} onClick={onCancel}>
          {onCancelText ?? 'Cancel'}
        </Button>
      )}
      {onSaveAndClose &&
        renderButtonWithTooltip(
          <Button className={classes.saveCloseButton} startIcon={<Undo />} onClick={onSaveAndClose} disabled={disableSave}>
            Save & Close
          </Button>
        )}
      {onSave &&
        renderButtonWithTooltip(
          <Button className={classes.saveButton} startIcon={onSaveIcon ?? <SaveOutlined />} onClick={onSave} disabled={disableSave}>
            {onSaveText ?? 'Save'}
          </Button>
        )}
      {releaseStatusName === ReleaseStatuses.CLIENTAPPROVAL && (
        <Button className={classes.clientApprovedButton} startIcon={onSaveIcon ?? <SaveOutlined />} onClick={onClientApproved} disabled={disableSave}>
          Client Approved
        </Button>
      )}
    </div>
  );
};

const useStyles = makeStyles<Theme, { saveButtonColor?: string }>(theme => ({
  buttonGroup: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
    justifyContent: 'flex-end',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center'
    }
  },
  cancelButton: {
    color: theme.palette.grey[700],
    textTransform: 'none',
    '& .MuiButton-startIcon': {
      marginRight: theme.spacing(0.5)
    }
  },
  saveCloseButton: {
    color: theme.palette.success.main,
    textTransform: 'none',
    '& .MuiButton-startIcon': {
      marginRight: theme.spacing(0.5)
    }
  },
  saveButton: {
    color: ({ saveButtonColor }) => saveButtonColor || theme.palette.primary.main,
    textTransform: 'none',
    '& .MuiButton-startIcon': {
      marginRight: theme.spacing(0.5)
    }
  },
  customWidth: {
    maxWidth: 400
  },
  customTooltip: {
    fontSize: '1rem',
    backgroundColor: theme.palette.grey[600],
    color: 'white',
    marginTop: '-0.05rem',
    padding: '0.75rem'
  },
  clientApprovedButton: {
    color: theme.palette.primary.main,
    textTransform: 'none',
    '& .MuiButton-startIcon': {
      marginRight: theme.spacing(0.5)
    }
  }
}));
