import React, { createContext, FC, useState } from 'react';

interface ICardContext {
  cardContentHeight: number;
  setCardContentHeight: (val: number) => void;
}

export const CardContext = createContext<ICardContext>({
  cardContentHeight: 0,
  setCardContentHeight: () => {}
});

interface ICardContextContextHandlerProps {}

export const CardContextHandler: FC<ICardContextContextHandlerProps> = ({ children }): JSX.Element => {
  const [cardContentHeight, setCardContentHeight] = useState<number>(0);

  return <CardContext.Provider value={{ cardContentHeight, setCardContentHeight }}>{children}</CardContext.Provider>;
};
