import React, { useState, useEffect, useMemo, FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';
// Components
import { Table, ITableColumn } from '@shared/components/tables';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import Add from '@material-ui/icons/Add';
import { RevenueEditCard } from '../components/cards';
import { LoaderOverlay } from '@shared/components/loader';
import { Alert } from '@shared/components/alerts';
import { EditPanelLayout } from '@shared/components/layout';
import { Page } from '@shared/components/layout';
// Fetch
import { getRevenueCategories, addRevenueCategory, updateRevenueCategory } from '@shared/fetch';
// Types
import { IRevenueCategory } from '@shared/types';
import AddRevenueCategory from '../components/modals/AddRevenueCategory';

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1
  },
  checkbox: {
    padding: 0
  }
}));

export const ProjectRevenues: FC = () => {
  const classes = useStyles();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [revenueCategory, setRevenueCategories] = useState<IRevenueCategory[]>([]);
  const initialLoad = async () => {
    try {
      setIsLoading(true);
      const revenueCategoryResponse = await getRevenueCategories();
      setRevenueCategories(revenueCategoryResponse);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    initialLoad();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // edit toggles
  const [editSelected, setEditSelected] = useState<IRevenueCategory | null>(null);
  const toggleEdit = (revenueCategory: IRevenueCategory) => {
    let newSelected = null;
    if (!editSelected || editSelected.revenueCategoryId !== revenueCategory.revenueCategoryId) {
      newSelected = { ...revenueCategory };
    }
    setEditSelected(newSelected);
  };
  const columns = useMemo(() => {
    return [
      {
        id: 'selection',
        sort: false,
        hideLoad: true,
        className: classes.checkbox,
        overrideWidth: 50,
        Header: ' ',
        Cell: ({
          cell: {
            row: { original }
          }
        }: any) => (
          <div>
            <Checkbox
              checked={Boolean(editSelected && editSelected.revenueCategoryId === original.revenueCategoryId)}
              onClick={() => toggleEdit(original)}
            />
          </div>
        )
      },
      { Header: 'Name', accessor: 'name' }
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [revenueCategory, editSelected]);

  // save and save error functionality
  const [errorOpen, setErrorOpen] = React.useState(false);
  const [successOpen, setSuccessOpen] = React.useState(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const handleSave = async (newRevenueCategory: IRevenueCategory) => {
    try {
      setIsSaving(true);
      await updateRevenueCategory(newRevenueCategory);
      const newRevenueCategories = [...revenueCategory].map(revenueCategory => {
        if (revenueCategory.revenueCategoryId === newRevenueCategory.revenueCategoryId) {
          revenueCategory.name = newRevenueCategory.name;
        }
        return revenueCategory;
      });
      setRevenueCategories(newRevenueCategories);

      // clear saving and edit state
      setEditSelected(null);
      setSuccessOpen(true);
      setIsSaving(false);
    } catch (error) {
      setErrorOpen(true);
      setIsSaving(false);
    }
  };

  // add functionality
  const [isAddShowing, setIsAddShowing] = useState<boolean>(false);
  const handleAdd = async (name: string, callback: (error?: Error) => void) => {
    try {
      setIsSaving(true);
      const revenueCategoryId = await addRevenueCategory(name);
      const newRevenueCategory = { revenueCategoryId, name };
      const newRevenueCategories = [...revenueCategory];
      newRevenueCategories.push(newRevenueCategory);
      setRevenueCategories(newRevenueCategories);

      callback();
      setSuccessOpen(true);
      setIsSaving(false);
      setIsAddShowing(false);
    } catch (error) {
      setErrorOpen(true);
      setIsSaving(false);
      callback(error);
    }
  };

  return (
    <Page
      title='Project Revenue Categories'
      actions={() => (
        <Button color='primary' aria-label='add' onClick={() => setIsAddShowing(true)} startIcon={<Add />}>
          Add Revenue Category
        </Button>
      )}
      setHeight={false}
      flexGrow={false}
      footerSpacing={50}
    >
      <EditPanelLayout
        open={Boolean(!isSaving && editSelected)}
        editPanel={<RevenueEditCard revenueCategory={editSelected} onSave={handleSave} onCancel={() => setEditSelected(null)} />}
      >
        <Table key='applications-table' data={revenueCategory} columns={columns as ITableColumn[]} stickyHeader expandToFit isLoading={isLoading} />
      </EditPanelLayout>
      <LoaderOverlay open={isSaving} />
      <Alert open={errorOpen} onClose={setErrorOpen} type='error' text='Problem saving please try again!' />
      <Alert open={successOpen} onClose={setSuccessOpen} type='success' text='Save Success!' />
      <AddRevenueCategory open={isAddShowing} onClose={() => setIsAddShowing(false)} onSave={handleAdd} />
    </Page>
  );
};
