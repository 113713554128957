import React, { FC } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
// Components
import { FormLabel, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails, Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
// Types
import { IClientInfo } from '@shared/types';
import { FormikHandlers, FieldMetaProps } from 'formik';

interface IMobileClient extends FormikHandlers, FieldMetaProps<any> {
  original: IClientInfo;
}

export const MobileClient: FC<IMobileClient> = ({
  original: {
    name,
    clientName,
    address,
    city,
    state,
    partner,
    partnerName,
    relationshipManager,
    relationshipManagerName,
    productManager,
    productManagerName
  }
}) => {
  const classes = useStyles();

  return (
    <ExpansionPanel className={classes.root}>
      <ExpansionPanelSummary className={classes.expansionPanel} expandIcon={<ExpandMoreIcon />} aria-controls='panel1a-content' id='panel1a-header'>
        <div className={classes.panelSummaryWrapper}>
          <Typography component='span'>{name || clientName}</Typography>
        </div>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails className={classes.details}>
        <FormLabel component='h2'>Address</FormLabel>
        <Typography component='span'>{address || '--'}</Typography>

        <FormLabel component='h2'>City</FormLabel>
        <Typography component='span'>{city || '--'}</Typography>

        <FormLabel component='h2'>State</FormLabel>
        <Typography component='span'>{state || '--'}</Typography>
        <FormLabel component='h2'>Partner</FormLabel>
        <Typography component='span'>{partner ?? partnerName ?? '--'}</Typography>

        <FormLabel component='h2'>Engagement Manager</FormLabel>
        <Typography component='span'>{relationshipManager ?? relationshipManagerName ?? '--'}</Typography>

        <FormLabel component='h2'>Delivery Lead</FormLabel>
        <Typography component='span'>{productManager ?? productManagerName ?? '--'}</Typography>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    maxWidth: '100%'
  },
  expansionPanel: {
    padding: '0px 24px'
  },
  panelSummaryWrapper: {
    display: 'flex',
    alignItems: 'flex-start',
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  details: {
    display: 'flex',
    flexDirection: 'column'
  }
}));
