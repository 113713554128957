import React, { FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';
// components
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles(() => ({
  backdrop: {
    zIndex: 9999,
    color: '#fff'
  }
}));

interface ILoaderOverlayProps {
  open: boolean;
}

export const LoaderOverlay: FC<ILoaderOverlayProps> = ({ open }) => {
  const classes = useStyles();
  return (
    <Backdrop className={classes.backdrop} open={open}>
      <CircularProgress color='inherit' />
    </Backdrop>
  );
};
