import React, { FC } from 'react';
import { makeStyles, Theme, Typography } from '@material-ui/core';
import { TypographyProps } from '@material-ui/core/Typography';
import clsx from 'clsx';

interface IH3Props extends TypographyProps {}

export const H3: FC<IH3Props> = ({ children, ...props }) => {
  const classes = h3Styles();
  return (
    <Typography {...props} className={clsx(classes.h3, props.className)} variant='h3'>
      {children}
    </Typography>
  );
};

const h3Styles = makeStyles((theme: Theme) => ({
  h3: {
    fontSize: 24,
    fontWeight: 700,
    margin: theme.spacing(6, 0, 3)
  }
}));
