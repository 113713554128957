import React, { FC } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { withFormik, FormikProps } from 'formik';
import * as Yup from 'yup';
// Components
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import FormControl from '@material-ui/core/FormControl';
import { DatePicker } from '@shared/components/inputs';
// Types
import { IHolidayTimeEntryItem } from '@shared/types';

interface IAddHolidayProps {
  onSave: (holiday: IHolidayTimeEntryItem, callback: (error?: Error) => void) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cardRoot: {
      justifyContent: 'flex-start'
    },

    root: {
      marginBottom: theme.spacing(1),
      width: 300,
      flexGrow: 0,
      maxHeight: 300,
      justifyContent: 'flex-start',
      [theme.breakpoints.up('md')]: {
        width: 450,
        maxHeight: 300
      }
    },
    primaryHeader: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white
    }
  })
);

const AddHoliday: FC<IAddHolidayProps & FormikProps<IHolidayTimeEntryItem>> = ({
  setFieldValue,
  handleBlur,
  handleChange,
  resetForm,
  errors,
  touched,
  values,
  submitForm,
  isValid
}) => {
  const classes = useStyles();

  return (
    <Card elevation={0} variant='outlined' classes={{ root: classes.cardRoot }} className={classes.root}>
      <CardHeader
        title='Holiday Details'
        className={classes.primaryHeader}
        titleTypographyProps={{ component: 'h2', className: 'fontWeight-normal' }}
      />
      <CardContent>
        <Grid container direction='column' justify='space-around' spacing={1}>
          <Grid item>
            <Paper elevation={0}>
              <FormControl fullWidth>
                <DatePicker
                  id='date-picker'
                  label='Date'
                  name='date'
                  inputVariant='outlined'
                  size='small'
                  value={values.date ? new Date(values.date) : null}
                  onChange={date => setFieldValue('date', date?.toISOString())}
                  error={(errors.date && touched.date) as boolean}
                  helperText={touched.date && errors.date}
                />
              </FormControl>
            </Paper>
          </Grid>
          <Grid item>
            <Paper elevation={0}>
              <FormControl fullWidth>
                <TextField
                  label='Description'
                  variant='outlined'
                  size='small'
                  id='description-text-field'
                  value={values.description}
                  name='description'
                  error={(errors.description && touched.description) as boolean}
                  helperText={touched.description && errors.description}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
              </FormControl>
            </Paper>
          </Grid>
        </Grid>
      </CardContent>
      <CardActions>
        <Button variant='contained' color='secondary' disabled={!isValid} onClick={submitForm}>
          Save
        </Button>
        <Button type='button' variant='contained' color='default' onClick={() => resetForm()}>
          Cancel
        </Button>
      </CardActions>
    </Card>
  );
};

const AddHolidaySchema = Yup.object().shape({
  date: Yup.date().nullable().required('Date is required'),
  description: Yup.string().nullable().required('Description is required')
});

export default withFormik<IAddHolidayProps, IHolidayTimeEntryItem>({
  enableReinitialize: true,
  mapPropsToValues: () => {
    return {
      description: '',
      date: null
    };
  },
  validationSchema: AddHolidaySchema,
  handleSubmit: (values, { resetForm, props: { onSave } }) => {
    onSave(values, error => {
      if (!error) {
        resetForm();
      }
    });
  }
})(AddHoliday);
