import { Environments } from '../types';

/**
 * Get site environment (one of 'localhost' | 'stg' | 'prod')
 * @param {string} origin string
 * @returns {Environments} Environments
 */
export const getEnvironment = (): Environments => {
  const environment = window.location.href.includes('localhost')
    ? 'localhost'
    : window.location.href.includes('stg-my.mwks')
    ? 'stg'
    : 'prod';
  return environment;
};

export const environmentLabels = {
  localhost: 'Local',
  stg: 'Stg',
  prod: 'Production'
};
