import { authFetch } from './';
// types
import { IStoryComment, WorkItemTypes, WorkItemStatuses, IWorkItemCounts } from '@shared/types';

/**
 * Get user story comments
 * @param {number} workItemId number
 * @returns {Promise<IStoryComment[]>} Promise<IStoryComment[]>
 */
export const getStoryComments = async (workItemId: number): Promise<IStoryComment[]> => {
  try {
    const { data } = await authFetch.get(`/api/ADO/WorkItems/${workItemId}/comments`);
    return Promise.resolve(data as IStoryComment[]);
  } catch (error) {
    console.error('getStoryComments', error);
    return Promise.reject(error);
  }
};

/**
 * Create a user story comment
 * @param {number} workItemId number
 * @param {string} create string
 * @returns {Promise<any>} any
 */
export const createStoryComment = async (workItemId: number, create: string): Promise<any> => {
  try {
    const res = await authFetch.post(`/api/ADO/WorkItems/${workItemId}/comment`, JSON.stringify(create), {
      headers: {
        'Content-Type': 'application/json'
      }
    });
    return Promise.resolve(res);
  } catch (error) {
    console.error('createStoryComment', error);
    return Promise.reject(error);
  }
};

/**
 * Update a user story comment
 * @param workItemId number
 * @param commentId number
 * @param {string} baseText baseText (the updated text WITHOUT the prepended PM tag and appended signature)
 * @returns {Promise<boolean>} boolean
 */
export const updateStoryComment = async (workItemId: number, commentId: number, baseText: string): Promise<boolean> => {
  try {
    await authFetch.put(`/api/ADO/WorkItems/${workItemId}/comment/${commentId}`, JSON.stringify(baseText), {
      headers: {
        'Content-Type': 'application/json'
      }
    });
    return Promise.resolve(true);
  } catch (error) {
    console.error('updateStoryComment', error);
    return Promise.reject(error);
  }
};

/**
 * Delete a user story comment
 * @param workItemId number
 * @param commentId number
 * @returns {Promise<boolean>} boolean
 */
export const deleteStoryComment = async (workItemId: number, commentId: number): Promise<boolean> => {
  try {
    await authFetch.delete(`/api/ADO/WorkItems/${workItemId}/comment/${commentId}`);
    return Promise.resolve(true);
  } catch (error) {
    console.error('deleteStoryComment', error);
    return Promise.reject(error);
  }
};

/**
 * Get ADO work item counts (used by kanban columns)
 * @param userStoryClientId number
 * @param workItemTypes WorkItemTypes[]
 * @param userStoryIterationId number
 * @param workItemStatus WorkItemStatuses
 * @returns Promise<IWorkItemCounts>
 */
export const getWorkItemCounts = async (
  userStoryClientId: number,
  workItemTypes: WorkItemTypes[],
  userStoryIterationId?: number | string,
  userStoryState?: WorkItemStatuses,
  UserStoryClientProjectIds?: (string | number)[]
): Promise<IWorkItemCounts> => {
  try {
    let query = `?`;
    workItemTypes.forEach((type) => {
      query += `&WorkItemTypes=${type}`;
    });
    if (userStoryIterationId) {
      query += `&UserStoryIterationUuid=${userStoryIterationId}`;
    }
    if (userStoryState) {
      query += `&UserStoryState=${userStoryState}`;
    }
    if (UserStoryClientProjectIds) {
      query += `&UserStoryClientProjectIds=${UserStoryClientProjectIds}`;
    }
    const { data } = await authFetch.get(`/api/ADO/WorkItems/${userStoryClientId}/Count${query}`);
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};
