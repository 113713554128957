import { useState, useEffect, useRef } from 'react';
import { useMedia } from 'react-use';
import { getReleaseStatuses } from '@shared/fetch';
import { IRelease } from '@shared/types';

export const useReleases = (loadReleases: any, selectedClient?: any) => {
  const isDesktop = useMedia('(min-width: 1100px)');
  // Release states //
  const [releases, setReleases] = useState<IRelease[] | []>([]);
  const [releasesLoading, setReleasesLoading] = useState<boolean>(true);
  // Status states //
  // activeStatuses - defines the list of active status IDs //
  const activeStatuses = [1, 2, 3, 4, 5, 6, 7];
  const [selectedStatusIds, setSelectedStatusIds] = useState<any>('');
  const [selectedStatusValues, setSelectedStatusValues] = useState<any[]>([]);
  const [isLoadingStatuses, setIsLoadingStatuses] = useState(false);
  const [statuses, setStatuses] = useState<any[]>([]);
  // Filter states //
  const [hasAppliedFilters, setHasAppliedFilters] = useState<boolean>(false);
  const [filters, setFilters] = useState<{ title?: string; status?: string }>({});
  const [searchTitle, setSearchTitle] = useState<string>('');
  const [page, setPage] = useState<number>(0);
  const [recordCount, setRecordCount] = useState<number>(0);
  const [perPage, setRowsPerPage] = useState<number>(10);
  const [selectedSort, setSelectedSort] = useState<string>('CreatedOn');
  const [sortDirection, setSortDirection] = useState<{ [key: string]: 'Asc' | 'Desc' }>({
    CreatedOn: 'Desc'
  });
  // Modal states //
  const [isFiltersModalOpen, setIsFiltersModalOpen] = useState(false);
  const releaseFiltersButtonRef = useRef<HTMLButtonElement>(null);
  const [modalStyle, setModalStyle] = useState({});

  const toggleFiltersModal = () => {
    setIsFiltersModalOpen(prevState => !prevState);

    if (releaseFiltersButtonRef.current && !isFiltersModalOpen) {
      const buttonRect = releaseFiltersButtonRef.current.getBoundingClientRect();
      setModalStyle({
        ...modalStyle,
        top: `${buttonRect.bottom + window.scrollY}px`,
        left: `${buttonRect.left + window.scrollX}px`,
        transform: 'translate(0, 0)'
      });
    }
  };

  const handleStatuses = (statuses: any) => {
    return statuses ? statuses : activeStatuses;
  };

  const fetchReleaseStatuses = async () => {
    setIsLoadingStatuses(true);
    try {
      const res = await getReleaseStatuses();
      const filteredSelectedStatuses = res.filter((status: any) => status.shorthand !== 'Complete');
      setStatuses(res);
      setSelectedStatusValues(filteredSelectedStatuses);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoadingStatuses(false);
    }
  };

  useEffect(() => {
    fetchReleaseStatuses();
  }, []);

  useEffect(() => {
    if (selectedClient) {
      loadReleases({
        page,
        perPage,
        selectedSort,
        sortDirection,
        selectedStatusIds,
        searchTitle,
        setReleases,
        setRecordCount,
        setReleasesLoading,
        handleStatuses,
        selectedClient
      });
    } else {
      loadReleases({
        page,
        perPage,
        selectedSort,
        sortDirection,
        selectedStatusIds,
        searchTitle,
        setReleases,
        setRecordCount,
        setReleasesLoading,
        handleStatuses
      });
    }
  }, [page, perPage, hasAppliedFilters, filters, selectedSort, sortDirection, selectedClient]);

  useEffect(() => {
    if (selectedStatusIds || hasAppliedFilters === true || selectedSort !== 'CreatedOn') {
      setSelectedStatusIds('');
      setSelectedSort('CreatedOn');
      setHasAppliedFilters(false);
    } else {
      loadReleases({
        page,
        perPage,
        selectedSort,
        sortDirection,
        selectedStatusIds,
        searchTitle,
        setReleases,
        setRecordCount,
        setReleasesLoading,
        handleStatuses,
        selectedClient
      });
    }
  }, [selectedClient]);

  return {
    isDesktop,
    releases,
    releasesLoading,
    selectedStatusIds,
    setSelectedStatusIds,
    selectedStatusValues,
    setSelectedStatusValues,
    hasAppliedFilters,
    setHasAppliedFilters,
    filters,
    setFilters,
    isLoadingStatuses,
    statuses,
    setStatuses,
    searchTitle,
    setSearchTitle,
    page,
    setPage,
    recordCount,
    perPage,
    setRowsPerPage,
    selectedSort,
    setSelectedSort,
    sortDirection,
    setSortDirection,
    isFiltersModalOpen,
    toggleFiltersModal,
    releaseFiltersButtonRef,
    modalStyle,
    fetchReleaseStatuses,
    setReleases,
    setRecordCount,
    setReleasesLoading,
    handleStatuses
  };
};