import React, { FC } from 'react';
import clsx from 'clsx';
import { makeStyles, Theme } from '@material-ui/core/styles';
// Components
import { SubNavItem } from './SubNavItem';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
// Types
import { ISubNavItem } from './SubNavItem';
import { IUserProps, IAppState } from '@shared/types';
import { useSelector } from 'react-redux';
import { INavItem } from './NavItem';

export interface ISubNavMenu {
  title: string;
  subNav: ISubNavItem[];
  isNavOpen: boolean;
  isActive: boolean;
  className?: string;
  onNav?: (event: {}) => void;
  isMobile?: boolean;
}

export const SubNavMenu: FC<ISubNavMenu> = ({ subNav, isNavOpen, isActive, className, title, onNav, isMobile }) => {
  const classes = useStyles({ isNavOpen });
  const user: IUserProps = useSelector((state: IAppState) => state.user);
  const { userAccess, featureFlags } = user;
  const { selectedClient } = useSelector((state: IAppState) => ({ ...state.extranet }));
  const clientReleases = !!featureFlags.find(flag => flag.name === 'mymwks-client-release-notes')?.hasAccess;
  const accessSoftwareReleases = !!featureFlags.find(flag => flag.name === 'software-release')?.hasAccess;
  const sharedFilesUpdate = !!featureFlags.find(flag => flag.name === 'shared-files-update')?.hasAccess;
  const hasZendeskForm = !!featureFlags.find(flag => flag.name === 'zendesk-form')?.hasAccess;

  const userHasAccess = (item: INavItem | ISubNavItem) => {
    return !item.userAccess || userAccess[item.userAccess];
  };

  const subNavItemShouldBeInMenu = (item: ISubNavItem) => {
    if (user.userAccess.ClientOnly && !selectedClient?.usesProgressReports && item?.title === 'Progress Reports') {
      return false;
    }
    if (!clientReleases && item?.title === 'Releases') {
      return false;
    }
    if (!hasZendeskForm && item?.title === 'Zendesk Form') {
      return false;
    }
    if (!sharedFilesUpdate && item?.title === 'Files V2') {
      return false;
    }
    if (item?.title === 'Software Releases' && !accessSoftwareReleases) {
      return false;
    }
    return userHasAccess(item);
  };

  const showStaticMenu = isActive && isNavOpen;
  const clientBacklogRequest = !!featureFlags.find(flag => flag.name === 'client-backlog-request')?.hasAccess;
  
  return (
    <List
      disablePadding
      className={clsx(className, classes.root, {
        [classes.staticMenu]: showStaticMenu,
        [classes.hoverMenu]: !showStaticMenu,
        [classes.hoverMenuNavClosed]: !isNavOpen
      })}
    >
      {(!isNavOpen || !isActive) && (
        <ListItem disableGutters className={classes.menuHeader}>
          <ListItemText className={classes.textMenuHeader} primary={title} onClick={onNav} />
        </ListItem>
      )}
      {subNav
        .filter(subNavItem => {
          if (clientBacklogRequest) {
            return true;
          }
          return !clientBacklogRequest && subNavItem.title !== 'Pending Requests';
        })
        .filter(subNavItemShouldBeInMenu)
        .map(navItem => (
          <SubNavItem key={navItem.path} {...navItem} onNav={onNav} showStaticMenu={showStaticMenu} isMobile={isMobile} />
        ))}
    </List>
  );
};

const useStyles = makeStyles<Theme, { isNavOpen: boolean }>(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.common.white,
    overflowX: 'hidden',
    alignItems: 'left',
    marginLeft: ({ isNavOpen }) => (isNavOpen ? 0 : theme.spacing(0.75))
  },
  staticMenu: {
    position: 'static',
    display: 'flex',
    width: '100%'
  },
  hoverMenu: {
    left: 284,
    top: 0,
    position: 'absolute',
    display: 'none',
    width: 232,
    boxShadow: '0px 10px 30px 0px rgba(0, 0, 0, 0.1)'
  },
  hoverMenuNavClosed: {
    left: 48,
    width: 240
  },
  menuHeader: {
    color: theme.palette.secondary.main,
    display: 'inline-block',
    position: 'relative',
    paddingLeft: theme.spacing(0.75)
  },
  textMenuHeader: {
    textAlign: 'left'
  }
}));
