import React, { FC } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
// Components
import { FormLabel, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails, Typography, Button } from '@material-ui/core';
import { ExpandMore, ThumbUp, DeleteForever } from '@material-ui/icons';
// Types
import { IExtranetUserRequest } from '@shared/types';
// helpers
import { formatShortFriendlyDateWithTime } from '@shared/helpers';

interface IMobileUser {
  original: IExtranetUserRequest;
}

export const MobileUserRequest: FC<IMobileUser> = ({ original }) => {
  const classes = useStyles();
  const { firstName, lastName, email, requestDate } = original;
  return (
    <ExpansionPanel className={classes.root}>
      <ExpansionPanelSummary className={classes.expansionPanel} expandIcon={<ExpandMore />} aria-controls='panel1a-content' id='panel1a-header'>
        <div className={classes.panelSummaryWrapper}>
          <Typography component='span'>
            {firstName} {lastName}
          </Typography>
        </div>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails className={classes.details}>
        <FormLabel component='h2'>First Name</FormLabel>
        <Typography component='span'>{firstName || '--'}</Typography>

        <FormLabel component='h2'>Last Name</FormLabel>
        <Typography component='span'>{lastName || '--'}</Typography>

        <FormLabel component='h2'>Email</FormLabel>
        <Typography component='span'>{email || '--'}</Typography>

        <FormLabel component='h2'>Request Date/Time</FormLabel>
        <Typography component='span'>{requestDate ? formatShortFriendlyDateWithTime(requestDate) : '--'}</Typography>

        <Button
          color='primary'
          className={classes.approveButton}
          startIcon={<ThumbUp />}
          onClick={() => {
            console.log('approve');
          }}
        >
          Approve
        </Button>

        <Button
          color='inherit'
          className={classes.deleteButton}
          startIcon={<DeleteForever />}
          onClick={() => {
            console.log('delete');
          }}
        >
          Delete
        </Button>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    maxWidth: '100%'
  },
  expansionPanel: {
    padding: '0px 24px'
  },
  panelSummaryWrapper: {
    display: 'flex',
    alignItems: 'flex-start',
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start'
  },
  approveButton: {
    marginTop: theme.spacing(0.5)
  },
  deleteButton: {
    marginTop: theme.spacing(0.5),
    color: theme.palette.error.main
  }
}));
