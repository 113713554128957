import { handleActions } from 'redux-actions';
// Types
import { IAdminState } from '@shared/types';

const initialState: IAdminState = {
  billingTypes: [],
  employees: []
};

export default handleActions(
  {
    SET_BILLING_TYPES: (state: any, { payload: { billingTypes } }) => ({ ...state, billingTypes }),
    SET_EMPLOYEES: (state: any, { payload: { employees } }) => ({ ...state, employees })
  },
  initialState
);
