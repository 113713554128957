import React, { FC, HTMLAttributes } from 'react';
import clsx from 'clsx';
import { makeStyles, createStyles } from '@material-ui/core/styles';

interface ILoaderWrapperProps extends HTMLAttributes<HTMLDivElement> {}

export const LoaderWrapper: FC<ILoaderWrapperProps> = ({ children, ...props }) => {
  const classes = loaderWrapperStyles();
  return (
    <div {...props} className={clsx(classes.loaderWrapper, props.className)}>
      {children}
    </div>
  );
};

const loaderWrapperStyles = makeStyles(() => {
  return createStyles({
    loaderWrapper: {
      display: 'flex', // needed for User Stories Kanban
      flex: 1,
      flexDirection: 'column', // needed for User Stories Kanban
      height: '100%',
      position: 'relative',
      width: '100%'
    }
  });
});
