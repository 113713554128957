import React, { FC, useState, useRef } from 'react';
import { useMedia } from 'react-use';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Typography, IconButton, Grid } from '@material-ui/core';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ExpandLess from '@material-ui/icons/ExpandLess';
import clsx from 'clsx';

interface IProgressReportCardProps {
  title: string;
  actionButton?: JSX.Element;
  className?: string;
}

export const ProgressReportCard: FC<IProgressReportCardProps> = ({ title, className, actionButton, children }) => {
  const [isOpen, setOpen] = useState<boolean>(true);
  const isMobile = useMedia('(max-width: 960px)');
  const classes = useStyles({ isOpen });
  const contentContainer = useRef<HTMLDivElement>(null);

  if (!isMobile && !isOpen) {
    setOpen(true);
  }

  return (
    <div className={clsx(classes.wrapper, className)}>
      <Grid
        container
        justify={actionButton ? undefined : 'space-between'}
        alignItems='center'
        className={clsx(classes.background, actionButton ? classes.hasActionButton : undefined)}
      >
        <Typography className={classes.title}>{title}</Typography>
        {actionButton && actionButton}
        <IconButton className={clsx(classes.icon, actionButton ? classes.alignRight : undefined)} onClick={() => setOpen(!isOpen)}>
          {isOpen ? <ExpandLess /> : <ExpandMore />}
        </IconButton>
      </Grid>
      <div ref={contentContainer} className={classes.content}>
        {children}
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    width: '100%',
    margin: theme.spacing(0, 0, 1, 0),
    [theme.breakpoints.up('md')]: {
      margin: theme.spacing(1, 0)
    }
  },
  title: {
    fontWeight: 600
  },
  background: {
    color: theme.palette.common.white,
    paddingLeft: theme.spacing(0.75),
    backgroundColor: theme.palette.primary.main,
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(0.75)
    }
  },
  content: ({ isOpen }: { isOpen: boolean }) =>
    isOpen
      ? {
          border: `1.5px solid ${theme.palette.grey[300]}`,
          borderTop: 0,
          padding: theme.spacing(0.75),
          borderBottomRightRadius: 5,
          borderBottomLeftRadius: 5,
          maxHeight: theme.spacing(30),
          overflow: 'auto',

          '.budgetAccordion &': {
            [theme.breakpoints.up('md')]: {
              overflow: 'visible',
              maxHeight: 'none'
            }
          }
        }
      : {
          height: 0,
          overflow: 'hidden'
        },
  icon: {
    display: 'block',
    color: theme.palette.common.white,
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  },
  alignRight: {
    [theme.breakpoints.down('sm')]: {
      marginLeft: 'auto'
    }
  },
  hasActionButton: {
    [theme.breakpoints.up('md')]: {
      justifyContent: 'space-between'
    }
  }
}));
