import { IconButton, List, ListItem, ListItemText, ListSubheader, Popover, Tooltip } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { FileCopyOutlined, Lock } from '@material-ui/icons';
import React, { FC, useEffect, useState } from 'react';
import { ITimeTrackingEntry } from '../../../shared/types';
import { getSourceInfo } from '@shared/fetch';
import { Loader } from '../loader';

const ClickToCopy: FC<{ content: string }> = ({ content }) => {
  const [copied, setCopied] = useState(false);

  return (
    <Tooltip title={copied ? 'Copied' : 'Copy to clipboard'}>
      <IconButton
        size='small'
        onClick={async () => {
          try {
            await navigator.clipboard.writeText(content);
            setCopied(true);
          } catch (error) {
            // Skip.
          }
        }}
      >
        <FileCopyOutlined fontSize='small' />
      </IconButton>
    </Tooltip>
  );
};

export const TimeEntrySourceCard: FC<{
  anchorEl: HTMLButtonElement | null;
  close(): void;
  isOpen: boolean;
  timeEntry: ITimeTrackingEntry;
}> = ({ anchorEl, close, isOpen, timeEntry }) => {
  const classes = useStyles();

  const [copied, setCopied] = useState(false);

  const [isSourceInfoLoading, setIsSourceInfoLoading] = useState(true);
  const fetchSourceInfo = async () => {
    try {
      setIsSourceInfoLoading(true);
      let sourceInfo;
      if (timeEntry.timeEntryId) {
        sourceInfo = await getSourceInfo(timeEntry.timeEntryId);
      } else if (!isNaN(Number(timeEntry.externalId))) {
        sourceInfo = await getSourceInfo(null, timeEntry.source, Number(timeEntry.externalId));
      }
      if (!timeEntry.tfsid) timeEntry.tfsid = sourceInfo.tfsid;
      if (!timeEntry.tfsStoryId) timeEntry.tfsStoryId = sourceInfo.tfsStoryId;
      if (!timeEntry.tfsStoryTitle) timeEntry.tfsStoryTitle = sourceInfo.tfsStoryTitle;
      if (!timeEntry.zendeskTicketNumber) timeEntry.zendeskTicketNumber = sourceInfo.zendeskTicketNumber;
      setIsSourceInfoLoading(false);
    } catch (error) {
      setIsSourceInfoLoading(false);
      console.error(error);
    }
  };

  useEffect(() => {
    if (!copied) return;
    setTimeout(() => setCopied(false), 1500);
  }, [copied]);

  useEffect(() => {
    if (timeEntry.source !== 'Manual' && timeEntry.source !== 'Office') {
      isOpen && fetchSourceInfo();
    } else {
      setIsSourceInfoLoading(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  return (
    <Popover
      open={isOpen}
      anchorEl={anchorEl}
      onClose={close}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
    >
      {isSourceInfoLoading && <Loader loaderWrapperClassName={classes.loader} type={'inline'} size={'small'} />}
      {!isSourceInfoLoading && timeEntry.isLocked && (
        <div className={classes.cardLockIcon}>
          <Lock color='disabled' fontSize='large' />
        </div>
      )}

      {!isSourceInfoLoading && (
        /** LIST OF ENTRIES */
        <List>
          <ListSubheader>Additional Information</ListSubheader>
          {timeEntry.source && (
            <ListItem>
              <ListItemText secondary='Source'>
                {timeEntry.source === 'ADO' && timeEntry.tfsid ? (
                  <>
                    {`${timeEntry?.source} ${timeEntry?.description} Task `}
                    <a
                      href={`https://dev.azure.com/mwks/internal/_workitems/edit/${timeEntry?.tfsid}`}
                      target='_blank'
                      rel='noopener noreferrer'
                      className={classes.marginRight}
                    >
                      {timeEntry?.tfsid}
                    </a>

                    <ClickToCopy content={timeEntry.tfsid!.toString()} />
                  </>
                ) : (
                  timeEntry.source
                )}
              </ListItemText>
            </ListItem>
          )}
          {timeEntry.zendeskTicketNumber && (
            <ListItem>
              <ListItemText secondary='Ticket Number'>
                <a
                  href={`https://mercurynewmedia.zendesk.com/agent/tickets/${timeEntry.zendeskTicketNumber}`}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  {timeEntry.zendeskTicketNumber}
                </a>
              </ListItemText>
            </ListItem>
          )}
          {timeEntry.description && timeEntry.source === 'ADO' && (
            <ListItem>
              <ListItemText secondary='Story Name'>
                {timeEntry.tfsStoryId ? (
                  <>
                    {`${timeEntry?.tfsStoryTitle ?? ''} `}
                    <a
                      href={`https://dev.azure.com/mwks/internal/_workitems/edit/${timeEntry.tfsStoryId}`}
                      target='_blank'
                      rel='noopener noreferrer'
                      className={classes.marginRight}
                    >
                      {timeEntry?.tfsStoryId}
                    </a>

                    <ClickToCopy content={timeEntry.tfsStoryId!.toString()} />
                  </>
                ) : (
                  timeEntry.source
                )}
              </ListItemText>
            </ListItem>
          )}
        </List>
      )}
    </Popover>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  cardLockIcon: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '4px 4px 0 0'
  },

  loader: {
    padding: theme.spacing(1)
  },
  marginRight: {
    marginRight: theme.spacing(0.5)
  }
}));
