import React, { FC, useState, useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { useLocation } from 'react-router-dom';
// Components
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ExpandLess from '@material-ui/icons/ExpandLess';
// Types
import { SubNavMenu } from './SubNavMenu';
import { INavItem } from './NavItem';

interface IMobileNavItemProps extends INavItem {
  onNav?: (event: {}) => void;
}

const useStyles = makeStyles(theme => ({
  link: {
    textDecoration: 'none',
    color: theme.palette.text.secondary
  },
  listItem: {
    overflowX: 'hidden'
  },
  listItemActive: {
    color: theme.palette.secondary.main
  },
  icon: {
    '& > svg': {
      fill: theme.palette.primary.main
    }
  },
  iconActive: {
    '& > svg': {
      fill: theme.palette.secondary.main
    }
  },
  expandIcon: {
    marginLeft: 'auto'
  }
}));

const splitPathString = (path: string) => {
  return path.split('/').filter((x: string) => x);
};

export const MobileNavItem: FC<IMobileNavItemProps> = ({ title, rootPath, icon, subNav, onNav }) => {
  const classes = useStyles();

  const [expanded, setExpanded] = useState<boolean>(false);
  const { pathname } = useLocation();

  // determine if this rootPath is active so we can highlight
  // when nav is closed or show menu when nav is open
  const splitPathname = splitPathString(pathname);
  const splitPath = splitPathString(rootPath);
  const active = splitPath[0] === splitPathname[0];

  useEffect(() => {
    if (active) {
      setExpanded(true);
    }
  }, [active]);

  return (
    <>
      <ListItem className={classes.listItem} button key={title} divider onClick={() => setExpanded(!expanded)}>
        <ListItemIcon
          classes={{
            root: clsx(classes.icon, {
              [classes.iconActive]: active
            })
          }}
        >
          {icon}
        </ListItemIcon>
        <ListItemText
          primary={title}
          className={clsx({
            [classes.listItemActive]: active
          })}
        />
        <ListItemIcon>{expanded ? <ExpandLess className={classes.expandIcon} /> : <ExpandMore className={classes.expandIcon} />}</ListItemIcon>
      </ListItem>
      {subNav && (
        <SubNavMenu className='sub-nav-menu' title={title} isNavOpen={true} isMobile isActive={expanded} subNav={subNav || []} onNav={onNav} />
      )}
    </>
  );
};
