import React, { FC } from 'react';
import { Button, Theme, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Loader } from '@shared/components/loader';

interface ITableLoadMoreButtonProps {
  isShowing: boolean;
  isLoading: boolean;
  onClick: () => void;
}

/**
 * A button for loading more results into a table.
 * Typically used by passing to a `Table` as the `LoadMoreComponent`.
 */
const TableLoadMoreButton: FC<ITableLoadMoreButtonProps> = ({ isShowing, isLoading, onClick }) => {
  const classes = useStyles();

  if (isShowing) {
    return (
      <Grid container alignItems='center' justify='center' className={classes.loadMoreWrapper}>
        <Button
          data-testid='table-load-more-button'
          color='primary'
          disabled={isLoading}
          variant='contained'
          onClick={onClick}
          aria-label='load-more'
        >
          {isLoading ? <Loader>Loading...</Loader> : 'Load More'}
        </Button>
      </Grid>
    );
  }

  return null;
};

const useStyles = makeStyles((theme: Theme) => ({
  loadMoreWrapper: {
    marginTop: theme.spacing(1)
  }
}));

export default TableLoadMoreButton;
