import React, { FC } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
// Types
import { ITableSummaryData } from '@shared/types';

interface TableSummaryProps {
  data: ITableSummaryData;
}

export const TableSummary: FC<TableSummaryProps> = ({ data }) => {
  const classes = tableSummaryStyles();
  return (
    <div className={classes.tableSummaryContainer}>
      {data.title && <div>{data.title}</div>}
      <div className={data.entries.length > 2 ? classes.longValueContainer : classes.valueContainer}>
        {data.entries.map((d, i) => (
          <div key={`${d.value}_${i}`} className={classes.values}>
            <span className={classes.summaryDataLabel}>{d.key}:</span>
            <span className={classes.value}>{d.value}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

const tableSummaryStyles = makeStyles((theme: Theme) => ({
  tableSummaryContainer: {
    display: 'flex',
    alignItems: 'center',
    paddingRight: '.35vw',
    [theme.breakpoints.down('md')]: {
      width: 200
    }
  },
  valueContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column'
    }
  },
  longValueContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    flexDirection: 'column',
    marginTop: '.25rem'
  },
  values: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  summaryDataLabel: {
    width: 133,
    fontWeight: 'bold'
  },
  value: {
    marginLeft: '.5rem',
    marginRight: '.5rem'
  }
}));
