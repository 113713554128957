import { handleActions } from 'redux-actions';
// Types
import { IExtrantState } from '@shared/types';

const initialState: IExtrantState = {
  clients: [],
  selectedClient: null,
  isDisabled: false
};

export default handleActions(
  {
    SET_EXTRANET_CLIENT: (state: any, { payload: { client } }) => ({ ...state, selectedClient: client }),
    SET_EXTRANET_CLIENTS: (state: any, { payload: { clients } }) => ({ ...state, clients }),
    SET_CLIENT_SWITCHER_DISABLED: (state: any, { payload: { isDisabled } }) => ({ ...state, isDisabled })
  },
  initialState
);
