import { makeStyles, Theme } from '@material-ui/core/styles';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMedia } from 'react-use';
import ReactGA from 'react-ga';
import { useLocation, useHistory, Link } from 'react-router-dom';
// components
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';

import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import Print from '@material-ui/icons/Print';
import { DynamicFeed, WebAsset } from '@material-ui/icons';

import { UserStoryGridView } from '../components/UserStoryGridView';
import { GridLoader, LoaderWrapper } from '@shared/components/loader';
import { Empty } from '@shared/components/empty';
import { Toast } from '@shared/components/toast';
import { Page } from '@shared/components/layout';
import { CustomMessage } from '@shared/components/custom-message';
import { UserStoryDetail } from '../components/UserStoryDetail';
import { Modal } from '@shared/components/modals/Modal';
// fetch
import {
  getExtranetProjects,
  getExtranetUserStoryDetail,
  getExtranetClientMentionLinks,
  getClientProjects,
  getExtranetUserBacklogEpics,
  getExtranetUserBacklogFeatures,
  getExtranetUserBacklogStories,
  getExtranetBacklogExport,
  getExtranetWorkItemParents
} from '@shared/fetch';
// helpers
import { formatWorkItemType } from '@shared/helpers';
// redux
import { setSelectedProjectId, setClientMentionLinks, setExtranetClient } from '@shared/redux/actions';
// types
import {
  IUserProject,
  IUserEpic,
  IUserStoryFeature,
  IExtranetSprintItem,
  IUserStory,
  IUserStoryDetail,
  IAppState,
  IModifiedSearchValue,
  WorkItemTypes,
  WorkItemStatuses
} from '@shared/types';
import { IBacklogParams } from '@shared/types/extranet';
// other
import { Tooltip } from '@material-ui/core';
import { ExportButton } from '@shared/components/buttons';

const DEFAULT_PAGE = 0;
const DEFAULT_PAGE_SIZE = 10;

export type UserStoryViews = 'Grid' | 'Backlog';

export const Backlog = () => {
  const history = useHistory();
  const location = useLocation();
  // styles
  const classes = backlogStyles();
  // check for workitem query param
  const query = new URLSearchParams(location.search);
  // state
  // search state
  const [searchResults, setSearchResults] = useState<IModifiedSearchValue[] | null>(null);
  // sprint state
  const [selectedSprint, setSelectedSprint] = useState<IExtranetSprintItem | null>({ name: 'Backlog', projectIterationUuid: null });
  const [isLoadingProjects, setLoadingProjects] = useState<boolean>(true);
  const [isLoadingEpics, setLoadingEpics] = useState<boolean>(false);
  const [isLoadingFeatures, setLoadingFeatures] = useState<boolean>(false);
  const [isLoadingFeaturesArray, setIsLoadingFeaturesArray] = useState<number[]>([]);
  const [isLoadingStories, setLoadingStories] = useState<boolean>(true);
  // set this true by default if we are loading the story directly from a link
  const [isLoadingStoryDetail, setLoadingStoryDetail] = useState<boolean>(query && query.get('workitem') ? true : false);
  const [epics, setEpics] = useState<IUserEpic[] | null>(null);
  const [deepLinkEpic, setDeepLinkEpic] = useState<IUserEpic[] | null>(null);
  const [features, setFeatures] = useState<IUserStoryFeature[]>([]);
  const [deepLinkFeature, setDeepLinkFeature] = useState<IUserStoryFeature[]>([]);
  const [projects, setProjects] = useState<IUserProject[] | null>(null);
  const [project, setProject] = useState<IUserProject | null>(null);
  const [selectedStories, setSelectedStories] = useState<IUserStoryDetail[]>([]);
  const [selectedUserStories, setSelectedUserStories] = useState<IUserStory[]>([]);
  const [deepLinkUserStory, setDeepLinkUserStory] = useState<IUserStory[]>([]);
  const [allUserStories, setAllUserStories] = useState<IUserStory[] | null>(null);
  const [allFeatureUserStories, setAllFeatureUserStories] = useState<IUserStoryFeature[]>([]);
  const [currentEpicId, setCurrentEpicId] = useState<number | null>(null);
  const [currentFeatureId, setCurrentFeatureId] = useState<number | null>(null);
  const [currentStoryId, setCurrentStoryId] = useState<number | null>((query && Number(query.get('workitem'))) || null);
  const [page, setPage] = useState<number>(DEFAULT_PAGE);
  const [perPage, setPerPage] = useState<number>(DEFAULT_PAGE_SIZE);
  const [recordCount, setRecordCount] = useState<number>(0);
  const [selectedStateFilter, setStateFilter] = useState<WorkItemStatuses | ''>('');
  const [selectedTypeFilter, setTypeFilter] = useState<WorkItemTypes | null>('Epic');
  const [showStoryModal, setShowStoryModal] = useState((query && !!query.get('workitem')) || false);
  const [isExporting, setisExporting] = useState<boolean>(false);
  const [isDeepLink, setIsDeepLink] = useState<boolean>(false);
  const [noWorkItems, setNoWorkItems] = useState<boolean>(false);
  const [resetToBacklog, setResetToBacklog] = useState<boolean>(false);

  const [successMessage, setSuccessMessage] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');

  const [showOnlyOpen, setShowOnlyOpen] = useState<boolean>(false);
  // const [isExporting, setExporting] = useState(false);
  // const [exportProgress, setExportProgress] = useState<string>('');
  const [isShowingMore, setShowingMore] = useState(false);
  const [error, setError] = useState<Error | null>(null);
  const [isUserStoryNotAuthorized, setUserStoryNotAuthorized] = useState<boolean>(false);

  // This is a dictionary that will make sure we aren't calling the API more than once per Epic
  const [epicsWithFeatures, setEpicsWithFeatures] = useState({});

  const [expanded, setExpanded] = useState<{ [id: number]: boolean }>({});

  // breakpoints
  const isMobile = useMedia('(max-width: 959px)');
  // const isSmallMobile = useMedia('(max-width: 768px)');

  // redux
  const dispatch = useDispatch();
  const { selectedClient, clients } = useSelector((state: IAppState) => state.extranet);
  const { selectedProjectId } = useSelector((state: IAppState) => state.clients);
  const gaLabelClientValue: string = selectedClient ? ` | Client: ${selectedClient?.name}` : '';
  const [backlogParams, setBacklogParams] = useState<IBacklogParams | undefined>(undefined);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const obj: IBacklogParams = {
      clientId: params.get('clientId') || '',
      epicId: params.get('epicId') || '',
      featureId: params.get('featureId') || '',
      userStoryId: params.get('userStoryId') || ''
    };

    setBacklogParams(obj);
  }, [location.search]);

  useEffect(() => {
    if (backlogParams?.clientId) {
      fetchWorkItemParents();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [backlogParams]);

  useEffect(() => {
    if (resetToBacklog) {
      resetFromDeepLink();
      history.push('/clients/backlog');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetToBacklog]);

  // Show/hide Sprint Selector based on client planning type
  useEffect(() => {
    if (!selectedClient) {
      return;
    }
    if (selectedClient?.clientId !== Number(backlogParams?.clientId)) {
      resetFromDeepLink();
      history.push('/clients/backlog');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedClient]);

  useEffect(() => {
    fetchClientProjects();
    fetchMentionLinks();
    if (selectedProjectId) {
      setSelectedSprint({ name: 'Backlog', projectIterationUuid: null });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedClient]);

  useEffect(() => {
    setEpicsWithFeatures({});
    setExpanded({});
  }, [showOnlyOpen]);

  // fetch Epics, Features, or Stories
  useEffect(() => {
    if (selectedSprint && selectedClient && !searchResults && !backlogParams?.clientId) {
      if (!selectedTypeFilter || selectedTypeFilter === 'Epic') {
        fetchEpicsAndFeatures();
      }
      if (selectedTypeFilter === 'Story') {
        fetchAllUserStories();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, perPage, project, selectedClient, selectedSprint, selectedStateFilter, selectedTypeFilter, showOnlyOpen]);

  // fetch features for an epic
  useEffect(() => {
    if (currentEpicId && !backlogParams?.clientId) {
      if (!epicsWithFeatures[currentEpicId]) {
        fetchFeatures(currentEpicId, true);
        setEpicsWithFeatures({ ...epicsWithFeatures, [currentEpicId]: true });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentEpicId]);

  // fetch all the stories in a feature
  useEffect(() => {
    if (currentFeatureId) {
      fetchUserStories();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentFeatureId]);

  // fetch the story detail
  useEffect(() => {
    // only make the call if we haven't previously loaded the info
    const currentLoadedStory = selectedStories.find(story => story.id === currentStoryId);
    if (currentStoryId && !currentLoadedStory && selectedClient) {
      fetchUserStoryDetail();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStoryId, selectedClient]);

  // update the UI when changing between views
  useEffect(() => {
    setCurrentEpicId(null);
    setCurrentFeatureId(null);
    if (!query || (query && !query.get('workitem'))) {
      setCurrentStoryId(null);
    }

    setTypeFilter(null);

    if (selectedProjectId) {
      setStateFilter('New');
    } else {
      setStateFilter('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // reset selectedProjectId if it's set and we navigate away from the page
    // to ensure we don't go back into a single project backlog view when the user comes back
    return history.listen(location => {
      if (location.pathname !== '/clients/board' && selectedProjectId) {
        dispatch(setSelectedProjectId(null));
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);

  // api calls
  const fetchWorkItemParents = async () => {
    setIsDeepLink(true);
    try {
      const res = await getExtranetWorkItemParents(backlogParams);
      setCurrentEpicId(res.epic.id);
      setDeepLinkEpic([res.epic]);

      if (res.userStory) {
        setCurrentFeatureId(res.feature.id);
        setDeepLinkFeature([res.feature]);
        setCurrentStoryId(res.userStory.id);
        setDeepLinkUserStory([res.userStory]);
      } else if (res.feature) {
        setDeepLinkFeature([res.feature]);
        setCurrentFeatureId(res.feature.id);
        fetchUserStories();
      } else if (res.epic) {
        fetchFeatures(res.epic.id, true);
        fetchUserStories();
      }
    } catch (error) {
      console.log(error);
      setNoWorkItems(true);
    }
  };

  const fetchClientProjects = async () => {
    if (selectedClient) {
      setLoadingProjects(true);
      setEpics(null);
      setAllFeatureUserStories([]);
      setFeatures([]);
      setAllUserStories([]);
      setSearchResults(null);
      setPage(DEFAULT_PAGE);
      setPerPage(DEFAULT_PAGE_SIZE);
      if (!query || (query && !query.get('workitem'))) {
        setCurrentStoryId(null);
      }
      try {
        const ClientId = selectedClient.clientId;
        const [extranetProjects, clientProjects] = await Promise.all([getExtranetProjects(ClientId), getClientProjects({ ClientId })]);
        // Remove user projects that should not be shown in the client portal
        const showableProjects = extranetProjects.filter(
          x => clientProjects.find(cp => cp.clientProjectId === x.clientProjectId)?.shouldShowInClientPortal ?? true
        );
        setProjects(showableProjects);
        const foundProject = selectedProjectId ? extranetProjects.find(project => project.clientProjectId === selectedProjectId) : null;

        setProject(foundProject ?? null);
      } catch (error) {
        console.error(error);
      } finally {
        setLoadingProjects(false);
      }
    }
  };

  const baseFilters = {
    projectId: project?.clientProjectId,
    showOnlyOpen: showOnlyOpen
  };

  // used only by Backlog view
  const fetchEpicsAndFeatures = async () => {
    if (selectedClient) {
      setEpics(null);
      setFeatures([]);
      setPage(DEFAULT_PAGE);
      if (!isLoadingEpics) {
        setLoadingEpics(true);
      }
      setAllFeatureUserStories([]);
      setAllUserStories([]);
      setPerPage(DEFAULT_PAGE_SIZE);
      if (!query || (query && !query.get('workitem'))) {
        setCurrentStoryId(null);
      }
      try {
        const res = await getExtranetUserBacklogEpics(selectedClient.clientId, baseFilters);

        if (res.length > 1) {
          setFeatures([]);
          setEpics(res);
          return;
        }
        const featurePromises = res.map(async epic => {
          return await getExtranetUserBacklogFeatures(selectedClient.clientId, {
            ...baseFilters,
            epicId: epic.id
          });
        });
        const featureRes = await Promise.all(featurePromises);

        setEpics(res);

        const featureRecords = featureRes.map(feature => feature).flat();
        setFeatures(featureRecords);
      } catch (error) {
        console.error(error);
      } finally {
        setLoadingEpics(false);
      }
    }
  };

  const fetchFeatures = async (epicId?: number, isBacklog = false) => {
    if (selectedClient || backlogParams?.clientId) {
      try {
        // only reset epics if we're not loading an epics' children features
        if (!epicId) {
          setCurrentEpicId(null);
          setEpics([]);
        }
        if (!query || (query && !query.get('workitem'))) {
          setCurrentStoryId(null);
        }
        setCurrentFeatureId(null);
        setLoadingFeatures(true);

        const clientId = selectedClient?.clientId || Number(backlogParams?.clientId);

        if (epicId) setIsLoadingFeaturesArray(prev => [...prev, epicId]);
        const result = await getExtranetUserBacklogFeatures(
          clientId,
          epicId
            ? {
                ...baseFilters,
                epicId
              }
            : baseFilters
        );

        setFeatures(prev => [...(isShowingMore || isBacklog ? prev : []), ...result]);
        if (deepLinkFeature.length < 1 && !backlogParams?.featureId && !backlogParams?.userStoryId) {
          setDeepLinkFeature([...result]);
        }
      } catch (error) {
        console.error(error);
        setFeatures([]);
        setRecordCount(0);
      } finally {
        setLoadingFeatures(false);
        setShowingMore(false);
        if (epicId) setIsLoadingFeaturesArray(prev => prev.filter(id => id !== epicId));
      }
    }
  };

  const fetchUserStories = async () => {
    if (backlogParams?.userStoryId) {
      setLoadingStories(false);
      return;
    }
    if (currentFeatureId && !backlogParams?.userStoryId && (selectedClient || backlogParams?.clientId)) {
      setSelectedUserStories([]);
      setLoadingStories(true);

      const clientId = selectedClient?.clientId || Number(backlogParams?.clientId);
      try {
        const res = await getExtranetUserBacklogStories(clientId, {
          ...baseFilters,
          featureId: currentFeatureId
        });
        setAllUserStories(prev => [...(prev ? prev : []), ...res]);
        setSelectedUserStories([...res]);
        if (!backlogParams?.userStoryId) {
          setDeepLinkUserStory([...res]);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoadingStories(false);
      }
    }
  };

  // used by Backlog
  const fetchAllUserStories = async () => {
    if (selectedClient && selectedSprint) {
      setAllUserStories(null);

      setAllFeatureUserStories([]);
      if (!query || (query && !query.get('workitem'))) {
        setCurrentStoryId(null);
      }
      setEpics(null);
      setFeatures([]);
      setLoadingStories(true);

      try {
        const res = await getExtranetUserBacklogStories(selectedClient.clientId);

        setAllUserStories([...(isShowingMore && allUserStories ? allUserStories : []), ...res]);
      } catch (error) {
        console.error(error);
        setAllUserStories(null);
        setRecordCount(0);
      } finally {
        setLoadingStories(false);
        setShowingMore(false);
      }
    }
  };

  // used by Backlog
  const fetchUserStoryDetail = async () => {
    if (currentStoryId && selectedClient) {
      if (!isLoadingStoryDetail) {
        setLoadingStoryDetail(true);
      }
      if (isUserStoryNotAuthorized) {
        setUserStoryNotAuthorized(false);
      }
      try {
        const res = await getExtranetUserStoryDetail(currentStoryId);
        // the user is opening a user story directly, so we want to check that and then set the dropdown extranet client to the correct one
        if (res.clientId !== selectedClient.clientId) {
          dispatch(setExtranetClient(clients.find(client => client.clientId === Number(res.clientId))));
        }
        setSelectedStories([...selectedStories, res]);
      } catch (error) {
        // @ts-ignore
        if (error.message && error.message.includes('500')) {
          setUserStoryNotAuthorized(true);
        }
        console.error(error);
      } finally {
        setLoadingStoryDetail(false);
      }
    }
  };

  const fetchMentionLinks = async () => {
    const { clientId } = selectedClient || {};
    if (!clientId) {
      return;
    }

    try {
      const mentionLinksRes = await getExtranetClientMentionLinks(clientId);
      dispatch(setClientMentionLinks(mentionLinksRes));
    } catch (error) {
      console.error(error);
    }
  };

  const resetFromDeepLink = () => {
    setEpicsWithFeatures({});
    setFeatures([]);
    setDeepLinkEpic([]);
    setDeepLinkFeature([]);
    setDeepLinkUserStory([]);
    setExpanded({});
    setCurrentEpicId(null);
    setLoadingStories(true);
    fetchEpicsAndFeatures();
  };

  const resetBacklog = () => {
    setEpicsWithFeatures({});
    setExpanded({});
    setCurrentEpicId(null);
    setLoadingStories(true);
  };

  const getFileName = (res: any) => {
    let filename = '';
    let disposition = res.headers['content-disposition'];
    if (disposition && disposition.indexOf('attachment') !== -1) {
      const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      const matches = filenameRegex.exec(disposition);
      if (matches != null && matches[1]) {
        filename = matches[1].replace(/['"]/g, '');
      }
    }
    return filename;
  };

  const fetchExcel = async () => {
    if (selectedClient) {
      try {
        setisExporting(true);
        const res = await getExtranetBacklogExport(selectedClient?.clientId, baseFilters);
        const url = URL.createObjectURL(res.data);
        const link = document.createElement('a');
        link.download = getFileName(res);
        link.href = url;
        link.click();
      } catch (e) {
        console.error(e);
      } finally {
        setisExporting(false);
      }
    }
  };
  const hasNoEpicsWithoutFilter =
    !searchResults &&
    !isLoadingProjects &&
    !isLoadingEpics &&
    !isLoadingFeatures &&
    !isLoadingStories &&
    !selectedTypeFilter &&
    epics &&
    epics.length === 0;
  const hasNoEpics = !searchResults && !isLoadingProjects && !isLoadingEpics && epics && epics.length === 0;
  const hasNoFeatures =
    !searchResults &&
    !isLoadingProjects &&
    !isLoadingFeatures &&
    !isLoadingStories &&
    features.length === 0 &&
    deepLinkFeature.length === 0 &&
    allFeatureUserStories &&
    allFeatureUserStories.length === 0;
  const hasNoStories =
    !searchResults &&
    !isLoadingProjects &&
    !isLoadingEpics &&
    !isLoadingFeatures &&
    !isLoadingStories &&
    allUserStories &&
    allUserStories.length === 0;

  // const hasNoSearchResults = !isSearching && searchResults && searchResults.length === 0;

  const selectedStory = selectedStories && selectedStories.find(story => story.id === Number(currentStoryId));

  return (
    <>
      {backlogParams?.clientId ? (
        <Page title='Backlog' overflow>
          <div className={classes.wrapper}>
            <LoaderWrapper>
              <div>
                <>
                  <Grid container alignItems='center' className={`${classes.searchWrapper} ${classes.hideForPrint}`}>
                    <Grid container alignItems='center' item xs={12} md={9} className={classes.hideForPrint}>
                      <Grid container alignItems='center' item xs={8} md={4} justify={'flex-start'} className={classes.viewBy}>
                        <>
                          <Button
                            component={Link}
                            to='/clients/backlog'
                            variant='contained'
                            className={classes.resetLink}
                            color='primary'
                            onClick={() => setResetToBacklog(!resetToBacklog)}
                          >
                            RESET TO FULL BACKLOG
                          </Button>
                        </>
                      </Grid>
                    </Grid>
                  </Grid>

                  {noWorkItems && !isLoadingProjects && !isLoadingEpics && (
                    <CustomMessage
                      mode='warn'
                      messages={[`The provided work item was not found in the backlog.`]}
                      link='/clients/backlog'
                      linkText='Reset view to see full backlog'
                    />
                  )}
                </>
                {/* Backlog specific components */}
                <>
                  {(isLoadingProjects || isLoadingEpics) && <GridLoader />}
                  {!isLoadingProjects && !isLoadingEpics && !searchResults && (
                    <UserStoryGridView
                      allFeatureUserStories={allFeatureUserStories}
                      allUserStories={allUserStories}
                      currentEpicId={currentEpicId}
                      currentFeatureId={currentFeatureId}
                      currentStoryId={currentStoryId}
                      epics={deepLinkEpic}
                      selectedTypeFilter={selectedTypeFilter}
                      features={deepLinkFeature}
                      handleEpicClick={(id: number) => {
                        setCurrentEpicId(id);
                      }}
                      handleFeatureClick={(id: number | null) => {
                        setCurrentFeatureId(id);
                      }}
                      handleStoryClick={(id: number) => {
                        setCurrentStoryId(id);
                      }}
                      isLoadingFeatures={isLoadingFeatures}
                      isLoadingStories={isLoadingStories}
                      isLoadingStoryDetail={isLoadingStoryDetail}
                      page={page}
                      recordCount={recordCount}
                      rowsPerPage={perPage}
                      selectedStories={selectedStories}
                      setPage={val => setPage(val)}
                      setRowsPerPage={val => setPerPage(val)}
                      userStories={deepLinkUserStory}
                      showPagination={false}
                      indent={true}
                      expanded={expanded}
                      setExpanded={setExpanded}
                      isLoadingFeaturesArray={isLoadingFeaturesArray}
                      isDeepLink={isDeepLink}
                    />
                  )}
                </>
              </div>
            </LoaderWrapper>
            <Toast
              id='user-stories-error'
              message={error && error.message}
              open={error ? true : false}
              onClose={() => setError(null)}
              variant='error'
            />
          </div>

          {/** USER STORY DETAIL MODAL */}
          <Modal
            maxWidth='lg'
            onClose={() => {
              // reset the route
              history.push(location.pathname);
              setCurrentStoryId(null);
              setShowStoryModal(false);
            }}
            open={showStoryModal}
            subtitle={selectedStory ? `${selectedStory.id}  ${selectedStory.title}` : ''}
            title={
              selectedStory && selectedStory.workItemType === 'Product Backlog Item'
                ? 'Story Details'
                : selectedStory && selectedStory.workItemType
                ? `${selectedStory.workItemType} Details`
                : ''
            }
          >
            <LoaderWrapper>
              <UserStoryDetail
                isUserStoryNotAuthorized={isUserStoryNotAuthorized}
                currentStoryId={currentStoryId}
                isLoadingStoryDetail={isLoadingStoryDetail}
                selectedStory={selectedStory}
                isModalView
                printUserStory={false}
                isDeepLink={isDeepLink}
              />
            </LoaderWrapper>
          </Modal>
          <Toast id='new-request-success' message={successMessage} open={!!successMessage} onClose={() => setSuccessMessage('')} variant='success' />
          <Toast id='new-request-error' message={errorMessage} open={!!errorMessage} onClose={() => setErrorMessage('')} variant='error' />
        </Page>
      ) : (
        <Page title='Backlog' overflow>
          <div className={classes.wrapper}>
            <LoaderWrapper>
              <div className={classes.backlogSpacing}>
                <>
                  <Grid container alignItems='center' className={`${classes.searchWrapper} ${classes.hideForPrint}`}>
                    <Grid container alignItems='center' item xs={12} md={9} className={classes.hideForPrint}>
                      <TextField
                        id='select-a-project'
                        label='Project'
                        select
                        className={classes.projectSelect}
                        margin='none'
                        disabled={isLoadingProjects || (!!projects && projects.length === 0)}
                        value={project ? project.clientProjectId : 0}
                        variant='outlined'
                        size='small'
                        onChange={e => {
                          if (Number(e.target.value) === 0) {
                            return setProject(null);
                          }
                          const selected = projects && projects.find(pro => pro.clientProjectId === Number(e.target.value));
                          if (selected) {
                            ReactGA.event({
                              category: 'User Stories',
                              action: 'Set a Project Filter',
                              value: selected.clientProjectId,
                              label: `Filter by Project: ${selected.name}${gaLabelClientValue}`
                            });
                            setProject(selected);
                            resetBacklog();
                          } else {
                            setProject(null);
                          }
                        }}
                      >
                        <MenuItem key={`${0}`} value={0}>
                          All Projects
                        </MenuItem>
                        {projects &&
                          projects.map((project, index) => (
                            <MenuItem key={`${index + 1}`} value={project.clientProjectId}>
                              {project.name}
                            </MenuItem>
                          ))}
                      </TextField>
                      <Grid container alignItems='center' item xs={8} md={4} justify={'flex-start'} className={classes.viewBy}>
                        <>
                          <Typography display='inline' className={classes.viewText}>
                            View:
                          </Typography>
                          <Button
                            disabled={isLoadingEpics || isLoadingProjects}
                            disableRipple
                            size='small'
                            color={!showOnlyOpen ? 'secondary' : 'primary'}
                            startIcon={<DynamicFeed />}
                            className={!showOnlyOpen ? classes.viewButtonActive : undefined}
                            onClick={() => {
                              setShowOnlyOpen(false);
                              resetBacklog();
                            }}
                          >
                            All
                          </Button>
                          <Typography display='inline' className={classes.pipe}>
                            |
                          </Typography>
                          <Button
                            disabled={isLoadingEpics || isLoadingProjects}
                            disableRipple
                            size='small'
                            color={showOnlyOpen ? 'secondary' : 'primary'}
                            startIcon={<WebAsset />}
                            onClick={() => {
                              setShowOnlyOpen(true);
                              resetBacklog();
                            }}
                            className={showOnlyOpen ? classes.viewButtonActive : undefined}
                          >
                            Open
                          </Button>

                          <Tooltip arrow={true} placement='top' title={`Export all items to Excel`}>
                            <span>
                              <ExportButton
                                isExporting={isExporting}
                                onClick={() => {
                                  ReactGA.event({
                                    category: 'User Stories',
                                    action: 'Export items to Excel',
                                    label: `Export items to Excel${gaLabelClientValue}`
                                  });
                                  setisExporting(true);
                                  fetchExcel();
                                }}
                                className={classes.excelButton}
                                ariaLabel='export-backlog'
                                isDisabled={isExporting || isLoadingEpics || isLoadingFeatures || isLoadingProjects || epics?.length === 0}
                              />
                            </span>
                          </Tooltip>
                          <Typography display='inline' className={classes.pipe}>
                            |
                          </Typography>
                          {/* PRINT BUTTON */}
                          <Button
                            startIcon={<Print />}
                            disabled={isLoadingEpics || isLoadingProjects}
                            color='primary'
                            onClick={() => {
                              window.print();
                              ReactGA.event({
                                category: 'User Stories',
                                action: 'Clicking the print button',
                                label: `Clicking the print button${gaLabelClientValue}`
                              });
                            }}
                          >
                            PRINT
                          </Button>
                        </>
                      </Grid>
                    </Grid>
                    <Grid container alignItems='center' item xs={12} md={3} justify={isMobile ? 'flex-start' : 'flex-end'} className={classes.viewBy}>
                      <>
                        <Button
                          disabled={isLoadingEpics || isLoadingProjects}
                          disableRipple
                          size='small'
                          onClick={() => {
                            if (epics) {
                              epics.forEach(epic => {
                                setEpicsWithFeatures(prev => {
                                  return { ...prev, [epic.id]: true };
                                });
                                if (!epicsWithFeatures[epic.id]) {
                                  fetchFeatures(epic.id, true);
                                }
                              });
                              setExpanded(
                                epics.reduce((prev, curr) => {
                                  return { ...prev, [curr.id]: true };
                                }, {})
                              );
                            }
                          }}
                        >
                          Expand All
                        </Button>
                        <Button
                          disabled={isLoadingEpics || isLoadingProjects}
                          disableRipple
                          size='small'
                          onClick={() => {
                            if (epics)
                              setExpanded(
                                epics.reduce((prev, curr) => {
                                  return { ...prev, [curr.id]: false };
                                }, {})
                              );
                          }}
                        >
                          Collapse All
                        </Button>
                      </>
                    </Grid>
                  </Grid>

                  {(hasNoEpicsWithoutFilter || hasNoEpics || hasNoFeatures || hasNoStories) && !isLoadingProjects && !isLoadingEpics && (
                    <Empty
                      messages={[`No ${formatWorkItemType(selectedTypeFilter || 'Epic', true)} are available. Please select a different sprint.`]}
                    />
                  )}
                </>
                {/* Backlog specific components */}
                <>
                  {(isLoadingProjects || isLoadingEpics) && <GridLoader />}
                  {!isLoadingProjects && !isLoadingEpics && !searchResults && (
                    <UserStoryGridView
                      allFeatureUserStories={allFeatureUserStories}
                      allUserStories={allUserStories}
                      currentEpicId={currentEpicId}
                      currentFeatureId={currentFeatureId}
                      currentStoryId={currentStoryId}
                      epics={epics}
                      selectedTypeFilter={selectedTypeFilter}
                      features={features}
                      handleEpicClick={(id: number) => {
                        setCurrentEpicId(id);
                      }}
                      handleFeatureClick={(id: number | null) => {
                        setCurrentFeatureId(id);
                      }}
                      handleStoryClick={(id: number) => {
                        setCurrentStoryId(id);
                      }}
                      isLoadingFeatures={isLoadingFeatures}
                      isLoadingStories={isLoadingStories}
                      isLoadingStoryDetail={isLoadingStoryDetail}
                      page={page}
                      recordCount={recordCount}
                      rowsPerPage={perPage}
                      selectedStories={selectedStories}
                      setPage={val => setPage(val)}
                      setRowsPerPage={val => setPerPage(val)}
                      userStories={selectedUserStories}
                      showPagination={false}
                      indent={true}
                      expanded={expanded}
                      setExpanded={setExpanded}
                      isLoadingFeaturesArray={isLoadingFeaturesArray}
                    />
                  )}
                </>
              </div>
            </LoaderWrapper>
            <Toast
              id='user-stories-error'
              message={error && error.message}
              open={error ? true : false}
              onClose={() => setError(null)}
              variant='error'
            />
          </div>

          {/** USER STORY DETAIL MODAL */}
          <Modal
            maxWidth='lg'
            onClose={() => {
              // reset the route
              history.push(location.pathname);
              setCurrentStoryId(null);
              setShowStoryModal(false);
            }}
            open={showStoryModal}
            subtitle={selectedStory ? `${selectedStory.id}  ${selectedStory.title}` : ''}
            title={
              selectedStory && selectedStory.workItemType === 'Product Backlog Item'
                ? 'Story Details'
                : selectedStory && selectedStory.workItemType
                ? `${selectedStory.workItemType} Details`
                : ''
            }
          >
            <LoaderWrapper>
              <UserStoryDetail
                isUserStoryNotAuthorized={isUserStoryNotAuthorized}
                currentStoryId={currentStoryId}
                isLoadingStoryDetail={isLoadingStoryDetail}
                selectedStory={selectedStory}
                isModalView
                printUserStory={false}
              />
            </LoaderWrapper>
          </Modal>
          <Toast id='new-request-success' message={successMessage} open={!!successMessage} onClose={() => setSuccessMessage('')} variant='success' />
          <Toast id='new-request-error' message={errorMessage} open={!!errorMessage} onClose={() => setErrorMessage('')} variant='error' />
        </Page>
      )}
    </>
  );
};

const backlogStyles = makeStyles((theme: Theme) => ({
  backlogSpacing: {
    marginBottom: theme.spacing(1)
  },
  hideForPrint: {
    '@media print': {
      display: 'none'
    }
  },
  wrapper: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    paddingTop: theme.spacing(0.125),
    '&& .MuiPaper-elevation1': {
      boxShadow: 'none',
      margin: 0,
      borderTop: `1px solid ${theme.palette.grey[300]}`
    }
  },
  heading: {
    alignSelf: 'flex-end',
    marginBottom: theme.spacing(1)
  },
  searchWrapper: {
    margin: theme.spacing(0.5, 0),
    [theme.breakpoints.up('sm')]: {
      margin: theme.spacing(1, 0)
    }
  },
  viewBy: {
    '@media (max-width: 959px)': {
      alignSelf: 'flex-start'
    },
    '@media (min-width: 1171px)': {
      alignSelf: 'center'
    },
    marginTop: theme.spacing(0.5),
    flexWrap: 'nowrap',
    [theme.breakpoints.up('md')]: {
      marginTop: 0,
      alignItems: 'center'
    }
  },
  projectSelect: {
    width: '100%',
    margin: theme.spacing(0.5, 0),

    [theme.breakpoints.up('md')]: {
      width: 300,
      margin: 0,
      marginRight: theme.spacing(1)
    }
  },
  pipe: {
    margin: '0 12px 0 4px',
    color: theme.palette.grey[300],
    [theme.breakpoints.up('md')]: {
      margin: '0 8px 0 4px'
    }
  },
  acceptanceCriteria: {
    margin: theme.spacing(1, 0),
    '&& ul': {
      padding: `0 0 0 10px !important`,
      [theme.breakpoints.up('md')]: {
        padding: `0 0 0 30px !important`
      }
    }
  },
  margin: {
    margin: theme.spacing(1, 0)
  },
  marginBottom: {
    marginBottom: theme.spacing(0.5)
  },
  bold: {
    fontWeight: 700
  },
  marginTop: {
    marginTop: theme.spacing(1)
  },
  viewText: {
    marginRight: theme.spacing(0.5),
    whiteSpace: 'nowrap',
    '@media (max-width: 600px)': {
      display: 'none'
    }
  },
  storyTitle: {
    fontWeight: 'bold'
  },
  filtersButton: {
    padding: 0,
    minWidth: 0,
    '&& .MuiButton-startIcon': {
      marginRight: 0
    },
    [theme.breakpoints.up('md')]: {
      minWidth: '64px',
      '&& .MuiButton-startIcon': {
        marginRight: theme.spacing(0.5)
      }
    }
  },
  filtersButtonActive: {
    padding: 0,
    minWidth: 0,
    borderBottom: '1px solid',
    borderRadius: 0,
    fontWeight: 700,
    '&& .MuiButton-startIcon': {
      marginRight: 0
    },
    [theme.breakpoints.up('md')]: {
      minWidth: '64px',
      '&& .MuiButton-startIcon': {
        marginRight: theme.spacing(0.5)
      }
    }
  },
  excelButton: {
    paddingLeft: theme.spacing(2),
    '@media (max-width: 980px)': {
      paddingLeft: theme.spacing(0.75)
    },
    minWidth: 0,
    '&& .MuiButton-startIcon': {
      marginRight: 0
    },
    [theme.breakpoints.up('md')]: {
      minWidth: '64px',
      '&& .MuiButton-startIcon': {
        marginRight: theme.spacing(0.5)
      }
    }
  },
  filtersWrapper: {
    margin: theme.spacing(0, 0, 1, 0),
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'column'
    }
  },
  filtersResetButton: {
    alignSelf: 'flex-start',
    [theme.breakpoints.up('md')]: {
      alignSelf: 'center'
    }
  },
  searchInput: {
    marginRight: '21px',
    width: '66%',
    '&& .MuiInputBase-root': {
      width: '100%',
      paddingLeft: theme.spacing(0.5)
    },
    [theme.breakpoints.up('sm')]: {
      width: '68%'
    },
    [theme.breakpoints.up('md')]: {
      marginRight: theme.spacing(1),
      width: 'auto'
    },
    '@media (min-width: 1038px)': {
      '&& .MuiInputBase-root': {
        width: 300
      }
    }
  },
  viewButtonActive: {
    borderBottom: '1px solid',
    borderRadius: 0,
    fontWeight: 700
  },
  effortValue: {
    color: theme.palette.grey[500],
    fontFamily: 'Poppins-Light, sans-serif',
    fontSize: 16,
    lineHeight: 1.6,
    margin: theme.spacing(0.125, 0, 0)
  },
  viewByWrapper: {
    display: 'flex',
    alignItems: 'center'
  },
  mobileEffort: {
    margin: theme.spacing(1, 0)
  },
  resetLink: {
    whiteSpace: 'nowrap',
    textDecoration: 'none'
  }
}));
