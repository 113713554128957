import React, { FC, useMemo, useState, useEffect } from 'react';
import { useSensors, useSensor, MouseSensor, TouchSensor, KeyboardSensor, DndContext, closestCenter, DragOverlay } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { SortableContext, arrayMove, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { Button, FormControl, Grid, InputLabel, makeStyles, MenuItem, Select, Theme, Typography, useMediaQuery } from '@material-ui/core';
import { theme } from '@shared/helpers';
import { useParams } from 'react-router-dom';
import { FieldArray } from 'formik';
import { StaticOptionListItem } from '@shared/components/draggable-table/static-option-list-item';
import { QueryObserverResult, RefetchOptions, RefetchQueryFilters, useQuery } from 'react-query';
import { Add } from '@material-ui/icons';
// Context
import { useValidation } from '@src/context/validation-context';
// Fetch
import {
  copyExecutionSteps,
  createExecutionStep,
  deleteExecutionStep,
  getPriorReleases,
  getReleaseById,
  updateExecutionStep,
  updateExecutionSteps
} from '@shared/fetch';
// Components
import { WysiwygRender } from '@shared/components/wysiwyg';
import { ReleaseProcedureStepsDragAndDropRow } from './ReleaseProcedureStepsDragAndDropRow';
import { ListItemColors } from '@shared/components/draggable-table/util';

interface IReleaseProcedureSteps {
  setFieldValue: (key: string, val: any) => void;
  values: any[];
  initialValues: any[];
  setPageToast: (toast: { message: string; variant: 'error' | 'success'; isOpen: boolean }) => void;
  touched: any;
  errors: any;
  handleChange: any;
  handleBlur: any;
  handleSubmit: () => void;
  isSubmitting: boolean;
  dirty: boolean;
  isValid: boolean;
  refetchExecutionPlans: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
  ) => Promise<QueryObserverResult<any[], unknown>>;
  isRefetchingExecutionPlans: boolean;
}

export const ReleaseProcedureSteps: FC<IReleaseProcedureSteps> = ({
  setFieldValue,
  values,
  initialValues,
  setPageToast,
  touched,
  errors,
  handleBlur,
  handleChange,
  dirty,
  isValid,
  handleSubmit,
  isSubmitting,
  refetchExecutionPlans,
  isRefetchingExecutionPlans
}) => {
  const color = ListItemColors.Primary;
  const isMediumDown = useMediaQuery(theme.breakpoints.down('md'));
  const classes = useStyles({ isOptionsScroll: values?.length > 4, isMediumDown: isMediumDown });
  const { setValidationMessages } = useValidation(); 

  const { releaseId }: { releaseId: string } = useParams();
  const [activeId, setActiveId] = useState<any>();
  const [editingId, setEditingId] = useState<any>('');
  const items = useMemo(() => values?.map(({ id }: any) => id), [values]);
  // Use the state and functions returned from useTable to build your UI

  const sensors = useSensors(useSensor(MouseSensor, {}), useSensor(TouchSensor, {}), useSensor(KeyboardSensor, {}));

  const { isLoading: isLoadingRelease, data: currentRelease } = useQuery(
    ['currentReleases', releaseId],

    async () => await getReleaseById(+releaseId),
    {
      notifyOnChangeProps: 'tracked',
      enabled: !!releaseId
    }
  );

  const { isLoading: isLoadingPriorRelease, data: priorReleases } = useQuery(
    ['previousReleases', currentRelease?.clientId],

    async () => await getPriorReleases(`${currentRelease?.clientId}`),
    {
      notifyOnChangeProps: 'tracked',
      enabled: !isLoadingRelease && !!currentRelease?.clientId
    }
  );

  useEffect(() => {
    const errors: string[] = [];
    if (values.length === 0) {
      errors.push('Execution Steps');
    }
    setValidationMessages(prev => {
      const filteredMessages = prev.filter(msg => !msg.startsWith('Execution Steps'));
      return [...filteredMessages, ...errors];
    });
  }, [values, setValidationMessages]);

  const [selectedPlanToImport, setSelectedPlanToImport] = useState<any>(null);

  const handleDelete = async (index: number) => {
    try {
      await deleteExecutionStep(releaseId, values[index].id);
      const filteredValues = values.filter(step => step.id !== values[index].id);
      const orderedReleaseExecutionSteps = filteredValues.map((step: any, index: number) => ({
        ...step,
        releaseExecutionStepId: step.id,
        order: index + 1
      }));

      await updateExecutionSteps(releaseId, { updates: orderedReleaseExecutionSteps });
      await refetchExecutionPlans();
    } catch (e) {
      console.log('Error: ', e);
    }
  };

  const handleCreate = async (name: string, id: string | number) => {
    try {
      // returns viewmodel
      const createdStep = await createExecutionStep(releaseId, { name: name, order: values.length + 1 });

      setFieldValue('executionSteps', [...values, createdStep]);
      const orderedReleaseExecutionSteps = [...values, createdStep].map((step: any, index: number) => ({
        ...step,
        releaseExecutionStepId: step.id,
        order: index + 1
      }));

      await updateExecutionSteps(releaseId, { updates: orderedReleaseExecutionSteps });
      setEditingId(createdStep.id);
      await refetchExecutionPlans();
    } catch (e) {
      console.log('Error: ', e);
    }
  };

  const handleUpdate = async (payload: any, id: string | number) => {
    try {
      // returns viewmodel
      const updatedStep = await updateExecutionStep(releaseId, id, payload);
      const orderedReleaseExecutionSteps = [...values, updatedStep].map((step: any, index: number) => ({
        ...step,
        releaseExecutionStepId: step.id,
        order: step.order
      }));
      await updateExecutionSteps(releaseId, { updates: orderedReleaseExecutionSteps });

      await refetchExecutionPlans();
      setPageToast({
        message: 'Execution steps updated',
        variant: 'success',
        isOpen: true
      });
    } catch (e) {
      console.log('Error: ', e);
    }
  };

  function handleDragStart(event: any) {
    setActiveId(event.active.id);
  }

  const handleDragEnd = async (event: any) => {
    const { active, over } = event;

    if (active.id !== over.id) {
      const oldIndex = items.indexOf(active.id);
      const newIndex = items.indexOf(over.id);
      const newItems = arrayMove(values, oldIndex, newIndex);

      // sort Items
      const orderedReleaseExecutionSteps = newItems.map((step: any, index: number) => ({
        ...step,
        releaseExecutionStepId: step.id,
        order: index + 1
      }));

      setFieldValue('executionSteps', orderedReleaseExecutionSteps);

      await updateExecutionSteps(releaseId, { updates: orderedReleaseExecutionSteps });

      await refetchExecutionPlans();
    }

    setActiveId(null);
  };

  function handleDragCancel() {
    setActiveId(null);
  }

  const selectedRow = useMemo(() => {
    if (!activeId) {
      return null;
    }

    //@ts-ignore
    const row = values.find(option => option?.id === activeId) as Row<any>;
    return row;
  }, [activeId, values]);

  const handleImport = async (selectedPlanToImport: string) => {
    try {
      const imported = await copyExecutionSteps(releaseId, selectedPlanToImport);
      // add to the bottom and save

      const orderedReleaseExecutionSteps = [...values, ...imported].map((step: any, index: number) => ({
        ...step,
        releaseExecutionStepId: step.id,
        order: index + 1
      }));

      await updateExecutionSteps(releaseId, { updates: orderedReleaseExecutionSteps });
      await refetchExecutionPlans();
      setPageToast({
        message: 'Execution steps imported successfully',
        variant: 'success',
        isOpen: true
      });
    } catch (e) {
      console.log('error', e);
      setPageToast({
        message: (e as any)?.Detail ?? 'Execution steps were not imported successfully. Please try again.',
        variant: 'error',
        isOpen: true
      });
    }
  };

  const valuesContainNewItem = values.some((item: any) => item.id.toString().includes('new-'));

  return (
    <Grid className={classes.grid} container spacing={1}>
      {
        <Grid item xs={12}>
          <DndContext
            sensors={sensors}
            onDragEnd={handleDragEnd}
            onDragStart={handleDragStart}
            onDragCancel={handleDragCancel}
            collisionDetection={closestCenter}
            modifiers={[restrictToVerticalAxis]}
          >
            <SortableContext
              disabled={editingId !== '' || valuesContainNewItem || isRefetchingExecutionPlans}
              items={items ?? []}
              strategy={verticalListSortingStrategy}
            >
              <FieldArray name='executionSteps'>
                {arrayHelpers => (
                  <>
                    <Grid container spacing={1}>
                      <Grid item xs={12} className={classes.titleContainer}>
                        <Typography className={classes.title}>Release Procedure Steps</Typography>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <FormControl disabled={releaseId === 'add' || isRefetchingExecutionPlans} fullWidth variant='outlined' size='small'>
                          <InputLabel style={{ backgroundColor: 'white', padding: '0 4px' }} id='importFrom'>
                            Import From
                          </InputLabel>
                          <Select
                            name='importFrom'
                            labelId='importFrom'
                            fullWidth
                            variant='outlined'
                            value={selectedPlanToImport}
                            disabled={priorReleases?.length === 0 || isLoadingPriorRelease || isRefetchingExecutionPlans || releaseId === 'add'}
                            onChange={event => {
                              let target = event.target as HTMLInputElement;
                              const { value } = target;
                              setSelectedPlanToImport(value);
                            }}
                          >
                            {!isLoadingPriorRelease
                              ? priorReleases?.map((option, index) => (
                                  <MenuItem key={`${option?.value}`} value={option?.value}>
                                    {option?.description}
                                  </MenuItem>
                                ))
                              : []}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={6}>
                        <Button
                          type='button'
                          color='secondary'
                          variant='contained'
                          disabled={!selectedPlanToImport || isLoadingPriorRelease || isRefetchingExecutionPlans}
                          onClick={() => handleImport(selectedPlanToImport)}
                        >
                          Import
                        </Button>
                      </Grid>
                    </Grid>

                    <div className={classes.list}>
                      {values?.map((option: any, index: number) => {
                        const name = `executionSteps[${index}].name`;
                        const status = `executionSteps[${index}].status`;

                        const originalNameValue = initialValues?.[index]?.name ?? '<p></p>';
                        const nameValue = values?.[index]?.name ?? '<p></p>';
                        const orderValue = values?.[index]?.order;
                        const statusValue = values?.[index]?.status;
                        return (
                          <ReleaseProcedureStepsDragAndDropRow
                            key={option.id}
                            option={option}
                            color={color}
                            handleChangeName={(text: string) => setFieldValue(name, text)}
                            handleChangeStatus={(isDone: boolean) => setFieldValue(status, isDone)}
                            name={name}
                            nameValue={nameValue}
                            originalNameValue={originalNameValue}
                            status={status}
                            statusValue={statusValue}
                            orderValue={orderValue}
                            index={index}
                            isRefetchingExecutionPlans={isRefetchingExecutionPlans}
                            setEditingId={setEditingId}
                            editingId={editingId}
                            handleDelete={async () => {
                              if (!values?.[index]?.id?.toString()?.includes('new-')) {
                                await handleDelete(index);
                              } else {
                                arrayHelpers.remove(index);
                              }
                            }}
                            handleCreate={async (name: string, id: string | number) => await handleCreate(name, id)}
                            handleUpdate={async (name: string, order: number, id: string | number) =>
                              await handleUpdate({ name: name, order: order }, id)
                            }
                          />
                        );
                      })}
                    </div>
                    <Grid container spacing={1}>
                      <Grid item xs={12} justify={'flex-end'} style={{ textAlign: 'right' }}>
                        <Button
                          autoCapitalize={'none'}
                          disabled={releaseId === 'add' || isRefetchingExecutionPlans}
                          type='button'
                          color='primary'
                          className={classes.addButton}
                          variant={'text'}
                          onClick={() => handleCreate('', 'new-' + new Date().getTime())}
                        >
                          <Add />
                          Add Step
                        </Button>
                      </Grid>
                    </Grid>
                  </>
                )}
              </FieldArray>
            </SortableContext>
            <DragOverlay>
              {activeId && (
                <StaticOptionListItem item={selectedRow} id={selectedRow?.id} color={color}>
                  <WysiwygRender
                    plainHtml={true}
                    html={selectedRow?.name}
                    htmrOptions={{
                      transform: {},
                      preserveAttributes: [],
                      dangerouslySetChildren: ['style']
                    }}
                  />
                </StaticOptionListItem>
              )}
            </DragOverlay>
          </DndContext>
        </Grid>
      }
    </Grid>
  );
};

const useStyles = makeStyles<Theme, { isOptionsScroll: boolean; isMediumDown: boolean }>(() => ({
  emphasis: {
    fontWeight: 600,
    color: theme.palette.secondary.main
  },
  titleContainer: {
    padding: ({ isMediumDown }) => (isMediumDown ? '2rem 1rem 0.25rem 1rem' : '1rem 1rem 0 1rem')
  },
  title: {
    fontSize: '1.125rem',
    color: '#616161',
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      fontSize: '1rem'
    }
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5)
  },
  list: {
    paddingRight: ({ isOptionsScroll }) => (isOptionsScroll ? theme.spacing(0.5) : theme.spacing(0)),
    margin: theme.spacing(0.5, 0, 1, 0)
  },
  grid: {
    marginTop: theme.spacing(0.5),
    paddingTop: '0 !important'
  },
  inputRoot: {
    fontSize: '1rem'
  },
  inputLabelRoot: {
    fontSize: '1rem'
  },
  textFieldRoot: {
    fontSize: '1rem'
  },
  wysiwyg: {
    backgroundColor: theme.palette.background.paper,
    '& .ql-toolbar': {
      borderRadius: '15px 15px 0 0',
      backgroundColor: theme.palette.grey[200]
    },
    '& .ql-container': {
      height: '100% !important',
      borderRadius: '0 0 15px 15px'
    },
    '&& .ql-editor': {
      minHeight: 200
    }
  },
  addButton: {
    color: theme.palette.primary.main,
    textTransform: 'none',
    fontWeight: 500,
    '& .MuiButton-startIcon': {
      marginRight: theme.spacing(0.25)
    }
  }
}));
