import React from 'react';
import ReactDOM from 'react-dom';
import '@shared/styles/styles.css';
// Containers
import App from '@shared/containers/App';
// Components
import { ErrorBoundary } from '@shared/components/layout';
// Redux
import { Provider } from 'react-redux';
import { createStore } from '@shared/redux';
// react-ADAL setup
import { runWithAdal } from 'react-adal';
import { authContext } from './adalConfig';
// Material setup
import { ThemeProvider } from '@material-ui/core/styles';
import { theme } from '@shared/helpers';
import CssBaseline from '@material-ui/core/CssBaseline';
import { CardContextHandler } from './context/card';
import { FiltersContextHandler } from './context/filters';

const { store } = createStore();
const DO_NOT_LOGIN = true;

runWithAdal(
  authContext,
  () => {
    ReactDOM.render(
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <CardContextHandler>
            <FiltersContextHandler>
              <CssBaseline />
              <ErrorBoundary>
                <App />
              </ErrorBoundary>
            </FiltersContextHandler>
          </CardContextHandler>
        </ThemeProvider>
      </Provider>,
      document.getElementById('root')
    );
  },
  DO_NOT_LOGIN
);
