import React, { FC } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { useLocation, Link } from 'react-router-dom';
// Components
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
// Types
import { IClientNavItem } from './ClientNavItem';

interface IMobileClientNavItemProps extends IClientNavItem {
  onNav?: (event: {}) => void;
}

const useStyles = makeStyles(theme => ({
  link: {
    textDecoration: 'none',
    color: theme.palette.text.secondary
  },
  listItem: {
    overflowX: 'hidden'
  },
  listItemActive: {
    color: theme.palette.secondary.main
  },
  icon: {
    '& > svg': {
      fill: theme.palette.primary.main
    }
  },
  iconActive: {
    '& > svg': {
      fill: theme.palette.secondary.main
    }
  }
}));

const splitPathString = (path: string) => {
  return path.split('/').filter((x: string) => x);
};

export const MobileClientNavItem: FC<IMobileClientNavItemProps> = ({ title, path, icon, onNav }) => {
  const classes = useStyles();

  const { pathname } = useLocation();

  // determine if this path is active so we can highlight
  const splitPathname = splitPathString(pathname);
  const splitPath = splitPathString(path);
  const active = splitPath[1] === splitPathname[1];

  return (
    <Link className={clsx(classes.link, { [classes.listItemActive]: active })} to={path} onClick={onNav}>
      <ListItem className={classes.listItem} button key={title} divider>
        <ListItemIcon
          classes={{
            root: clsx(classes.icon, {
              [classes.iconActive]: active
            })
          }}
        >
          {icon}
        </ListItemIcon>
        <ListItemText
          primary={title}
          className={clsx({
            [classes.listItemActive]: active
          })}
        />
      </ListItem>
    </Link>
  );
};
