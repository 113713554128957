import React, { FC, Dispatch, SetStateAction } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
// Components
import { FormLabel, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails, Typography, Button } from '@material-ui/core';
import { ExpandMore, Edit } from '@material-ui/icons';
// Types
import { IEmployeeRate } from '@shared/types';
import { FormikHandlers, FieldMetaProps } from 'formik';
import format from 'date-fns/format';

interface IMobileEmployeeRole extends FormikHandlers, FieldMetaProps<any> {
  original: IEmployeeRate;
  setEditing: Dispatch<SetStateAction<IEmployeeRate | {} | null>>;
}

export const MobileEmployeeRoles: FC<IMobileEmployeeRole> = ({ original, setEditing }) => {
  const classes = useStyles();
  const { title, dateCreated, dateUpdated, isActive } = original;
  return (
    <ExpansionPanel className={classes.root}>
      <ExpansionPanelSummary className={classes.expansionPanel} expandIcon={<ExpandMore />} aria-controls='panel1a-content' id='panel1a-header'>
        <div className={classes.panelSummaryWrapper}>
          <Typography component='span'>{`${title} ${title}`}</Typography>
        </div>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails className={classes.details}>
        <FormLabel component='h2'>Date Created</FormLabel>
        <Typography component='span'>{dateCreated !== null ? format(new Date(original.dateCreated), 'MM/dd/yy') : '--'}</Typography>

        <FormLabel component='h2'>Date Updated</FormLabel>
        <Typography component='span'>{dateUpdated !== null ? format(new Date(original.dateUpdated), 'MM/dd/yy') : '--'}</Typography>

        <FormLabel component='h2'>Status</FormLabel>
        <Typography component='span'>{isActive ? 'Active' : 'Inactive'}</Typography>

        <Button color='primary' startIcon={<Edit />} onClick={() => setEditing(original)}>
          Edit
        </Button>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    maxWidth: '100%'
  },
  expansionPanel: {
    padding: '0px 24px'
  },
  panelSummaryWrapper: {
    display: 'flex',
    alignItems: 'flex-start',
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  details: {
    display: 'flex',
    flexDirection: 'column'
  }
}));
