import React, { FC } from 'react';
import { makeStyles, Theme, Typography } from '@material-ui/core';
import { TypographyProps } from '@material-ui/core/Typography';
import clsx from 'clsx';

interface IParagraphProps extends TypographyProps {}

export const Paragraph: FC<IParagraphProps> = ({ children, ...props }) => {
  const classes = pStyles();
  return (
    <Typography {...props} className={clsx(classes.p, props.className)} variant='body1'>
      {children}
    </Typography>
  );
};

const pStyles = makeStyles((theme: Theme) => ({
  p: {
    fontSize: 16,
    margin: theme.spacing(2, 0)
  }
}));
