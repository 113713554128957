import clsx from 'clsx';
import React, { FC, ReactNode } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';

interface IExternalLink {
  className?: string;
  to: string;
  type?: 'primary' | 'white' | 'secondary';
  target?: '_self' | '_blank' | '_parent' | '_top';
  title?: string;
  icon?: ReactNode;
  fontSize?: number;
  textDecoration?: string;
  fontWeight?: number;
}

export const ExternalLink: FC<IExternalLink> = ({ to, children, className, type = 'primary', target, title, icon, fontSize, textDecoration, fontWeight }) => {
  const classes = useStyles({ fontSize, textDecoration, fontWeight });
  return (
    <a href={to} rel='noopener noreferrer' target={target} className={clsx(classes[type], classes.link, className)} title={title}>
      {icon && <span className={classes.icon}>{icon}</span>}
      {children}
    </a>
  );
};

const useStyles = makeStyles<Theme, { fontSize?: number; textDecoration?: string; fontWeight?: number }>(theme => ({
  white: {
    color: theme.palette.background.paper,
    textDecoration: 'none'
  },
  primary: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
    '&:hover': {
      color: theme.palette.primary.dark
    }
  },
  secondary: {
    color: theme.palette.secondary.main,
    textDecoration: 'none',
    '&:hover': {
      color: theme.palette.secondary.dark
    }
  },
  icon: {
    marginRight: theme.spacing(0.25),
    verticalAlign: 'sub',
    display: 'inline-block'
  },
  link: {
    fontSize: ({ fontSize }) => (fontSize ? `${fontSize}px` : 'inherit'),
    textDecoration: ({ textDecoration }) => textDecoration ?? 'none',
    fontWeight: ({ fontWeight }) => fontWeight ?? 'inherit',
  }
}));