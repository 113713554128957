import React, { FC } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
// Components
import { FormLabel, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails, Typography, Button } from '@material-ui/core';
import { ExpandMore, Edit } from '@material-ui/icons';
// Types
import { IClientProject } from '@shared/types';
import { FormikHandlers, FieldMetaProps } from 'formik';
// helpers
import { formatMoney } from '@shared/helpers';

interface IMobileClientProject extends FormikHandlers, FieldMetaProps<any> {
  original: IClientProject;
  handleEdit: (val: IClientProject) => void;
}

export const MobileClientProject: FC<IMobileClientProject> = ({ original, handleEdit }) => {
  const { name, hashtag, billingTypeId, scopedHours, budgetedAmount, blendedRate, fixedPrice, projectStatus } = original;
  const classes = useStyles();

  return (
    <ExpansionPanel className={classes.root}>
      <ExpansionPanelSummary className={classes.expansionPanel} expandIcon={<ExpandMore />} aria-controls='panel1a-content' id='panel1a-header'>
        <div className={classes.panelSummaryWrapper}>
          <Typography component='span'>{name}</Typography>
        </div>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails className={classes.details}>
        <FormLabel component='h2'>Hashtag</FormLabel>
        <Typography component='span'>{hashtag || '--'}</Typography>

        <FormLabel component='h2'>Billing Type</FormLabel>
        <Typography component='span'>{billingTypeId || '--'}</Typography>

        <FormLabel component='h2'>Scoped Hours</FormLabel>
        <Typography component='span'>{scopedHours || '--'}</Typography>

        <FormLabel component='h2'>Budgeted Amount</FormLabel>
        <Typography component='span'>{formatMoney(budgetedAmount)}</Typography>

        <FormLabel component='h2'>Blended Rate</FormLabel>
        <Typography component='span'>{formatMoney(blendedRate)}</Typography>

        <FormLabel component='h2'>Fixed Price</FormLabel>
        <Typography component='span'>{fixedPrice ? 'Yes' : 'No'}</Typography>

        <FormLabel component='h2'>Status</FormLabel>
        <Typography component='span'>{projectStatus || '--'}</Typography>
        <Button color='primary' startIcon={<Edit />} onClick={() => handleEdit(original)}>
          Edit
        </Button>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    maxWidth: '100%'
  },
  expansionPanel: {
    padding: '0px 24px'
  },
  panelSummaryWrapper: {
    display: 'flex',
    alignItems: 'flex-start',
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  details: {
    display: 'flex',
    flexDirection: 'column'
  }
}));
