import DateFnsUtils from '@date-io/date-fns';
import { Button, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Toolbar, Tooltip, Typography } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Add, CheckCircleOutline, Clear, Delete, Link, MoreVert, Queue, Refresh, RestorePage, Save, SupervisorAccount } from '@material-ui/icons';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { Alert } from '@shared/components/alerts';
import { Page } from '@shared/components/layout';
import { LoaderOverlay, MobileExpanderLoader } from '@shared/components/loader';
import { ConfirmationDialogue, TimeEntrySourceCard, UnsavedChanges } from '@shared/components/modals';
import { ITableColumn, Table } from '@shared/components/tables';
import { getEmployeeTimeSheet, postEmployeeTimeSheet, postTimeSheetRange } from '@shared/fetch';
import { IAppState, IDismissedEntry, ITimesheet, ITimeSheetRange, ITimeTrackingEntry, IUserProps, IWeeklyTimesheet } from '@shared/types';
import clsx from 'clsx';
import { addDays, isToday, subDays } from 'date-fns';
import { FormikProps, withFormik } from 'formik';
import { sum } from 'lodash';
import get from 'lodash/get';
import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { CellProps } from 'react-table';
import { useMedia } from 'react-use';
import * as Yup from 'yup';

import { ActionCell, ClientCell, ClientProjectCell, DescriptionCell, HoursCell, WeeklyTotals } from '../controls';
import { MobileTimeSheetEntry } from '../mobile';
import { ConnectTask, MultiDayAdd, SwitchUser, Undismiss } from '../modals';
import { DayPicker } from './Daypicker';
import { formatDate } from '@shared/helpers';

interface ITimesheetFormValues extends ITimesheet {
  saveSuccess?: boolean;
  saveError?: boolean;
}

interface ITimesheetFormProps {
  initialValues?: ITimesheet;
  isLoading: boolean;
  day: string;
  onChangeDay(date: string): void;
  weeklyTimeSheet: IWeeklyTimesheet;
  updateWeekly(search?: { TimeEntryDate: string; EmployeeId?: number }): void;
  updateParent(values: ITimesheet): void;
  setSuccess: React.Dispatch<React.SetStateAction<boolean>>;
  setError: React.Dispatch<React.SetStateAction<boolean>>;
  reportingEmployees: number[];
  handleEmployeeSwitch(newId: number | null): void;
  loadTimeSheets(search?: { TimeEntryDate: string; includeSuggestions: boolean }): void;
}

const DefaultTimeEntry: {
  isSuggestion: boolean;
  hours: number;
  clientId: null;
  isDeleted: boolean;
  timeEntryId: null;
  billingTypeId: string;
  sourceType: string;
  externalId: null;
  description: string;
  employeeId: number;
  clientProjectId: null;
} = {
  timeEntryId: null,
  clientId: null,
  billingTypeId: '',
  employeeId: 0,
  clientProjectId: null,
  externalId: null,
  description: '',
  hours: 0,
  isDeleted: false,
  isSuggestion: false,
  sourceType: 'Manual'
};

// workaround for faulty method https://github.com/jaredpalmer/formik/issues/1580
const fixedSubmitForm = ({ submitForm, validateForm }: any): Promise<void> => {
  return new Promise((resolve, reject) => {
    submitForm()
      .then(validateForm)
      .then((errors: any) => {
        const isValid = Object.keys(errors).length === 0;
        if (isValid) {
          resolve();
        } else {
          reject();
        }
      })
      .catch((error: Error) => {
        console.log('error: ', error);
        reject();
      });
  });
};

const Timesheet: FC<ITimesheetFormProps & FormikProps<ITimesheetFormValues>> = ({
  isLoading,
  values,
  day,
  onChangeDay,
  weeklyTimeSheet,
  errors,
  dirty,
  isSubmitting,
  touched,
  setFieldValue,
  handleBlur,
  handleChange,
  submitForm,
  validateForm,
  setErrors,
  setSubmitting,
  setSuccess,
  setError,
  updateWeekly,
  updateParent,
  resetForm,
  reportingEmployees,
  handleEmployeeSwitch,
  submitCount,
  isValid,
  loadTimeSheets
}) => {
  const classes = useStyles();
  const isDesktop = useMedia('(min-width: 960px)');

  const dateSplit = day.split('-');
  const todaysDate = new Date(parseInt(dateSplit[0], 10), parseInt(dateSplit[1], 10) - 1, parseInt(dateSplit[2], 10));

  // datePicker state
  const [isOpen, setIsOpen] = useState<boolean>(false);

  // redux
  const user: IUserProps = useSelector((state: IAppState) => state.user);
  const { employees } = useSelector((state: IAppState) => state.admin);
  const { clients, clientProjectsList } = useSelector((state: IAppState) => state.clients);
  const { sourceTypes } = useSelector((state: IAppState) => state.employees);

  const updateMyData = (rowIndex: number, columnId: string, value: any) => {
    setFieldValue(`timeEntries[${rowIndex}].${columnId}`, value);
  };

  const handleAccept = (index: number) => {
    const externalId = get(values, `timeEntries[${index}].externalId`);
    if (externalId) {
      values.timeEntries.forEach((entry, i) => {
        if (entry.externalId === externalId) {
          setFieldValue(`timeEntries[${i}].isSuggestion`, false);
        }
      });
    } else {
      setFieldValue(`timeEntries[${index}].isSuggestion`, false);
    }
  };
  const handleDismiss = (index: number) => {
    const externalId = get(values, `timeEntries[${index}].externalId`);
    if (externalId) {
      values.timeEntries.forEach((entry, i) => {
        if (entry.externalId === externalId) {
          setFieldValue(`timeEntries[${i}].isDeleted`, true);
        }
      });
    } else {
      setFieldValue(`timeEntries[${index}].isDeleted`, true);
    }
  };

  // handle scroll to top on add
  const usePrevious = (value: ITimeTrackingEntry[]) => {
    const ref = useRef<ITimeTrackingEntry[]>();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  };
  const previousEntries = usePrevious(values.timeEntries);
  useEffect(() => {
    if (values.timeEntries && previousEntries && values.timeEntries.length > previousEntries.length) {
      const anchor = document.querySelector('.MuiTableRow-root:not(.MuiTableRow-head)');

      if (anchor) {
        anchor.scrollIntoView({ behavior: 'smooth', block: 'center' });
        const input = document.querySelector('#clientId-search-autocomplete-0');
        if (input && (input as any).focus) {
          (input as any).focus();
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.timeEntries]);

  useEffect(() => {
    const errorKeys = Object.keys(errors);
    if (errorKeys.length > 0 && submitCount > 0 && isSubmitting && !isValid) {
      const anchor = document.querySelector('.Mui-error');
      if (anchor) {
        anchor.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors, submitCount, isValid]);

  const connectIsDisabled = (sourceType?: string, isMultipleTimeEntry?: boolean) => {
    switch (sourceType) {
      case 'Office':
        return isMultipleTimeEntry;
      case 'Manual':
        return false;
      case 'ADO':
        return false;
      default:
        return true;
    }
  };

  const [contextMenuButtonEl, setContextMenuButtonEl] = useState<null | HTMLElement>(null);

  const handleSetContextButton = (target: any, index: number) => {
    setContextMenuButtonEl(target);
    setSelectedIndex(index);
  };

  const theme = useTheme();

  const [dismissedExternalEntries, setDismissedExternalEntries] = useState<IDismissedEntry[]>([]);
  useEffect(() => {
    setFieldValue('dismissedExternalEntries', dismissedExternalEntries);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dismissedExternalEntries]);

  // TABLE STUFF
  const columns: ITableColumn[] = useMemo(() => {
    return [
      { Header: 'Client', accessor: 'clientId', Cell: ClientCell, sort: false },
      { Header: 'Project', accessor: 'clientProjectId', Cell: ClientProjectCell, sort: false },
      { Header: 'Description', accessor: 'description', Cell: DescriptionCell, sort: false },
      { Header: 'Hours', accessor: 'hours', overrideWidth: 100, Cell: HoursCell, sort: false },
      {
        Header: 'Source',
        accessor: 'sourceType',
        overrideWidth: 92,
        sort: false,
        Cell: ({
          cell: {
            row: { original }
          }
        }: CellProps<ITimeTrackingEntry>) => {
          const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
          original.source = original.sourceType;
          return (
            <>
              <Button
                variant='text'
                size='small'
                style={{ color: theme.palette.text.primary, textTransform: 'none' }}
                onClick={event => {
                  setAnchorEl(event.currentTarget);
                }}
              >
                {original.sourceType}
              </Button>
              <TimeEntrySourceCard timeEntry={original} isOpen={!!anchorEl} close={() => setAnchorEl(null)} anchorEl={anchorEl} />
            </>
          );
        }
      },
      { Header: ' ', id: 'actions', overrideWidth: 100, sort: false, Cell: ActionCell }
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clients, clientProjectsList, sourceTypes, values.timeEntries]);

  const handleDelete = (index: number) => {
    if (values.timeEntries[index].timeEntryId) {
      if (values.timeEntries[index].externalId) {
        values.timeEntries.forEach((entry, i) => {
          if (entry.externalId === values.timeEntries[index].externalId) {
            setFieldValue(`timeEntries[${i}].isDeleted`, true);
          }
        });
      } else {
        setFieldValue(`timeEntries[${index}].isDeleted`, true);
      }
    } else {
      let timeEntries = [...values.timeEntries];
      timeEntries.splice(index, 1);
      setFieldValue('timeEntries', timeEntries);
    }
    setErrors({});
    setSelectedIndex(undefined);
  };

  const getTotal = () => {
    const availableTimeEntryHours = values.timeEntries
      .filter(x => !x.isDeleted && !x.isSuggestion)
      .map(x => {
        return x.hours ? (typeof x.hours === 'string' ? parseFloat(x.hours) : x.hours) : 0;
      });
    return sum(availableTimeEntryHours).toFixed(2);
  };

  const getConfirmationDialogueText = () => {
    return values?.employeeType === 'Billable'
      ? 'Submit timesheet? Once submitted your timesheet will be locked down and no further changes will be permitted for this day.'
      : `Submit time as complete with ${getTotal()} hours?`;
  };

  const handleDateChange = (date: Date | null) => {
    if (date) {
      onChangeDay(`${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`);
    }
  };

  const handleDateChangeForWeekly = (date: string | null) => {
    if (date) {
      onChangeDay(date);
    }
  };

  const handleChangeDay = (direction: string) => {
    const currentDay = new Date(day);
    const prevDay = subDays(currentDay, 1).toISOString().split('T')[0];
    const nextDay = addDays(currentDay, 1).toISOString().split('T')[0];

    if (dirty) {
      if (window.confirm('You have unsaved changes, are you sure you want to leave?')) {
        direction === 'prev' ? onChangeDay(prevDay) : onChangeDay(nextDay);
      } else {
        return;
      }
    } else {
      direction === 'prev' ? onChangeDay(prevDay) : onChangeDay(nextDay);
    }
  };

  // complete functionality
  const [completionError, setCompletionError] = useState('');
  const handleComplete = async () => {
    const originalCompletedValue = values.isCompleted;

    // Notify the user that we cannot save bad data.
    if (!values.date) {
      setCompletionError('Cannot complete timesheet due to invalid date.');
      return;
    }
    const day = new Date(values.date).getDay();
    const isWeekend = day === 6 || day === 0;
    if (!originalCompletedValue && (parseFloat(getTotal()) < 8 || values.employeeType === 'Billable') && !isWeekend) {
      return setShouldMarkComplete(true);
    }

    try {
      setFieldValue('isCompleted', !originalCompletedValue);
      await fixedSubmitForm({ submitForm, validateForm });
    } catch {
      setFieldValue('isCompleted', originalCompletedValue);
    }
  };
  // incomplete timesheet hours
  const [shouldMarkComplete, setShouldMarkComplete] = useState<boolean>(false);
  const handleMarkCompleteAccept = async () => {
    try {
      setFieldValue('isCompleted', true);
      setShouldMarkComplete(false);
      await fixedSubmitForm({ submitForm, validateForm });
    } catch {
      setFieldValue('isCompleted', false);
      setShouldMarkComplete(false);
    }
  };

  // multi-day add
  const [multiDayModalShowing, setMultiDayModalShowing] = useState<boolean>(false);
  const handleMultiDaySave = async (timeSheetRange: ITimeSheetRange, callback: (error?: Error) => void) => {
    try {
      setMultiDayModalShowing(false);
      setSubmitting(true);
      // post timesheet days
      await postTimeSheetRange({ ...timeSheetRange, employeeId: values.employeeId });

      const timesheetDate = new Date(formatDate(day) ?? '');
      const isDateToday = isToday(timesheetDate);

      // get back new values and update UI
      const shouldIncludeSuggestions = isDateToday;
      const timesheet = await getEmployeeTimeSheet({ TimeEntryDate: day, includeSuggestions: shouldIncludeSuggestions });
      if (timesheet) {
        // update weeklyTimeSheet values
        updateWeekly({ TimeEntryDate: day });
        // update timesheet in parent to prevent props overriding values
        updateParent(timesheet);
      }
      resetForm({ values: { ...timesheet } }); // allows for the dirty flag to reset

      // clear states and set success
      setSuccess(true);
      callback();
    } catch (error) {
      // set error, clear submitting state, and let modal know
      setError(true);
      setMultiDayModalShowing(true);
      // @ts-ignore
      callback(error);
    } finally {
      setSubmitting(false);
    }
  };

  // editSource
  const [connectTaskModalShowing, setConnectTaskModalShowing] = useState<boolean>(false);
  const [selectedIndex, setSelectedIndex] = useState<number | undefined>(undefined);

  const handleUpdateTaskConnection = (entry: ITimeTrackingEntry, previousEntry: ITimeTrackingEntry) => {
    if (previousEntry.sourceType === 'Office') {
      setDismissedExternalEntries(prev => [...prev, { externalId: previousEntry.externalId, sourceType: previousEntry.sourceType }]);
    }
    setFieldValue(`timeEntries[${selectedIndex}]`, entry);
    setContextMenuButtonEl(null);
    setSelectedIndex(undefined);
  };

  // undismiss
  const dismissedEntries = useMemo(() => {
    return values.timeEntries.filter(x => x.isDeleted && x.isSuggestion);
  }, [values.timeEntries]);
  const [undismissModalShowing, setUndismissModalShowing] = useState<boolean>(false);
  const handleUndismissSave = (externalIds: string[]) => {
    let newtimeEntries = [...values.timeEntries];
    newtimeEntries.forEach((entry, index) => {
      if (entry.externalId && externalIds.indexOf(entry.externalId) > -1) {
        // do it individually so Formik marks dirty properly and enables save
        setFieldValue(`timeEntries[${index}].isDeleted`, false);
      }
    });
  };

  // handle emulating another user
  const [userModalShowing, setUserModalShowing] = useState<boolean>(false);

  // handle grouping suggestions here
  const getCellClasses = useCallback(
    (entry: ITimeTrackingEntry) => {
      // we only group suggestions with externalIds
      if (entry.isSuggestion && entry.externalId) {
        // filter down based on current entry
        const group = values.timeEntries.filter(x => x.externalId === entry.externalId);
        const groupLength = group.length;
        // if we have 1 item just return normal cell classes
        if (groupLength === 1) {
          return classes.cell;
        } else {
          // Compare the project id of the entry to determine where we are since that will be unique
          // for each entry in a group of suggestions.
          // Based on that return normal grouped cell, top, or bottom
          if (entry.clientProjectId === group[0].clientProjectId) {
            return classes.groupedCellTop;
          } else if (entry.clientProjectId === group[groupLength - 1].clientProjectId) {
            return clsx(classes.groupedCellBottom, classes.groupedCell);
          } else {
            return classes.groupedCell;
          }
        }
      } else if (entry.externalId) {
        // filter down based on current entry
        const group = values.timeEntries.filter(x => x.externalId === entry.externalId);
        const groupLength = group.length;
        // if we have 1 item just return normal cell classes
        if (groupLength === 1) {
          return classes.cell;
        } else {
          // Compare the project id of the entry to determine where we are since that will be unique
          // for each entry in a group of suggestions.
          // Based on that return normal grouped cell, top, or bottom
          if (entry.clientProjectId === group[0].clientProjectId) {
            return clsx(classes.previousCellGroupItem, classes.groupedPreviousCellTop);
          } else if (entry.clientProjectId === group[groupLength - 1].clientProjectId) {
            return clsx(classes.groupedPreviousCellBottom, classes.groupedPreviousCell, classes.previousCellGroupItem);
          } else {
            return clsx(classes.previousCellGroupItem, classes.groupedPreviousCell);
          }
        }
      }

      return classes.cell;
    },
    [values.timeEntries, classes]
  );

  const useTableProps = {
    updateMyData,
    errors,
    touched,
    handleChange,
    handleBlur,
    data: values.timeEntries,
    handleAccept,
    handleDismiss,
    handleSetContextButton
  };

  // employee information
  const currentEmployee = useMemo(() => {
    return employees.find(x => x.employeeId === values.employeeId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.employeeId]);

  const originalUserId = useMemo(() => {
    return employees.find(x => x.email === user.email)?.employeeId ?? 0;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employees]);

  // more buttons
  const [moreActionsButtonEl, setMoreActionsButtonEl] = useState<null | HTMLElement>(null);

  return (
    <>
      <UnsavedChanges dirty={dirty && !isSubmitting} />
      {completionError && <Alert open onClose={() => setCompletionError('')} type='error' text={completionError} duration={3000} />}
      <Page
        title='Time Tracking'
        actionsStyle={{ margin: 'none', width: '100%' }}
        footerSpacing={64}
        rightAlignActions={false}
        cardHeaderIsBlockInMobile={true}
        isTitleCenteredMobile={true}
        titleGrow={false}
        actions={() => (
          <div className={classes.toolbarActionsWrapper}>
            <DayPicker isLoading={isLoading} day={day} setIsOpen={setIsOpen} handleChangeDay={handleChangeDay} />
            {values.isLocked && (
              <div className={classes.lockNotification}>
                <Typography variant='h6' display='inline' color='error'>
                  Timesheet Locked
                </Typography>
              </div>
            )}
            <div className={classes.toolbarIcons}>
              {isDesktop ? (
                <Button
                  color='primary'
                  aria-label='add'
                  onClick={() => setFieldValue('timeEntries', [{ ...DefaultTimeEntry }, ...values.timeEntries])}
                  startIcon={<Add />}
                  disabled={isLoading || values.isLocked}
                >
                  Add Entry
                </Button>
              ) : (
                <IconButton
                  color='primary'
                  aria-label='add'
                  disabled={isLoading || values.isLocked}
                  onClick={() => setFieldValue('timeEntries', [{ ...DefaultTimeEntry }, ...values.timeEntries])}
                >
                  <Add />
                </IconButton>
              )}
              {isDesktop ? (
                <Button
                  disabled={isLoading || dirty}
                  aria-label='refresh'
                  color='primary'
                  onClick={() => loadTimeSheets({ TimeEntryDate: day, includeSuggestions: true })}
                  startIcon={<Refresh />}
                >
                  Refresh
                </Button>
              ) : (
                <IconButton
                  disabled={isLoading || dirty}
                  aria-label='refresh'
                  color='primary'
                  onClick={() => loadTimeSheets({ TimeEntryDate: day, includeSuggestions: true })}
                >
                  <Refresh />
                </IconButton>
              )}
              {isDesktop ? (
                <Button
                  disabled={isLoading || !dirty || values.isLocked}
                  aria-label='save'
                  color='secondary'
                  onClick={() => submitForm()}
                  startIcon={<Save />}
                >
                  Save
                </Button>
              ) : (
                <IconButton disabled={isLoading || !dirty || values.isLocked} aria-label='save' color='secondary' onClick={() => submitForm()}>
                  <Save />
                </IconButton>
              )}
              {isDesktop ? (
                <Button
                  aria-label='complete-toggle'
                  color={values.isCompleted ? 'default' : 'secondary'}
                  disabled={isLoading || values.isCompleted}
                  onClick={handleComplete}
                  startIcon={<CheckCircleOutline />}
                >
                  {values.isCompleted ? 'Completed' : 'Submit'}
                </Button>
              ) : (
                <IconButton
                  aria-label='incomplete'
                  color={values.isCompleted ? 'default' : 'secondary'}
                  disabled={isLoading || values.isCompleted}
                  onClick={handleComplete}
                >
                  <CheckCircleOutline />
                </IconButton>
              )}
              <Tooltip title='More Actions'>
                <IconButton aria-label='more-options' onClick={({ currentTarget }) => (isLoading ? false : setMoreActionsButtonEl(currentTarget))}>
                  <MoreVert />
                </IconButton>
              </Tooltip>
            </div>
          </div>
        )}
        // subActions
      >
        {currentEmployee !== undefined && currentEmployee.email !== user.email && (
          <div className={classes.dismissUserButtonWrapper}>
            <Button
              color='primary'
              aria-label='switched-employee-dismiss'
              onClick={() => handleEmployeeSwitch(originalUserId)}
              startIcon={<Clear />}
              disabled={isLoading}
            >
              {`${currentEmployee?.firstName} ${currentEmployee?.lastName}`}
            </Button>
          </div>
        )}
        <Table
          key='timesheet-table'
          data={[...values.timeEntries]}
          hideDeleted
          columns={columns}
          isLoading={isLoading}
          noResultsText='No Time Entries'
          cellClasses={getCellClasses}
          useTableProps={useTableProps}
          hidePagination
          rowClasses={(original: ITimeTrackingEntry) => (original.isSuggestion && isDesktop ? classes.suggestion : '')}
          stickyHeader
          expandToFit
          ResponsiveComponent={MobileTimeSheetEntry}
          ResponsiveComponentLoader={MobileExpanderLoader}
          containerClasses={isDesktop ? classes.desktopTable : classes.mobileTable}
          tableSize='small'
        />
        <Toolbar disableGutters className={classes.totalsWrapper}>
          {isDesktop && (
            <WeeklyTotals loading={isLoading} weeklyTimeSheet={weeklyTimeSheet} handleChangeDate={handleDateChangeForWeekly} currentDate={day} />
          )}
          {!isLoading && (
            <Typography className={clsx(classes.totals, values.isCompleted ? '' : classes.incompleteTotals)} variant='h5' color='secondary'>
              {`Total: ${getTotal()}`}
            </Typography>
          )}
        </Toolbar>
        <LoaderOverlay open={isSubmitting} />
        {
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DatePicker
              open={isOpen}
              onOpen={() => setIsOpen(true)}
              onClose={() => setIsOpen(false)}
              value={todaysDate}
              onChange={handleDateChange}
              autoOk={true}
              okLabel={''} //hide the ok button
              TextFieldComponent={() => null}
            />
          </MuiPickersUtilsProvider>
        }
      </Page>
      <ConfirmationDialogue
        id='mark-complete'
        open={shouldMarkComplete}
        title='Mark Complete?'
        text={getConfirmationDialogueText()}
        onClose={() => setShouldMarkComplete(false)}
        onConfirm={handleMarkCompleteAccept}
      />
      <MultiDayAdd close={() => setMultiDayModalShowing(false)} open={multiDayModalShowing} onSave={handleMultiDaySave} />
      <Undismiss
        close={() => setUndismissModalShowing(false)}
        open={undismissModalShowing}
        onSave={handleUndismissSave}
        timeEntries={dismissedEntries}
      />
      <SwitchUser
        close={() => setUserModalShowing(false)}
        open={userModalShowing}
        onSave={handleEmployeeSwitch}
        reportingEmployees={reportingEmployees}
      />
      {selectedIndex !== undefined && values.timeEntries[selectedIndex] && (
        <ConnectTask
          close={() => setConnectTaskModalShowing(false)}
          open={connectTaskModalShowing}
          onSave={handleUpdateTaskConnection}
          entry={values.timeEntries[selectedIndex]}
        />
      )}
      {/* More actions for toolbar */}
      <Menu
        classes={{ paper: classes.contextMenu }}
        elevation={0}
        getContentAnchorEl={null}
        anchorEl={moreActionsButtonEl}
        keepMounted
        open={Boolean(moreActionsButtonEl)}
        onClose={() => setMoreActionsButtonEl(null)}
      >
        {!values.isLocked && values.timeEntries.some(x => x.isDeleted && x.isSuggestion) && (
          <MenuItem
            onClick={() => {
              setUndismissModalShowing(true);
              setMoreActionsButtonEl(null);
            }}
          >
            <ListItemIcon>
              <RestorePage fontSize='small' />
            </ListItemIcon>
            <ListItemText primary='Undismiss' />
          </MenuItem>
        )}
        {!values.isLocked && values.isCompleted && (
          <MenuItem
            onClick={() => {
              handleComplete();
              setMoreActionsButtonEl(null);
            }}
          >
            <ListItemIcon>
              <Delete fontSize='small' />
            </ListItemIcon>
            <ListItemText primary='Mark Incomplete' />
          </MenuItem>
        )}
        <MenuItem
          onClick={() => {
            setMultiDayModalShowing(true);
            setMoreActionsButtonEl(null);
          }}
        >
          <ListItemIcon>
            <Queue fontSize='small' />
          </ListItemIcon>
          <ListItemText primary='Multi-Day' />
        </MenuItem>
        {reportingEmployees.length > 0 && (
          <MenuItem
            onClick={() => {
              setUserModalShowing(true);
              setMoreActionsButtonEl(null);
            }}
          >
            <ListItemIcon>
              <SupervisorAccount fontSize='small' />
            </ListItemIcon>
            <ListItemText primary='Switch Employees' />
          </MenuItem>
        )}
      </Menu>

      {/* More options for time sheet entries */}
      <Menu
        classes={{ paper: classes.contextMenu }}
        elevation={0}
        getContentAnchorEl={null}
        anchorEl={contextMenuButtonEl}
        keepMounted
        open={Boolean(contextMenuButtonEl)}
        onClose={() => setContextMenuButtonEl(null)}
      >
        <MenuItem
          onClick={() => setConnectTaskModalShowing(true)}
          disabled={connectIsDisabled(
            selectedIndex !== undefined ? values.timeEntries[selectedIndex].sourceType : undefined,
            selectedIndex !== undefined
              ? values.timeEntries.filter(entry => entry.externalId === values.timeEntries[selectedIndex].externalId).length > 1
              : true
          )}
        >
          <ListItemIcon>
            <Link fontSize='small' />
          </ListItemIcon>
          <ListItemText primary='Link ADO Task' />
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleDelete(selectedIndex as number);
            setContextMenuButtonEl(null);
          }}
        >
          <ListItemIcon>
            <Delete fontSize='small' />
          </ListItemIcon>
          <ListItemText primary='Delete' />
        </MenuItem>
      </Menu>
    </>
  );
};

const TimesheetSchema = Yup.object().shape({
  isCompleted: Yup.boolean(),
  timeEntries: Yup.array().of(
    Yup.object().shape({
      clientId: Yup.number()
        .nullable()
        .when('isSuggestion', {
          is: false,
          then: Yup.number().nullable().required('Client is required')
        }),
      clientProjectId: Yup.number()
        .nullable()
        .when('isSuggestion', {
          is: false,
          then: Yup.number().nullable().required('Project is required')
        }),
      description: Yup.string()
        .nullable()
        .when('isSuggestion', {
          is: false,
          then: Yup.string().nullable().required('Description is required')
        }),
      hours: Yup.number()
        .nullable()
        .when('isSuggestion', {
          is: false,
          then: Yup.number().moreThan(0, "Can't be 0").nullable().required('Hours required')
        })
    })
  )
});

export default withFormik<ITimesheetFormProps, ITimesheetFormValues>({
  enableReinitialize: true,
  validateOnBlur: false,
  validateOnChange: false,
  mapPropsToValues: ({ initialValues = {} }): ITimesheet => {
    return {
      timeSheetId: null,
      date: new Date().toISOString(),
      isCompleted: false,
      timeEntries: [],
      employeeId: 0,
      employeeType: 'FullTimeEmployee',
      isLocked: false,
      ...initialValues
    };
  },
  validationSchema: TimesheetSchema,
  handleSubmit: async (values, { props: { updateWeekly, updateParent, day, setSuccess, setError }, setSubmitting, resetForm }): Promise<void> => {
    setSubmitting(true);

    const timesheetDate = new Date(formatDate(day) ?? '');
    const isDateToday = isToday(timesheetDate);

    const shouldIncludeSuggestions = isDateToday;

    try {
      await postEmployeeTimeSheet({ ...values });
      const timesheet = await getEmployeeTimeSheet({
        TimeEntryDate: day,
        EmployeeId: values.employeeId,
        includeSuggestions: shouldIncludeSuggestions
      });

      if (timesheet) {
        // update weeklyTimeSheet values
        updateWeekly({ TimeEntryDate: day });
        // update timesheet in parent to prevent props overriding values
        updateParent(timesheet);
      }

      resetForm({ values: { ...timesheet } }); // allows for the dirty flag to reset
      setSuccess(true);
    } catch (error) {
      resetForm({ values: { ...values } }); // allows for the dirty flag to reset
      setError(true);
    }
  }
})(Timesheet);

const useStyles = makeStyles(theme => ({
  totalsWrapper: {
    marginTop: 'auto'
  },
  totals: {
    marginLeft: 'auto',
    marginRight: theme.spacing(5),
    [theme.breakpoints.down('md')]: {
      marginRight: theme.spacing(2)
    }
  },
  toolbarActionsWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    flexWrap: 'wrap',
    [theme.breakpoints.between(960, 1003)]: {
      flexDirection: 'row-reverse'
    },

    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
      width: '100%'
    }
  },
  toolbarIcons: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      textAlign: 'center'
    }
  },
  contextMenuButton: {
    padding: 0
  },
  markIncomplete: {
    backgroundColor: theme.palette.error.main
  },
  incompleteTotals: {
    color: theme.palette.error.main
  },
  suggestion: {
    backgroundColor: theme.palette.action.selected
  },

  mobileTable: {
    padding: 0
  },
  desktopTable: {
    paddingLeft: 5,
    paddingRight: 5
  },
  cell: {
    border: 'none',
    borderTop: `5px solid ${theme.palette.background.default}`,
    '&:first-child': { borderTopLeftRadius: 10, borderBottomLeftRadius: 10 },
    '&:last-child': { borderTopRightRadius: 10, borderBottomRightRadius: 10 }
  },
  groupedCell: {
    border: 'none',
    '& > .actionButtons, & > .contextMenu': {
      display: 'none'
    }
  },
  groupedCellBottom: {
    '&:first-child': { borderBottomLeftRadius: 10 },
    '&:last-child': { borderBottomRightRadius: 10 }
  },
  groupedCellTop: {
    border: 'none',
    borderTop: `5px solid ${theme.palette.background.default}`,
    '&:first-child': { borderTopLeftRadius: 10 },
    '&:last-child': { borderTopRightRadius: 10 }
  },
  previousCellGroupItem: {
    backgroundColor: '#e0f2ff'
  },
  groupedPreviousCell: {
    border: 'none',
    '& > .actionButtons, & > .contextMenu': {
      display: 'none'
    }
  },
  groupedPreviousCellBottom: {
    borderBottom: `1px solid #fff`,
    '&:first-child': { borderBottomLeftRadius: 10 },
    '&:last-child': { borderBottomRightRadius: 10 }
  },
  groupedPreviousCellTop: {
    border: 'none',
    borderTop: `5px solid ${theme.palette.background.default}`,
    '&:first-child': { borderTopLeftRadius: 10 },
    '&:last-child': { borderTopRightRadius: 10 }
  },
  dismissUserButtonWrapper: {
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.up('md')]: {
      justifyContent: 'flex-start'
    }
  },
  contextMenu: {
    border: '1px solid #d3d4d5'
  },
  textDate: {
    cursor: 'pointer'
  },
  title: {
    marginBottom: theme.spacing(0.75)
  },
  lockNotification: {
    display: 'flex',
    alignItems: 'center',
    margin: '0 auto'
  },
  progress: {
    position: 'absolute',
    top: 6,
    left: 2,
    zIndex: 1
  }
}));
