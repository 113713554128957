import React, { FC, useState } from 'react';
// Components
import { LoaderOverlay } from '@shared/components/loader';
import { Alert } from '@shared/components/alerts';
import { Page } from '@shared/components/layout';
import AddHolidayCard from '../components/cards/AddHolidayCard';
// Fetch
import { bulkAddHoliday } from '@shared/fetch';
// Types
import { IHolidayTimeEntryItem } from '@shared/types';

export const CompanyHolidays: FC<{}> = () => {
  // save and save error functionality
  const [errorOpen, setErrorOpen] = React.useState(false);
  const [successOpen, setSuccessOpen] = React.useState(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const handleSave = async (newHoliday: IHolidayTimeEntryItem, callback: (error?: Error) => void) => {
    try {
      setIsSaving(true);
      await bulkAddHoliday(newHoliday);

      setSuccessOpen(true);
      setIsSaving(false);
      callback();
    } catch (error) {
      setErrorOpen(true);
      setIsSaving(false);
      callback(error);
    }
  };

  return (
    <Page title='Holiday Entry' justifyContent={'flex-start'}>
      <LoaderOverlay open={isSaving} />
      <Alert open={errorOpen} onClose={setErrorOpen} type='error' text='Problem saving please try again!' />
      <Alert open={successOpen} onClose={setSuccessOpen} type='success' text='Save Success!' />
      <AddHolidayCard onSave={handleSave} />
    </Page>
  );
};
