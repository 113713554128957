import React, { FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
// Components
import { Tooltip, Grid, Button } from '@material-ui/core';
import { History } from '@material-ui/icons';

interface IActionCellProps {
  row: { index: number };
  data: any[];
  handleHistoryModal: (index: number) => void;
}

export const ActionCell: FC<IActionCellProps> = ({ row: { index }, handleHistoryModal, data }) => {
  const classes = useStyles();

  return (
    <Grid className={clsx(classes.actionButtons, 'actionButtons')}>
      <Tooltip title={data[index].hideHistoryEdit ? 'No History Available' : 'Rate History'}>
        <span>
          <Button
            className={classes.contextMenuButton}
            disableRipple
            size='medium'
            color='primary'
            startIcon={<History />}
            onClick={() => handleHistoryModal(index)}
            disabled={data[index].hideHistoryEdit}
          >
            History
          </Button>
        </span>
      </Tooltip>
    </Grid>
  );
};

const useStyles = makeStyles(theme => ({
  delete: {
    '& svg': {
      fill: theme.palette.error.main
    }
  },
  accept: {
    '& svg': {
      fill: theme.palette.primary.main
    }
  },
  actionButtons: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  contextMenuButton: {
    padding: 0
  }
}));
