import { authFetch } from './';
import { ISharedFile } from '@shared/types/sharedFiles';
// Types

export const getSharedFiles = async (clentId: number, forDashboard?: boolean): Promise<ISharedFile[]> => {
  let query = '?';
  if (forDashboard) {
    query += 'ForDashboard=true';
  }
  try {
    const { data } = await authFetch.get(`/api/extranet/SharedFiles/${clentId}${query}`);
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getSharedFile = async (clentId: number, fileId: number): Promise<any> => {
  try {
    const data = await authFetch.get(`/api/extranet/SharedFiles/${clentId}/SharedFile/${fileId}`, 
      {responseType: 'blob'});
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const deleteSharedFile = async (clientId: number, fileId: number): Promise<any> => {
  try {
    const { data } = await authFetch.delete(`/api/extranet/SharedFiles/${clientId}/SharedFile/${fileId}`);
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const createSharedFile = async (clentId: number, files: File): Promise<any> => {
  // Need to make sure this works
  const formData = new FormData();
  formData.append('Files', files);
  try {
    const { data } = await authFetch.post(`/api/extranet/SharedFiles/${clentId}/SharedFile`, formData);
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};
