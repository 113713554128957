export function byteToUint8Array(byteArray: number[]) {
    let uint8Array = new Uint8Array(byteArray.length);
    for(let i = 0; i < uint8Array.length; i++) {
        uint8Array[i] = byteArray[i];
    }

    return uint8Array;
}

export function base64ToArrayBuffer(base64: string) {
    let binaryString = atob(base64);
    let bytes = new Uint8Array(binaryString.length);
    for (let i = 0; i < binaryString.length; i++) {
        bytes[i] = binaryString.charCodeAt(i);
    }
    return new Uint8Array(bytes.buffer);
}