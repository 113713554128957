import { PieDatum } from '@nivo/pie';

import { IUserStoryCount } from '@shared/types';

export const buildBudgetGraphData = (spent: number = 0, budget: number = 0, invoiced: number = 0) => [
  {
    id: 'Budget',
    budget,
    invoiced: 0,
    spent: 0
  },
  {
    id: 'Actual',
    budget: 0,
    spent,
    invoiced
  }
];

export const buildStoryCompletionChart = (counts: IUserStoryCount[]): PieDatum[] => {
  return counts.map(count => ({
    id: count.status,
    label: count.status === 'InProgress' ? 'In Progress' : count.status,
    value: Number(toFixedPrecision(count.percentEffort, 2)),
    color: count.status === 'New' ? '#000' : '#fff' 
  }));
};

  // It turns out "toFixed" is not reliable in JavaScript. Results with 3 significant digits would 
  // occasionally round down, even if the last digit was a "5". This is because the conversion to 
  // floating point looses precision. The solution is to use a more reliable rounding method.
export const calculateStoryCompletionPercent = (workCompletePercent: number): string => {
  return `${Math.round((Number(toFixedPrecision(workCompletePercent, 2))) * 100)}%`;
};

export const toFixedPrecision = (value: number, precision: number = 2) => {
  return (+(Math.round(+(value + 'e' + precision)) + 'e' + -precision)).toFixed(precision);
}