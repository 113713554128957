import React, { FC } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Page } from '@shared/components/layout/Page';
import { ProgramIncrementContent } from '@shared/components/widgets/ProgramIncrementCalendarContent';
interface IProgramIncrements {}

export const ProgramIncrements: FC<IProgramIncrements> = () => {
  return (
    <Page title='Calendar' overflow flexGrow={false} hideHeader={true} isColumn={false} setHeight={false}>
      <ProgramIncrementContent stackVertically={false} isWidget={false} />
    </Page>
  );
};

const useStyles = makeStyles<Theme>((theme: Theme) => ({}));
