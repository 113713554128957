import { makeStyles, Theme, Typography } from '@material-ui/core';
import { TypographyProps } from '@material-ui/core/Typography';
import clsx from 'clsx';
import React, { FC } from 'react';

interface IH5Props extends TypographyProps {}

export const H5: FC<IH5Props> = ({ children, ...props }) => {
  const classes = h5Styles();
  return (
    <Typography {...props} className={clsx(classes.h5, props.className)} variant='h5'>
      {children}
    </Typography>
  );
};

const h5Styles = makeStyles((theme: Theme) => ({
  h5: {
    fontSize: 18,
    fontWeight: 700,
    margin: theme.spacing(6, 0, 3)
  }
}));
