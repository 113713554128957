
/**
 * A check to make sure we show the correct value to compare against, sometimes the value is correct when it is published, but not when it is first created or in a draft state
 * @param isNotDraft boolean
 * @param reportValue string
 * @param initialReport string
 */
export const getCorrectProgressReportEditorValue = (isNotDraft: boolean, reportValue: string, initialReport?: string) => {
  return isNotDraft && initialReport && initialReport !== reportValue ? initialReport : reportValue;
};
