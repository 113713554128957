import React, { FC, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { useMedia } from 'react-use';
import { isEmpty, sortBy, isNull, get } from 'lodash';
import { TextField, Button } from '@material-ui/core';
import { Edit, Add } from '@material-ui/icons';
import Autocomplete from '@material-ui/lab/Autocomplete';
import clsx from 'clsx';

import { EmployeeForm } from '../components/forms';
import { Page } from '@shared/components/layout';
import { Table, TableToolbar, ITableColumn } from '@shared/components/tables';
import { MobileExpanderLoader } from '@shared/components/loader';
import { MobileEmployee } from '../components/mobile';
import { Alert } from '@shared/components/alerts';

import { getEmployees, updateEmployee, addEmployee, reduxFetch, getEmployeeRates, getEmployeeTypes } from '@shared/fetch';
import { setEmployees } from '@shared/redux/actions';
import { IAppState, IEmployeeInfo, IBasicObject, IEmployeeRate, IEmployeeType } from '@shared/types';

export const Employees: FC = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const isDesktop = useMedia('(min-width: 960px)');
  const [isPageLoading, setPageLoading] = useState<boolean>(false);
  const [roles, setRoles] = useState<IEmployeeRate[] | []>([]);
  const [types, setTypes] = useState<IEmployeeType[] | []>([]);
  const [activeFilter, setActiveFilter] = useState<IBasicObject>({ label: 'Active', value: 'active' });
  const [filteredEmployees, setFilteredEmployees] = useState<IEmployeeInfo[]>([]);
  const [error, setError] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);

  const [editing, setEditing] = useState<IEmployeeInfo | {} | null>(null);

  const { employees } = useSelector((state: IAppState) => state.admin);

  const columns = [
    {
      Header: 'First Name',
      accessor: 'firstName'
    },
    {
      Header: 'Last Name',
      accessor: 'lastName'
    },
    {
      Header: 'Role',
      accessor: 'currentTitle'
    },
    {
      Header: 'Type',
      id: 'employeeType',
      Cell: ({
        cell: {
          row: { original }
        }
      }: any) => {
        const type = types && types.find(t => t.text === original.employeeType);
        return type ? type.description : '';
      }
    },
    {
      Header: 'Target Utilization',
      accessor: (d: IEmployeeInfo) => (d.targetUtilization ? d.targetUtilization.toFixed(2) : '0.00')
    },
    {
      Header: 'Status',
      accessor: ({ isActive }: any) => {
        return isActive ? 'Active' : 'Inactive';
      },
      canFilter: true
    },
    {
      Header: ' ',
      id: 'actions',
      sort: false,
      hideLoad: true,
      className: classes.actionButton,
      Cell: ({
        cell: {
          row: { original }
        }
      }: any) => {
        return (
          <Button color='primary' startIcon={<Edit />} onClick={() => setEditing(original)}>
            Edit
          </Button>
        );
      }
    }
  ];

  const selectOptions = [
    { label: 'Active', value: 'active' },
    { label: 'Inactive', value: 'inactive' }
  ];

  const onFormSave = async (values: IEmployeeInfo, saveCallback: (error?: Error) => void) => {
    try {
      let updatedEmployees: IEmployeeInfo[] = [];
      // New employees don't have an employeeId until they're created, so we use -1 as a temp id/flag for a new employee
      if (values.employeeId === -1) {
        const newEmployeeId = await addEmployee(values);
        const response = await getEmployees();
        updatedEmployees = [...response];
      } else {
        await updateEmployee(values);
        const response = await getEmployees();
        updatedEmployees = [...response];
      }
      await dispatch(setEmployees(updatedEmployees));
      setEditing(null);
    } catch (error) {
      saveCallback(get(error, 'response.data.Detail'));
      setError(true);
    }
  };
  const loadEmployees = async () => {
    try {
      setPageLoading(true);
      const employeeResponse = await reduxFetch(getEmployees, employees);
      dispatch(setEmployees(employeeResponse));
      setPageLoading(false);
    } catch (error) {
      console.log(error);
      setPageLoading(false);
    }
  };

  const loadRoles = async () => {
    try {
      const [rolesResponse, typesResponse] = await Promise.all([getEmployeeRates(), getEmployeeTypes()]);
      setRoles(rolesResponse);
      setTypes(typesResponse);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    loadEmployees();
    loadRoles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isEmpty(employees)) {
      let employeeArray: IEmployeeInfo[] = [];
      if (isNull(activeFilter)) {
        employeeArray = employees;
      } else {
        if (activeFilter.value === 'active') {
          employeeArray = employees.filter(employee => employee.isActive);
        }

        if (activeFilter.value === 'inactive') {
          employeeArray = employees.filter(employee => !employee.isActive);
        }
      }
      setFilteredEmployees(sortBy(employeeArray, 'firstName'));
    }
  }, [activeFilter, employees]);

  return !isNull(editing) ? (
    <EmployeeForm initialValues={editing} onSave={onFormSave} onCancel={() => setEditing(null)} employeeRoles={roles} employeeTypes={types} />
  ) : (
    <Page
      title='Employees'
      flexGrow={false}
      setHeight={false}
      footerSpacing={100}
      actions={() =>
        isNull(editing) && (
          <Button color='primary' onClick={() => setEditing({})} aria-label='add-employee' startIcon={<Add />}>
            Add Employee
          </Button>
        )
      }
    >
      {isNull(editing) && (
        <>
          <TableToolbar>
            <Autocomplete
              id='status-autocomplete'
              className={classes.activeSelect}
              options={selectOptions.map(option => option)}
              getOptionLabel={option => option && option.label}
              value={activeFilter}
              disabled={isPageLoading}
              onChange={(e: any, value: any) => setActiveFilter(value)}
              renderInput={params => <TextField {...params} label='Status' variant='outlined' size='small' />}
            />
          </TableToolbar>
        </>
      )}

      <Table
        data={sortBy(filteredEmployees, 'firstName')}
        columns={columns as ITableColumn[]}
        stickyHeader
        expandToFit
        isLoading={isPageLoading}
        ResponsiveComponent={MobileEmployee}
        useTableProps={{ setEditing }}
        ResponsiveComponentLoader={MobileExpanderLoader}
        containerClasses={clsx(isDesktop ? classes.desktopTable : classes.mobileTable, !isNull(editing) ? classes.editing : '')}
      />

      <Alert
        open={error}
        onClose={() => setError(false)}
        type='error'
        text={`An error was encountered when trying to ${editing ? 'update' : 'save'} this user in ADO. Please try again.`}
      />
      <Alert open={success} onClose={() => setSuccess(false)} type='success' text='Save Success!' />
    </Page>
  );
};

const useStyles = makeStyles(theme => ({
  activeSelect: {
    minWidth: '200px',
    marginBottom: theme.spacing(1)
  },
  mobileTable: {
    padding: 0
  },
  desktopTable: {
    paddingLeft: 5,
    paddingRight: 5
  },
  actionButton: {
    padding: 0
  },
  editing: {
    display: 'none'
  },
  rightAlign: {
    textAlign: 'right'
  }
}));
