import React, { FC, useState, useEffect, useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useMedia } from 'react-use';
import get from 'lodash/get';
import clsx from 'clsx';
import * as localForage from 'localforage';
// Redux
import { useDispatch, useSelector, batch } from 'react-redux';
import { setClientProjectStatuses, setClientProjectTypes, setBillingTypes, setClientProjects as setReduxClientProjects } from '@shared/redux/actions';
// Components
import { Table, ITableColumn, TableToolbar } from '@shared/components/tables';
import { TextField, Button } from '@material-ui/core';
import { Edit, Add } from '@material-ui/icons';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { MobileExpanderLoader } from '@shared/components/loader';
import { MobileClientProject } from '../components/mobile';
import { ClientProjectForm } from '../components/forms';
import { Alert } from '@shared/components/alerts';
import { Page } from '@shared/components/layout';
import { HashtagCell } from '@shared/components/table-cells';
// Fetch
import {
  getClients,
  reduxFetch,
  getClientProjects,
  getClientProjectStatuses,
  getBillingTypes,
  updateClientProjects,
  getClientProjectTypes,
  getRevenueCategories
} from '@shared/fetch';
// Types
import { IAppState, IClientProject, IRevenueCategory, IClientInfo } from '@shared/types';
// helpers
import { formatDateWithDay, formatMoney } from '@shared/helpers';
// constants
import { STORED_CLIENT } from '@shared/constants';

export const ClientProjects: FC = () => {
  const classes = useStyles();
  const isDesktop = useMedia('(min-width: 960px)');

  // redux
  const { clientProjectStatuses, clientProjectTypes } = useSelector((state: IAppState) => state.clients);
  const { billingTypes } = useSelector((state: IAppState) => state.admin);
  const dispatch = useDispatch();

  // general state
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isPageLoading, setIsPageLoading] = useState<boolean>(false);
  const [clients, setClients] = useState<IClientInfo[]>([]);
  const [clientProjects, setClientProjects] = useState<IClientProject[]>([]);
  const [revenueCategories, setRevenueCategories] = useState<IRevenueCategory[]>([]);

  const initialLoad = async () => {
    try {
      setIsPageLoading(true);

      const [clientsResponse, clientProjectStatusesResponse, clientProjectTypesResponse, billingTypesResponse, revenueCategoriesResponse] =
        await Promise.all([
          getClients(),
          reduxFetch(getClientProjectStatuses, clientProjectStatuses),
          reduxFetch(getClientProjectTypes, clientProjectTypes),
          reduxFetch(getBillingTypes, billingTypes),
          getRevenueCategories()
        ]);

      batch(() => {
        dispatch(setClientProjectStatuses(clientProjectStatusesResponse));
        dispatch(setClientProjectTypes(clientProjectTypesResponse));
        dispatch(setBillingTypes(billingTypesResponse));
      });
      setClients(clientsResponse);
      setRevenueCategories(revenueCategoriesResponse);

      setIsPageLoading(false);
    } catch (error) {
      setIsPageLoading(false);
    }
  };
  useEffect(() => {
    initialLoad();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // filter
  const [client, setClient] = useState<{ label: string; value: number } | null>(null);
  const [status, setStatus] = useState<{ label: string; value: string } | null>({ label: 'Approved', value: 'Approved' });
  const loadClientProjects = async () => {
    try {
      setIsLoading(true);
      const clientsProjectsResponse = await getClientProjects({
        ClientId: client ? client.value : undefined,
        ProjectStatus: status ? status.value : undefined
      });
      setClientProjects(clientsProjectsResponse.filter(project => project.name !== 'All Projects'));

      // update client in local storage
      if (client && client.value) {
        localForage.setItem(STORED_CLIENT, JSON.stringify(client));
      } else {
        localForage.setItem(STORED_CLIENT, '');
      }

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    if (client) {
      loadClientProjects();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client, status]);
  // intial load
  const getStoredClient = async () => {
    const storedClient = await localForage.getItem<string>(STORED_CLIENT);

    if (storedClient) {
      try {
        setClient(JSON.parse(storedClient));
      } catch (error) {
        // do nothing, just won't have default client
      }
    }
  };
  useEffect(() => {
    getStoredClient();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [editing, setEditing] = useState<IClientProject | {} | null>(null);

  const columns = useMemo(() => {
    return [
      { Header: 'Name', accessor: 'name' },
      {
        Header: 'Hashtag',
        accessor: 'hashtag',
        Cell: HashtagCell
      },
      {
        Header: 'Default Billing Type',
        id: 'billingTypeId',
        overrideWidth: 190,
        accessor: (d: IClientProject) => billingTypes.find(x => x.value === d.billingTypeId)?.description
      },
      { Header: 'Scoped Hours', accessor: 'scopedHours', className: classes.rightAlign, overrideWidth: 150 },
      {
        Header: 'Budget Amount',
        id: 'budgetedAmount',
        overrideWidth: 175,
        accessor: (d: IClientProject) => (d.budgetedAmount ? formatMoney(d.budgetedAmount, 0) : null),
        className: classes.rightAlign
      },
      {
        Header: 'Actual Spend',
        id: 'actualSpend',
        overrideWidth: 175,
        accessor: (d: IClientProject) => (d.actualSpend ? formatMoney(d.actualSpend) : null),
        className: classes.rightAlign
      },
      {
        Header: 'Blended Rate',
        id: 'blendedRate',
        overrideWidth: 150,
        accessor: (d: IClientProject) => formatMoney(d.blendedRate),
        className: classes.rightAlign
      },
      {
        Header: 'Status',
        id: 'projectStatus',
        overrideWidth: 105,
        accessor: (d: IClientProject) => clientProjectStatuses.find(x => x.value === d.projectStatus)?.description
      },
      {
        Header: 'Contract Review',
        id: 'contractReview',
        overrideWidth: 165,
        accessor: (d: IClientProject) => (d.contractReviewDate ? formatDateWithDay(d.contractReviewDate, false) : null)
      },
      {
        Header: ' ',
        id: 'actions',
        overrideWidth: 100,
        sort: false,
        hideLoad: true,
        className: classes.actionButton,
        Cell: ({
          cell: {
            row: { index }
          }
        }: any) => (
          <Button color='primary' startIcon={<Edit />} onClick={() => setEditing(clientProjects[index])}>
            Edit
          </Button>
        )
      }
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientProjects]);

  // success and error state
  const [success, setSuccess] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  return (
    <Page
      title={'Client Projects'}
      hideTitle={!!editing}
      setHeight={false}
      overflow={editing !== null}
      flexGrow={false}
      isColumn={false}
      footerSpacing={100}
      actions={
        editing === null && isDesktop && client?.value
          ? () => (
              <Button
                color='primary'
                onClick={() => setEditing({ clientId: client.value, clientName: client.label })}
                aria-label='add-project'
                startIcon={<Add />}
                disabled={isPageLoading}
                className={classes.addButton}
              >
                Add Project
              </Button>
            )
          : undefined
      }
    >
      {!editing && (
        <TableToolbar>
          <Autocomplete
            id='clients-autocomplete'
            className={classes.clientsFormControl}
            options={clients.map(client => ({ label: client.name, value: client.clientId }))}
            getOptionLabel={option => option && option.label}
            value={client}
            disabled={isPageLoading}
            onChange={(e: any, value: any) => setClient(value)}
            disableClearable
            renderInput={params => <TextField {...params} label='Client' variant='outlined' size='small' />}
          />
          <Autocomplete
            id='status-autocomplete'
            className={classes.statusFormControl}
            options={clientProjectStatuses.map(status => ({ label: status.description, value: status.text }))}
            getOptionLabel={option => option && option.label}
            value={status}
            disabled={isPageLoading}
            onChange={(e: any, value: any) => setStatus(value)}
            renderInput={params => <TextField {...params} label='Status' variant='outlined' size='small' />}
          />
        </TableToolbar>
      )}
      <Table
        data={clientProjects}
        columns={columns as ITableColumn[]}
        stickyHeader
        expandToFit
        isLoading={isLoading || isPageLoading}
        noResultsText={client && client.value ? 'No Projects For This Client.' : 'Please Select a Client.'}
        ResponsiveComponent={MobileClientProject}
        ResponsiveComponentLoader={MobileExpanderLoader}
        mobileProps={{
          handleEdit: (val: IClientProject) => setEditing(val)
        }}
        containerClasses={clsx(isDesktop ? classes.desktopTable : classes.mobileTable, editing !== null ? classes.editing : '')}
      />
      {editing !== null && (
        <ClientProjectForm
          initialValues={editing}
          onSave={async (values: IClientProject, saveCallback: (error?: Error) => void) => {
            try {
              await updateClientProjects(values);
              const clientsProjectsResponse = await getClientProjects({
                ClientId: client ? client.value : undefined,
                ProjectStatus: status ? status.value : undefined
              });
              setClientProjects(clientsProjectsResponse);

              // update redux values so they can be used elsewhere
              try {
                const newClientsProjectsResponse = await getClientProjects({ ProjectStatus: 'Approved' });
                dispatch(setReduxClientProjects(newClientsProjectsResponse));
              } catch (error) {
                console.log('error', error);
                setError(true);
              }

              saveCallback();
              setEditing(null);
              setSuccess(true);
            } catch (error) {
              console.log('error', error);
              saveCallback(get(error, 'response.data.Detail') || true);
              const message = get(error, 'response.data.Detail');
              setErrorMessage(message && message.length ? message : '');
              setError(true);
            }
          }}
          onCancel={() => {
            setEditing(null);
            loadClientProjects();
          }}
          revenueCategories={revenueCategories}
        />
      )}
      <Alert
        open={Boolean(error)}
        onClose={() => {
          setError(false);
          setErrorMessage('');
        }}
        type='error'
        text={`Problem saving. ${error && errorMessage.length ? `${errorMessage}. Please try again!` : 'Please try again!'}`}
      />
      <Alert open={success} onClose={() => setSuccess(false)} type='success' text='Save Success!' />
    </Page>
  );
};

const useStyles = makeStyles(theme => ({
  clientsFormControl: {
    margin: theme.spacing(0, 1, 1, 0),
    minWidth: 300
  },
  statusFormControl: {
    margin: theme.spacing(0, 1, 1, 0),
    minWidth: 165
  },
  mobileTable: {
    padding: 0
  },
  desktopTable: {
    paddingLeft: 5,
    paddingRight: 5
  },
  rightAlign: {
    textAlign: 'right'
  },
  actionButton: {
    padding: 0
  },
  addButton: {
    marginLeft: 'auto'
  },
  editing: {
    display: 'none'
  }
}));
