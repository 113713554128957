import React, { FC, useMemo } from 'react';
import { theme } from '@shared/helpers';
import { Grid, Typography, ButtonGroup, IconButton, Theme, makeStyles } from '@material-ui/core';
import { Edit, Clear, Save, Close } from '@material-ui/icons';
// Components
import { OptionListItem } from '@shared/components/draggable-table/option-List-Item';
import { ListItemColors } from '@shared/components/draggable-table/util';
import { Wysiwyg, WysiwygRender } from '@shared/components/wysiwyg';

interface IReleaseProcedureStepsDragAndDropRow {
  option: any;
  name: string;
  nameValue: string;
  originalNameValue: string;
  status: string;
  statusValue: boolean;
  orderValue: number;
  handleChangeName: any;
  handleChangeStatus: any;
  index: number;
  setEditingId: React.Dispatch<any>;
  editingId: string;
  handleDelete: () => void;
  handleCreate: (name: string, id: number | string) => Promise<void>;
  handleUpdate: (name: string, order: number, id: number | string) => Promise<void>;
  isRefetchingExecutionPlans: boolean;
  color?: ListItemColors;
}
export const ReleaseProcedureStepsDragAndDropRow: FC<IReleaseProcedureStepsDragAndDropRow> = ({
  option,
  nameValue,
  originalNameValue,
  handleChangeName,
  handleChangeStatus,
  status,
  statusValue,
  name,
  orderValue,
  index,
  setEditingId,
  editingId,
  handleCreate,
  handleUpdate,
  handleDelete,
  isRefetchingExecutionPlans,
  color
}) => {
  const classes = useStyles();

  const isNew = useMemo(() => {
    return !!option.id?.toString()?.includes('new-');
  }, [option.id]);

  const handleSave = async () => {
    setEditingId('');
    if (isNew) {
      await handleCreate(nameValue, option.id);
    } else {
      await handleUpdate(nameValue, orderValue, option.id);
    }
  };

  const handleCancel = async () => {
    setEditingId('');
    handleChangeName(originalNameValue);
  };

  return (
    <>
      <OptionListItem key={option.id} item={option} id={option.id} color={color}>
        <Grid alignItems={'center'} justify={'space-between'} container spacing={1}>
          <Grid item xs={9}>
            <Typography>
              <WysiwygRender
                html={nameValue}
                plainHtml={true}
                htmrOptions={{
                  transform: {},
                  preserveAttributes: [],
                  dangerouslySetChildren: ['style']
                }}
              />
            </Typography>
          </Grid>
          <Grid style={{ justifyContent: 'flex-end', display: 'flex' }} item xs={2}>
            {editingId === option.id ? (
              <ButtonGroup>
                <IconButton size='small' color='secondary' aria-label='save' disabled={isRefetchingExecutionPlans} onClick={() => handleSave()}>
                  <Save className={classes.icon} />
                </IconButton>
                <IconButton size='small' color='default' aria-label='remove' disabled={isRefetchingExecutionPlans} onClick={() => handleCancel()}>
                  <Close className={classes.icon} />
                </IconButton>
              </ButtonGroup>
            ) : (
              <ButtonGroup>
                <IconButton
                  size='small'
                  color='primary'
                  aria-label='edit'
                  disabled={isRefetchingExecutionPlans}
                  onClick={() => setEditingId(option.id)}
                >
                  <Edit className={classes.icon} />
                </IconButton>
                <IconButton size='small' color='default' aria-label='remove' disabled={isRefetchingExecutionPlans} onClick={() => handleDelete()}>
                  <Clear className={classes.icon} />
                </IconButton>
              </ButtonGroup>
            )}
          </Grid>
        </Grid>
      </OptionListItem>
      {editingId === option.id && (
        <Wysiwyg
          id='editor'
          plainWywiwyg={true}
          className={classes.wysiwyg}
          onChange={(value) => {
            handleChangeName(value);
          }}
          value={option.name}
        />
      )}
    </>
  );
};

const useStyles = makeStyles<Theme>(() => ({
  wysiwyg: {
    backgroundColor: theme.palette.background.paper,
    '& .ql-toolbar': {
      borderRadius: '15px 15px 0 0',
      backgroundColor: theme.palette.grey[200]
    },
    '& .ql-container': {
      height: '100% !important',
      borderRadius: '0 0 15px 15px'
    },
    '&& .ql-editor': {
      minHeight: 200
    }
  },
  icon: {
    fontSize: '1.125rem',
  },
}));
