import React, { FC } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import TablePagination from '@material-ui/core/TablePagination';
import TablePaginationActions from '../tables/TablePaginationActions';
import { TableSummary } from './TableSummary';
import { DEFAULT_PAGE } from '@shared/constants';
// Types
import { ITableSummaryData } from '@shared/types';

interface IPaginationProps {
  labelRowsPerPage?: string;
  rowsPerPageOptions?: number[] | { label: string; value: number }[] | any;
  count: number;
  rowsPerPage: number;
  page: number;
  setPage: (newPage: number) => void;
  setRowsPerPage: (val: number) => void;
  summaryData?: ITableSummaryData;
}

export const Pagination: FC<IPaginationProps> = ({
  labelRowsPerPage,
  rowsPerPageOptions,
  count,
  rowsPerPage,
  page,
  setPage,
  setRowsPerPage,
  summaryData
}) => {
  const classes = paginationStyles();
  return (
    <div className={classes.paginationContainer}>
      <TablePagination
        labelRowsPerPage={labelRowsPerPage}
        count={count}
        page={page}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={rowsPerPageOptions || [10, 25, 50]}
        component='div'
        classes={{
          root: classes.paginationRoot
        }}
        onChangePage={(e: any, newPage: number) => setPage(newPage)}
        onChangeRowsPerPage={(e: React.ChangeEvent<HTMLInputElement>) => {
          setPage(DEFAULT_PAGE);
          setRowsPerPage(parseInt(e.target.value, 10));
        }}
        ActionsComponent={TablePaginationActions}
      />
      {summaryData && <TableSummary data={summaryData} />}
    </div>
  );
};

const paginationStyles = makeStyles((theme: Theme) => ({
  paginationContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',  
    }
  },
  paginationRoot: {
    margin: '0 auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&& .MuiTablePagination-selectRoot': {
      marginLeft: 0,
      marginRight: '5px'
    },
    '&& .MuiToolbar-gutters': {
      paddingLeft: 0
    },
    '&& .MuiTablePagination-actions': {
      marginLeft: 0
    },
    [theme.breakpoints.up('md')]: {
      '&& .MuiTablePagination-selectRoot': {
        marginLeft: '8px',
        marginRight: '32px'
      },
      '&& .MuiToolbar-gutters': {
        paddingLeft: 0
      },
      '&& .MuiTablePagination-actions': {
        marginLeft: '20px'
      }
    }
  }
}));
