import React, { FC } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { Link, useLocation } from 'react-router-dom';
// Components
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ExpandLess from '@material-ui/icons/ExpandLess';
// Types
import { ISubNavItem } from './SubNavItem';
import { SubNavMenu } from './SubNavMenu';

export interface INavItem {
  rootPath: string;
  title: string;
  subNav?: ISubNavItem[];
  icon: JSX.Element;
  userAccess?: string;
  featureFlag?: string;
}

interface INavItemProps extends INavItem {
  isNavOpen: boolean;
}

const splitPathString = (path: string) => {
  return path.split('/').filter((x: string) => x);
};

export const NavItem: FC<INavItemProps> = ({ title, rootPath, icon, isNavOpen, subNav }) => {
  const classes = useStyles();

  const { pathname } = useLocation();

  // determine if this rootPath is active so we can highlight
  // when nav is closed or show menu when nav is open
  const splitPathname = splitPathString(pathname);
  const splitPath = splitPathString(rootPath);
  let active = splitPath[0] === splitPathname[0];

  return (
    <div className={classes.root}>
      <Link
        className={clsx(classes.link, { [classes.listItemActive]: active })}
        to={Array.isArray(subNav) && subNav.length ? subNav[0].path : rootPath}
      >
        {isNavOpen && (
          <div className={classes.iconAndTextGroupExpanded}>
            <div className={clsx(classes.firstGroup, { active })}>
              <ListItem className={clsx(classes.listItem, { active })} button key={title}>
                <ListItemIcon
                  classes={{
                    root: clsx(classes.icon, {
                      [classes.iconActive]: active
                    })
                  }}
                >
                  {icon}
                </ListItemIcon>
              </ListItem>
            </div>
            {isNavOpen && (
              <div className={classes.secondGroup}>
                <ListItemText primary={title} />
                <ListItemIcon>
                  {active ? <ExpandLess className={classes.expandIcon} /> : <ExpandMore className={clsx(classes.expandIcon, 'expand-icon')} />}
                </ListItemIcon>
              </div>
            )}
          </div>
        )}
        {!isNavOpen && (
          <div className={classes.iconAndTextGroup}>
            <div className={clsx(classes.firstGroup, { active })}>
              <ListItem className={clsx(classes.listItem, { active })} button key={title}>
                <ListItemIcon
                  classes={{
                    root: clsx(classes.icon, {
                      [classes.iconActive]: active
                    })
                  }}
                >
                  {icon}
                </ListItemIcon>
              </ListItem>
            </div>
            {isNavOpen && (
              <div className={classes.secondGroup}>
                <ListItemText primary={title} />
                <ListItemIcon>
                  {active ? <ExpandLess className={classes.expandIcon} /> : <ExpandMore className={clsx(classes.expandIcon, 'expand-icon')} />}
                </ListItemIcon>
              </div>
            )}
          </div>
        )}
      </Link>
      {(subNav || !isNavOpen) && <SubNavMenu className='sub-nav-menu' title={title} isNavOpen={isNavOpen} isActive={active} subNav={subNav || []} />}
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    '&:hover > .sub-nav-menu': {
      display: 'flex'
    },
    position: 'relative',
    paddingBottom: theme.spacing(0.25),
    '&:hover > a > div > div > div:not(.active)': {
      width: 54,
      borderTopRightRadius: 80,
      borderBottomRightRadius: 80,
      backgroundColor: theme.palette.common.white,
      color: theme.palette.secondary.main,
      '& svg': {
        fill: '#989898'
      }
    },
    '& a > div > div.active': {
      width: 54,
      height: 45,
      borderTopRightRadius: 80,
      borderBottomRightRadius: 80,
      backgroundColor: theme.palette.secondary.main,
      color: '#989898',
      '& svg': {
        fill: theme.palette.common.white
      }
    }
  },
  firstGroup: {
    display: 'flex'
  },
  secondGroup: {
    display: 'flex',
    paddingLeft: 14,
    paddingTop: 8,
    paddingBottom: 4,
    width: 200
  },
  iconAndTextGroup: {
    display: 'flex',
    width: 76
  },
  iconAndTextGroupExpanded: {
    display: 'flex',
    width: 275
  },
  link: {
    textDecoration: 'none',
    color: theme.palette.text.secondary
  },
  listItem: {
    overflowX: 'hidden',
    width: 54,
    height: 45,
    borderTopRightRadius: 80,
    borderBottomRightRadius: 80,
    '&:hover': {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.secondary.main,
      '& svg': {
        fill: theme.palette.common.white
      }
    }
  },
  listItemActive: {
    color: theme.palette.secondary.main
  },
  icon: {
    '& > svg': {
      fill: '#989898'
    }
  },
  iconActive: {
    '& > svg': {
      fill: theme.palette.secondary.main
    }
  },
  expandIcon: {
    marginLeft: 'auto'
  }
}));
