import { createActions } from 'redux-actions';
// Types
import {
  IClientInfo,
  IClientProject,
  IEnumResponse,
  IClientMentionLink,
  IClientProjectType,
  IClientProjectsListItem
} from '@shared/types';

export const { setClients, setClientProjects, setClientProjectsList, setClient, setClientProjectStatuses, setClientProjectTypes, setClientMentionLinks, setSelectedProjectId } = createActions({
  SET_CLIENT: (client: IClientInfo) => ({ client }),
  SET_CLIENTS: (clients: IClientInfo[]) => ({ clients }),
  SET_CLIENT_PROJECTS: (clientProjects: IClientProject[]) => ({ clientProjects }),
  SET_CLIENT_PROJECTS_LIST: (clientProjectsList: IClientProjectsListItem[]) => ({ clientProjectsList }),
  SET_CLIENT_PROJECT_STATUSES: (clientProjectStatuses: IEnumResponse[]) => ({ clientProjectStatuses }),
  SET_CLIENT_PROJECT_TYPES: (clientProjectTypes: IClientProjectType[]) => ({ clientProjectTypes }),
  SET_CLIENT_MENTION_LINKS: (clientMentionLinks: IClientMentionLink[]) => ({ clientMentionLinks }),
  SET_SELECTED_PROJECT_ID: (selectedProjectId: number) => ({ selectedProjectId }),
});
