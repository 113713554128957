import { FileCopy, VideoLibrary, PictureAsPdf } from '@material-ui/icons'
import { ISharedFile } from '@shared/types/sharedFiles'

const videoFileExtnsions = ['mp4']

export const getFileExtension = ({ fileName }: ISharedFile) => fileName.split('.').pop()

export const isVideoFile = (file: ISharedFile) => videoFileExtnsions.includes(getFileExtension(file) ?? '')

export const selectFileIcon = ({ fileName }: ISharedFile) => {
  const fileExt = fileName.split('.').pop()

  switch(fileExt) {
    case 'mp4':
      return VideoLibrary;
    case 'pdf':
      return PictureAsPdf
    default:
      return FileCopy
  }
}