import React, { FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import LogoResolver from '@shared/helpers/LogoResolver';

const useStyles = makeStyles(theme => ({
  logo: {
    width: 225,
    height: 'auto',
    display: 'block',
    [theme.breakpoints.up('sm')]: {
      width: 270
    }
  }
}));

interface ILogoProps {
  inverted?: boolean;
}

export const Logo: FC<ILogoProps> = ({ inverted }) => {
  const classes = useStyles();
  return <img className={classes.logo} src={LogoResolver.getLogoUrl()} alt='logo'></img>;
};
