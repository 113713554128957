import React, { FC } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
// Components
import { FormLabel, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails, Typography } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
// Types
import { IBudgetHistoryRecord } from '@shared/types';
import { FormikHandlers, FieldMetaProps } from 'formik';
// helpers
import { formatShortFriendlyDateWithTime, formatMoney } from '@shared/helpers';

interface IMobileBudgetAdjustment extends FormikHandlers, FieldMetaProps<any> {
  original: IBudgetHistoryRecord;
}

export const MobileBudgetAdjustment: FC<IMobileBudgetAdjustment> = ({ original }) => {
  const classes = useStyles();
  const { budgetedAmount, scopedHours, comment, createdDate, createdByName, budgetedTotal, scopedHoursTotal } = original;
  return (
    <ExpansionPanel className={classes.root}>
      <ExpansionPanelSummary className={classes.expansionPanel} expandIcon={<ExpandMore />} aria-controls='panel1a-content' id='panel1a-header'>
        <div className={classes.panelSummaryWrapper}>
          <Typography component='span'>{budgetedAmount ? formatMoney(budgetedAmount) : '$0.00'}</Typography>
        </div>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails className={classes.details}>
        <FormLabel component='h2'>Adjustment Amount</FormLabel>
        <Typography component='span'>{budgetedAmount ? formatMoney(budgetedAmount) : '$0.00'}</Typography>
        <FormLabel component='h2'>Scope Adjustment</FormLabel>
        <Typography component='span'>{scopedHours || '0'}</Typography>
        <FormLabel component='h2'>Comment</FormLabel>
        <Typography component='span'>{comment || '--'}</Typography>
        <FormLabel component='h2'>Date Created</FormLabel>
        <Typography component='span'>{createdDate ? formatShortFriendlyDateWithTime(createdDate) : '--'}</Typography>
        <FormLabel component='h2'>Created By</FormLabel>
        <Typography component='span'>{createdByName || '--'}</Typography>
        <FormLabel component='h2'>Budget Total</FormLabel>
        <Typography component='span'>{budgetedTotal || '$0.00'}</Typography>
        <FormLabel component='h2'>Scoped Hours Total</FormLabel>
        <Typography component='span'>{scopedHoursTotal || '0'}</Typography>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    maxWidth: '100%'
  },
  expansionPanel: {
    padding: '0px 24px'
  },
  panelSummaryWrapper: {
    display: 'flex',
    alignItems: 'flex-start',
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  details: {
    display: 'flex',
    flexDirection: 'column'
  }
}));
