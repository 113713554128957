import { theme } from '@shared/helpers/theme';

export enum ListItemColors {
  Primary = 'primary',
  Secondary = 'secondary',
  Dark = 'dark',
  Default = 'default'
}

export const primaryListItemStyle = {
  paddingLeft: 0,
  paddingRight: 0,
  backgroundColor: 'rgb(239,245,255)',
  borderRadius: '4px',
  borderLeft: `4px solid ${theme.palette.primary.main}`
};

export const secondaryListItemStyle = {
  paddingLeft: 0,
  paddingRight: 0,
  backgroundColor: 'rgb(245,245,245)',
  borderRadius: '4px',
  borderLeft: `4px solid ${theme.palette.secondary.main}`
};

export const darkListItemStyle = {
  paddingLeft: 0,
  paddingRight: 0,
  backgroundColor: 'rgb(239,245,255)',
  borderRadius: '4px',
  borderLeft: `4px solid ${theme.palette.common.black}`
};

export const defaultListItemStyle = {
  paddingLeft: 0,
  paddingRight: 0,
  backgroundColor: 'rgb(239,245,255)',
  borderRadius: '4px',
  borderLeft: `4px solid ${theme.palette.grey[400]}`
};

export const getStyle = (color: ListItemColors) => {
  switch (color) {
    case ListItemColors.Primary:
      return primaryListItemStyle;
    case ListItemColors.Secondary:
      return secondaryListItemStyle;
    case ListItemColors.Dark:
      return darkListItemStyle;
    default:
      return defaultListItemStyle;
  }
};
