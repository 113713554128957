import { IEnumResponse } from './global';
import { ITimeEntrySourceWidgetInfo } from './widgets';

// Used on general release form & releasing page
export enum ReleaseTimeZone {
  EST = 'EST',
  CST = 'CST',
  MST = 'MST',
  PST = 'PST'
}

/** @see TimeEntryViewModel.cs */
export interface ITimeTrackingEntry extends ITimeEntrySourceWidgetInfo {
  billingTypeId: string;
  clientId: number | null;
  clientProjectId: number | null;
  description: string;
  employeeId: number | null;
  externalId: string | null;
  hours: number;
  isDeleted: boolean;
  isSuggestion: boolean;
  sourceType: string;
  timeEntryId: number | null;
  isLocked: boolean;
  date: string | null;
}

export interface IDismissedEntry {
  externalId: string | null;
  sourceType: string;
}

export interface ITimesheet {
  timeSheetId: number | null;
  date: string | null;
  isCompleted: boolean;
  employeeId: number;
  employeeType: 'FullTimeEmployee' | 'Billable';
  isLocked: boolean;
  timeEntries: ITimeTrackingEntry[];
  dismissedExternalEntries?: IDismissedEntry[];
}

export interface IEmployeeState {
  sourceTypes: IEnumResponse[];
}

interface IDay {
  date: string;
  dayOfWeek: string;
  hoursCompleted: number;
  isCompleted: boolean;
}

export interface IWeeklyTimesheet {
  currentDate: string;
  totalHoursCompleted: number;
  dailyTotals: IDay[];
}

export interface ITimesheetCompletion {
  person: ITimesheetWidgetPerson;
  managedEmployees: ITimesheetWidgetPerson[];
}

export interface ITimesheetWidgetPerson {
  employeeName: string;
  totalHours: number;
  incompleteDates: string[];
  isPrimary: boolean;
  employeeId: number;
  isSubReport: boolean;
}

export interface ITimesheetWidgetColors {
  incomplete: string;
  complete: string;
  managerComplete: string;
}

export interface ITimesheetWidget {
  title: string;
  cardColor: string;
  colors: ITimesheetWidgetColors;
  people: ITimesheetWidgetPerson[];
  showNames: boolean;
}

export interface ITimeSheetRange {
  startDate: string;
  endDate: string;
  description: string;
  clientProjectId: number;
  employeeId?: number;
}

export interface ITimesheetCompletionV2 {
  currentUser: ITimesheetCompletionPerson;
}
export interface ITimesheetCompletionPerson {
  employeeId: number;
  name: string;
  reports: ITimesheetCompletionPerson[];
  time: ITimesheetCompletionTime
}

export interface ITimesheetCompletionTime {
  currentDate: string;
  totalHoursCompleted: number;
  dailyTotals: IDay[];
  isCompleted: boolean;
}