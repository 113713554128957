import { Card, CardContent, CardHeader, Divider, Grid, Typography } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Variant } from '@material-ui/core/styles/createTypography';
import { theme } from '@shared/helpers/theme';
import { useOnWindowSize } from '@shared/hooks';
import { CardContext } from '@src/context/card';

import React, { FC, useContext, useEffect, useRef } from 'react';
import { useMedia } from 'react-use';

interface IDashboardCard {
  title?: string;
  actions?: any;
  children?: any;
  hasChildren?: boolean;
  className?: any;
  color?: string;
  isColumn?: boolean;
  overflow?: boolean;
  flexGrow?: boolean;
  setHeight?: boolean;
  hideTitle?: boolean;
  cardHeaderIsBlockInMobile?: boolean;
  elevation?: number;
  footerSpacing?: number;
  rightAlignActions?: boolean;
  justifyContent?: string;
  isTitleCenteredMobile?: boolean;
  disableJustifyContentOnMobile?: boolean;
  titleVariant?: Variant;
  titleComponent?: React.ElementType;
  titleGrow?: boolean;
  additionalHeaderContent?: JSX.Element;
  useCardWrapper?: boolean;
  cardPadding?: string; 
  parentCardPadding?: string;
  parentCardMarginBottom?: string;
  mobileStyles?: { maxWidth?: string; padding?: string };
}

export const DashboardCard: FC<IDashboardCard> = ({
  title,
  actions = undefined,
  children,
  hasChildren = true,
  color = theme.palette.primary.main,
  isColumn = true,
  overflow = false,
  flexGrow = true,
  setHeight = true,
  hideTitle = false,
  cardHeaderIsBlockInMobile = false,
  elevation = 12,
  footerSpacing = 135,
  rightAlignActions = true,
  justifyContent = 'space-around',
  isTitleCenteredMobile = false,
  disableJustifyContentOnMobile = false,
  titleVariant = 'h2',
  titleComponent = 'h2',
  titleGrow = true,
  additionalHeaderContent,
  useCardWrapper = true,
  parentCardPadding = '1rem',
  parentCardMarginBottom = '1rem',
  cardPadding = '1rem',
  mobileStyles
}) => {
  const isMobile = useMedia('(max-width: 768px)');
  const classes = useStyles({
    overflow,
    flexGrow,
    setHeight,
    isMobile,
    rightAlignActions,
    justifyContent,
    cardHeaderIsBlockInMobile,
    isTitleCenteredMobile,
    disableJustifyContentOnMobile,
    titleGrow,
    parentCardPadding,
    parentCardMarginBottom,
    cardPadding,
    mobileStyles
  });

  const cardContentRef = useRef<HTMLDivElement>(null);
  const { setCardContentHeight } = useContext(CardContext);
  const size = useOnWindowSize();
  useEffect(() => {
    // Fix for card content height
    setCardContentHeight(Math.max((cardContentRef?.current?.offsetHeight ?? 0) - footerSpacing, 0));
  }, [size.height]);

  const content = (
    <>
      {!hideTitle && (
        <>
          <CardHeader
            action={
              <>
                {additionalHeaderContent && <div className={classes.additionalHeaderContent}>{additionalHeaderContent}</div>}
                {actions}
              </>
            }
            title={
              <Typography variant={titleVariant} component={titleComponent} style={color ? { color: color } : undefined}>
                {title}
              </Typography>
            }
            classes={{ root: classes.cardHeader, content: classes.cardHeaderContent, action: classes.cardHeaderActions }}
          />
          <Divider className={classes.divider} />
        </>
      )}

      {hasChildren && (
        <CardContent ref={cardContentRef} classes={{ root: classes.cardContent }}>
          <Grid
            container
            item
            justify='space-around'
            style={{ flexDirection: isColumn ? 'column' : 'row', height: setHeight ? '100%' : 'auto', marginTop: -16 }}
            className={classes.cardContentGrid}
          >
            {children}
          </Grid>
        </CardContent>
      )}
    </>
  );

  return useCardWrapper ? (
    <Card elevation={elevation} className={classes.card}>
      {content}
    </Card>
  ) : (
    content
  );
};

const useStyles = makeStyles<
  Theme,
  {
    overflow: boolean;
    flexGrow: boolean;
    setHeight: boolean;
    isMobile: boolean;
    rightAlignActions: boolean;
    justifyContent: string;
    cardHeaderIsBlockInMobile: boolean;
    isTitleCenteredMobile: boolean;
    disableJustifyContentOnMobile: boolean;
    titleGrow: boolean;
    parentCardPadding: string;
    parentCardMarginBottom: string;
    cardPadding: string;
    mobileStyles?: { maxWidth?: string; padding?: string };
  }
>((theme: Theme) => ({
  cardHeader: {
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'wrap',
    gap: '12px',
    [theme.breakpoints.down('sm')]: {
      justifyContent: ({ isTitleCenteredMobile, disableJustifyContentOnMobile }) =>
        disableJustifyContentOnMobile ? undefined : isTitleCenteredMobile ? 'center' : 'flex-start',
      alignText: ({ isTitleCenteredMobile }) => (isTitleCenteredMobile ? 'center' : 'left'),
      display: ({ cardHeaderIsBlockInMobile }) => (cardHeaderIsBlockInMobile ? 'block' : 'flex')
    }
  },
  additionalHeaderContent: {
    marginLeft: 'auto',
    [theme.breakpoints.down('md')]: {
      display: 'inline-block'
    }
  },
  cardContentGrid: {
    '& > *': {
      flexGrow: ({ flexGrow }) => (flexGrow ? 1 : 0),
      width: '100%'
    },
    height: ({ setHeight }) => (setHeight ? '100%' : 'auto'),
    justifyContent: ({ justifyContent }) => justifyContent
  },
  cardContent: {
    height: '100%',
    padding: ({ cardPadding }) => cardPadding,
    '& > *': {
      flexGrow: ({ flexGrow }) => (flexGrow ? 1 : 0)
    },
    '&:last-child': {
      paddingBottom: 0
    }
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',
    overflowY: ({ isMobile, overflow }) => (isMobile ? 'auto' : overflow ? 'auto' : 'hidden'),
    maxHeight: '100%',
    height: '100%',
    width: '100%',
    padding: ({ parentCardPadding }) => parentCardPadding,
    marginBottom: ({ parentCardMarginBottom }) => parentCardMarginBottom,
    '@media (max-width: 600px)': {
      maxWidth: ({ mobileStyles }) => mobileStyles?.maxWidth || '92vw',
      padding: ({ mobileStyles }) => mobileStyles?.padding || theme.spacing(0.25),
    }
  },
  cardHeaderContent: {
    flex: ({ titleGrow }) => (titleGrow ? '1 1 auto' : '0 0 auto'),
    [theme.breakpoints.down('sm')]: {
      textAlign: ({ isTitleCenteredMobile }) => (isTitleCenteredMobile ? 'center' : 'left')
    }
  },
  cardHeaderActions: {
    flex: ({ rightAlignActions }) => (rightAlignActions ? '0 0 auto' : '1 0 auto'),
    [theme.breakpoints.down('sm')]: {
      flex: '1 1 auto'
    }
  },
  divider: {
    marginBottom: theme.spacing(1)
  }
}));
