import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import * as localForage from 'localforage';
// Components
import { Link, Grid, List, ListItem, Typography, Divider } from '@material-ui/core';
import { AccountCircle } from '@material-ui/icons/';
// types
import { IUserProps, IAppState } from '@shared/types';
// Auth
import { logout } from '@src/adalConfig';
// constants
import { selectedClientIdLSKey } from '@shared/constants';

interface IUserMenu {
  isMobile?: boolean;
}

export const UserMenu: FC<IUserMenu> = ({ isMobile }) => {
  const classes = useStyles();
  const user: IUserProps = useSelector((state: IAppState) => state.user);

  return (
    <Grid className={classes.root} container justify='center'>
      {isMobile && (
        <>
          <Grid item className={classes.userInfo}>
            <AccountCircle color='secondary' />
            <Typography color='secondary' variant='body1'>
              {user.name}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
        </>
      )}
      <List className={classes.list}>
        <Link href='/settings/config' className={clsx(classes.desktopLink)}>
          {user && user.userAccess && !user.userAccess.ClientOnly && (
            <ListItem button selected={false}>
              Settings
            </ListItem>
          )}
        </Link>
        <Link target='_blank' href='https://www.mercuryworks.com/' className={clsx(classes.desktopLink)}>
          <ListItem button selected={false}>
            Back to MercuryWorks
          </ListItem>
        </Link>
        <ListItem
          button
          selected={false}
          className={clsx(classes.desktopLink)}
          onClick={() => {
            // remove from local forage the clientId saved
            localForage.removeItem(selectedClientIdLSKey);
            logout();
          }}
        >
          Logout
        </ListItem>
      </List>
    </Grid>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    flexDirection: 'row'
  },
  desktopLink: {
    textDecoration: 'none',
    cursor: 'pointer',
    fontSize: theme.spacing(1),
    color: theme.palette.primary.main,
    '&:hover': {
      color: theme.palette.secondary.main,
      textDecoration: 'none'
    }
  },
  link: {
    textDecoration: 'none',
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.secondary.main,
      textDecoration: 'none'
    }
  },
  userInfo: {
    textAlign: 'center',
    marginTop: theme.spacing(1),
    '& svg': {
      fontSize: theme.spacing(3)
    },
    '& > p': {
      fontSize: theme.spacing(1.5)
    }
  },
  list: {
    width: '100%',
    padding: theme.spacing(0.5, 0)
  }
}));
