import React, { FC, useEffect } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Grid, TextField, InputAdornment } from '@material-ui/core';

import { Close, Search } from '@material-ui/icons';
import { FiltersButtons } from '@src/admin/components/filters';
// Types

interface ISharedFilesGraphFilters {
  isLoading: boolean;
  applyFilters: (clearFilters?: boolean) => void;
  setHasAppliedFilters: (val: boolean) => void;
  hasAppliedFilters?: boolean;
  searchTerm: string;
  setSearchTerm: (val: any) => void;
  handleTermSearch: (clearSearch?: boolean) => Promise<void>;
  clearFilters?: boolean;
}

export const SharedFilesGraphFilters: FC<ISharedFilesGraphFilters> = ({
  hasAppliedFilters,
  setHasAppliedFilters,
  isLoading,
  applyFilters,
  searchTerm,
  setSearchTerm,
  handleTermSearch,
  clearFilters
}) => {
  const classes = useStyles();

  useEffect(() => {
    if (clearFilters) {
      setHasAppliedFilters(false);
      setSearchTerm('');
      applyFilters(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid container alignItems='center' className={classes.searchWrapper}>
      <Grid item xs={12} sm={6} md={4} lg={6} className={classes.inputWrapper}>
        <TextField
          className={classes.filterSelect}
          size='small'
          fullWidth
          variant='outlined'
          placeholder='Search...'
          name='search'
          value={searchTerm}
          disabled={isLoading}
          InputProps={{
            startAdornment: (
              <InputAdornment
                position='start'
                className={classes.searchIcon}
                onClick={() => {
                  if (searchTerm && searchTerm.length > 0) {
                    setHasAppliedFilters(true);
                    handleTermSearch();
                  }
                }}
              >
                <Search />
              </InputAdornment>
            ),
            endAdornment: searchTerm ? (
              <InputAdornment
                position='end'
                className={classes.searchIcon}
                onClick={() => {
                  setSearchTerm('');
                  handleTermSearch(true);
                }}
              >
                <Close />
              </InputAdornment>
            ) : null
          }}
          onKeyDown={e => {
            if (e.key === 'Enter' && searchTerm && searchTerm.length > 0) {
              setHasAppliedFilters(true);
              handleTermSearch();
            }
          }}
          onChange={e => {
            setSearchTerm(e.target.value);
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={3}>
        <FiltersButtons
          applyFiltersText='Search'
          applyFiltersIcon={<Search />}
          hasAppliedFilters={hasAppliedFilters}
          isDisabled={isLoading}
          handleApplyFilters={() => {
            setHasAppliedFilters(true);
            applyFilters();
          }}
          handleResetFilters={() => {
            setHasAppliedFilters(false);
            setSearchTerm('');
            applyFilters(true);
          }}
        />
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  searchIcon: {
    cursor: 'pointer',
    color: theme.palette.grey[500]
  },
  mobileTable: {
    padding: 0
  },
  desktopTable: {
    paddingLeft: 5,
    paddingRight: 5
  },
  actionButton: {
    padding: 0
  },
  iconButton: {
    padding: 0
  },
  inputWrapper: {
    [theme.breakpoints.up('sm')]: {
      paddingRight: '12px'
    }
  },
  searchWrapper: {
    minHeight: theme.spacing(4)
  },
  filterSelect: {
    width: '100%',
    marginBottom: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      margin: theme.spacing(0, 1, 1, 0)
    },
    '&& .MuiInputBase-root': {
      width: '100%',
      paddingLeft: theme.spacing(0.5)
    }
  }
}));
