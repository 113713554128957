import { Card, CardContent, Checkbox, FormControlLabel, Grid, IconButton, lighten, Typography } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Cached } from '@material-ui/icons';
import clsx from 'clsx';

import React, { FC } from 'react';

interface IProgressReportDetailCard {
  icon: any;
  detail: number;
  detailName: 'budget' | 'hours' | 'stories' | 'charts' | null;
  detailLabel: string;
  detailFormatter?: (value: any) => string;
  refresh: string | null;
  handleRefresh: (type: 'budget' | 'hours' | 'stories' | 'charts' | null) => Promise<void>;
  isAdmin: boolean;
  isEditing: boolean | undefined;
  showDetail: boolean;
  setShowDetail: (value: React.SetStateAction<boolean>) => void;
  isPrimary?: boolean;
}

export const ProgressReportDetailCard: FC<IProgressReportDetailCard> = ({
  icon,
  detail,
  detailName,
  detailLabel,
  detailFormatter,
  refresh,
  handleRefresh,
  isAdmin,
  isEditing,
  showDetail,
  setShowDetail,
  isPrimary = true
}) => {
  const classes = useStyles({ isPrimary });

  const DetailIcon = icon;
  return (
    <>
      <Card className={classes.card}>
        <CardContent className={classes.cardContent}>
          <Grid container alignItems='center'>
            <Grid alignItems='center' item xs={9}>
              <div className={classes.detailText}>
                <Typography className={classes.statValue}>{!!detailFormatter ? detailFormatter(detail) : detail}</Typography>
                <Typography variant='h2' gutterBottom className={classes.statTitle}>
                  {detailLabel.toUpperCase()}
                </Typography>
              </div>
              {isAdmin && isEditing && (
                <IconButton
                  onClick={() => handleRefresh(detailName)}
                  className={clsx(classes.refresh, refresh === detailName ? classes.refreshAnimation : undefined)}
                >
                  <Cached />
                </IconButton>
              )}
            </Grid>
            <Grid item xs={3} className={classes.iconContainer}>
              {<DetailIcon className={classes.detailIcon} />}
            </Grid>
          </Grid>
        </CardContent>
        {isAdmin && isEditing && (
          <div className={classes.statsContent}>
            <FormControlLabel
              control={
                <Checkbox
                  name={`show=${detailName}`}
                  classes={{ root: classes.checkbox, checked: classes.checkbox }}
                  checked={showDetail}
                  onChange={() => setShowDetail(!showDetail)}
                />
              }
              label='Show on Report'
            />
          </div>
        )}
      </Card>
    </>
  );
};

const useStyles = makeStyles<Theme, { isPrimary?: boolean }>((theme: Theme) => ({
  cardContent: {
    padding: '16px 10px',
    paddingBottom: `16px !important`
  },
  detailText: {
    textAlign: 'center'
  },
  detailIcon: {
    color: ({ isPrimary }) => (isPrimary ? lighten(theme.palette.primary.light, 0.5) : lighten(theme.palette.secondary.light, 0.5)),
    fontSize: 70,
    backgroundColor: ({ isPrimary }) => (isPrimary ? theme.palette.primary.dark : theme.palette.secondary.dark),
    borderRadius: '50%',
    padding: theme.spacing(1)
  },
  icon: {
    color: ({ isPrimary }) => (isPrimary ? theme.palette.primary.light : theme.palette.secondary.light),
    fontSize: 70
  },
  iconContainer: {
    textAlign: 'center'
  },
  card: {
    position: 'relative',
    backgroundColor: ({ isPrimary }) => (isPrimary ? theme.palette.primary.main : theme.palette.secondary.main),
    color: theme.palette.common.white,
    //
    height: '100%'
  },
  refresh: {
    position: 'absolute',
    right: 0,
    top: 0,
    padding: theme.spacing(0.5),
    color: theme.palette.common.white
  },
  refreshAnimation: {
    animation: '$spin 1.3s linear infinite'
  },
  '@keyframes spin': {
    from: {
      transform: 'rotate(0deg)'
    },
    to: {
      transform: 'rotate(360deg)'
    }
  },
  statValue: {
    fontSize: 30,
    lineHeight: 1,
    fontWeight: 700
  },
  statTitle: {
    fontSize: 16,
    marginTop: '3px',
    color: ({ isPrimary }) => (isPrimary ? lighten(theme.palette.primary.light, 0.5) : lighten(theme.palette.secondary.light, 0.5))
  },
  statsContent: {
    borderTop: 0,
    padding: theme.spacing(0.5, 0.75),
    borderBottomRightRadius: 5,
    borderBottomLeftRadius: 5
  },
  checkbox: {
    color: 'white!important'
  }
}));
