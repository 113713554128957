import React, { FC } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useMedia } from 'react-use';
import { Link as RouterLink } from 'react-router-dom';
// Components
import Link from '@material-ui/core/Link';
import Toolbar from '@material-ui/core/Toolbar';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import AppBar from '@material-ui/core/AppBar';
import Explore from '@material-ui/icons/Explore';
import ExitToApp from '@material-ui/icons/ExitToApp';
import Phone from '@material-ui/icons/Phone';
import Twitter from '@material-ui/icons/Twitter';
import LinkedIn from '@material-ui/icons/LinkedIn';
import MarkunreadMailboxOutlined from '@material-ui/icons/MarkunreadMailboxOutlined';
// Auth
import { logout } from '@src/adalConfig';

const useStyles = makeStyles(theme => ({
  appBar: {
    top: 'auto',
    bottom: 0,
    height: 175,
    [theme.breakpoints.up('md')]: {
      height: 70
    },
    backgroundColor: theme.palette.grey[200],
    color: theme.palette.text.secondary,
    '& svg': {
      width: theme.spacing(0.9),
      height: theme.spacing(0.9),
      marginRight: theme.spacing(0.25)
    },
    '& p': {
      display: 'flex',
      alignItems: 'center',
      '& a': {
        display: 'flex',
        alignItems: 'center'
      }
    },
    '& a': {
      textDecoration: 'none',
      color: theme.palette.text.secondary,
      '&:hover': {
        color: theme.palette.secondary.main
      }
    }
  },
  toolbar: {
    padding: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    }
  },
  mainGrid: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    [theme.breakpoints.up('md')]: {
      justifyContent: 'center',
      flexDirection: 'row',
      alignItems: 'center'
    }
  },
  justifyCenter: {
    justifyContent: 'flex-start',
    [theme.breakpoints.up('md')]: {
      justifyContent: 'center'
    }
  },
  center: {
    marginTop: theme.spacing(0.5),
    textAlign: 'left',
    '& > p': {
      justifyContent: 'flex-start'
    },
    [theme.breakpoints.up('md')]: {
      marginTop: 0,
      textAlign: 'center',
      '& > p': {
        justifyContent: 'center'
      }
    }
  },
  left: {
    '& > p': {
      justifyContent: 'flex-start'
    },
    [theme.breakpoints.up('md')]: {
      marginRight: 'auto'
    }
  },
  right: {
    '& > p': {
      justifyContent: 'flex-start'
    },
    [theme.breakpoints.up('md')]: {
      marginLeft: 'auto',
      textAlign: 'right',
      '& > p': {
        justifyContent: 'flex-end'
      }
    }
  },
  rightLinks: {
    justifyContent: 'flex-start',
    marginTop: theme.spacing(0.5),
    [theme.breakpoints.up('md')]: {
      justifyContent: 'flex-end',
      marginTop: 0
    }
  },
  link: {
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.secondary.main
    }
  },
  exploreIcon: {
    alignSelf: 'flex-start',
    marginTop: 4
  }
}));

export const Footer: FC = () => {
  const classes = useStyles();
  const theme = useTheme();

  const handleBackToTop = (event: React.MouseEvent<HTMLDivElement>) => {
    const anchor = ((event.target as HTMLDivElement).ownerDocument || document).querySelector('#back-to-top-anchor');

    if (anchor) {
      anchor.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  };

  const isDesktop = useMedia('(min-width: 960px)');

  return (
    <AppBar component='footer' position='fixed' style={{ zIndex: isDesktop ? theme.zIndex.drawer + 1 : -1 }} className={classes.appBar}>
      <Toolbar className={classes.toolbar}>
        <Grid container className={classes.mainGrid} direction='row' alignItems='center'>
          <Grid className={classes.left}>
            <Typography variant='body2'>© 2020 MercuryWorks</Typography>
            <Typography variant='body2'>
              <Phone /> (813) 933-9800
            </Typography>
          </Grid>
          <Grid className={classes.center}>
            <Grid container direction='row' className={classes.justifyCenter} alignItems='center'>
              <RouterLink to='/'>My MercuryWorks Home </RouterLink>&nbsp;/&nbsp;
              <Typography variant='body2' className={classes.link} onClick={logout}>
                <ExitToApp /> Logout
              </Typography>
            </Grid>
            <Typography variant='body2'>
              <Link
                target='_blank'
                href='https://www.google.com/maps/place/1+N+Dale+Mabry+Hwy,+Tampa,+FL+33609/@27.9453298,-82.5071993,17z/data=!3m1!4b1!4m5!3m4!1s0x88c2c30e4df999b1:0x1d78eb0c9f309353!8m2!3d27.9453298!4d-82.5050106'
              >
                <Explore className={classes.exploreIcon} /> 1 N Dale Mabry Hwy. Suite 1050, Tampa, FL 33609
              </Link>
            </Typography>
          </Grid>
          <Grid className={classes.right}>
            <Grid container className={classes.rightLinks} direction='row' alignItems='center'>
              <Link target='_blank' href='https://twitter.com/Mercury_Works'>
                <Twitter />
              </Link>{' '}
              <Link target='_blank' href='https://linkedin.com/company/mercury-works'>
                <LinkedIn />
              </Link>
              <Typography variant='body2'>/&nbsp;</Typography>
              <Typography variant='body2' className={classes.link} onClick={handleBackToTop}>
                Back to top
              </Typography>
            </Grid>
            <Typography variant='body2'>
              <Link target='_blank' href='mailto:info@mercuryworks.com'>
                <MarkunreadMailboxOutlined /> info@mercuryworks.com
              </Link>
            </Typography>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};
