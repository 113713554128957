import React, { FC } from 'react';
import { Button, CircularProgress } from '@material-ui/core';
import { CloudDownload } from '@material-ui/icons';

interface IExportButtonProps {
  isExporting: boolean;
  onClick: () => void;
  ariaLabel: string;
  size?: 'small' | 'medium' | 'large';
  isDisabled?: boolean;
  className?: string;
  exportProgress?: string;
}

export const ExportButton: FC<IExportButtonProps> = ({ className, isExporting, onClick, ariaLabel, size, isDisabled, exportProgress }) => {
  return (
    <Button
      className={className}
      color='secondary'
      onClick={onClick}
      size={size}
      aria-label={ariaLabel}
      startIcon={isExporting ? <CircularProgress size={15} /> : <CloudDownload />}
      disabled={isDisabled}
    >
      {exportProgress ? exportProgress : isExporting ? 'Exporting...' : 'Export'}
    </Button>
  );
};
