import { adalApiFetch } from '@src/adalConfig';

const axiosAdalFetch = async (method = 'GET', url = '', data = {}, options = {}) => {
  try {
    const response = await adalApiFetch(url, { method, data, ...options });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

// keep axios syntax for simplicity
export const authFetch = {
  get: async (url: string, options = {}) => {
    return await axiosAdalFetch('GET', url, {}, options);
  },
  put: async (url: string, data: any, options?: any) => {
    return await axiosAdalFetch('PUT', url, data, options);
  },
  post: async (url: string, data: any, options?: any) => {
    return await axiosAdalFetch('POST', url, data, options);
  },
  delete: async (url: string) => {
    return await axiosAdalFetch('DELETE', url);
  }
};

// we need to define a fetch for redux variables that only fetches when they don't exist
const isEmpty = (val: any) =>
  (val === null || val === undefined || (Array.isArray(val) && val.length === 0) || Object.keys(val).length === 0) as boolean;

export const reduxFetch = async <T>(fetch: (...args: any[]) => Promise<T>, reduxValue: T, ...args: Parameters<typeof fetch>): Promise<T> => {
  if (!isEmpty(reduxValue)) return reduxValue;
  try {
    const data = await fetch.apply(null, [...args]);
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};
