import React, { FC } from 'react';

import { DashboardCard } from '@src/clients/components/DashboardCard';
import { Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { OpenRequestsContent } from './OpenRequestsContent';

interface IOpenRequests {}

export const OpenRequests: FC<IOpenRequests> = () => {
  const history = useHistory();

  return (
    <DashboardCard
      actions={
        <Button color='primary' variant='contained' onClick={() => history.push('/clients/requests')}>
          View All
        </Button>
      }
      title={'Open Requests'}
    >
      <OpenRequestsContent />
    </DashboardCard>
  );
};
