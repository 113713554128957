import React, { FC, useEffect, useState } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import {
  Grid,
  TextField,
  InputAdornment,
  Typography,
  Checkbox,
  FormControl,
  Select,
  OutlinedInput,
  MenuItem,
  ListItemText,
  IconButton
} from '@material-ui/core';
import { getClientZendeskStatuses, getClientZendeskSeverities, getClientZendeskFormTypes } from '../../shared/fetch';
import { RequestsFilterButtons } from '../../admin/components/filters';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { DateRangePicker, DateRange } from '@shared/components/inputs';
import { Close, Search } from '@material-ui/icons';
// Types
import { IClientRelationsLookup } from '@shared/types';

interface IRequestsFilters {
  isLoading: boolean;
  applyFilters: (clearFilters?: boolean) => void;
  setHasAppliedFilters: (val: boolean) => void;
  hasAppliedFilters?: boolean;
  searchTitle: string;
  setSearchTitle: (val: any) => void;
  handleTitleSearch: (clearSearch?: boolean) => void;
  selectedStatusIds?: any;
  setSelectedStatusIds: (val: any) => void;
  selectedFormTypeId: any;
  setSelectedFormTypeId: (val: any) => void;
  selectedSeverity: any;
  setSelectedSeverity: (val: string) => void;
  clearFilters?: boolean;
  hasTitle?: boolean;
  requestDateRange: DateRange | undefined;
  setRequestDateRange: (val: any) => void;
  updatedDateRange: DateRange | undefined;
  setUpdatedDateRange: (val: any) => void;
}

export const RequestsFilters: FC<IRequestsFilters> = ({
  hasAppliedFilters,
  setHasAppliedFilters,
  isLoading,
  applyFilters,
  setSelectedStatusIds,
  selectedFormTypeId,
  setSelectedFormTypeId,
  selectedSeverity,
  setSelectedSeverity,
  clearFilters,
  searchTitle,
  setSearchTitle,
  handleTitleSearch,
  hasTitle,
  requestDateRange,
  setRequestDateRange,
  updatedDateRange,
  setUpdatedDateRange
}) => {
  const [isLoadingStatuses, setIsLoadingStatuses] = useState(false);
  const [statuses, setStatuses] = useState<any[]>([]);
  const [isLoadingSeverities, setIsLoadingSeverities] = useState(false);
  const [severities, setSeverities] = useState<IClientRelationsLookup[]>([]);
  const [isLoadingFormTypes, setIsLoadingFormTypes] = useState(false);
  const [formTypes, setFormTypes] = useState<IClientRelationsLookup[]>([]);
  const [selectedStatusValues, setSelectedStatusValues] = useState<any[]>([]);
  const classes = useStyles();

  const fetchZendeskStatuses = async () => {
    setIsLoadingStatuses(true);
    try {
      const res = await getClientZendeskStatuses();
      const filteredStatuses = res.filter((status: any) => {
        return status.shorthand !== 'Open' || status.value === 1943011;
      });
      const filteredSelectedStatuses = res.filter(
        (status: any) => !(status.shorthand === 'Solved' || (status.shorthand === 'Open' && status.value !== 1943011))
      );
      setStatuses(filteredStatuses);
      setSelectedStatusValues(filteredSelectedStatuses);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoadingStatuses(false);
    }
  };

  const fetchZendeskSeverities = async () => {
    setIsLoadingSeverities(true);
    try {
      const res = await getClientZendeskSeverities();
      setSeverities(res);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoadingSeverities(false);
    }
  };

  const fetchZendeskFormtypes = async () => {
    setIsLoadingFormTypes(true);
    try {
      const res = await getClientZendeskFormTypes();
      setFormTypes(res);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoadingFormTypes(false);
    }
  };

  useEffect(() => {
    if (clearFilters) {
      setHasAppliedFilters(false);
      setSelectedSeverity('');
      setSelectedStatusIds('');
      setSelectedFormTypeId('');
      applyFilters(true);
    }
    fetchZendeskStatuses();
    fetchZendeskSeverities();
    fetchZendeskFormtypes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleStatusFilterChange = (e: any, s: any) => {
    const {
      target: { value }
    } = e;

    let values = value.slice(0, -1);
    let selected = value.pop();
    let checked = s.props.children[0].props.checked;
    const newValues = () => {
      if (checked) {
        return values?.filter((item: any) => item?.value !== selected);
      } else {
        return [...values, statuses.find((status: any) => status.value === selected)];
      }
    };

    setSelectedStatusValues(newValues());
    const valuesArray = newValues().map((item: any) => item.value);
    setSelectedStatusIds(valuesArray);
  };

  return (
    <Grid container alignItems='center' className={classes.searchWrapper}>
      {hasTitle && <Typography className={classes.modalTitle}>Filter Requests</Typography>}
      <TextField
        className={classes.filters}
        size='small'
        fullWidth
        variant='outlined'
        placeholder='Search Title...'
        name='nameSearch'
        value={searchTitle}
        disabled={isLoading}
        InputProps={{
          startAdornment: (
            <InputAdornment
              position='start'
              className={classes.searchIcon}
              onClick={() => {
                if (searchTitle && searchTitle.length > 0) {
                  setHasAppliedFilters(true);
                  handleTitleSearch();
                }
              }}
            >
              <Search />
            </InputAdornment>
          ),
          endAdornment: searchTitle ? (
            <InputAdornment
              position='end'
              className={classes.searchIcon}
              onClick={() => {
                setSearchTitle('');
                handleTitleSearch(true);
              }}
            >
              <Close />
            </InputAdornment>
          ) : null
        }}
        onKeyDown={e => {
          if (e.key === 'Enter' && searchTitle && searchTitle.length > 0) {
            setHasAppliedFilters(true);
            handleTitleSearch();
          }
        }}
        onChange={e => {
          setSearchTitle(e.target.value);
        }}
      />
      <FormControl size='small' fullWidth className={classes.filters}>
        <Select
          labelId={'status-filter-label'}
          id={'status-filter'}
          multiple
          displayEmpty
          fullWidth
          value={selectedStatusValues}
          disabled={isLoadingStatuses || isLoading}
          input={<OutlinedInput disabled={isLoadingStatuses} />}
          MenuProps={{
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left'
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left'
            },
            getContentAnchorEl: null,
            PaperProps: {
              style: {
                maxHeight: 300
              }
            }
          }}
          endAdornment={
            selectedStatusValues.length > 0 ? (
              <InputAdornment position='end' style={{ position: 'absolute', right: 26 }}>
                <IconButton
                  onClick={() => {
                    setSelectedStatusValues([]);
                    setSelectedStatusIds([]);
                  }}
                  style={{ padding: '4px' }}
                  aria-label='Clear'
                >
                  <Close fontSize='small' />
                </IconButton>
              </InputAdornment>
            ) : null
          }
          onChange={handleStatusFilterChange}
          renderValue={(selected: any) => {
            if (selected.length === 0) {
              return <span>Status</span>;
            }
            return [
              selectedStatusValues.map((status: any) => status.shorthand)[0],
              selectedStatusValues.length > 1 ? ` (+${selected.length - 1})` : ''
            ];
          }}
          variant='outlined'
          placeholder='Status'
        >
          {statuses.map((option: any) => (
            <MenuItem key={option?.key} value={option?.value}>
              <Checkbox checked={selectedStatusValues.indexOf(option) > -1} style={{ paddingTop: 0, paddingBottom: 0 }} />
              <ListItemText primary={option.shorthand} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Autocomplete
        id='severity-autocomplete'
        className={classes.severityFilter}
        size='small'
        options={severities}
        getOptionLabel={option => option?.text}
        value={selectedSeverity}
        disabled={isLoadingSeverities}
        onChange={(e: any, value: any) => {
          setSelectedSeverity(value);
        }}
        renderInput={params => <TextField {...params} label='Severity' variant='outlined' size='small' />}
      />
      <Autocomplete
        id='formType-autocomplete'
        className={classes.serviceFilter}
        options={formTypes}
        getOptionLabel={option => option?.description}
        value={selectedFormTypeId}
        onChange={(e: any, value: any) => setSelectedFormTypeId(value)}
        disabled={isLoadingFormTypes}
        renderInput={params => <TextField {...params} label='Service Type' variant='outlined' size='small' />}
      />
      <DateRangePicker
        className={classes.dateRangeFilters}
        inputVariant='outlined'
        size='small'
        autoOk
        label='Request Date'
        value={requestDateRange}
        onChange={values => setRequestDateRange(values)}
        disabled={isLoading}
        format='M/d/yyyy'
        handleClear={() => {
          setRequestDateRange(undefined);
        }}
      />
      <DateRangePicker
        className={classes.dateRangeFilters}
        inputVariant='outlined'
        size='small'
        autoOk
        label='Updated Date'
        value={updatedDateRange}
        onChange={values => setUpdatedDateRange(values)}
        disabled={isLoading}
        format='M/d/yyyy'
        handleClear={() => {
          setUpdatedDateRange(undefined);
        }}
      />

      <div className={classes.buttonContainer}>
        <RequestsFilterButtons
          hasAppliedFilters={hasAppliedFilters}
          isDisabled={isLoading}
          handleApplyFilters={() => {
            setHasAppliedFilters(true);
            applyFilters();
          }}
          handleResetFilters={() => {
            setHasAppliedFilters(false);
            setSelectedStatusIds([1943011, 1943031, 1943071, 1943051, 18099093098388, 18886582837268, 19360829437716]);
            setSelectedSeverity('');
            setSearchTitle('');
            setSelectedFormTypeId('');
            setRequestDateRange(undefined);
            setUpdatedDateRange(undefined);
            fetchZendeskStatuses();
            applyFilters(true);
          }}
        />
      </div>
    </Grid>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  searchIcon: {
    cursor: 'pointer',
    color: theme.palette.grey[500]
  },
  mobileTable: {
    padding: 0
  },
  desktopTable: {
    paddingLeft: 5,
    paddingRight: 5
  },
  actionButton: {
    padding: 0
  },
  clientsFormControl: {
    margin: '0 2rem 1rem 0',
    minWidth: 300
  },
  iconButton: {
    padding: 0
  },
  searchWrapper: {
    minHeight: theme.spacing(2)
  },
  filters: {
    width: '100%',
    marginBottom: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      width: 225,
      margin: theme.spacing(0, 0.75, 1, 0)
    },
    '&& .MuiInputBase-root': {
      width: '100%',
      paddingLeft: theme.spacing(0.5)
    },
    '@media (max-width: 1100px)': {
      width: '100%'
    }
  },
  serviceFilter: {
    width: '100%',
    marginBottom: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      width: 205,
      margin: theme.spacing(0, 0.75, 1, 0)
    },
    '&& .MuiInputBase-root': {
      width: '100%',
      paddingLeft: theme.spacing(0.5)
    },
    '@media (max-width: 1100px)': {
      width: '100%'
    }
  },
  severityFilter: {
    width: '100%',
    marginBottom: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      width: 175,
      margin: theme.spacing(0, 0.75, 1, 0)
    },
    '&& .MuiInputBase-root': {
      width: '100%',
      paddingLeft: theme.spacing(0.5)
    },
    '@media (max-width: 1100px)': {
      width: '100%'
    }
  },
  dateRangeFilters: {
    width: '100%',
    marginBottom: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      width: 230,
      margin: theme.spacing(0, 0.75, 1, 0)
    },
    '&& .MuiInputBase-root': {
      width: '100%',
      paddingLeft: theme.spacing(0.5)
    },
    '@media (max-width: 1100px)': {
      width: '100%'
    }
  },
  inputRoot: {
    maxHeight: 38,
    '@media (max-width: 1100px)': {
      maxHeight: 'none'
    },
    '& .MuiAutocomplete-input': {
      minWidth: 0
    }
  },
  chip: {
    height: 'auto',
    fontSize: 'small',
    marginRight: theme.spacing(0.2)
  },
  modalTitle: {
    fontWeight: 600,
    marginBottom: theme.spacing(1)
  },
  buttonContainer: {
    '@media (max-width: 1100px)': {
      width: '400px'
    }
  }
}));
