import React, { FC, useState, useEffect, useMemo } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import format from 'date-fns/format';
import { useMedia } from 'react-use';
import People from '@material-ui/icons/People';
import Person from '@material-ui/icons/Person';
import Reorder from '@material-ui/icons/Reorder';
import FilterList from '@material-ui/icons/FilterList';
// Redux
import { useDispatch, useSelector } from 'react-redux';
import { setClients, setClientProjectStatuses } from '@shared/redux/actions';
// Components
import { Table, ITableColumn } from '@shared/components/tables';
import { Button, Toolbar, ButtonGroup, TextField, Tooltip, Typography } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Save, Close, ArrowDownward } from '@material-ui/icons';
import { Page } from '@shared/components/layout';
import { MobileExpanderLoader } from '@shared/components/loader';
import { MobileLaborRate } from '../components/mobile';
import { LaborRateCell, ActionCell } from '../components/controls';
import { DatePicker } from '@shared/components/inputs';
import { LoaderOverlay } from '@shared/components/loader';
import { Alert } from '@shared/components/alerts';
import { asyncConfirmDialogue } from '@shared/components/modals';
import { LaborRateHistoryModal } from '../components/modals';
// Fetch
import { getLaborRates, updateLaborRates, getClients, getClientProjects, reduxFetch, getClientProjectStatuses } from '@shared/fetch';
// Types
import { ILaborRate, IAppState, IClientProject, IBasicObject, IEnumResponse } from '@shared/types';

export enum LaborRateTypes {
  STANDARD = 'standard',
  CLIENT = 'client',
  CLIENTPROJECT = 'clientProject'
}

const RateHeader = {
  [LaborRateTypes.STANDARD]: 'Effective Rate',
  [LaborRateTypes.CLIENT]: 'Effective Client Rate',
  [LaborRateTypes.CLIENTPROJECT]: 'Effective Project Rate'
};

const EffectiveDateHeader = {
  [LaborRateTypes.STANDARD]: 'Effective Date',
  [LaborRateTypes.CLIENT]: 'Client Effective Date',
  [LaborRateTypes.CLIENTPROJECT]: 'Project Effective Date'
};

const RateKeyLookup = {
  [LaborRateTypes.STANDARD]: 'currentRate',
  [LaborRateTypes.CLIENT]: 'clientRate',
  [LaborRateTypes.CLIENTPROJECT]: 'clientProjectRate'
};

const EffectiveDateKeyLookup = {
  [LaborRateTypes.STANDARD]: 'effectiveDate',
  [LaborRateTypes.CLIENT]: 'clientEffectiveDate',
  [LaborRateTypes.CLIENTPROJECT]: 'clientProjectEffectiveDate'
};

const NewRateKeyLookup = {
  [LaborRateTypes.STANDARD]: 'newStandardRate',
  [LaborRateTypes.CLIENT]: 'newClientRate',
  [LaborRateTypes.CLIENTPROJECT]: 'newClientProjectRate'
};

const NewDateKeyLookup = {
  [LaborRateTypes.STANDARD]: 'newStandardRateDate',
  [LaborRateTypes.CLIENT]: 'newClientRateDate',
  [LaborRateTypes.CLIENTPROJECT]: 'newClientProjectRateDate'
};

const RateIndicatorLevels = {
  one: 5,
  two: 15
};

export const LaborRates: FC = () => {
  const classes = useStyles();

  // breakpoints
  const isDesktop = useMedia('(min-width: 960px)');

  // redux
  const { clients, clientProjectStatuses } = useSelector((state: IAppState) => state.clients);
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [laborRates, setLaborRates] = useState<ILaborRate[]>([]);
  const initialLoad = async () => {
    try {
      setIsLoading(true);
      const laborRatesResponse = await getLaborRates();
      setLaborRates(laborRatesResponse);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    initialLoad();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // standard vs clients vs client project
  const [selectedLaborRateType, setSelectedLaborRateType] = useState<LaborRateTypes>(LaborRateTypes.STANDARD);
  const [client, setClient] = useState<{ label: string; value: number } | null>(null);
  const [clientProjects, setClientProjects] = useState<IClientProject[]>([]);
  const [clientProject, setClientProject] = useState<{ label: string; value: number } | null>(null);
  const [isEmployeeRates, setIsEmployeeRates] = useState<boolean>(false);
  // filtering client projects by active (pending/approved) or not (cancelled/completed)
  const [showAllProjects, setShowAllProjects] = useState<boolean>(false);

  // map for client project status for quick lookup
  const [clientProjectStatusMap, setClientProjectStatusMap] = useState<IBasicObject>({});

  // Load clients
  const loadClients = async () => {
    if (selectedLaborRateType === LaborRateTypes.CLIENT) {
      try {
        setIsLoading(true);
        const clientsResponse = await getClients();
        dispatch(setClients(clientsResponse));
        if (Array.isArray(clientsResponse) && clientsResponse.length > 0) {
          setClient({ label: clientsResponse[0].name, value: clientsResponse[0].clientId });
        }

        // don't toggle off load since we have to load client rates based on client being set
      } catch (error) {
        setIsLoading(false);
      }
    }
  };
  useEffect(() => {
    if (!client) {
      loadClients();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLaborRateType]);

  // Client Rates
  const loadClientRatesAndProjects = async (getEmployeeRates?: boolean) => {
    try {
      setIsLoading(true);
      const [laborRatesResponse, clientProjectsResponse, clientProjectStatusesResponse] = await Promise.all([
        getLaborRates(client!.value, undefined, getEmployeeRates),
        getClientProjects({ ClientId: client!.value }),
        reduxFetch(getClientProjectStatuses, clientProjectStatuses)
      ]);

      setLaborRates(laborRatesResponse);
      setClientProjects(clientProjectsResponse);
      dispatch(setClientProjectStatuses(clientProjectStatusesResponse));

      const projectStatusMap = {};
      clientProjectStatusesResponse.forEach((status: IEnumResponse) => {
        projectStatusMap[status.value] = status.text;
      });
      setClientProjectStatusMap(projectStatusMap);

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    if (client && client.value) {
      loadClientRatesAndProjects(isEmployeeRates);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client]);

  // Client Project Rates
  const loadClientProjectRates = async (getEmployeeRates?: boolean) => {
    try {
      setIsLoading(true);
      const laborRatesResponse = await getLaborRates(client!.value, clientProject!.value, getEmployeeRates);
      setLaborRates(laborRatesResponse);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    if (clientProject && clientProject.value) {
      loadClientProjectRates(isEmployeeRates);
    } else if (client && client.value) {
      loadClientRatesAndProjects(isEmployeeRates);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientProject]);

  // history modal
  const [historyModalIndex, setHistoryModalIndex] = useState<number>(-1);
  const handleHistoryModal = (index: number) => {
    setHistoryModalIndex(index);
  };

  // Used for rendering rate cells, we want to show an indicator for clientRate
  // and clientProjectRate if they are a certain percent below the standard rate
  // - <span className={classes.rateIndicatorSpacing}></span> -
  // Is used to align the rate text vertically for those cells that don't
  // show the indicator, giving a better UI
  const getRateCell = (rate: number, standardRate: number) => {
    if (!rate) {
      return (
        <div className={classes.rateIndicatorWrapper}>
          N/A<span className={classes.rateIndicatorSpacing}></span>
        </div>
      );
    } else {
      let str = `$${rate} / hr`;
      if (rate >= standardRate) {
        return (
          <div className={classes.rateIndicatorWrapper}>
            {str}
            <span className={classes.rateIndicatorSpacing}></span>
          </div>
        );
      } else {
        let percentageBelow = 100 - (rate / standardRate) * 100;
        let className = '';
        let showIndicator = true;
        if (percentageBelow < RateIndicatorLevels.one) {
          showIndicator = false;
        } else if (percentageBelow < RateIndicatorLevels.two) {
          className = classes.rateIndicatorLevelTwo;
        } else {
          className = classes.rateIndicatorLevelThree;
        }
        return (
          <div className={classes.rateIndicatorWrapper}>
            {str}
            <Tooltip title={`Effective rate is ${percentageBelow.toFixed(1)}% below standard`}>
              {showIndicator ? <ArrowDownward className={className} /> : <span className={classes.rateIndicatorSpacing}></span>}
            </Tooltip>
          </div>
        );
      }
    }
  };

  const columns = useMemo(() => {
    if (isEmployeeRates) {
      return [
        { Header: 'Employee', id: 'employeeName', accessor: (d: ILaborRate) => `${d.employeeLastName}, ${d.employeeFirstName}` },
        {
          Header: EffectiveDateHeader[selectedLaborRateType],
          id: 'effectiveDate',
          isDate: true,
          overrideWidth: 190,
          accessor: (d: ILaborRate) =>
            d[EffectiveDateKeyLookup[selectedLaborRateType]] !== null
              ? format(new Date(d[EffectiveDateKeyLookup[selectedLaborRateType]]), 'MM/dd/YYYY', {
                  useAdditionalWeekYearTokens: true
                })
              : 'Earliest'
        },
        ...(selectedLaborRateType === LaborRateTypes.STANDARD
          ? []
          : [
              {
                Header: 'Effective Standard Rate',
                overrideWidth: 250,
                id: 'standardRate',
                isNumber: true,
                accessor: (d: ILaborRate) => `${d.currentRate ? `$${d.currentRate}/ hr` : 'N/A'}`
              }
            ]),
        ...(selectedLaborRateType === LaborRateTypes.CLIENTPROJECT
          ? [
              {
                Header: 'Effective Client Rate',
                id: 'clientRate',
                isNumber: true,
                overrideWidth: 220,
                accessor: (d: ILaborRate) => `${d.clientRate ? `$${d.clientRate} / hr` : 'N/A'}`,
                Cell: ({
                  cell: {
                    row: { original }
                  }
                }: any) => getRateCell(original.clientRate, original.currentRate)
              }
            ]
          : []),
        {
          Header: RateHeader[selectedLaborRateType],
          id: 'currentRate',
          isNumber: true,
          overrideWidth: 220,
          accessor: (d: ILaborRate) => `${d[RateKeyLookup[selectedLaborRateType]] ? `$${d[RateKeyLookup[selectedLaborRateType]]} / hr` : 'N/A'}`,
          Cell: ({
            cell: {
              row: { original }
            }
          }: any) => {
            if (selectedLaborRateType === LaborRateTypes.STANDARD) {
              return `${original[RateKeyLookup[selectedLaborRateType]] ? `$${original[RateKeyLookup[selectedLaborRateType]]} / hr` : 'N/A'}`;
            } else {
              return getRateCell(original[RateKeyLookup[selectedLaborRateType]], original.currentRate);
            }
          }
        },
        ...(isDesktop
          ? [
              {
                Header: 'New Rates',
                isRate: true,
                accessor: NewRateKeyLookup[selectedLaborRateType],
                Cell: LaborRateCell,
                sort: false,
                className: classes.smallCell,
                overrideWidth: 145
              }
            ]
          : []),
        {
          Header: '',
          id: 'actions',
          Cell: ActionCell,
          sort: false,
          className: classes.smallCell,
          overrideWidth: 130
        }
      ];
    }
    return [
      { Header: 'Role', accessor: 'role' },
      {
        Header: EffectiveDateHeader[selectedLaborRateType],
        id: 'effectiveDate',
        isDate: true,
        overrideWidth: 190,
        accessor: (d: ILaborRate) =>
          d[EffectiveDateKeyLookup[selectedLaborRateType]] !== null
            ? format(new Date(d[EffectiveDateKeyLookup[selectedLaborRateType]]), 'MM/dd/YYYY', {
                useAdditionalWeekYearTokens: true
              })
            : 'Earliest'
      },
      ...(selectedLaborRateType === LaborRateTypes.STANDARD
        ? []
        : [
            {
              Header: 'Effective Standard Rate',
              overrideWidth: 250,
              id: 'standardRate',
              isNumber: true,
              accessor: (d: ILaborRate) => `${d.currentRate ? `$${d.currentRate} / hr` : 'N/A'}`
            }
          ]),
      ...(selectedLaborRateType === LaborRateTypes.CLIENTPROJECT
        ? [
            {
              Header: 'Effective Client Rate',
              id: 'clientRate',
              isNumber: true,
              overrideWidth: 220,
              accessor: (d: ILaborRate) => `${d.clientRate ? `$${d.clientRate} / hr` : 'N/A'}`,
              Cell: ({
                cell: {
                  row: { original }
                }
              }: any) => getRateCell(original.clientRate, original.currentRate)
            }
          ]
        : []),
      {
        Header: RateHeader[selectedLaborRateType],
        id: 'currentRate',
        isNumber: true,
        overrideWidth: 220,
        accessor: (d: ILaborRate) => `${d[RateKeyLookup[selectedLaborRateType]] ? `$${d[RateKeyLookup[selectedLaborRateType]]} / hr` : 'N/A'}`,
        Cell: ({
          cell: {
            row: { original }
          }
        }: any) => {
          if (selectedLaborRateType === LaborRateTypes.STANDARD) {
            return `${original[RateKeyLookup[selectedLaborRateType]] ? `$${original[RateKeyLookup[selectedLaborRateType]]} / hr` : 'N/A'}`;
          } else {
            return getRateCell(original[RateKeyLookup[selectedLaborRateType]], original.currentRate);
          }
        }
      },
      ...(isDesktop
        ? [
            {
              Header: 'New Rates',
              isRate: true,
              accessor: NewRateKeyLookup[selectedLaborRateType],
              Cell: LaborRateCell,
              sort: false,
              className: classes.smallCell,
              overrideWidth: 130
            }
          ]
        : []),
      {
        Header: '',
        id: 'actions',
        Cell: ActionCell,
        sort: false,
        className: classes.smallCell,
        overrideWidth: 130
      }
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [laborRates, selectedLaborRateType, client, clientProject, isDesktop]);

  // edit functionality
  const [newEffectiveDate, setNewEffectiveDate] = useState<string | undefined>();
  useEffect(() => {
    const newLaborRates = [...laborRates].map(rate =>
      rate.isEdited ? { ...rate, [NewDateKeyLookup[selectedLaborRateType]]: newEffectiveDate || '' } : rate
    );
    setLaborRates(newLaborRates);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newEffectiveDate]);

  const updateMyData = (rowIndex: number, value: any) => {
    if (value !== '') {
      const newLaborRates = [...laborRates];
      newLaborRates[rowIndex] = {
        ...laborRates[rowIndex],
        isEdited: true,
        [NewRateKeyLookup[selectedLaborRateType]]: value,
        [NewDateKeyLookup[selectedLaborRateType]]: newEffectiveDate || ''
      };
      setLaborRates(newLaborRates);
    }
  };

  const handleRateToggle = (e: React.MouseEvent<HTMLButtonElement>, value: string) => {
    if (value === 'role') {
      setIsEmployeeRates(false);
    } else {
      setIsEmployeeRates(true);
    }

    const getEmployeeRates = value === 'employee';
    if (clientProject && clientProject.value) {
      loadClientProjectRates(getEmployeeRates);
    } else if (client && client.value) {
      loadClientRatesAndProjects(getEmployeeRates);
    }
  };

  const useTableProps = {
    updateMyData,
    handleHistoryModal
  };

  const setToStandardRates = () => {
    setIsEmployeeRates(false);
    setSelectedLaborRateType(LaborRateTypes.STANDARD);
    initialLoad();
  };

  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [errorOpen, setErrorOpen] = React.useState(false);
  const [errorText, setErrorText] = React.useState('Problem saving please try again!');
  const [successOpen, setSuccessOpen] = React.useState(false);
  const handleSave = async () => {
    try {
      // Confirm
      const isConfirmed = await asyncConfirmDialogue({
        title: 'Confirm Rates',
        text: `Create new rates effective ${format(new Date(newEffectiveDate as string), 'M/d/yyyy', { useAdditionalWeekYearTokens: true })}?`
      });
      if (!isConfirmed) {
        return;
      }

      setIsSaving(true);
      const laborRatesSaveResponse = await updateLaborRates(laborRates);
      setLaborRates(laborRatesSaveResponse);
      setNewEffectiveDate(undefined);
      setIsSaving(false);
      setSuccessOpen(true);
    } catch (error) {
      console.error(error);
      setIsSaving(false);
      setErrorText(error?.response?.data?.Detail);
      setErrorOpen(true);
    }
  };
  const handleCancel = () => {
    const newLaborRates = [...laborRates].map(rate =>
      rate.isEdited
        ? { ...rate, [NewRateKeyLookup[selectedLaborRateType]]: null, isEdited: false, [NewDateKeyLookup[selectedLaborRateType]]: '' }
        : rate
    );
    setLaborRates(newLaborRates);
  };

  // handle filtering client projects based on active (approved/pending) or not (completed/cancelled)
  const filterClientProjects = (clientProject: IClientProject) => {
    if (showAllProjects || Object.keys(clientProjectStatusMap).length === 0) {
      return true;
    }

    return (
      clientProjectStatusMap[clientProject.projectStatus].toLowerCase() === 'approved' ||
      clientProjectStatusMap[clientProject.projectStatus].toLowerCase() === 'pending'
    );
  };

  return (
    <Page
      setHeight={false}
      flexGrow={false}
      footerSpacing={135}
      title='Labor Rates'
      actions={() =>
        isDesktop ? (
          <div className={classes.buttonWrapper}>
            <DatePicker
              id='date-picker'
              label='Date'
              name='date'
              inputVariant='outlined'
              size='small'
              value={newEffectiveDate ? new Date(newEffectiveDate) : null}
              onChange={date => setNewEffectiveDate(date?.toISOString())}
              disableMargin
            />
            <Button
              size='small'
              color='secondary'
              disabled={!laborRates?.some(rate => rate.isEdited) || !newEffectiveDate}
              startIcon={<Save />}
              onClick={handleSave}
            >
              Save
            </Button>
            <Button size='small' color='inherit' disabled={!laborRates?.some(rate => rate.isEdited)} startIcon={<Close />} onClick={handleCancel}>
              Cancel
            </Button>
          </div>
        ) : undefined
      }
    >
      <Toolbar disableGutters className={classes.buttonToolbar}>
        <ButtonGroup size='large' color='primary' className={classes.buttonGroup} aria-label='rate-select-button-group'>
          <Button onClick={setToStandardRates} className={selectedLaborRateType === LaborRateTypes.STANDARD ? classes.activeButton : ''}>
            Standard
          </Button>
          <Button
            onClick={() => setSelectedLaborRateType(LaborRateTypes.CLIENT)}
            className={
              selectedLaborRateType === LaborRateTypes.CLIENT || selectedLaborRateType === LaborRateTypes.CLIENTPROJECT ? classes.activeButton : ''
            }
          >
            Client
          </Button>
        </ButtonGroup>
        {(selectedLaborRateType === LaborRateTypes.CLIENT || selectedLaborRateType === LaborRateTypes.CLIENTPROJECT) && (
          <>
            <Autocomplete
              id='clients-autocomplete'
              className={classes.clientsFormControl}
              options={clients.filter(x => x.isActive).map(x => ({ label: x.name, value: x.clientId }))}
              getOptionLabel={option => option && option.label}
              value={client}
              onChange={(e: any, value: any) => {
                setClientProject(null);
                setClient(value);
                setSelectedLaborRateType(LaborRateTypes.CLIENT);
              }}
              disabled={isLoading}
              disableClearable
              renderInput={params => <TextField {...params} label='Client' variant='outlined' size='small' />}
            />
            <Autocomplete
              id='client-projects-autocomplete'
              className={classes.clientsFormControl}
              options={clientProjects
                .filter(filterClientProjects)
                .map(clientProject => ({ label: clientProject.name, value: clientProject.clientProjectId }))}
              getOptionLabel={option => option && option.label}
              value={clientProject}
              onChange={(e: any, value: any) => {
                setClientProject(value);
                setSelectedLaborRateType(value === null ? LaborRateTypes.CLIENT : LaborRateTypes.CLIENTPROJECT);
              }}
              disabled={isLoading}
              renderInput={params => <TextField {...params} label='Project' variant='outlined' size='small' />}
            />
            <div className={classes.toggleWrapper}>
              <Typography display='inline' className={classes.viewText}>
                Projects:
              </Typography>
              <Button
                disableRipple
                size='small'
                color={!showAllProjects ? 'secondary' : 'primary'}
                startIcon={<FilterList />}
                className={!showAllProjects ? classes.viewButtonActive : undefined}
                onClick={() => setShowAllProjects(false)}
              >
                Open
              </Button>
              <Typography display='inline' className={classes.pipe}>
                |
              </Typography>
              <Button
                disableRipple
                size='small'
                color={showAllProjects ? 'secondary' : 'primary'}
                startIcon={<Reorder />}
                onClick={() => setShowAllProjects(true)}
                className={showAllProjects ? classes.viewButtonActive : undefined}
              >
                All
              </Button>
            </div>
            <div className={classes.toggleWrapperRight}>
              <Typography display='inline' className={classes.viewText}>
                View By:
              </Typography>
              <Button
                disableRipple
                size='small'
                color={!isEmployeeRates ? 'secondary' : 'primary'}
                startIcon={<People />}
                className={!isEmployeeRates ? classes.viewButtonActive : undefined}
                onClick={e => handleRateToggle(e, 'role')}
              >
                Role
              </Button>
              <Typography display='inline' className={classes.pipe}>
                |
              </Typography>
              <Button
                disableRipple
                size='small'
                color={isEmployeeRates ? 'secondary' : 'primary'}
                startIcon={<Person />}
                onClick={e => handleRateToggle(e, 'employee')}
                className={isEmployeeRates ? classes.viewButtonActive : undefined}
              >
                Employee
              </Button>
            </div>
          </>
        )}
      </Toolbar>
      <Table
        key='labor-rates-table'
        data={laborRates}
        columns={columns as ITableColumn[]}
        expandToFit
        stickyHeader
        isLoading={isLoading}
        ResponsiveComponent={MobileLaborRate}
        ResponsiveComponentLoader={MobileExpanderLoader}
        useTableProps={useTableProps}
      />
      <LoaderOverlay open={isSaving} />
      <Alert open={errorOpen} onClose={setErrorOpen} type='error' text={errorText} />
      <Alert open={successOpen} onClose={setSuccessOpen} type='success' text='Save Success!' />
      <LaborRateHistoryModal
        onClose={(refresh?: boolean) => {
          if (refresh) {
            if (selectedLaborRateType === LaborRateTypes.STANDARD) {
              initialLoad();
            } else if (selectedLaborRateType === LaborRateTypes.CLIENT) {
              loadClientRatesAndProjects(isEmployeeRates);
            } else if (selectedLaborRateType === LaborRateTypes.CLIENTPROJECT) {
              loadClientProjectRates(isEmployeeRates);
            }
          }
          setHistoryModalIndex(-1);
        }}
        open={historyModalIndex > -1}
        laborRate={laborRates[historyModalIndex]}
        currentLaborRateType={selectedLaborRateType}
      />
    </Page>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  smallCell: {
    padding: '0px 17px'
  },
  buttonWrapper: {
    marginLeft: 'auto',
    display: 'flex',
    alignItems: 'center',
    '& button:first-of-type': {
      marginLeft: theme.spacing(1)
    }
  },
  activeButton: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark
    }
  },
  buttonGroup: {
    marginRight: theme.spacing(1),
    [theme.breakpoints.down('md')]: {
      marginTop: theme.spacing(1)
    }
  },
  clientsFormControl: {
    marginRight: theme.spacing(1),
    minWidth: 300,
    [theme.breakpoints.down('md')]: {
      minWidth: 285,
      marginTop: theme.spacing(1)
    }
  },
  buttonToolbar: {
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      flexWrap: 'wrap',
      minHeight: 'unset'
    }
  },
  switchLabel: {
    color: 'black'
  },
  switchLabelLarge: {
    width: '68px'
  },
  rateIndicatorWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'flex-start'
    }
  },
  rateIndicatorSpacing: {
    width: theme.spacing(1.25),
    height: theme.spacing(1.25)
  },
  rateIndicatorLevelTwo: {
    color: 'orange',
    width: theme.spacing(1.25),
    height: theme.spacing(1.25)
  },
  rateIndicatorLevelThree: {
    color: 'red',
    width: theme.spacing(1.25),
    height: theme.spacing(1.25)
  },
  viewByWrapper: {
    display: 'flex',
    alignItems: 'center'
  },
  viewBy: {
    '@media (min-width: 960px)': {
      alignSelf: 'flex-start'
    },
    '@media (min-width: 1171px)': {
      alignSelf: 'center'
    },
    marginTop: theme.spacing(0.5),
    flexWrap: 'nowrap',
    [theme.breakpoints.up('md')]: {
      marginTop: 0,
      alignItems: 'center'
    }
  },
  viewText: {
    marginRight: theme.spacing(0.5),
    whiteSpace: 'nowrap'
  },
  viewButtonActive: {
    borderBottom: '1px solid',
    borderRadius: 0,
    fontWeight: 700
  },
  pipe: {
    margin: '0 12px 0 4px',
    color: theme.palette.grey[300],
    [theme.breakpoints.up('md')]: {
      margin: '0 8px 0 4px'
    }
  },
  toggleWrapper: {
    display: 'flex',
    alignItems: 'center'
  },
  toggleWrapperRight: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: theme.spacing(1.5)
  }
}));
