import React, { FC } from 'react';
import { makeStyles, Theme, Typography } from '@material-ui/core';
import { TypographyProps } from '@material-ui/core/Typography';
import clsx from 'clsx';

interface IH2Props extends TypographyProps {}

export const H2: FC<IH2Props> = ({ children, ...props }) => {
  const classes = h2Styles();
  return (
    <Typography {...props} className={clsx(classes.h2, props.className)} variant='h2'>
      {children}
    </Typography>
  );
};

const h2Styles = makeStyles((theme: Theme) => ({
  h2: {
    fontSize: 28,
    fontWeight: 700,
    margin: theme.spacing(6, 0, 3)
  }
}));
