import React, { FC } from 'react';
import { useMedia } from 'react-use';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import { makeStyles, useTheme } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    flexShrink: 0,
    [theme.breakpoints.up('md')]: {
      marginLeft: theme.spacing(2.5)
    }
  },
  button: {
    [theme.breakpoints.down('md')]: {
      padding: 0
    }
  }
}));

interface IPaginationActionsProps {
  count: number;
  page: number;
  rowsPerPage: number;
  onChangePage: (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, page: number) => void;
}

const TablePaginationActions: FC<IPaginationActionsProps> = ({ count, page, rowsPerPage, onChangePage }) => {
  const classes = useStyles();
  const theme = useTheme();
  const isDesktop = useMedia('(min-width: 960px)');

  const handleFirstPageButtonClick = () => {
    onChangePage(null, 0);
  };

  const handleBackButtonClick = () => {
    onChangePage(null, page - 1);
  };

  const handleNextButtonClick = () => {
    onChangePage(null, page + 1);
  };

  const handleLastPageButtonClick = () => {
    onChangePage(null, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      {isDesktop && (
        <IconButton onClick={handleFirstPageButtonClick} disabled={page === 0} aria-label='first page'>
          {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
      )}
      <IconButton className={classes.button} onClick={handleBackButtonClick} disabled={page === 0} aria-label='previous page'>
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        className={classes.button}
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label='next page'
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      {isDesktop && (
        <IconButton onClick={handleLastPageButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label='last page'>
          {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      )}
    </div>
  );
};

export default TablePaginationActions;
