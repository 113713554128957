import React, { FC } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { withFormik, FormikProps } from 'formik';
import * as Yup from 'yup';
import { useSelector } from 'react-redux';
// Components
import Modal from '@material-ui/core/Modal';
import Fade from '@material-ui/core/Fade';
import Backdrop from '@material-ui/core/Backdrop';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { DatePicker } from '@shared/components/inputs';
// Types
import { IAdminTimeEntryItem, IAppState } from '@shared/types';
// Helpers
import { getValueForSelect } from '@shared/helpers';
import { CLIENT_PROJECT_STATUSES } from '@shared/constants';

interface IAddTimeEntryProps {
  open: boolean;
  onClose: (event?: {}, reason?: 'backdropClick' | 'escapeKeyDown') => void;
  onSave: (timeEntry: IAdminTimeEntryItem, callback: (error?: Error) => void) => void;
  currentFilter: Record<string, any>;
}

const AddTimeEntry: FC<IAddTimeEntryProps & FormikProps<IAdminTimeEntryItem>> = ({
  open,
  onClose,
  setFieldValue,
  handleBlur,
  handleChange,
  resetForm,
  errors,
  touched,
  values,
  submitForm,
  setFieldTouched
}) => {
  const classes = useStyles();

  // redux
  const { billingTypes, employees } = useSelector((state: IAppState) => state.admin);
  const { clients, clientProjectsList } = useSelector((state: IAppState) => state.clients);

  return (
    <Modal
      className={classes.modal}
      open={open}
      onClose={() => {
        resetForm();
        onClose();
      }}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500
      }}
    >
      <Fade in={open}>
        <Card className={classes.root}>
          <CardHeader title='Add New Time Entry' />
          <CardContent>
            {/* CLIENT DROPDOWN */}
            <FormControl fullWidth className={classes.formField}>
              <Autocomplete
                id='client-autocomplete'
                options={clients.filter(c => c.isActive).map(x => ({ label: x.name, value: x.clientId }))}
                getOptionLabel={option => option.label}
                value={getValueForSelect(values.clientId, 'clientId', clients)}
                onChange={(e: any, value: any) => {
                  setFieldValue('clientId', value ? value.value : null);
                  setFieldValue('clientProjectId', null);
                }}
                renderInput={params => (
                  <TextField
                    {...params}
                    label='Client'
                    variant='outlined'
                    size='small'
                    InputLabelProps={{ id: 'client-autocomplete-label', htmlFor: 'client-autocomplete' }}
                    error={(errors.clientId && touched.clientId) as boolean}
                    helperText={touched.clientId && errors.clientId}
                  />
                )}
              />
            </FormControl>

            {/* CLIENT PROJECT DROPDOWN */}
            <FormControl fullWidth className={classes.formField}>
              <Autocomplete
                id='client-project-autocomplete'
                options={clientProjectsList
                  .filter(x => values.clientId === x.clientId && x.projectStatus === CLIENT_PROJECT_STATUSES.APPROVED)
                  .map(x => ({ label: x.projectName, value: x.clientProjectId }))}
                getOptionLabel={option => option.label}
                value={getValueForSelect(values.clientProjectId, 'clientProjectId', clientProjectsList, clientItem => clientItem.projectName)}
                onChange={(e: any, value: any) => setFieldValue('clientProjectId', value ? value.value : null)}
                disabled={!values.clientId}
                renderInput={params => (
                  <TextField
                    {...params}
                    variant='outlined'
                    label='Project'
                    size='small'
                    InputLabelProps={{ id: 'client-project-autocomplete-label', htmlFor: 'client-project-autocomplete' }}
                    error={(errors.clientProjectId && touched.clientProjectId) as boolean}
                    helperText={touched.clientProjectId && errors.clientProjectId}
                  />
                )}
              />
            </FormControl>

            {/* EMPLOYEE DROPDOWN */}
            <FormControl fullWidth>
              <Autocomplete
                id='employee-autocomplete'
                options={employees.filter(x => x.isActive).map(x => ({ label: `${x.firstName} ${x.lastName}`, value: x.employeeId }))}
                getOptionLabel={option => option.label}
                value={getValueForSelect(values.employeeId, 'employeeId', employees, (original: any) => `${original.firstName} ${original.lastName}`)}
                onChange={(e: any, value: any) => setFieldValue('employeeId', value ? value.value : null)}
                renderInput={params => (
                  <TextField
                    {...params}
                    label='Employee'
                    variant='outlined'
                    size='small'
                    InputLabelProps={{ id: 'employee-autocomplete-label', htmlFor: 'cemployee-autocomplete' }}
                    error={(errors.employeeId && touched.employeeId) as boolean}
                    helperText={touched.employeeId && errors.employeeId}
                  />
                )}
              />
            </FormControl>

            {/* DATE */}
            <FormControl fullWidth className={classes.dateField}>
              <DatePicker
                id='date-picker'
                label='Date'
                name='date'
                value={values.date ? new Date(values.date) : null}
                onChange={date => setFieldValue('date', date?.toISOString())}
                error={(errors.date && touched.date) as boolean}
                helperText={touched.date && errors.date}
                onBlur={() => setFieldTouched('date', true)}
                size='small'
                inputVariant='outlined'
              />
            </FormControl>

            {/* DESCRIPTION */}
            <FormControl fullWidth className={classes.formField}>
              <TextField
                variant='outlined'
                label='Description'
                id='description-text-field'
                defaultValue={values.description}
                name='description'
                size='small'
                error={(errors.description && touched.description) as boolean}
                helperText={touched.description && errors.description}
                onBlur={handleBlur}
                onChange={handleChange}
              />
            </FormControl>

            {/* HOURS WORKED */}
            <FormControl fullWidth className={classes.formField}>
              <TextField
                variant='outlined'
                label='Hours'
                type='number'
                id='hours-text-field'
                defaultValue={values.hours}
                name='hours'
                size='small'
                error={(errors.hours && touched.hours) as boolean}
                helperText={touched.hours && errors.hours}
                onBlur={handleBlur}
                onChange={handleChange}
              />
            </FormControl>

            {/* BILLING TYPE */}
            <FormControl fullWidth className={classes.formField}>
              <Autocomplete
                id='billing-type-autocomplete'
                options={billingTypes.map(x => ({ label: x.description, value: x.text }))}
                getOptionLabel={option => option.label}
                value={getValueForSelect(values.billingTypeId, 'text', billingTypes, billingObj => billingObj.description)}
                onChange={(e: any, value: any) => setFieldValue('billingTypeId', value ? value.value : null)}
                renderInput={params => (
                  <TextField
                    {...params}
                    variant='outlined'
                    label='Billing Type'
                    size='small'
                    InputLabelProps={{ id: 'billing-type-autocomplete-label', htmlFor: 'billing-type-autocomplete' }}
                    error={(errors.billingTypeId && touched.billingTypeId) as boolean}
                    helperText={touched.billingTypeId && errors.billingTypeId}
                  />
                )}
              />
            </FormControl>
          </CardContent>

          {/* ACTION BUTTONS */}
          <CardActions>
            <Button variant='contained' color='secondary' onClick={submitForm}>
              Save
            </Button>
            <Button
              type='button'
              variant='contained'
              color='default'
              onClick={() => {
                resetForm();
                onClose();
              }}
            >
              Cancel
            </Button>
          </CardActions>
        </Card>
      </Fade>
    </Modal>
  );
};

const AddTimeEntrySchema = Yup.object().shape({
  clientId: Yup.number().nullable().required('Client is required'),
  clientProjectId: Yup.number().nullable().required('Project is required'),
  employeeId: Yup.number().nullable().required('Employee is required'),
  date: Yup.date().nullable().required('Valid date is required'),
  description: Yup.string().nullable().required('Descrirption is required'),
  hours: Yup.number().moreThan(0, 'Must be greater than 0').nullable().required('Hours is required'),
  billingTypeId: Yup.string().nullable().required('Billing Type is required')
});

export default withFormik<IAddTimeEntryProps, IAdminTimeEntryItem>({
  enableReinitialize: true,
  mapPropsToValues({ currentFilter }) {
    return {
      billingTypeId: '',
      clientId: null,
      clientProjectId: null,
      clientProjectName: '',
      date: '',
      description: '',
      employeeId: null,
      externalId: null,
      hours: 0,
      isDeleted: false,
      isLocked: false,
      isSuggestion: false,
      source: '',
      sourceType: 'Manual',
      tfsid: 0,
      tfsStoryId: null,
      tfsStoryTitle: '',
      timeEntryId: null,
      timeSheetId: 0,
      zendeskTicketNumber: null,
      ...currentFilter
    };
  },
  validationSchema: AddTimeEntrySchema,
  handleSubmit: (values, { resetForm, props: { onSave } }) => {
    onSave(values, error => {
      if (!error) {
        resetForm();
      }
    });
  }
})(AddTimeEntry);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    root: {
      width: 300,
      [theme.breakpoints.up('md')]: {
        width: 450
      }
    },
    formField: {
      marginBottom: theme.spacing(1)
    },
    dateField: {
      marginBottom: theme.spacing(0.5)
    }
  })
);
