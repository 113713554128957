import { handleActions } from 'redux-actions';
// Types
import { IProgressReportState } from '@shared/types';

const initialState: IProgressReportState = {
  currentReport: null
};

export default handleActions(
  {
    SET_CURRENT_REPORT: (state: any, { payload: { progressReport } }) => ({ ...state, currentReport: progressReport })
  },
  initialState
);
