import React, { FC } from 'react';
import { withFormik, FormikProps } from 'formik';
import * as Yup from 'yup';
import { Grid, FormControl, Button, TextField } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { requestNewAccess } from '@shared/fetch';

interface MyFormValues {
  firstName: string;
  lastName: string;
  email: string;
}

interface ILoginFormProps {
  setIsSendingRequest: (isSending: boolean) => void;
  setRequestSuccessful: (isSuccessful: boolean) => void;
  isSendingRequest: boolean; 
  setErrorMessage?: (message: string) => void;
  setErrorOpen: (open: boolean) => void; 
  requestSuccessful: boolean;
}

export const LoginForm: FC<ILoginFormProps & FormikProps<MyFormValues> & { isSendingRequest: boolean }> = ({
  handleBlur,
  handleChange,
  errors,
  touched,
  values,
  submitForm,
  isSubmitting,
  isSendingRequest,
  requestSuccessful
}) => {
  const classes = useStyles();

  return (
    <>
      <Grid item xs={12} md={8}>
        <FormControl fullWidth>
          <TextField
            label='First Name'
            variant='outlined'
            name='firstName'
            value={values.firstName}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.firstName && Boolean(errors.firstName)}
            helperText={touched.firstName && errors.firstName}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} md={8}>
        <FormControl fullWidth>
          <TextField
            label='Last Name'
            variant='outlined'
            name='lastName'
            value={values.lastName}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.lastName && Boolean(errors.lastName)}
            helperText={touched.lastName && errors.lastName}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} md={8}>
        <FormControl fullWidth>
          <TextField
            label='Email'
            variant='outlined'
            name='email'
            value={values.email}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.email && Boolean(errors.email)}
            helperText={touched.email && errors.email}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} md={8}>
        <Button
          color='primary'
          variant='contained'
          className={classes.requestButton}
          disabled={isSubmitting || isSendingRequest || requestSuccessful || !values.firstName || !values.lastName || !values.email}
          onClick={submitForm}
        >
          {isSubmitting ? 'Requesting Access...' : 'Request Access'}
        </Button>
      </Grid>
    </>
  );
};

const LoginFormSchema = Yup.object().shape({
  firstName: Yup.string().required('First Name is required.').max(255, 'Max 255 characters'),
  lastName: Yup.string().required('Last Name is required.').max(255, 'Max 255 characters'),
  email: Yup.string().email('Invalid email address').required('Email is required.')
});

const handleSubmit = async (
  values: MyFormValues,
  { setSubmitting, props: { setIsSendingRequest, setRequestSuccessful, setErrorMessage, setErrorOpen } }: any
): Promise<void> => {
  try {
    setIsSendingRequest(true);
    const userData = {
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      requestPath: ''
    };
    await requestNewAccess(userData);
    setRequestSuccessful(true);
    setIsSendingRequest(false);
    setSubmitting(false);
  } catch (error) {
    setIsSendingRequest(false);
    const errorMessage = (error as any).response?.data?.Detail || 'An unexpected error occurred';
    setErrorMessage(errorMessage);
    setErrorOpen(true); // Ensure the Alert component is opened on error
    setSubmitting(false);
  }
};

export default withFormik<ILoginFormProps, MyFormValues>({
  mapPropsToValues: () => ({
    firstName: '',
    lastName: '',
    email: ''
  }),
  validationSchema: LoginFormSchema,
  handleSubmit: handleSubmit
})(LoginForm);

const useStyles = makeStyles((theme: Theme) => ({
  requestButton: {
    borderRadius: '0.5rem',
    width: '100%',
    marginTop: theme.spacing(1),
    padding: theme.spacing(1),
    fontWeight: 600,
    backgroundColor: theme.palette.primary.main,
    '&:disabled': {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.common.white,
      opacity: 0.5
    }
  }
}));
