import React, { FC, useState } from 'react';
import { Grid, Typography, Button, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import WaitingGraphic from '@shared/assets/images/waiting-graphic.svg';
import { useHistory, useParams } from 'react-router-dom';
import { beginSoftwareRelease } from '@shared/fetch';
import { ReleaseStatuses } from '@shared/types';
import { formatDateTime } from '@shared/helpers';
import { Loader } from '@shared/components/loader';
import { Toast } from '@shared/components/toast/Toast';

interface IWaitingForRelease {
  releaseDate: string | null;
  timezone: string;
  releaseStatusName: string;
}

export const WaitingRelease: FC<IWaitingForRelease> = ({ releaseDate, timezone, releaseStatusName }) => {
  const classes = useStyles();
  const history = useHistory();
  const { releaseId } = useParams<{ releaseId: string }>();
  const [{ message: PageMessage, variant: pageVariant, isOpen: pageToastIsOpen }, setPageToast] = useState<{
    message: string;
    variant: 'error' | 'success';
    isOpen: boolean;
  }>({
    message: '',
    variant: 'success',
    isOpen: false
  });
  const [loading, setLoading] = useState(false);

  const handleBeginRelease = async () => {
    setLoading(true);
    try {
      await beginSoftwareRelease(releaseId);
      history.push(`/employees/software-releases/${releaseId}/releasing`);
    } catch (error) {
      setPageToast({
        message: `${(error as any)?.response?.data?.Detail ?? 'Error beginning release'}`,
        variant: 'error',
        isOpen: true
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <div className='mt-7 mb-6'>
            <Grid item xs={12} className={classes.imageContainer}>
              <img src={WaitingGraphic} alt='Waiting for Release' className={classes.responsiveImage} />
            </Grid>
            <Grid item xs={12}>
              <Typography className={classes.title}>Waiting for expected release time of</Typography>
            </Grid>
            <Grid item xs={12} className={classes.textWrapper}>
              <Typography variant='subtitle1'>{releaseDate ? `${formatDateTime(releaseDate)} ${timezone}` : <Loader>Loading...</Loader>}</Typography>
            </Grid>
            <Grid item xs={12} className={classes.buttonWrapper}>
              <Button
                variant='contained'
                color='secondary'
                className={classes.button}
                onClick={handleBeginRelease}
                disabled={loading || releaseStatusName !== ReleaseStatuses.CLIENTAPPROVAL}
                startIcon={loading ? <CircularProgress size={20} /> : null}
              >
                Begin Release
              </Button>
            </Grid>
          </div>
        </Grid>
      </Grid>
      <Toast
        id='page-toast'
        message={PageMessage}
        open={pageToastIsOpen}
        onClose={() =>
          setPageToast({
            message: '',
            variant: pageVariant,
            isOpen: false
          })
        }
        variant={pageVariant}
      />
    </>
  );
};

const useStyles = makeStyles(theme => ({
  title: {
    fontSize: '1.5rem',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.25rem'
    },
    color: theme.palette.primary.main,
    fontWeight: 600,
    textAlign: 'center',
    marginTop: theme.spacing(2)
  },
  imageContainer: {
    display: 'flex',
    justifyContent: 'center'
  },
  textWrapper: {
    backgroundColor: theme.palette.grey[100],
    display: 'flex',
    justifyContent: 'center',
    borderRadius: '.25rem',
    alignItems: 'center',
    margin: '.5rem auto',
    maxWidth: '20%',
    padding: theme.spacing(0.25),
    [theme.breakpoints.down('sm')]: {
      maxWidth: '80%'
    },
    width: '100%'
  },
  responsiveImage: {
    maxWidth: '30%',
    height: 'auto',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '80%'
    }
  },
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(2)
  },
  button: {
    maxWidth: '30%',
    width: '100%',
    height: '42px',
    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.3)',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '80%'
    }
  }
}));
