import React, { FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
// Components
import { IconButton, Tooltip, Grid } from '@material-ui/core';
import { Clear, Done, MoreVert } from '@material-ui/icons';
// Types
import { FormikHandlers, FieldMetaProps } from 'formik';

interface IActionCellProps extends FormikHandlers, FieldMetaProps<any> {
  row: { index: number };
  data: any[];
  handleAccept: (index: number) => void;
  handleDismiss: (index: number) => void;
  handleSetContextButton: (currentTarget: HTMLElement, index: number) => void;
}

export const ActionCell: FC<IActionCellProps> = ({ row: { index }, data, handleAccept, handleDismiss, handleSetContextButton }) => {
  const classes = useStyles();

  return (
    <React.Fragment>
      {data[index].isSuggestion && (
        <Grid className={clsx(classes.actionButtons, 'actionButtons')}>
          <>
            <Tooltip title={!data[index].clientProjectId ? 'Select a client and project' : 'Accept'}>
              <span>
                <IconButton
                  disabled={!data[index].clientProjectId || data[index].isLocked}
                  size='small'
                  aria-label='accept'
                  onClick={() => handleAccept(index)}
                  className={clsx(classes.accept, !data[index].clientProjectId ? classes.disabled : '')}
                >
                  <Done />
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip title='Dismiss'>
              <IconButton size='small' aria-label='Dismiss' onClick={() => handleDismiss(index)} className={classes.delete}>
                <Clear />
              </IconButton>
            </Tooltip>
          </>
        </Grid>
      )}
      {!data[index].isSuggestion && (
        <Grid className={clsx(classes.actionButtons, 'actionButtons')}>
          <Tooltip title='More Options'>
            <IconButton
              size='small'
              aria-label='edit'
              disabled={data[index].isLocked}
              onClick={({ currentTarget }) => {
                handleSetContextButton(currentTarget, index);
              }}
              className={classes.contextMenuButton}
            >
              <MoreVert />
            </IconButton>
          </Tooltip>
        </Grid>
      )}
    </React.Fragment>
  );
};

const useStyles = makeStyles(theme => ({
  delete: {
    '& svg': {
      fill: theme.palette.error.main
    }
  },
  accept: {
    '& svg': {
      fill: theme.palette.primary.main
    }
  },
  actionButtons: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  contextMenuButton: {
    padding: 0
  },
  disabled: {
    backgroundColor: 'transparent !important',
    '& svg': {
      fill: `${theme.palette.text.hint} !important`
    }
  }
}));
